import { ReactNode } from "react";

import { CONTACT_EMAIL } from "./constants";

const Email = () => <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>;

export const GENERIC_ERROR_MESSAGE = (
  <span>
    Wystąpił nieprzewidziany błąd - jeżeli sytuacja będzie się powtarzać
    skontaktuj się z nami (<Email />)
  </span>
);

export const mapErrorMessage = (code: string): ReactNode => {
  switch (code) {
    case "short_name_is_not_alpha":
      return "Krótka wersja nazwy firmy może zawierać jedynie litery";

    case "no_data_for_date_range":
      return "Brak rekordów dla wybranego zakresu dat.";

    case "max_accounts_number_too_low":
      return "Nie można zmniejszyć limitu użytkowników poniżej ich aktualnej liczby.";

    case "category_level_required_when_categories_are_given":
      return (
        <span>
          Brak niezbędnych informacji o kategorii - jeżeli sytuacja będzie się
          powtarzać skontaktuj się z nami (<Email />)
        </span>
      );

    case "limit_offset_both_required":
      return (
        <span>
          Brak niezbędnych informacji o produktach - jeżeli sytuacja będzie się
          powtarzać skontaktuj się z nami (<Email />)
        </span>
      );

    case "store_type_with_counties_voivodeships_not_allowed":
      return "Wybór rodzaju sklepów jest możliwy tylko dla terenu całej Polski.";

    case "date_from_earlier_than_date_to":
      return "Data końcowa musi być późniejsza niż początkowa.";

    case "companies_materials_both_not_allowed":
      return "Brak możliwości wybrania do porówania jednocześnie produktów i firm konkurencyjnych";

    case "cookie_missing":
    case "cookie_mismatch":
      return (
        <span>
          Błąd bezpieczeństwa - skontaktuj się z nami (<Email />)
        </span>
      );

    case "user_exists_in_ad":
      return "Taki użytkownik (@ lub nr tel.) już istnieje w systemie.";

    case "wrong_domain":
      return "Nie możesz dodać tego użytkownika, ponieważ jego adres email nie należy do domeny internetowej Twojej firmy. Skontaktuj się z zespołem BDA w tej sprawie.";

    case "wrong_company":
      return "Nie można przypisać użytkownika do innej organizacji.";

    case "too_many_users":
      return "Nie możesz dodać kolejnego użytkownika - aktualna liczba osób korzystających z ACIT w Twojej firmie osiągnęła limit. Skontaktuj się z zespołem BDA w tej sprawie.";

    case "agreement_not_confirmed":
      return "Brak akceptacji zgód prawnych przy pierwszy logowaniu.";

    case "authentication_failed":
    case "permission_denied":
      return "Brak dostępu do portalu ACIT";

    case "category-dates-permissions-required":
      return (
        <span>
          Brak dostępu do danych. Prosimy o kontakt - <Email />
        </span>
      );

    case "parse_error":
    case "not_found":
    default:
      return GENERIC_ERROR_MESSAGE;
  }
};
