import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { useLogisticsChartData } from "api/logistics/mappers/useLogisticsChartData";

import { Button } from "components/atoms";
import { isRestrictedToOneLineSelector } from "components/molecules/ChartTypeToggle/hooks";
import { IDS } from "components/organisms/Tour";
import c from "pages/Reports/partials/Chart/Legend/common.module.scss";
import { useLegend } from "pages/Reports/partials/Chart/Legend/hooks/useLegend";
import { useLegendItemsType } from "pages/Reports/partials/Chart/Legend/hooks/useLegendItemsType";
import { useLegendLineNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendLineNames";
import s from "pages/Reports/partials/Chart/Legend/Legend/legends.module.scss";
import {
  LegendItem,
  LegendItems
} from "pages/Reports/partials/Chart/Legend/LegendItems/LegendItems";
import { LegendSubSection } from "pages/Reports/partials/Chart/Legend/LegendSubSection/LegendSubSection";
import {
  hoveredLineSelector,
  toggleChart,
  updateHoveredLine
} from "pages/Reports/redux/reducers/chartReducer";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { DATASET_TYPES, isThisPage, NO_DATA } from "utils";
import { pushLegendEvent } from "utils/googleTagManager/dataLayer";
import { Values } from "utils/types";

export type LegendTab = {
  label: string;
  disabled?: boolean;
  sections: {
    label: string;
    type?: Values<typeof DATASET_TYPES>;
    items: LegendItem[];
  }[];
};

const TEST_IDS = {
  LEGEND_TAB_BUTTON: "legend-tab-button"
};

export const Legend = () => {
  const pathname = useSelector(pathnameSelector);

  const isLogisticsPage = isThisPage(
    pathname,
    REPORTS_FULL_PATHS.LOGISTICS_PATH
  );

  let tabs: LegendTab[] = useLegend();

  const lineChart = useLogisticsChartData();
  if (isLogisticsPage) tabs = lineChart[0]?.legend || [];

  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const hoveredLine = useSelector(hoveredLineSelector);
  const isRestrictedToOneLine = useSelector(isRestrictedToOneLineSelector);
  const legendLineNames = useLegendLineNames();
  const legendItemsType = useLegendItemsType();

  useEffect(() => {
    const index = tabs.findIndex(
      tab => tab.sections.length > 0 && !tab.disabled
    );

    if (index === -1) {
      setTabIndex(0);
    }
  }, [tabs]);

  const onTabSelect = (index: number) => {
    const label = tabs[index].label.toLowerCase();

    setTabIndex(index);
    pushLegendEvent(label);
  };

  const onClick = (labels: string[]) => {
    if (isRestrictedToOneLine) {
      dispatch(toggleChart([]));
      dispatch(toggleChart(legendLineNames));
    }

    dispatch(toggleChart(labels));
    pushLegendEvent(legendItemsType);
  };

  const onMouse = (labels: string[]) => {
    if (!isRestrictedToOneLine) {
      dispatch(updateHoveredLine(labels));
    }
  };

  return (
    <Tabs
      data-testid={IDS.LEGEND_TABS}
      selectedIndex={tabIndex}
      onSelect={onTabSelect}
    >
      <TabList>
        <li className={s.legend}>Legenda:</li>
        {tabs.map(tab => (
          <Tab
            key={`legend-tab-${tab.label}`}
            disabled={tab.disabled}
            selectedClassName={c.activeTab}
            disabledClassName={s.disabledTab}
          >
            <Button
              disabled={tab.disabled}
              className={s.tabTitle}
              data-ga-graph-legend={tab.label}
              data-testid={`${TEST_IDS.LEGEND_TAB_BUTTON}-${tab.label}`}
            >
              {tab.label}
            </Button>
          </Tab>
        ))}
      </TabList>

      {tabs.map(tab => (
        <TabPanel key={`tab-panel${tab.label}`}>
          {tab.sections.map(section => (
            <LegendSubSection
              isDisabled={tab.label === NO_DATA || isRestrictedToOneLine}
              onClick={isRestrictedToOneLine ? () => {} : onClick}
              sectionTitle={section.label}
              items={section.items}
              key={`legend-subsection-${section.label}`}
            >
              <LegendItems
                isDisabled={tab.label === NO_DATA}
                items={section.items}
                itemsTitle={section.label}
                onClick={onClick}
                onMouse={onMouse}
                hoveredLine={hoveredLine}
              />
            </LegendSubSection>
          ))}
        </TabPanel>
      ))}
    </Tabs>
  );
};
