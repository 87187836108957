import { useState } from "react";

import cn from "classnames";
import { uniq } from "ramda";

import { TooltipIcon } from "components/atoms/Icon";
import { Checkbox, CompareDateCalendar } from "components/molecules";
import { IDS } from "components/organisms/Tour/constants";

import c from "../dateDropdownMenu.module.scss";
import s from "./historicalSection.module.scss";
import { useHistorical } from "./useHistorical";

enum OpenStatus {
  none,
  start,
  end
}

type Props = {
  isMonthCalendar: boolean;
} & ReturnType<typeof useHistorical>;

export const HistoricalSection = ({
  isMonthCalendar,
  isEnabled,
  isOpen,
  toggleOpen,
  startDate,
  endDate,
  rangeStart,
  rangeEnd,
  handleDateChange,
  diffInfo,
  hasLeapYear,
  isLeapYearChecked,
  toggleLeapYear,
  handlePredefinedOption
}: Props) => {
  const [openStatus, setOpenStatus] = useState(OpenStatus.none);

  const changeOpenStatus = (status: OpenStatus) => () => {
    setOpenStatus(status);
  };

  if (!isEnabled) {
    return null;
  }

  const predefinedOptions = uniq([14, 28, diffInfo.base + 1].filter(Boolean));

  return (
    <div
      id={IDS.HISTORICAL_CONTAINER}
      className={cn(c.calendarSection, s.historicalSection, {
        [s.historicalSectionMonth]: isMonthCalendar
      })}
    >
      <Checkbox
        className={{ base: s.checkbox }}
        isChecked={isOpen}
        onChange={toggleOpen}
        label="Porównaj do okresu historycznego"
        testId="toggle-historical-calendar-checkbox"
      />
      {isOpen ? (
        <>
          {!isMonthCalendar && (
            <div id={IDS.PREDEFINED_OPTIONS} className={s.options}>
              <div className={s.optionsTooltip}>
                <TooltipIcon />
              </div>
              <p className={s.optionsLabel}>Ustawienia domyślne:</p>
              {predefinedOptions.map(days => {
                return (
                  <button
                    key={days}
                    type="button"
                    className={s.optionButton}
                    onClick={() => handlePredefinedOption(days)}
                  >{`${days} dni`}</button>
                );
              })}
            </div>
          )}
          <div className={s.inputs}>
            <CompareDateCalendar
              label="Od:"
              className={s.input}
              isMonthPicker={isMonthCalendar}
              open={openStatus === OpenStatus.start}
              onInputClick={changeOpenStatus(OpenStatus.start)}
              onSelect={changeOpenStatus(OpenStatus.end)}
              onClickOutside={changeOpenStatus(OpenStatus.none)}
              selected={startDate}
              onChange={handleDateChange("start")}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={rangeStart.min}
              maxDate={rangeStart.max}
              popperPlacement="top-end"
            />
            <CompareDateCalendar
              label="Do:"
              className={s.input}
              isMonthPicker={isMonthCalendar}
              open={openStatus === OpenStatus.end}
              onInputClick={changeOpenStatus(OpenStatus.end)}
              onSelect={changeOpenStatus(OpenStatus.none)}
              onClickOutside={changeOpenStatus(OpenStatus.none)}
              selected={endDate}
              onChange={handleDateChange("end")}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={rangeEnd.min}
              maxDate={rangeEnd.max}
              popperPlacement="top-end"
            />
            {diffInfo.message ? (
              <span
                className={cn(
                  s.info,
                  c.alert,
                  diffInfo.base < diffInfo.compare ? c.error : c.success
                )}
              >
                {diffInfo.message}
              </span>
            ) : null}
          </div>
          {hasLeapYear ? (
            <Checkbox
              className={{ base: s.leapYearCheckbox }}
              isChecked={isLeapYearChecked}
              onChange={toggleLeapYear}
              label="Dołącz 29.02 do obliczeń"
              testId="toggle-include-leap-year"
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};
