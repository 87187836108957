import * as React from "react";

import cn from "classnames";

import { Text } from "components/atoms";
import { TEXT_WEIGHTS, TEXT_WRAPPERS } from "utils";

import { FilterDropdownList } from "../";
import { DropdownItem, PropGetter } from "../types";
import s from "./filterDropdownMenu.module.scss";

type MenuPropGetter = PropGetter<"getMenuProps">;
type ItemPropGetter = PropGetter<"getItemProps">;

type IsActiveFn<Item = any, SelectedItem = any> = (
  currentItem: Item,
  selectedItem?: SelectedItem
) => boolean;

export interface Props<Item extends DropdownItem> {
  isDisabled?: boolean;
  className?: {
    base?: string;
    label?: string;
    list?: string;
    item?: string;
    itemText?: string;
  };
  items?: Item[];
  label?: string;
  getMenuProps?: MenuPropGetter;
  getItemProps?: ItemPropGetter;
  selectedItem?: DropdownItem | null;
  placeholder?: React.ReactNode;
  inlinePlaceholder?: React.ReactNode | null;
  isMultiChoice?: boolean;
  isMultiChoiceSplitted?: boolean;
  isItemActiveFn?: IsActiveFn;
  inputValue?: string;
  filterItemsFn?: (
    inputValue: string
  ) => (item: Item, index: number, array: Item[]) => boolean;
  listItem?: React.ReactElement<any, any>;
  onChange?: (item: DropdownItem | null) => void;
  isOpen?: boolean;
  minimumItemsToVirtualize?: number;
  onSelectAll?: () => void;
  onSelectAllActive?: () => void;
  onSelectAllInactive?: () => void;
  onClear?: () => void;
  bottomElement?: React.ReactNode;
  dropdownAlertText?: string;
  itemsCounterText?: string;
}

const TEST_IDS = {
  selected: "filter-menu-selected",
  selectAll: "filter-menu-select-all",
  selectActive: "filter-menu-select-active",
  selectInactive: "filter-menu-select-inactive",
  clear: "filter-menu-clear"
};

export function FilterDropdownMenu<Item extends DropdownItem>({
  className = {},
  label,
  selectedItem,
  isItemActiveFn = () => false,
  placeholder,
  filterItemsFn,
  inputValue = "",
  onChange = () => {},
  isMultiChoice = false,
  isMultiChoiceSplitted = false,
  isOpen,
  inlinePlaceholder,
  minimumItemsToVirtualize = 0,
  onSelectAll,
  onSelectAllActive = () => {},
  onSelectAllInactive = () => {},
  onClear,
  bottomElement,
  dropdownAlertText,
  itemsCounterText,
  ...props
}: Props<Item>) {
  const getMenuProps = props.getMenuProps as MenuPropGetter;
  const getItemProps = props.getItemProps as ItemPropGetter;
  const listItem = props.listItem as React.ReactElement<any, any>;

  const items = props.items as Item[];

  const isItemActive = (item: Item) => isItemActiveFn(item, selectedItem);

  const filterItems = filterItemsFn ? filterItemsFn(inputValue) : () => true;

  const filteredItems = items.filter(filterItems);

  const showSelectAllButton =
    isMultiChoice &&
    !isMultiChoiceSplitted &&
    typeof onSelectAll !== "undefined";

  return (
    <div
      {...getMenuProps({
        className: cn(s.base, className.base)
      })}
    >
      {label && (
        <Text
          size="small"
          className={cn(s.label, className.label)}
          weight={TEXT_WEIGHTS.BOLD}
        >
          {label}
        </Text>
      )}
      {items.length === 0 && placeholder ? (
        <div className={cn(s.list, className.list)}>{placeholder}</div>
      ) : (
        <div>
          <div className={s.itemsCounter}>
            <Text className={s.itemsCounterText} testId={TEST_IDS.selected}>
              {itemsCounterText ??
                `Wybrano ${
                  Array.isArray(selectedItem) ? selectedItem.length : 0
                } z ${filteredItems.length}`}
            </Text>
            {showSelectAllButton && (
              <button
                type="button"
                className={s.itemsCounterButton}
                onClick={onSelectAll}
                data-testid={TEST_IDS.selectAll}
                {...{
                  "data-ga-filter-dropdown": "zaznacz wszystkie"
                }}
              >
                <Text className={s.itemsCounterButtonText}>
                  Zaznacz wszystkie
                </Text>
              </button>
            )}
            {isMultiChoiceSplitted && (
              <Text className={s.itemsCounterButtonText}>
                Zaznacz:
                <button
                  type="button"
                  className={s.itemsCounterButton}
                  onClick={onSelectAllActive}
                  data-testid={TEST_IDS.selectActive}
                  {...{
                    "data-ga-filter-dropdown": "zaznacz aktywne"
                  }}
                >
                  aktywne
                </button>
                <Text
                  className={s.buttonsSplitter}
                  Wrapper={TEXT_WRAPPERS.SPAN}
                >
                  |
                </Text>
                <button
                  type="button"
                  className={s.itemsCounterButton}
                  onClick={onSelectAllInactive}
                  data-testid={TEST_IDS.selectInactive}
                  {...{
                    "data-ga-filter-dropdown": "zaznacz nieaktywne"
                  }}
                >
                  nieaktywne
                </button>
              </Text>
            )}
            {onClear && (
              <button
                type="button"
                className={s.itemsCounterButton}
                onClick={() => onClear()}
                data-testid={TEST_IDS.clear}
                {...{
                  "data-ga-filter-dropdown": "wyczyść"
                }}
              >
                <Text className={s.itemsCounterButtonText}>Wyczyść</Text>
              </button>
            )}
          </div>

          {inlinePlaceholder || null}
          <FilterDropdownList
            listItem={listItem}
            items={filteredItems}
            minimumItemsToVirtualize={18}
            isItemActive={isItemActive}
            getItemProps={getItemProps}
            className={cn(className.list)}
            isMultiChoice={isMultiChoice}
          />
        </div>
      )}
      {bottomElement && bottomElement}
      {dropdownAlertText && (
        <div className={s.dropdownAlert}>{dropdownAlertText}</div>
      )}
    </div>
  );
}
