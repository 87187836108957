import { useGetLogistics } from "api/logistics/hooks/useGetLogistics";

export const useIsLogisticsLoading = () => {
  const {
    isLoading: isLocationsLoading,
    isIdle: isLocationsIdle
  } = useGetLogistics();

  return isLocationsLoading || isLocationsIdle;
};
