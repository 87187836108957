import React from "react";
import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";

import { Button, Heading, Portal, SpinnerLoader, Text } from "components/atoms";
import { ChevronRight } from "components/atoms/Icon";
import { PopupWindow } from "components/molecules";
import {
  BLOCK_USER,
  blockUserThunk,
  DELETE_USER,
  deleteUserThunk,
  reactivateUserThunk,
  RESET_PASSWORD_USER,
  resetPasswordThunk,
  setUserPopup,
  UNLOCK_USER
} from "pages/Admin/redux/reducers/userManagementReducer";
import {
  isUserActionInProgressSelector,
  usersErrorSelector
} from "pages/Admin/redux/selectors/userManagementSelectors";
import { User } from "store/reducers/userReducer";
import { ICON_SIZES, TEXT_WRAPPERS } from "utils";

import s from "./userManagingPopup.module.scss";

interface Props {
  user: User;
  popupType: string;
}

export const UserManagingPopup = ({ popupType, user }: Props) => {
  const dispatch = useDispatch();
  const error = useSelector(usersErrorSelector);
  const isInProgress = useSelector(isUserActionInProgressSelector);

  const generateContent = () => {
    switch (popupType) {
      case BLOCK_USER:
        return (
          <>
            <Heading className={s.title}>Zablokowanie użytkownika</Heading>
            <Text className={s.content}>
              <Text Wrapper={TEXT_WRAPPERS.SPAN}>
                Czy na pewno chcesz zablokować użytkownika{" "}
              </Text>
              <Text Wrapper={TEXT_WRAPPERS.SPAN} className={s.userName}>
                {`${user.name} `}
              </Text>
              <Text Wrapper={TEXT_WRAPPERS.SPAN}>({user.email})? </Text>
              <Text Wrapper={TEXT_WRAPPERS.SPAN}>
                W ciągu 30 dni możesz odblokować użytkownika ponownie.
              </Text>
            </Text>
          </>
        );
      case UNLOCK_USER:
        return (
          <>
            <Heading className={s.title}>Odblokowanie użytkownika</Heading>
            <Text className={s.content}>
              <Text Wrapper={TEXT_WRAPPERS.SPAN}>
                Czy na pewno chcesz odblokować użytkownika{" "}
              </Text>
              <Text Wrapper={TEXT_WRAPPERS.SPAN} className={s.userName}>
                {`${user.name} `}
              </Text>
              <Text Wrapper={TEXT_WRAPPERS.SPAN}>({user.email})? </Text>
            </Text>
          </>
        );
      case DELETE_USER:
        return (
          <>
            <Heading className={s.title}>Usuwanie użytkownika</Heading>
            <Text className={s.content}>
              <Text Wrapper={TEXT_WRAPPERS.SPAN}>
                Czy na pewno chcesz usunąć użytkownika{" "}
              </Text>
              <Text Wrapper={TEXT_WRAPPERS.SPAN} className={s.userName}>
                {user.name}
              </Text>
              ?
            </Text>
          </>
        );
      case RESET_PASSWORD_USER:
        return (
          <>
            <Heading className={s.title}>Resetowanie hasła użytkownika</Heading>
            <Text className={s.content}>
              <Text Wrapper={TEXT_WRAPPERS.SPAN}>
                Czy na pewno chcesz zresetować hasło użytkownika{" "}
              </Text>
              <Text Wrapper={TEXT_WRAPPERS.SPAN} className={s.userName}>
                {user.name}
              </Text>
              ?
            </Text>
          </>
        );
      default:
        return <span></span>;
    }
  };

  const dispatchAction = () => {
    switch (popupType) {
      case BLOCK_USER:
        dispatch(blockUserThunk(user.id, user.email));
        break;
      case DELETE_USER:
        dispatch(deleteUserThunk(user.id, user.email));
        break;
      case RESET_PASSWORD_USER:
        dispatch(resetPasswordThunk(user.id, user.email));
        break;
      case UNLOCK_USER:
        dispatch(reactivateUserThunk(user.id, user.email));
        break;
    }
  };

  const getActionButtonText = () => {
    switch (popupType) {
      case BLOCK_USER:
        return "Zablokuj";
      case DELETE_USER:
        return "Usuń";
      case RESET_PASSWORD_USER:
        return "Zresetuj";
      case UNLOCK_USER:
        return "Odblokuj";
      default:
        return "";
    }
  };

  return (
    <Portal id="js-user-action-confirm">
      <PopupWindow>
        <div className={s.userManagementPopupContainer}>
          {generateContent()}
          <footer
            className={cn(s.buttonsWrapper, {
              [s.center]: isInProgress
            })}
          >
            {isInProgress ? (
              <SpinnerLoader />
            ) : (
              <>
                <Button
                  onClick={dispatchAction}
                  type="button"
                  className={s.actionButton}
                >
                  {getActionButtonText()}
                  <ChevronRight
                    size={ICON_SIZES.LARGE}
                    className={s.chevronRightIcon}
                  />
                </Button>
                <button
                  onClick={() => dispatch(setUserPopup(""))}
                  type="button"
                  className={s.cancelButton}
                >
                  Anuluj
                </button>
                {error && <Text className={s.errorText}>{error}</Text>}
              </>
            )}
          </footer>
        </div>
      </PopupWindow>
    </Portal>
  );
};
