import { useDispatch, useSelector } from "react-redux";

import { ToggleButton } from "components/atoms";
import { ToggleButtonList } from "components/molecules";
import s from "components/molecules/ChartTypeToggle/chartTypeToggle.module.scss";
import { IDS } from "components/organisms/Tour";
import { updateQueryParams } from "store/actions/routerActions";
import { CHART_TYPES } from "utils";
import { QP } from "utils/defaultQueryParams";
import { pushChartSwitchEvent } from "utils/googleTagManager/dataLayer";

import {
  disabledChartTypesSelector,
  hiddenChartTypesSelector,
  useSelectedChartType
} from "./hooks";
import {
  IconAreaChart,
  IconComboChart,
  IconGroupedBarChart,
  IconIncrementalChart,
  IconLineChart
} from "./icons";

const CHART_BUTTONS = [
  {
    icon: <IconLineChart className={s.icon} />,
    tooltipLabel: "Wykres liniowy",
    disabledTooltip: "Brak możliwości wygenerowania wykresu liniowego",
    type: CHART_TYPES.LINE
  },
  {
    icon: <IconAreaChart className={s.icon} />,
    tooltipLabel: "Wykres pasmowy",
    disabledTooltip: "Brak możliwości wygenerowania wykresu pasmowego",
    type: CHART_TYPES.AREA
  },
  {
    icon: <IconGroupedBarChart className={s.icon} />,
    tooltipLabel: "Wykres kolumnowy grupowany",
    disabledTooltip:
      "Brak możliwości wygenerowania wykresu kolumnowego grupowanego",
    type: CHART_TYPES.GROUPED_BAR
  },
  {
    icon: <IconIncrementalChart className={s.icon} />,
    tooltipLabel: "Wykres przyrostowy",
    disabledTooltip: "Brak możliwości wygenerowania wykresu przyrostowego",
    type: CHART_TYPES.INCREMENTAL
  },
  {
    icon: <IconComboChart className={s.icon} />,
    tooltipLabel: "Wykres przyrostowy combo",
    disabledTooltip: "Brak możliwości wygenerowania wykresu przyrostowego",
    type: CHART_TYPES.COMBO
  }
];

export const ChartTypeToggle = () => {
  const dispatch = useDispatch();
  const hidden = useSelector(hiddenChartTypesSelector);
  const disabled = useSelector(disabledChartTypesSelector);
  const selected = useSelectedChartType();

  const onChange = (chartType: CHART_TYPES) => {
    let event;

    switch (chartType) {
      case CHART_TYPES.LINE:
        event = "chart type - line";
        break;
      case CHART_TYPES.AREA:
        event = "chart type - band";
        break;
      case CHART_TYPES.GROUPED_BAR:
        event = "chart type - clustered column";
        break;
      case CHART_TYPES.INCREMENTAL:
        event = "chart type - incremental";
        break;
      case CHART_TYPES.COMBO:
        event = "chart type - combo";
        break;
      default:
        event = "";
        break;
    }

    dispatch(updateQueryParams({ [QP.CHART_TYPE]: chartType }));
    pushChartSwitchEvent(event);
  };

  return (
    <ToggleButtonList id={IDS.CHART_TYPE_TOGGLE} className={s.wrapper}>
      {CHART_BUTTONS.filter(({ type }) => !hidden.includes(type)).map(item => {
        const isDisabled = disabled.includes(item.type);
        return (
          <ToggleButton
            key={item.type}
            tooltip={isDisabled ? item.disabledTooltip : item.tooltipLabel}
            onClick={() => onChange(item.type)}
            isDisabled={isDisabled}
            isActive={item.type === selected}
            className={{ li: s.toggleButton }}
          >
            {item.icon}
          </ToggleButton>
        );
      })}
    </ToggleButtonList>
  );
};
