import { ParsedQuery } from "query-string";
import { isNil, pickBy } from "ramda";

type FilteredParsedQuery = {
  [key: string]: string | string[];
};

export const filterEmptyParameters = (
  queryParams: ParsedQuery
): FilteredParsedQuery =>
  pickBy(val => !isNil(val) && val.length > 0, queryParams);
