import { useSelector } from "react-redux";

import { useGetSellsByPeriod } from "api/sells/mappers/useGetSellsByPeriod";
import { sortBarChartData } from "api/utils";
import { uniq } from "ramda";

import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useSidebarMetric } from "components/organisms/SidebarMetricSelect/SidebarMetricSelect";
import { useBarChartSorting } from "hooks/useBarChartSorting";
import { useLegendContentNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendContentNames";
import { createLegendId } from "pages/Reports/partials/Chart/Legend/utils/createLegendId";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { hiddenLinesSelector } from "pages/Reports/redux/reducers/chartReducer";
import { checkedDataTypes } from "pages/Reports/sections/utils/chartData";
import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import { valueByDataType } from "pages/Reports/utils";
import { getIsPercentageType } from "pages/Reports/utils/formatTooltipValue";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { isBarChartTableEnabled } from "utils/constants";

import { Dataset } from "./types";

const emptyResult = { dataType: "", isPercentageType: false, dataset: [] };

export const useSellBarChartTableData = () => {
  const firstType = useSelector(firstChartDataTypeSelector);
  const secondType = useSelector(secondChartDataTypeSelector);
  const hiddenLines = useSelector(hiddenLinesSelector).other;
  const { data: sell } = useGetSellsByPeriod();
  const dataset = useCurrentDataset();
  const { isLineChart } = useChartTypeBoolean();
  const contentNames = useLegendContentNames();
  const barChartSorting = useBarChartSorting();
  const { value: sidebarDataType } = useSidebarMetric();

  if (
    !isBarChartTableEnabled ||
    !sell ||
    !sell.data?.data?.length ||
    sell.data?.data[0].timeline.length > 1 || // for bar chart only
    !dataset.both || // with both datasets selected
    !isLineChart // and line chart selected
  ) {
    return [emptyResult, emptyResult, emptyResult];
  }

  const dataTypes = uniq(
    checkedDataTypes([firstType, secondType, sidebarDataType])
  );
  const itemsWithData = sell.data.data.filter(data => {
    return dataTypes.some(dataType => {
      return (
        valueByDataType(dataType, data.timeline[0]) ||
        valueByDataType(`${dataType}_LAST_YEAR`, data.timeline[0])
      );
    });
  });
  const [first, second, third] = dataTypes.map(dataType => {
    const isPercentageType = getIsPercentageType(dataType);
    return {
      dataType,
      isPercentageType,
      dataset: itemsWithData
        // filter out hidden lines
        .filter(data => {
          return !hiddenLines.includes(
            createLegendId(
              getChartLabel({
                vendorId: data.vendorId,
                resultId: data.resultId,
                type: data.type
              }),
              contentNames,
              data.type
            )
          );
        })
        // sort by bar chart sorting
        .sort(sortBarChartData(barChartSorting.dataKey, barChartSorting.isDesc))
        // transform data for table
        .map(data => {
          return {
            legendId: createLegendId(
              getChartLabel({
                vendorId: data.vendorId,
                resultId: data.resultId,
                type: data.type
              }),
              contentNames,
              data.type
            ),
            baseValue: valueByDataType(dataType, data.timeline[0]),
            historicalValue: valueByDataType(
              `${dataType}_LAST_YEAR`,
              data.timeline[0]
            ),
            changeValue: valueByDataType(
              isPercentageType
                ? `${dataType}_CHANGE` // use regular change for percentage data type
                : `${dataType}_CHANGE_PERCENTAGE`, // use percentage change for non-percentage data type
              data.timeline[0]
            )
          } as Dataset;
        })
    };
  });

  return [first ?? emptyResult, second ?? emptyResult, third ?? emptyResult];
};
