import { useRankingWarnings } from "api/ranking/mappers";
import {
  useGetReceiptsByPeriod,
  useReceiptsGeneralWarnings
} from "api/receipts/mappers";

export const useReceiptsWarnings = (): string[] => {
  const generalWarnings = useReceiptsGeneralWarnings();
  const info = useGetReceiptsByPeriod().data?.info ?? [];
  const rankingWarnings = useRankingWarnings();

  return [...generalWarnings, ...info, ...rankingWarnings].filter(
    warning => !!warning
  );
};
