import { useEffect, useRef } from "react";

import { area, line, select } from "d3";

import { ChartData } from "components/D3/types";
import { useChartScales } from "pages/Reports/partials/Chart/StandardChart/utils";
import { BOOL_STRING_VALUES } from "utils";

import { useChartToShow } from "./hooks/useChartToShow";
import { useOneLineSelection } from "./hooks/useOneLineSelected";

type Datum = { x: string; y0: number; y1: number };

type Props = {
  width: number;
  height: number;
  margin: { left: number; top: number; right: number; bottom: number };
  lineChart: ChartData;
};

export const AreaChartSection: React.FC<Props> = ({
  width,
  height,
  margin,
  lineChart
}) => {
  const line1Ref = useRef<SVGPathElement>(null);
  const line2Ref = useRef<SVGPathElement>(null);
  const redAreaClipPathRef = useRef<SVGPathElement>(null);
  const redAreaRef = useRef<SVGPathElement>(null);
  const greenAreaClipPathRef = useRef<SVGPathElement>(null);
  const greenAreaRef = useRef<SVGPathElement>(null);
  const { xScaleBand, yScale } = useChartScales(lineChart, width);
  const { domain, chartToShow, values, lastYearValues, id } = useChartToShow(
    lineChart
  );
  const x =
    Math.max(0, (width - margin.left - margin.right) / domain.length) / 2;

  useOneLineSelection();

  useEffect(() => {
    const datum = values.map((y0, i) => ({
      x: domain[i],
      y0,
      y1: lastYearValues[i]
    }));
    const drawArea = area<Datum>()
      .x(d => xScaleBand(d.x) || 0)
      .y0(d => yScale(d.y0))
      .y1(d => yScale(d.y1));
    // draw line for base data
    if (line1Ref.current) {
      select(line1Ref.current)
        .datum(datum)
        .attr(
          "d",
          line<Datum>()
            .x(d => xScaleBand(d.x) || 0)
            .y(d => yScale(d.y0))
        );
    }
    // draw line for historical data
    if (line2Ref.current) {
      select(line2Ref.current)
        .datum(datum)
        .attr(
          "d",
          line<Datum>()
            .x(d => xScaleBand(d.x) || 0)
            .y(d => yScale(d.y1))
        );
    }
    // hide red area below base data line
    if (redAreaClipPathRef.current) {
      select(redAreaClipPathRef.current)
        .datum(datum)
        .attr(
          "d",
          area<Datum>()
            .x(d => xScaleBand(d.x) || 0)
            .y0(height)
            .y1(d => yScale(d.y1))
        );
    }
    // draw red area
    if (redAreaRef.current) {
      select(redAreaRef.current)
        .datum(datum)
        .attr("d", drawArea);
    }
    // hide green area below historical data line
    if (greenAreaClipPathRef.current) {
      select(greenAreaClipPathRef.current)
        .datum(datum)
        .attr(
          "d",
          area<Datum>()
            .x(d => xScaleBand(d.x) || 0)
            .y0(0)
            .y1(d => yScale(d.y1))
        );
    }
    // draw green area
    if (greenAreaRef.current) {
      select(greenAreaRef.current)
        .datum(datum)
        .attr("d", drawArea);
    }
  }, [domain, height, lastYearValues, values, xScaleBand, yScale]);

  if (chartToShow === null) {
    return null;
  }

  return (
    <g transform={`translate(${x}, 0)`}>
      <path
        ref={line1Ref}
        id={id}
        fill="none"
        stroke="#000000"
        strokeWidth={3}
        data-multiline={BOOL_STRING_VALUES.TRUE}
      />
      {values.length === lastYearValues.length && (
        <>
          <path
            ref={line2Ref}
            id={id}
            fill="none"
            stroke="#878787"
            strokeWidth={3}
            data-multiline={BOOL_STRING_VALUES.TRUE}
            data-historical={BOOL_STRING_VALUES.TRUE}
          />
          <clipPath id="red-area-clip-path">
            <path ref={redAreaClipPathRef} />
          </clipPath>
          <path
            ref={redAreaRef}
            fill="#fb4934"
            stroke="none"
            fillOpacity={0.5}
            clipPath="url(#red-area-clip-path)"
          />
          <clipPath id="green-area-clip-path">
            <path ref={greenAreaClipPathRef} />
          </clipPath>
          <path
            ref={greenAreaRef}
            fill="#43b649"
            stroke="none"
            fillOpacity={0.5}
            clipPath="url(#green-area-clip-path)"
          />
        </>
      )}
    </g>
  );
};
