export const SIZES = {
  extraSmall: 12,
  small: 14,
  normal: 16,
  large: 20,
  extraLarge: 30
};

export const COLORS = {
  ALERT: "#CA1D08",
  OVERLAY: "#1D1D1D"
};
