import { DropdownItem } from "components/molecules/types";
import { CategoryItem } from "pages/Reports/redux/reducers/filters/categoryFilters/categoryFiltersActions";
import { ProductItem } from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";

export const isIncludedInInput = <T extends DropdownItem>(
  inputValue: string,
  item: T
) =>
  !inputValue ||
  item.label.toLowerCase().includes(inputValue.toLocaleLowerCase());

export const isAlreadySelected = <T extends DropdownItem>(
  selectedItems: T[],
  item: T
) => selectedItems.some(({ value }) => value === item.value);

export const isParentCategorySelected = (
  level: 1 | 2 | 3 | "product",
  currentItem: CategoryItem | ProductItem,
  l1Items: CategoryItem[],
  l2Items: CategoryItem[],
  l3Items?: CategoryItem[]
) => {
  if (level === 1) return true;

  if (level === 2) {
    if (l1Items.length === 0) return true;

    return l1Items.some(({ value }) => value === currentItem.parent?.id);
  }

  if (level === 3) {
    if (l2Items.length === 0 && l1Items.length === 0) return true;

    if (l2Items.length !== 0) {
      return l2Items.some(({ value }) => value === currentItem.parent?.id);
    }

    return l1Items.some(
      ({ value }) => value === currentItem.parent?.parent?.id
    );
  }

  if (l3Items && l3Items.length !== 0) {
    return l3Items.some(({ value }) => value === currentItem.parent?.id);
  }

  if (l2Items.length !== 0) {
    return l2Items.some(
      ({ value }) => value === currentItem.parent?.parent?.id
    );
  }

  if (l1Items.length !== 0) {
    return l1Items.some(
      ({ value }) => value === currentItem.parent?.parent?.parent?.id
    );
  }

  return true;
};

export const shouldSelectItem = <T extends DropdownItem>(
  level: 1 | 2 | 3 | "product",
  inputValue: string,
  selectedItems: T[],
  l1Items: CategoryItem[],
  l2Items: CategoryItem[],
  l3Items?: CategoryItem[]
) => (item: T) =>
  !isAlreadySelected<T>(selectedItems, item) &&
  isIncludedInInput<CategoryItem | ProductItem>(inputValue, item) &&
  isParentCategorySelected(level, item, l1Items, l2Items, l3Items);
