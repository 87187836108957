import * as React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useIsLocationsLoading } from "api/location/mappers/useIsLocationLoading";
import cn from "classnames";
import { push } from "connected-react-router";

import { TooltipWrapper } from "components/atoms";
import { VoivodeshipProps } from "components/D3/Voivodeship/types";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils/constants";
import { REGION_TYPES } from "utils";
import { Values } from "utils/types";

import { DEFAULT_COLOR } from "../Voivodeship/constants";
import { Voivodeship } from "../Voivodeship/Voivodeship";
import s from "./region.module.scss";

type Props = {
  Component?: React.ForwardRefExoticComponent<VoivodeshipProps>;
  type: Values<typeof REGION_TYPES>;
  id: string;
  fill?: string;
  onHover: () => void;
  onBlur: () => void;
};

const { VOIVODESHIP } = REGION_TYPES;
const { LOCATION_PATH } = REPORTS_FULL_PATHS;

export const Region = ({
  Component,
  id,
  type,
  fill = DEFAULT_COLOR,
  onHover,
  onBlur
}: Props) => {
  const dispatch = useDispatch();
  const isFetching = useIsLocationsLoading();
  const { search } = useLocation();

  if (type !== VOIVODESHIP && !Component) {
    throw new Error(
      `Invalid Region props. Component prop must be provided if type prop is not "voivodeship"`
    );
  }

  const handleRegionClick = React.useCallback(
    (e: React.MouseEvent<SVGPathElement, MouseEvent>) => {
      if (type !== VOIVODESHIP || isFetching) return;

      const { dataset } = e.currentTarget;
      const detailPath = `${LOCATION_PATH}/${dataset.name}${search}`;
      dispatch(push(detailPath));
    },
    [type, isFetching, dispatch, search]
  );

  const isClickable = type === VOIVODESHIP && !isFetching;

  const componentProps = React.useMemo(
    () => ({
      id,
      className: cn(isClickable && s.clickable),
      onClick: isClickable ? handleRegionClick : undefined,
      fill
    }),
    [fill, handleRegionClick, id, isClickable]
  );

  return (
    <TooltipWrapper<SVGPathElement>
      Component={Component || Voivodeship}
      onComponentHover={onHover}
      onComponentBlur={onBlur}
      isDisabled={isFetching}
      componentProps={componentProps}
    />
  );
};
