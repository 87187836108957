import * as React from "react";

import cn from "classnames";

import { Text } from "components/atoms";
import { ChevronDown, ChevronUp, TooltipIcon } from "components/atoms/Icon";
import { TableColumnHeader } from "components/types";
import { SECTIONS } from "pages/Reports/utils";
import { ICON_SIZES, TEXT_WRAPPERS } from "utils";
import { pushLogisticsReportAction } from "utils/googleTagManager/dataLayer";

import s from "./tableHeader.module.scss";

interface Props<Data> {
  column: TableColumnHeader<Data>;
  className?: {
    textWrapper?: string;
    text?: string;
  };
  testId?: string;
  isToggleSortButton?: boolean;
  reportName?: string;
}

//extends keyword must be included https://github.com/Microsoft/TypeScript/issues/4922
export const TableHeader = <Data extends {}>({
  column,
  className = {},
  testId,
  isToggleSortButton,
  reportName = ""
}: Props<Data>) => {
  if (typeof column.Header !== "string") {
    return column.render("Header");
  }

  if (!isToggleSortButton) {
    return (
      <Text className={cn(s.base, className)} testId={testId}>
        {column.Header}
      </Text>
    );
  }

  const { getSortByToggleProps, isSorted, isSortedDesc } = column;

  const pushDataLayer = () => {
    const isLogistics = reportName === SECTIONS.LOGISTICS;

    if (isLogistics) {
      pushLogisticsReportAction(`tabelka sortowanie - ${column.Header}`);
    }
  };

  return (
    <button
      {...getSortByToggleProps({
        title: "",
        className: cn(className, s.sortButton)
      })}
      {...{
        "data-ga-table-column": "sortowanie"
      }}
    >
      <Text className={className.textWrapper}>
        <Text
          className={cn(s.base, { [s.active]: isSorted }, className.text)}
          testId={testId}
          onClick={pushDataLayer}
          Wrapper={TEXT_WRAPPERS.SPAN}
        >
          {column.Header}
        </Text>
        {!!column.tooltipText && (
          <i className={s.tooltipIconWrapper} data-tooltip={column.tooltipText}>
            <TooltipIcon className={s.tooltipIcon} />
          </i>
        )}
      </Text>
      {isSorted && (
        <div className={s.iconWrapper}>
          {isSortedDesc ? (
            <ChevronDown
              size={ICON_SIZES.LARGE}
              testId={`${testId}-chevron-down`}
            />
          ) : (
            <ChevronUp
              size={ICON_SIZES.LARGE}
              testId={`${testId}-chevron-up`}
            />
          )}
        </div>
      )}
    </button>
  );
};
