import { Fragment } from "react";

import { useIsRankingSelected } from "api/ranking/utils";
import {
  useIsSellsLoading,
  useSellsChartData,
  useSellsWarnings
} from "api/sells/mappers";

import { CardContent, CardHeader } from "components/atoms";
import s from "components/common.module.scss";
import { Card, ChartSectionHeader } from "components/molecules";
import { ChartHeader } from "components/molecules/ChartHeader/ChartHeader";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { LinesLegend } from "pages/Reports/partials/Chart";
import { BarChartTable } from "pages/Reports/partials/Chart/BarChartTable/BarChartTable";
import { useSellBarChartTableData } from "pages/Reports/partials/Chart/BarChartTable/hooks/useSellBarChartTableData";
import { RankingChartSection } from "pages/Reports/partials/Chart/HorizontalBarChart/RankingChartSection";
import { Legend } from "pages/Reports/partials/Chart/Legend/Legend/Legend";
import { ChartSection } from "pages/Reports/partials/Chart/StandardChart/ChartSection";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";

import { LoadingInfo } from "../LoadingInfo/LoadingInfo";

export const SellChartSection = () => {
  const isLoading = useIsSellsLoading();
  const charts = useSellsChartData({});
  const historicalCharts = useSellsChartData({ isForHistoricalChart: true });
  const isRankingSelected = useIsRankingSelected();
  const warnings = useSellsWarnings();
  const { both: isBothDatasets } = useCurrentDataset();
  const { isGroupedBarChart } = useChartTypeBoolean();
  const barChartTableData = useSellBarChartTableData();

  return (
    <Card
      className={{ base: s.section }}
      isLoading={isLoading}
      loadingInfo={<LoadingInfo />}
    >
      <CardHeader className={s.cardHeader}>
        <ChartHeader />
      </CardHeader>
      <CardContent>
        <ChartSectionHeader warnings={warnings} isLoading={isLoading} />
        {isRankingSelected ? (
          <RankingChartSection />
        ) : (
          charts.map((chart, index) => {
            const showHistoricalGroupedBarChart =
              !chart.isTimelineMerged &&
              Boolean(historicalCharts[index]) &&
              isBothDatasets &&
              isGroupedBarChart;

            return (
              <Fragment key={`sell-chartsection-${chart.labels.bottom}`}>
                <ChartSection lineChart={chart} />
                {showHistoricalGroupedBarChart && (
                  <ChartSection lineChart={historicalCharts[index]} />
                )}
                {barChartTableData.map((tableData, index) => (
                  <BarChartTable
                    key={`bar-chart-table-${index}`}
                    dataType={tableData.dataType}
                    isPercentageType={tableData.isPercentageType}
                    dataset={tableData.dataset}
                  />
                ))}
                <LinesLegend data={chart} />
                {chart.domain.length > 0 && <Legend />}
              </Fragment>
            );
          })
        )}
      </CardContent>
    </Card>
  );
};
