import {
  Action,
  createHook,
  createStore,
  defaultRegistry
} from "react-sweet-state";

import { BOOL_STRING_VALUES } from "utils";

type State = { delay: boolean };
type Actions = typeof actions;

const actions = {
  getInitialDelayState: (delayQP: string): Action<State> => ({ setState }) => {
    setState({
      delay: delayQP === BOOL_STRING_VALUES.TRUE
    });
  },
  updateDelay: (delay: boolean): Action<State> => ({ setState }) => {
    setState({
      delay
    });
  },
  clearDelay: (): Action<State> => ({ setState }) => {
    setState({
      delay: false
    });
  }
};

export const DelayStore = createStore<State, Actions>({
  name: "delay",
  initialState: {
    delay: true
  },
  actions
});

export const DelayHooks = {
  useDelay: createHook(DelayStore)
};

export const DelayStoreInstance = defaultRegistry.getStore(DelayStore);
