import {
  format,
  getDate,
  getISOWeek,
  getMonth,
  getWeek,
  getYear,
  isSunday
} from "date-fns";

import { ChartTimePoint } from "components/D3/types";
import { TickLabel } from "pages/Reports/redux/types";
import {
  DATASET_TYPES,
  DEFAULT_DATE_FORMAT,
  getPolishMonthName,
  isD28DateSelected,
  PERIOD_TYPE
} from "utils";
import { PROMO_WEEK_START_DAY } from "utils/constants";
import { QP } from "utils/defaultQueryParams";

const MAX_DAYS_IN_MONTH = 31 as const;

export const getMonthsForNonMonthAggregation = (
  dates: Date[]
): { value: string | null; date: string }[] =>
  dates.map((currentDate, i, dates) => {
    const prevDate = dates[i - 1];

    const prevMonth = prevDate && getMonth(prevDate);
    const currentMonth = getMonth(currentDate);

    return {
      value:
        prevMonth === currentMonth
          ? null
          : getPolishMonthName(currentMonth, true),
      date: format(currentDate, DEFAULT_DATE_FORMAT)
    };
  });

export const getTickLabels = (period: string, dates: Date[]): TickLabel[] => {
  if (!dates.length)
    return [
      {
        value: "",
        date: ""
      }
    ];
  switch (period) {
    case PERIOD_TYPE.DAYS:
      return dates.length <= MAX_DAYS_IN_MONTH
        ? dates.map(date => ({
            value: getDate(date),
            isSunday: isSunday(date),
            date: format(date, DEFAULT_DATE_FORMAT)
          }))
        : getMonthsForNonMonthAggregation(dates);

    case PERIOD_TYPE.WEEKS:
      return dates.length < MAX_DAYS_IN_MONTH
        ? dates.map(date => ({
            value: getISOWeek(date),
            date: format(date, DEFAULT_DATE_FORMAT)
          }))
        : getMonthsForNonMonthAggregation(dates);

    case PERIOD_TYPE.PROMO_WEEKS:
      return dates.length < MAX_DAYS_IN_MONTH
        ? dates.map(date => ({
            value: getWeek(date, { weekStartsOn: PROMO_WEEK_START_DAY }),
            date: format(date, DEFAULT_DATE_FORMAT)
          }))
        : getMonthsForNonMonthAggregation(dates);

    case PERIOD_TYPE.MONTHS:
      return dates.map(date => ({
        value: getPolishMonthName(getMonth(date), true),
        date: format(date, DEFAULT_DATE_FORMAT)
      }));

    case PERIOD_TYPE.YEAR:
      return dates.map(date => ({
        value: getYear(date),
        date: format(date, DEFAULT_DATE_FORMAT)
      }));

    case PERIOD_TYPE.TOTAL:
      return [{ value: "Total", date: format(dates[0], DEFAULT_DATE_FORMAT) }];

    default:
      throw new Error(`"${period}" is not valid location chart period`);
  }
};

export const BAR_RADIUS = {
  SMALL: 5,
  BIG: 12
};

export const mapTimelineByType = (
  type: string,
  timeline: ChartTimePoint[]
): ChartTimePoint[] => {
  switch (type) {
    case DATASET_TYPES.OWN:
      return timeline.map(({ valueX, valueY }) => ({
        valueX,
        valueY: valueY === null ? 0 : valueY
      }));
    case DATASET_TYPES.COMPETITOR:
    case QP.COMPETING_COMPANIES:
    case QP.COMPETING_MATERIALS:
      return timeline.map(({ valueX, valueY }) => ({
        valueX,
        valueY:
          valueY !== null
            ? valueY
            : timeline.length === 1
            ? 0
            : isD28DateSelected(valueX) // hide competitor data for d28 (line chart only)
            ? null
            : 0
      }));
    default:
      return timeline;
  }
};

// [PMD-3885]: change null to zero, but only for the entire length of the historical period
export const mapLastYearTimelineByType = (
  type: string,
  timeline: ChartTimePoint[],
  length: number
): ChartTimePoint[] => {
  const mappedTimeline = mapTimelineByType(type, timeline);
  if (timeline.length === length) return mappedTimeline;
  return mappedTimeline.map((data, index) => ({
    ...data,
    valueY: index > length - 1 ? null : data.valueY
  }));
};
