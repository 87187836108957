import { ChangeEvent } from "react";

import s from "./chartSectionSelect.module.scss";

interface Props {
  id: string;
  label: string;
  value: string;
  options: { value: string; display: string; disabled?: boolean }[];
  onChange: (value: string) => void;
  wrapperId?: string;
}

export const ChartSectionSelect = ({
  id,
  label,
  value,
  options,
  onChange,
  wrapperId
}: Props) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <div id={wrapperId} className={s.wrapper}>
      <label htmlFor={id} className={s.label}>
        {label}
      </label>
      <select
        id={id}
        className={s.select}
        value={value}
        onChange={handleChange}
        data-testid={id}
      >
        {options.map(option => (
          <option
            key={`chart-select-option-${option.value}`}
            value={option.value}
            disabled={option.disabled || false}
          >
            {option.display}
          </option>
        ))}
      </select>
    </div>
  );
};
