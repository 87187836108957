import { ParsedQuery } from "query-string";

import { DISTRIBUTION_METRICS } from "components/molecules/ChartCheckboxDropdown/hooks/useGetDisabledDataTypeTooltip";
import { isAttributesAggregationEnabled } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/AggregationFilters/utils";
import {
  FILTER_NAMES,
  FILTERS
} from "pages/Reports/redux/reducers/filters/hooks/constants";
import { AggregationTypes } from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils/constants";
import {
  AGGREGATED_COMPETITION_ALLOWED_TIER,
  BOOL_STRING_VALUES,
  CHART_DATA_TYPE,
  isThisPage,
  PERIOD_TYPE
} from "utils";
import { QP } from "utils/defaultQueryParams";

const { NONE } = CHART_DATA_TYPE;
const { TRUE, FALSE } = BOOL_STRING_VALUES;
const { LOGISTICS_PATH } = REPORTS_FULL_PATHS;

export const adjustParamsByAggregation = (
  params: ParsedQuery<string | string[]>,
  pathname: string,
  isPowerUser: boolean
) => {
  const product = !params[QP.PRODUCT]
    ? []
    : String(params[QP.PRODUCT]).split(",");
  const secondDataType = String(
    params[QP.CHART_DATA_TYPE_SECOND]
  ).toUpperCase();
  const tier = String(params[QP.TIER]);
  const period = String(params[QP.CHART_PERIOD]).toUpperCase();
  const isLogistics = isThisPage(pathname, LOGISTICS_PATH);

  if (params[QP.AGGREGATED] === TRUE) {
    params[QP.AGGREGATED] = AggregationTypes.total;
  }

  const isAggregated = params[QP.AGGREGATED] === AggregationTypes.total;

  const isOtherThanRanking = period !== PERIOD_TYPE.RANKING;
  const areProductsAggregated =
    !product.length || (product.length > 1 && isAggregated);

  if (
    isOtherThanRanking &&
    areProductsAggregated &&
    // @ts-ignore - no point to compare type of query param to chart data type
    DISTRIBUTION_METRICS.includes(secondDataType) &&
    !isLogistics
  ) {
    params[QP.CHART_DATA_TYPE_SECOND] = NONE;
  }

  if (!isAggregated && tier === String(AGGREGATED_COMPETITION_ALLOWED_TIER)) {
    params[QP.AGGREGATED_COMPETITION] = FALSE;
  }

  // [PMD-4794]: fix attributes aggregation if attributes filter is not visible or not last
  const attributesFilter = FILTERS.find(
    filter => filter.name === FILTER_NAMES.ATTRIBUTES
  );
  const isAttributesFilterEnabled =
    attributesFilter?.page.some(url => isThisPage(pathname, url)) &&
    attributesFilter?.accessLevels.includes(Number(tier));
  const isAttribitueAggregation =
    params[QP.AGGREGATED] === AggregationTypes.attr_group ||
    params[QP.AGGREGATED] === AggregationTypes.attr_total;
  if (isAttributesFilterEnabled && isAttribitueAggregation) {
    const company = String(params[QP.VENDOR_ID] || "");
    const attributes = String(params[QP.ATTRIBUTES] || "");
    const brands = String(params[QP.BRANDS] || "");
    const subBrands = String(params[QP.SUB_BRANDS] || "");
    const comp = {
      vendors: String(params[QP.COMPETING_COMPANIES] || ""),
      products: String(params[QP.COMPETING_MATERIALS] || ""),
      brands: String(params[QP.COMPETING_BRANDS] || ""),
      subBrands: String(params[QP.COMPETING_SUB_BRANDS] || "")
    };
    if (
      !isAttributesAggregationEnabled({
        isPowerUser,
        filtersState: {
          company,
          attributes,
          brands,
          subBrands,
          products: product.join(","),
          competingCompanies: comp.vendors,
          competingProducts: comp.products,
          competingBrands: comp.brands,
          competingSubBrands: comp.subBrands
        }
      })
    ) {
      params[QP.AGGREGATED] = AggregationTypes.false;
    }
  }

  return params;
};
