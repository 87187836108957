import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { useGetDynamicsByPeriod } from "api/dynamics/mappers/useGetDynamicsByPeriod";
import { useGetHours } from "api/hours/hooks";
import { useGetLocationByPeriod } from "api/location/mappers/useGetLocationByPeriod";
import { useGetLoyaltyByPeriod } from "api/loyalty/mappers/useGetLoyaltyByPeriod";
import { useGetMultipackByPeriod } from "api/multipack/mappers";
import { useGetReceiptsByPeriod } from "api/receipts/mappers/useGetReceiptsByPeriod";
import { useGetSellsByPeriod } from "api/sells/mappers/useGetSellsByPeriod";
import { useGetSharesByPeriod } from "api/shares/mappers";
import { isEmpty } from "ramda";

import { REPORTS_FULL_PATHS, SUMMARY_SWITCH } from "pages/Reports/utils";
import {
  brandQueryParamSelector,
  categoriesSelector,
  productsQueryParamSelector,
  subBrandQueryParamSelector,
  uppercasedLocationSummaryTabSelector,
  uppercasedSellSummaryTabSelector
} from "store/selectors/routerSelectors";
import { toArray } from "store/utils";
import { isThisPage, WARNINGS } from "utils";
import { Nullable } from "utils/types";

const {
  SELL_PATH,
  SHARES_PATH,
  LOCATION_PATH,
  DYNAMICS_PATH,
  RECEIPTS_PATH,
  HOURS_PATH,
  LOYALTY_PATH,
  MULTIPACK_PATH
} = REPORTS_FULL_PATHS;

const filterEmpty = (array: string[]) => array.filter(value => value !== "");

const getLower = ({ higher, lower }: { higher: string[]; lower: string[] }) =>
  isEmpty(lower) ? higher : lower;

const useDoubleReceiptsWarning = () => {
  const { category2, category3 } = useSelector(categoriesSelector);
  const products = toArray(useSelector(productsQueryParamSelector));

  const showDoubleReceiptsWarning = [
    getLower({ higher: category2, lower: category3 }),
    products
  ].some(filters => filters.length > 1);

  return showDoubleReceiptsWarning ? WARNINGS.DOUBLED_RECEIPTS : "";
};

const useMaxShopCountWarning = () => {
  const sellSummaryTab = useSelector(uppercasedSellSummaryTabSelector);
  const locationSummaryTab = useSelector(uppercasedLocationSummaryTabSelector);
  const { category2, category3 } = useSelector(categoriesSelector);
  const brands = toArray(useSelector(brandQueryParamSelector));
  const subBrands = toArray(useSelector(subBrandQueryParamSelector));
  const products = toArray(useSelector(productsQueryParamSelector));

  const isPerShopSummaryTab = [sellSummaryTab, locationSummaryTab].includes(
    SUMMARY_SWITCH.PER_SHOP
  );
  const isMoreThanOneFilterItem = [
    getLower({ higher: category2, lower: category3 }),
    getLower({ higher: brands, lower: subBrands }),
    products
  ].some(filters => filters.length > 1);

  return isPerShopSummaryTab && isMoreThanOneFilterItem
    ? WARNINGS.MAX_SHOP_COUNT
    : "";
};

export const useSummaryWarning = (): Nullable<string[]> => {
  const { pathname } = useLocation();
  const maxShopCountWarning = useMaxShopCountWarning();
  const doubleReceiptsWarning = useDoubleReceiptsWarning();

  const sellDataInfo = useGetSellsByPeriod().data?.data.info || [];
  const locationDataInfo = useGetLocationByPeriod().data?.data.info || [];
  const sharesDataInfo = useGetSharesByPeriod().data?.info || [];
  const dynamicsDataInfo = useGetDynamicsByPeriod().data?.info || [];
  const receiptsDataInfo = useGetReceiptsByPeriod().data?.info || [];
  const hoursDataInfo = useGetHours().data?.info || [];
  const loyaltyDataInfo = useGetLoyaltyByPeriod().data?.info || [];
  const multipackDataInfo = useGetMultipackByPeriod().data?.data.info || [];

  if (isThisPage(pathname, SELL_PATH)) {
    return filterEmpty([...sellDataInfo, maxShopCountWarning]);
  }

  if (isThisPage(pathname, LOCATION_PATH)) {
    return filterEmpty([...locationDataInfo, maxShopCountWarning]);
  }

  if (isThisPage(pathname, SHARES_PATH)) {
    return sharesDataInfo;
  }

  if (isThisPage(pathname, DYNAMICS_PATH)) {
    return dynamicsDataInfo;
  }

  if (isThisPage(pathname, HOURS_PATH)) {
    return hoursDataInfo;
  }

  if (isThisPage(pathname, LOYALTY_PATH)) {
    return loyaltyDataInfo;
  }

  if (isThisPage(pathname, MULTIPACK_PATH)) {
    return multipackDataInfo;
  }

  if (isThisPage(pathname, RECEIPTS_PATH)) {
    //receipts don't need ranking warnings as they come from getData()
    return filterEmpty([...receiptsDataInfo, doubleReceiptsWarning]);
  }

  return null;
};
