import qs, { ParsedQuery } from "query-string";
import { omit } from "ramda";

import { toArray } from "store/utils";
import { BOOL_STRING_VALUES } from "utils";

import { filterEmptyParameters } from "./";
import { CLIENT_TYPES, isReferenceBrandsFilterEnabled } from "./constants";
import { QP } from "./defaultQueryParams";
import { isValidPeriod } from "./isValidPeriod";

type Category = string | string[] | null | number[];

export const getCategoriesAndLevel = (
  category1?: Category,
  category2?: Category,
  category3?: Category
): [string, string] => {
  if (category3?.length) {
    return [String(category3), "3"];
  }
  if (category2?.length) {
    return [String(category2), "2"];
  }
  if (category1?.length) {
    return [String(category1), "1"];
  }
  return ["", ""];
};

export const translateFeParamsToBeParams = (
  query: string,
  period: string = "",
  additionalParams?: ParsedQuery
) => {
  const inputQueryParameters = qs.parse(query, { arrayFormat: "comma" });

  const {
    category1,
    category2,
    category3,
    attributes,
    product,
    client,
    competing_companies = [],
    competing_materials = [],
    delay,
    vendor_id,
    brands,
    sub_brands,
    competing_brands,
    competing_sub_brands,
    ref_cat1,
    ref_cat2,
    ref_cat3,
    ref_brand,
    ref_subbrand,
    ref_brand_aggregated,
    ref_cat_aggregated,
    compare_date_from,
    compare_date_to,
    include_leap_year,
    group_by_segments,
    ...outputQueryParameters
  } = filterEmptyParameters(inputQueryParameters);

  if (competing_materials?.length) {
    Object.assign(outputQueryParameters, {
      competing_materials: toArray(competing_materials)
    });
  } else if (competing_companies?.length) {
    Object.assign(outputQueryParameters, {
      competing_companies: toArray(competing_companies)
    });
  }

  if (category1 || category2 || category3) {
    const [categories, level] = getCategoriesAndLevel(
      category1,
      category2,
      category3
    );

    Object.assign(outputQueryParameters, {
      categories,
      category_level: level
    });
  }

  if (attributes) {
    Object.assign(outputQueryParameters, { attributes });
  }

  if (vendor_id) {
    Object.assign(outputQueryParameters, { vendor_id });
  }

  if (brands) {
    Object.assign(outputQueryParameters, { brands });
  }

  if (sub_brands) {
    Object.assign(outputQueryParameters, { sub_brands });
  }

  if (compare_date_from) {
    Object.assign(outputQueryParameters, { compare_date_from });
  }

  if (compare_date_to) {
    Object.assign(outputQueryParameters, { compare_date_to });
  }

  if (include_leap_year) {
    Object.assign(outputQueryParameters, {
      include_leap_year_day: include_leap_year
    });
  }

  if (competing_brands) {
    Object.assign(outputQueryParameters, { competing_brands });
  }

  if (competing_sub_brands) {
    Object.assign(outputQueryParameters, { competing_sub_brands });
  }

  if (product) {
    Object.assign(outputQueryParameters, { materials: product });
  }

  // if both options are chosen (client query param === "all")
  // effect is the same as if the client was undefined so we may
  // skip putting it to BE params in this case
  if (client !== CLIENT_TYPES.ALL) {
    Object.assign(outputQueryParameters, { client_type: client });
  }

  if (delay === BOOL_STRING_VALUES.TRUE || delay === BOOL_STRING_VALUES.FALSE) {
    Object.assign(outputQueryParameters, { competitor_delay: delay });
  }

  if (isValidPeriod(period)) {
    Object.assign(outputQueryParameters, {
      timeline_unit: period.toLowerCase()
    });
  }

  if (ref_cat1 || ref_cat2 || ref_cat3) {
    const [
      reference_categories,
      reference_category_level
    ] = getCategoriesAndLevel(ref_cat1, ref_cat2, ref_cat3);

    Object.assign(outputQueryParameters, {
      reference_categories,
      reference_category_level
    });
  }

  if (ref_brand) {
    Object.assign(outputQueryParameters, {
      reference_brands: ref_brand
    });
  }

  if (ref_subbrand) {
    Object.assign(outputQueryParameters, {
      reference_sub_brands: ref_subbrand
    });
  }

  if (isReferenceBrandsFilterEnabled && ref_brand_aggregated) {
    Object.assign(outputQueryParameters, {
      reference_brands_aggregated: ref_brand_aggregated
    });
  }

  if (ref_cat_aggregated) {
    Object.assign(outputQueryParameters, {
      reference_categories_aggregated: ref_cat_aggregated
    });
  }

  if (group_by_segments) {
    Object.assign(outputQueryParameters, { group_by_segments });
  }

  const backendUnrelatedParams = [
    QP.CHART_DATA_TYPE_FIRST,
    QP.CHART_DATA_TYPE_SECOND,
    QP.SELL_SUMMARY_TYPE,
    QP.LOYALTY_SUMMARY_TYPE,
    QP.LOCATION_SUMMARY_TYPE,
    QP.SHARES_SUMMARY_TYPE,
    QP.DYNAMICS_SUMMARY_TYPE,
    QP.RECEIPTS_SUMMARY_TYPE,
    QP.MULTIPACK_SUMMARY_TYPE,
    QP.SUMMARY_SUB_TYPE,
    QP.PROMOTIONS_CHART_DATA_TYPE,
    QP.SIDEBAR_CHANGE,
    QP.SIDEBAR_DESC,
    QP.SIDEBAR_SORT_BY,
    QP.SIDEBAR_SORT_TYPE,
    QP.SIDEBAR_METRIC,
    QP.TABLE_PERIOD,
    QP.SORT_RANKING,
    QP.HIDE_5_RANK,
    QP.EXPORT_DATA_TYPE,
    QP.CHART_PERIOD,
    QP.ACTIVE_INSIGHT,
    QP.REPORT_TYPE,
    QP.SENTIMENT,
    QP.CHART_TYPE,
    QP.HIDE_TILES,
    QP.ZAPPKA_SHARES_FIRST,
    QP.ZAPPKA_SHARES_SECOND,
    QP.ZAPPKA_TRANSACTION,
    QP.ZAPPKA_SUMMARY_TYPE,
    QP.SHOW_DATASET,
    QP.FULL_SCREEN
  ];

  const onlyFetchRelatedParams = omit(
    backendUnrelatedParams,
    outputQueryParameters
  );

  return qs.stringify(
    { ...onlyFetchRelatedParams, ...additionalParams },
    { arrayFormat: "comma" }
  );
};
