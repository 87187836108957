import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";
import qs from "query-string";

import { SpinnerLoader, Text } from "components/atoms";
import { Search } from "components/atoms/Icon";
import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import s from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/CompetitorsFilters/competitorsFilters.module.scss";
import {
  DROPDOWN_LABELS,
  DROPDOWN_PLACEHOLDERS
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/constants";
import { FilterErrorMsg } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/FilterErrorMsg/FilterErrorMsg";
import {
  filterItems,
  isActiveFn
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import {
  asyncFiltersSelector,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useCompetitionCompanyBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useCompetitorCompanyFetching";
import {
  CompetingCompaniesHooks,
  DropdownItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useCompetingCompanies";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

type Props = {
  isDisabled: boolean;
};

export const CompetitionCompanyDropdown = ({ isDisabled }: Props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(asyncFiltersSelector).fetching
    .competitorCompanies;
  const errorMsg = useSelector(asyncFiltersSelector).error.competitorCompanies;
  const [state, actions] = CompetingCompaniesHooks.useCompetingCompanies();
  const [selectedCountLabel] = CompetingCompaniesHooks.useSelectedCountLabel();

  useCompetitionCompanyBehaviour(false);

  const [isOpen, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const handleChange = (item: DropdownItem) => {
    setOpen(true);
    const found = state.selected.find(i => i.value === item.value);
    if (found) {
      actions.updateSelected(
        state.selected.filter(i => i.value !== item.value)
      );
    } else {
      actions.updateSelected([...state.selected, item]);
    }
  };

  const handleInputValueChange = (value: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (selectedItem?: DropdownItem) => {
    setOpen(false);
    if (!selectedItem) {
      actions.updateAll([
        // sort selected items at first
        ...state.selected.sort((a, b) => a.label.localeCompare(b.label)),
        // then sort all other unselected items
        ...state.all
          .filter(item => !state.selected.some(i => i.value === item.value))
          .sort((a, b) => a.label.localeCompare(b.label))
      ]);
      setInputValue(selectedCountLabel);
    }
  };

  const handleStateChange = (changes: StateChangeOptions<DropdownItem[]>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleSelectAll = () => {
    actions.updateSelected(state.all);
    pushFiltersEvent("suppliers - select all");
  };

  const handleClear = () => {
    actions.updateSelected([]);
    pushFiltersEvent("suppliers - clear");
  };

  const filterCompanyByInput = (inputValue: string) => (item: DropdownItem) => {
    const isItemSelected = state.selected.some(
      ({ value }) => value === item.value
    );

    //always show selected item
    if (isItemSelected) {
      return true;
    }

    const competitionCompanyMatchInputValue = filterItems<DropdownItem>(
      inputValue
    )(item);

    return competitionCompanyMatchInputValue;
  };

  React.useEffect(() => {
    if (!isOpen) setInputValue(selectedCountLabel);
  }, [isOpen, selectedCountLabel]);

  if (!isLoading && errorMsg) {
    return (
      <FilterErrorMsg
        label={DROPDOWN_LABELS.COMPANY}
        callback={() => {
          dispatch(
            updateQuery({
              filter: "competitorCompanies",
              query: qs.stringify({})
            })
          );
        }}
        errorMsg={errorMsg}
      />
    );
  }

  return (
    <Dropdown<DropdownItem[]>
      className={s.dropdownWrapper}
      isDisabled={isLoading || isDisabled}
      isMultiChoice
      // @ts-ignore
      itemToString={() => {}}
      selectedItem={state.selected}
      items={state.all}
      label={DROPDOWN_LABELS.COMPANY}
      openedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.COMPANY.OPEN
      }
      closedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.COMPANY.CLOSED
      }
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      // @ts-ignore
      onChange={handleChange}
      onClear={handleClear}
      onStateChange={handleStateChange}
      onSelectAll={handleSelectAll}
      input={
        <FilterDropdownInput
          customIcon={isLoading ? <SpinnerLoader size="small" /> : <Search />}
          isTypingEnabled
          automaticInputValue={selectedCountLabel}
          testId="competition-company-dropdown"
        />
      }
      menu={
        <FilterDropdownMenu<DropdownItem>
          isItemActiveFn={isActiveFn}
          filterItemsFn={filterCompanyByInput}
          listItem={<FilterDropdownListItem />}
          minimumItemsToVirtualize={30}
          inlinePlaceholder={
            isLoading && (
              <Text className={s.inlinePlaceholder}>Pobieranie danych</Text>
            )
          }
        />
      }
      onOuterClick={() => setOpen(false)}
    />
  );
};
