import { useSelector } from "react-redux";

import { useGetDynamicsByPeriod } from "api/dynamics/mappers";
import { useGetLoyaltyByPeriod } from "api/loyalty/mappers";
import { useGetMultipackByPeriod } from "api/multipack/mappers";
import { useGetReceiptsByPeriod } from "api/receipts/mappers";
import { sortByPrefix } from "api/segments/utils/sortSegments";
import { useGetSellsByPeriod } from "api/sells/mappers/useGetSellsByPeriod";
import { useGetSharesByPeriod } from "api/shares/mappers";

import { getLegendItemColor } from "pages/Reports/sections/utils/color";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { DATASET_TYPES } from "utils";

const ORDER = Object.values(DATASET_TYPES);

export const useLegendReportData = () => {
  const pathname = useSelector(pathnameSelector);

  let data = null;
  const { data: sales } = useGetSellsByPeriod();
  const salesData = sales?.data?.data;

  const { data: shares } = useGetSharesByPeriod();
  const sharesData = shares?.currentPeriod;

  const { data: dynamics } = useGetDynamicsByPeriod();
  const dynamicsData = dynamics?.currentPeriod;

  const { data: receipts } = useGetReceiptsByPeriod();
  const receiptsData = receipts?.currentPeriod;

  const { data: loyalty } = useGetLoyaltyByPeriod();
  const loyaltyData = loyalty?.currentPeriod;

  const { data: multipack } = useGetMultipackByPeriod();
  const multipackData = multipack?.data?.data;

  const {
    SELL_PATH,
    DYNAMICS_PATH,
    SHARES_PATH,
    RECEIPTS_PATH,
    LOYALTY_PATH,
    MULTIPACK_PATH
  } = REPORTS_FULL_PATHS;

  const isSellPage = pathname.includes(SELL_PATH);
  const isSharesPage = pathname.includes(SHARES_PATH);
  const isDynamicsPage = pathname.includes(DYNAMICS_PATH);
  const isReceiptsPage = pathname.includes(RECEIPTS_PATH);
  const isLoyaltyPage = pathname.includes(LOYALTY_PATH);
  const isMultipackPage = pathname.includes(MULTIPACK_PATH);

  if (isSellPage) data = salesData;
  if (isSharesPage) data = sharesData;
  if (isDynamicsPage) data = dynamicsData;
  if (isReceiptsPage) data = receiptsData;
  if (isLoyaltyPage) data = loyaltyData;
  if (isMultipackPage) data = multipackData;

  if (!data) return [];

  return data
    .sort((a, b) => sortByPrefix(a.type, b.type, ORDER))
    .map((item, index) => ({
      ...item,
      color: getLegendItemColor({
        vendorId: item.vendorId,
        resultId: item.resultId,
        index
      })
    }));
};
