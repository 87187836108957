import { format } from "date-fns";
import { omit } from "ramda";

import {
  SharesDataApi,
  SharesNormalizedResponse,
  SharesTimeline
} from "pages/Reports/sections/Shares/redux/types";
import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import {
  ExistingSharesMetrics,
  SharesMetrics,
  SharesMetricsApi
} from "pages/Reports/types/metrics";
import { newDateByTimezone } from "store/utils";
import { TimelineApi } from "store/utils/types";
import { DEFAULT_DATE_FORMAT } from "utils/constants";

import { snake2camel } from "../snake2camel";

export const normalizeSharesResponse = (
  data: SharesDataApi[]
): SharesNormalizedResponse[] =>
  data.map(data => {
    const vendorId = data.vendor_id || "";
    const resultId = data.result_id || "";
    const type = data.type;
    const name = getChartLabel({ vendorId, resultId, type });

    return {
      vendorId,
      resultId,
      name,
      type,
      timeline: data.timeline.map(standarizeDataPoint),
      existingMetrics: Object.keys(data.existing_metrics).reduce(
        (acc, key) => ({
          ...acc,
          [snake2camel(key)]:
            data.existing_metrics[key as keyof SharesMetricsApi] || false
        }),
        {}
      ) as ExistingSharesMetrics
    };
  });

const standarizeDataPoint = (
  dataPoint: TimelineApi<SharesMetricsApi>
): SharesTimeline => ({
  date: format(
    newDateByTimezone(new Date(dataPoint.date || Date.now())),
    DEFAULT_DATE_FORMAT
  ),
  dateLastYear: dataPoint.date_last_year
    ? format(
        newDateByTimezone(new Date(dataPoint.date_last_year)),
        DEFAULT_DATE_FORMAT
      )
    : null,
  unit: dataPoint.unit?.toLowerCase() || "",
  ...(Object.keys(omit(["date", "date_last_year", "unit"], dataPoint)).reduce(
    (acc, key) => ({
      ...acc,
      [snake2camel(key)]: dataPoint[key as keyof SharesMetricsApi] ?? null
    }),
    {}
  ) as SharesMetrics)
});
