import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "components/molecules";
import { updateQueryParams } from "store/actions/routerActions";
import { isRanking5DistributionHiddenSelector } from "store/selectors/routerSelectors";
import { QP } from "utils/defaultQueryParams";

import s from "./hideLowRankingCheckbox.module.scss";

export const HideLowRankingCheckbox: FC = () => {
  const dispatch = useDispatch();
  const is5RankingDistributionHidden = useSelector(
    isRanking5DistributionHiddenSelector
  );

  const onChange = () => {
    dispatch(
      updateQueryParams({
        [QP.HIDE_5_RANK]: String(!is5RankingDistributionHidden)
      })
    );
  };

  return (
    <Checkbox
      className={{ base: s.base, checkbox: s.checkbox, label: s.label }}
      label="Ukryj produkty z dystrybucją <5%"
      onChange={onChange}
      isChecked={is5RankingDistributionHidden}
    />
  );
};
