import * as React from "react";
import { Manager, Popper, Reference } from "react-popper";

import cn from "classnames";
import Downshift from "downshift";
import { equals } from "ramda";

import { Portal } from "components/atoms";
import { MENU_PLACEMENTS } from "components/molecules/constants";
import { DropdownItem, DropdownProps } from "components/molecules/types";
import { usePrevious } from "hooks";

import s from "./dropdown.module.scss";

export function Dropdown<T>({
  className,
  items = [],
  menu,
  input,
  onInputKeyDown,
  label,
  isDisabled,
  openedMenuPlaceholder,
  closedMenuPlaceholder,
  isMultiChoice,
  isMultiChoiceSplitted,
  onChange,
  onSelect,
  onInputValueChange,
  onSelectAll,
  onSelectAllActive,
  onSelectAllInactive,
  menuPlacement = MENU_PLACEMENTS.BOTTOM_START,
  withPortal = true,
  onClear,
  ...downShiftProps
}: DropdownProps<T>) {
  const previousItems = usePrevious<DropdownItem[]>(items);

  return (
    <Downshift
      itemToString={(item: any) => (item ? item.label : "")}
      onChange={onChange}
      onSelect={onSelect}
      onInputValueChange={onInputValueChange}
      {...downShiftProps}
    >
      {({
        getLabelProps,
        getInputProps,
        getMenuProps,
        getItemProps,
        inputValue,
        isOpen,
        getToggleButtonProps,
        selectedItem,
        selectItem,
        closeMenu,
        openMenu,
        highlightedIndex
      }) => {
        const clonedMenu = React.cloneElement(menu, {
          ...menu.props,
          getMenuProps,
          getItemProps,
          selectedItem,
          inputValue,
          items,
          isMultiChoice,
          isMultiChoiceSplitted,
          onChange,
          onSelectAll,
          onSelectAllActive,
          onSelectAllInactive,
          onClear
        });

        const menuWithPopper =
          menuPlacement !== MENU_PLACEMENTS.FIXED ? (
            <Popper
              placement={menuPlacement}
              modifiers={{
                preventOverflow: {
                  enabled: true,
                  boundariesElement: "viewport"
                },
                flip: {
                  enabled: true,
                  boundariesElement: "scrollParent"
                }
              }}
            >
              {({ ref, style, placement, scheduleUpdate }) => {
                const hasItemsChanged = !equals(items, previousItems);

                if (hasItemsChanged) {
                  scheduleUpdate();
                }

                return (
                  <div
                    ref={ref}
                    style={style}
                    className={cn(s.menuWrapper, {
                      [s.menuWrapperTop]: placement !== menuPlacement
                    })}
                    data-testid="downshift-wrapper"
                  >
                    {clonedMenu}
                  </div>
                );
              }}
            </Popper>
          ) : null;

        return (
          <div className={cn(className, s.base)}>
            <Manager>
              <Reference>
                {({ ref }) => (
                  <div ref={ref}>
                    {React.cloneElement(input, {
                      ...input.props,
                      getLabelProps,
                      getInputProps,
                      getToggleButtonProps,
                      inputValue,
                      selectItem,
                      label,
                      isDisabled,
                      openedMenuPlaceholder,
                      closedMenuPlaceholder,
                      isOpen,
                      onInputValueChange,
                      openMenu,
                      closeMenu,
                      onSelectAll,
                      highlightedIndex
                    })}
                  </div>
                )}
              </Reference>

              {menuPlacement === MENU_PLACEMENTS.FIXED && isOpen && (
                <Portal id="js-dropdown-menu">{clonedMenu}</Portal>
              )}

              {menuPlacement !== MENU_PLACEMENTS.FIXED &&
                isOpen &&
                !withPortal &&
                menuWithPopper}

              {menuPlacement !== MENU_PLACEMENTS.FIXED &&
                isOpen &&
                withPortal && (
                  <Portal id="js-dropdown-menu">{menuWithPopper}</Portal>
                )}
            </Manager>
          </div>
        );
      }}
    </Downshift>
  );
}
