import React from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import { DropdownItem } from "components/molecules/types";
import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import {
  ShopTypeApi,
  ShopTypeHooks,
  sortBySpecificOrder
} from "pages/Reports/redux/reducers/sweetStateHooks/useShopType";
import { shopTypesQueryParamsSelector } from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";

const fetchShopTypes = async () => {
  const response = await HTTP.get<ShopTypeApi[]>(
    REST_API_ENDPOINTS.STORE_TYPES
  );

  const dropdownItems = response.data.map(item => ({
    value: item.id || "",
    label: `${item.name || ""} (${item.store_count || 0})` || ""
  }));
  const storeCountData = response.data.map(item => ({
    value: item.id || "",
    count: item.store_count || 0
  }));

  return { dropdownItems, storeCountData };
};

const useFetchingFlag = (params: {}, isDisabled: boolean) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching.shopTypes;
  const lastQuery = useSelector(asyncFiltersSelector).query.shopTypes;

  const query = qs.stringify(params);
  if (isLoading || isDisabled) {
    return false;
  }

  if (query === lastQuery) {
    return false;
  }

  return true;
};

export const useShopTypeFetching = (isDisabled: boolean) => {
  const filter = "shopTypes";
  const [, actions] = ShopTypeHooks.useShopType();
  const dispatch = useDispatch();
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const shopTypeQP = useSelector(shopTypesQueryParamsSelector);

  const isFetchingPossible = useFetchingFlag({}, isDisabled);

  const callback = React.useCallback(
    (data: {
      dropdownItems: DropdownItem<string>[];
      storeCountData: { value: string; count: number }[];
    }) => {
      const allShopTypesInOrder = sortBySpecificOrder(data.dropdownItems);
      actions.updateAllShopTypes(allShopTypesInOrder);
      actions.updateStoreCountData(data.storeCountData);

      if (isPristine) {
        actions.checkPristineSelectionsAfterFetching(
          data.dropdownItems,
          shopTypeQP
        );
      }
      actions.checkSelectionsAfterFetching(
        data.dropdownItems.map(shop => shop.value)
      );
    },
    [isPristine, actions, shopTypeQP]
  );
  React.useEffect(() => {
    if (!isFetchingPossible) {
      return;
    }

    const fetch = async () => {
      try {
        dispatch(updateQuery({ filter, query: qs.stringify({}) }));

        dispatch(setFlag({ filter, isFetching: true }));
        const shopTypes = await fetchShopTypes();
        dispatch(setError({ filter, status: "" }));

        return shopTypes;
      } catch (e) {
        dispatch(setError({ filter, status: "Błąd pobierania typów sklepów" }));
        return { dropdownItems: [], storeCountData: [] };
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };
    fetch().then(callback);
  }, [dispatch, isFetchingPossible, callback]);
};
