import { useCallback, useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { HeaderColumn, useFlexLayout, useSortBy, useTable } from "react-table";
import { VariableSizeList as List } from "react-window";

import cn from "classnames";

import { NiceScrollbar } from "components/atoms";
import { ROW_HEIGHT } from "components/molecules/constants";
import { TableProps } from "components/types";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage } from "utils";

import { MeasureRow } from "./MeasureRow";
import s from "./virtualizedTable.module.scss";

const { LOGISTICS_PATH } = REPORTS_FULL_PATHS;

export function VirtualizedTable<Data>({
  className = {},
  renderHeader,
  renderCell,
  columns,
  defaultColumn,
  testId,
  initialState,
  listClassName,
  customTableHeight,
  verticalScrollPosition,
  minColumnWidth,
  ...tableOptions
}: TableProps<Data>) {
  const virtualizedListRef = useRef<List>(null);

  const pathname = useSelector(pathnameSelector);
  const isLogisticsPage = isThisPage(pathname, LOGISTICS_PATH);

  const standardColumn = useMemo(
    () =>
      defaultColumn || {
        width: `calc(100%/${columns.length})`
      },
    [columns.length, defaultColumn]
  );

  const updatedHeaderColumns = columns.map(
    (
      { width, ...column }: HeaderColumn<Data, never>,
      _: number,
      columns: HeaderColumn<Data, never>[]
    ) => ({
      ...column,
      width: width || `calc(100%/${columns.length})`
    })
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable<Data>(
    {
      columns,
      defaultColumn: standardColumn,
      initialState,
      ...tableOptions
    },
    useSortBy,
    useFlexLayout
  );

  const rowSizeMap = useRef<{ [index: number]: number }>({});
  const setRowSize = useCallback((index, size) => {
    rowSizeMap.current = { ...rowSizeMap.current, [index]: size };
    virtualizedListRef.current?.resetAfterIndex(index, true);
  }, []);
  const getRowSize = useCallback(
    index => rowSizeMap.current[index] || ROW_HEIGHT,
    []
  );

  const renderRow = useCallback(
    (index: number) => {
      const row = rows[index];
      const isEven = index % 2 === 0;
      prepareRow(row);

      const segmentValue = row.original.segment;

      const isTotalSegmentRow = isLogisticsPage && segmentValue === "TOTAL";
      const isSegmentedLogisticsData =
        isLogisticsPage && segmentValue !== "TOTAL" && segmentValue !== "";

      return (
        <div
          {...row.getRowProps({
            className: cn(
              s.tableRow,
              {
                [s.even]: isEven,
                [s.totalSegmentRow]: isTotalSegmentRow,
                [s.segmentRow]: isSegmentedLogisticsData
              },
              className.tableRow
            ),
            style: {
              width: "100%",
              minHeight: ROW_HEIGHT,
              maxHeight: ROW_HEIGHT
            },
            "data-testid": `${testId}-body-row-${index}`
          })}
        >
          {row.cells.map(cell => (
            <div
              {...cell.getCellProps({
                className: cn(s.tableCell, className.tableCell)
              })}
              // overriding style injected by react-table
              style={{
                ...cell.getCellProps().style,
                width: cell.column.width || 10
              }}
            >
              {renderCell ? renderCell(cell) : cell.render("Cell")}
            </div>
          ))}
        </div>
      );
    },
    [
      rows,
      prepareRow,
      className.tableRow,
      className.tableCell,
      testId,
      renderCell,
      isLogisticsPage
    ]
  );

  useEffect(() => {
    if (virtualizedListRef.current) {
      const el = ReactDOM.findDOMNode(virtualizedListRef.current);
      const child = el?.firstChild;
      if (child) {
        // @ts-ignore Property 'setAttribute' does not exist on type 'ChildNode'
        child?.setAttribute("data-ga-table", "Scroll");
      }
    }
  }, [virtualizedListRef]);

  return (
    <div
      {...getTableProps({
        className: cn(s.table, className.table)
      })}
      style={{ minWidth: "100%" }}
      data-testid={testId}
    >
      <div
        data-testid={`${testId}-head`}
        className={cn(s.tableHead, className.tableHead)}
      >
        {headerGroups.map(({ getHeaderGroupProps, headers }, i) => (
          <div
            {...getHeaderGroupProps({
              className: cn(s.tableHeaderRow, className.tableHeaderRow),
              "data-testid": `${testId}-head-row-${i}`
            })}
          >
            {headers.map((header, i) => (
              <div
                {...header.getHeaderProps({
                  className: cn(s.tableHeaderCell, className.tableHeader)
                })}
                // overriding style injected by react-table
                style={{
                  ...header.getHeaderProps().style,
                  width: updatedHeaderColumns[i].width,
                  minColumnWidth,
                  minWidth: updatedHeaderColumns[i].minWidth
                }}
              >
                {renderHeader ? renderHeader(header) : header.render("Header")}
              </div>
            ))}
          </div>
        ))}
      </div>

      <NiceScrollbar
        autoHeight
        autoHeightMin={customTableHeight || 0}
        hideHorizontal
        verticalScrollPosition={verticalScrollPosition}
      >
        <div
          {...getTableBodyProps({
            className: cn(s.tableBody, className.tableBody),
            "data-testid": `${testId}-body`
          })}
        >
          <List
            ref={virtualizedListRef}
            height={rows.length * ROW_HEIGHT}
            width="100%"
            itemCount={rows.length}
            itemSize={getRowSize}
            className={cn(s.listWrapper, listClassName)}
          >
            {({ index, style }) => (
              <div className={s.tableRowListWrapper} style={style}>
                <MeasureRow index={index} setRowSize={setRowSize}>
                  {renderRow(index)}
                </MeasureRow>
              </div>
            )}
          </List>
        </div>
      </NiceScrollbar>
    </div>
  );
}
