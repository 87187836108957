import { ParsedQuery } from "query-string";

import { BACK_DATA_REPORTS } from "components/molecules/DateDropdownMenu/HistoricalSection/selectors";
import { BOOL_STRING_VALUES, isThisPage } from "utils";
import { QP } from "utils/defaultQueryParams";

// [PMD-4530]: disable lfl when back data is selected
export const adjustLflParams = (
  params: ParsedQuery<string>,
  pathname: string
) => {
  const isLflEnabled = params[QP.LFL] === BOOL_STRING_VALUES.TRUE;
  const isBackDataEnabled =
    BACK_DATA_REPORTS.some(url => isThisPage(pathname, url)) &&
    [params[QP.COMPARE_DATE_FROM], params[QP.COMPARE_DATE_TO]].every(Boolean);

  if (isLflEnabled && isBackDataEnabled) {
    return {
      ...params,
      [QP.LFL]: BOOL_STRING_VALUES.FALSE
    };
  }

  return params;
};
