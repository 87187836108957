import OutsideClickHandler from "react-outside-click-handler";

import LoyaltyExampleSrc from "assets/images/loyalty_example.png";
import cn from "classnames";

import { Heading, Portal, Text } from "components/atoms";
import { Close } from "components/atoms/Icon";
import {
  Card,
  PopupWindow,
  TableCell,
  TableHeader
} from "components/molecules";
import { Table } from "components/organisms/Table/Table";
import { HEADER_TYPES, ICON_SIZES, TEXT_WEIGHTS, TEXT_WRAPPERS } from "utils";

import {
  METRICS_DETAILS_COLUMNS,
  MetricsDetailsTableMetrics,
  TABLE_DATA
} from "./constants";
import s from "./metricsDetailsModal.module.scss";

interface Props {
  onClose: () => void;
}

export const MetricsDetailsModal = ({ onClose }: Props) => {
  return (
    <Portal id="metrics-details-modal">
      <PopupWindow>
        <OutsideClickHandler onOutsideClick={onClose}>
          <Card className={{ base: s.popupWrapper }}>
            <>
              <div className={s.topLineWraper}>
                <button
                  className={s.closeButton}
                  type="button"
                  onClick={onClose}
                >
                  <Close size={ICON_SIZES.EXTRA_SMALL} />
                </button>
              </div>
              <Heading>Raport Lojalność - najważniejsze założenia</Heading>
              <Text className={s.paragraph}>
                W celu jak najlepszego zrozumienia Raportu Lojalność zachęcamy
                do zapoznania się z poniższą interpretacją konkretnego
                przykładu.
              </Text>
              <Text className={s.paragraph}>
                Na początek ważne jest to, aby pamiętać, że{" "}
                <Text Wrapper={TEXT_WRAPPERS.SPAN} className={s.bold}>
                  dane zawarte w Raporcie Lojalność są danymi na podstawie
                  płatności kartą płatniczą.
                </Text>{" "}
                To oznacza, że liczymy pierwszy zakup klienta i jego powrót do
                danego produktu pod warunkiem, iż zapłaci za obydwa zakupy tą
                samą kartą płatniczą.
              </Text>
              <Heading
                className={cn(s.paragraph, s.underline)}
                type={HEADER_TYPES.H5}
              >
                Najważniejsze założenia oraz krótki opis poszczególnych miar:
              </Heading>
              <ul className={s.paragraph}>
                <li>
                  <Text>
                    <Text
                      Wrapper={TEXT_WRAPPERS.SPAN}
                      weight={TEXT_WEIGHTS.BOLD}
                    >
                      1. Transakcje
                    </Text>{" "}
                    - transakcje dokonane kartą płatniczą
                  </Text>
                </li>
                <li>
                  <Text>
                    <Text
                      Wrapper={TEXT_WRAPPERS.SPAN}
                      weight={TEXT_WEIGHTS.BOLD}
                    >
                      2. Nowy klient{" "}
                    </Text>{" "}
                    – klient, który dokonał zakupu przy użyciu karty płatniczej,
                    konkretnego produktu w danymi dniu po raz pierwszy od 84 dni
                  </Text>
                </li>
                <li>
                  <Text>
                    <Text
                      Wrapper={TEXT_WRAPPERS.SPAN}
                      weight={TEXT_WEIGHTS.BOLD}
                    >
                      3. % nowych klientów{" "}
                    </Text>{" "}
                    – udział nowych klientów w ogólnej liczbie klientów w danym
                    dniu
                  </Text>
                </li>
                <li>
                  <Text>
                    <Text
                      Wrapper={TEXT_WRAPPERS.SPAN}
                      weight={TEXT_WEIGHTS.BOLD}
                    >
                      4. Powroty{" "}
                    </Text>{" "}
                    – ponowny zakup tego samego produktu od momentu pierwszego
                    zakupu przez kolejne 8 tygodni (przy płatności tą samą kartą
                    płatniczą, co przy pierwszym zakupie)
                  </Text>
                </li>
                <li>
                  <Text>
                    <Text
                      Wrapper={TEXT_WRAPPERS.SPAN}
                      weight={TEXT_WEIGHTS.BOLD}
                    >
                      5. % wszystkich powrotów{" "}
                    </Text>{" "}
                    – procent klientów, którzy ponownie zakupili produkt
                  </Text>
                </li>
                <li>
                  <Text>
                    <Text
                      Wrapper={TEXT_WRAPPERS.SPAN}
                      weight={TEXT_WEIGHTS.BOLD}
                    >
                      6. % powrotów w 1 tyg./ 2 tyg. / 3-4 tyg./ 5-6 tyg. / 7-8
                      tyg.{" "}
                    </Text>{" "}
                    - jaki procent klientów dokonuje powtórnego zakupu w danym
                    czasie od momentu pierwszego zakupu
                  </Text>
                </li>
              </ul>
              <ul className={s.paragraph}>
                <li>
                  <Text>
                    1. Dla Raportu Lojalność patrząc na agregację dzienną należy
                    rozpatrywać dane per ten konkretny dzień - oznacza to, że
                    wybierając konkretny dzień na wykresie wszystkie miary
                    odnoszą się do tego zaznaczonego dnia, tzn.: nowi klienci to
                    Ci, którzy dokonali po raz pierwszy zakupu w tym zaznaczonym
                    dniu, a powracający klienci, to Ci którzy od tego dnia w
                    określonym zakresie czasu zakupili ponownie ten sam produkt
                    (zgodnie z przykładem opisanym poniżej)
                  </Text>
                </li>
                <li>
                  <Text>
                    2. Dane w agregacjach innych niż dzienna są kumulowane (po
                    przełączeniu na agregacje tygodniową poszczególne miary mogą
                    się różnić od sumy miar dla konkretnych dni tygodnia)
                  </Text>
                </li>
                <li>
                  <Text>
                    3. Nieważne, ile razy klient powróci do zakupu danego
                    produktu w okresie 8 tygodni, jest on liczony zawsze tylko
                    raz! - bierzemy pod uwagę tylko pierwszy powrót do zakupu
                    przez danego klienta.
                  </Text>
                </li>
              </ul>
              <Heading className={s.paragraph}>Przykład</Heading>
              <Text className={s.paragraph}>
                Zerknijmy na raport dla dnia 1.09.2022r. i przejdźmy po kolei
                przez każdą z dostępnych miar.
              </Text>
              <img alt="przyklad_lojalnosc" src={LoyaltyExampleSrc} />
              <Text className={s.paragraph}>
                Opis został sporządzony 17.10.2022r. Jest to na tyle istotna
                informacja, że patrząc na dane za 1.09.2022r. nie będziemy mieli
                jeszcze pełnych danych na ten dzień dla dwóch ostatnich miar.
              </Text>
              <Text className={s.paragraph}>
                *Pełne dane dla 1.09.2022r będą dostępne w dniu 26.10.2022r.
              </Text>
              <Table<MetricsDetailsTableMetrics>
                initialState={TABLE_DATA}
                columns={METRICS_DETAILS_COLUMNS}
                data={TABLE_DATA}
                isLoading={false}
                renderHeader={column => (
                  <TableHeader<MetricsDetailsTableMetrics>
                    column={column}
                    isToggleSortButton
                    className={{ textWrapper: s.tableHeaderTextWrapper }}
                  />
                )}
                renderCell={cell => (
                  <TableCell<MetricsDetailsTableMetrics> cell={cell} />
                )}
                className={{ tableResponsiveWrapper: s.tableResponsiveWrapper }}
                customTableHeight={660}
              />
              <Text className={s.paragraph}>
                W przypadku jakichkolwiek wątpliwości związanych z Raportem
                Lojalność i liczeniem poszczególnych miar zachęcamy do kontaktu
                z zespołem Business Data Advisors.
              </Text>
            </>
          </Card>
        </OutsideClickHandler>
      </PopupWindow>
    </Portal>
  );
};
