import { newDateByTimezone } from "store/utils";
import { PERIOD_TYPE } from "utils";
import { Values } from "utils/types";

import { getPeriodValue } from "./getPeriodValue";

export const getSidebarHeader = (
  regularDate: string,
  lastYearDate: string | null,
  chartPeriod: Values<typeof PERIOD_TYPE>,
  dataset: { base: boolean; historical: boolean; both: boolean }
) => {
  if (dataset.base || !lastYearDate) {
    return getPeriodValue(newDateByTimezone(regularDate), chartPeriod);
  }
  if (dataset.historical) {
    return getPeriodValue(newDateByTimezone(lastYearDate), chartPeriod);
  }
  return [
    getPeriodValue(newDateByTimezone(regularDate), chartPeriod),
    "vs",
    getPeriodValue(newDateByTimezone(lastYearDate), chartPeriod)
  ].join(" ");
};
