import React, { FC } from "react";

import cn from "classnames";

import { CardContent, NiceScrollbar, SpinnerLoader } from "components/atoms";
import { Card } from "components/molecules";
import { useMinSummaryHeight } from "hooks";

import s from "./SummaryCard.module.scss";

interface Props {
  className: string;
  isLoading: boolean;
}

export const SummaryCard: FC<Props> = ({
  children,
  className,
  isLoading = false
}) => {
  // min-height is added to prevent box expanding when data comes
  const minHeight = useMinSummaryHeight();

  return (
    <div className={s.slider}>
      <Card className={{ base: s.summaryCard }} style={{ minHeight }}>
        <NiceScrollbar autoHeight hideVertical autoHeightMax={500}>
          {isLoading && <SpinnerLoader className={{ wrapper: s.loader }} />}
          <CardContent className={cn(s.content, className)}>
            {children}
          </CardContent>
        </NiceScrollbar>
      </Card>
    </div>
  );
};
