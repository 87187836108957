import { DATASET_TYPES } from "utils";

export const sortMapCharts = <T extends { type: string; label: string }>(
  left: T,
  right: T
) => {
  if (left.type === right.type) {
    return left.label.localeCompare(right.label);
  }

  return left.type === DATASET_TYPES.OWN ? -1 : 1;
};
