import * as React from "react";

import cn from "classnames";

import { ALERT_TYPES, ICON_SIZES } from "utils";
import { Values } from "utils/types";

import { Text } from "../../atoms";
import { Close } from "../../atoms/Icon";
import s from "./alert.module.scss";

interface Props {
  className?: {
    alert?: string;
    text?: string;
    button?: string;
    icon?: string;
  };
  children: React.ReactNode;
  onClose: () => void;
  type?: Values<typeof ALERT_TYPES>;
}

export const Alert = ({
  className = {},
  children,
  onClose,
  type = ALERT_TYPES.CRITICAL
}: Props) => {
  const TYPE_CLASSES = {
    success: {
      alert: [s.successAlert],
      text: [s.successAlertText],
      button: [s.successAlertButton]
    },
    critical: {
      alert: [s.criticalAlert],
      text: [s.criticalAlertText],
      button: [s.criticalAlertButton]
    }
  } as const;

  return (
    <div className={cn(s.base, TYPE_CLASSES[type].alert, className.alert)}>
      <Text className={cn(s.text, TYPE_CLASSES[type].text, className.text)}>
        {children}
      </Text>
      <button
        type="button"
        className={cn(s.button, TYPE_CLASSES[type].button, className.button)}
        onClick={onClose}
      >
        <Close
          size={ICON_SIZES.EXTRA_SMALL}
          className={cn(s.icon, className.icon)}
        />
      </button>
    </div>
  );
};
