import { useSelector } from "react-redux";

import cn from "classnames";

import { isChartSidebarOpenSelector } from "store/selectors/appSelectors";

import { IDS } from "../Tour";
import s from "./resizeHandle.module.scss";

interface Props {
  isSidebarBorderClicked: boolean;
}

const DotsIcon = () => {
  return (
    <svg
      id={IDS.SIDEBAR_RESIZE_DOTS}
      className={s.dotsIcon}
      width="3"
      height="18"
      viewBox="0 0 3 18"
      fill="currentColor"
    >
      <circle cx="1.5" cy="1.5" r="1.5" />
      <circle cx="1.5" cy="6.5" r="1.5" />
      <circle cx="1.5" cy="11.5" r="1.5" />
      <circle cx="1.5" cy="16.5" r="1.5" />
    </svg>
  );
};

export const ResizeHandle = ({ isSidebarBorderClicked }: Props) => {
  const isOpen = useSelector(isChartSidebarOpenSelector);

  return (
    <div
      className={cn(
        { [s.resizeHandleOpen]: isOpen },
        { [s.resizeHandleClosed]: !isOpen },
        { [s.resizeHandleClicked]: isSidebarBorderClicked }
      )}
    >
      <DotsIcon />
    </div>
  );
};
