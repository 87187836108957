import { useState } from "react";

import { AnimatePresence, motion, MotionProps } from "framer-motion";

import { Button, Text } from "components/atoms";
import { ThumbDown, ThumbUp } from "components/atoms/Icon";
import { InsightsModal } from "pages/Insights/partials/InsightsModal/InsightsModal";
import { sendSingleInsightFeedback } from "pages/Insights/utils/analytics";
import { InsightRateLevel } from "pages/Insights/utils/types";
import { HTTP, ICON_SIZES, REST_API_ENDPOINTS } from "utils";
import { pushInsightsAction } from "utils/googleTagManager/dataLayer";

import s from "../insightsBox.module.scss";

interface InsightBoxFooterProps {
  rateLevel: InsightRateLevel;
  insightId: string;
  isAnimated: boolean;
}

const APPEARANCE_PROPS: MotionProps = {
  initial: { opacity: 0, scale: 0.4 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.4 }
};

export const InsightBoxFooter: React.FC<InsightBoxFooterProps> = ({
  rateLevel,
  insightId,
  isAnimated
}) => {
  const [isInsightModalOpen, setInsightModalOpen] = useState<boolean>(false);
  const [isRating, setIsRating] = useState(false);
  //0 - no rating, 1 - thumb rating done, 2 - modal rating done
  const [insightRateLevel, setInsightRateLevel] = useState<InsightRateLevel>(
    rateLevel
  );

  const handleThumbClick = async (isThumbUp: boolean) => {
    pushInsightsAction(
      "click",
      isThumbUp ? "lista - thumb up" : "lista - thumb down"
    );

    try {
      setIsRating(true);
      await HTTP.post<{}>(`${REST_API_ENDPOINTS.INSIGHTS.RATE}${insightId}/`, {
        like: isThumbUp
      });
      setInsightRateLevel(InsightRateLevel.THUMB);
      sendSingleInsightFeedback(
        isThumbUp ? "THUMB_UP" : "THUMB_DOWN",
        insightId
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsRating(false);
    }
  };

  const handleAnswerClick = () => {
    pushInsightsAction("click", "lista - pokaż ankietę");
    setInsightModalOpen(true);
    sendSingleInsightFeedback("SURVEY_SHOW", insightId);
  };

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {insightRateLevel === 0 && (
          <motion.div
            key="rateLevel0"
            className={s.footer}
            {...(isAnimated ? APPEARANCE_PROPS : {})}
          >
            <span className={s.feedback}>Czy ta informacja była pomocna?</span>
            <div>
              <button
                onClick={() => handleThumbClick(true)}
                className={s.voteButton}
                data-testid="insight-vote-up"
                type="button"
                disabled={isRating}
              >
                <ThumbUp size={ICON_SIZES.LARGE} />
              </button>
              <button
                onClick={() => handleThumbClick(false)}
                className={s.voteButton}
                data-testid="insight-vote-down"
                type="button"
                disabled={isRating}
              >
                <ThumbDown size={ICON_SIZES.LARGE} />
              </button>
            </div>
          </motion.div>
        )}
        {insightRateLevel === 1 && (
          <motion.div
            key="rateLevel1"
            className={s.footer}
            {...(isAnimated ? APPEARANCE_PROPS : {})}
          >
            <span className={s.feedback}>
              Dziękujemy! Chcesz otrzymać spersonalizowane insighty w
              przyszłości? Odpowiedz na trzy krótkie pytania w naszej ankiecie
              🙂
            </span>
            <Button onClick={handleAnswerClick} className={s.answerButton}>
              <Text className={s.answerText}>Otwórz</Text>
            </Button>
          </motion.div>
        )}
        {insightRateLevel === 2 && (
          <motion.div
            key="rateLevel2"
            className={s.footer}
            {...(isAnimated ? APPEARANCE_PROPS : {})}
          >
            <span className={s.feedback}>Dziękujemy za opinię.</span>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isInsightModalOpen && (
          <InsightsModal
            id={insightId}
            onClose={() => setInsightModalOpen(false)}
            setRateLevel={setInsightRateLevel}
          />
        )}
      </AnimatePresence>
    </>
  );
};
