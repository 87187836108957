import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const ArrowTurnDown = ({
  className,
  size = ICON_SIZES.NORMAL
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={SIZES[size]}
      height={SIZES[size]}
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="m15 15l-6 6m0 0l-6-6m6 6V9a6 6 0 0 1 12 0v3"
      />
    </svg>
  );
};
