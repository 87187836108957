import { useDispatch } from "react-redux";

import { ChartSectionSelect } from "components/organisms";
import { IDS } from "components/organisms/Tour";
import { updateQueryParams } from "store/actions/routerActions";
import { QP } from "utils/defaultQueryParams";

import { useShowDatasetOptions } from "./hooks";

export const ShowDatasetSelect = () => {
  const dispatch = useDispatch();
  const { value, options } = useShowDatasetOptions();

  if (!options.length) return null;

  return (
    <ChartSectionSelect
      id="show-dataset-select"
      wrapperId={IDS.DATASET_SELECT}
      label="Okres"
      value={value}
      options={options}
      onChange={value => {
        dispatch(updateQueryParams({ [QP.SHOW_DATASET]: value }));
      }}
    />
  );
};
