import { createSelector } from "reselect";

import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  compareDateFromQPSelector,
  compareDateToQPSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import { APP_PATHS, isThisPage } from "utils";

export const BACK_DATA_REPORTS = [
  REPORTS_FULL_PATHS.SELL_PATH,
  REPORTS_FULL_PATHS.LOCATION_PATH,
  REPORTS_FULL_PATHS.SHARES_PATH,
  REPORTS_FULL_PATHS.RECEIPTS_PATH,
  REPORTS_FULL_PATHS.SEGMENTS_PATH,
  APP_PATHS.EXPORT,
  REPORTS_FULL_PATHS.LOYALTY_PATH,
  REPORTS_FULL_PATHS.MULTIPACK_PATH
];

export const isHistoricalDataEnabledSelector = createSelector(
  pathnameSelector,
  pathname => {
    const isReportValid = BACK_DATA_REPORTS.some(url =>
      isThisPage(pathname, url)
    );

    return isReportValid;
  }
);

export const isBackDataSelectableSelector = createSelector(
  isHistoricalDataEnabledSelector,
  compareDateFromQPSelector,
  compareDateToQPSelector,
  (isEnabled, compareDateFromQP, compareDateToQP) =>
    isEnabled && Boolean(compareDateFromQP) && Boolean(compareDateToQP)
);
