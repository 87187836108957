import { useSelector } from "react-redux";

import { useGetDynamicsByPeriod } from "api/dynamics/mappers";
import { useGeneralDynamicsWarnings } from "api/dynamics/mappers/useGeneralDynamicsWarnings";
import { useRankingWarnings } from "api/ranking/mappers";

import { chartPeriodSelector } from "store/selectors/routerSelectors";
import { PERIOD_TYPE, WARNINGS } from "utils";

export const useDynamicsWarnings = (): string[] => {
  const generalWarnings = useGeneralDynamicsWarnings();
  const period = useSelector(chartPeriodSelector);
  const info = useGetDynamicsByPeriod().data?.info ?? [];
  const rankingWarnings = useRankingWarnings();
  const isWeeksPeriod = period === PERIOD_TYPE.WEEKS;

  return [
    ...generalWarnings,
    ...info,
    ...rankingWarnings,
    isWeeksPeriod ? WARNINGS.DYNAMICS_WEEK_DATA_WARNING : ""
  ].filter(warning => !!warning);
};
