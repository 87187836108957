import * as React from "react";

import s from "components/common.module.scss";

interface Props {
  width: number;
  height: number;
  text: string;
}

export const BottomLabel: React.FC<Props> = ({ width, height, text }) => (
  <text
    textAnchor="middle"
    className={s.axisLabel}
    transform={`translate(${width}, ${height})`}
    dy="-0.5rem"
    data-testid="bottom-axis-label"
  >
    {text}
  </text>
);
