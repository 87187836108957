import { useMemo } from "react";

import { useGetSharesByPeriod } from "api/shares/mappers";
import { ReportTypes } from "api/shares/types";

import { DATASET_TYPES } from "utils";

export const useOwnSharesTableData = () => {
  const query = useGetSharesByPeriod(ReportTypes.TABLE);

  const tableData = useMemo(
    () =>
      query?.data?.currentPeriod.filter(
        ({ type }) => type === DATASET_TYPES.OWN
      ) ?? [],
    [query]
  );

  return tableData;
};
