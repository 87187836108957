import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { usePrevious } from "hooks";
import { useFetchingStatus } from "hooks/useFetchingStatus";

export const useDataReadyFlag = () => {
  const [isReady, setReady] = useState(false);
  const { pathname } = useLocation();

  const isLoading = useFetchingStatus();
  const prevLoading = usePrevious(isLoading);

  useEffect(() => {
    if (isLoading) {
      setReady(false);
    }

    if (prevLoading && !isLoading) {
      setReady(true);
    }
  }, [isLoading, prevLoading, pathname]);

  return isReady;
};
