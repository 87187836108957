import { FC } from "react";

import { Text } from "components/atoms";
import {
  Checkbox,
  FilterDropdownList,
  FilterDropdownListItem
} from "components/molecules";
import { PropGetter } from "components/molecules/types";
import { isActiveFn } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import {
  CategoryLevel,
  ReferenceCategoryHooks,
  ReferenceCategoryItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useReferenceCategory";
import { TEXT_WRAPPERS } from "utils";

import s from "./referenceCategories.module.scss";

type ItemPropGetter = PropGetter<"getItemProps">;

type Props = {
  level: CategoryLevel;
  getItemProps?: ItemPropGetter;
};

export const ReferenceCategoriesList: FC<Props> = ({ level, ...props }) => {
  const [, actions] = ReferenceCategoryHooks.useReferenceCategory();
  const [items] = ReferenceCategoryHooks.useAllCategories(level);
  const [selectedItems] = ReferenceCategoryHooks.useSelectedCategories(level);

  const getItemProps = props.getItemProps as ItemPropGetter;
  const isItemActive = (item: ReferenceCategoryItem) =>
    isActiveFn(item, selectedItems);

  const isAllCheckboxChecked =
    selectedItems.length > 0 && selectedItems.length === items.length;

  const handleAllItemsCheckboxChange = () => {
    if (isAllCheckboxChecked) {
      actions.updateSelectedCategories([], level);
    } else {
      actions.selectAllCategories(level);
    }
  };

  return (
    <div className={s.categoriesWrapper}>
      <label className={s.categoryLabelWrapper}>
        <Checkbox
          isChecked={isAllCheckboxChecked}
          onChange={handleAllItemsCheckboxChange}
        />
        <Text className={s.categoryLabel} Wrapper={TEXT_WRAPPERS.SPAN}>
          {`Kategoria G${level} (${selectedItems.length}/${items.length})`}
        </Text>
      </label>

      <FilterDropdownList
        listItem={
          <FilterDropdownListItem
            className={{
              base: s.categoryItemsBase,
              itemText: s.categoryItemText
            }}
          />
        }
        items={items}
        basicItemHeight={26}
        maxCharactersInLine={22}
        minimumItemsToVirtualize={0}
        isItemActive={isItemActive}
        getItemProps={getItemProps}
        className={s.referenceCategories}
        isMultiChoice
      />
    </div>
  );
};
