import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const EnvelopeBold: React.FC<Props> = ({
  className,
  color = "#6D89B6",
  size = ICON_SIZES.NORMAL
}) => {
  const iconSize = SIZES[size];

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize}
      height={iconSize}
      viewBox="0 0 2560 2560"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g fill={color} stroke="none">
        <path d="M125 2231 c-45 -11 -74 -32 -98 -71 l-22 -35 0 -845 0 -845 22 -35 c12 -19 37 -44 55 -55 32 -20 51 -20 1198 -20 l1165 0 35 22 c19 12 44 37 55 55 20 32 20 53 23 863 l2 830 -22 44 c-27 53 -60 79 -114 91 -46 11 -2255 11 -2299 1z m2115 -731 c0 -236 -4 -420 -9 -420 -5 0 -205 112 -446 250 -240 137 -454 254 -475 260 -29 8 -48 7 -77 -3 -21 -7 -229 -123 -462 -256 -233 -133 -430 -245 -437 -248 -12 -4 -14 63 -14 416 l0 421 960 0 960 0 0 -420z m-484 -514 l479 -273 3 -37 3 -36 -961 0 -961 0 3 36 3 37 475 273 c261 149 476 273 476 273 1 1 217 -122 480 -273z" />
      </g>
    </svg>
  );
};
