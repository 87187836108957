import {
  Action,
  createHook,
  createStore,
  defaultRegistry
} from "react-sweet-state";

export enum AggregationTypes {
  false = "false",
  attr_group = "attr_group",
  attr_total = "attr_total",
  own = "own",
  comp = "comp",
  both = "both",
  total = "total"
}

export interface AggregationTypesItem {
  label: string;
  value: AggregationTypes;
  tooltipText?: string;
  isDisabled?: boolean;
}

const isAggregationType = (
  aggregation: string
): aggregation is AggregationTypes =>
  Object.values(AggregationTypes).includes(aggregation as AggregationTypes);

type State = { selected: AggregationTypes };
type Actions = typeof actions;

const actions = {
  getInitialAggregatedState: (aggregatedQP: string): Action<State> => ({
    setState
  }) => {
    setState({
      selected: isAggregationType(aggregatedQP)
        ? aggregatedQP
        : AggregationTypes.false
    });
  },
  updateAggregated: (selected: AggregationTypes): Action<State> => ({
    setState
  }) => {
    setState({
      selected
    });
  },
  clearAggregated: (): Action<State> => ({ setState }) => {
    setState({
      selected: AggregationTypes.false
    });
  }
};

export const AggregatedStore = createStore<State, Actions>({
  name: "aggregated",
  initialState: {
    selected: AggregationTypes.false
  },
  actions
});

export const AggregatedHooks = {
  useAggregated: createHook(AggregatedStore)
};

export const AggregatedStoreInstance = defaultRegistry.getStore(
  AggregatedStore
);
