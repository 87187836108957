import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { path } from "ramda";

import { logoutUser } from "pages/Login/redux/reducer";
import { VendorStoreInstance } from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { store } from "store";
import { setErrorMessage } from "store/actions/appActions";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { vendorIdQueryParamSelector } from "store/selectors/routerSelectors";
import { REST_API_ENDPOINTS, VENDOR_DEPENDENT_REQUESTS } from "utils/constants";
import { getDelayTime } from "utils/getDelayTime";
import { hideNotRelevantError } from "utils/hideError";

import { GENERIC_ERROR_MESSAGE, mapErrorMessage } from "./mapErrorMessage";

type ApiError = {
  [key: string]: { code: string; message: string }[];
};

const HTTP = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL + "v1",
  withCredentials: true
});

//@ts-ignore explicit dispatch on store
const handleLogout = () => store.dispatch(logoutUser());

const onResponseError = (error: AxiosError<ApiError>): Promise<AxiosError> => {
  const config = error.config as InternalAxiosRequestConfig;
  const errorStatus: number = path(["request", "status"], error) || 400;
  const errorData = error.response?.data;

  const hideError = hideNotRelevantError(
    config.url || "",
    window.location.pathname
  );

  if ([401, 403].includes(errorStatus)) {
    handleLogout();
    return Promise.reject(error);
  }

  if (hideError) return Promise.reject(error);

  if (errorData) {
    const errorDataKeys = Object.keys(errorData);
    const errorKey = errorDataKeys[0] || "";
    const errorCode: string = errorData[errorKey]?.length
      ? errorData[errorKey][0]?.code
      : "";
    const errorMessage = mapErrorMessage(errorCode);

    store.dispatch(setErrorMessage(errorMessage));
    setTimeout(function() {
      store.dispatch(setErrorMessage(""));
    }, getDelayTime(errorMessage));
  } else {
    const urlsToSkipError = [
      REST_API_ENDPOINTS.REPORTS.PROMOTIONS,
      REST_API_ENDPOINTS.CATEGORIES,
      REST_API_ENDPOINTS.PRODUCTS,
      REST_API_ENDPOINTS.COUNTIES,
      REST_API_ENDPOINTS.STORE_TYPES,
      REST_API_ENDPOINTS.COMPANIES.GET_POWERUSER_COMPANIES,
      REST_API_ENDPOINTS.COMPANIES.GET_COMPETITION_COMPANIES
    ];
    const shouldShipError = !urlsToSkipError.includes(config.url || "");
    if (shouldShipError) {
      store.dispatch(setErrorMessage(GENERIC_ERROR_MESSAGE));
      setTimeout(function() {
        store.dispatch(setErrorMessage(""));
      }, getDelayTime(GENERIC_ERROR_MESSAGE));
    }
  }

  return Promise.reject(error);
};

export const vendorIdInterceptorHandler = (
  request: InternalAxiosRequestConfig,
  appStore: typeof store
) => {
  const state = appStore.getState();
  const isPowerUser = isPowerUserSelector(state);
  const companyQP = vendorIdQueryParamSelector(state);
  const vendorStore = VendorStoreInstance.storeState.getState();
  const company = vendorStore.selected[0]?.value;

  const shouldAppendVendor =
    isPowerUser && VENDOR_DEPENDENT_REQUESTS.includes(request.url as string);

  if (shouldAppendVendor) {
    return {
      ...request,
      vendor_id: company ? company : companyQP
    };
  }

  return request;
};

HTTP.defaults.headers.post["Content-Type"] = "application/json";

HTTP.interceptors.request.use(
  request => vendorIdInterceptorHandler(request, store),
  err => Promise.reject(err)
);

HTTP.interceptors.response.use(
  response => response,
  err => onResponseError(err)
);

// axios interceptors should always return resolved Promise at the end of intercepted failures
// interceptors shouldn't be used explicitly anywhere outside this file
export default HTTP;
