import {
  MultipackDataApi,
  MultipackDataPointApi,
  MultipackNormalizedResponse,
  MultipackTimeline
} from "api/multipack/types";
import { format } from "date-fns";

import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import { newDateByTimezone } from "store/utils";
import { DEFAULT_DATE_FORMAT, standarizeTimepointValue } from "utils";

export const normalizeMultipackResponse = (
  data: MultipackDataApi[]
): MultipackNormalizedResponse[] =>
  data.map(data => {
    const vendorId = data.vendor_id || "";
    const resultId = data.result_id || "";
    const type = data.type;
    const name = getChartLabel({
      vendorId,
      resultId,
      type
    });

    return {
      vendorId,
      resultId,
      type,
      name,
      timeline: data.timeline.map(standarizeDataPoint),
      existingMetrics: {
        worthSumWs: data.existing_metrics.worth_sum_ws,
        worthSumWsChange: data.existing_metrics.worth_sum_ws_change,
        worthSumWsChangePercentage:
          data.existing_metrics.worth_sum_ws_change_percentage,
        worthSumWsLastYear: data.existing_metrics.worth_sum_ws_last_year,

        worthSumWso: data.existing_metrics.worth_sum_wso,
        worthSumWsoChange: data.existing_metrics.worth_sum_wso_change,
        worthSumWsoChangePercentage:
          data.existing_metrics.worth_sum_wso_change_percentage,
        worthSumWsoLastYear: data.existing_metrics.worth_sum_wso_last_year,

        worthSumWsa: data.existing_metrics.worth_sum_wsa,
        worthSumWsaChange: data.existing_metrics.worth_sum_wsa_change,
        worthSumWsaChangePercentage:
          data.existing_metrics.worth_sum_wsa_change_percentage,
        worthSumWsaLastYear: data.existing_metrics.worth_sum_wsa_last_year,

        worthSumWsWsoWsa: data.existing_metrics.worth_sum_ws_wso_wsa,
        worthSumWsWsoWsaChange:
          data.existing_metrics.worth_sum_ws_wso_wsa_change,
        worthSumWsWsoWsaChangePercentage:
          data.existing_metrics.worth_sum_ws_wso_wsa_change_percentage,
        worthSumWsWsoWsaLastYear:
          data.existing_metrics.worth_sum_ws_wso_wsa_last_year,

        worthSum: data.existing_metrics.worth_sum,
        worthSumChange: data.existing_metrics.worth_sum_change,
        worthSumChangePercentage:
          data.existing_metrics.worth_sum_change_percentage,
        worthSumLastYear: data.existing_metrics.worth_sum_last_year,

        quantitySumWs: data.existing_metrics.quantity_sum_ws,
        quantitySumWsChange: data.existing_metrics.quantity_sum_ws_change,
        quantitySumWsChangePercentage:
          data.existing_metrics.quantity_sum_ws_change_percentage,
        quantitySumWsLastYear: data.existing_metrics.quantity_sum_ws_last_year,

        quantitySumWso: data.existing_metrics.quantity_sum_wso,
        quantitySumWsoChange: data.existing_metrics.quantity_sum_wso_change,
        quantitySumWsoChangePercentage:
          data.existing_metrics.quantity_sum_wso_change_percentage,
        quantitySumWsoLastYear:
          data.existing_metrics.quantity_sum_wso_last_year,

        quantitySumWsa: data.existing_metrics.quantity_sum_wsa,
        quantitySumWsaChange: data.existing_metrics.quantity_sum_wsa_change,
        quantitySumWsaChangePercentage:
          data.existing_metrics.quantity_sum_wsa_change_percentage,
        quantitySumWsaLastYear:
          data.existing_metrics.quantity_sum_wsa_last_year,

        quantitySumWsWsoWsa: data.existing_metrics.quantity_sum_ws_wso_wsa,
        quantitySumWsWsoWsaChange:
          data.existing_metrics.quantity_sum_ws_wso_wsa_change,
        quantitySumWsWsoWsaChangePercentage:
          data.existing_metrics.quantity_sum_ws_wso_wsa_change_percentage,
        quantitySumWsWsoWsaLastYear:
          data.existing_metrics.quantity_sum_ws_wso_wsa_last_year,

        quantitySum: data.existing_metrics.quantity_sum,
        quantitySumChange: data.existing_metrics.quantity_sum_change,
        quantitySumChangePercentage:
          data.existing_metrics.quantity_sum_change_percentage,
        quantitySumLastYear: data.existing_metrics.quantity_sum_last_year,

        worthSumPerShopWs: data.existing_metrics.worth_sum_per_shop_ws,
        worthSumPerShopWsChange:
          data.existing_metrics.worth_sum_per_shop_ws_change,
        worthSumPerShopWsChangePercentage:
          data.existing_metrics.worth_sum_per_shop_ws_change_percentage,
        worthSumPerShopWsLastYear:
          data.existing_metrics.worth_sum_per_shop_ws_last_year,

        worthSumPerShopWso: data.existing_metrics.worth_sum_per_shop_wso,
        worthSumPerShopWsoChange:
          data.existing_metrics.worth_sum_per_shop_wso_change,
        worthSumPerShopWsoChangePercentage:
          data.existing_metrics.worth_sum_per_shop_wso_change_percentage,
        worthSumPerShopWsoLastYear:
          data.existing_metrics.worth_sum_per_shop_wso_last_year,

        worthSumPerShopWsa: data.existing_metrics.worth_sum_per_shop_wsa,
        worthSumPerShopWsaChange:
          data.existing_metrics.worth_sum_per_shop_wsa_change,
        worthSumPerShopWsaChangePercentage:
          data.existing_metrics.worth_sum_per_shop_wsa_change_percentage,
        worthSumPerShopWsaLastYear:
          data.existing_metrics.worth_sum_per_shop_wsa_last_year,

        worthSumPerShopWsWsoWsa:
          data.existing_metrics.worth_sum_per_shop_ws_wso_wsa,
        worthSumPerShopWsWsoWsaChange:
          data.existing_metrics.worth_sum_per_shop_ws_wso_wsa_change,
        worthSumPerShopWsWsoWsaChangePercentage:
          data.existing_metrics.worth_sum_per_shop_ws_wso_wsa_change_percentage,
        worthSumPerShopWsWsoWsaLastYear:
          data.existing_metrics.worth_sum_per_shop_ws_wso_wsa_last_year,

        worthSumPerShop: data.existing_metrics.worth_sum_per_shop,
        worthSumPerShopChange: data.existing_metrics.worth_sum_per_shop_change,
        worthSumPerShopChangePercentage:
          data.existing_metrics.worth_sum_per_shop_change_percentage,
        worthSumPerShopLastYear:
          data.existing_metrics.worth_sum_per_shop_last_year,

        quantitySumPerShopWs: data.existing_metrics.quantity_sum_per_shop_ws,
        quantitySumPerShopWsChange:
          data.existing_metrics.quantity_sum_per_shop_ws_change,
        quantitySumPerShopWsChangePercentage:
          data.existing_metrics.quantity_sum_per_shop_ws_change_percentage,
        quantitySumPerShopWsLastYear:
          data.existing_metrics.quantity_sum_per_shop_ws_last_year,

        quantitySumPerShopWso: data.existing_metrics.quantity_sum_per_shop_wso,
        quantitySumPerShopWsoChange:
          data.existing_metrics.quantity_sum_per_shop_wso_change,
        quantitySumPerShopWsoChangePercentage:
          data.existing_metrics.quantity_sum_per_shop_wso_change_percentage,
        quantitySumPerShopWsoLastYear:
          data.existing_metrics.quantity_sum_per_shop_wso_last_year,

        quantitySumPerShopWsa: data.existing_metrics.quantity_sum_per_shop_wsa,
        quantitySumPerShopWsaChange:
          data.existing_metrics.quantity_sum_per_shop_wsa_change,
        quantitySumPerShopWsaChangePercentage:
          data.existing_metrics.quantity_sum_per_shop_wsa_change_percentage,
        quantitySumPerShopWsaLastYear:
          data.existing_metrics.quantity_sum_per_shop_wsa_last_year,

        quantitySumPerShopWsWsoWsa:
          data.existing_metrics.quantity_sum_per_shop_ws_wso_wsa,
        quantitySumPerShopWsWsoWsaChange:
          data.existing_metrics.quantity_sum_per_shop_ws_wso_wsa_change,
        quantitySumPerShopWsWsoWsaChangePercentage:
          data.existing_metrics
            .quantity_sum_per_shop_ws_wso_wsa_change_percentage,
        quantitySumPerShopWsWsoWsaLastYear:
          data.existing_metrics.quantity_sum_per_shop_ws_wso_wsa_last_year,

        quantitySumPerShop: data.existing_metrics.quantity_sum_per_shop,
        quantitySumPerShopChange:
          data.existing_metrics.quantity_sum_per_shop_change,
        quantitySumPerShopChangePercentage:
          data.existing_metrics.quantity_sum_per_shop_change_percentage,
        quantitySumPerShopLastYear:
          data.existing_metrics.quantity_sum_per_shop_last_year,

        quantityShareTotalWs: data.existing_metrics.quantity_share_total_ws,
        quantityShareTotalWsChange:
          data.existing_metrics.quantity_share_total_ws_change,
        quantityShareTotalWsChangePercentage:
          data.existing_metrics.quantity_share_total_ws_change_percentage,
        quantityShareTotalWsLastYear:
          data.existing_metrics.quantity_share_total_ws_last_year,

        quantityShareTotalWso: data.existing_metrics.quantity_share_total_wso,
        quantityShareTotalWsoChange:
          data.existing_metrics.quantity_share_total_wso_change,
        quantityShareTotalWsoChangePercentage:
          data.existing_metrics.quantity_share_total_wso_change_percentage,
        quantityShareTotalWsoLastYear:
          data.existing_metrics.quantity_share_total_wso_last_year,

        quantityShareTotalWsa: data.existing_metrics.quantity_share_total_wsa,
        quantityShareTotalWsaChange:
          data.existing_metrics.quantity_share_total_wsa_change,
        quantityShareTotalWsaChangePercentage:
          data.existing_metrics.quantity_share_total_wsa_change_percentage,
        quantityShareTotalWsaLastYear:
          data.existing_metrics.quantity_share_total_wsa_last_year,

        quantityShareTotalWsWsoWsa:
          data.existing_metrics.quantity_share_total_ws_wso_wsa,
        quantityShareTotalWsWsoWsaChange:
          data.existing_metrics.quantity_share_total_ws_wso_wsa_change,
        quantityShareTotalWsWsoWsaChangePercentage:
          data.existing_metrics
            .quantity_share_total_ws_wso_wsa_change_percentage,
        quantityShareTotalWsWsoWsaLastYear:
          data.existing_metrics.quantity_share_total_ws_wso_wsa_last_year,

        worthShareTotalWs: data.existing_metrics.worth_share_total_ws,
        worthShareTotalWsChange:
          data.existing_metrics.worth_share_total_ws_change,
        worthShareTotalWsChangePercentage:
          data.existing_metrics.worth_share_total_ws_change_percentage,
        worthShareTotalWsLastYear:
          data.existing_metrics.worth_share_total_ws_last_year,

        worthShareTotalWso: data.existing_metrics.worth_share_total_wso,
        worthShareTotalWsoChange:
          data.existing_metrics.worth_share_total_wso_change,
        worthShareTotalWsoChangePercentage:
          data.existing_metrics.worth_share_total_wso_change_percentage,
        worthShareTotalWsoLastYear:
          data.existing_metrics.worth_share_total_wso_last_year,

        worthShareTotalWsa: data.existing_metrics.worth_share_total_wsa,
        worthShareTotalWsaChange:
          data.existing_metrics.worth_share_total_wsa_change,
        worthShareTotalWsaChangePercentage:
          data.existing_metrics.worth_share_total_wsa_change_percentage,
        worthShareTotalWsaLastYear:
          data.existing_metrics.worth_share_total_wsa_last_year,

        worthShareTotalWsWsoWsa:
          data.existing_metrics.worth_share_total_ws_wso_wsa,
        worthShareTotalWsWsoWsaChange:
          data.existing_metrics.worth_share_total_ws_wso_wsa_change,
        worthShareTotalWsWsoWsaChangePercentage:
          data.existing_metrics.worth_share_total_ws_wso_wsa_change_percentage,
        worthShareTotalWsWsoWsaLastYear:
          data.existing_metrics.worth_share_total_ws_wso_wsa_last_year,

        netQuantityShareTotalWs:
          data.existing_metrics.net_quantity_share_total_ws,
        netQuantityShareTotalWsChange:
          data.existing_metrics.net_quantity_share_total_ws_change,
        netQuantityShareTotalWsChangePercentage:
          data.existing_metrics.net_quantity_share_total_ws_change_percentage,
        netQuantityShareTotalWsLastYear:
          data.existing_metrics.net_quantity_share_total_ws_last_year,

        netQuantityShareTotalWso:
          data.existing_metrics.net_quantity_share_total_wso,
        netQuantityShareTotalWsoChange:
          data.existing_metrics.net_quantity_share_total_wso_change,
        netQuantityShareTotalWsoChangePercentage:
          data.existing_metrics.net_quantity_share_total_wso_change_percentage,
        netQuantityShareTotalWsoLastYear:
          data.existing_metrics.net_quantity_share_total_wso_last_year,

        netQuantityShareTotalWsa:
          data.existing_metrics.net_quantity_share_total_wsa,
        netQuantityShareTotalWsaChange:
          data.existing_metrics.net_quantity_share_total_wsa_change,
        netQuantityShareTotalWsaChangePercentage:
          data.existing_metrics.net_quantity_share_total_wsa_change_percentage,
        netQuantityShareTotalWsaLastYear:
          data.existing_metrics.net_quantity_share_total_wsa_last_year,

        netQuantityShareTotalWsWsoWsa:
          data.existing_metrics.net_quantity_share_total_ws_wso_wsa,
        netQuantityShareTotalWsWsoWsaChange:
          data.existing_metrics.net_quantity_share_total_ws_wso_wsa_change,
        netQuantityShareTotalWsWsoWsaChangePercentage:
          data.existing_metrics
            .net_quantity_share_total_ws_wso_wsa_change_percentage,
        netQuantityShareTotalWsWsoWsaLastYear:
          data.existing_metrics.net_quantity_share_total_ws_wso_wsa_last_year,

        priceAvgWs: data.existing_metrics.price_avg_ws,
        priceAvgWsChange: data.existing_metrics.price_avg_ws_change,
        priceAvgWsChangePercentage:
          data.existing_metrics.price_avg_ws_change_percentage,
        priceAvgWsLastYear: data.existing_metrics.price_avg_ws_last_year,

        priceAvgWso: data.existing_metrics.price_avg_wso,
        priceAvgWsoChange: data.existing_metrics.price_avg_wso_change,
        priceAvgWsoChangePercentage:
          data.existing_metrics.price_avg_wso_change_percentage,
        priceAvgWsoLastYear: data.existing_metrics.price_avg_wso_last_year,

        priceAvgWsa: data.existing_metrics.price_avg_wsa,
        priceAvgWsaChange: data.existing_metrics.price_avg_wsa_change,
        priceAvgWsaChangePercentage:
          data.existing_metrics.price_avg_wsa_change_percentage,
        priceAvgWsaLastYear: data.existing_metrics.price_avg_wsa_last_year,

        priceAvgWsWsoWsa: data.existing_metrics.price_avg_ws_wso_wsa,
        priceAvgWsWsoWsaChange:
          data.existing_metrics.price_avg_ws_wso_wsa_change,
        priceAvgWsWsoWsaChangePercentage:
          data.existing_metrics.price_avg_ws_wso_wsa_change_percentage,
        priceAvgWsWsoWsaLastYear:
          data.existing_metrics.price_avg_ws_wso_wsa_last_year,

        priceAvg: data.existing_metrics.price_avg,
        priceAvgChange: data.existing_metrics.price_avg_change,
        priceAvgChangePercentage:
          data.existing_metrics.price_avg_change_percentage,
        priceAvgLastYear: data.existing_metrics.price_avg_last_year,

        netQuantitySumWs: data.existing_metrics.net_quantity_sum_ws,
        netQuantitySumWsChange:
          data.existing_metrics.net_quantity_sum_ws_change,
        netQuantitySumWsChangePercentage:
          data.existing_metrics.net_quantity_sum_ws_change_percentage,
        netQuantitySumWsLastYear:
          data.existing_metrics.net_quantity_sum_ws_last_year,

        netQuantitySumWso: data.existing_metrics.net_quantity_sum_wso,
        netQuantitySumWsoChange:
          data.existing_metrics.net_quantity_sum_wso_change,
        netQuantitySumWsoChangePercentage:
          data.existing_metrics.net_quantity_sum_wso_change_percentage,
        netQuantitySumWsoLastYear:
          data.existing_metrics.net_quantity_sum_wso_last_year,

        netQuantitySumWsa: data.existing_metrics.net_quantity_sum_wsa,
        netQuantitySumWsaChange:
          data.existing_metrics.net_quantity_sum_wsa_change,
        netQuantitySumWsaChangePercentage:
          data.existing_metrics.net_quantity_sum_wsa_change_percentage,
        netQuantitySumWsaLastYear:
          data.existing_metrics.net_quantity_sum_wsa_last_year,

        netQuantitySumWsWsoWsa:
          data.existing_metrics.net_quantity_sum_ws_wso_wsa,
        netQuantitySumWsWsoWsaChange:
          data.existing_metrics.net_quantity_sum_ws_wso_wsa_change,
        netQuantitySumWsWsoWsaChangePercentage:
          data.existing_metrics.net_quantity_sum_ws_wso_wsa_change_percentage,
        netQuantitySumWsWsoWsaLastYear:
          data.existing_metrics.net_quantity_sum_ws_wso_wsa_last_year,

        netQuantitySales: data.existing_metrics.net_quantity_sales,
        netQuantitySalesChange: data.existing_metrics.net_quantity_sales_change,
        netQuantitySalesChangePercentage:
          data.existing_metrics.net_quantity_sales_change_percentage,
        netQuantitySalesLastYear:
          data.existing_metrics.net_quantity_sales_last_year,

        avgNetQuantityPerShopWs:
          data.existing_metrics.avg_net_quantity_per_shop_ws,
        avgNetQuantityPerShopWsChange:
          data.existing_metrics.avg_net_quantity_per_shop_ws_change,
        avgNetQuantityPerShopWsChangePercentage:
          data.existing_metrics.avg_net_quantity_per_shop_ws_change_percentage,
        avgNetQuantityPerShopWsLastYear:
          data.existing_metrics.avg_net_quantity_per_shop_ws_last_year,

        avgNetQuantityPerShopWso:
          data.existing_metrics.avg_net_quantity_per_shop_wso,
        avgNetQuantityPerShopWsoChange:
          data.existing_metrics.avg_net_quantity_per_shop_wso_change,
        avgNetQuantityPerShopWsoChangePercentage:
          data.existing_metrics.avg_net_quantity_per_shop_wso_change_percentage,
        avgNetQuantityPerShopWsoLastYear:
          data.existing_metrics.avg_net_quantity_per_shop_wso_last_year,

        avgNetQuantityPerShopWsa:
          data.existing_metrics.avg_net_quantity_per_shop_wsa,
        avgNetQuantityPerShopWsaChange:
          data.existing_metrics.avg_net_quantity_per_shop_wsa_change,
        avgNetQuantityPerShopWsaChangePercentage:
          data.existing_metrics.avg_net_quantity_per_shop_wsa_change_percentage,
        avgNetQuantityPerShopWsaLastYear:
          data.existing_metrics.avg_net_quantity_per_shop_wsa_last_year,

        avgNetQuantityPerShopWsWsoWsa:
          data.existing_metrics.avg_net_quantity_per_shop_ws_wso_wsa,
        avgNetQuantityPerShopWsWsoWsaChange:
          data.existing_metrics.avg_net_quantity_per_shop_ws_wso_wsa_change,
        avgNetQuantityPerShopWsWsoWsaChangePercentage:
          data.existing_metrics
            .avg_net_quantity_per_shop_ws_wso_wsa_change_percentage,
        avgNetQuantityPerShopWsWsoWsaLastYear:
          data.existing_metrics.avg_net_quantity_per_shop_ws_wso_wsa_last_year,

        avgNetQuantityPerShop: data.existing_metrics.avg_net_quantity_per_shop,
        avgNetQuantityPerShopChange:
          data.existing_metrics.avg_net_quantity_per_shop_change,
        avgNetQuantityPerShopChangePercentage:
          data.existing_metrics.avg_net_quantity_per_shop_change_percentage,
        avgNetQuantityPerShopLastYear:
          data.existing_metrics.avg_net_quantity_per_shop_last_year,

        // WS + WSA - these metrics are temporary and should be deleted in the future
        worthSumWsWsa: data.existing_metrics.worth_sum_ws_wsa,
        worthSumWsWsaChange: data.existing_metrics.worth_sum_ws_wsa_change,
        worthSumWsWsaChangePercentage:
          data.existing_metrics.worth_sum_ws_wsa_change_percentage,
        worthSumWsWsaLastYear: data.existing_metrics.worth_sum_ws_wsa_last_year,

        quantitySumWsWsa: data.existing_metrics.quantity_sum_ws_wsa,
        quantitySumWsWsaChange:
          data.existing_metrics.quantity_sum_ws_wsa_change,
        quantitySumWsWsaChangePercentage:
          data.existing_metrics.quantity_sum_ws_wsa_change_percentage,
        quantitySumWsWsaLastYear:
          data.existing_metrics.quantity_sum_ws_wsa_last_year,

        worthSumPerShopWsWsa: data.existing_metrics.worth_sum_per_shop_ws_wsa,
        worthSumPerShopWsWsaChange:
          data.existing_metrics.worth_sum_per_shop_ws_wsa_change,
        worthSumPerShopWsWsaChangePercentage:
          data.existing_metrics.worth_sum_per_shop_ws_wsa_change_percentage,
        worthSumPerShopWsWsaLastYear:
          data.existing_metrics.worth_sum_per_shop_ws_wsa_last_year,

        quantitySumPerShopWsWsa:
          data.existing_metrics.quantity_sum_per_shop_ws_wsa,
        quantitySumPerShopWsWsaChange:
          data.existing_metrics.quantity_sum_per_shop_ws_wsa_change,
        quantitySumPerShopWsWsaChangePercentage:
          data.existing_metrics.quantity_sum_per_shop_ws_wsa_change_percentage,
        quantitySumPerShopWsWsaLastYear:
          data.existing_metrics.quantity_sum_per_shop_ws_wsa_last_year,

        quantityShareTotalWsWsa:
          data.existing_metrics.quantity_share_total_ws_wsa,
        quantityShareTotalWsWsaChange:
          data.existing_metrics.quantity_share_total_ws_wsa_change,
        quantityShareTotalWsWsaChangePercentage:
          data.existing_metrics.quantity_share_total_ws_wsa_change_percentage,
        quantityShareTotalWsWsaLastYear:
          data.existing_metrics.quantity_share_total_ws_wsa_last_year,

        worthShareTotalWsWsa: data.existing_metrics.worth_share_total_ws_wsa,
        worthShareTotalWsWsaChange:
          data.existing_metrics.worth_share_total_ws_wsa_change,
        worthShareTotalWsWsaChangePercentage:
          data.existing_metrics.worth_share_total_ws_wsa_change_percentage,
        worthShareTotalWsWsaLastYear:
          data.existing_metrics.worth_share_total_ws_wsa_last_year,

        netQuantityShareTotalWsWsa:
          data.existing_metrics.net_quantity_share_total_ws_wsa,
        netQuantityShareTotalWsWsaChange:
          data.existing_metrics.net_quantity_share_total_ws_wsa_change,
        netQuantityShareTotalWsWsaChangePercentage:
          data.existing_metrics
            .net_quantity_share_total_ws_wsa_change_percentage,
        netQuantityShareTotalWsWsaLastYear:
          data.existing_metrics.net_quantity_share_total_ws_wsa_last_year,

        priceAvgWsWsa: data.existing_metrics.price_avg_ws_wsa,
        priceAvgWsWsaChange: data.existing_metrics.price_avg_ws_wsa_change,
        priceAvgWsWsaChangePercentage:
          data.existing_metrics.price_avg_ws_wsa_change_percentage,
        priceAvgWsWsaLastYear: data.existing_metrics.price_avg_ws_wsa_last_year,

        netQuantitySumWsWsa: data.existing_metrics.net_quantity_sum_ws_wsa,
        netQuantitySumWsWsaChange:
          data.existing_metrics.net_quantity_sum_ws_wsa_change,
        netQuantitySumWsWsaChangePercentage:
          data.existing_metrics.net_quantity_sum_ws_wsa_change_percentage,
        netQuantitySumWsWsaLastYear:
          data.existing_metrics.net_quantity_sum_ws_wsa_last_year,

        avgNetQuantityPerShopWsWsa:
          data.existing_metrics.avg_net_quantity_per_shop_ws_wsa,
        avgNetQuantityPerShopWsWsaChange:
          data.existing_metrics.avg_net_quantity_per_shop_ws_wsa_change,
        avgNetQuantityPerShopWsWsaChangePercentage:
          data.existing_metrics
            .avg_net_quantity_per_shop_ws_wsa_change_percentage,
        avgNetQuantityPerShopWsWsaLastYear:
          data.existing_metrics.avg_net_quantity_per_shop_ws_wsa_last_year
      }
    };
  });

const standarizeDataPoint = (
  dataPoint: MultipackDataPointApi
): MultipackTimeline => ({
  date: format(
    newDateByTimezone(new Date(dataPoint.date || Date.now())),
    DEFAULT_DATE_FORMAT
  ),
  dateLastYear: dataPoint.date_last_year
    ? format(
        newDateByTimezone(new Date(dataPoint.date_last_year)),
        DEFAULT_DATE_FORMAT
      )
    : null,

  worthSumWs: standarizeTimepointValue(dataPoint.worth_sum_ws),
  worthSumWsChange: standarizeTimepointValue(dataPoint.worth_sum_ws_change),
  worthSumWsChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_ws_change_percentage
  ),
  worthSumWsLastYear: standarizeTimepointValue(
    dataPoint.worth_sum_ws_last_year
  ),

  worthSumWso: standarizeTimepointValue(dataPoint.worth_sum_wso),
  worthSumWsoChange: standarizeTimepointValue(dataPoint.worth_sum_wso_change),
  worthSumWsoChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_wso_change_percentage
  ),
  worthSumWsoLastYear: standarizeTimepointValue(
    dataPoint.worth_sum_wso_last_year
  ),

  worthSumWsa: standarizeTimepointValue(dataPoint.worth_sum_wsa),
  worthSumWsaChange: standarizeTimepointValue(dataPoint.worth_sum_wsa_change),
  worthSumWsaChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_wsa_change_percentage
  ),
  worthSumWsaLastYear: standarizeTimepointValue(
    dataPoint.worth_sum_wsa_last_year
  ),

  worthSumWsWsoWsa: standarizeTimepointValue(dataPoint.worth_sum_ws_wso_wsa),
  worthSumWsWsoWsaChange: standarizeTimepointValue(
    dataPoint.worth_sum_ws_wso_wsa_change
  ),
  worthSumWsWsoWsaChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_ws_wso_wsa_change_percentage
  ),
  worthSumWsWsoWsaLastYear: standarizeTimepointValue(
    dataPoint.worth_sum_ws_wso_wsa_last_year
  ),

  worthSum: standarizeTimepointValue(dataPoint.worth_sum),
  worthSumChange: standarizeTimepointValue(dataPoint.worth_sum_change),
  worthSumChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_change_percentage
  ),
  worthSumLastYear: standarizeTimepointValue(dataPoint.worth_sum_last_year),

  quantitySumWs: standarizeTimepointValue(dataPoint.quantity_sum_ws),
  quantitySumWsChange: standarizeTimepointValue(
    dataPoint.quantity_sum_ws_change
  ),
  quantitySumWsChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_ws_change_percentage
  ),
  quantitySumWsLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_ws_last_year
  ),

  quantitySumWso: standarizeTimepointValue(dataPoint.quantity_sum_wso),
  quantitySumWsoChange: standarizeTimepointValue(
    dataPoint.quantity_sum_wso_change
  ),
  quantitySumWsoChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_wso_change_percentage
  ),
  quantitySumWsoLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_wso_last_year
  ),

  quantitySumWsa: standarizeTimepointValue(dataPoint.quantity_sum_wsa),
  quantitySumWsaChange: standarizeTimepointValue(
    dataPoint.quantity_sum_wsa_change
  ),
  quantitySumWsaChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_wsa_change_percentage
  ),
  quantitySumWsaLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_wsa_last_year
  ),

  quantitySumWsWsoWsa: standarizeTimepointValue(
    dataPoint.quantity_sum_ws_wso_wsa
  ),
  quantitySumWsWsoWsaChange: standarizeTimepointValue(
    dataPoint.quantity_sum_ws_wso_wsa_change
  ),
  quantitySumWsWsoWsaChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_ws_wso_wsa_change_percentage
  ),
  quantitySumWsWsoWsaLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_ws_wso_wsa_last_year
  ),

  quantitySum: standarizeTimepointValue(dataPoint.quantity_sum),
  quantitySumChange: standarizeTimepointValue(dataPoint.quantity_sum_change),
  quantitySumChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_change_percentage
  ),
  quantitySumLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_last_year
  ),

  worthSumPerShopWs: standarizeTimepointValue(dataPoint.worth_sum_per_shop_ws),
  worthSumPerShopWsChange: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_ws_change
  ),
  worthSumPerShopWsChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_ws_change_percentage
  ),
  worthSumPerShopWsLastYear: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_ws_last_year
  ),

  worthSumPerShopWso: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_wso
  ),
  worthSumPerShopWsoChange: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_wso_change
  ),
  worthSumPerShopWsoChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_wso_change_percentage
  ),
  worthSumPerShopWsoLastYear: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_wso_last_year
  ),

  worthSumPerShopWsa: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_wsa
  ),
  worthSumPerShopWsaChange: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_wsa_change
  ),
  worthSumPerShopWsaChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_wsa_change_percentage
  ),
  worthSumPerShopWsaLastYear: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_wsa_last_year
  ),

  worthSumPerShopWsWsoWsa: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_ws_wso_wsa
  ),
  worthSumPerShopWsWsoWsaChange: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_ws_wso_wsa_change
  ),
  worthSumPerShopWsWsoWsaChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_ws_wso_wsa_change_percentage
  ),
  worthSumPerShopWsWsoWsaLastYear: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_ws_wso_wsa_last_year
  ),

  worthSumPerShop: standarizeTimepointValue(dataPoint.worth_sum_per_shop),
  worthSumPerShopChange: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_change
  ),
  worthSumPerShopChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_change_percentage
  ),
  worthSumPerShopLastYear: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_last_year
  ),

  quantitySumPerShopWs: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_ws
  ),
  quantitySumPerShopWsChange: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_ws_change
  ),
  quantitySumPerShopWsChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_ws_change_percentage
  ),
  quantitySumPerShopWsLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_ws_last_year
  ),

  quantitySumPerShopWso: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_wso
  ),
  quantitySumPerShopWsoChange: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_wso_change
  ),
  quantitySumPerShopWsoChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_wso_change_percentage
  ),
  quantitySumPerShopWsoLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_wso_last_year
  ),

  quantitySumPerShopWsa: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_wsa
  ),
  quantitySumPerShopWsaChange: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_wsa_change
  ),
  quantitySumPerShopWsaChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_wsa_change_percentage
  ),
  quantitySumPerShopWsaLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_wsa_last_year
  ),

  quantitySumPerShopWsWsoWsa: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_ws_wso_wsa
  ),
  quantitySumPerShopWsWsoWsaChange: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_ws_wso_wsa_change
  ),
  quantitySumPerShopWsWsoWsaChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_ws_wso_wsa_change_percentage
  ),
  quantitySumPerShopWsWsoWsaLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_ws_wso_wsa_last_year
  ),

  quantitySumPerShop: standarizeTimepointValue(dataPoint.quantity_sum_per_shop),
  quantitySumPerShopChange: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_change
  ),
  quantitySumPerShopChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_change_percentage
  ),
  quantitySumPerShopLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_last_year
  ),

  quantityShareTotalWs: standarizeTimepointValue(
    dataPoint.quantity_share_total_ws
  ),
  quantityShareTotalWsChange: standarizeTimepointValue(
    dataPoint.quantity_share_total_ws_change
  ),
  quantityShareTotalWsChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_share_total_ws_change_percentage
  ),
  quantityShareTotalWsLastYear: standarizeTimepointValue(
    dataPoint.quantity_share_total_ws_last_year
  ),

  quantityShareTotalWso: standarizeTimepointValue(
    dataPoint.quantity_share_total_wso
  ),
  quantityShareTotalWsoChange: standarizeTimepointValue(
    dataPoint.quantity_share_total_wso_change
  ),
  quantityShareTotalWsoChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_share_total_wso_change_percentage
  ),
  quantityShareTotalWsoLastYear: standarizeTimepointValue(
    dataPoint.quantity_share_total_wso_last_year
  ),

  quantityShareTotalWsa: standarizeTimepointValue(
    dataPoint.quantity_share_total_wsa
  ),
  quantityShareTotalWsaChange: standarizeTimepointValue(
    dataPoint.quantity_share_total_wsa_change
  ),
  quantityShareTotalWsaChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_share_total_wsa_change_percentage
  ),
  quantityShareTotalWsaLastYear: standarizeTimepointValue(
    dataPoint.quantity_share_total_wsa_last_year
  ),

  quantityShareTotalWsWsoWsa: standarizeTimepointValue(
    dataPoint.quantity_share_total_ws_wso_wsa
  ),
  quantityShareTotalWsWsoWsaChange: standarizeTimepointValue(
    dataPoint.quantity_share_total_ws_wso_wsa_change
  ),
  quantityShareTotalWsWsoWsaChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_share_total_ws_wso_wsa_change_percentage
  ),
  quantityShareTotalWsWsoWsaLastYear: standarizeTimepointValue(
    dataPoint.quantity_share_total_ws_wso_wsa_last_year
  ),

  worthShareTotalWs: standarizeTimepointValue(dataPoint.worth_share_total_ws),
  worthShareTotalWsChange: standarizeTimepointValue(
    dataPoint.worth_share_total_ws_change
  ),
  worthShareTotalWsChangePercentage: standarizeTimepointValue(
    dataPoint.worth_share_total_ws_change_percentage
  ),
  worthShareTotalWsLastYear: standarizeTimepointValue(
    dataPoint.worth_share_total_ws_last_year
  ),

  worthShareTotalWso: standarizeTimepointValue(dataPoint.worth_share_total_wso),
  worthShareTotalWsoChange: standarizeTimepointValue(
    dataPoint.worth_share_total_wso_change
  ),
  worthShareTotalWsoChangePercentage: standarizeTimepointValue(
    dataPoint.worth_share_total_wso_change_percentage
  ),
  worthShareTotalWsoLastYear: standarizeTimepointValue(
    dataPoint.worth_share_total_wso_last_year
  ),

  worthShareTotalWsa: standarizeTimepointValue(dataPoint.worth_share_total_wsa),
  worthShareTotalWsaChange: standarizeTimepointValue(
    dataPoint.worth_share_total_wsa_change
  ),
  worthShareTotalWsaChangePercentage: standarizeTimepointValue(
    dataPoint.worth_share_total_wsa_change_percentage
  ),
  worthShareTotalWsaLastYear: standarizeTimepointValue(
    dataPoint.worth_share_total_wsa_last_year
  ),

  worthShareTotalWsWsoWsa: standarizeTimepointValue(
    dataPoint.worth_share_total_ws_wso_wsa
  ),
  worthShareTotalWsWsoWsaChange: standarizeTimepointValue(
    dataPoint.worth_share_total_ws_wso_wsa_change
  ),
  worthShareTotalWsWsoWsaChangePercentage: standarizeTimepointValue(
    dataPoint.worth_share_total_ws_wso_wsa_change_percentage
  ),
  worthShareTotalWsWsoWsaLastYear: standarizeTimepointValue(
    dataPoint.worth_share_total_ws_wso_wsa_last_year
  ),

  netQuantityShareTotalWs: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_ws
  ),
  netQuantityShareTotalWsChange: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_ws_change
  ),
  netQuantityShareTotalWsChangePercentage: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_ws_change_percentage
  ),
  netQuantityShareTotalWsLastYear: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_ws_last_year
  ),

  netQuantityShareTotalWso: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_wso
  ),
  netQuantityShareTotalWsoChange: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_wso_change
  ),
  netQuantityShareTotalWsoChangePercentage: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_wso_change_percentage
  ),
  netQuantityShareTotalWsoLastYear: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_wso_last_year
  ),

  netQuantityShareTotalWsa: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_wsa
  ),
  netQuantityShareTotalWsaChange: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_wsa_change
  ),
  netQuantityShareTotalWsaChangePercentage: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_wsa_change_percentage
  ),
  netQuantityShareTotalWsaLastYear: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_wsa_last_year
  ),

  netQuantityShareTotalWsWsoWsa: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_ws_wso_wsa
  ),
  netQuantityShareTotalWsWsoWsaChange: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_ws_wso_wsa_change
  ),
  netQuantityShareTotalWsWsoWsaChangePercentage: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_ws_wso_wsa_change_percentage
  ),
  netQuantityShareTotalWsWsoWsaLastYear: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_ws_wso_wsa_last_year
  ),

  priceAvgWs: standarizeTimepointValue(dataPoint.price_avg_ws),
  priceAvgWsChange: standarizeTimepointValue(dataPoint.price_avg_ws_change),
  priceAvgWsChangePercentage: standarizeTimepointValue(
    dataPoint.price_avg_ws_change_percentage
  ),
  priceAvgWsLastYear: standarizeTimepointValue(
    dataPoint.price_avg_ws_last_year
  ),

  priceAvgWso: standarizeTimepointValue(dataPoint.price_avg_wso),
  priceAvgWsoChange: standarizeTimepointValue(dataPoint.price_avg_wso_change),
  priceAvgWsoChangePercentage: standarizeTimepointValue(
    dataPoint.price_avg_wso_change_percentage
  ),
  priceAvgWsoLastYear: standarizeTimepointValue(
    dataPoint.price_avg_wso_last_year
  ),

  priceAvgWsa: standarizeTimepointValue(dataPoint.price_avg_wsa),
  priceAvgWsaChange: standarizeTimepointValue(dataPoint.price_avg_wsa_change),
  priceAvgWsaChangePercentage: standarizeTimepointValue(
    dataPoint.price_avg_wsa_change_percentage
  ),
  priceAvgWsaLastYear: standarizeTimepointValue(
    dataPoint.price_avg_wsa_last_year
  ),

  priceAvgWsWsoWsa: standarizeTimepointValue(dataPoint.price_avg_ws_wso_wsa),
  priceAvgWsWsoWsaChange: standarizeTimepointValue(
    dataPoint.price_avg_ws_wso_wsa_change
  ),
  priceAvgWsWsoWsaChangePercentage: standarizeTimepointValue(
    dataPoint.price_avg_ws_wso_wsa_change_percentage
  ),
  priceAvgWsWsoWsaLastYear: standarizeTimepointValue(
    dataPoint.price_avg_ws_wso_wsa_last_year
  ),

  priceAvg: standarizeTimepointValue(dataPoint.price_avg),
  priceAvgChange: standarizeTimepointValue(dataPoint.price_avg_change),
  priceAvgChangePercentage: standarizeTimepointValue(
    dataPoint.price_avg_change_percentage
  ),
  priceAvgLastYear: standarizeTimepointValue(dataPoint.price_avg_last_year),

  netQuantitySumWs: standarizeTimepointValue(dataPoint.net_quantity_sum_ws),
  netQuantitySumWsChange: standarizeTimepointValue(
    dataPoint.net_quantity_sum_ws_change
  ),
  netQuantitySumWsChangePercentage: standarizeTimepointValue(
    dataPoint.net_quantity_sum_ws_change_percentage
  ),
  netQuantitySumWsLastYear: standarizeTimepointValue(
    dataPoint.net_quantity_sum_ws_last_year
  ),

  netQuantitySumWso: standarizeTimepointValue(dataPoint.net_quantity_sum_wso),
  netQuantitySumWsoChange: standarizeTimepointValue(
    dataPoint.net_quantity_sum_wso_change
  ),
  netQuantitySumWsoChangePercentage: standarizeTimepointValue(
    dataPoint.net_quantity_sum_wso_change_percentage
  ),
  netQuantitySumWsoLastYear: standarizeTimepointValue(
    dataPoint.net_quantity_sum_wso_last_year
  ),

  netQuantitySumWsa: standarizeTimepointValue(dataPoint.net_quantity_sum_wsa),
  netQuantitySumWsaChange: standarizeTimepointValue(
    dataPoint.net_quantity_sum_wsa_change
  ),
  netQuantitySumWsaChangePercentage: standarizeTimepointValue(
    dataPoint.net_quantity_sum_wsa_change_percentage
  ),
  netQuantitySumWsaLastYear: standarizeTimepointValue(
    dataPoint.net_quantity_sum_wsa_last_year
  ),

  netQuantitySumWsWsoWsa: standarizeTimepointValue(
    dataPoint.net_quantity_sum_ws_wso_wsa
  ),
  netQuantitySumWsWsoWsaChange: standarizeTimepointValue(
    dataPoint.net_quantity_sum_ws_wso_wsa_change
  ),
  netQuantitySumWsWsoWsaChangePercentage: standarizeTimepointValue(
    dataPoint.net_quantity_sum_ws_wso_wsa_change_percentage
  ),
  netQuantitySumWsWsoWsaLastYear: standarizeTimepointValue(
    dataPoint.net_quantity_sum_ws_wso_wsa_last_year
  ),

  netQuantitySales: standarizeTimepointValue(dataPoint.net_quantity_sales),
  netQuantitySalesChange: standarizeTimepointValue(
    dataPoint.net_quantity_sales_change
  ),
  netQuantitySalesChangePercentage: standarizeTimepointValue(
    dataPoint.net_quantity_sales_change_percentage
  ),
  netQuantitySalesLastYear: standarizeTimepointValue(
    dataPoint.net_quantity_sales_last_year
  ),

  avgNetQuantityPerShopWs: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_ws
  ),
  avgNetQuantityPerShopWsChange: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_ws_change
  ),
  avgNetQuantityPerShopWsChangePercentage: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_ws_change_percentage
  ),
  avgNetQuantityPerShopWsLastYear: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_ws_last_year
  ),

  avgNetQuantityPerShopWso: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_wso
  ),
  avgNetQuantityPerShopWsoChange: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_wso_change
  ),
  avgNetQuantityPerShopWsoChangePercentage: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_wso_change_percentage
  ),
  avgNetQuantityPerShopWsoLastYear: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_wso_last_year
  ),

  avgNetQuantityPerShopWsa: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_wsa
  ),
  avgNetQuantityPerShopWsaChange: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_wsa_change
  ),
  avgNetQuantityPerShopWsaChangePercentage: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_wsa_change_percentage
  ),
  avgNetQuantityPerShopWsaLastYear: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_wsa_last_year
  ),

  avgNetQuantityPerShopWsWsoWsa: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_ws_wso_wsa
  ),
  avgNetQuantityPerShopWsWsoWsaChange: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_ws_wso_wsa_change
  ),
  avgNetQuantityPerShopWsWsoWsaChangePercentage: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_ws_wso_wsa_change_percentage
  ),
  avgNetQuantityPerShopWsWsoWsaLastYear: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_ws_wso_wsa_last_year
  ),

  avgNetQuantityPerShop: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop
  ),
  avgNetQuantityPerShopChange: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_change
  ),
  avgNetQuantityPerShopChangePercentage: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_change_percentage
  ),
  avgNetQuantityPerShopLastYear: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_last_year
  ),

  // WS + WSA - these metrics are temporary and should be deleted in the future
  worthSumWsWsa: standarizeTimepointValue(dataPoint.worth_sum_ws_wsa),
  worthSumWsWsaChange: standarizeTimepointValue(
    dataPoint.worth_sum_ws_wsa_change
  ),
  worthSumWsWsaChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_ws_wsa_change_percentage
  ),
  worthSumWsWsaLastYear: standarizeTimepointValue(
    dataPoint.worth_sum_ws_wsa_last_year
  ),

  quantitySumWsWsa: standarizeTimepointValue(dataPoint.quantity_sum_ws_wsa),
  quantitySumWsWsaChange: standarizeTimepointValue(
    dataPoint.quantity_sum_ws_wsa_change
  ),
  quantitySumWsWsaChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_ws_wsa_change_percentage
  ),
  quantitySumWsWsaLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_ws_wsa_last_year
  ),

  worthSumPerShopWsWsa: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_ws_wsa
  ),
  worthSumPerShopWsWsaChange: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_ws_wsa_change
  ),
  worthSumPerShopWsWsaChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_ws_wsa_change_percentage
  ),
  worthSumPerShopWsWsaLastYear: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_ws_wsa_last_year
  ),

  quantitySumPerShopWsWsa: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_ws_wsa
  ),
  quantitySumPerShopWsWsaChange: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_ws_wsa_change
  ),
  quantitySumPerShopWsWsaChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_ws_wsa_change_percentage
  ),
  quantitySumPerShopWsWsaLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_ws_wsa_last_year
  ),

  quantityShareTotalWsWsa: standarizeTimepointValue(
    dataPoint.quantity_share_total_ws_wsa
  ),
  quantityShareTotalWsWsaChange: standarizeTimepointValue(
    dataPoint.quantity_share_total_ws_wsa_change
  ),
  quantityShareTotalWsWsaChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_share_total_ws_wsa_change_percentage
  ),
  quantityShareTotalWsWsaLastYear: standarizeTimepointValue(
    dataPoint.quantity_share_total_ws_wsa_last_year
  ),

  worthShareTotalWsWsa: standarizeTimepointValue(
    dataPoint.worth_share_total_ws_wsa
  ),
  worthShareTotalWsWsaChange: standarizeTimepointValue(
    dataPoint.worth_share_total_ws_wsa_change
  ),
  worthShareTotalWsWsaChangePercentage: standarizeTimepointValue(
    dataPoint.worth_share_total_ws_wsa_change_percentage
  ),
  worthShareTotalWsWsaLastYear: standarizeTimepointValue(
    dataPoint.worth_share_total_ws_wsa_last_year
  ),

  netQuantityShareTotalWsWsa: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_ws_wsa
  ),
  netQuantityShareTotalWsWsaChange: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_ws_wsa_change
  ),
  netQuantityShareTotalWsWsaChangePercentage: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_ws_wsa_change_percentage
  ),
  netQuantityShareTotalWsWsaLastYear: standarizeTimepointValue(
    dataPoint.net_quantity_share_total_ws_wsa_last_year
  ),

  priceAvgWsWsa: standarizeTimepointValue(dataPoint.price_avg_ws_wsa),
  priceAvgWsWsaChange: standarizeTimepointValue(
    dataPoint.price_avg_ws_wsa_change
  ),
  priceAvgWsWsaChangePercentage: standarizeTimepointValue(
    dataPoint.price_avg_ws_wsa_change_percentage
  ),
  priceAvgWsWsaLastYear: standarizeTimepointValue(
    dataPoint.price_avg_ws_wsa_last_year
  ),

  netQuantitySumWsWsa: standarizeTimepointValue(
    dataPoint.net_quantity_sum_ws_wsa
  ),
  netQuantitySumWsWsaChange: standarizeTimepointValue(
    dataPoint.net_quantity_sum_ws_wsa_change
  ),
  netQuantitySumWsWsaChangePercentage: standarizeTimepointValue(
    dataPoint.net_quantity_sum_ws_wsa_change_percentage
  ),
  netQuantitySumWsWsaLastYear: standarizeTimepointValue(
    dataPoint.net_quantity_sum_ws_wsa_last_year
  ),

  avgNetQuantityPerShopWsWsa: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_ws_wsa
  ),
  avgNetQuantityPerShopWsWsaChange: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_ws_wsa_change
  ),
  avgNetQuantityPerShopWsWsaChangePercentage: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_ws_wsa_change_percentage
  ),
  avgNetQuantityPerShopWsWsaLastYear: standarizeTimepointValue(
    dataPoint.avg_net_quantity_per_shop_ws_wsa_last_year
  )
});
