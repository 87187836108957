import { useSelector } from "react-redux";

import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { isPowerUserSelector, tierSelector } from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage } from "utils";
import { CHART_DATA_TYPE, isMultipackWsaEnabled } from "utils/constants";
import { Nullable } from "utils/types";

import { MULTIPACK_TIER_4_HIDDEN_TYPES } from "../utils";

const prepareMetrics = ({
  metric,
  total,
  hasFullAccess
}: {
  metric: string;
  total: Nullable<string>;
  hasFullAccess: boolean;
}) => {
  const suffixes = isMultipackWsaEnabled
    ? ["WS", "WSO", "WSA", "WS_WSO_WSA"]
    : ["WS", "WSO", "WS_WSO"];

  const metrics = suffixes.map(suffix => {
    const value = `${metric}_${suffix}`;
    return {
      value,
      disabled: !hasFullAccess && MULTIPACK_TIER_4_HIDDEN_TYPES.includes(value)
    };
  });

  if (total !== null) {
    metrics.push({
      value: total,
      disabled: !hasFullAccess && MULTIPACK_TIER_4_HIDDEN_TYPES.includes(total)
    });
  }

  return metrics;
};

export const useMultipackDropdown = () => {
  const pathname = useSelector(pathnameSelector);
  const isPowerUser = useSelector(isPowerUserSelector);
  const tier = useSelector(tierSelector);

  if (!isThisPage(pathname, REPORTS_FULL_PATHS.MULTIPACK_PATH)) {
    return [];
  }

  return [
    {
      label: "Wartość",
      metric: CHART_DATA_TYPE.VALUE
    },
    {
      label: "Sztuki",
      metric: CHART_DATA_TYPE.QUANTITY
    },
    {
      label: "Wartość per sklep",
      metric: CHART_DATA_TYPE.VALUE_PER_SHOP
    },
    {
      label: "Sztuki per sklep",
      metric: CHART_DATA_TYPE.QUANTITY_PER_SHOP
    },
    {
      label: "Sprzedaż w jn",
      metric: "NET_QUANTITY_SUM", // this metric is not in CHART_DATA_TYPE
      total: CHART_DATA_TYPE.NET_QUANTITY_SALES
    },
    {
      label: "Jn per sklep",
      metric: CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP
    },
    {
      label: "Średnia cena",
      metric: "PRICE_AVG", // this metric is not in CHART_DATA_TYPE
      total: CHART_DATA_TYPE.AVG_PRICE
    },
    {
      label: "Udział wartościowy w sprzedaży total",
      metric: CHART_DATA_TYPE.VALUE_SHARES,
      total: null
    },
    {
      label: "Udział ilościowy w sprzedaży total",
      metric: CHART_DATA_TYPE.QUANTITY_SHARES,
      total: null
    },
    {
      label: "Udział ilościowy w sprzedaży total w jn",
      metric: CHART_DATA_TYPE.NET_QUANTITY_SHARE,
      total: null
    }
  ].map(item => ({
    label: item.label,
    metrics: prepareMetrics({
      metric: item.metric,
      total: typeof item.total === "undefined" ? item.metric : item.total,
      hasFullAccess: isPowerUser || tier !== 4
    })
  }));
};
