import { useGetLocationByPeriod } from "api/location/mappers/useGetLocationByPeriod";
import { useGetRanking } from "api/ranking/hooks";

export const useIsLocationsLoading = () => {
  const {
    isLoading: isLocationsLoading,
    isIdle: isLocationsIdle
  } = useGetLocationByPeriod();
  const { isLoading: rankingLoading } = useGetRanking();

  return isLocationsLoading || isLocationsIdle || rankingLoading;
};
