import {
  HoursSummary,
  HoursSummaryApi
} from "pages/Reports/sections/Hours/types";

export const normalizeHoursSummary = (
  summaryApi: HoursSummaryApi
): HoursSummary => {
  return {
    bestDay: summaryApi.best_day || null,
    bestDayLy: summaryApi.best_day_last_year || null,

    bestHour: summaryApi.best_hour || null,
    bestHourLy: summaryApi.best_hour_last_year || null,

    worstDay: summaryApi.worst_day || null,
    worstDayLy: summaryApi.worst_day_last_year || null,

    worstHour: summaryApi.worst_hour || null,
    worstHourLy: summaryApi.worst_hour_last_year || null,

    bestDate: summaryApi.best_date || null,
    bestDateLy: summaryApi.best_date_last_year || null
  };
};
