import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import {
  changeMainChartDataType,
  changeSecondChartDataType
} from "pages/Reports/redux/actions/updateReportsQuery";
import { usePromotionsOptions } from "pages/Reports/sections/NewChartDropdown/hooks";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { getPolishLabelForChart, isThisPage } from "utils";
import { pushLogisticsReportAction } from "utils/googleTagManager/dataLayer";

import { DropdownType } from "./useDropdownTypes";

const { LOGISTICS_PATH } = REPORTS_FULL_PATHS;

export const useTypeChangeAction = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const promotions = usePromotionsOptions();

  const base = {
    first: (type: ChartDataTypes, dropdown: DropdownType) =>
      dispatch(changeMainChartDataType(type, dropdown)),
    second: (type: ChartDataTypes, dropdown: DropdownType) =>
      dispatch(changeSecondChartDataType(type, dropdown)),
    third: promotions.onChange,
    thirdAll: promotions.onSelectAll,
    thirdClear: promotions.onClear
  };

  if (isThisPage(pathname, LOGISTICS_PATH)) {
    return {
      ...base,
      first: (type: ChartDataTypes, dropdown: DropdownType) => {
        base.first(type, dropdown);
        pushLogisticsReportAction(`miara - ${getPolishLabelForChart(type)}`);
      },
      second: (type: ChartDataTypes, dropdown: DropdownType) => {
        base.second(type, dropdown);
        pushLogisticsReportAction(`miara - ${getPolishLabelForChart(type)}`);
      }
    };
  }

  return base;
};
