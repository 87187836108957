import React, { SetStateAction, useState } from "react";

import { Formik } from "formik";
import { motion } from "framer-motion";
import * as yup from "yup";

import {
  Button,
  Heading,
  Portal,
  SpinnerLoader,
  Text,
  ToggleButton
} from "components/atoms";
import { ChevronRight, Close } from "components/atoms/Icon";
import {
  Card,
  PopupWindow,
  Textarea,
  ToggleButtonList
} from "components/molecules";
import { sendSingleInsightFeedback } from "pages/Insights/utils/analytics";
import { InsightRateLevel } from "pages/Insights/utils/types";
import {
  HEADER_ELEMENTS,
  HTTP,
  ICON_SIZES,
  REST_API_ENDPOINTS,
  TEXT_WEIGHTS
} from "utils";
import { pushInsightsAction } from "utils/googleTagManager/dataLayer";
import { Nullable } from "utils/types";

import s from "./insightsModal.module.scss";
import { ThanksModal } from "./ThanksModal/ThanksModal";

const usefullnessOptions = Array.from(Array(11).keys());

interface InsightModalFormData {
  rate: Nullable<number>;
  suggestedExtraContent: string;
  suggestedExtraConditions: string;
}

type InsightModalInputsIds =
  | "rate"
  | "suggestedExtraContent"
  | "suggestedExtraConditions";

interface InsightModalInputs {
  label: string;
  id: InsightModalInputsIds;
  type: string;
  isOptional: boolean;
}

const INPUTS: InsightModalInputs[] = [
  {
    label: "1. Jak oceniasz przydatność tego insightu?",
    id: "rate",
    type: "rating",
    isOptional: false
  },
  {
    label:
      "2. Jakie dodatkowe informacje powinny być zawarte w treści i / lub tytule?",
    id: "suggestedExtraContent",
    type: "text",
    isOptional: true
  },
  {
    label:
      "3. Jakie jeszcze uwarunkowania (np. wykluczenia) powinniśmy wziąć pod uwagę przy tworzeniu insightu, żeby podnieść jego wartość?",
    id: "suggestedExtraConditions",
    type: "text",
    isOptional: true
  }
];

const insightsModalValidation = yup.object().shape({
  rate: yup
    .number()
    .nullable(true)
    .required("Poprosimy przynajmniej o ocenę przydatności insightu 😃"),
  suggestedExtraContent: yup.string(),
  suggestedExtraConditions: yup.string()
});

interface Props {
  id: string;
  onClose: (value: SetStateAction<boolean>) => void;
  setRateLevel: (value: SetStateAction<InsightRateLevel>) => void;
}

export const InsightsModal = ({ id, onClose, setRateLevel }: Props) => {
  const [shouldThanksShow, setShouldThanksShow] = useState(false);

  const initialFormData: InsightModalFormData = {
    rate: null,
    suggestedExtraContent: "",
    suggestedExtraConditions: ""
  };

  const submitAnalytics = ({
    rate,
    suggestedExtraContent,
    suggestedExtraConditions
  }: InsightModalFormData) => {
    if (rate) {
      sendSingleInsightFeedback("SURVEY_NPS", id, String(rate));
    }

    if (suggestedExtraContent) {
      sendSingleInsightFeedback("SURVEY_QUESTION_1", id, suggestedExtraContent);
    }

    if (suggestedExtraConditions) {
      sendSingleInsightFeedback(
        "SURVEY_QUESTION_2",
        id,
        suggestedExtraConditions
      );
    }
  };

  const submitUserForm = async (values: InsightModalFormData) => {
    const params = {
      rate: values.rate,
      suggested_extra_content: values.suggestedExtraContent,
      suggested_extra_conditions: values.suggestedExtraConditions
    };

    try {
      await HTTP.put<{ insight_id: string }>(
        `${REST_API_ENDPOINTS.INSIGHTS.RATE}${id}/`,
        params
      );
      submitAnalytics(values);
      pushInsightsAction("click", "ankieta - wysłanie");
      setRateLevel(2);
      setShouldThanksShow(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Portal id="js-clear-filter-confirm-modal">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <PopupWindow className={s.modalContainer}>
          <motion.div
            initial={{ scale: 0.4 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            {shouldThanksShow ? (
              <ThanksModal
                shouldThanksShow={shouldThanksShow}
                onClose={onClose}
              />
            ) : (
              <Formik
                initialValues={initialFormData}
                onSubmit={submitUserForm}
                validationSchema={insightsModalValidation}
                validateOnChange={false}
                validateOnBlur={true}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  errors,
                  handleChange,
                  values,
                  setFieldValue
                }) => {
                  return (
                    <Card
                      className={{ base: s.container }}
                      testId="insights-rate-modal"
                    >
                      <form onSubmit={isSubmitting ? () => null : handleSubmit}>
                        <div className={s.headerContainer}>
                          <Heading
                            type={HEADER_ELEMENTS.H3}
                            className={s.heading}
                          >
                            Ankieta
                          </Heading>
                          <button
                            className={s.closeButton}
                            type="button"
                            onClick={() => onClose(false)}
                            data-testid="insights-rate-modal-close"
                          >
                            <Close
                              className={s.closeIcon}
                              size={ICON_SIZES.EXTRA_SMALL}
                            />
                          </button>
                        </div>

                        {INPUTS.map(input => {
                          return (
                            <div className={s.inputWrapper}>
                              <Text
                                className={s.labelText}
                                weight={TEXT_WEIGHTS.BOLD}
                              >
                                {input.label}
                              </Text>
                              {input.isOptional && (
                                <Text
                                  className={s.optionalText}
                                  weight={TEXT_WEIGHTS.NORMAL}
                                >
                                  (opcjonalnie)
                                </Text>
                              )}
                              {input.id === "rate" ? (
                                <>
                                  <ToggleButtonList
                                    className={s.rating}
                                    testId={`insights-modal-${input.id}`}
                                  >
                                    {usefullnessOptions.map(option => {
                                      return (
                                        <ToggleButton
                                          className={{
                                            li: s.toggleButtonLi
                                          }}
                                          onClick={() => {
                                            setFieldValue(input.id, option);
                                            pushInsightsAction(
                                              "click",
                                              `ankieta - NPS ${String(option)}`
                                            );
                                            sendSingleInsightFeedback(
                                              "SURVEY_NPS",
                                              id,
                                              String(option)
                                            );
                                          }}
                                          label={String(option)}
                                          isActive={values.rate === option}
                                        />
                                      );
                                    })}
                                  </ToggleButtonList>
                                  <div className={s.ratingScaleTextContainer}>
                                    <Text
                                      className={s.ratingScaleText}
                                      weight={TEXT_WEIGHTS.NORMAL}
                                    >
                                      Całkowicie nieprzydatny
                                    </Text>
                                    <Text
                                      className={s.ratingScaleText}
                                      weight={TEXT_WEIGHTS.NORMAL}
                                    >
                                      Bardzo przydatny
                                    </Text>
                                  </div>
                                  {errors[input.id] && (
                                    <Text className={s.errorText}>
                                      {errors[input.id]}
                                    </Text>
                                  )}
                                </>
                              ) : (
                                <Textarea
                                  id={input.id}
                                  className={{ input: s.inputText }}
                                  onChange={handleChange}
                                  value={values[input.id]}
                                  error={errors[input.id]}
                                  testId={`insights-modal-${input.id}`}
                                />
                              )}
                            </div>
                          );
                        })}

                        <div className={s.buttonsWrapper}>
                          {isSubmitting ? (
                            <SpinnerLoader />
                          ) : (
                            <>
                              <button
                                onClick={() => onClose(false)}
                                type="button"
                                className={s.cancelButton}
                              >
                                Anuluj
                              </button>
                              <Button
                                type="submit"
                                className={s.actionButton}
                                testId="insights-modal-send"
                              >
                                Wyślij
                                <ChevronRight
                                  size={ICON_SIZES.LARGE}
                                  className={s.chevronRightIcon}
                                />
                              </Button>
                            </>
                          )}
                        </div>
                      </form>
                    </Card>
                  );
                }}
              </Formik>
            )}
          </motion.div>
        </PopupWindow>
      </motion.div>
    </Portal>
  );
};
