import * as React from "react";

import cn from "classnames";

import { INPUT_TYPE, TEXT_WEIGHTS, TEXT_WRAPPERS } from "utils";

import { InputText } from "../";
import { Text } from "../../atoms";
import { ChevronDown, ChevronUp, Clear, TooltipIcon } from "../../atoms/Icon";
import { PropGetter } from "../types";
import s from "./filterDropdownInput.module.scss";

type ToggleButtonPropsGetter = PropGetter<"getToggleButtonProps">;
type LabelPropGetter = PropGetter<"getLabelProps">;
type InputPropGetter = PropGetter<"getInputProps">;

interface Props {
  isDisabled?: boolean;
  className?: {
    base?: string;
    label?: string;
    toggleButton?: string;
    labelText?: string;
    inputContainer?: string;
    input?: string;
  };
  openedMenuPlaceholder?: string;
  closedMenuPlaceholder?: string;
  label?: string;
  getToggleButtonProps?: ToggleButtonPropsGetter;
  getLabelProps?: LabelPropGetter;
  getInputProps?: InputPropGetter;
  isTypingEnabled?: boolean;
  inputValue?: string;
  isOpen?: boolean;
  automaticInputValue?: string;
  customIcon?: React.ReactElement<any, any>;
  onInputValueChange?: (value: string) => void;
  onInputClear?: () => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  openMenu?: () => void;
  closeMenu?: () => void;
  highlightedIndex?: number | null;
  required?: boolean;
  testId?: string;
  tooltip?: string;
  labelTooltip?: string;
}

export function FilterDropdownInput({
  className = {},
  isDisabled,
  openedMenuPlaceholder = "",
  closedMenuPlaceholder = "",
  label,
  isTypingEnabled = false,
  onClick = () => {},
  isOpen,
  onBlur = () => {},
  onInputValueChange = () => {},
  onInputClear = () => {},
  openMenu = () => {},
  closeMenu = () => {},
  automaticInputValue,
  inputValue,
  customIcon,
  highlightedIndex,
  required = false,
  testId,
  tooltip,
  labelTooltip,
  ...props
}: Props) {
  const getInputProps = props.getInputProps as InputPropGetter;
  const getToggleButtonProps = props.getToggleButtonProps as ToggleButtonPropsGetter;
  const getLabelProps = props.getLabelProps as LabelPropGetter;

  const [showClearButton, setShowClearButton] = React.useState(!!inputValue);

  const input = React.useRef<HTMLInputElement>(null);
  const clearButton = React.useRef<HTMLButtonElement>(null);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget !== clearButton.current) {
      setShowClearButton(false);
    }

    onBlur(e);
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    openMenu();
    onClick(e);
  };

  const handleLabelClick = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    // prevent focusing input on label click
    // @ts-ignore this is not included in downshift definition but necessary here
    // https://github.com/downshift-js/downshift#customizing-handlers
    e.nativeEvent.preventDownshiftDefault = true;
    e.preventDefault();

    if (isOpen) {
      closeMenu();
    }
  };

  const handleClear = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    onInputValueChange(""); // TODO: it doesn't work, replace it with `onInputClear`
    onInputClear();
    setShowClearButton(false);
    input.current && input.current.focus();
  };

  return (
    <div className={cn(s.base, className.base)}>
      {label && (
        <label
          {...getLabelProps({
            className: cn(s.label, className.label),
            onClick: handleLabelClick
          })}
        >
          <Text
            size="small"
            className={cn(s.labelText, className.labelText)}
            weight={TEXT_WEIGHTS.BOLD}
          >
            {label}
            {required && (
              <Text Wrapper={TEXT_WRAPPERS.SPAN} className={s.requiredSign}>
                *
              </Text>
            )}
          </Text>
          {labelTooltip && (
            <div className={s.labelTooltip} data-tooltip={labelTooltip}>
              <TooltipIcon />
            </div>
          )}
        </label>
      )}
      <div
        data-testid={testId}
        {...(tooltip ? { "data-tooltip": tooltip } : {})}
        className={cn(s.inputContainer, className.inputContainer)}
      >
        {isTypingEnabled || isDisabled || <div className={s.inputBlocker} />}
        <button
          {...getToggleButtonProps({
            className: cn(s.toggleButton, className.toggleButton),
            disabled: isDisabled
          })}
        >
          {customIcon || null}
          {!customIcon &&
            (isOpen ? (
              <ChevronUp size="large" />
            ) : (
              <ChevronDown size="large" />
            ))}
        </button>
        {showClearButton && (
          <button
            className={s.clearButton}
            ref={clearButton}
            onClick={handleClear}
            type="button"
          >
            <Clear />
          </button>
        )}
        <InputText
          {...getInputProps({
            className: {
              input: cn(s.input, className.input, {
                [s.inputWithClearButton]: showClearButton
              })
            },
            disabled: isDisabled,
            placeholder: isOpen ? openedMenuPlaceholder : closedMenuPlaceholder,
            onBlur: handleBlur,
            onClick: handleClick,
            ref: input,
            type: INPUT_TYPE.TEXT,
            onKeyDown: e => {
              if (inputValue !== "") {
                setShowClearButton(true);
              }
              if (e.key === "Enter") {
                // @ts-ignore this is not included in downshift definition but necessary here
                // https://github.com/downshift-js/downshift#customizing-handlers
                e.nativeEvent.preventDownshiftDefault = true;
                input.current?.blur();
              }
            },
            onChange: e => {
              //@ts-ignore it says value doesnt exist on e.target, but it does
              const value = e.target.value;

              if (value === "") {
                setShowClearButton(false);
              }
            }
          })}
        />
      </div>
    </div>
  );
}
