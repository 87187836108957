import { POLISH_MONTH_NAMES } from "./constants";

export const getPolishMonthName = (monthIndex: number, isShort?: boolean) => {
  const month = POLISH_MONTH_NAMES[monthIndex];

  if (!month) {
    throw new Error(
      `Invalid monthIndex for getPolishName util, number between 0-11 was expected but ${monthIndex} was given.`
    );
  }
  return isShort ? month.slice(0, 3) : month;
};
