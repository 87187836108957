import { useSelector } from "react-redux";

import {
  hoveredTimepointPositionSelector,
  pinnedTimepointPositionSelector
} from "pages/Reports/redux/reducers/chartReducer";

export const curtainId = "curtain-clip-path";
const topMargin = 10; // add some top margin to prevent cropping lines

export const Curtain = ({
  gridWidth,
  gridHeight,
  domain
}: {
  gridWidth: number;
  gridHeight: number;
  domain: string[];
}) => {
  const hoveredTimepointX = useSelector(hoveredTimepointPositionSelector);
  const pinnedTimepointX = useSelector(pinnedTimepointPositionSelector);

  const positionX = pinnedTimepointX ?? hoveredTimepointX;
  const timepoint = gridWidth / domain.length;
  const width = positionX === null ? 0 : Math.max(0, positionX - timepoint / 2);

  return (
    <clipPath id={curtainId}>
      <rect width={width} height={gridHeight + topMargin} y={-topMargin} />
    </clipPath>
  );
};
