import { DropdownItem } from "components/molecules/types";
import { isAlreadySelected, isIncludedInInput } from "store/utils/filtersUtils";
import { Nullable } from "utils/types";

export const isActiveFn = <T extends DropdownItem>(
  item: T,
  selectedItems: T[] | T
) => {
  if (Array.isArray(selectedItems)) {
    return Boolean(
      selectedItems &&
        selectedItems.length &&
        selectedItems.find(({ value }) => value === item.value)
    );
  }

  return selectedItems.value === item.value;
};

export const filterItems = <T extends DropdownItem>(inputValue: string) => (
  item: T
) => {
  const itemLabelIncludesInputValue = item.label
    .toLowerCase()
    .includes(inputValue.toLowerCase());

  return itemLabelIncludesInputValue;
};

export const getSelectedCountLabel = (
  selectedItemsCount: number,
  allItemsCount: number,
  isMultiChoice: boolean = true
) => {
  if (selectedItemsCount === 0) {
    return "";
  }
  if (!isMultiChoice) {
    return `Wybrano ${selectedItemsCount}`;
  }

  return selectedItemsCount > 0
    ? `Wybrano ${selectedItemsCount} z ${allItemsCount}`
    : "";
};

type Element<T> = T & { value: string | number; label: string };

export function selectElement<T extends {}>(
  newElement: Nullable<Element<T>>,
  selectedElements: Element<T>[]
): T[] {
  if (!newElement) {
    return [];
  }

  const isItemAlreadySelected = selectedElements.some(
    ({ value }) => value === newElement.value
  );

  if (isItemAlreadySelected) {
    return selectedElements.filter(({ value }) => value !== newElement.value);
  }

  return [...selectedElements, newElement];
}

export function getMatched<T extends {}>(
  selected: Element<T>[],
  ids: string[] | number[]
) {
  return selected.filter(county =>
    ids.map(Number).includes(Number(county.value))
  );
}

export function remainingElementsToSelect<T extends DropdownItem>(
  selected: T[],
  all: T[],
  inputValue: string
) {
  return all.filter(
    item =>
      !isAlreadySelected(selected, item) && isIncludedInInput(inputValue, item)
  );
}
