import { useMemo } from "react";

import { addDays, getISOWeek, getWeek, isSameMonth } from "date-fns";

import { newDateByTimezone } from "store/utils";
import { PERIOD_TYPE, PROMO_WEEK_START_DAY } from "utils/constants";

interface Props {
  height: number;
  width: number;
  period: string;
  domain: string[];
}

const GRAY = "#888888";

function transformDates(dates: Date[], isWeekAggregation: boolean) {
  if (!isWeekAggregation) return dates;
  return dates.map(date => addDays(date, 6)); // take into account only the date when the week ends
}

function getUniqDatesByMonth(dates: Date[]) {
  const uniqDatesByMonth: Date[] = [];
  dates.forEach(date => {
    if (!uniqDatesByMonth.some(d => isSameMonth(d, date))) {
      uniqDatesByMonth.push(date);
    }
  });
  return uniqDatesByMonth;
}

export const BottomAxis = ({ height, width, period, domain }: Props) => {
  const dates = domain.map(newDateByTimezone);
  const isDay = period === PERIOD_TYPE.DAYS;
  const isWeek = period === PERIOD_TYPE.WEEKS;
  const isPromoWeek = period === PERIOD_TYPE.PROMO_WEEKS;
  const isYear = period === PERIOD_TYPE.YEAR;
  const isTotal = period === PERIOD_TYPE.TOTAL;
  const isWeekAggregation = isWeek || isPromoWeek;
  const isMonthAggregation =
    period === PERIOD_TYPE.MONTHS ||
    ((isDay || isWeek || isPromoWeek) && dates.length > 31);

  const ticks = useMemo(() => {
    if (isMonthAggregation) {
      const uniqDatesByMonth = getUniqDatesByMonth(
        transformDates(dates, isWeek || isPromoWeek)
      );
      const months = uniqDatesByMonth.map(date =>
        date.toLocaleString("pl-PL", { month: "short" })
      );
      const timepoint = width / months.length;
      return {
        dates: uniqDatesByMonth,
        items: months.map((month, index) => ({
          // show every 3rd month in case of more than 24 months to avoid overlapping
          value: months.length > 24 ? (index % 3 === 0 ? month : "") : month,
          x: timepoint * index + timepoint / 2,
          fill: GRAY,
          weight: 400
        }))
      };
    } else if (isDay) {
      const timepoint = width / dates.length;
      return {
        dates,
        items: dates.map((date, index) => {
          const isSunday = date.getDay() === 0;
          return {
            value: String(date.getDate()),
            x: timepoint * index + timepoint / 2,
            fill: isSunday ? "#fb4934" : GRAY,
            weight: isSunday ? 600 : 400
          };
        })
      };
    } else if (isWeek || isPromoWeek) {
      const weeks = dates.map(date => {
        if (isPromoWeek) {
          return getWeek(date, { weekStartsOn: PROMO_WEEK_START_DAY });
        }
        return getISOWeek(date);
      });
      const timepoint = width / weeks.length;
      return {
        dates,
        items: weeks.map((week, index) => ({
          value: String(week),
          x: timepoint * index + timepoint / 2,
          fill: GRAY,
          weight: 400
        }))
      };
    } else if (isYear) {
      const years = dates.map(date => date.getFullYear());
      const timepoint = width / years.length;
      return {
        dates,
        items: years.map((year, index) => ({
          value: String(year),
          x: timepoint * index + timepoint / 2,
          fill: GRAY,
          weight: 400
        }))
      };
    } else if (isTotal) {
      return {
        dates,
        items: [
          {
            value: "Total",
            x: width / 2,
            fill: GRAY,
            weight: 400
          }
        ]
      };
    } else {
      return { dates, items: [] };
    }
  }, [
    dates,
    isDay,
    isMonthAggregation,
    isPromoWeek,
    isTotal,
    isWeek,
    isYear,
    width
  ]);

  const otherTicks = useMemo(() => {
    let months: { value: string; x: number }[] = [];
    let years: { value: string; x: number }[] = [];
    const dates = transformDates(ticks.dates, isWeekAggregation);
    const timepoint = width / dates.length;
    if (isWeekAggregation) {
      dates.forEach((date, index) => {
        const month = date.toLocaleString("pl-PL", { month: "short" });
        if (!months.find(m => m.value === month)) {
          months.push({
            value: month,
            x: timepoint * index + timepoint / 2
          });
        }
      });
    }
    if (isWeekAggregation || isMonthAggregation) {
      dates.forEach((date, index) => {
        const year = String(date.getFullYear());
        if (!years.find(y => y.value === year)) {
          years.push({
            value: year,
            x: timepoint * index + timepoint / 2
          });
        }
      });
    }
    return { months, years };
  }, [isWeekAggregation, ticks.dates, width, isMonthAggregation]);

  const showMonths = !isMonthAggregation && isWeekAggregation;
  const showYears = isMonthAggregation || isWeekAggregation;

  return (
    <g transform={`translate(0, ${height})`}>
      {ticks.items.map((tick, index) => (
        <text
          key={`tick-${index}`}
          transform={`translate(${tick.x}, 20)`}
          fill={tick.fill}
          fontSize={12}
          fontWeight={tick.weight}
          textAnchor="middle"
        >
          {tick.value}
        </text>
      ))}
      {showMonths
        ? otherTicks.months.map((tick, index) => (
            <text
              key={`month-tick-${index}`}
              transform={`translate(${tick.x}, 30)`}
              fill="#74859f"
              fontSize={12}
              textAnchor="middle"
            >
              {tick.value}
            </text>
          ))
        : null}
      {showYears
        ? otherTicks.years.map((tick, index) => (
            <text
              key={`year-tick-${index}`}
              transform={`translate(${tick.x}, ${showMonths ? 42 : 32})`}
              fill="#515151"
              fontSize={12}
              fontWeight={600}
              textAnchor="middle"
            >
              {tick.value}
            </text>
          ))
        : null}
    </g>
  );
};
