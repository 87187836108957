import { DynamicsApi } from "api/dynamics/dtos/dynamics.dto";

import { appendInsightId } from "pages/Insights/partials/InsightsBox/utils";
import { DynamicsDataset } from "pages/Reports/sections/Dynamics/redux/types";
import { getOwnSummary, getReferenceSummary } from "pages/Reports/utils";
import { createDatasetObject } from "pages/Reports/utils/createDatasetObject";
import { normalizeDynamicsResponse } from "pages/Reports/utils/normalize/normalizeDynamicsResponse";
import { normalizeDynamicsSummary } from "pages/Reports/utils/normalize/normalizeDynamicsSummary";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { pushReportFetchEvent } from "utils/googleTagManager/dataLayer";
import { Periods } from "utils/types";

export const dynamicsService = {
  fetchDynamicsSales: async (queryString: string, period: Periods) => {
    const url = appendInsightId(
      `${REST_API_ENDPOINTS.REPORTS.DYNAMIC_SALES}?${queryString}`
    );
    const { data } = await HTTP.get<DynamicsApi>(url);

    const ownSummary = getOwnSummary(data.summary);
    const referenceSummary = getReferenceSummary(data.summary);
    const standardizedData: DynamicsDataset = createDatasetObject({
      query: queryString,
      currentPeriod: normalizeDynamicsResponse(data.data),
      ownSummary: normalizeDynamicsSummary(ownSummary),
      referenceSummary: referenceSummary
        ? normalizeDynamicsSummary(referenceSummary)
        : null,
      periodType: period,
      info: data.info
    });

    pushReportFetchEvent("Dynamika");

    return standardizedData;
  }
};
