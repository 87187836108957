import { useMemo, useState } from "react";
import Measure, { ContentRect } from "react-measure";
import { useSelector } from "react-redux";

import {
  useIsSegmentsLoading,
  useSegmentsChartData,
  useSegmentsWarnings,
  useVisibleSegments
} from "api/segments/mappers";
import cn from "classnames";

import { CardContent, CardHeader } from "components/atoms";
import s from "components/common.module.scss";
import { Card, ChartSectionHeader } from "components/molecules";
import { ChartHeader } from "components/molecules/ChartHeader/ChartHeader";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { TilesDropdown } from "components/organisms/TilesDropdown/TilesDropdown";
import { LegendTab } from "pages/Reports/partials/Chart/Legend/Legend/Legend";
import { LegendItem } from "pages/Reports/partials/Chart/Legend/LegendItems/LegendItems";
import { SegmentsLegend } from "pages/Reports/partials/Chart/Legend/SegmentsLegend/SegmentsLegend";
import { ChartSection } from "pages/Reports/partials/Chart/StandardChart/ChartSection";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { ShowDatasetSelect } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/ShowDatasetSelect";
import { ZappkaTransactionSelect } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import { useChartValueTilesVisibility } from "pages/Reports/partials/Chart/StandardChart/utils/useChartValueTilesSettings";
import {
  hiddenChartsSelector,
  hiddenLinesSelector
} from "pages/Reports/redux/reducers/chartReducer";
import { isShowDatasetSelectEnabledSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { SECTIONS } from "pages/Reports/utils";
import {
  groupBySegmentsQPSelector,
  isGroupedBySegmentsSelector
} from "store/selectors/routerSelectors";
import { BOOL_STRING_VALUES } from "utils/constants";

import { GroupByOptions } from "./GroupByOptions";
import c from "./segmentsChartSection.module.scss";
import {
  createLegendItem,
  filterLegendItem,
  getUnknownShortLabels,
  SEGMENTS_SHORT_LABELS
} from "./utils";

const UNKNOWN_LABEL = "Pozostałe";

const useLegendTab = () => {
  const groupBySegmentsQP = useSelector(groupBySegmentsQPSelector);
  const [tabIndex, setTabIndex] = useState(
    groupBySegmentsQP === BOOL_STRING_VALUES.TRUE ? 1 : 0
  );

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const handleOptionChange = (value: string) => {
    setTabIndex(value === BOOL_STRING_VALUES.TRUE ? 1 : 0);
  };

  return { tabIndex, handleTabChange, handleOptionChange };
};

export const createSegmentsLegendTabs = (
  hiddenLines: string[],
  visibleSegments: { shortLabel: string; longLabel: string }[]
): LegendTab[] => {
  const createLegendItemCurry = (shortLabel: string) =>
    createLegendItem(shortLabel, visibleSegments, hiddenLines);
  const filterLegendItemCurry = (item: LegendItem) =>
    filterLegendItem(item, visibleSegments);

  return [
    {
      label: SECTIONS.SEGMENTS,
      disabled: false,
      sections: [
        {
          label: "Sklepy specjalistyczne",
          items: [
            SEGMENTS_SHORT_LABELS.A1,
            SEGMENTS_SHORT_LABELS.A2,
            SEGMENTS_SHORT_LABELS.A3,
            SEGMENTS_SHORT_LABELS.A4,
            SEGMENTS_SHORT_LABELS.A5
          ]
            .map(createLegendItemCurry)
            .filter(filterLegendItemCurry)
        },
        {
          label: "Sklepy spożywcze",
          items: [
            SEGMENTS_SHORT_LABELS.O1,
            SEGMENTS_SHORT_LABELS.O2,
            SEGMENTS_SHORT_LABELS.O3
          ]
            .map(createLegendItemCurry)
            .filter(filterLegendItemCurry)
        },
        {
          label: "Sklepy trafficowe",
          items: [
            SEGMENTS_SHORT_LABELS.T1,
            SEGMENTS_SHORT_LABELS.T2,
            SEGMENTS_SHORT_LABELS.T3,
            SEGMENTS_SHORT_LABELS.T4,
            SEGMENTS_SHORT_LABELS.K
          ]
            .map(createLegendItemCurry)
            .filter(filterLegendItemCurry)
        },
        {
          label: UNKNOWN_LABEL,
          items: [
            SEGMENTS_SHORT_LABELS.NC,
            SEGMENTS_SHORT_LABELS.NO,
            SEGMENTS_SHORT_LABELS.NT,
            ...getUnknownShortLabels(visibleSegments)
          ]
            .map(createLegendItemCurry)
            .filter(filterLegendItemCurry)
        }
      ].filter(seg => seg.items.length > 0)
    },
    {
      label: "Produkty / Kategorie",
      disabled: false,
      sections: []
    }
  ];
};

export const SegmentsChartSection = () => {
  const [cardWidth, setCardWidth] = useState(0);
  const hiddenLines = useSelector(hiddenLinesSelector).segments;
  const hiddenCharts = useSelector(hiddenChartsSelector);
  const isGroupedBySegments = useSelector(isGroupedBySegmentsSelector);
  const isShowDatasetSelectEnabled = useSelector(
    isShowDatasetSelectEnabledSelector
  );
  const visibleSegments = useVisibleSegments();
  const isLoading = useIsSegmentsLoading();
  const [isTilesSelectionEnabled] = useChartValueTilesVisibility();
  const tabs = useMemo(
    () =>
      createSegmentsLegendTabs(
        isGroupedBySegments ? hiddenCharts : hiddenLines,
        visibleSegments
      ),
    [hiddenCharts, hiddenLines, isGroupedBySegments, visibleSegments]
  );

  const lineChart = useSegmentsChartData({});
  const { both: isBothDatasets } = useCurrentDataset();
  const { isGroupedBarChart } = useChartTypeBoolean();
  const historicalCharts = useSegmentsChartData({ isForHistoricalChart: true });
  const warnings = useSegmentsWarnings();
  const { tabIndex, handleTabChange, handleOptionChange } = useLegendTab();

  const isHistoricalGroupedBarChartVisible = (() => {
    const charts = historicalCharts[0]?.charts;
    // [PMD-4031]: display regular bar chart on segments due to differences in domain
    if (charts?.showBarChart) return false;
    return Boolean(charts?.domain[0] && isBothDatasets && isGroupedBarChart);
  })();

  const displayTilesDropdown = isTilesSelectionEnabled && lineChart.length > 0;

  const handleCardResize = ({ entry }: ContentRect) => {
    setCardWidth(entry?.width || 0);
  };

  return (
    <section className={s.section}>
      <Measure onResize={handleCardResize}>
        {({ measureRef }) => (
          <div ref={measureRef}>
            <Card className={{ base: s.card }} isLoading={isLoading}>
              <CardHeader className={s.cardHeader}>
                <ChartHeader />
              </CardHeader>
              <CardContent>
                <ChartSectionHeader warnings={warnings} isLoading={isLoading} />
                <div className={c.optionsWrapper}>
                  {isShowDatasetSelectEnabled && <ShowDatasetSelect />}
                  <GroupByOptions onOptionChange={handleOptionChange} />
                  <ZappkaTransactionSelect />
                  {displayTilesDropdown && (
                    <TilesDropdown lineChart={lineChart[0].charts} />
                  )}
                </div>
                <div
                  className={cn(s.chart, c.segmentsChart)}
                  style={{
                    gridTemplateColumns: `repeat(${Math.min(
                      lineChart.length || 1,
                      2
                    )}, 1fr)`
                  }}
                >
                  {lineChart.map((chart, index) => (
                    <div key={chart.name} className={c.chart}>
                      <h2
                        className={c.chartName}
                        data-testid={`segment-chart-label-${chart.name}`}
                      >
                        {chart.name}
                      </h2>

                      {!chart?.charts?.leftChart &&
                      !chart?.charts?.rightChart ? null : (
                        <ChartSection
                          key={`segments-chartsection-${chart.charts.labels.bottom}`}
                          id={
                            lineChart.find(item => item.name === chart.name)
                              ?.name
                          }
                          lineChart={chart.charts}
                        />
                      )}

                      {isHistoricalGroupedBarChartVisible && (
                        <div className={c.chart}>
                          <h2
                            className={c.chartName}
                            data-testid={`segment-chart-label-${chart.name}`}
                          >
                            {historicalCharts[index].name}
                          </h2>
                          <ChartSection
                            key={`segments-chartsection-${historicalCharts[index].charts.labels.bottom}-historical`}
                            id={
                              lineChart.find(item => item.name === chart.name)
                                ?.name
                            }
                            lineChart={historicalCharts[index].charts}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
            <SegmentsLegend
              width={cardWidth}
              tabs={tabs}
              tabIndex={tabIndex}
              onTabChange={handleTabChange}
            />
          </div>
        )}
      </Measure>
    </section>
  );
};
