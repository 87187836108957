import { NewUserData } from "pages/Admin/utils";

type NewUserDataParams = {
  email: string;
  company: string;
  role: number;
  phone_number?: string;
};

export const mapNewUsersDataForBE = (data: NewUserData) => {
  const params: NewUserDataParams = {
    email: data.email,
    company: data.company,
    role: Number(data.role)
  };

  if (data.phoneNumber) {
    params["phone_number"] = `+48${data.phoneNumber}`;
  }

  return params;
};
