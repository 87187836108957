import { createSelector } from "reselect";

import { AppState } from "store";
import { RESET_DATA, resetData } from "store/actions/appActions";
import {
  setAgreementsSigned,
  SetAgreementsSignedAction
} from "store/reducers/userReducer";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { Thunk, Values } from "utils/types";

// CONSTANTS
const GET_AGREEMENT_CONTENT = "AGREEMENT.GET_AGREEMENT_CONTENT";
const GET_AGREEMENT_CONTENT_SUCCESS = "AGREEMENT.GET_AGREEMENT_CONTENT_SUCCESS";
const GET_AGREEMENT_CONTENT_FAIL = "AGREEMENT.GET_AGREEMENT_CONTENT_FAIL";
const SIGN_AGREEMENT = "AGREEMENT.SIGN_AGREEMENT";
const SIGN_AGREEMENT_SUCCESS = "AGREEMENT.SIGN_AGREEMENT_SUCCESS";
const SIGN_AGREEMENT_FAIL = "AGREEMENT.SIGN_AGREEMENT_FAIL";

// TYPES
type AgreementState = {
  isLoading: boolean;
  content: string;
  isSigning: boolean;
  id: string;
};

type AgreementApi = {
  content: string;
  id: string;
};

type ConfirmAgreementApi = {
  confirm: boolean;
  agreement: string;
};

type GetAgreementContentAction = {
  type: typeof GET_AGREEMENT_CONTENT;
};
type GetAgreementContentSuccessAction = {
  payload: AgreementApi;
  type: typeof GET_AGREEMENT_CONTENT_SUCCESS;
};
type GetAgreementContentFailAction = {
  type: typeof GET_AGREEMENT_CONTENT_FAIL;
};

type SignAgreementAction = {
  type: typeof SIGN_AGREEMENT;
};
type SignAgreementSuccessAction = {
  type: typeof SIGN_AGREEMENT_SUCCESS;
};
type SignAgreementFailAction = {
  type: typeof SIGN_AGREEMENT_FAIL;
};

type Actions = {
  GET_AGREEMENT_CONTENT: GetAgreementContentAction;
  GET_AGREEMENT_CONTENT_SUCCESS: GetAgreementContentSuccessAction;
  GET_AGREEMENT_CONTENT_FAIL: GetAgreementContentFailAction;
  SIGN_AGREEMENT: SignAgreementAction;
  SIGN_AGREEMENT_SUCCESS: SignAgreementSuccessAction;
  SIGN_AGREEMENT_FAIL: SignAgreementFailAction;
};

// SELECTORS
const agreementSelector = (state: AppState) => state.agreement;

export const agreementContentSelector = createSelector(
  agreementSelector,
  agreement => agreement.content
);

export const agreementIdSelector = createSelector(
  agreementSelector,
  agreement => agreement.id
);

// ACTIONS
const getAgreementContent = (): GetAgreementContentAction => ({
  type: GET_AGREEMENT_CONTENT
});
const getAgreementContentSuccess = (
  payload: AgreementApi
): GetAgreementContentSuccessAction => ({
  type: GET_AGREEMENT_CONTENT_SUCCESS,
  payload: payload
});
const getAgreementContentFail = (): GetAgreementContentFailAction => ({
  type: GET_AGREEMENT_CONTENT_FAIL
});

const signAgreement = (): SignAgreementAction => ({
  type: SIGN_AGREEMENT
});
const signAgreementSuccess = (): SignAgreementSuccessAction => ({
  type: SIGN_AGREEMENT_SUCCESS
});
const signAgreementFail = (): SignAgreementFailAction => ({
  type: SIGN_AGREEMENT_FAIL
});

export const fetchAgreementContent = (): Thunk<
  | GetAgreementContentAction
  | GetAgreementContentSuccessAction
  | GetAgreementContentFailAction
> => async dispatch => {
  try {
    dispatch(getAgreementContent());
    const fetchedAgreement = await HTTP.get<AgreementApi>(
      REST_API_ENDPOINTS.AGREEMENT
    );
    dispatch(getAgreementContentSuccess(fetchedAgreement.data));
  } catch (err) {
    dispatch(getAgreementContentFail());
  }
};

export const signAgreementThunk = (
  id: string
): Thunk<
  | SignAgreementAction
  | SignAgreementSuccessAction
  | SignAgreementFailAction
  | SetAgreementsSignedAction
> => async dispatch => {
  try {
    dispatch(signAgreement());
    await HTTP.post<ConfirmAgreementApi>(REST_API_ENDPOINTS.AGREEMENT, {
      confirm: true,
      agreement: id
    });
    dispatch(setAgreementsSigned());
    dispatch(signAgreementSuccess());
  } catch (err) {
    dispatch(signAgreementFail());
  }
};

//  REDUCER
const initialState: AgreementState = {
  isLoading: false,
  isSigning: false,
  content: "",
  id: ""
};

export const agreementReducer = (
  state: AgreementState = initialState,
  action: Values<Actions> | ReturnType<typeof resetData>
): AgreementState => {
  switch (action.type) {
    case GET_AGREEMENT_CONTENT:
      return {
        ...state,
        isLoading: true
      };
    case GET_AGREEMENT_CONTENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };
    case GET_AGREEMENT_CONTENT_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case SIGN_AGREEMENT:
      return {
        ...state,
        isSigning: true
      };
    case SIGN_AGREEMENT_SUCCESS:
    case SIGN_AGREEMENT_FAIL:
      return {
        ...state,
        isSigning: false
      };

    case RESET_DATA:
      return initialState;

    default:
      return state;
  }
};
