import { useSelector } from "react-redux";

import { TIERS_WITH_PROMOTIONS } from "pages/Reports/redux/selectors/promotionsSelectors";
import { isPowerUserSelector, tierSelector } from "store/reducers/userReducer";

export const useDataTypeAccess = () => {
  const tier = useSelector(tierSelector);
  const isPowerUser = useSelector(isPowerUserSelector);

  let access = { first: true, second: true, third: false };

  if (isPowerUser || TIERS_WITH_PROMOTIONS.includes(tier)) {
    access.third = true;
  }

  return access;
};
