import { useMemo } from "react";

import { format, startOfYesterday } from "date-fns";
import { ParsedQuery } from "query-string";

import { getFixedPeriodDateItem } from "components/organisms/DateDropdown/utils";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  APP_PATHS,
  DATE_PARAMS,
  DEFAULT_DATE_FORMAT,
  DEFAULT_QUERY_PARAMS
} from "utils";
import { QP } from "utils/defaultQueryParams";
import { Nullable } from "utils/types";

import { useCurrentLocation } from "./";

interface Dates {
  date_from: string;
  date_to: string;
}

const { MAIN, LOGIN, LOGOUT, INSIGHTS, EXPORT } = APP_PATHS;
const {
  SELL_PATH,
  SHARES_PATH,
  LOCATION_PATH,
  DYNAMICS_PATH,
  RECEIPTS_PATH,
  SEGMENTS_PATH,
  LOGISTICS_PATH,
  EXPORT_PATH,
  HOURS_PATH,
  LOYALTY_PATH,
  MULTIPACK_PATH,
  PROMOTIONS_PATH
} = REPORTS_FULL_PATHS;

const getDefaultDateParams = (location: Nullable<string>): Dates => {
  if (!location) return { [QP.DATE_FROM]: "", [QP.DATE_TO]: "" };

  const prevMonth = getFixedPeriodDateItem("month", 1);
  const yesterday = format(startOfYesterday(), DEFAULT_DATE_FORMAT);

  // for logistics use yesterday's dates
  if (location.includes(LOGISTICS_PATH)) {
    return { [QP.DATE_FROM]: yesterday, [QP.DATE_TO]: yesterday };
  }

  // for insights period from the beginning of 2020
  if (location.includes(INSIGHTS)) {
    return {
      [QP.DATE_FROM]: "2020-01-01",
      [QP.DATE_TO]: yesterday
    };
  }

  // for other reports use date_from and date_to saved in local storage
  const saved = JSON.parse(localStorage.getItem(DATE_PARAMS) || "null");
  if (Boolean(saved)) {
    return { [QP.DATE_FROM]: saved.date_from, [QP.DATE_TO]: saved.date_to };
  }

  // otherwise use start and end of the month
  return {
    [QP.DATE_FROM]: prevMonth.value.dateFrom,
    [QP.DATE_TO]: prevMonth.value.dateTo
  };
};

export const useDefaultQueryParams = () => {
  const currentLocation = useCurrentLocation();
  const { date_from, date_to } = getDefaultDateParams(currentLocation);

  return useMemo(() => {
    const DATES = { date_from, date_to };
    let params: ParsedQuery<string | string[]>;

    switch (currentLocation) {
      case MAIN:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.MAIN
        };
        break;

      case LOGIN:
        params = DEFAULT_QUERY_PARAMS.LOGIN;
        break;

      case LOGOUT:
        params = {};
        break;

      case INSIGHTS:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.INSIGHTS
        };
        break;

      case SELL_PATH:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.REPORTS.SELL
        };
        break;

      case SHARES_PATH:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.REPORTS.SHARES
        };
        break;

      case LOCATION_PATH:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.REPORTS.LOCATION
        };
        break;

      case DYNAMICS_PATH:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.REPORTS.DYNAMICS
        };
        break;

      case RECEIPTS_PATH:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.REPORTS.RECEIPTS
        };
        break;

      case SEGMENTS_PATH:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.REPORTS.SEGMENTS
        };
        break;

      case LOGISTICS_PATH:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.REPORTS.LOGISTICS
        };
        break;

      case EXPORT:
      case EXPORT_PATH:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.REPORTS.EXPORT
        };
        break;

      case HOURS_PATH:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.REPORTS.HOURS
        };
        break;

      case LOYALTY_PATH:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.REPORTS.LOYALTY
        };
        break;

      case MULTIPACK_PATH:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.REPORTS.MULTIPACK
        };
        break;

      case PROMOTIONS_PATH:
        params = {
          ...DATES,
          ...DEFAULT_QUERY_PARAMS.REPORTS.PROMOTIONS
        };
        break;

      default:
        params = { ...DATES };
    }

    return params;
  }, [date_from, date_to, currentLocation]);
};
