import { useRankingWarnings } from "api/ranking/mappers";
import {
  useGetSharesByPeriod,
  useSharesGeneralWarnings
} from "api/shares/mappers";

export const useSharesWarnings = (): string[] => {
  const generalWarnings = useSharesGeneralWarnings();
  const info = useGetSharesByPeriod().data?.info ?? [];
  const rankingWarnings = useRankingWarnings();

  return [...generalWarnings, ...info, ...rankingWarnings].filter(
    warning => !!warning
  );
};
