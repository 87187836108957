import { useSelector } from "react-redux";

import { EMPTY_CHART } from "api/consts";
import { useGetLocationByPeriod } from "api/location/mappers/useGetLocationByPeriod";
import { useLocationGridChartData } from "api/location/mappers/useLocationGridChartData";
import { useLocationGridChartDomain } from "api/location/mappers/useLocationGridChartDomain";
import { ChartDataOptions } from "api/sells/mappers/useSellsChartData";
import { containsDataType } from "api/utils";
import { useChartLabels } from "api/utils/useChartLabels";

import { ChartData } from "components/D3/types";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import {
  updateHoveredTimepoint,
  updatePinnedTimepoint
} from "pages/Reports/redux/reducers/chartReducer";
import { chartRange } from "pages/Reports/utils";
import {
  chartPeriodSelector,
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { LOCATION_SECOND_CHART_DATA_TYPES } from "utils/constants/dataTypes";

export const useLocationChartData = ({
  isForHistoricalChart = false
}: ChartDataOptions): ChartData => {
  const period = useSelector(chartPeriodSelector);
  const firstDataType = useSelector(firstChartDataTypeSelector);
  const secondDataType = useSelector(secondChartDataTypeSelector);
  const { isGroupedBarChart, isIncrementalComboChart } = useChartTypeBoolean();
  const { isLoading, data: locationData } = useGetLocationByPeriod();
  const {
    domain,
    lastYearDomainLength,
    isTimelineMerged
  } = useLocationGridChartDomain({
    data: locationData,
    isForHistoricalChart,
    period
  });
  const showBarChart = domain.length === 1;
  const data = useLocationGridChartData({
    isForHistoricalChart,
    lastYearDomainLength,
    showBarChart,
    isTimelineMerged
  });
  const labels = useChartLabels();

  const { maxValue: firstMaxValue, minValue: firstMinValue } = chartRange(
    data.left.visibleLines,
    {
      isRightChartData: containsDataType(
        LOCATION_SECOND_CHART_DATA_TYPES,
        firstDataType
      ),
      isGroupedBarChart,
      isIncrementalComboChart
    }
  );
  const { maxValue: secondMaxValue, minValue: secondMinValue } = chartRange(
    data.right.visibleLines
  );

  if (!data.left || (isForHistoricalChart && isTimelineMerged)) {
    return EMPTY_CHART;
  }

  return {
    leftChart: {
      chart: data.left.visibleLines.filter(line => line.exists),
      dataType: firstDataType,
      minValue: firstMinValue,
      maxValue: firstMaxValue
    },
    rightChart: !data.right
      ? null
      : {
          chart: data.right.visibleLines.filter(line => line.exists),
          dataType: secondDataType,
          minValue: secondMinValue,
          maxValue: secondMaxValue
        },
    domain,
    legend: [],
    period,
    labels,
    isFetching: isLoading,
    showBarChart,
    onTimelineHover: updateHoveredTimepoint,
    onTimelineClick: updatePinnedTimepoint,
    isHistoricalGroupedChart: isForHistoricalChart,
    isTimelineMerged
  };
};
