import { useState } from "react";

import { BOOL_STRING_VALUES, TOURS_KEYS } from "utils";

import {
  EXPORT_REDIRECT_STEPS,
  EXPORT_STEPS,
  REF_CAT_STEPS
} from "../constants";
import { TourHooks } from "../hooks/useTours";
import { Tour } from "../Tour";

interface Props {
  isLoading: boolean;
}

const { IS_EXPORT_ARCHIVE_TOUR_HIDDEN, IS_REF_CAT_TOUR_HIDDEN } = TOURS_KEYS;
const { TRUE } = BOOL_STRING_VALUES;
const IS_EXPORT_REDIRECT_TOUR_HIDDEN = "IS_EXPORT_REDIRECT_TOUR_HIDDEN";

export const ExportTour = ({ isLoading }: Props) => {
  const [{ isExportRedirectTourHidden }, actions] = TourHooks.useTours();
  // tours has to be rendered in sequence due to the differences in targets access/deploy time
  const [sequence, setSequence] = useState([
    {
      key: IS_REF_CAT_TOUR_HIDDEN,
      steps: REF_CAT_STEPS,
      isHidden: localStorage.getItem(IS_REF_CAT_TOUR_HIDDEN) === TRUE
    },
    {
      key: IS_EXPORT_ARCHIVE_TOUR_HIDDEN,
      steps: EXPORT_STEPS,
      isHidden: localStorage.getItem(IS_EXPORT_ARCHIVE_TOUR_HIDDEN) === TRUE
    },
    {
      key: IS_EXPORT_REDIRECT_TOUR_HIDDEN,
      steps: EXPORT_REDIRECT_STEPS,
      isHidden: isExportRedirectTourHidden
    }
  ]);
  const [isNavTourHidden] = TourHooks.useIsNavTourHidden();

  const activeTour = sequence.find(({ isHidden }) => !isHidden);

  const handleClose = () => {
    if (!activeTour) return;

    if (activeTour.key === IS_EXPORT_REDIRECT_TOUR_HIDDEN) {
      actions.setExportRedirectTourHidden(true);
    } else {
      localStorage.setItem(activeTour.key, TRUE);
    }

    setSequence(
      sequence.map(tour =>
        tour.key === activeTour.key ? { ...tour, isHidden: true } : tour
      )
    );
  };

  if (isLoading || !isNavTourHidden || !activeTour) {
    return null;
  }

  return (
    <Tour
      key={activeTour.key}
      run
      steps={activeTour.steps}
      onClose={handleClose}
    />
  );
};
