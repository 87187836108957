import { useMemo } from "react";

import { useGetDynamicsByPeriod } from "api/dynamics/mappers";
import { ReportTypes } from "api/shares/types";

import { DATASET_TYPES } from "utils";

export const useDynamicsOwnTableData = () => {
  const query = useGetDynamicsByPeriod(ReportTypes.TABLE);

  const tableData = useMemo(
    () =>
      query?.data?.currentPeriod.filter(
        ({ type }) => type === DATASET_TYPES.OWN
      ) ?? [],
    [query]
  );

  return tableData;
};
