import { useSelector } from "react-redux";

import { useGetLocationByPeriod } from "api/location/mappers/useGetLocationByPeriod";
import { useLocationGridChartData } from "api/location/mappers/useLocationGridChartData";
import { useActiveCompanyName } from "api/utils/useActiveCompanyName";
import { flatten, groupBy, isEmpty, map, sort, toPairs, uniqBy } from "ramda";
import { compose } from "redux";

import { LegendItem } from "components/types";
import { LocationLegendType } from "pages/Reports/partials/Chart/Legend/LocationLegend/LocationLegend";
import { hiddenLinesSelector } from "pages/Reports/redux/reducers/chartReducer";
import { LocationLineChart } from "pages/Reports/sections/Location/redux/types/locationChartTypes";
import { firstChartDataTypeSelector } from "store/selectors/routerSelectors";
import { DATASET_TYPES, getPolishLabelForChart, NO_DATA } from "utils";

const isOwn = (type: string) => type === DATASET_TYPES.OWN;
const isCompetitor = (type: string) => type === DATASET_TYPES.COMPETITOR;
const greaterThanZero = (arr: Array<unknown>) => arr.length > 0;
const mapLegendItem = (reg: LegendItem) => ({
  text: reg.label,
  id: reg.id,
  color: reg.backgroundColor,
  isLineHidden: reg.isLineHidden
});
const mapLegendItems = ({
  label,
  items
}: {
  label: string;
  items: LegendItem[];
}) => ({
  label: label,
  region: items.map(mapLegendItem)
});

export const useLocationGridChartLegend = () => {
  const { left, right } = useLocationGridChartData({
    isForHistoricalChart: false, // it should be the same for historical and regular chart
    lastYearDomainLength: 0, // doesn't matter in this use case
    showBarChart: false, // doesn't matter in this use case
    isTimelineMerged: false // doesn't matter in this use case
  });
  const hiddenLines = useSelector(hiddenLinesSelector);
  const first = useSelector(firstChartDataTypeSelector);
  const activeCompany = useActiveCompanyName();

  const { data } = useGetLocationByPeriod();

  if (!data) {
    return [];
  }

  const mapToLegendItem = map<LocationLineChart, LegendItem>(
    ({ chartName, label, color, exists, type }: LocationLineChart) => ({
      chartName,
      label,
      backgroundColor: color,
      exists,
      type,
      id: `${label} - ${chartName}`
    })
  );

  const groupByChartName = groupBy<LegendItem>(datum => datum?.chartName || "");

  const sortByVoivodeship = sort((a: LegendItem, b: LegendItem) =>
    a.label.localeCompare(b.label)
  );

  const leftChartData = left?.allLines || [];
  const rightChartData = right?.allLines || [];

  const composeData = (lines: LocationLineChart[]) =>
    compose(
      toPairs,
      groupByChartName,
      sortByVoivodeship,
      mapToLegendItem
    )(lines).map(res => ({
      label: res[0],
      items: res[1],
      type: data.data.data.find(data => data.name === res[0])?.type || ""
    }));

  const rawData = [composeData(leftChartData), composeData(rightChartData)];

  const addedLineHiddenInfo = rawData.map(chart =>
    chart.map(material => ({
      label: material.label,
      type: material.type,
      items: material.items.map(region => ({
        ...region,
        isLineHidden: hiddenLines.location.includes(
          `${material.label} - ${region.label}`
        ),
        id: `${material.label} - ${region.label}`
      }))
    }))
  );

  const exist = flatten(addedLineHiddenInfo).map(el => ({
    ...el,
    items: el.items.filter(el => el.exists)
  }));
  const notExist = flatten(addedLineHiddenInfo).map(el => ({
    ...el,
    items: el.items.filter(el => !el.exists)
  }));

  const ownExist = uniqBy(a => a.label, exist)
    .filter(el => isOwn(el.type))
    .map(mapLegendItems)
    .filter(regions => greaterThanZero(regions.region));

  const competitorExist = uniqBy(a => a.label, exist)
    .filter(el => isCompetitor(el.type))
    .map(mapLegendItems)
    .filter(regions => greaterThanZero(regions.region));

  const ownNotExist = uniqBy(a => a.label, notExist)
    .filter(el => isOwn(el.type))
    .map(mapLegendItems)
    .filter(regions => greaterThanZero(regions.region));

  const competitorNotExist = uniqBy(a => a.label, notExist)
    .filter(el => isCompetitor(el.type))
    .map(mapLegendItems)
    .filter(regions => greaterThanZero(regions.region));

  const tabs = [
    {
      label: getPolishLabelForChart(first),
      own: ownExist,
      comp: competitorExist
    },
    { label: NO_DATA, own: ownNotExist, comp: competitorNotExist }
  ].map(({ label, own, comp }) => {
    const isDisabled =
      own.filter(el => el.region.length > 0).length === 0 &&
      comp.filter(el => el.region.length > 0).length === 0;
    return {
      label,
      isDisabled,
      sections: [
        {
          label: activeCompany || "Kategorie / produkty własne:",
          regions: own
        },
        {
          label: "Firmy / produkty konkurencyjne:",
          regions: comp
        }
      ]
    };
  });

  const tabsWithoutEmptySections: LocationLegendType[] = tabs
    .map(tab => ({
      ...tab,
      sections: tab.sections.filter(section => !isEmpty(section.regions))
    }))
    .filter(tab => !(tab.label === NO_DATA && tab.sections.length === 0))
    .map(tab => (tab.label === NO_DATA ? tab : { ...tab, label: "Dane" }));

  return tabsWithoutEmptySections;
};
