import React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const ResetPassword = ({
  className,
  color = "#43B649",
  size = ICON_SIZES.SMALL
}: Props) => {
  const currentSize = SIZES[size];
  return (
    <svg width={currentSize} height={currentSize} className={className}>
      <path
        d="M2.002 9.866A5.597 5.597 0 01.878 7.332l1.578-.265c.11.658.387 1.283.802 1.808l-1.256.991zm3.465 2.056a5.612 5.612 0 01-2.38-1.007l.948-1.289c.5.368 1.087.616 1.698.719l-.266 1.577zm1.865 0l-.264-1.578a3.992 3.992 0 001.767-.771l.975 1.269c-.724.556-1.58.93-2.478 1.08zM6.4.8a5.62 5.62 0 00-3.958 1.642L.72.72.16 5.84l5.12-.56-1.708-1.708A4.019 4.019 0 016.4 2.4c2.206 0 4 1.794 4 4 0 .848-.262 1.658-.758 2.342l1.297.939A5.568 5.568 0 0012 6.4C12 3.312 9.487.8 6.4.8z"
        fill={color}
      />
    </svg>
  );
};
