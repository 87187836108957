import * as React from "react";

import c from "components/common.module.scss";
import { ChartDataTypes } from "pages/Reports/types/chart";

type Props = {
  offsetLeft: number;
  offsetTop: number;
  dataType?: ChartDataTypes;
  axisLabel: string;
  smallerOffset: boolean;
};

export const RightLabel: React.FC<Props> = ({
  offsetLeft,
  offsetTop,
  axisLabel,
  smallerOffset = false
}) => {
  const offset = smallerOffset ? offsetLeft - 45 : offsetLeft - 20;
  const transform = `rotate(270deg) translate(-${offsetTop}px, ${offset}px)`;

  return (
    <g style={{ transform }}>
      <text
        dy="1rem"
        textAnchor="middle"
        className={c.axisLabel}
        data-testid="right-axis-label"
      >
        {axisLabel}
      </text>
    </g>
  );
};
