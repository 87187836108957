import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Fade, MovableTooltipWrapper } from "components/atoms";
import { Pin } from "components/atoms/Icon";
import { Tooltip } from "components/D3";
import { ChartData, Margins } from "components/D3/types";
import {
  ChartOnClickAction,
  hoveredTimepointPositionSelector,
  pinnedTimepointPositionSelector,
  pinnedTimepointSelector
} from "pages/Reports/redux/reducers/chartReducer";
import { TOOLTIP_ORIENTATIONS } from "utils";

import s from "./chartPinTooltip.module.scss";

interface Props {
  positionX: number;
  positionY: number;
  onTimelineClick: ChartOnClickAction;
  margins: Margins;
  lineChart: ChartData[];
}

const PIN_SIZE = 20;

export const ChartPinTooltip = ({
  positionX,
  positionY,
  onTimelineClick,
  margins,
  lineChart
}: Props) => {
  const dispatch = useDispatch();

  const hoveredTimepointPosition = useSelector(
    hoveredTimepointPositionSelector
  );
  const pinnedTimepoint = useSelector(pinnedTimepointSelector);
  const pinnedTimepointPosition = useSelector(pinnedTimepointPositionSelector);

  if (
    !lineChart ||
    (hoveredTimepointPosition === null && pinnedTimepointPosition === null)
  ) {
    return null;
  }

  //pinned tooltip
  if (pinnedTimepoint !== null && pinnedTimepointPosition !== null) {
    return (
      <Fade timeout={50} appear in>
        <div
          className={s.chartPinTooltip}
          style={{
            left: pinnedTimepointPosition + margins.left - PIN_SIZE / 2,
            width: PIN_SIZE, //these sizes are needed only to trigger onClick
            height: PIN_SIZE //these sizes are needed only to trigger onClick
          }}
          onClick={() => dispatch(onTimelineClick(null))}
          data-testid="chart-pin-tooltip"
        >
          <IconBox />
        </div>
      </Fade>
    );
  }

  //hovering near cursor
  return (
    <MovableTooltipWrapper
      positionX={positionX + 17} //positioned to the top-right place of the cursor
      positionY={positionY - 12} //positioned to the top-right place of the cursor
      orientation={
        lineChart[0]?.showBarChart
          ? TOOLTIP_ORIENTATIONS.VERTICAL
          : TOOLTIP_ORIENTATIONS.HORIZONTAL
      }
      isPinTooltip
    >
      <IconBox />
    </MovableTooltipWrapper>
  );
};

const IconBox = () => (
  <Tooltip className={s.pinWrapper}>
    <Pin />
  </Tooltip>
);
