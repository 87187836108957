import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { APP_PATHS, isThisPage } from "utils";

const {
  SELL_PATH,
  SHARES_PATH,
  LOCATION_PATH,
  DYNAMICS_PATH,
  RECEIPTS_PATH,
  LOGISTICS_PATH,
  SEGMENTS_PATH,
  LOYALTY_PATH,
  MULTIPACK_PATH,
  HOURS_PATH
} = REPORTS_FULL_PATHS;

enum BACKEND_REPORT_TYPES {
  SELL = "sales",
  SHARES = "shares",
  LOCATION = "by-location",
  DYNAMICS = "dynamic",
  RECEIPTS = "receipts",
  LOGISTICS = "logistics",
  SEGMENTS = "segments",
  EXPORT = "data-export",
  HOURS = "hourly",
  LOYALTY = "loyalty",
  MULTIPACK = "multipack"
}

export const getBackendReportType = (pathname: string) => {
  return isThisPage(pathname, SELL_PATH)
    ? BACKEND_REPORT_TYPES.SELL
    : isThisPage(pathname, LOCATION_PATH)
    ? BACKEND_REPORT_TYPES.LOCATION
    : isThisPage(pathname, DYNAMICS_PATH)
    ? BACKEND_REPORT_TYPES.DYNAMICS
    : isThisPage(pathname, RECEIPTS_PATH)
    ? BACKEND_REPORT_TYPES.RECEIPTS
    : isThisPage(pathname, SHARES_PATH)
    ? BACKEND_REPORT_TYPES.SHARES
    : isThisPage(pathname, LOGISTICS_PATH)
    ? BACKEND_REPORT_TYPES.LOGISTICS
    : isThisPage(pathname, LOYALTY_PATH)
    ? BACKEND_REPORT_TYPES.LOYALTY
    : isThisPage(pathname, SEGMENTS_PATH)
    ? BACKEND_REPORT_TYPES.SEGMENTS
    : isThisPage(pathname, APP_PATHS.EXPORT)
    ? BACKEND_REPORT_TYPES.EXPORT
    : isThisPage(pathname, HOURS_PATH)
    ? BACKEND_REPORT_TYPES.HOURS
    : isThisPage(pathname, MULTIPACK_PATH)
    ? BACKEND_REPORT_TYPES.MULTIPACK
    : "";
};
