import { createSelector } from "reselect";

import { ChartDataTypes } from "pages/Reports/types/chart";
import { isPowerUserSelector } from "store/reducers/userReducer";
import {
  chartPeriodSelector,
  delayCompetitionQueryParamSelector,
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import {
  BOOL_STRING_VALUES,
  CHART_DATA_TYPE,
  DISTRIBUTION_CHART_DATA_TYPE,
  PERIOD_TYPE
} from "utils";

import {
  isCompetitionEnabledSelector,
  isD28DateSelectedSelector
} from "./competitionSelectors";

export const showCompetitionDistributionWarningSelector = createSelector(
  isD28DateSelectedSelector,
  secondChartDataTypeSelector,
  isCompetitionEnabledSelector,
  (isD28DateSelected, secondChartType, isCompetitionEnabled) => {
    if (secondChartType === "") {
      return false;
    }

    const dataTypes: ChartDataTypes[] = [...DISTRIBUTION_CHART_DATA_TYPE];

    return Boolean(
      isD28DateSelected &&
        dataTypes.some(type => secondChartType.includes(type)) &&
        isCompetitionEnabled
    );
  }
);

export const showRankingCompetitionWarningSelector = createSelector(
  chartPeriodSelector,
  firstChartDataTypeSelector,
  isCompetitionEnabledSelector,
  isPowerUserSelector,
  (chartPeriod, mainChartDataType, isCompetitionEnabled, isPowerUser) =>
    chartPeriod === PERIOD_TYPE.RANKING &&
    isCompetitionEnabled &&
    !isPowerUser &&
    (mainChartDataType === CHART_DATA_TYPE.DISTRIBUTION_RANGE ||
      mainChartDataType === CHART_DATA_TYPE.AVG_SHOPS_COUNT)
);

export const showD28CompetitionWarningSelector = createSelector(
  isD28DateSelectedSelector,
  isCompetitionEnabledSelector,
  chartPeriodSelector,
  isPowerUserSelector,
  delayCompetitionQueryParamSelector,
  (
    isD28DateSelected,
    isCompetitionEnabled,
    chartPeriod,
    isPowerUser,
    delayCompetitionQueryParam
  ) => {
    const shouldDisplayWarning = Boolean(
      isD28DateSelected &&
        isCompetitionEnabled &&
        chartPeriod.toUpperCase() !== PERIOD_TYPE.DAYS
    );

    if (isPowerUser) {
      const isDelayEnabled =
        String(delayCompetitionQueryParam).toLocaleLowerCase() ===
        BOOL_STRING_VALUES.TRUE;

      return shouldDisplayWarning && isDelayEnabled;
    }

    return shouldDisplayWarning;
  }
);

export const showNoMetricsWarningSelector = createSelector(
  firstChartDataTypeSelector,
  secondChartDataTypeSelector,
  (first, second) => !first && !second
);
