import { Action, createHook, createStore } from "react-sweet-state";

import { Nullable } from "utils/types";

interface State {
  startElement: Nullable<number>;
  endElement: Nullable<number>;
  hoveredElementIndex: Nullable<number>;
}

type Actions = typeof actions;

const actions = {
  setStartElement: (startElement: number): Action<State> => ({ setState }) => {
    setState({
      startElement
    });
  },
  setEndElement: (endElement: number): Action<State> => ({ setState }) => {
    setState({ endElement });
  },
  setHoveredElementIndex: (
    hoveredElementIndex: Nullable<number>
  ): Action<State> => ({ setState }) => {
    setState({ hoveredElementIndex });
  },
  clearStartEndElements: (): Action<State> => ({ setState }) => {
    setState({
      startElement: null,
      endElement: null
    });
  }
};

const SidebarMultiselectStore = createStore<State, Actions>({
  name: "sidebarMultiselect",
  initialState: {
    startElement: null,
    endElement: null,
    hoveredElementIndex: null
  },
  actions
});

export const SidebarMultiselectHooks = {
  useSidebarMultiselect: createHook(SidebarMultiselectStore)
};
