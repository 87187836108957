import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";
import qs from "query-string";
import { uniq } from "ramda";

import { SpinnerLoader, Text } from "components/atoms";
import { Search } from "components/atoms/Icon";
import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import { UnactiveProductsCheckbox } from "components/organisms/UnactiveProductsCheckbox/UnactiveProductsCheckbox";
import s from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/CompetitorsFilters/competitorsFilters.module.scss";
import {
  DROPDOWN_LABELS,
  DROPDOWN_PLACEHOLDERS
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/constants";
import { FilterErrorMsg } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/FilterErrorMsg/FilterErrorMsg";
import {
  filterItems,
  isActiveFn
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import {
  asyncFiltersSelector,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useCompetitionProductBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useCompetitorProductFetching";
import {
  CompetingProductsHooks,
  DropdownItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useCompetingProducts";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

type Props = {
  isDisabled: boolean;
};

export const CompetitionProductDropdown = ({ isDisabled }: Props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(asyncFiltersSelector).fetching
    .competitorProducts;
  const errorMsg = useSelector(asyncFiltersSelector).error.competitorProducts;
  const [state, actions] = CompetingProductsHooks.useCompetingProducts();
  const [selectedCountLabel] = CompetingProductsHooks.useSelectedCountLabel();

  useCompetitionProductBehaviour(isDisabled);

  const [isOpen, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const handleChange = (item: DropdownItem) => {
    setOpen(true);
    const found = state.selected.find(i => i.value === item.value);
    if (found) {
      actions.updateSelected(
        state.selected.filter(i => i.value !== item.value)
      );
    } else {
      actions.updateSelected([...state.selected, item]);
    }
  };

  const handleInputValueChange = (value?: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (selectedItem?: DropdownItem) => {
    setOpen(false);
    if (!selectedItem) {
      setInputValue(selectedCountLabel);
      actions.sortItems();
    }
  };

  const handleStateChange = (changes: StateChangeOptions<DropdownItem[]>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleSelectAllActive = () => {
    actions.updateSelected(
      uniq([
        ...state.selected,
        ...state.all.filter(item => !item.label.startsWith("!"))
      ])
    );
    pushFiltersEvent("suppliers product - select active");
  };

  const handleSelectAllInactive = () => {
    actions.updateSelected(
      uniq([
        ...state.selected,
        ...state.all.filter(item => item.label.startsWith("!"))
      ])
    );
    pushFiltersEvent("suppliers product - select inactive");
  };

  const handleClear = () => {
    actions.updateSelected([]);
    pushFiltersEvent("suppliers product - clear");
  };

  const filterProductByInput = (inputValue: string) => (item: DropdownItem) => {
    const isItemSelected = state.selected.some(
      ({ value }) => value === item.value
    );

    //always show selected item
    if (isItemSelected) {
      return true;
    }
    const competitionProductMatchInputValue = filterItems<DropdownItem>(
      inputValue
    )(item);

    return competitionProductMatchInputValue;
  };

  React.useEffect(() => {
    if (!isOpen) setInputValue(selectedCountLabel);
  }, [isOpen, selectedCountLabel]);

  if (!isLoading && errorMsg) {
    return (
      <FilterErrorMsg
        label={DROPDOWN_LABELS.PRODUCT}
        callback={() => {
          dispatch(
            updateQuery({
              filter: "competitorProducts",
              query: qs.stringify({})
            })
          );
        }}
        errorMsg={errorMsg}
      />
    );
  }

  return (
    <Dropdown<DropdownItem[]>
      className={s.dropdownWrapper}
      isDisabled={isLoading || isDisabled}
      isMultiChoice
      isMultiChoiceSplitted
      //@ts-ignore
      itemToString={() => {}}
      selectedItem={state.selected}
      items={state.all}
      label={DROPDOWN_LABELS.PRODUCT}
      openedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.PRODUCT.OPEN
      }
      closedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.PRODUCT.CLOSED
      }
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      //@ts-ignore
      onChange={handleChange}
      onClear={handleClear}
      onStateChange={handleStateChange}
      onSelectAllActive={handleSelectAllActive}
      onSelectAllInactive={handleSelectAllInactive}
      input={
        <FilterDropdownInput
          customIcon={isLoading ? <SpinnerLoader size="small" /> : <Search />}
          isTypingEnabled
          automaticInputValue={selectedCountLabel}
          testId="competition-products-dropdown"
        />
      }
      menu={
        <FilterDropdownMenu<DropdownItem>
          isItemActiveFn={isActiveFn}
          filterItemsFn={filterProductByInput}
          listItem={<FilterDropdownListItem shouldMarkWithdrawnItems={true} />}
          minimumItemsToVirtualize={30}
          inlinePlaceholder={
            isLoading && (
              <Text className={s.inlinePlaceholder}>Pobieranie danych</Text>
            )
          }
          bottomElement={<UnactiveProductsCheckbox isCompetition />}
        />
      }
      onOuterClick={() => setOpen(false)}
    />
  );
};
