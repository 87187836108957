import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "components/atoms";
import { ChevronRight } from "components/atoms/Icon";
import { Navbar } from "components/molecules";
import {
  agreementContentSelector,
  agreementIdSelector,
  fetchAgreementContent,
  signAgreementThunk
} from "pages/Agreement/redux/agreementReducer";
import { ICON_SIZES } from "utils";

import s from "./agreement.module.scss";

export const Agreement = () => {
  const dispatch = useDispatch();
  const agreementContent = useSelector(agreementContentSelector);
  const agreementId = useSelector(agreementIdSelector);

  useEffect(() => {
    dispatch(fetchAgreementContent());
  }, [dispatch]);

  return (
    <div className={s.wrapper}>
      <Navbar />
      <div className={s.contentWrapper}>
        <div
          className={s.content}
          dangerouslySetInnerHTML={{ __html: agreementContent }}
        />
        <Button
          onClick={() => dispatch(signAgreementThunk(agreementId))}
          type="button"
          className={s.button}
        >
          Akceptuję
          <ChevronRight
            size={ICON_SIZES.LARGE}
            className={s.chevronRightIcon}
          />
        </Button>
        <footer className={s.footer}>Copyright © 2020 Żabka</footer>
      </div>
    </div>
  );
};
