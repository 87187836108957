import * as React from "react";
import { useRouteMatch } from "react-router";

import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { APP_PATHS } from "utils";

const { MAIN, LOGIN, INSIGHTS, EXPORT, ADMIN, LOGOUT } = APP_PATHS;
const {
  SELL_PATH,
  SHARES_PATH,
  LOCATION_PATH,
  DYNAMICS_PATH,
  RECEIPTS_PATH,
  LOGISTICS_PATH,
  SEGMENTS_PATH,
  EXPORT_PATH,
  HOURS_PATH,
  LOYALTY_PATH,
  MULTIPACK_PATH,
  PROMOTIONS_PATH
} = REPORTS_FULL_PATHS;

export const useCurrentLocation = () => {
  //main routes
  const mainRouteMatch = useRouteMatch(MAIN);
  const isMainPage = mainRouteMatch?.isExact;

  const loginMatch = useRouteMatch(LOGIN);
  const isLoginPage = loginMatch?.isExact;

  const logoutMatch = useRouteMatch(LOGOUT);
  const isLogoutPage = logoutMatch?.isExact;

  const insightsMatch = useRouteMatch(INSIGHTS);
  const isInsightsPage = insightsMatch?.isExact;

  const exportMatch = useRouteMatch(EXPORT);
  const isExportPage = exportMatch?.isExact;

  const adminMatch = useRouteMatch(ADMIN);
  const isAdminPage = adminMatch?.isExact;

  // reports sub-routes
  const sellRouteMatch = useRouteMatch(SELL_PATH);
  const isSellPage = Boolean(sellRouteMatch);

  const sharesRouteMatch = useRouteMatch(SHARES_PATH);
  const isSharesPage = Boolean(sharesRouteMatch);

  const locationMatch = useRouteMatch(LOCATION_PATH);
  const isLocationPage = Boolean(locationMatch);

  const dynamicsMatch = useRouteMatch(DYNAMICS_PATH);
  const isDynamicsPage = Boolean(dynamicsMatch);

  const receiptsMatch = useRouteMatch(RECEIPTS_PATH);
  const isReceiptsPage = Boolean(receiptsMatch);

  const logisticsMatch = useRouteMatch(LOGISTICS_PATH);
  const isLogisticsPage = Boolean(logisticsMatch);

  const segmentsMatch = useRouteMatch(SEGMENTS_PATH);
  const isSegmentsPage = Boolean(segmentsMatch);

  const oldExportMatch = useRouteMatch(EXPORT_PATH);
  const isOldExportPage = Boolean(oldExportMatch);

  const hoursMatch = useRouteMatch(HOURS_PATH);
  const isHoursPage = Boolean(hoursMatch);

  const loyaltyMatch = useRouteMatch(LOYALTY_PATH);
  const isLoyaltyPage = Boolean(loyaltyMatch);

  const multipackMatch = useRouteMatch(MULTIPACK_PATH);
  const isMultipackPage = Boolean(multipackMatch);

  const promotionsMatch = useRouteMatch(PROMOTIONS_PATH);
  const isPromotionsPage = Boolean(promotionsMatch);

  return React.useMemo(
    () =>
      //main routes
      (isMainPage && MAIN) ||
      (isInsightsPage && INSIGHTS) ||
      (isExportPage && EXPORT) ||
      (isLoginPage && LOGIN) ||
      (isLogoutPage && LOGOUT) ||
      (isAdminPage && ADMIN) ||
      // reports sub-routes
      (isSellPage && SELL_PATH) ||
      (isSharesPage && SHARES_PATH) ||
      (isLocationPage && LOCATION_PATH) ||
      (isDynamicsPage && DYNAMICS_PATH) ||
      (isReceiptsPage && RECEIPTS_PATH) ||
      (isLogisticsPage && LOGISTICS_PATH) ||
      (isSegmentsPage && SEGMENTS_PATH) ||
      (isOldExportPage && EXPORT_PATH) ||
      (isHoursPage && HOURS_PATH) ||
      (isLoyaltyPage && LOYALTY_PATH) ||
      (isMultipackPage && MULTIPACK_PATH) ||
      (isPromotionsPage && PROMOTIONS_PATH) ||
      null,
    [
      isMainPage,
      isInsightsPage,
      isExportPage,
      isLoginPage,
      isLogoutPage,
      isAdminPage,
      isSellPage,
      isSharesPage,
      isLocationPage,
      isDynamicsPage,
      isReceiptsPage,
      isLogisticsPage,
      isSegmentsPage,
      isOldExportPage,
      isHoursPage,
      isLoyaltyPage,
      isMultipackPage,
      isPromotionsPage
    ]
  );
};
