import OutsideClickHandler from "react-outside-click-handler";

import { Button } from "components/atoms";
import { Close } from "components/atoms/Icon";
import { ICON_SIZES } from "utils";
import { Nullable } from "utils/types";

import s from "./multiList.module.scss";

type Props = {
  onClose: () => void;
  testIds?: {
    menu?: string;
    close?: string;
    accept?: string;
  };
  footer?: Nullable<JSX.Element>;
  children: JSX.Element;
};

export const MultiList = ({
  onClose,
  testIds = {
    menu: "",
    close: "",
    accept: ""
  },
  footer = null,
  children
}: Props) => {
  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div className={s.multiMenu} data-testid={testIds.menu}>
        <button
          className={s.closeButton}
          type="button"
          onClick={onClose}
          data-testid={testIds.close}
        >
          <Close size={ICON_SIZES.EXTRA_SMALL} />
        </button>
        <div className={s.listWrapper}>{children}</div>
        <div className={s.bottomWrapper}>
          {footer}
          <Button
            className={s.acceptButton}
            onClick={onClose}
            testId={testIds.accept}
          >
            OK
          </Button>
        </div>
      </div>
    </OutsideClickHandler>
  );
};
