import {
  compose,
  equals,
  join,
  lensIndex,
  over,
  split,
  toUpper,
  unless
} from "ramda";

export const capitalize = unless(
  equals(""),
  compose<string, string[], string[], string>(
    join(""),
    over(lensIndex(0), toUpper),
    split("")
  )
);
