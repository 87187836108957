import { defaultTo } from "ramda";

// https://stackoverflow.com/questions/23917074/javascript-flooring-number-to-order-of-magnitude
export const getMagnitudeOrder = (n: number) =>
  Math.floor(Math.log(n) / Math.LN10 + 0.000000001);

export const floorToNearestMultiply = (
  value: number,
  multiplicator: number = 1
) => Math.floor(value / multiplicator) * multiplicator;

export const ceilToNearestMultiply = (
  value: number,
  multiplicator: number = 1
) => Math.ceil(value / multiplicator) * multiplicator;

export const defaultTo0 = defaultTo(0);
