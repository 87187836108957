import {
  createHistoricalTimeline,
  createMergedTimeline,
  createTimeline
} from "api/utils";
import { values } from "ramda";

import { LocationLineChart } from "pages/Reports/sections/Location/redux/types/locationChartTypes";
import { LocationRegion } from "pages/Reports/sections/Location/redux/types/locationRawTypes";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { valueByDataTypeBoolean } from "pages/Reports/utils";
import {
  mapLastYearTimelineByType,
  mapTimelineByType
} from "store/utils/chartUtils";
import { CHART_COLORS } from "utils";

export const getChartData = (
  chartName: string,
  colorIndex = 0,
  dataType: ChartDataTypes,
  type: string,
  dataset: { base: boolean; historical: boolean; both: boolean },
  isForHistoricalChart: boolean,
  lastYearDomainLength: number,
  isTimelineMerged: boolean
) => (
  { id, voivodeship, timeline, existingMetrics }: LocationRegion,
  index: number
): LocationLineChart => {
  const label = getLocationChartLabel(id, voivodeship);
  const color = values(CHART_COLORS)[(colorIndex + index) % 10];

  const timelineByDataType = isTimelineMerged
    ? createMergedTimeline(dataType, timeline)
    : dataset.historical
    ? createHistoricalTimeline(dataType, timeline, true)
    : createTimeline(dataType, timeline);
  const lastYearTimelineByDataType = createHistoricalTimeline(
    dataType,
    timeline,
    isForHistoricalChart
  );

  return {
    chartName,
    label,
    color,
    timeline: dataset.historical
      ? mapLastYearTimelineByType(
          type,
          timelineByDataType,
          lastYearDomainLength
        )
      : mapTimelineByType(type, timelineByDataType),
    ...(dataset.both && {
      lastYearTimeline: mapLastYearTimelineByType(
        type,
        lastYearTimelineByDataType,
        lastYearDomainLength
      )
    }),
    change: createTimeline(`${dataType}_CHANGE`, timeline),
    changePercentage: createTimeline(`${dataType}_CHANGE_PERCENTAGE`, timeline),
    exists: valueByDataTypeBoolean(dataType, existingMetrics, dataset) || false,
    type
  };
};

const getLocationChartLabel = (id: string, voivodeship?: string) => {
  if (voivodeship) {
    return `${id.split("-")[1]} (${voivodeship})`;
  }

  return id;
};
