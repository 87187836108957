interface Props {
  transform: string;
  fill: string;
  id: string;
  onMouseMove: () => void;
  onMouseLeave: () => void;
  onTimelineClick: () => void;
}

export const ChartDot = ({
  transform,
  fill,
  id,
  onTimelineClick,
  onMouseMove,
  onMouseLeave
}: Props) => (
  <circle
    id={id}
    key={id}
    data-testid={id}
    fill={fill}
    transform={transform}
    r="4"
    stroke="white"
    onClick={onTimelineClick}
    onMouseMove={onMouseMove}
    onMouseLeave={onMouseLeave}
  />
);
