import { range } from "d3";

export const getRange = (
  min: number,
  max: number,
  ticksAmount: number,
  fillWithZero: boolean
) => {
  const step = (max - min) / ticksAmount;

  // min + max + step creates additional tick which is wrong and ticks array length is to big. min + max + step / 2 does not do this
  const ticksRange = range(min, max + step / 2, step);
  if (
    ticksRange.some(val => val < 0) &&
    ticksRange.some(val => val > 0) &&
    ticksRange.every(val => val !== 0) &&
    fillWithZero
  ) {
    ticksRange.push(0);
    if (Array.isArray(ticksRange)) {
      ticksRange.sort();
    }
  }

  return ticksRange;
};
