import { useSelector } from "react-redux";

import { ChartData } from "components/D3/types";
import { BottomAxis } from "pages/Reports/partials/Chart/StandardChart/BottomAxis";
import { BottomLabel } from "pages/Reports/partials/Chart/StandardChart/BottomLabel";
import { LeftAxis } from "pages/Reports/partials/Chart/StandardChart/LeftAxis";
import { LeftLabel } from "pages/Reports/partials/Chart/StandardChart/LeftLabel";
import { SegmentsBottomAxis } from "pages/Reports/partials/Chart/StandardChart/SegmentsBottomAxis";
import { useChartScales } from "pages/Reports/partials/Chart/StandardChart/utils";
import { REPORTS_PATHS } from "pages/Reports/utils";
import {
  isGroupedBySegmentsSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import { Periods } from "utils/types";

type Props = {
  lineChart: ChartData;
  domain: string[];
  labels: {
    left: string;
    right: string;
    bottom: string;
  };
  margin: {
    left: number;
    right: number;
    bottom: number;
    top: number;
  };
  width: number;
  height: number;
  period: Periods;
};

export const LabelsChart: React.FC<Props> = ({
  domain,
  labels,
  margin,
  width,
  height,
  period,
  lineChart
}) => {
  const { yScale, xScaleBand } = useChartScales(lineChart, width);
  const pathname = useSelector(pathnameSelector);
  const isGroupedBySegments = useSelector(isGroupedBySegmentsSelector);
  const isSegmentsPage = pathname.includes(REPORTS_PATHS.SEGMENTS);
  const gridWidth = width - margin.left - margin.right;
  const pxPerDomainElement = gridWidth / domain.length;
  const showSundayOnly = pxPerDomainElement < 14;
  const { leftChart } = lineChart;

  return (
    <>
      {leftChart && (
        <>
          <LeftAxis
            yScale={yScale}
            dataType={leftChart.dataType}
            minValue={leftChart.minValue}
            maxValue={leftChart.maxValue}
          />
          <LeftLabel
            text={labels.left}
            left={-margin.left}
            height={-height / 2}
            smallerOffset={isSegmentsPage}
          />
        </>
      )}
      {isSegmentsPage && !isGroupedBySegments ? (
        <SegmentsBottomAxis
          showSundayOnly={showSundayOnly}
          xScale={xScaleBand}
          domain={domain}
          period={period}
          height={height}
        />
      ) : (
        <BottomAxis
          width={width - margin.left - margin.right}
          height={height}
          domain={domain}
          period={period}
        />
      )}
      <BottomLabel
        text={labels.bottom}
        width={(width - margin.left - margin.right) / 2}
        height={height + margin.top + 25}
      />
    </>
  );
};
