import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { COLORS, SIZES } from "./utils/constants";

export const Alert = ({
  className,
  color = COLORS.ALERT,
  size = ICON_SIZES.LARGE
}: Props) => {
  const currentSize = SIZES[size];
  return (
    <svg
      className={className}
      width={currentSize}
      height={currentSize}
      viewBox="0 0 32 32"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm0 24c-1.2 0-2-.8-2-2s.8-2 2-2 2 .8 2 2-.8 2-2 2zm2-6h-4V8h4v10z"
      />
    </svg>
  );
};
