interface Args {
  chartName?: string;
  id: string;
  hoveredLine: string[];
  isLocationPage: boolean;
}

export const getLineOpacity = ({
  chartName = "",
  id,
  hoveredLine,
  isLocationPage
}: Args): string => {
  if (!hoveredLine.length) return "1";
  if (isLocationPage && hoveredLine.includes(`${chartName} - ${id}`))
    return "1";
  if (hoveredLine.includes(id)) return "1";
  return "0.2";
};
