import React from "react";

import { scaleBand, scaleLinear } from "d3";

import { ChartData } from "components/D3/types";

const height = 280;

const margin = {
  top: 40,
  bottom: 40,
  left: 100,
  right: 100
};

export const useChartScales = (lineChart: ChartData, width: number) => {
  const { domain, leftChart, rightChart } = lineChart;
  const xScaleBand = React.useMemo(
    () =>
      scaleBand()
        .domain(domain.map(valueX => valueX) || [0, 1])
        .range([0, width - margin.left - margin.right]),
    [domain, width]
  );

  const yScale = React.useMemo(
    () =>
      scaleLinear()
        .domain([leftChart?.maxValue ?? 1, leftChart?.minValue || 0])
        .range([0, height]),
    [leftChart]
  );

  const yScaleForGrid = React.useMemo(() => {
    if (leftChart) {
      return scaleLinear()
        .domain([leftChart?.maxValue || 0, leftChart?.minValue || 0])
        .range([0, height]);
    }
    if (!leftChart && rightChart) {
      return scaleLinear()
        .domain([rightChart?.maxValue || 0, rightChart?.minValue || 0])
        .range([0, height]);
    }
    return scaleLinear()
      .domain([1, 0])
      .range([0, height]);
  }, [leftChart, rightChart]);

  const yScaleRight = scaleLinear()
    .domain([rightChart?.maxValue || 0, rightChart?.minValue || 0])
    .range([0, height]);

  return {
    xScaleBand,
    yScale,
    yScaleForGrid,
    yScaleRight
  };
};
