import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const ThumbUp: React.FC<Props> = ({
  className,
  color,
  size = ICON_SIZES.NORMAL
}) => {
  const iconSize = SIZES[size];

  return (
    <svg
      className={className}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 20 20"
      version="1.1"
    >
      <g fill={color} fillRule="nonzero">
        <path d="M10.806 7.96V3.144A2.11 2.11 0 008.398.736a.803.803 0 00-.723.602l-2.859 9.03V20H15.14a3.225 3.225 0 003.13-2.77l.964-5.538a3.05 3.05 0 00-.723-2.528 3.024 3.024 0 00-2.528-1.204h-5.177zM0 10.368h3.211V20H0z" />
      </g>
    </svg>
  );
};
