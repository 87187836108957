import qs from "query-string";

import { CHART_PERIODS_SETTINGS } from "pages/Reports/redux/reducers/periods/constants";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { PERIOD_TYPE } from "utils/constants";
import { Periods, Tier, Values } from "utils/types";

export const getAvailablePeriods = (
  report: Values<typeof REPORTS_FULL_PATHS>,
  search: string
): Periods[] => {
  const params = qs.parse(search, { arrayFormat: "comma" });
  const tier = params.tier ? (Number(params.tier) as Tier) : 3;

  const periods = CHART_PERIODS_SETTINGS.filter(
    period => period.report.includes(report) && period.tier.includes(tier)
  ).map(period => period.type);

  return periods;
};

// [PMD-4768]: use "promo week" as a defaule period
export const getDefaultPeriod = (
  report: Values<typeof REPORTS_FULL_PATHS>,
  search: string
) => {
  const periods = getAvailablePeriods(report, search);
  if (periods.includes(PERIOD_TYPE.PROMO_WEEKS)) return PERIOD_TYPE.PROMO_WEEKS;
  return periods[0];
};
