import { useSelector } from "react-redux";

import { useGetSharesByPeriod } from "api/shares/mappers";
import { transformSummary } from "api/utils";

import { useSummaryDataTypeSuffix } from "components/organisms/ReportSummary/hooks/useSummaryClientTabs";
import { SharesSummaryItem } from "pages/Reports/types/summary";
import {
  createSummaryItemData,
  SUMMARY_SUB_SWITCH,
  SUMMARY_SWITCH
} from "pages/Reports/utils";
import { NUMBER_TYPE } from "pages/Reports/utils/formatNumber";
import { isPowerUserSelector } from "store/reducers/userReducer";
import {
  productsQueryParamSelector,
  uppercasedSharesSummaryTabSelector,
  uppercasedSummarySubTypeSelector
} from "store/selectors/routerSelectors";
import { ONLY_SINGLE_SKU_VISIBLE, REPORTS_SUMMARY_LABELS } from "utils";

const KEYS_WITH_SUFFIX = [
  "worthShareInCategory",
  "quantityShareInCategory",
  "netQuantityShareInCategory"
].flatMap(key => [key, `${key}Change`, `${key}LastYear`]);

export const useSharesSummary = () => {
  const { data } = useGetSharesByPeriod();
  const summarySubType = useSelector(uppercasedSummarySubTypeSelector);
  const productsQueryParam = useSelector(productsQueryParamSelector);
  const type = useSelector(uppercasedSharesSummaryTabSelector);
  const isPowerUser = useSelector(isPowerUserSelector);
  const suffix = useSummaryDataTypeSuffix();

  const isReferenceType = summarySubType === SUMMARY_SUB_SWITCH.REF_CATEGORY;
  const rawSummary = isReferenceType
    ? data?.referenceSummary
    : data?.ownSummary;

  if (!rawSummary) return [];

  const [g1, g2, g3] = ["1", "2", "3"].map(key => {
    // @ts-ignore - avoid key type checking
    const data = rawSummary[key];
    return data
      ? transformSummary<SharesSummaryItem>(data, KEYS_WITH_SUFFIX, suffix)
      : null;
  });

  const isOneProductSelected =
    typeof productsQueryParam === "string" && !!productsQueryParam.length;

  const distributionTabItems = [
    {
      label: REPORTS_SUMMARY_LABELS.AVG_SHOPS_COUNT,
      firstValue: rawSummary.avgShopsCount,
      firstValueType: isOneProductSelected
        ? NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
        : NUMBER_TYPE.NOT_APPLICABLE,
      secondValue: isOneProductSelected ? rawSummary.avgShopsCountChange : null,
      secondValueType: NUMBER_TYPE.PERCENT,
      thirdValue: isOneProductSelected
        ? rawSummary.avgShopsCountLastYear
        : null,
      thirdValueType: NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
      change: isOneProductSelected ? rawSummary.avgShopsCountChange : 0,
      tooltipText: !isOneProductSelected ? ONLY_SINGLE_SKU_VISIBLE : ""
    },
    {
      label: REPORTS_SUMMARY_LABELS.DISTRIBUTION_RANGE,
      firstValue: rawSummary.distributionRange,
      firstValueType: isOneProductSelected
        ? NUMBER_TYPE.PERCENT
        : NUMBER_TYPE.NOT_APPLICABLE,
      secondValue: isOneProductSelected
        ? rawSummary.distributionRangeChange
        : null,
      secondValueType: NUMBER_TYPE.PERCENT_POINTS,
      thirdValue: isOneProductSelected
        ? rawSummary.distributionRangeLastYear
        : null,
      thirdValueType: NUMBER_TYPE.PERCENT,
      change: isOneProductSelected ? rawSummary.distributionRangeChange : 0,
      tooltipText: !isOneProductSelected ? ONLY_SINGLE_SKU_VISIBLE : ""
    },
    ...(isPowerUser
      ? [
          {
            label: REPORTS_SUMMARY_LABELS.AVG_PLANOGRAM_SHOPS_COUNT,
            firstValue: rawSummary.avgPlanogramShopsCount,
            firstValueType: isOneProductSelected
              ? NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
              : NUMBER_TYPE.NOT_APPLICABLE,
            secondValue: isOneProductSelected
              ? rawSummary.avgPlanogramShopsCountChange
              : null,
            secondValueType: NUMBER_TYPE.PERCENT,
            thirdValue: isOneProductSelected
              ? rawSummary.avgPlanogramShopsCountLastYear
              : null,
            thirdValueType: NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
            change: isOneProductSelected
              ? rawSummary.avgPlanogramShopsCountChange
              : 0,
            tooltipText: !isOneProductSelected ? ONLY_SINGLE_SKU_VISIBLE : ""
          },
          {
            label: REPORTS_SUMMARY_LABELS.PLANOGRAM_DISTRIBUTION_RANGE,
            firstValue: rawSummary.planogramDistributionRange,
            firstValueType: isOneProductSelected
              ? NUMBER_TYPE.PERCENT
              : NUMBER_TYPE.NOT_APPLICABLE,
            secondValue: isOneProductSelected
              ? rawSummary.planogramDistributionRangeChange
              : null,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: isOneProductSelected
              ? rawSummary.planogramDistributionRangeLastYear
              : null,
            thirdValueType: NUMBER_TYPE.PERCENT,
            change: isOneProductSelected
              ? rawSummary.planogramDistributionRangeChange
              : 0,
            tooltipText: !isOneProductSelected ? ONLY_SINGLE_SKU_VISIBLE : ""
          }
        ]
      : [])
  ];

  const mainTabItems = [
    ...(!!g1
      ? [
          {
            label: `Udział wartościowy w G1`,
            firstValue: g1.worthShareInCategory,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: g1.worthShareInCategoryChange,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: g1.worthShareInCategoryLastYear,
            change: g1.worthShareInCategoryChange
          },
          {
            label: `Udział ilościowy w G1`,
            firstValue: g1.quantityShareInCategory,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: g1.quantityShareInCategoryChange,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: g1.quantityShareInCategoryLastYear,
            change: g1.quantityShareInCategoryChange
          }
        ]
      : []),
    ...(!!g2
      ? [
          {
            label: `Udział wartościowy w G2`,
            firstValue: g2.worthShareInCategory,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: g2.worthShareInCategoryChange,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: g2.worthShareInCategoryLastYear,
            change: g2.worthShareInCategoryChange
          },
          {
            label: `Udział ilościowy w G2`,
            firstValue: g2.quantityShareInCategory,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: g2.quantityShareInCategoryChange,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: g2.quantityShareInCategoryLastYear,
            change: g2.quantityShareInCategoryChange
          }
        ]
      : []),
    ...(!!g3
      ? [
          {
            label: `Udział wartościowy w G3`,
            firstValue: g3.worthShareInCategory,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: g3.worthShareInCategoryChange,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: g3.worthShareInCategoryLastYear,
            change: g3.worthShareInCategoryChange
          },
          {
            label: `Udział ilościowy w G3`,
            firstValue: g3.quantityShareInCategory,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: g3.quantityShareInCategoryChange,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: g3.quantityShareInCategoryLastYear,
            change: g3.quantityShareInCategoryChange
          }
        ]
      : [])
  ];

  const naturalUnitsItems = [
    ...(!!g1
      ? [
          {
            label: `Udział jn w G1`,
            firstValue: g1.netQuantityShareInCategory,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: g1.netQuantityShareInCategoryChange,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: g1.netQuantityShareInCategoryLastYear,
            change: g1.netQuantityShareInCategoryChange
          }
        ]
      : []),
    ...(!!g2
      ? [
          {
            label: `Udział jn w G2`,
            firstValue: g2.netQuantityShareInCategory,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: g2.netQuantityShareInCategoryChange,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: g2.netQuantityShareInCategoryLastYear,
            change: g2.netQuantityShareInCategoryChange
          }
        ]
      : []),
    ...(!!g3
      ? [
          {
            label: `Udział jn w G3`,
            firstValue: g3.netQuantityShareInCategory,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: g3.netQuantityShareInCategoryChange,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: g3.netQuantityShareInCategoryLastYear,
            change: g3.netQuantityShareInCategoryChange
          }
        ]
      : [])
  ];

  if (type === SUMMARY_SWITCH.VALUE_QUANTITY) {
    return createSummaryItemData(mainTabItems);
  }

  if (type === SUMMARY_SWITCH.DISTRIBUTION) {
    return createSummaryItemData(distributionTabItems);
  }

  if (type === SUMMARY_SWITCH.NATURAL_UNITS) {
    return createSummaryItemData(naturalUnitsItems);
  }

  return [];
};
