import * as React from "react";

import { VOIVODESHIP_PATHS } from "./constants";
import { VoivodeshipProps } from "./types";

export const Voivodeship = React.forwardRef<SVGPathElement, VoivodeshipProps>(
  ({ className, onMouseEnter, onMouseLeave, onClick, id, fill }, ref) => {
    const path = VOIVODESHIP_PATHS[id];

    if (!path) {
      throw new Error(
        `Invalid Voivodeship component id. Voivodeship of id "${id}" does not exist`
      );
    }

    if (Array.isArray(path)) {
      return (
        <g
          data-name={id}
          ref={ref}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          fill={fill}
          className={className}
          onClick={onClick}
        >
          {path.map(d => (
            <path key={d} d={d} />
          ))}
        </g>
      );
    }

    return (
      <path
        data-name={id}
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        fill={fill}
        className={className}
        onClick={onClick}
        d={path}
      />
    );
  }
);
