// TYPES

import { RESET_DATA, resetData } from "store/actions/appActions";
import { Nullable, Values } from "utils/types";

export type HoveredRegion = {
  chart: number;
  id: string;
};

type LocationChartState = {
  hoveredRegion: Nullable<HoveredRegion>;
};

type UpdateHoveredRegionAction = {
  payload: HoveredRegion | null;
  type: typeof UPDATE_HOVERED_REGION;
};

type Actions = Values<{
  UpdateHoveredRegionAction: UpdateHoveredRegionAction;
}>;

// CONST
const UPDATE_HOVERED_REGION = "Reports.Location.Chart.UPDATE_HOVERED_REGION" as const;

// ACTIONS
export const updateHoveredRegion = (
  payload: HoveredRegion | null
): UpdateHoveredRegionAction => ({
  type: UPDATE_HOVERED_REGION,
  payload
});

//  REDUCER
const initialState: LocationChartState = {
  hoveredRegion: null
};

export const locationChartReducer = (
  state: LocationChartState = initialState,
  action: Actions | ReturnType<typeof resetData>
): LocationChartState => {
  switch (action.type) {
    case UPDATE_HOVERED_REGION:
      return { ...state, hoveredRegion: action.payload };

    case RESET_DATA:
      return initialState;

    default:
      return state;
  }
};
