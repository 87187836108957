import { ParsedQuery } from "query-string";

import {
  REPORTS_FULL_PATHS,
  SUMMARY_SWITCH
} from "pages/Reports/utils/constants";
import { CHART_DATA_TYPE, isThisPage } from "utils";
import { QP } from "utils/defaultQueryParams";

const {
  VALUE,
  QUANTITY,
  VALUE_PER_SHOP,
  AVG_SHOPS_COUNT,
  DISTRIBUTION_RANGE,
  NONE,
  NET_QUANTITY_SALES
} = CHART_DATA_TYPE;
const { TOTAL, PER_SHOP } = SUMMARY_SWITCH;
const { SELL_PATH, LOCATION_PATH } = REPORTS_FULL_PATHS;

export const adjustParamsByCountiesSelection = (
  params: ParsedQuery<string | string[]>,
  pathname: string,
  isPowerUser: boolean
) => {
  const hasCounties = !!params[QP.COUNTIES]?.length;

  if (!hasCounties) return params;

  const isSell = isThisPage(pathname, SELL_PATH);
  const isLocation = isThisPage(pathname, LOCATION_PATH) && !isPowerUser; // [PMD-2212]: power users should have access to all first types on location

  const firstDataType = String(params[QP.CHART_DATA_TYPE_FIRST]).toUpperCase();
  const secondDataType = String(
    params[QP.CHART_DATA_TYPE_SECOND]
  ).toUpperCase();
  const sellSummary = String(params[QP.SELL_SUMMARY_TYPE]).toUpperCase();
  const locationSummary = String(
    params[QP.LOCATION_SUMMARY_TYPE]
  ).toUpperCase();

  const isTotalSummaryOnSales = isSell && sellSummary === TOTAL;
  const isTotalSummaryOnLocation = isLocation && locationSummary === TOTAL;

  // adjust data types
  if (isSell || isLocation) {
    // @ts-ignore - no point to compare type of query param to chart data type
    if ([VALUE, QUANTITY, NET_QUANTITY_SALES].includes(firstDataType)) {
      params[QP.CHART_DATA_TYPE_FIRST] = VALUE_PER_SHOP.toLowerCase();
    }
  }

  if (
    // @ts-ignore - no point to compare type of query param to chart data type
    [AVG_SHOPS_COUNT, DISTRIBUTION_RANGE].includes(secondDataType)
  ) {
    params[QP.CHART_DATA_TYPE_SECOND] = NONE;
  }

  // adjust summary types
  if (isTotalSummaryOnSales) {
    params[QP.SELL_SUMMARY_TYPE] = PER_SHOP.toLowerCase();
  }

  if (isTotalSummaryOnLocation) {
    params[QP.LOCATION_SUMMARY_TYPE] = PER_SHOP.toLowerCase();
  }

  return params;
};
