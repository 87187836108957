import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isRestrictedToOneLineSelector } from "components/molecules/ChartTypeToggle/hooks";
import { useAllLegendLineNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendLineNames";
import {
  hiddenLinesByReportSelector,
  toggleChart
} from "pages/Reports/redux/reducers/chartReducer";

export const useOneLineSelection = () => {
  const dispatch = useDispatch();
  const isRestrictedToOneLine = useSelector(isRestrictedToOneLineSelector);
  const hiddenLinesArray = useSelector(hiddenLinesByReportSelector);
  const allLegendLineNames = useAllLegendLineNames();

  const hiddenLinesId = hiddenLinesArray.join(";");
  const allLinesId = allLegendLineNames.join(";");

  // select only first item from legend on page load & report change
  useEffect(() => {
    if (isRestrictedToOneLine) {
      const hiddenLines = hiddenLinesId.split(";").filter(name => name !== "");
      const allLines = allLinesId.split(";").filter(name => name !== "");
      const visibleLines = Math.abs(allLines.length - hiddenLines.length);

      if (allLines.length > 0 && visibleLines !== 1) {
        dispatch(toggleChart([]));
        dispatch(toggleChart(allLines));
        dispatch(toggleChart([allLines[0]]));
      }
    }
  }, [allLinesId, dispatch, hiddenLinesId, isRestrictedToOneLine]);
};
