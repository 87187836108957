import { format } from "d3";

import {
  ExplainabilityUnit,
  InsightExplainability
} from "pages/Insights/utils/types";

type Row = InsightExplainability[number]["table"][number];
type Column = Row["columns"][number];

export const formatColumnValue = (column: Column) => {
  const { value, unit } = column;

  if (value === null) {
    return "";
  }

  if (typeof value === "string") {
    return value;
  }

  switch (unit) {
    case ExplainabilityUnit.QUANTITY:
    case ExplainabilityUnit.NATURAL_UNITS:
      return `${format(",.0f")(value)} ${unit}`;
    case ExplainabilityUnit.CURRENCY:
    case ExplainabilityUnit.PERCENT_POINT:
      return `${format(",.2f")(value)} ${unit}`;
    case ExplainabilityUnit.PERCENT:
      if (value === 0) return "0%";
      if (value === 100) return "100%";
      return format(".2%")(value / 100);
    default:
      return "";
  }
};

export const findColumnIndexes = (columns: Column[], name: string) =>
  columns.reduce((result: number[], column, index) => {
    if (name === column.value) result.push(index);
    return result;
  }, []);

export const removeColumnByIndexes = (columns: Column[], indexes: number[]) =>
  columns.filter((_, index) => !indexes.includes(index));

// [PMD-4476]: in case change value is between -1 and 1 for natual units, we want to display 0 for percentage change
export const adjustPercentageChangeColumn = (
  change: Column,
  percentageChange: Column
) => {
  if (
    change.value === null ||
    percentageChange.value === null ||
    typeof change.value === "string" ||
    typeof percentageChange.value === "string" ||
    change.unit !== ExplainabilityUnit.NATURAL_UNITS
  ) {
    return percentageChange;
  }
  return change.value > -1 && change.value < 1
    ? { ...percentageChange, value: 0 }
    : percentageChange;
};
