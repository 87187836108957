import { combineReducers } from "redux";

import { dynamicFiltersQueryReducer } from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { chartReducer } from "pages/Reports/redux/reducers/chartReducer";
import { locationReducer } from "pages/Reports/sections/Location/redux/";
import { segmentsChartReducer } from "pages/Reports/sections/Segments/redux/reducers/segmentsChartReducer";

import { reportsFiltersReducer } from "./reducers/filters/filtersReducer";
import { promotionsReducer } from "./reducers/promotionsReducer";

export const reportsReducer = combineReducers({
  asyncFilters: dynamicFiltersQueryReducer,
  location: locationReducer,
  promotions: promotionsReducer,
  chart: chartReducer,
  segments: combineReducers({
    chart: segmentsChartReducer
  }),
  filters: reportsFiltersReducer
});
