import {
  FetchedPromotion,
  PromotionPeriod,
  PromotionsApi
} from "pages/Reports/redux/reducers/promotionsReducer";
import { PROMOTION_DATA_TYPES } from "utils";
import { isMultipackWsaEnabled } from "utils/constants";

export const PROMOTION_BACKEND_TYPES = {
  ZESTAWY: "Zestawy",
  PODKLADKA_ALKOHOLOWA: "Podkładka Alkoholowa",
  ODLICZANIE: "Odliczanie",
  ODLICZANIE_WSO: "Odliczanie (WSO)",
  MENUBOARD: "Menuboard",
  KATALOG: "Katalog",
  KATALOG_WSO: "Katalog (WSO)",
  HAPPY_HOURS: "Happy Hours",
  GAZETKA: "Gazetka",
  GAZETKA_WSO: "Gazetka (WSO)",
  APLIKACJA: "Aplikacja",
  AKCJA_POLECAMY: "Akcja Polecamy",
  WIELOSZTUKA: "Wielosztuka (WS)",
  WIELOSZTUKA_SUPER_OFERTA: "Wielosztuka Super Oferta (WSO)",
  WIELOSZTUKA_APLIKACYJNA: "Wielosztuka Aplikacyjna (WSA)",
  APLIKACJA_WSO: "Aplikacja WSO",
  APLIKACJA_WSO_MULTIPACK: "Aplikacja (WSO)",
  ZESTAWY_APLIKACJA: "Zestawy Aplikacja"
};

const transformDisplay = (display: string) => {
  switch (display) {
    case PROMOTION_BACKEND_TYPES.ZESTAWY:
      return PROMOTION_DATA_TYPES.ZESTAWY;
    case PROMOTION_BACKEND_TYPES.PODKLADKA_ALKOHOLOWA:
      return PROMOTION_DATA_TYPES.PODKLADKA_ALKOHOLOWA;
    case PROMOTION_BACKEND_TYPES.ODLICZANIE:
      return PROMOTION_DATA_TYPES.ODLICZANIE;
    case PROMOTION_BACKEND_TYPES.ODLICZANIE_WSO:
      return PROMOTION_DATA_TYPES.ODLICZANIE_WSO;
    case PROMOTION_BACKEND_TYPES.MENUBOARD:
      return PROMOTION_DATA_TYPES.MENUBOARD;
    case PROMOTION_BACKEND_TYPES.KATALOG:
      return PROMOTION_DATA_TYPES.KATALOG;
    case PROMOTION_BACKEND_TYPES.KATALOG_WSO:
      return PROMOTION_DATA_TYPES.KATALOG_WSO;
    case PROMOTION_BACKEND_TYPES.HAPPY_HOURS:
      return PROMOTION_DATA_TYPES.HAPPY_HOURS;
    case PROMOTION_BACKEND_TYPES.GAZETKA:
      return PROMOTION_DATA_TYPES.GAZETKA;
    case PROMOTION_BACKEND_TYPES.GAZETKA_WSO:
      return PROMOTION_DATA_TYPES.GAZETKA_WSO;
    case PROMOTION_BACKEND_TYPES.APLIKACJA:
      return PROMOTION_DATA_TYPES.APLIKACJA;
    case PROMOTION_BACKEND_TYPES.AKCJA_POLECAMY:
      return PROMOTION_DATA_TYPES.AKCJA_POLECAMY;
    case PROMOTION_BACKEND_TYPES.WIELOSZTUKA:
      return PROMOTION_DATA_TYPES.WIELOSZTUKA;
    case PROMOTION_BACKEND_TYPES.WIELOSZTUKA_SUPER_OFERTA:
      return PROMOTION_DATA_TYPES.WIELOSZTUKA_SUPER_OFERTA;
    case PROMOTION_BACKEND_TYPES.WIELOSZTUKA_APLIKACYJNA:
      return isMultipackWsaEnabled
        ? PROMOTION_DATA_TYPES.WIELOSZTUKA_APLIKACYJNA
        : PROMOTION_DATA_TYPES.WIELOSZTUKA;
    case PROMOTION_BACKEND_TYPES.APLIKACJA_WSO:
      return PROMOTION_DATA_TYPES.APLIKACJA_WSO;
    case PROMOTION_BACKEND_TYPES.APLIKACJA_WSO_MULTIPACK:
      return PROMOTION_DATA_TYPES.APLIKACJA_WSO_MULTIPACK;
    case PROMOTION_BACKEND_TYPES.ZESTAWY_APLIKACJA:
      return PROMOTION_DATA_TYPES.ZESTAWY_APLIKACJA;
    default:
      return PROMOTION_DATA_TYPES.UNKNOWN_PROMOTION; //this one might come as null - we show it as "Pozostałe" type
  }
};

export const transformPromotionApiData = (
  data: PromotionsApi[],
  period: PromotionPeriod
): FetchedPromotion[] => {
  return data.map(
    ({
      valid_to,
      valid_from,
      variant,
      display,
      materials,
      promotion_group,
      promotion_desc,
      category_display
    }) => ({
      validTo: valid_to || "",
      validFrom: valid_from || "",
      variant: variant || "",
      display: transformDisplay(display) || "",
      materials: {
        competingMaterials: materials?.competing_materials || [],
        ownMaterials: materials?.own_materials || []
      },
      promotionGroup: promotion_group || "",
      promotionDesc: promotion_desc || "",
      categoryDisplay: category_display || "",
      period // [PMD-4260]: needed to separate promotions in sidebar
    })
  );
};
