import * as React from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { EnterHandler } from "react-transition-group/Transition";

import { TooltipContent } from "api/types";

import { Fade } from "components/atoms";
import { ChartTooltipCell } from "components/visualisation/charts/ChartTooltip/ChartTooltipCell";
import { ChartTooltipTable } from "components/visualisation/charts/ChartTooltip/ChartTooltipTable";
import s from "pages/Reports/partials/Chart/ReportBasedTooltip/segmentsTooltip.module.scss";
import {
  isFilterSidebarOpenSelector,
  windowWidthSelector
} from "store/selectors/appSelectors";
import { Nullable } from "utils/types";

type Props = {
  tooltipData: Nullable<TooltipContent>;
  positionX: number;
  positionY: number;
  boundX: number;
};

const OFFSET = 15;

const useCardWidth = () => {
  const windowWidth = useSelector(windowWidthSelector);
  const isFilterSidebarOpen = useSelector(isFilterSidebarOpenSelector);

  return isFilterSidebarOpen ? windowWidth + 300 : windowWidth;
};

export const SegmentsTooltip: React.FC<Props> = ({
  tooltipData,
  positionX,
  positionY,
  boundX
}) => {
  const [tooltipWidth, setTooltipWidth] = React.useState(0);
  const [tooltipHeight, setTooltipHeight] = React.useState(0);
  const [showOnLeft, setShowOnLeft] = React.useState(false);
  const [node, setNode] = React.useState<Nullable<Element>>(null);
  const cardWidth = useCardWidth();

  const handleTooltipAppear: EnterHandler = node => {
    const element = node.childNodes[0] as Element;
    const rect = element?.getBoundingClientRect();

    setNode(node);
    setTooltipWidth(rect.width || 0);
    setTooltipHeight(rect.height || 0);

    const showOnTheLeft = cardWidth / 2 < rect.left;
    setShowOnLeft(showOnTheLeft);
  };

  React.useEffect(() => {
    if (node) {
      const element = node.childNodes[0] as Element;
      const rect = element.getBoundingClientRect();

      const left = showOnLeft
        ? rect.left + tooltipWidth + OFFSET
        : rect.left - OFFSET;
      const showOnTheLeft = cardWidth / 2 < left;
      setShowOnLeft(showOnTheLeft);
    }
  }, [cardWidth, node, showOnLeft, tooltipWidth, positionX]);

  if (!tooltipData) return null;

  const showCell = tooltipData.data?.length <= 1;

  const styles: React.CSSProperties = {
    position: "absolute",
    top: positionY,
    left: positionX,
    transform: showCell
      ? `translate(${boundX - tooltipWidth / 2}px, -${tooltipHeight + 20}px)`
      : showOnLeft
      ? `translate(${boundX - tooltipWidth - OFFSET}px, 0)`
      : `translate(${boundX + OFFSET}px, 0)`,
    zIndex: 8 // because full screen wrapper has z-index: 7
  };

  return ReactDOM.createPortal(
    <Fade
      styles={styles}
      timeout={50}
      onEntered={handleTooltipAppear}
      appear
      in
    >
      <>
        {showCell ? (
          <ChartTooltipCell tooltipData={tooltipData} />
        ) : (
          <ChartTooltipTable
            className={s.segmentsTooltip}
            tooltipData={tooltipData}
          />
        )}
      </>
    </Fade>,
    document.body
  );
};
