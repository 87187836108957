import React from "react";

import cn from "classnames";

import s from "./popupWindow.module.scss";

interface Props {
  className?: string;
  children: React.ReactElement;
}

export const PopupWindow = ({ children, className }: Props) => {
  return (
    <section className={cn(s.popupContainer, className)}>{children}</section>
  );
};
