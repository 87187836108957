const ORDER = ["A", "O", "T", "K", "N"];

// use in this order -> sortByIndex, sortByPrefix, sortUnknown

export const sortByIndex = (a: string, b: string) => {
  return a.localeCompare(b);
};

export const sortByPrefix = (a: string, b: string, order = ORDER) => {
  const prefixA = a.charAt(0);
  const prefixB = b.charAt(0);

  return order.indexOf(prefixA) - order.indexOf(prefixB);
};

export const sortUnknown = (a: string, b: string) => {
  const prefixA = a.charAt(0);
  const prefixB = b.charAt(0);

  if (!ORDER.includes(prefixA) && ORDER.includes(prefixB)) {
    return 1;
  }

  if (ORDER.includes(prefixA) && !ORDER.includes(prefixB)) {
    return -1;
  }

  return 0;
};
