import React, { useEffect, useState } from "react";

import cn from "classnames";

import { Button, Text, Toggle } from "components/atoms";
import { ChevronDown, ChevronUp } from "components/atoms/Icon";
import { ICON_SIZES } from "utils";

import s from "./selectMenu.module.scss";

export type Option = {
  label: string;
  value: string;
};

interface Props {
  options: Option[];
  onChange: (event: string) => void;
  value: string;
  id: string;
  className?: {
    button?: string;
    label?: string;
  };
  label?: string;
  name: string;
  error?: string;
  placeholder?: string;
}

export const SelectMenu = ({
  options,
  onChange,
  value,
  id,
  className = {},
  label,
  error,
  placeholder
}: Props) => {
  const [valueToShow, setValueToShow] = useState(placeholder);

  useEffect(() => {
    options.forEach(option => {
      if (option.value === value) {
        setValueToShow(option.label);
      }
    });
  }, [options, value]);

  const handleChange = (_: boolean, value: string) => {
    onChange(value);
  };

  return (
    <Toggle<string> onClickOutside="close" onChange={handleChange}>
      {({ isOpen, setOpen }) => {
        return (
          <div className={s.selectWrapper}>
            {label && (
              <Text className={cn(s.labelText, className.label)}>{label}</Text>
            )}
            <Button
              type="button"
              className={cn(s.selectMenuButton, className.button)}
              onClick={() => setOpen(!isOpen)}
            >
              <Text className={cn({ [s.placeholder]: value?.length === 0 })}>
                {valueToShow}
              </Text>
              <div>
                {isOpen ? (
                  <ChevronUp
                    className={s.chevronIcon}
                    size={ICON_SIZES.NORMAL}
                  />
                ) : (
                  <ChevronDown
                    className={s.chevronIcon}
                    size={ICON_SIZES.NORMAL}
                  />
                )}
              </div>
            </Button>
            {isOpen && (
              <ul className={s.menuWrapper}>
                {options.map(option => (
                  <li
                    key={option.value}
                    className={s.selectOption}
                    onClick={() => setOpen(!isOpen, option.value)}
                    id={id}
                    value={option.value}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            )}
            {error && <Text className={s.errorText}>{error}</Text>}
          </div>
        );
      }}
    </Toggle>
  );
};
