import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";

import { Button } from "components/atoms";
import { updateQueryParams } from "store/actions/routerActions";
import { isReportTableOpenSelector } from "store/selectors/routerSelectors";
import { QP } from "utils/defaultQueryParams";
import { pushTableEvent } from "utils/googleTagManager/dataLayer";

import s from "./reportTableToggler.module.scss";

export const ReportTableToggler: FC = ({ children }) => {
  const dispatch = useDispatch();
  const isReportTableOpen = useSelector(isReportTableOpenSelector);

  const toggle = () => {
    dispatch(
      updateQueryParams({ [QP.TABLE_OPEN]: String(!isReportTableOpen) })
    );
    pushTableEvent(`dane - ${isReportTableOpen ? "hide" : "show"}`);
  };

  return (
    <>
      <Button
        className={cn(s.button, { [s.expanded]: isReportTableOpen })}
        onClick={toggle}
        data-ga-table={isReportTableOpen ? "collapse" : "expand"}
      >
        {isReportTableOpen ? "Zwiń dane" : "Pokaż dane"}
      </Button>
      {isReportTableOpen && children}
    </>
  );
};
