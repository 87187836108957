import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Image from "assets/images/sell-modal.png";
import { format, isBefore, subDays } from "date-fns";

import { Text } from "components/atoms";
import c from "components/common.module.scss";
import { OnboardingModal } from "components/organisms";
import { TourHooks } from "components/organisms/Tour/hooks/useTours";
import { NEWS_PATHS } from "pages/News/constants";
import { CategoryItem } from "pages/Reports/redux/reducers/filters/categoryFilters/categoryFiltersActions";
import { FILTERS } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { CategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { isPowerUserSelector, tierSelector } from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { APP_PATHS, isThisPage, MODAL_KEYS, TEXT_WRAPPERS } from "utils";
import { CLIENT_TYPES, DEFAULT_DATE_FORMAT } from "utils/constants";
import { QP } from "utils/defaultQueryParams";
import { Nullable } from "utils/types";

const accessLevels =
  FILTERS.find(filter => filter.name === "clientType")?.accessLevels || [];

export const useSellOnboardingModalHidden = () => {
  const pathname = useSelector(pathnameSelector);
  const tier = useSelector(tierSelector);
  const [{ isSellOnboardingModalHidden }] = TourHooks.useTours();

  return isThisPage(pathname, REPORTS_FULL_PATHS.SELL_PATH) &&
    accessLevels.includes(tier)
    ? isSellOnboardingModalHidden
    : true;
};

export const SellOnboardingModal = () => {
  const isPowerUser = useSelector(isPowerUserSelector);
  const [categories1] = CategoryHooks.useAllCategories(1);
  const [categories2] = CategoryHooks.useAllCategories(2);
  const [categories3] = CategoryHooks.useAllCategories(3);
  const [, actions] = TourHooks.useTours();
  const isHidden = useSellOnboardingModalHidden();

  if (isHidden) return null;

  const handleReportGenerate = () => {
    let cat1 = "",
      cat2 = "",
      cat3 = "",
      selected: Nullable<CategoryItem> = null;

    for (let category of categories1) {
      const id = String(category.value);
      if (!category.isFake) {
        selected = category; // select non-fake category
        cat1 = id; // and store id
        break;
      } else {
        cat1 = id; // store fake category id
      }
    }

    // if there's no non-fake category found
    if (selected === null) {
      for (let category of categories2) {
        const id = String(category.value);
        if (!category.isFake && id.startsWith(cat1)) {
          selected = category; // select non-fake category which starts with cat1 id
          cat2 = id; // store id
          break;
        } else if (id.startsWith(cat1)) {
          cat2 = id; // store fake category id
        }
      }
    }

    // if there's no non-fake category found
    if (selected === null) {
      for (let category of categories3) {
        const id = String(category.value);
        if (!category.isFake && id.startsWith(cat2)) {
          selected = category; // select non-fake category which starts with cat2 id
          cat3 = id; // store id
          break;
        }
      }
    }

    if (!selected) return;

    const today = newDateByTimezone();
    const dateFrom = subDays(today, 30);
    const dateTo = subDays(today, 1);
    const isDateValid = isPowerUser
      ? true
      : isBefore(new Date(selected.startDate), dateFrom) &&
        isBefore(new Date(selected.startDate), dateTo);
    if (!isDateValid) return;

    const searchString = new URLSearchParams({
      [QP.CATEGORY1]: cat1,
      [QP.CATEGORY2]: cat2,
      [QP.CATEGORY3]: cat3,
      [QP.DATE_FROM]: format(dateFrom, DEFAULT_DATE_FORMAT),
      [QP.DATE_TO]: format(dateTo, DEFAULT_DATE_FORMAT),
      [QP.CLIENT]: [CLIENT_TYPES.ALL, CLIENT_TYPES.ONLINE].join(",")
    }).toString();

    window.location.search = searchString;
  };

  return (
    <OnboardingModal
      title="Sprzedaż z Żappką"
      localStorageKey={MODAL_KEYS.IS_SELL_MODAL_HIDDEN}
      button={{ text: "Wygeneruj przykładowy raport", center: true }}
      onClose={() => {
        actions.setSellOnboardingModalHidden(true);
        handleReportGenerate();
      }}
    >
      <Text Wrapper={TEXT_WRAPPERS.P} className={c.modalText}>
        Masz ochotę dowiedzieć się więcej na temat użycia aplikacji mobilnej
        Żappka wśród klientów naszej sieci?
      </Text>
      <Text Wrapper={TEXT_WRAPPERS.P} className={c.modalText}>
        Sprawdzić jaki udział w sprzedaży ma aplikacja Żappka?
      </Text>
      <Text Wrapper={TEXT_WRAPPERS.P} className={c.modalText}>
        Porównać na jednym wykresie sprzedaż total vs z użyciem Żappki?
      </Text>
      <Text Wrapper={TEXT_WRAPPERS.P} className={c.modalText}>
        Od teraz możesz to w prosty sposób zrobić na jednym z raportów:
        Sprzedaż, Udziały, Dynamika, Paragony i Segmenty.
      </Text>
      <img src={Image} alt="Sprzedaż z Żappką" className={c.modalImage} />
      <Text Wrapper={TEXT_WRAPPERS.P} className={c.modalText}>
        <Link
          to={APP_PATHS.NEWS + NEWS_PATHS.NEWSLETTER}
          className={c.modalLink}
        >
          Więcej informacji w aktualnościach
        </Link>
      </Text>
    </OnboardingModal>
  );
};
