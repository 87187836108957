import { useSelector } from "react-redux";

import { useIsDynamicsLoading } from "api/dynamics/mappers";
import { useIsHoursLoading } from "api/hours/mappers/useIsHoursLoading";
import { useIsLocationsLoading } from "api/location/mappers/useIsLocationLoading";
import { useIsLogisticsLoading } from "api/logistics/mappers/useIsLogisticsLoading";
import { useIsLoyaltyLoading } from "api/loyalty/mappers/useIsLoyaltyLoading";
import { useIsMultipackLoading } from "api/multipack/mappers";
import { useGetRanking } from "api/ranking/hooks/useGetRanking";
import { useIsReceiptsLoading } from "api/receipts/mappers/useIsReceiptsLoading";
import { useIsSegmentsLoading } from "api/segments/mappers";
import { useIsSellsLoading } from "api/sells/mappers/useIsSellsLoading";
import { useIsSharesLoading } from "api/shares/mappers/useIsSharesLoading";
import { ReportTypes } from "api/shares/types";

import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage } from "utils";

const {
  SELL_PATH,
  MULTIPACK_PATH,
  LOCATION_PATH,
  SHARES_PATH,
  RECEIPTS_PATH,
  DYNAMICS_PATH,
  LOGISTICS_PATH,
  SEGMENTS_PATH,
  HOURS_PATH,
  LOYALTY_PATH
} = REPORTS_FULL_PATHS;

export const useFetchingStatus = (
  type: ReportTypes = ReportTypes.CHART
): boolean => {
  const pathname = useSelector(pathnameSelector);

  const { isLoading: rankingLoading } = useGetRanking();

  const sellLoading = useIsSellsLoading();
  const multipackLoading = useIsMultipackLoading();
  const locationLoading = useIsLocationsLoading();
  const hoursLoading = useIsHoursLoading();
  const sharesLoading = useIsSharesLoading();
  const dynamicsLoading = useIsDynamicsLoading();
  const receiptsLoading = useIsReceiptsLoading();
  const segmentsLoading = useIsSegmentsLoading();
  const logisticsLoading = useIsLogisticsLoading();
  const loyaltyLoading = useIsLoyaltyLoading();

  if (rankingLoading) {
    return rankingLoading;
  }

  if (isThisPage(pathname, SELL_PATH)) {
    return sellLoading;
  }

  if (isThisPage(pathname, MULTIPACK_PATH)) {
    return multipackLoading;
  }

  if (isThisPage(pathname, LOCATION_PATH)) {
    return locationLoading;
  }

  if (isThisPage(pathname, SHARES_PATH)) {
    return sharesLoading;
  }

  if (isThisPage(pathname, RECEIPTS_PATH)) {
    return receiptsLoading;
  }

  if (isThisPage(pathname, DYNAMICS_PATH)) {
    return dynamicsLoading;
  }

  if (isThisPage(pathname, LOGISTICS_PATH)) {
    return logisticsLoading;
  }

  if (isThisPage(pathname, SEGMENTS_PATH)) {
    return segmentsLoading;
  }

  if (isThisPage(pathname, HOURS_PATH)) {
    return hoursLoading;
  }
  if (isThisPage(pathname, LOYALTY_PATH)) {
    return loyaltyLoading;
  }

  return false;
};
