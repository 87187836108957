import React, { useState } from "react";
import Measure, { ContentRect } from "react-measure";
import { useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";

import { uniq } from "ramda";

import { Link, Text, TierValidator } from "components/atoms";
import { ChevronLeft } from "components/atoms/Icon";
import { CHART_PERIODS } from "components/D3/constants";
import { ChartTypeToggle, PeriodMenu } from "components/molecules";
import { FullScreenButton } from "pages/Reports/partials/FullScreenWrapper/FullScreenWrapper";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { chartPeriodSelector } from "store/selectors/routerSelectors";
import { ICON_SIZES, TEXT_WRAPPERS } from "utils";

import s from "./chartSectionHeader.module.scss";

interface Props {
  warnings: string[];
  isLoading: boolean;
}

const PERIOD_MENU_BREAKPOINT = 850;

const { LOCATION_PATH } = REPORTS_FULL_PATHS;

const Warnings = ({
  isLoading,
  warnings
}: {
  isLoading: boolean;
  warnings: string[];
}) => {
  if (isLoading || !warnings.length) {
    return null;
  }

  return (
    <div className={s.warnings}>
      {warnings.map(warning => (
        <Text
          key={warning}
          testId="chart-section-warning-text"
          Wrapper={TEXT_WRAPPERS.SPAN}
          className={s.warning}
        >
          {warning}
        </Text>
      ))}
    </div>
  );
};

export const ChartSectionHeader: React.FC<Props> = ({
  warnings,
  isLoading
}) => {
  const { search } = useLocation();
  const [width, setWidth] = useState(0);

  const isVoivodeshipPage = useRouteMatch(`${LOCATION_PATH}/:voivodeship`);
  const period = useSelector(chartPeriodSelector);

  const isTotal = period === CHART_PERIODS.TOTAL;
  const isVoivodeshipMapVisible = isTotal && isVoivodeshipPage;
  const uniqWarnings = uniq(warnings);

  const handleResize = (contentRect: ContentRect) => {
    const contentRectWidth = contentRect.bounds?.width || 0;

    setWidth(contentRectWidth);
  };

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <>
          <Warnings isLoading={isLoading} warnings={uniqWarnings} />
          <div ref={measureRef} className={s.wrapper}>
            <FullScreenButton />
            <div className={s.controls}>
              <PeriodMenu
                isSmall={width <= PERIOD_MENU_BREAKPOINT}
                className={s.periodMenu}
              />
              <TierValidator accessLevels={[3, 4]}>
                <ChartTypeToggle />
              </TierValidator>
            </div>
          </div>
          {isVoivodeshipMapVisible && (
            <Link className={s.voivBackLink} href={LOCATION_PATH + search}>
              <Text className={s.voivBackLinkText}>
                <ChevronLeft
                  className={s.voivBackLinkIcon}
                  size={ICON_SIZES.LARGE}
                />
                Wróć do mapy województw
              </Text>
            </Link>
          )}
        </>
      )}
    </Measure>
  );
};
