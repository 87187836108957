import * as React from "react";

import cn from "classnames";

import { RadioButton } from "components/atoms";
import { TooltipIcon } from "components/atoms/Icon";
import { isProductWithdrawn } from "utils";

import { Checkbox } from "../";
import { DropdownItem, PropGetter } from "../types";
import s from "./filterDropdownListItem.module.scss";

type ItemPropGetter = PropGetter<"getItemProps">;

interface Props<Value = any> {
  getItemProps?: ItemPropGetter;
  isActive?: boolean;
  inputValue?: string;
  className?: {
    base?: string;
    itemText?: string;
  };
  item?: DropdownItem<Value>;
  style?: React.CSSProperties;
  isMultiChoice?: boolean;
  shouldMarkWithdrawnItems?: boolean;
}

export function FilterDropdownListItem<Value = any>({
  isActive,
  inputValue,
  className = {},
  style,
  isMultiChoice,
  shouldMarkWithdrawnItems = false,
  ...props
}: Props<Value>) {
  const getItemProps = props.getItemProps as ItemPropGetter;
  const item = props.item as DropdownItem<Value>;

  return (
    <li
      {...getItemProps({
        className: cn(s.base, className.base),
        item,
        disabled: item.isDisabled,
        style: {
          ...style,
          width: "calc(100% - 12px)"
        },
        onMouseMove: e => {
          // @ts-ignore this is not included in downshift definition but necessary here
          // https://github.com/downshift-js/downshift#customizing-handlers
          e.nativeEvent.preventDownshiftDefault = true;
        }
      })}
      data-testid="filter-list-item"
    >
      {isMultiChoice ? (
        <Checkbox
          isChecked={isActive}
          isDisabled={item.isDisabled}
          label={item.menuLabel || item.label}
          className={{
            label: cn(s.filterElementText, className.itemText, {
              [s.greyText]:
                shouldMarkWithdrawnItems &&
                isProductWithdrawn(item.menuLabel || item.label)
            })
          }}
          // workaround to avoid unexpected events while clicking on label - https://github.com/downshift-js/downshift/issues/465
          onLabelClick={e => e.preventDefault()}
        />
      ) : (
        <RadioButton
          isChecked={isActive}
          isDisabled={item.isDisabled}
          label={item.menuLabel || item.label}
          className={{ label: className.itemText }}
        />
      )}
      {item.tooltipText && (
        <div className={s.tooltipWrapper} data-tooltip={item.tooltipText}>
          <TooltipIcon />
        </div>
      )}
    </li>
  );
}
