import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";

import cn from "classnames";

import { Text } from "components/atoms";
import { TooltipIcon } from "components/atoms/Icon";
import { Checkbox } from "components/molecules";
import { IDS } from "components/organisms/Tour";
import { DROPDOWN_LABELS } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/constants";
import {
  AggregatedHooks,
  AggregationTypes
} from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";
import { AggregatedCompetitionHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useAggregatedCompetition";
import { showAggregationDropdownSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { REPORTS_PATHS } from "pages/Reports/utils/constants";
import {
  aggregatedCompetitionQueryParamSelector,
  aggregatedQueryParamSelector
} from "store/selectors/routerSelectors";
import { APP_PATHS, isThisPage } from "utils";
import { pushLflFilterHover } from "utils/googleTagManager/dataLayer";

import { AggregationDropdown } from "./AggregationDropdown";
import s from "./aggregationFilters.module.scss";

const { CHART_OPTIONS, EXPORT_OPTIONS } = DROPDOWN_LABELS;

type Props = {
  isDisabled: boolean;
  isCompetitionDisabled: boolean;
  isCompetitionVisible: boolean;
};

export const AggregationFilters = ({
  isDisabled,
  isCompetitionDisabled,
  isCompetitionVisible
}: Props) => {
  const { pathname } = useLocation();
  const [aggregatedState, aggregatedActions] = AggregatedHooks.useAggregated();
  const [
    aggregatedCompetitionState,
    aggregatedCompetitionActions
  ] = AggregatedCompetitionHooks.useAggregatedCompetition();
  const aggregatedQP = useSelector(aggregatedQueryParamSelector);
  const aggregatedCompetitionQP = useSelector(
    aggregatedCompetitionQueryParamSelector
  );
  const showAggregationDropdown = useSelector(showAggregationDropdownSelector);

  const exportRouteMatch = useRouteMatch(APP_PATHS.EXPORT);
  const isExportPage = Boolean(exportRouteMatch);
  const isLoyaltyPage = isThisPage(pathname, REPORTS_PATHS.LOYALTY);

  useEffect(() => {
    aggregatedActions.getInitialAggregatedState(aggregatedQP);
    aggregatedCompetitionActions.getInitialAggregationCompetitionState(
      aggregatedCompetitionQP
    );
  }, [
    aggregatedActions,
    aggregatedQP,
    aggregatedCompetitionActions,
    aggregatedCompetitionQP
  ]);

  // disable `aggregated_competition` in case `aggregated` is disabled
  // or for tiers other than 2 and reports other than sales
  useEffect(() => {
    if (
      aggregatedState.selected === AggregationTypes.total &&
      isCompetitionVisible
    )
      return;

    aggregatedCompetitionActions.clearAggregatedCompetition();
  }, [
    aggregatedState.selected,
    isCompetitionVisible,
    aggregatedCompetitionActions
  ]);

  return (
    <div className={s.base}>
      <Text className={s.title}>
        {isExportPage ? EXPORT_OPTIONS : CHART_OPTIONS}
      </Text>
      <div id={IDS.AGGREGATION_WRAPPER} className={s.aggregateWrapper}>
        {showAggregationDropdown ? (
          <AggregationDropdown isDisabled={isDisabled} />
        ) : (
          <Checkbox
            className={{
              base: s.checkbox,
              label: cn(s.checkboxLabel, {
                [s.checkboxLabelChecked]:
                  aggregatedState.selected === AggregationTypes.total
              })
            }}
            isChecked={aggregatedState.selected === AggregationTypes.total}
            onChange={e =>
              aggregatedActions.updateAggregated(
                e.currentTarget.checked
                  ? AggregationTypes.total
                  : AggregationTypes.false
              )
            }
            label="Agreguj dane"
            isDisabled={isDisabled}
            testId="aggregation-checkbox"
            ga={{
              "data-ga-filter-aggregation": "Agreguj dane"
            }}
          />
        )}
        {isLoyaltyPage && (
          <div
            className={s.tooltipWrapper}
            onMouseEnter={pushLflFilterHover}
            data-tooltip={
              "W raporcie LOJALNOŚCI dane są prezentowane bez możliwości agregacji"
            }
          >
            <TooltipIcon />
          </div>
        )}
      </div>
      {isCompetitionVisible && (
        <Checkbox
          className={{
            base: s.checkbox,
            label: cn(s.checkboxLabel, {
              [s.checkboxLabelChecked]: aggregatedCompetitionState.aggregated
            })
          }}
          label="Pokaż zagregowane dane konkurencji"
          isChecked={aggregatedCompetitionState.aggregated}
          onChange={e =>
            aggregatedCompetitionActions.updateAggregatedCompetition(
              e.currentTarget.checked
            )
          }
          isDisabled={isCompetitionDisabled}
          testId="aggregate-competition-checkbox"
          ga={{
            "data-ga-filter-aggregate-competition": "Agreguj dane konkurencji"
          }}
        />
      )}
    </div>
  );
};
