import { omit } from "ramda";

import { SellSummary, SellSummaryApi } from "pages/Reports/types/summary";

import { snake2camel } from "../snake2camel";

export const normalizeSellSummary = (summary: SellSummaryApi) => {
  return {
    unit: summary.unit?.toLowerCase() || "",
    ...Object.keys(omit(["unit"], summary)).reduce(
      (acc, key) => ({
        ...acc,
        [snake2camel(key)]: summary[key as keyof SellSummaryApi] ?? null
      }),
      {}
    )
  } as SellSummary;
};
