import {
  Action,
  createHook,
  createStore,
  defaultRegistry
} from "react-sweet-state";

import {
  getMatched,
  getSelectedCountLabel,
  remainingElementsToSelect,
  selectElement
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import { sortItemsBySelectionOrder } from "pages/Reports/redux/utils";
import { Nullable } from "utils/types";

export type BrandItem = { value: string; label: string };

type State = {
  brand: {
    all: BrandItem[];
    selected: BrandItem[];
  };
  subBrand: {
    all: BrandItem[];
    selected: BrandItem[];
  };
  competingBrand: {
    all: BrandItem[];
    selected: BrandItem[];
  };
  competingSubBrand: {
    all: BrandItem[];
    selected: BrandItem[];
  };
  referenceBrand: {
    all: BrandItem[];
    selected: BrandItem[];
  };
  referenceSubBrand: {
    all: BrandItem[];
    selected: BrandItem[];
  };
  referenceBrandAggregated: boolean;
};
type Actions = typeof actions;

export enum BrandType {
  brand = "brand",
  subBrand = "subBrand",
  competingBrand = "competingBrand",
  competingSubBrand = "competingSubBrand",
  referenceBrand = "referenceBrand",
  referenceSubBrand = "referenceSubBrand"
}

const getReferenceBrandAggregated = (state: State) =>
  state.referenceBrandAggregated;

const actions = {
  updateAllBrands: (all: BrandItem[], type: BrandType): Action<State> => ({
    setState,
    getState
  }) => {
    setState({
      ...getState(),
      [type]: {
        ...getState()[type],
        all
      }
    });
  },
  updateSelectedBrands: (
    selected: BrandItem[],
    type: BrandType
  ): Action<State> => ({ setState, getState }) =>
    setState({
      ...getState(),
      [type]: {
        ...getState()[type],
        selected
      }
    }),
  selectBrand: (
    selectedBrand: Nullable<BrandItem>,
    type: BrandType
  ): Action<State> => ({ setState, getState }) =>
    setState({
      ...getState(),
      [type]: {
        ...getState()[type],
        selected: selectElement(selectedBrand, getState()[type].selected)
      }
    }),
  selectAllBrands: (inputValue: string, type: BrandType): Action<State> => ({
    setState,
    getState
  }) => {
    const state = getState();
    const allBrands = state[type].all;
    const selectedBrands = state[type].selected;

    const remainingToSelect = remainingElementsToSelect(
      selectedBrands,
      allBrands,
      inputValue
    );
    setState({
      ...state,
      [type]: {
        ...state[type],
        selected: [...selectedBrands, ...remainingToSelect]
      }
    });
  },
  sortBrands: (type: BrandType): Action<State> => ({ setState, getState }) => {
    const state = getState();

    const allBrands = state[type].all;
    const selectedBrandsIds = state[type].selected.map(item => item.value);

    const sortedAllBrands = sortItemsBySelectionOrder(
      selectedBrandsIds,
      allBrands
    );
    setState({
      ...state,
      [type]: {
        ...state[type],
        all: sortedAllBrands
      }
    });
  },
  clearBrands: (type: BrandType): Action<State> => ({ setState, getState }) =>
    setState({
      ...getState(),
      [type]: {
        ...getState()[type],
        selected: []
      }
    }),
  checkSelectionsAfterFetching: (
    ids: string[],
    type: BrandType
  ): Action<State> => ({ getState, setState }) =>
    setState({
      ...getState(),
      [type]: {
        ...getState()[type],
        selected: getMatched(getState()[type].selected, ids)
      }
    }),
  checkPristineSelectionsAfterFetching: (
    brands: BrandItem[],
    brandQueryParam: string | string[],
    type: BrandType
  ): Action<State> => ({ setState, getState }) => {
    const updatedSelectedBrands = brands.filter(({ value }) => {
      if (typeof brandQueryParam === "string") {
        return value === brandQueryParam;
      }
      return brandQueryParam.includes(value);
    });

    setState({
      ...getState(),
      [type]: {
        ...getState()[type],
        selected: updatedSelectedBrands
      }
    });
  },
  updateReferenceAggregated: (
    referenceBrandAggregated: boolean
  ): Action<State> => ({ setState }) => {
    setState({ referenceBrandAggregated });
  }
};

export const BrandStore = createStore<State, Actions>({
  name: "brand",
  initialState: {
    brand: {
      all: [],
      selected: []
    },
    subBrand: {
      all: [],
      selected: []
    },
    competingBrand: {
      all: [],
      selected: []
    },
    competingSubBrand: {
      all: [],
      selected: []
    },
    referenceBrand: {
      all: [],
      selected: []
    },
    referenceSubBrand: {
      all: [],
      selected: []
    },
    referenceBrandAggregated: false
  },
  actions
});

export const BrandHooks = {
  useBrand: createHook(BrandStore),
  useBrandLabel: createHook(BrandStore, {
    selector: (state: State, type: BrandType) => {
      if (state[type].selected.length === 1) {
        return state[type].selected[0].label;
      }
      return getSelectedCountLabel(
        state[type].selected.length,
        state[type].all.length
      );
    }
  }),
  useAllBrands: createHook(BrandStore, {
    selector: (state: State, type: BrandType) => state[type].all
  }),
  useSelectedBrands: createHook(BrandStore, {
    selector: (state: State, type: BrandType) => state[type].selected
  }),
  useSelectedBrandsIds: createHook(BrandStore, {
    selector: (state: State, type: BrandType): string[] =>
      state[type].selected.map(item => item.value)
  }),
  useStringifiedSelectedBrandsIds: createHook(BrandStore, {
    selector: (state: State, type: BrandType) =>
      state[type].selected.map(item => item.label).join(", ")
  }),
  useRefBrandAggregated: createHook(BrandStore, {
    selector: getReferenceBrandAggregated
  })
};

export const BrandStoreInstance = defaultRegistry.getStore(BrandStore);
