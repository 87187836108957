import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RadioButton, Text } from "components/atoms";
import { useFiltersQP } from "hooks";
import { useFiltersState } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/AggregationFilters/hooks";
import { DROPDOWN_LABELS } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/constants";
import {
  EXPORT_DATA_TYPE,
  exportDataTypeSelector,
  ExportDataTypeState,
  updateExportDataType
} from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";

import s from "./exportDataType.module.scss";
import { useButtons } from "./hooks";

export const ExportDataType = ({ isDisabled }: { isDisabled: boolean }) => {
  const dispatch = useDispatch();
  const exportDataType = useSelector(exportDataTypeSelector);
  const { exportDataTypeQP } = useFiltersQP();
  const filtersState = useFiltersState();
  const buttons = useButtons(isDisabled);

  const handleChange = (exportDataType: ExportDataTypeState) => {
    if (!isDisabled) {
      dispatch(updateExportDataType(exportDataType));
    }
  };

  useEffect(() => {
    const isAttributesType = exportDataTypeQP === EXPORT_DATA_TYPE.ATTRIBUTES;
    const hasAttributes = filtersState.attributes.length > 0;
    let type = exportDataTypeQP;
    if (isAttributesType && !hasAttributes) type = EXPORT_DATA_TYPE.GENERAL;
    dispatch(updateExportDataType(type));
  }, [dispatch, exportDataTypeQP, filtersState.attributes]);

  return (
    <div className={s.wrapper} data-testid="export-data-type-wrapper">
      <Text className={s.title}>{DROPDOWN_LABELS.EXPORT_DATA_TYPE}</Text>
      {buttons.map(button => (
        <RadioButton
          key={button.exportType}
          className={{ base: s.radioBase }}
          label={button.label}
          isDisabled={button.isDisabled}
          isChecked={exportDataType === button.exportType}
          onChange={() => handleChange(button.exportType)}
          tooltipText={button.tooltipText}
        />
      ))}
    </div>
  );
};
