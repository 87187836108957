import { useSelector } from "react-redux";

import { useGetSegmentsByPeriod } from "api/segments/mappers/useGetSegmentsByPeriod";
import { getName } from "api/segments/utils/getName";
import { TooltipContent } from "api/types";
import { getDataTypeKey } from "api/utils";
import { formatSidebarValue } from "api/utils/formatSidebarValue";
import { mergeAll } from "ramda";

import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { useDataTypeSuffix } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import {
  hiddenChartsSelector,
  hiddenLinesSelector,
  timepointSelector
} from "pages/Reports/redux/reducers/chartReducer";
import { getSidebarHeader } from "pages/Reports/redux/utils/getSidebarHeader";
import { DataTimeline } from "pages/Reports/redux/utils/sortDataByVendorAndLabel";
import { useGetZappkaSharesSuffix } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import { checkedDataTypes } from "pages/Reports/sections/utils/chartData";
import {
  chartPeriodSelector,
  firstChartDataTypeSelector,
  isGroupedBySegmentsSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { Nullable } from "utils/types";

export const useSegmentsTooltip = (): Nullable<TooltipContent>[] => {
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const chartPeriod = useSelector(chartPeriodSelector);
  const timepoint = useSelector(timepointSelector);
  const hiddenLines = useSelector(hiddenLinesSelector);
  const hiddenCharts = useSelector(hiddenChartsSelector);
  const dataset = useCurrentDataset();
  const isGroupedBySegments = useSelector(isGroupedBySegmentsSelector);
  const [suffix1, suffix2] = useDataTypeSuffix();
  const getZappkaSharesSuffix = useGetZappkaSharesSuffix();

  const { data: segments } = useGetSegmentsByPeriod();

  if (timepoint === null || !segments) {
    return [];
  }

  const isBarChart =
    segments.currentPeriod[0]?.segments[0].timeline.length <= 1;

  const data = segments.currentPeriod
    .filter(chart => !hiddenCharts.includes(chart.name))
    .map(period => {
      const name = isGroupedBySegments
        ? period.vendorName
        : getName(period, false);

      if (isBarChart) {
        const segment = period.segments.filter(
          segment => !hiddenLines.segments.includes(segment.label)
        )[timepoint];

        if (!segment) {
          return [
            { tooltipId: "", label: "", name: "", shortLabel: "", data: null }
          ];
        }

        return [
          {
            tooltipId: name,
            label: segment.label,
            name: segment.label,
            shortLabel: isGroupedBySegments
              ? segment.label
              : segment.shortLabel,
            data: segment.timeline[0]
          }
        ];
      }

      return period.segments
        .map(seg => {
          return {
            tooltipId: name,
            label: seg.label,
            name: isGroupedBySegments ? period.vendorName : period.name,
            shortLabel: isGroupedBySegments ? seg.label : seg.shortLabel,
            data: seg.timeline[timepoint || 0]
          };
        })
        .filter(seg => !hiddenLines.segments.includes(seg.label));
    });

  const dataTypes = checkedDataTypes([firstChartType, secondChartType]).map(
    (dataType, index) => {
      const sharesSuffix = getZappkaSharesSuffix(index);
      return {
        baseKey: dataType,
        primaryKey: getDataTypeKey({
          dataType,
          suffix: suffix1,
          sharesSuffix
        }),
        secondaryKey: getDataTypeKey({
          dataType,
          suffix: suffix2,
          sharesSuffix,
          isSecondary: true
        })
      };
    }
  );

  const dataByType = data.map(product =>
    product.map(segment => {
      return mergeAll(
        dataTypes.map(type => {
          const lastYear = {
            primaryKey: type.primaryKey.concat("_LAST_YEAR"),
            secondaryKey: type.secondaryKey.concat("_LAST_YEAR")
          };
          const data = segment.data || ({} as DataTimeline);

          return {
            itemLabel: segment.shortLabel,
            [type.primaryKey]: formatSidebarValue(
              type.baseKey,
              dataset.historical ? lastYear.primaryKey : type.primaryKey,
              data
            ),
            ...(dataset.both && {
              [lastYear.primaryKey]: formatSidebarValue(
                type.baseKey,
                lastYear.primaryKey,
                data
              )
            }),
            ...(Boolean(type.secondaryKey) && {
              [type.secondaryKey]: formatSidebarValue(
                type.baseKey,
                dataset.historical ? lastYear.secondaryKey : type.secondaryKey,
                data
              ),
              ...(dataset.both && {
                [lastYear.secondaryKey]: formatSidebarValue(
                  type.baseKey,
                  lastYear.secondaryKey,
                  data
                )
              })
            })
          };
        })
      );
    })
  );

  const datum = data[0][0]?.data;

  if (!datum) return [];

  const regularDate = datum?.date || "";
  const lastYearDate = datum?.dateLastYear || null;

  const result = dataByType.map((d, idx) => {
    const header = `${data[idx][0].name}\n${getSidebarHeader(
      regularDate,
      lastYearDate,
      chartPeriod,
      dataset
    )}`;

    return {
      name: data[idx][0]?.tooltipId || "",
      header,
      date: newDateByTimezone(regularDate),
      promotions: [],
      data: d
    };
  });

  return result;
};
