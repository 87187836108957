import { useGetRanking } from "api/ranking/hooks";
import { useGetSellsByPeriod } from "api/sells/mappers/useGetSellsByPeriod";

export const useIsSellsLoading = () => {
  const {
    isLoading: isSellsLoading,
    isIdle: isSellsIdle
  } = useGetSellsByPeriod();
  const { isLoading: rankingLoading } = useGetRanking();

  return isSellsLoading || isSellsIdle || rankingLoading;
};
