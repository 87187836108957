import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocationHoveredRegion } from "api/location/mappers/useLocationHoveredRegion";
import { TooltipContent } from "api/types";

import { SingleInsightData } from "pages/Insights/utils/types";
import {
  arePromotionsDisabledSelector,
  fetchedPromotionsSelector
} from "pages/Reports/redux/selectors/promotionsSelectors";
import { usePromotionsOptions } from "pages/Reports/sections/NewChartDropdown/hooks";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  toggleChartSidebar,
  updateChartSidebarWidth
} from "store/actions/appActions";
import { CHART_SIDEBAR_MIN_WIDTH } from "store/reducers/appReducer";
import { isChartSidebarOpenSelector } from "store/selectors/appSelectors";
import {
  chartPeriodSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import { isThisPage, PERIOD_TYPE, PROMOTIONS_FOR_DAY_PERIOD_ONLY } from "utils";
import { pushSidebarEvent } from "utils/googleTagManager/dataLayer";
import { Nullable } from "utils/types";

export type TabItem = {
  id: "" | "data" | "promotions" | "insights";
  label: string;
  disabled: boolean;
};

export const SIDEBAR_CONFIG = {
  MIN_WIDTH: CHART_SIDEBAR_MIN_WIDTH,
  MAX_WIDTH: "60%",
  RESIZE_DELAY: 800
};

// [PMD-4531]: display count of all matched and max 3 unmatched insights
export const getSidebarInsightsCount = (insights: SingleInsightData[]) => {
  if (!insights.length) return 0;
  const matched = insights.filter(insight => insight.similarity >= 1);
  const unmatched = Math.min(3, insights.length - matched.length);
  return matched.length + unmatched;
};

export const useSegmentsSidebarOpenStatus = () => {
  const dispatch = useDispatch();
  const [isInitialMount, setInitialMount] = useState(true);
  const pathname = useSelector(pathnameSelector);
  const options = usePromotionsOptions();

  const isSegmentsPage = isThisPage(pathname, REPORTS_FULL_PATHS.SEGMENTS_PATH);
  const isPromotionSelected = options.selected.length > 0;

  useEffect(() => {
    if (!isSegmentsPage) return;

    if (isInitialMount && !isPromotionSelected) {
      dispatch(toggleChartSidebar(false));
    }

    if (isPromotionSelected) {
      dispatch(toggleChartSidebar(true));
    }

    setInitialMount(false);
  }, [dispatch, isPromotionSelected, isInitialMount, isSegmentsPage]);
};

export const useSidebarState = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isChartSidebarOpenSelector);

  const toggle = useCallback(() => {
    dispatch(toggleChartSidebar(!isOpen));
    setTimeout(() => {
      dispatch(updateChartSidebarWidth(SIDEBAR_CONFIG.MIN_WIDTH));
    }, SIDEBAR_CONFIG.RESIZE_DELAY);
  }, [isOpen, dispatch]);

  return { isOpen, toggle };
};

export const useSidebarTabs = ({
  showPromotionsTab,
  showInsightsTab,
  hasSidebarDate
}: {
  showPromotionsTab: boolean;
  showInsightsTab: boolean;
  hasSidebarDate: boolean;
}) => {
  const [activeTabId, setActiveTabId] = useState<TabItem["id"]>("");
  const pathname = useSelector(pathnameSelector);
  const period = useSelector(chartPeriodSelector);
  const hoveredRegion = useLocationHoveredRegion();

  const isSegments = isThisPage(pathname, REPORTS_FULL_PATHS.SEGMENTS_PATH);
  const isRanking = period === PERIOD_TYPE.RANKING;
  const showDataTab = !isSegments && !isRanking;
  const hasTimepoint = hasSidebarDate || Boolean(hoveredRegion);

  const tabs = useMemo(() => {
    const items: TabItem[] = [];

    if (showDataTab) {
      items.push({
        id: "data",
        label: "Dane",
        disabled: !hasTimepoint
      });
    }
    if (showPromotionsTab) {
      items.push({
        id: "promotions",
        label: "Promocje",
        disabled: isRanking ? false : !hasTimepoint
      });
    }
    if (showInsightsTab) {
      items.push({
        id: "insights",
        label: "Insights",
        disabled: false
      });
    }

    return items;
  }, [
    hasTimepoint,
    isRanking,
    showDataTab,
    showInsightsTab,
    showPromotionsTab
  ]);

  useEffect(() => {
    const firstEnabledTab = tabs.find(tab => !tab.disabled);
    setActiveTabId(firstEnabledTab?.id || "");
  }, [tabs]);

  const onTabChange = (tab: TabItem) => {
    setActiveTabId(tab.id);
    pushSidebarEvent(`show ${tab.label}`);
  };

  return {
    items: tabs,
    activeId: activeTabId,
    onChange: onTabChange
  };
};

export const useSidebarPromotions = (data: Nullable<TooltipContent>) => {
  const pathname = useSelector(pathnameSelector);
  const arePromotionsDisabled = useSelector(arePromotionsDisabledSelector);
  const promotions = useSelector(fetchedPromotionsSelector);
  const options = usePromotionsOptions();

  const isSegments = isThisPage(pathname, REPORTS_FULL_PATHS.SEGMENTS_PATH);
  const isDisabled = isSegments ? false : arePromotionsDisabled; // [PMD-3186]: don't disable promotions tab on segments

  if (arePromotionsDisabled) {
    return {
      isDisabled,
      info: [PROMOTIONS_FOR_DAY_PERIOD_ONLY]
    };
  }

  const isPromotionSelected = options.selected.length > 0;
  const noPromotionsInData = data?.promotions?.length === 0;
  let info = [];

  if (isPromotionSelected && noPromotionsInData) {
    info.push(
      "W tym dniu nie było promocji dot. wybranych produktów lub kategorii"
    );
  }

  if (!isPromotionSelected && promotions.length > 0) {
    info.push(
      "Wybierz przynajmniej jeden typ promocji, aby sprawdzić czy w danym okresie były prowadzone akcje promocyjne"
    );
  }

  if (promotions.length === 0) {
    info.push("W wybranym przez Ciebie okresie nie było promocji.");
  }

  return {
    isDisabled,
    info
  };
};
