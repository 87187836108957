import * as React from "react";

import cn from "classnames";

import s from "./index.module.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
  testId?: string;
}

export const CardContent = ({ className, testId, children }: Props) => (
  <div className={cn(s.base, className)} data-testid={testId}>
    {children}
  </div>
);
