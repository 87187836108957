import { CallHistoryMethodAction, push, replace } from "connected-react-router";
import qs, { ParsedQuery } from "query-string";
import { ThunkAction } from "redux-thunk";

import {
  pathnameSelector,
  queryParamsSelector
} from "store/selectors/routerSelectors";
import { DATE_PARAMS } from "utils";

import { AppState } from "../";

const stringifyRouterPath = (
  pathname: string,
  queryParams: ParsedQuery<string>
) => {
  // If there will be new param which should be sorted at the start
  // just put it below between vendor_id and sub_brands
  const sortedParams = {
    vendor_id: queryParams.vendor_id,
    product: queryParams.product,
    category1: queryParams.category1,
    category2: queryParams.category2,
    category3: queryParams.category3,
    brands: queryParams.brands,
    sub_brands: queryParams.sub_brands,
    ...queryParams
  };

  const mainQuery = qs.stringify(sortedParams, {
    arrayFormat: "comma",
    encode: true,
    sort: false
  });

  const routerPath = pathname + "?" + mainQuery;

  return routerPath;
};

export const updateQueryParams: (
  params: ParsedQuery,
  type?: "replace" | "push",
  replaceParams?: boolean
) => ThunkAction<void, AppState, undefined, CallHistoryMethodAction> = (
  params,
  type = "replace",
  replaceParams = false
) => (dispatch, getState) => {
  const state = getState();
  const pathname = pathnameSelector(state);
  const currentQueryParams = queryParamsSelector(state);
  const currentParamsString = stringifyRouterPath(pathname, currentQueryParams);
  const newParamsString = stringifyRouterPath(pathname, params);

  if (currentParamsString === newParamsString) {
    return;
  }

  let queryParams: ParsedQuery<string> = { ...currentQueryParams, ...params };
  if (replaceParams) {
    queryParams = params;
  }

  if (params.date_from && params.date_to) {
    const date_from = params.date_from as string;
    const date_to = params.date_to as string;

    localStorage.setItem(DATE_PARAMS, JSON.stringify({ date_from, date_to }));
  }
  if (type === "replace") {
    dispatch(replace(stringifyRouterPath(pathname, queryParams)));
  }
  if (type === "push") {
    dispatch(push(stringifyRouterPath(pathname, queryParams)));
  }
};
