import { MapChartData } from "pages/Reports/sections/Location/redux/types/locationChartTypes";
import {
  LocationRegion,
  LocationTimePoint
} from "pages/Reports/sections/Location/redux/types/locationRawTypes";

export const getMapChartData = (
  regions: LocationRegion[],
  key?: keyof Omit<LocationTimePoint, "date" | "dateLastYear">
) => (result: MapChartData, regionId: string): MapChartData => {
  if (!key) {
    return {
      ...result,
      [regionId]: null
    };
  }

  const region = regions.find(({ id }) => id === regionId);
  const value = region?.timeline[0][key] ?? null;

  return {
    ...result,
    [regionId]: value
  };
};
