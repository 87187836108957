import React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const DeactivateUser = ({
  className,
  color = "#43B649",
  size = ICON_SIZES.SMALL
}: Props) => {
  const currentSize = SIZES[size];
  return (
    <svg width={currentSize} height={currentSize} className={className}>
      <path
        fill={color}
        d="M10 5H9V3a3 3 0 10-6 0v2H2a1 1 0 00-1 1v5a1 1 0 001 1h8a1 1 0 001-1V6a1 1 0 00-1-1zM7 5H5V3a1 1 0 112 0v2z"
      />
    </svg>
  );
};
