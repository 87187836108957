import { useSelector } from "react-redux";

import { useGetMultipack } from "api/multipack/hooks/useGetMultipack";
import { ReportTypes } from "api/shares/types";

import {
  chartPeriodSelector,
  isReportTableOpenQPSelector,
  tablePeriodSelector
} from "store/selectors/routerSelectors";
import { PERIOD_TYPE } from "utils";

export const useGetMultipackByPeriod = (
  reportType: ReportTypes = ReportTypes.CHART
) => {
  const chartPeriod = useSelector(chartPeriodSelector);
  const tablePeriod = useSelector(tablePeriodSelector);

  const isTableOpen = useSelector(isReportTableOpenQPSelector);

  const { queriesData, isLoading, isError, isIdle } = useGetMultipack();

  if (isLoading) {
    return {
      isLoading,
      isError,
      isIdle,
      data: undefined
    };
  }

  if (reportType === ReportTypes.TABLE && isTableOpen) {
    const query = queriesData.find(query => query?.periodType === tablePeriod);

    return {
      isLoading,
      isError,
      isIdle,
      data: query
    };
  }

  // it's for summary data. Ranking doesn't return summary data so we have to display aggregation total in the summary tabs
  if (reportType === ReportTypes.CHART && chartPeriod === PERIOD_TYPE.RANKING) {
    const query = queriesData.find(
      query => query?.periodType === PERIOD_TYPE.TOTAL
    );

    return {
      isLoading,
      isError,
      isIdle,
      data: query
    };
  }

  const query = queriesData.find(query => query?.periodType === chartPeriod);

  return {
    isLoading,
    isError,
    isIdle,
    data: query
  };
};
