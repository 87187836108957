import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { stringify } from "query-string";

import { usePrevious } from "hooks";
import { REQUEST_INSIGHT } from "pages/Insights/partials/InsightsBox/utils";
import { getPromotionsData } from "pages/Reports/redux/reducers/promotionsReducer";
import { isReportAccessibleSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  isCategorySelector,
  pathnameSelector,
  queryParamsSelector,
  searchStringSelector
} from "store/selectors/routerSelectors";
import { isThisPage } from "utils";
import { QP } from "utils/defaultQueryParams";
import { usePeriodsToFetch } from "utils/usePeriodsToFetch";

const {
  SELL_PATH,
  LOCATION_PATH,
  SHARES_PATH,
  DYNAMICS_PATH,
  RECEIPTS_PATH,
  LOGISTICS_PATH,
  SEGMENTS_PATH,
  HOURS_PATH,
  LOYALTY_PATH,
  MULTIPACK_PATH
} = REPORTS_FULL_PATHS;

const PARAMS_TRIGGERING_REFETCH = [
  QP.AGGREGATED,
  QP.AGGREGATED_COMPETITION,
  QP.CATEGORY1,
  QP.CATEGORY2,
  QP.CATEGORY3,
  QP.CHART_PERIOD,
  QP.CLIENT,
  QP.COMPETING_COMPANIES,
  QP.COMPETING_MATERIALS,
  QP.COUNTIES,
  QP.DATE_FROM,
  QP.DATE_TO,
  QP.DELAY,
  QP.LFL,
  QP.PRODUCT,
  QP.REF_CAT1,
  QP.REF_CAT2,
  QP.REF_CAT3,
  QP.REF_CAT_AGGREGATED,
  QP.STORE_TYPES,
  QP.STORE_TYPES_META,
  QP.TABLE_PERIOD,
  QP.TIER,
  QP.VENDOR_ID,
  QP.VOIVODESHIPS,
  QP.BRANDS,
  QP.SUB_BRANDS,
  QP.COMPETING_BRANDS,
  QP.COMPETING_SUB_BRANDS,
  QP.TABLE_OPEN,
  QP.COMPARE_DATE_FROM,
  QP.COMPARE_DATE_TO,
  QP.EXCLUDED_DAYS
];

const useReportQuery = () => {
  const pathname = useSelector(pathnameSelector);
  const params = useSelector(queryParamsSelector);
  const beParams = PARAMS_TRIGGERING_REFETCH.reduce(
    (obj, key) => ({ ...obj, [key]: params[key] }),
    {}
  );

  return `${pathname}?${stringify(beParams, { arrayFormat: "comma" })}`;
};

const useReadyFlag = (initial: boolean) => {
  const hasAccess = useSelector(isReportAccessibleSelector);
  const hasCategory = useSelector(isCategorySelector);
  const query = useReportQuery();
  const lastQuery = usePrevious(query);

  return useMemo(() => {
    if (!hasAccess || !hasCategory) return false;

    // don't compare query on initial render to fetch data from URL params on first render
    if (initial) return true;

    return query !== lastQuery;
  }, [hasAccess, hasCategory, initial, query, lastQuery]);
};

export const useQueryWithInsightId = () => {
  const search = useSelector(searchStringSelector);
  const requestId = REQUEST_INSIGHT.get();

  if (!requestId) {
    return {
      search,
      clearInsightId: () => {}
    };
  }

  // PMD-2511: add insight id to the search string cause it can't be passed via query params & clear function since it must be send only once
  return {
    search: `${search}&insight_id=${requestId}`,
    clearInsightId: REQUEST_INSIGHT.remove
  };
};

export const useUpdateDataActions = () => {
  const dispatch = useDispatch();
  const pathname = useSelector(pathnameSelector);
  const [initial, setInitial] = useState(true);
  const isReady = useReadyFlag(initial);
  const reportQuery = useReportQuery();
  const { search, clearInsightId } = useQueryWithInsightId();
  const periodsToFetch = usePeriodsToFetch();

  useEffect(() => {
    setInitial(false);

    if (!isReady) return;

    const shouldFetchPromotions = [
      SELL_PATH,
      MULTIPACK_PATH,
      LOCATION_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH,
      SEGMENTS_PATH,
      HOURS_PATH,
      LOGISTICS_PATH,
      LOYALTY_PATH
    ].some(url => isThisPage(pathname, url));

    if (shouldFetchPromotions) {
      dispatch(getPromotionsData());

      // clear insight in sidebar for reports w/o sidebar
      if ([HOURS_PATH, LOGISTICS_PATH].some(url => isThisPage(pathname, url))) {
        clearInsightId();
      }

      return;
    }
  }, [
    dispatch,
    isReady,
    pathname,
    reportQuery,
    periodsToFetch,
    search,
    clearInsightId
  ]);
};
