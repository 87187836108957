import { appendInsightId } from "pages/Insights/partials/InsightsBox/utils";
import { getOwnSummary, getReferenceSummary } from "pages/Reports/utils";
import { normalizeMultipackResponse } from "pages/Reports/utils/normalize/normalizeMultipackResponse";
import { normalizeMultipackSummary } from "pages/Reports/utils/normalize/normalizeMultipackSummary";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import {
  pushMultipackEvent,
  pushReportFetchEvent
} from "utils/googleTagManager/dataLayer";
import { Periods } from "utils/types";

import { MultipackApi } from "../types";

const FOUR_PACK_WARNING =
  "Dla danych sprzedażowych 4-paków piwa w Wielosztukach prosimy o kontakt z BDA.";

export const multipackServices = {
  fetchMultipack: async (queryString: string, period: Periods) => {
    const url = appendInsightId(
      `${REST_API_ENDPOINTS.REPORTS.MULTIPACK}?${queryString}`
    );
    const { data } = await HTTP.get<MultipackApi>(url);

    const ownSummary = getOwnSummary(data.summary);
    const referenceSummary = getReferenceSummary(data.summary);

    const standardizedData = {
      ownSummary: normalizeMultipackSummary(ownSummary),
      referenceSummary: referenceSummary
        ? normalizeMultipackSummary(referenceSummary)
        : null,
      data: normalizeMultipackResponse(data.data),
      info: data.info || []
    };

    pushReportFetchEvent("Wielosztuki");
    // [PMD-4613]: send data layer in case response contains four pack warning
    if (data.info?.includes(FOUR_PACK_WARNING)) {
      pushMultipackEvent({
        action: "warning",
        value: "multipack-warning-4-paki"
      });
    }

    return {
      query: queryString,
      timestamp: Date.now(),
      isActive: false,
      data: standardizedData,
      periodType: period
    };
  }
};
