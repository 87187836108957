import React from "react";
import Scrollbars, {
  positionValues,
  ScrollbarProps
} from "react-custom-scrollbars";

import cn from "classnames";

import s from "./niceScrollbar.module.scss";

interface Props {
  hideVertical?: boolean;
  hideHorizontal?: boolean;
  handleScroll?: (values: positionValues) => void;
  verticalScrollPosition?: number;
  classNames?: {
    horizontalTrack?: string;
    verticalTrack?: string;
    horizontalThumb?: string;
    verticalThumb?: string;
  };
}

export const NiceScrollbar = ({
  children,
  hideVertical = false,
  hideHorizontal = false,
  handleScroll = () => {},
  verticalScrollPosition = 0,
  classNames = {},
  ...props
}: Props & ScrollbarProps) => {
  return (
    <Scrollbars
      onScrollFrame={handleScroll}
      onUpdate={handleScroll}
      renderThumbVertical={props => (
        <div
          {...props}
          className={cn(s.scrollbarThumbVertical, classNames?.verticalThumb, {
            [s.scrollbarHidden]: hideVertical
          })}
        />
      )}
      renderThumbHorizontal={props => (
        <div
          {...props}
          className={cn(
            s.scrollbarThumbHorizontal,
            classNames?.horizontalThumb,
            {
              [s.scrollbarHidden]: hideHorizontal
            }
          )}
        />
      )}
      renderTrackVertical={props => (
        <div
          {...props}
          className={cn(s.scrollbarTrackVertical, classNames?.verticalTrack, {
            [s.scrollbarHidden]: hideVertical
          })}
          style={
            verticalScrollPosition
              ? {
                  left: verticalScrollPosition,
                  right: "unset"
                }
              : {}
          }
        />
      )}
      renderTrackHorizontal={props => (
        <div
          {...props}
          className={cn(
            s.scrollbarTrackHorizontal,
            classNames?.horizontalTrack,
            {
              [s.scrollbarHidden]: hideHorizontal
            }
          )}
        />
      )}
      {...props}
    >
      {children}
    </Scrollbars>
  );
};
