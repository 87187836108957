import { Nullable, Periods } from "utils/types";

interface Input<P, S> {
  query: string;
  currentPeriod: P;
  ownSummary: S;
  referenceSummary?: Nullable<S>;
  periodType?: Periods;
  company?: string;
  info?: string[];
}

export const createDatasetObject = <P extends Object, S>(
  inputObj: Input<P, S>
) => {
  const {
    query,
    currentPeriod,
    ownSummary,
    referenceSummary,
    periodType,
    company,
    info = []
  } = inputObj;

  return {
    query,
    timestamp: Date.now(),
    isActive: true,
    currentPeriod,
    ownSummary,
    referenceSummary,
    periodType,
    company,
    info
  };
};
