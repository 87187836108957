import { useGetMultipackByPeriod } from "api/multipack/mappers";
import { useGetRanking } from "api/ranking/hooks";

export const useIsMultipackLoading = () => {
  const {
    isLoading: isMultipackLoading,
    isIdle: isMultipackIdle
  } = useGetMultipackByPeriod();
  const { isLoading: rankingLoading } = useGetRanking();

  return isMultipackLoading || isMultipackIdle || rankingLoading;
};
