import qs from "query-string";

import { ApiRequestError } from "classes";
import { HTTP } from "utils";

export const fetchData = async <T extends {}>(
  query: string,
  endpoint: string
) => {
  try {
    const { data } = await HTTP.get<T>(endpoint, {
      params: qs.parse(query)
    });
    return data;
  } catch (err) {
    console.error(err);

    const apiRequestError = err?.response?.data?.non_field_errors?.[0];
    if (apiRequestError) return new ApiRequestError(apiRequestError);

    return err as Error;
  }
};
