export const pathShape = (
  width: number,
  height: number,
  topLeftRadius: number,
  topRightRadius: number,
  bottomRightRadius: number,
  bottomLeftRadius: number
) => {
  return (
    "m 0 " +
    topLeftRadius +
    " A " +
    topLeftRadius +
    " " +
    topLeftRadius +
    " 0 0 1 " +
    topLeftRadius +
    " 0" +
    " L " +
    (width - topRightRadius) +
    " 0" +
    " A " +
    topRightRadius +
    " " +
    topRightRadius +
    " 0 0 1 " +
    width +
    " " +
    topRightRadius +
    " L " +
    width +
    " " +
    (height - bottomRightRadius) +
    " A " +
    bottomRightRadius +
    " " +
    bottomRightRadius +
    " 0 0 1 " +
    (width - bottomRightRadius) +
    " " +
    height +
    " L " +
    bottomLeftRadius +
    " " +
    height +
    " A " +
    bottomLeftRadius +
    " " +
    bottomLeftRadius +
    " 0 0 1 0 " +
    (height - bottomLeftRadius) +
    " Z"
  );
};
