import {
  CHART_DATA_TYPE,
  DISTRIBUTION_CHART_DATA_TYPE,
  isMultipackWsaEnabled,
  WEATHER_CHART_DATA_TYPES
} from "utils/constants";

export const SELL_FIRST_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.VALUE,
  CHART_DATA_TYPE.QUANTITY,
  CHART_DATA_TYPE.VALUE_PER_SHOP,
  CHART_DATA_TYPE.QUANTITY_PER_SHOP,
  CHART_DATA_TYPE.NET_QUANTITY_SALES,
  CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP,
  CHART_DATA_TYPE.NONE
];

export const SELL_SECOND_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.AVG_PRICE,
  CHART_DATA_TYPE.NONE,
  ...DISTRIBUTION_CHART_DATA_TYPE,
  ...WEATHER_CHART_DATA_TYPES
];

export const SELL_COMBINED_CHART_DATA_TYPES = [
  ...SELL_FIRST_CHART_DATA_TYPES,
  ...SELL_SECOND_CHART_DATA_TYPES
];

export const LOCATION_FIRST_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.VALUE,
  CHART_DATA_TYPE.QUANTITY,
  CHART_DATA_TYPE.AVG_PRICE,
  CHART_DATA_TYPE.VALUE_PER_SHOP,
  CHART_DATA_TYPE.QUANTITY_PER_SHOP,
  CHART_DATA_TYPE.NONE
];

export const LOCATION_SECOND_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.NONE,
  ...DISTRIBUTION_CHART_DATA_TYPE,
  ...WEATHER_CHART_DATA_TYPES
];

export const SHARES_FIRST_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.VALUE_SHARES,
  CHART_DATA_TYPE.QUANTITY_SHARES,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE,
  CHART_DATA_TYPE.NONE
];

export const SHARES_SECOND_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.AVG_PRICE,
  CHART_DATA_TYPE.NONE,
  ...DISTRIBUTION_CHART_DATA_TYPE,
  ...WEATHER_CHART_DATA_TYPES
];

export const SHARES_COMBINED_CHART_DATA_TYPES = [
  ...SHARES_FIRST_CHART_DATA_TYPES,
  ...SHARES_SECOND_CHART_DATA_TYPES
];

export const DYNAMICS_FIRST_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.VALUE_DYNAMICS,
  CHART_DATA_TYPE.QUANTITY_DYNAMICS,
  CHART_DATA_TYPE.DYNAMICS_NET_QUANTITY_CHANGE,
  CHART_DATA_TYPE.NONE
];

export const DYNAMICS_SECOND_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.NONE,
  CHART_DATA_TYPE.AVG_PRICE,
  ...DISTRIBUTION_CHART_DATA_TYPE,
  ...WEATHER_CHART_DATA_TYPES
];

export const DYNAMICS_COMBINED_CHART_DATA_TYPES = [
  ...DYNAMICS_FIRST_CHART_DATA_TYPES,
  ...DYNAMICS_SECOND_CHART_DATA_TYPES
];

export const RECEIPTS_FIRST_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.SHARE_IN_RECEIPTS_CATEGORY,
  CHART_DATA_TYPE.SHARE_IN_RECEIPTS_TOTAL_SHOP,
  CHART_DATA_TYPE.ITEMS_PER_RECEIPT,
  CHART_DATA_TYPE.WORTH_PER_RECEIPT,
  CHART_DATA_TYPE.RECEIPTS_PER_SHOP,
  CHART_DATA_TYPE.RECEIPTS_QUANTITY,
  CHART_DATA_TYPE.NET_QUANTITY_PER_RECEIPT,
  CHART_DATA_TYPE.NONE
];

export const RECEIPTS_SECOND_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.AVG_PRICE,
  CHART_DATA_TYPE.NONE,
  ...DISTRIBUTION_CHART_DATA_TYPE,
  ...WEATHER_CHART_DATA_TYPES
];

export const RECEIPTS_COMBINED_CHART_DATA_TYPES = [
  ...RECEIPTS_FIRST_CHART_DATA_TYPES,
  ...RECEIPTS_SECOND_CHART_DATA_TYPES
];

export const SEGMENTS_FIRST_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.VALUE,
  CHART_DATA_TYPE.QUANTITY,
  CHART_DATA_TYPE.VALUE_PER_SHOP,
  CHART_DATA_TYPE.QUANTITY_PER_SHOP,
  CHART_DATA_TYPE.NET_QUANTITY_SALES,
  CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP,
  CHART_DATA_TYPE.NONE
];

export const SEGMENTS_SECOND_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.AVG_PRICE,
  CHART_DATA_TYPE.NONE,
  ...DISTRIBUTION_CHART_DATA_TYPE,
  ...WEATHER_CHART_DATA_TYPES
];

export const SEGMENTS_COMBINED_CHART_DATA_TYPES = [
  ...SEGMENTS_FIRST_CHART_DATA_TYPES,
  ...SEGMENTS_SECOND_CHART_DATA_TYPES
];

export const HOURS_FIRST_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.VALUE_PERCENT,
  CHART_DATA_TYPE.VALUE,
  CHART_DATA_TYPE.QUANTITY_SUM_PERCENT,
  CHART_DATA_TYPE.QUANTITY_SUM
];

export const HOURS_SECOND_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.CALENDAR_DATES,
  CHART_DATA_TYPE.WEEK_DATES,
  CHART_DATA_TYPE.CALENDAR_AND_WEEK_DATES
];

export const LOGISTICS_SUPPLY_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.TOTAL_SUPPLY,
  CHART_DATA_TYPE.SHOPS_SUPPLY,
  CHART_DATA_TYPE.INCOMING_SUPPLY,
  CHART_DATA_TYPE.CL_TOTAL_SUPPLY,
  CHART_DATA_TYPE.BED_SUPPLY,
  CHART_DATA_TYPE.KM_SUPPLY,
  CHART_DATA_TYPE.NAD_SUPPLY,
  CHART_DATA_TYPE.PLE_SUPPLY,
  CHART_DATA_TYPE.RADZ_SUPPLY,
  CHART_DATA_TYPE.SZA_SUPPLY,
  CHART_DATA_TYPE.TYCH_SUPPLY,
  CHART_DATA_TYPE.TYN_SUPPLY
];

export const LOGISTICS_WPZ7_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.WPZ_SHOPS_7,
  CHART_DATA_TYPE.WPZ_CL_TOTAL_7,
  CHART_DATA_TYPE.WPZ_BED_7,
  CHART_DATA_TYPE.WPZ_KM_7,
  CHART_DATA_TYPE.WPZ_NAD_7,
  CHART_DATA_TYPE.WPZ_PLE_7,
  CHART_DATA_TYPE.WPZ_RADZ_7,
  CHART_DATA_TYPE.WPZ_SZA_7,
  CHART_DATA_TYPE.WPZ_TYCH_7,
  CHART_DATA_TYPE.WPZ_TYN_7
];

export const LOGISTICS_WPZ30_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.WPZ_SHOPS_30,
  CHART_DATA_TYPE.WPZ_CL_TOTAL_30,
  CHART_DATA_TYPE.WPZ_BED_30,
  CHART_DATA_TYPE.WPZ_KM_30,
  CHART_DATA_TYPE.WPZ_NAD_30,
  CHART_DATA_TYPE.WPZ_PLE_30,
  CHART_DATA_TYPE.WPZ_RADZ_30,
  CHART_DATA_TYPE.WPZ_SZA_30,
  CHART_DATA_TYPE.WPZ_TYCH_30,
  CHART_DATA_TYPE.WPZ_TYN_30
];

export const LOGISTICS_WPZ90_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.WPZ_SHOPS_90,
  CHART_DATA_TYPE.WPZ_CL_TOTAL_90,
  CHART_DATA_TYPE.WPZ_BED_90,
  CHART_DATA_TYPE.WPZ_KM_90,
  CHART_DATA_TYPE.WPZ_NAD_90,
  CHART_DATA_TYPE.WPZ_PLE_90,
  CHART_DATA_TYPE.WPZ_RADZ_90,
  CHART_DATA_TYPE.WPZ_SZA_90,
  CHART_DATA_TYPE.WPZ_TYCH_90,
  CHART_DATA_TYPE.WPZ_TYN_90
];

export const LOGISTICS_SHOPS_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.AVG_SHOPS_COUNT,
  CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT
];

export const LOGISTICS_FIRST_CHART_DATA_TYPES = [
  ...LOGISTICS_SUPPLY_CHART_DATA_TYPES,
  CHART_DATA_TYPE.NONE
];

const LOGISTICS_SECOND_CHART_DATA_TYPES = [
  ...LOGISTICS_WPZ7_CHART_DATA_TYPES,
  ...LOGISTICS_SHOPS_CHART_DATA_TYPES,
  CHART_DATA_TYPE.NONE
];

export const LOGISTICS_COMBINED_CHART_DATA_TYPES = [
  ...LOGISTICS_FIRST_CHART_DATA_TYPES,
  ...LOGISTICS_SECOND_CHART_DATA_TYPES,
  ...LOGISTICS_WPZ30_CHART_DATA_TYPES,
  ...LOGISTICS_WPZ90_CHART_DATA_TYPES
];

export const LOYALTY_ABSOLUTE_DATA_TYPES = [
  CHART_DATA_TYPE.NEW_CUSTOMERS_COUNT,
  CHART_DATA_TYPE.ALL_RETURNS_COUNT,
  CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE,
  CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE,
  CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE,
  CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE,
  CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE,
  CHART_DATA_TYPE.NONE
];

export const LOYALTY_PERCENT_DATA_TYPES = [
  CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT,
  CHART_DATA_TYPE.ALL_RETURNS_PERCENT,
  CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT,
  CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT,
  CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT,
  CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT,
  CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT
];

export const LOYALTY_OTHER_CHART_DATA_TYPES = [
  CHART_DATA_TYPE.AVG_PRICE,
  ...DISTRIBUTION_CHART_DATA_TYPE,
  ...WEATHER_CHART_DATA_TYPES,
  CHART_DATA_TYPE.NONE
];

export const LOYALTY_ALL_CHART_DATA_TYPES = [
  ...LOYALTY_ABSOLUTE_DATA_TYPES,
  ...LOYALTY_PERCENT_DATA_TYPES,
  ...LOYALTY_OTHER_CHART_DATA_TYPES
];

const MULTIPACK_WS_WSO_WSA_DATA_TYPES = [
  CHART_DATA_TYPE.VALUE_WS_WSO_WSA,
  CHART_DATA_TYPE.QUANTITY_WS_WSO_WSA,
  CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_WSA,
  CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_WSA,
  CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_WSA,
  CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA,
  CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA,
  CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA,
  CHART_DATA_TYPE.PRICE_AVG_WS_WSO_WSA
];

const MULTIPACK_WS_WSO_DATA_TYPES = [
  CHART_DATA_TYPE.VALUE_WS_WSO,
  CHART_DATA_TYPE.QUANTITY_WS_WSO,
  CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO,
  CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO,
  CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO,
  CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO,
  CHART_DATA_TYPE.VALUE_SHARES_WS_WSO,
  CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO,
  CHART_DATA_TYPE.PRICE_AVG_WS_WSO
];

const MULTIPACK_WS_DATA_TYPES = [
  CHART_DATA_TYPE.VALUE_WS,
  CHART_DATA_TYPE.QUANTITY_WS,
  CHART_DATA_TYPE.VALUE_PER_SHOP_WS,
  CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS,
  CHART_DATA_TYPE.NET_QUANTITY_SUM_WS,
  CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS,
  CHART_DATA_TYPE.VALUE_SHARES_WS,
  CHART_DATA_TYPE.QUANTITY_SHARES_WS,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS,
  CHART_DATA_TYPE.PRICE_AVG_WS
];

const MULTIPACK_WSO_DATA_TYPES = [
  CHART_DATA_TYPE.VALUE_WSO,
  CHART_DATA_TYPE.QUANTITY_WSO,
  CHART_DATA_TYPE.VALUE_PER_SHOP_WSO,
  CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSO,
  CHART_DATA_TYPE.NET_QUANTITY_SUM_WSO,
  CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSO,
  CHART_DATA_TYPE.VALUE_SHARES_WSO,
  CHART_DATA_TYPE.QUANTITY_SHARES_WSO,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO,
  CHART_DATA_TYPE.PRICE_AVG_WSO
];

const MULTIPACK_WSA_DATA_TYPES = [
  CHART_DATA_TYPE.VALUE_WSA,
  CHART_DATA_TYPE.QUANTITY_WSA,
  CHART_DATA_TYPE.VALUE_PER_SHOP_WSA,
  CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSA,
  CHART_DATA_TYPE.NET_QUANTITY_SUM_WSA,
  CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSA,
  CHART_DATA_TYPE.VALUE_SHARES_WSA,
  CHART_DATA_TYPE.QUANTITY_SHARES_WSA,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA,
  CHART_DATA_TYPE.PRICE_AVG_WSA
];

const MULTIPACK_SALES_DATA_TYPES = [
  CHART_DATA_TYPE.VALUE,
  CHART_DATA_TYPE.QUANTITY,
  CHART_DATA_TYPE.VALUE_PER_SHOP,
  CHART_DATA_TYPE.QUANTITY_PER_SHOP,
  CHART_DATA_TYPE.AVG_PRICE,
  CHART_DATA_TYPE.NET_QUANTITY_SALES,
  CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP
];

export const MULTIPACK_CHART_DATA_TYPES = [
  ...(isMultipackWsaEnabled
    ? MULTIPACK_WS_WSO_WSA_DATA_TYPES
    : MULTIPACK_WS_WSO_DATA_TYPES),
  ...MULTIPACK_WS_DATA_TYPES,
  ...MULTIPACK_WSO_DATA_TYPES,
  ...(isMultipackWsaEnabled ? MULTIPACK_WSA_DATA_TYPES : []),
  ...MULTIPACK_SALES_DATA_TYPES,
  CHART_DATA_TYPE.NONE
];
