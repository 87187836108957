import { values } from "ramda";

import { CHART_COLORS, CHART_DATA_TYPE, WEATHER_CHART_COLOR } from "utils";

const RED_BULL_VENDOR_ID = "RED BULL SP. Z O.O.";

export const RED_BULL_PRODUCT_COLORS = {
  redBullColor1: "#00B0F0",
  redBullColor2: "#91AFEE",
  redBullColor3: "#8D1E34",
  redBullColor4: "#E25C18",
  redBullColor5: "#92D050",
  redBullColor6: "#C2D3F6",
  redBullColor7: "#102C68",
  redBullColor8: "#5A5A5A",
  redBullColor9: "#487AE3",
  redBullColor10: "#D9D9D9",
  redBullColor11: "#8C8C8C",
  redBullColor12: "#ED1847",
  redBullColor13: "#FFC423",
  redBullColor14: "#1947A3",
  redBullColor15: "#000000",
  redBullColor16: "#00B050",
  redBullColor17: "#18635F",
  redBullColor18: "#F5963E",
  redBullColor19: "#7030A0",
  redBullColor20: "#E43A70"
};

const RED_BULL_COLORS = {
  "NAPÓJ ENERG RED BULL SUMMER EDIT 250ML":
    RED_BULL_PRODUCT_COLORS.redBullColor1,
  "NAPÓJ ENERG RED BULL SUGAR FREE 355ML":
    RED_BULL_PRODUCT_COLORS.redBullColor2,
  "! NAPÓJ ENERGET.RED BULL GRANAT 250ML":
    RED_BULL_PRODUCT_COLORS.redBullColor3,
  "! NAPÓJ RED BULL MANDARYNKA 250 ML": RED_BULL_PRODUCT_COLORS.redBullColor4,
  "! NAPÓJ RED BULL KIWI 250ML": RED_BULL_PRODUCT_COLORS.redBullColor5,
  "! NAPÓJ ENERGETYCZNY RED BULL 250ML BU":
    RED_BULL_PRODUCT_COLORS.redBullColor6,
  "NAPÓJ ENERG RED BULL 355ML": RED_BULL_PRODUCT_COLORS.redBullColor7,
  "! NAPÓJ ENERGET RED BULL 2X250ML PUSZ":
    RED_BULL_PRODUCT_COLORS.redBullColor8,
  "NAPÓJ ENERG RED BULL SUGAR FREE 250ML":
    RED_BULL_PRODUCT_COLORS.redBullColor9,
  "! NAPÓJ RED BULL KOKOS-JAGODA ACAI 2":
    RED_BULL_PRODUCT_COLORS.redBullColor10,
  "NAPÓJ ENERG RED BULL ZERO 250ML": RED_BULL_PRODUCT_COLORS.redBullColor11,
  "! NAPÓJ RED BULL RED EDITION 250ML": RED_BULL_PRODUCT_COLORS.redBullColor12,
  "! NAPÓJ RED BULL TROPICAL EDITION 250M":
    RED_BULL_PRODUCT_COLORS.redBullColor13,
  "NAPÓJ ENERG RED BULL 250 ML": RED_BULL_PRODUCT_COLORS.redBullColor14,
  "NAPÓJ ENERG RED BULL 473ML": RED_BULL_PRODUCT_COLORS.redBullColor15,
  "NAPÓJ ENERG RED BULL GREEN EDIT 250ML":
    RED_BULL_PRODUCT_COLORS.redBullColor16,
  "NAPÓJ ENERG RED BULL WINTER EDIT 250ML":
    RED_BULL_PRODUCT_COLORS.redBullColor17,
  "NAPÓJ ENERG RED BULL APRICOT 250ML": RED_BULL_PRODUCT_COLORS.redBullColor18,
  "NAPÓJ ENERG RED BULL PURP SUG FREE 250ML":
    RED_BULL_PRODUCT_COLORS.redBullColor19,
  "NAPÓJ ENERG RED BULL PEAR CINNAMON 250ML":
    RED_BULL_PRODUCT_COLORS.redBullColor20
} as { [key: string]: string };

export const getLegendItemColor = ({
  vendorId,
  resultId,
  index
}: {
  vendorId: string;
  resultId: string;
  index: number;
}) => {
  if (vendorId === RED_BULL_VENDOR_ID) {
    const color = RED_BULL_COLORS[resultId];
    if (color) return color;
  }

  return values(CHART_COLORS)[index % 10];
};

export const getChartItemColor = ({
  dataType,
  vendorId,
  resultId,
  index
}: {
  dataType: string;
  vendorId: string;
  resultId: string;
  index: number;
}) => {
  if (
    dataType === CHART_DATA_TYPE.AVG_RAINFALL ||
    dataType === CHART_DATA_TYPE.AVG_TEMP
  ) {
    return WEATHER_CHART_COLOR;
  }

  return getLegendItemColor({ vendorId, resultId, index });
};

export const addColor = <T extends { vendorId: string; resultId: string }>(
  data: T[],
  dataType: string
) =>
  data.map((item, index) => ({
    ...item,
    color: getChartItemColor({
      dataType,
      vendorId: item.vendorId,
      resultId: item.resultId,
      index
    })
  }));
