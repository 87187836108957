import { DataTimeline } from "pages/Reports/redux/utils/sortDataByVendorAndLabel";
import { ZAPPKA_SHARES_SUFFIX } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import {
  formatNumber,
  formatTooltipValue,
  valueByDataType
} from "pages/Reports/utils";
import { NUMBER_TYPE } from "pages/Reports/utils/formatNumber";

export const formatSidebarValue = (
  baseKey: string,
  dataKey: string,
  timeline: DataTimeline
) => {
  const value = valueByDataType(dataKey, timeline);
  return dataKey.includes(ZAPPKA_SHARES_SUFFIX)
    ? formatNumber(value, NUMBER_TYPE.PERCENT)
    : formatTooltipValue(value, baseKey);
};
