import { useQueries } from "react-query";
import { useLocation } from "react-router-dom";

import { QueryKey } from "api/consts";
import { sellsServices } from "api/sells/services/sells.services";
import { useFetchParams } from "api/utils";

import { REPORTS_PATHS } from "pages/Reports/utils";
import { isThisPage } from "utils";

export const useGetSells = () => {
  const { queryKeys, isG1Selected } = useFetchParams();

  const { pathname } = useLocation();
  const isSellsPage = isThisPage(pathname, REPORTS_PATHS.SELL);

  const queries = useQueries(
    queryKeys.map(({ query, period }) => {
      return {
        queryKey: [QueryKey.SELLS_DATA, query],
        queryFn: () => sellsServices.fetchSells(query, period),
        enabled: isG1Selected && isSellsPage,
        retry: false
      };
    })
  );

  const isLoading = queries.some(query => query.isLoading);
  const isError = queries.some(query => query.isError);
  const isIdle = queries.some(query => query.isIdle);

  const returnValue = {
    isLoading,
    isError,
    isIdle,
    queriesData: queries.map(query => query.data)
  };

  return returnValue;
};
