import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const Reload: React.FC<Props> = ({
  className,
  color,
  size = ICON_SIZES.NORMAL,
  testId
}) => {
  const iconSize = SIZES[size];
  return (
    <svg
      className={className}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 20 20"
      version="1.1"
      data-testid={testId}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={color || "currentColor"} fillRule="nonzero">
          <path d="M2.0016,9.8664 C1.4208,9.1304 1.032,8.2536 0.8776,7.332 L2.456,7.0672 C2.5664,7.7248 2.8432,8.3504 3.2584,8.8752 L2.0016,9.8664 Z" />
          <path d="M5.4672,11.9224 C4.6112,11.7784 3.7872,11.4304 3.0864,10.9152 L4.0352,9.6264 C4.5344,9.9936 5.1216,10.2424 5.7328,10.3448 L5.4672,11.9224 Z" />
          <path d="M7.332,11.9224 L7.068,10.344 C7.7072,10.2368 8.3184,9.9704 8.8352,9.5728 L9.8104,10.8416 C9.0864,11.3984 8.2296,11.772 7.332,11.9224 Z" />
          <path d="M6.4,0.8 C4.9016,0.8 3.48,1.4072 2.4416,2.4416 L0.72,0.72 L0.16,5.84 L5.28,5.28 L3.572,3.572 C4.3144,2.8336 5.3296,2.4 6.4,2.4 C8.6056,2.4 10.4,4.1944 10.4,6.4 C10.4,7.248 10.1384,8.0576 9.6424,8.7424 L10.9392,9.6808 C11.6328,8.7208 12,7.5864 12,6.4 C12,3.312 9.4872,0.8 6.4,0.8 Z" />
        </g>
      </g>
    </svg>
  );
};
