import { SyntheticEvent } from "react";

import cn from "classnames";

import { TooltipIcon } from "components/atoms/Icon";
import { TEXT_WEIGHTS } from "utils";

import { Text } from "../../atoms";
import s from "./radioButton.module.scss";

interface Props {
  isChecked?: boolean;
  onClick?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  label?: string;
  tooltipText?: string;
  isDisabled?: boolean;
  className?: {
    base?: string;
    label?: string;
    tick?: string;
    checkbox?: string;
    iconWrapper?: string;
  };
  testId?: string;
  iconTooltipText?: string;
}

export const RadioButton = ({
  isChecked,
  onClick,
  onChange,
  label,
  tooltipText,
  isDisabled,
  className = {},
  testId = `radio-button-${label}`,
  iconTooltipText
}: Props) => (
  <label
    className={cn(s.base, { [s.disabled]: isDisabled }, className.base)}
    data-testid={testId}
  >
    <div
      className={cn(s.tooltipWrapper, { [s.tooltip]: tooltipText })}
      data-tooltip={tooltipText}
    >
      <div
        className={cn(
          s.radio,
          { [s.checked]: isChecked, [s.disabled]: isDisabled },
          className.checkbox
        )}
      >
        <input
          type="radio"
          checked={isChecked}
          onClick={onClick}
          onChange={onChange}
          readOnly={!onChange}
          disabled={isDisabled}
        />
        <div className={cn(s.tick, className.tick)} />
      </div>
      {label && (
        <Text
          className={cn(s.label, { [s.checked]: isChecked }, className.label)}
          weight={isChecked ? TEXT_WEIGHTS.BOLD : TEXT_WEIGHTS.NORMAL}
        >
          <span className={s.boldText} data-text={label}>
            {label}
          </span>
        </Text>
      )}
    </div>
    {iconTooltipText && (
      <div className={s.iconTooltipWrapper} data-tooltip={iconTooltipText}>
        <TooltipIcon className={s.tooltipIcon} />
      </div>
    )}
  </label>
);
