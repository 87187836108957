import { useSelector } from "react-redux";

import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage } from "utils";
const {
  DYNAMICS_PATH,
  LOCATION_PATH,
  RECEIPTS_PATH,
  SELL_PATH,
  SHARES_PATH,
  MULTIPACK_PATH,
  HOURS_PATH,
  LOYALTY_PATH
} = REPORTS_FULL_PATHS;

export const useMinSummaryHeight = () => {
  const pathname = useSelector(pathnameSelector);

  return isThisPage(pathname, SELL_PATH)
    ? 336
    : isThisPage(pathname, MULTIPACK_PATH)
    ? 324
    : isThisPage(pathname, LOCATION_PATH)
    ? 291
    : isThisPage(pathname, SHARES_PATH)
    ? 326
    : isThisPage(pathname, DYNAMICS_PATH)
    ? 338
    : isThisPage(pathname, RECEIPTS_PATH)
    ? 340
    : isThisPage(pathname, HOURS_PATH)
    ? 259
    : isThisPage(pathname, LOYALTY_PATH)
    ? 326
    : 0;
};
