import { useSelector } from "react-redux";

import { useGetLocationByPeriod } from "api/location/mappers/useGetLocationByPeriod";
import { useLocationMapChartScale } from "api/location/mappers/useLocationMapChartScale";
import cn from "classnames";

import { Text } from "components/atoms";
import { formatNumber } from "pages/Reports/utils/formatNumber";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { CHART_DATA_TYPE, CURRENCY } from "utils/constants";

import s from "./mapChartLegend.module.scss";

const {
  AVG_SHOPS_COUNT,
  DISTRIBUTION_RANGE,
  NONE,
  VALUE,
  QUANTITY,
  QUANTITY_PER_SHOP
} = CHART_DATA_TYPE;

export const MapChartLegend = () => {
  const firstDataType = useSelector(firstChartDataTypeSelector);
  const secondDataType = useSelector(secondChartDataTypeSelector);
  const { range, domain } = useLocationMapChartScale();
  const {
    isLoading: isLocationLoading,
    isIdle: isLocationIdle
  } = useGetLocationByPeriod();
  const isLoading = isLocationLoading || isLocationIdle;

  const dataType = firstDataType !== NONE ? firstDataType : secondDataType;

  const getUnit = (): string => {
    switch (dataType) {
      case QUANTITY:
      case QUANTITY_PER_SHOP:
      case AVG_SHOPS_COUNT:
        return "";
      case DISTRIBUTION_RANGE:
        return "%";
      default:
        return ` ${CURRENCY}`;
    }
  };

  const getDescriptionFormat = (): string => {
    switch (dataType) {
      case QUANTITY:
      case VALUE:
      case DISTRIBUTION_RANGE:
        return ",.0d";
      default:
        return ".2f";
    }
  };

  if (isLoading) {
    return null;
  }

  const unit = getUnit();
  const descriptionFormat = getDescriptionFormat();

  return (
    <div className={s.base} data-testid="map-chart-legend-container">
      <div className={cn(s.column, s.scaleColumn)}>
        {range.map(color => (
          <div
            key={color}
            className={s.item}
            style={{ backgroundColor: color }}
          />
        ))}
      </div>

      <div className={cn(s.column, s.descriptionColumn)}>
        {domain.length ? (
          domain.map((threshold, i) => {
            const max = formatNumber(threshold, descriptionFormat);

            const min =
              i === 0 ? "0" : formatNumber(domain[i - 1], descriptionFormat);

            return (
              <div className={s.textWrapper} key={threshold}>
                <Text
                  className={cn(s.text, {
                    [s.bold]: i === domain.length - 1
                  })}
                >
                  {min} - {`${max}${unit}`}
                </Text>
              </div>
            );
          })
        ) : (
          <div className={s.item}>
            <Text className={s.text}>0</Text>
          </div>
        )}
      </div>
    </div>
  );
};
