import { useSelector } from "react-redux";

import { useGetSegmentsByPeriod } from "api/segments/mappers/useGetSegmentsByPeriod";

import { isGroupedBySegmentsSelector } from "store/selectors/routerSelectors";

export const useVisibleSegments = () => {
  const isGroupedBySegments = useSelector(isGroupedBySegmentsSelector);
  const { data } = useGetSegmentsByPeriod();

  if (!data?.currentPeriod.length) return [];

  if (isGroupedBySegments) {
    return data.currentPeriod.map(segment => ({
      shortLabel: segment.name,
      longLabel: segment.vendorName
    }));
  }

  const segments = data.currentPeriod[0].segments;
  if (!segments.length) return [];

  return segments.map(segment => ({
    shortLabel: segment.shortLabel,
    longLabel: segment.label
  }));
};
