import { useSelector } from "react-redux";

import { Checkbox } from "components/molecules";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage } from "utils";

import s from "./curtainCheckbox.module.scss";
import { useCurtainState } from "./state";

export const useCurtainEnabled = (isBarChart: boolean) => {
  const isPowerUser = useSelector(isPowerUserSelector);
  const pathname = useSelector(pathnameSelector);
  const { isLineChart } = useChartTypeBoolean();

  return (
    !isBarChart &&
    isPowerUser &&
    isThisPage(pathname, REPORTS_FULL_PATHS.SELL_PATH) &&
    isLineChart
  );
};

export const CurtainCheckbox = () => {
  const [{ visible }, { updateVisible }] = useCurtainState();

  return (
    <Checkbox
      className={{ base: s.base, checkbox: s.checkbox, label: s.label }}
      label="Ukryj wykres"
      isChecked={visible}
      onChange={() => updateVisible(!visible)}
    />
  );
};
