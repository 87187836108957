import React from "react";
import { useSelector } from "react-redux";

import { isPowerUserSelector, tierSelector } from "store/reducers/userReducer";

type Props = {
  accessLevels: number[];
  children: React.ReactElement;
};

export const TierValidator = ({ accessLevels, children }: Props) => {
  const tier = useSelector(tierSelector);
  const isPowerUser = useSelector(isPowerUserSelector);

  if (!accessLevels.includes(tier) && !isPowerUser) return null;

  return children;
};
