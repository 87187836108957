import * as React from "react";

import cn from "classnames";

import s from "./logo.module.scss";
import { IconProps as Props } from "./types";

export const Logo = ({ className }: Props) => {
  return (
    <svg
      className={cn(s.base, className)}
      viewBox="0 0 80 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path id="a" d="M0 0h80v31.264H0z" />
      </defs>
      <g fill="none">
        <path
          d="M31.08 31.264a28.852 28.852 0 0017.41-5.826l-.678-3.246c-4.773 2.972-10.534 4.714-16.732 4.714-6.2 0-11.96-1.742-16.734-4.716l-.679 3.246a28.853 28.853 0 0017.413 5.828zm23.949-20.23c3.417 0 4.279-2.414 4.279-5.478h4.063c.092 3.157-.616 5.788-3.54 7.336l4.248 7.119v.433h-4.34l-3.54-6.19a3.81 3.81 0 01-1.232.185h-.985v6.005h-4.279V.5h4.28v10.535h1.046zm20.785-1.688c-1.01-.455-1.94-.633-2.801-.602-2 .093-3.54 1.145-3.54 4.488 0 3.034 1.57 4.024 3.755 4.055.8.03 1.663-.124 2.586-.372V9.346zM80 19.634c-1.818.272-3.425 1.121-6.28 1.15-4.524.047-8.557-1.733-8.557-7.552 0-5.169 2.986-7.986 7.111-7.986C76.508 5.246 80 6.953 80 6.953v12.68zM25.069 9.346c-1.01-.455-1.94-.633-2.802-.602-2 .093-3.54 1.145-3.54 4.488 0 3.034 1.57 4.024 3.756 4.055.8.03 1.662-.124 2.586-.372V9.346zm4.186 10.288c-1.818.272-3.425 1.121-6.28 1.15-4.524.047-8.557-1.733-8.557-7.552 0-5.169 2.986-7.986 7.11-7.986 4.234 0 7.727 1.707 7.727 1.707v12.68zm7.288-2.719c.923.248 1.785.403 2.586.372 2.185-.031 3.755-1.021 3.755-4.055 0-3.343-1.54-4.395-3.54-4.488-.862-.03-1.816.217-2.801.773v7.398zM32.357.531h4.278v5.427c1.2-.495 2.37-.712 3.447-.712 4.125-.03 7.111 2.817 7.111 7.986 0 5.82-4.032 7.505-8.557 7.552-2.543.027-4.174-.847-6.28-1.176V.531zM4.437 2.169C4.437.85 5.457 0 6.711 0c1.311 0 2.331.85 2.331 2.17 0 1.289-1.02 2.11-2.331 2.11-1.254 0-2.274-.821-2.274-2.11zm8.245 3.882L6.218 16.73h6.464v3.745H0v-.496L6.557 9.146H.462v-3.59h12.22v.495z"
          fill="#00682B"
          mask="url(#b)"
        />
      </g>
    </svg>
  );
};
