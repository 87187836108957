import React, { useRef } from "react";

import cn from "classnames";
import { motion } from "framer-motion";

import { Button, Text, Toggle } from "components/atoms";
import { ChevronDown } from "components/atoms/Icon";

import s from "./accordion.module.scss";

const DEFAULT_DURATION = 0.2;

const chevronVariants = {
  open: { rotate: 180 },
  closed: { rotate: 0 },
  tap: { scale: 0.9 },
  hover: { scale: 1.3 },
  rest: { scale: 1 }
};

const contentVariants = {
  open: {
    opacity: 1,
    height: "auto",
    transition: { duration: DEFAULT_DURATION }
  },
  closed: {
    opacity: 0,
    height: 0,
    overflow: "hidden",
    transition: { duration: DEFAULT_DURATION }
  }
};

type Props = {
  children: React.ReactNode;
  sectionKey: string;
  refToScroll?: React.RefObject<HTMLElement>;
  headerText: string;
  isInitiallyOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  className?: {
    headerTextOpen?: string;
    headerText?: string;
    sectionContent?: string;
  };
  initialAnimate?: boolean;
};

export const Accordion = ({
  children,
  sectionKey,
  refToScroll,
  headerText,
  isInitiallyOpen = true,
  onToggle = () => {},
  className = {},
  initialAnimate = true
}: Props) => {
  const childRef = useRef<HTMLDivElement>(null);

  return (
    <Toggle isInitiallyOpen={isInitiallyOpen}>
      {({ isOpen, setOpen }) => {
        const handleToggle = () => {
          setOpen(!isOpen);
          onToggle(isOpen);

          setTimeout(() => {
            if (!isOpen && refToScroll?.current) {
              refToScroll.current.scrollIntoView({
                behavior: "smooth"
              });
            }
          }, DEFAULT_DURATION * 1000);
        };

        return (
          <motion.div
            whileHover="hover"
            whileTap="tap"
            animate={isOpen ? "open" : "closed"}
          >
            <motion.div
              //this motion wrapper is responsible for initial
              //fade-in after entering page
              initial={initialAnimate ? { opacity: 0 } : false}
              animate={{ opacity: 1 }}
              className={s.filterSectionWrapper}
            >
              {headerText && (
                <Button
                  onClick={handleToggle}
                  className={s.sectionButton}
                  type="button"
                  data-testid={`${sectionKey}-toggler`}
                >
                  <Text
                    className={cn(s.sectionHeaderText, className.headerText, {
                      [className.headerTextOpen || ""]: isOpen
                    })}
                  >
                    {headerText}
                  </Text>
                  <motion.div
                    initial={initialAnimate}
                    variants={chevronVariants}
                  >
                    <ChevronDown className={s.chevronDown} />
                  </motion.div>
                </Button>
              )}
              <motion.div
                variants={contentVariants}
                initial={initialAnimate ? { height: 0 } : false} //initial height for render
              >
                <div
                  ref={childRef}
                  className={cn(s.sectionContent, className.sectionContent)}
                >
                  {children}
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        );
      }}
    </Toggle>
  );
};
