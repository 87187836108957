import { useSelector } from "react-redux";

import cn from "classnames";

import { hoveredLineSelector } from "pages/Reports/redux/reducers/chartReducer";
import { getPolishLabelForTooltip, NO_DATA_SHORT } from "utils";
import { round } from "utils/round";
import { Nullable } from "utils/types";

import s from "./barChartTable.module.scss";
import { Dataset } from "./hooks/types";

const getClassName = ({
  value,
  legendId,
  hoveredLines
}: {
  value: Nullable<number>;
  legendId: string;
  hoveredLines: string[];
}) => {
  const classNames = [];
  if (hoveredLines.length) {
    classNames.push(hoveredLines.includes(legendId) ? s.active : s.inactive);
  }
  if (value) {
    classNames.push(value > 0 ? s.positive : s.negative);
  }
  return cn(classNames);
};

const formatValue = ({
  value,
  suffix
}: {
  value: Nullable<number>;
  suffix: string;
}) => {
  if (value === null) return NO_DATA_SHORT;
  return `${round(value)}${suffix}`;
};

export const BarChartTable = ({
  dataType,
  isPercentageType,
  dataset
}: {
  dataType: string;
  isPercentageType: boolean;
  dataset: Dataset[];
}) => {
  const hoveredLines = useSelector(hoveredLineSelector);

  if (!dataType || !dataset.length) {
    return null;
  }

  return (
    <table className={s.table}>
      <tbody>
        <tr>
          <td>{getPolishLabelForTooltip(dataType)}</td>
          {dataset.map((data, index) => (
            <td
              key={`base-value-${index}`}
              className={getClassName({
                value: null,
                legendId: data.legendId,
                hoveredLines
              })}
            >
              {formatValue({
                value: data.baseValue,
                suffix: isPercentageType ? "%" : ""
              })}
            </td>
          ))}
        </tr>
        <tr>
          <td>Zmiana</td>
          {dataset.map((data, index) => {
            return (
              <td
                key={`change-value-${index}`}
                className={getClassName({
                  value: data.changeValue,
                  legendId: data.legendId,
                  hoveredLines
                })}
              >
                {formatValue({
                  value: data.changeValue,
                  suffix: isPercentageType ? " p.p." : "%"
                })}
              </td>
            );
          })}
        </tr>
        <tr>
          <td>Dane hist.</td>
          {dataset.map((data, index) => (
            <td
              key={`historical-value-${index}`}
              className={getClassName({
                value: null,
                legendId: data.legendId,
                hoveredLines
              })}
            >
              {formatValue({
                value: data.historicalValue,
                suffix: isPercentageType ? "%" : ""
              })}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};
