import { CallHistoryMethodAction, push } from "connected-react-router";
import { differenceInMinutes } from "date-fns";
import { createSelector } from "reselect";

import { AppState } from "store";
import { RESET_DATA, resetData } from "store/actions/appActions";
import { updateQueryParams } from "store/actions/routerActions";
import {
  ClearUserAction,
  getUser,
  isLoggedInSelector,
  isPowerUserSelector,
  UserApi
} from "store/reducers/userReducer";
import { pathSelector } from "store/selectors/routerSelectors";
import { APP_PATHS, HTTP, setLoginRedirectLocalStorage } from "utils";
import { PROMPT_KEYS, REST_API_ENDPOINTS, USER_DATA } from "utils";
import { QP } from "utils/defaultQueryParams";
import { Thunk, Values } from "utils/types";

// TYPES
type Credentials = {
  state: string;
  code: string;
};

type LoginState = {
  userActivityTimestamp: number;
  ADLoginURL: string;
  isLoading: boolean;
  isLoginError: boolean;
  isPreloginError: boolean;
};

type UpdateUserActivityTimestampAction = {
  type: typeof UPDATE_USER_ACTIVITY_TIMESTAMP;
  payload: number;
};

type UpdateADLoginURLAction = {
  type: typeof UPDATE_AD_LOGIN_URL;
  payload: string;
};

type UpdateIsLoadingAction = {
  type: typeof UPDATE_IS_LOADING;
  payload: boolean;
};

type UpdateIsLoginErrorAction = {
  type: typeof UPDATE_IS_LOGIN_ERROR;
  payload: boolean;
};

type UpdateIsPreloginErrorAction = {
  type: typeof UPDATE_IS_PRELOGIN_ERROR;
  payload: boolean;
};

type LoginActions = {
  UPDATE_USER_ACTIVITY_TIMESTAMP: UpdateUserActivityTimestampAction;
  UpdateADLoginURLAction: UpdateADLoginURLAction;
  UpdateIsLoadingAction: UpdateIsLoadingAction;
  UpdateIsErrorAction: UpdateIsLoginErrorAction;
  UpdateIsPreloginErrorAction: UpdateIsPreloginErrorAction;
};

// CONST
const UPDATE_USER_ACTIVITY_TIMESTAMP = "Login.UPDATE_USER_ACTIVITY_TIMESTAMP" as const;
const UPDATE_AD_LOGIN_URL = "Login.UPDATE_AD_LOGIN_URL" as const;
const UPDATE_IS_LOADING = "Login.UPDATE_IS_LOADING" as const;
const UPDATE_IS_LOGIN_ERROR = "Login.UPDATE_IS_LOGIN_ERROR" as const;
const UPDATE_IS_PRELOGIN_ERROR = "Login.UPDATE_IS_PRELOGIN_ERROR" as const;
const REGULAR_USER_ACTIVITY_TIMEOUT_MINUTES = 15;
const SUPER_USER_ACTIVITY_TIMEOUT_MINUTES = 120;

// SELECTORS
const loginSelector = (store: AppState) => store.login || initialState;

const userActivityTimestampSelector = createSelector<
  AppState,
  LoginState,
  number
>(loginSelector, ({ userActivityTimestamp }) => userActivityTimestamp);

export const isLoginErrorSelector = createSelector(
  loginSelector,
  ({ isLoginError }) => isLoginError
);

export const isPreloginErrorSelector = createSelector(
  loginSelector,
  ({ isPreloginError }) => isPreloginError
);

export const ADLoginURLSelector = createSelector(
  loginSelector,
  ({ ADLoginURL }) => ADLoginURL
);

export const isLoginLoadingSelector = createSelector(
  loginSelector,
  ({ isLoading }) => isLoading
);

// ACTIONS
export const updateUserActivityTimestamp = (
  payload: number
): UpdateUserActivityTimestampAction => ({
  type: UPDATE_USER_ACTIVITY_TIMESTAMP,
  payload
});

const updateADLoginURL = (payload: string): UpdateADLoginURLAction => ({
  type: UPDATE_AD_LOGIN_URL,
  payload
});

const updateIsLoading = (payload: boolean): UpdateIsLoadingAction => ({
  type: UPDATE_IS_LOADING,
  payload
});

const updateIsLoginError = (payload: boolean): UpdateIsLoginErrorAction => ({
  type: UPDATE_IS_LOGIN_ERROR,
  payload
});

const updateIsPreloginError = (
  payload: boolean
): UpdateIsPreloginErrorAction => ({
  type: UPDATE_IS_PRELOGIN_ERROR,
  payload
});

// THUNKS
export const logoutUser = (): Thunk<
  ClearUserAction | CallHistoryMethodAction | ReturnType<typeof resetData>
> => async (dispatch, getState) => {
  const state = getState();
  const isLoggedIn = isLoggedInSelector(state);
  if (!isLoggedIn) {
    return;
  }

  const routerPath = pathSelector(state);
  setLoginRedirectLocalStorage(routerPath);

  sessionStorage.removeItem(USER_DATA);
  localStorage.removeItem(PROMPT_KEYS.IS_INCONSISTENT_DATA_PROMPT_HIDDEN);
  localStorage.removeItem(PROMPT_KEYS.IS_REMOVE_HISTORICAL_PROMPT_HIDDEN);
  localStorage.removeItem(PROMPT_KEYS.IS_EXPORT_REDIRECT_PROMPT_HIDDEN);

  dispatch(resetData());

  try {
    await HTTP.post(REST_API_ENDPOINTS.USERS.LOGOUT);
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(push(APP_PATHS.LOGOUT));
  }
};

export const loginUser = (
  credentials: Credentials
): Thunk<UpdateIsLoginErrorAction | UpdateIsLoadingAction> => async (
  dispatch,
  getState
) => {
  const state = getState();
  const isLoggedIn = isLoggedInSelector(state);

  if (isLoggedIn) return;

  const isLoading = isLoginLoadingSelector(state);
  if (isLoading) return;

  dispatch(updateIsLoginError(false));
  dispatch(updateIsLoading(true));

  try {
    const response = await HTTP.post<UserApi>(
      REST_API_ENDPOINTS.USERS.LOGIN,
      credentials
    );

    return dispatch(getUser(response.data));
  } catch (err) {
    console.error(err);

    dispatch(updateIsLoginError(true));
  }

  dispatch(updateIsLoading(false));

  dispatch(updateQueryParams({ [QP.CODE]: undefined, [QP.STATE]: undefined }));
};

export const getAdLoginUrl = (): Thunk<
  UpdateADLoginURLAction | UpdateIsLoadingAction | UpdateIsPreloginErrorAction
> => async (dispatch, getState) => {
  const isLoading = isLoginLoadingSelector(getState());
  if (isLoading) return;

  dispatch(updateIsLoading(true));
  dispatch(updateIsPreloginError(false));

  try {
    const response = await HTTP.get<{ url: string }>(
      REST_API_ENDPOINTS.USERS.LOGIN
    );
    dispatch(updateADLoginURL(response.data.url));
  } catch (err) {
    console.error(err);
    dispatch(updateIsPreloginError(true));
  }

  dispatch(updateIsLoading(false));
};

export const checkUserActivity = (): Thunk<ClearUserAction> => (
  dispatch,
  getState
) => {
  const state = getState();
  const isLoggedIn = isLoggedInSelector(state);
  const isPowerUser = isPowerUserSelector(state);

  if (!isLoggedIn) {
    return;
  }

  const userActivityTimestamp = userActivityTimestampSelector(state);

  const userLogoutTime = isPowerUser
    ? SUPER_USER_ACTIVITY_TIMEOUT_MINUTES
    : REGULAR_USER_ACTIVITY_TIMEOUT_MINUTES;

  if (!isUserActive(userActivityTimestamp, userLogoutTime)) {
    dispatch(logoutUser());
  }
};

const isUserActive = (
  userActivityTimestamp: number,
  timeoutMinutes: number
) => {
  return (
    differenceInMinutes(Date.now(), userActivityTimestamp) < timeoutMinutes
  );
};

// REDUCER
const initialState: LoginState = {
  userActivityTimestamp: Date.now(),
  ADLoginURL: "",
  isLoading: false,
  isLoginError: false,
  isPreloginError: false
};

export const loginReducer = (
  state: LoginState = initialState,
  action: Values<LoginActions> | ReturnType<typeof resetData>
): LoginState => {
  switch (action.type) {
    case UPDATE_USER_ACTIVITY_TIMESTAMP:
      return { ...state, userActivityTimestamp: action.payload };
    case UPDATE_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case UPDATE_AD_LOGIN_URL:
      return { ...state, ADLoginURL: action.payload };
    case UPDATE_IS_LOGIN_ERROR:
      return { ...state, isLoginError: action.payload };
    case UPDATE_IS_PRELOGIN_ERROR:
      return { ...state, isPreloginError: action.payload };

    case RESET_DATA:
      return initialState;

    default:
      return state;
  }
};
