import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "components/molecules";
import { ChartDropdownIndex } from "components/molecules/ChartHeader/ChartHeader";
import { IDS } from "components/organisms/Tour";
import { updateQueryParams } from "store/actions/routerActions";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { getPolishLabelForChartHeader } from "utils";
import { QP } from "utils/defaultQueryParams";
import { pushChartSwitchEvent } from "utils/googleTagManager/dataLayer";

import s from "../chartDropdown.module.scss";
import {
  useZappkaSharesCheckboxDisabled,
  useZappkaSharesCheckboxState
} from "./hooks";

type Props = {
  dropdownIndex: ChartDropdownIndex;
};

export const ZappkaSharesCheckbox = ({ dropdownIndex }: Props) => {
  const dispatch = useDispatch();
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const state = useZappkaSharesCheckboxState();
  const disabled = useZappkaSharesCheckboxDisabled();
  const isFirst = dropdownIndex === ChartDropdownIndex.first;
  const isChecked = isFirst ? state.first : state.second;

  const handleChange = () => {
    const param = isFirst ? QP.ZAPPKA_SHARES_FIRST : QP.ZAPPKA_SHARES_SECOND;
    const newChecked = !isChecked;
    dispatch(updateQueryParams({ [param]: String(newChecked) }));

    const dataLayerValue = [
      getPolishLabelForChartHeader(isFirst ? firstChartType : secondChartType),
      "- udział apl.",
      newChecked ? "(włącz)" : "(wyłącz)"
    ].join(" ");
    pushChartSwitchEvent(dataLayerValue);
  };

  return (
    <div id={IDS.SHARES_CHECKBOX} className={s.sharesWrapper}>
      <Checkbox
        label="Pokaż dane jako udział Żappki w totalu"
        isDisabled={isFirst ? disabled.first : disabled.second}
        isChecked={isChecked}
        onChange={handleChange}
      />
    </div>
  );
};
