import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useIsRankingSelected } from "api/ranking/utils/useIsRankingSelected";
import cn from "classnames";
import { format } from "date-fns";

import { Button, Text, Toggle } from "components/atoms";
import { CheckExport } from "components/atoms/CheckExport/CheckExport";
import { Download } from "components/atoms/Icon";
import { TourHooks } from "components/organisms/Tour/hooks/useTours";
import { useExcelExportUrl } from "hooks/useExcelExportUrl";
import { useFiltersQP } from "hooks/useFiltersQP";
import { useGenerateExport } from "pages/Reports/sections/Export/hooks";
import { useLogisticsExportUrl } from "pages/Reports/sections/Logistics/utils/useLogisticsExportUrl";
import {
  EXPORT_TYPES,
  REPORTS_FULL_PATHS
} from "pages/Reports/utils/constants";
import { setSuccessMessage } from "store/actions/appActions";
import { hasFreeTrialSelector } from "store/reducers/userReducer";
import {
  pathnameSelector,
  queryParamsSelector,
  searchStringSelector
} from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { REPORT_TYPES } from "store/utils/constants";
import { DATE_FNS_DOT_FORMAT, ICON_SIZES, isThisPage } from "utils";
import {
  APP_PATHS,
  BOOL_STRING_VALUES,
  LOGISTICS_DATA_TYPES_PARAMS,
  PROMPT_KEYS
} from "utils/constants";
import { QP } from "utils/defaultQueryParams";
import { pushTableEvent } from "utils/googleTagManager/dataLayer";
import { Values } from "utils/types";

import { ExportRedirectModal } from "./ExportRedirectModal";
import s from "./reportDownloadDropdown.module.scss";

interface Props {
  iconClassname?: string;
  withHeader?: boolean;
  wrapperClassName?: string;
  buttonClassName?: string;
  reportFormatsAllowed: Values<typeof EXPORT_TYPES>[];
  type: Values<typeof REPORT_TYPES>;
  ga: {
    [key: string]: string;
  };
  reportDownloadHeader: string;
}

const { IS_EXPORT_REDIRECT_PROMPT_HIDDEN } = PROMPT_KEYS;
const { TRUE } = BOOL_STRING_VALUES;

const useDisabledTooltip = () => {
  const params = useSelector(queryParamsSelector);
  const isRankingSelected = useIsRankingSelected();

  if (isRankingSelected) {
    return "Pobieranie danych niedostępne dla rankingu";
  }

  if (params[QP.ATTRIBUTES]) {
    return "Brak możliwości eksportu danych wg atrybutów";
  }

  return "";
};

export const ReportDownloadDropdown = ({
  withHeader = true,
  wrapperClassName = "",
  buttonClassName = "",
  reportFormatsAllowed,
  type,
  ga,
  reportDownloadHeader
}: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [, actions] = TourHooks.useTours();
  const history = useHistory();
  const dispatch = useDispatch();
  const excelExportUrl = useExcelExportUrl(type);
  const logisticsTableExportUrl = useLogisticsExportUrl(
    LOGISTICS_DATA_TYPES_PARAMS.SINGLE_DAY
  );
  const logisticsChartExportUrl = useLogisticsExportUrl(
    LOGISTICS_DATA_TYPES_PARAMS.HISTORY
  );
  const generateExport = useGenerateExport();
  const search = useSelector(searchStringSelector);
  const hasFreeTrial = useSelector(hasFreeTrialSelector);
  const pathname = useSelector(pathnameSelector);
  const { dateFromQP, dateToQP } = useFiltersQP();
  const disabledTooltip = useDisabledTooltip();

  if (hasFreeTrial) {
    return null;
  }

  const handleGenerateCsvFromOption = () => {
    const onClick = () => {
      history.push(`${APP_PATHS.EXPORT}?${search}`);
      dispatch(setSuccessMessage(""));
    };

    generateExport.mutate();
    dispatch(setSuccessMessage(<CheckExport onClick={onClick} />));
  };

  const handleExcelExportDownload = async () => {
    if (excelExportUrl.isRefetching) return;
    const { data: url, isError } = await excelExportUrl.refetch();
    if (isError || !url) return;
    window.location.assign(url);
  };

  const handleLogisticsExportDownload = async (
    type: Values<typeof LOGISTICS_DATA_TYPES_PARAMS>
  ) => {
    const query =
      type === LOGISTICS_DATA_TYPES_PARAMS.SINGLE_DAY
        ? logisticsTableExportUrl
        : logisticsChartExportUrl;
    if (query.isRefetching) return;
    const { data: url, isError } = await query.refetch();
    if (isError || !url) return;
    window.location.assign(url);
  };

  const dateFrom = format(newDateByTimezone(dateFromQP), DATE_FNS_DOT_FORMAT);
  const dateTo = format(newDateByTimezone(dateToQP), DATE_FNS_DOT_FORMAT);
  const reportTypes = [
    {
      type: EXPORT_TYPES.CSV,
      title: "Rozszerzony eksport danych (CSV)",
      onClick: handleGenerateCsvFromOption
    },
    {
      type: EXPORT_TYPES.EXCEL,
      title: "Dane w formacie Excel (XLSX)",
      onClick: handleExcelExportDownload
    },
    {
      type: EXPORT_TYPES.LOGISTICS_SINGLE_DAY,
      title: `Dane na ${dateTo}`,
      onClick: () => {
        handleLogisticsExportDownload(LOGISTICS_DATA_TYPES_PARAMS.SINGLE_DAY);
      }
    },
    {
      type: EXPORT_TYPES.LOGITICS_SELECTED_PERIOD,
      title: `Dane ${dateFrom} - ${dateTo}`,
      onClick: () => {
        handleLogisticsExportDownload(LOGISTICS_DATA_TYPES_PARAMS.HISTORY);
      }
    }
  ];

  const handleModalClose = () => {
    localStorage.setItem(IS_EXPORT_REDIRECT_PROMPT_HIDDEN, TRUE);
    setModalOpen(false);
  };

  const handleGenerateCsvFromModal = () => {
    handleModalClose();
    history.push(`${APP_PATHS.EXPORT}?${search}`);
    generateExport.mutate();
    actions.setExportRedirectTourHidden(false);
  };

  const handleGenerateExcelFromModal = () => {
    handleModalClose();
    handleExcelExportDownload();
  };

  const handleIconButtonClick = (
    isOpen: boolean,
    setOpen: (value: boolean) => void
  ) => {
    if (disabledTooltip.length > 0) return;

    if (type === REPORT_TYPES.TABLE) {
      pushTableEvent("dane - download");
    }

    const isCustomExport = [
      REPORTS_FULL_PATHS.SEGMENTS_PATH,
      REPORTS_FULL_PATHS.HOURS_PATH,
      REPORTS_FULL_PATHS.LOGISTICS_PATH
    ].some(url => isThisPage(pathname, url));

    const shouldDisplayModal =
      !isCustomExport &&
      localStorage.getItem(IS_EXPORT_REDIRECT_PROMPT_HIDDEN) !== TRUE;

    if (shouldDisplayModal) {
      return setModalOpen(true);
    }

    setOpen(!isOpen);
  };

  return (
    <>
      <Toggle onClickOutside="close">
        {({ isOpen, setOpen }) => (
          <div className={cn(s.wrapper, wrapperClassName)}>
            <button
              className={cn(s.dropdownButton, buttonClassName, {
                [s.dropdownButtonDisabled]: disabledTooltip.length > 0
              })}
              type="button"
              onClick={() => handleIconButtonClick(isOpen, setOpen)}
              data-tooltip={disabledTooltip}
            >
              <Download size={ICON_SIZES.LARGE} className={s.dropdownIcon} />
              {withHeader && "Pobierz raport"}
            </button>
            {isOpen && (
              <ul className={s.dropdownWrapper}>
                <li className={s.dropdownHeader}>{reportDownloadHeader}</li>
                {reportTypes.map(reportType => {
                  if (reportFormatsAllowed.includes(reportType.type)) {
                    return (
                      <li
                        className={s.dropdownElement}
                        key={`report-type-${reportType.type}`}
                        {...ga}
                      >
                        <Button
                          type="button"
                          className={s.downloadLink}
                          onClick={reportType.onClick}
                        >
                          <Text className={s.downloadText}>
                            {reportType.title}
                          </Text>
                        </Button>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            )}
          </div>
        )}
      </Toggle>
      {isModalOpen && (
        <ExportRedirectModal
          onClose={handleModalClose}
          onPrimaryClick={handleGenerateCsvFromModal}
          onSecondaryClick={handleGenerateExcelFromModal}
        />
      )}
    </>
  );
};
