import { uniq } from "ramda";
import { AnyAction } from "redux";
import { createSelector } from "reselect";

import { isRestrictedToOneLineSelector } from "components/molecules/ChartTypeToggle/hooks";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { AppState } from "store";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage } from "utils";
import { Nullable, Thunk, Values } from "utils/types";

type HiddenLines = {
  location: string[];
  segments: string[];
  logistics: string[];
  other: string[];
};

export type Timepoint = Nullable<number>;

type ChartState = {
  hiddenSingleLine: HiddenLines;
  hiddenMultiLines: HiddenLines;
  hoveredLine: string[];
  hoveredLineTypes: string[];
  hiddenCharts: string[];
  pinnedTimepoint: Timepoint;
  pinnedTimepointPosition: Nullable<number>;
  hoveredTimepoint: Nullable<number>;
  hoveredTimepointPosition: Nullable<number>;
};

// this list is dynamic and comes from an API, hence needs to be updated from time to time
const UNKNOWN_SEGMENTS = [
  "NC - CL ŻABKA NANO",
  "NO - OSIEDLE NANO",
  "NT - TRAFFIC NANO",
  "IB - BIURO INDOOR/PIKO",
  "IF - FIT INDOOR/PIKO",
  "IT - TRAFFIC INDOOR/PIKO",
  "IZ - ZDROWA ŻYWNOŚĆ INDOOR/PIKO"
];

export const INITIAL_HIDDEN_LINES: HiddenLines = {
  location: [],
  segments: [],
  logistics: [],
  other: []
};

const { LOCATION_PATH, SEGMENTS_PATH, LOGISTICS_PATH } = REPORTS_FULL_PATHS;

const initialState: ChartState = {
  hiddenSingleLine: INITIAL_HIDDEN_LINES,
  hiddenMultiLines: INITIAL_HIDDEN_LINES,
  hoveredLine: [],
  hoveredLineTypes: [],
  hiddenCharts: UNKNOWN_SEGMENTS,
  pinnedTimepoint: null,
  pinnedTimepointPosition: null,
  hoveredTimepoint: null,
  hoveredTimepointPosition: null
};

const TOGGLE_SINGLE_LEGEND = "REPORTS.TOGGLE_SINGLE_LEGEND" as const;
const TOGGLE_MULTI_LEGEND = "REPORTS.TOGGLE_MULTI_LEGEND" as const;
const HOVERED_LINE = "REPORTS.HOVERED_LINE" as const;
const HOVERED_LINE_TYPES = "REPORTS.HOVERED_LINE_TYPES" as const;
const UPDATE_PINNED_TIMEPOINT = "REPORTS.UPDATE_PINNED_TIMEPOINT" as const;
const UPDATE_HIDDEN_CHARTS = "REPORTS.UPDATE_HIDDEN_CHARTS" as const;
const UPDATE_LOGISTICS_HIDDEN_LINES = "REPORTS.UPDATE_LOGISTICS_HIDDEN_LINES" as const;
const UPDATE_PINNED_TIMEPOINT_POSITION = "REPORTS.UPDATE_PINNED_TIMEPOINT_POSITION" as const;
const UPDATE_HOVERED_TIMEPOINT = "REPORTS.UPDATE_HOVERED_TIMEPOINT" as const;
const UPDATE_HOVERED_TIMEPOINT_POSITION = "REPORTS.UPDATE_HOVERED_TIMEPOINT_POSITION" as const;

export type ChartOnClickAction = (
  payload: Nullable<number>
) => AnyAction | void;

export type ChartOnHoverAction = (
  payload: Nullable<number>
) => AnyAction | void;

type ToggleSingleLegendAction = {
  type: typeof TOGGLE_SINGLE_LEGEND;
  payload: HiddenLines;
};

type ToggleMultiLegendAction = {
  type: typeof TOGGLE_MULTI_LEGEND;
  payload: HiddenLines;
};

export type HoverLineAction = {
  type: typeof HOVERED_LINE;
  payload: string[];
};

type HoverLineTypesAction = {
  type: typeof HOVERED_LINE_TYPES;
  payload: string[];
};

type UpdatePinnedTimepointAction = {
  type: typeof UPDATE_PINNED_TIMEPOINT;
  payload: Nullable<number>;
};

type UpdateHiddenChartsAction = {
  type: typeof UPDATE_HIDDEN_CHARTS;
  payload: string[];
};

type UpdateLogisticsHiddenLinesAction = {
  type: typeof UPDATE_LOGISTICS_HIDDEN_LINES;
  payload: string[];
};

type UpdatePinnedTimepointPositionAction = {
  type: typeof UPDATE_PINNED_TIMEPOINT_POSITION;
  payload: Nullable<number>;
};

type UpdateHoveredTimepointAction = {
  type: typeof UPDATE_HOVERED_TIMEPOINT;
  payload: Nullable<number>;
};

type UpdateHoveredTimepointPositionAction = {
  type: typeof UPDATE_HOVERED_TIMEPOINT_POSITION;
  payload: Nullable<number>;
};

export type ChartActions = {
  UPDATE_LOGISTICS_HIDDEN_LINES: UpdateLogisticsHiddenLinesAction;
  TOGGLE_SINGLE_LEGEND: ToggleSingleLegendAction;
  TOGGLE_MULTI_LEGEND: ToggleMultiLegendAction;
  HOVERED_LINE: HoverLineAction;
  HOVERED_LINE_TYPES: HoverLineTypesAction;
  UPDATE_PINNED_TIMEPOINT: UpdatePinnedTimepointAction;
  UPDATE_HIDDEN_CHARTS: UpdateHiddenChartsAction;
  UPDATE_PINNED_TIMEPOINT_POSITION: UpdatePinnedTimepointPositionAction;
  UPDATE_HOVERED_TIMEPOINT: UpdateHoveredTimepointAction;
  UPDATE_HOVERED_TIMEPOINT_POSITION: UpdateHoveredTimepointPositionAction;
};

const chartSelector = (state: AppState) => state.reports.chart;

export const hiddenLinesSelector = createSelector(
  chartSelector,
  isRestrictedToOneLineSelector,
  (chart, isRestrictedToOneLine) => {
    return isRestrictedToOneLine
      ? chart.hiddenSingleLine
      : chart.hiddenMultiLines;
  }
);

export const pinnedTimepointPositionSelector = createSelector(
  chartSelector,
  chart => chart.pinnedTimepointPosition
);

export const pinnedTimepointSelector = createSelector(
  chartSelector,
  chart => chart.pinnedTimepoint
);

export const hiddenChartsSelector = createSelector(
  chartSelector,
  chart => chart.hiddenCharts
);

export const hoveredTimepointSelector = createSelector(
  chartSelector,
  chart => chart.hoveredTimepoint
);

export const hoveredTimepointPositionSelector = createSelector(
  chartSelector,
  chart => chart.hoveredTimepointPosition
);

export const timepointSelector = createSelector(
  hoveredTimepointSelector,
  pinnedTimepointSelector,
  (hoveredTimepoint, pinnedTimepoint) => pinnedTimepoint ?? hoveredTimepoint
);

export const hiddenLinesByReportSelector = createSelector(
  pathnameSelector,
  hiddenLinesSelector,
  (pathname, hiddenLines) => {
    const isLocationPage = isThisPage(pathname, LOCATION_PATH);
    const isSegmentsPage = isThisPage(pathname, SEGMENTS_PATH);
    const isLogisticsPage = isThisPage(pathname, LOGISTICS_PATH);

    return isLocationPage
      ? hiddenLines.location
      : isSegmentsPage
      ? hiddenLines.segments
      : isLogisticsPage
      ? hiddenLines.logistics
      : hiddenLines.other;
  }
);

export const hoveredLineSelector = createSelector(
  chartSelector,
  chart => chart.hoveredLine
);

export const hoveredLineTypeSelector = createSelector(
  chartSelector,
  chart => chart.hoveredLineTypes
);

export const updateHoveredLine = (payload: string[]): HoverLineAction => ({
  type: HOVERED_LINE,
  payload
});

export const updateHoveredLineType = (
  payload: string[]
): HoverLineTypesAction => ({
  type: HOVERED_LINE_TYPES,
  payload
});

export const updatePinnedTimepoint = (
  payload: Nullable<number>
): UpdatePinnedTimepointAction => ({
  type: UPDATE_PINNED_TIMEPOINT,
  payload
});

export const updateHiddenCharts = (
  payload: string[]
): UpdateHiddenChartsAction => ({
  type: UPDATE_HIDDEN_CHARTS,
  payload
});

export const updateLogisticsHiddenLines = (
  payload: string[]
): UpdateLogisticsHiddenLinesAction => ({
  type: UPDATE_LOGISTICS_HIDDEN_LINES,
  payload
});

export const updatePinnedTimepointPosition = (
  payload: Nullable<number>
): UpdatePinnedTimepointPositionAction => ({
  type: UPDATE_PINNED_TIMEPOINT_POSITION,
  payload
});

export const updateHoveredTimepoint = (
  payload: Nullable<number>
): UpdateHoveredTimepointAction => ({
  type: UPDATE_HOVERED_TIMEPOINT,
  payload
});

export const updateHoveredTimepointPosition = (
  payload: Nullable<number>
): UpdateHoveredTimepointPositionAction => ({
  type: UPDATE_HOVERED_TIMEPOINT_POSITION,
  payload
});

export const toggleSingleLegend = (
  payload: HiddenLines
): ToggleSingleLegendAction => ({
  type: TOGGLE_SINGLE_LEGEND,
  payload
});

export const toggleArray = (array: string[], compareArray: string[]) => {
  let newCompareArray = [...compareArray];

  if (array.length === 1) {
    const value = array[0];
    const index = newCompareArray.indexOf(value);

    if (index === -1) {
      newCompareArray.push(value);
    } else {
      newCompareArray.splice(index, 1);
    }
  } else {
    const areAllItemsHidden = array.every(value =>
      newCompareArray.includes(value)
    );

    newCompareArray = areAllItemsHidden
      ? newCompareArray.filter(item => !array.includes(item))
      : uniq([...newCompareArray, ...array]);
  }

  return newCompareArray;
};

export const toggleChart = (labels: string[]): Thunk<Values<ChartActions>> => (
  dispatch,
  getState
) => {
  const state = getState();
  const pathname = pathnameSelector(state);
  const hiddenLinesByReport = hiddenLinesByReportSelector(state);
  const isRestrictedToOneLine = isRestrictedToOneLineSelector(state);

  const isLocationPage = isThisPage(pathname, LOCATION_PATH);
  const isSegmentsPage = isThisPage(pathname, SEGMENTS_PATH);
  const isLogisticsPage = isThisPage(pathname, LOGISTICS_PATH);
  const isOtherReport = [isLocationPage, isSegmentsPage, isLogisticsPage].every(
    value => value === false
  );

  const newLines = toggleArray(labels, hiddenLinesByReport);

  const updatedLines = {
    location: isLocationPage ? newLines : hiddenLinesByReport,
    segments: isSegmentsPage ? newLines : hiddenLinesByReport,
    logistics: isLogisticsPage ? newLines : hiddenLinesByReport,
    other: isOtherReport ? newLines : hiddenLinesByReport
  };

  dispatch({
    type: isRestrictedToOneLine ? TOGGLE_SINGLE_LEGEND : TOGGLE_MULTI_LEGEND,
    payload: updatedLines
  });
  dispatch(updateHoveredLine([]));
};

export const chartReducer = (
  state = initialState,
  action: Values<ChartActions>
): ChartState => {
  switch (action.type) {
    case UPDATE_LOGISTICS_HIDDEN_LINES:
      return {
        ...state,
        hiddenMultiLines: {
          ...state.hiddenMultiLines,
          logistics: action.payload
        }
      };

    case TOGGLE_SINGLE_LEGEND:
      return { ...state, hiddenSingleLine: action.payload };

    case TOGGLE_MULTI_LEGEND:
      return { ...state, hiddenMultiLines: action.payload };

    case HOVERED_LINE:
      return { ...state, hoveredLine: action.payload };

    case HOVERED_LINE_TYPES:
      return { ...state, hoveredLineTypes: action.payload };

    case UPDATE_PINNED_TIMEPOINT:
      return {
        ...state,
        pinnedTimepoint:
          state.pinnedTimepoint === action.payload ? null : action.payload
      };

    case UPDATE_PINNED_TIMEPOINT_POSITION:
      return {
        ...state,
        pinnedTimepointPosition: action.payload
      };

    case UPDATE_HOVERED_TIMEPOINT:
      return {
        ...state,
        hoveredTimepoint: action.payload
      };

    case UPDATE_HOVERED_TIMEPOINT_POSITION:
      return {
        ...state,
        hoveredTimepointPosition: action.payload
      };

    case UPDATE_HIDDEN_CHARTS:
      return {
        ...state,
        hiddenCharts: action.payload
      };

    default:
      return state;
  }
};
