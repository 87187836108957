const MAP_COLORS = [
  "#ccf1ce",
  "#91df99",
  "#25ce2e",
  "#0d9e14",
  "#09710f",
  "#0e5111"
];

// Mapping number of MapChart scale tresholds to its range
export const LOCATION_RANGES = [
  [MAP_COLORS[0]],
  [MAP_COLORS[2]],
  [MAP_COLORS[1], MAP_COLORS[4]],
  [MAP_COLORS[0], MAP_COLORS[2], MAP_COLORS[4]],
  [MAP_COLORS[0], MAP_COLORS[2], MAP_COLORS[3], MAP_COLORS[5]],
  MAP_COLORS.slice(0, 5),
  MAP_COLORS
];

export const TOTAL_BLOCKED_BY_COUNTIES_TOOLTIP =
  "Dane total niedostępne dla powiatu";
export const AVG_SHOPS_COUNT_AND_DISTRIBUTION_RANGE_BLOCKED_TOOLTIP =
  "Miary dystrybucji dostępne tylko po wybraniu produktów bez agregacji danych w odniesieniu do terenu całej Polski lub województw";
export const INTERNAL_USE_ONLY = "Miara tylko do użytku wewnętrznego";
