import { useMemo, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";

import cn from "classnames";

import { Button, Portal, SpinnerLoader, Text } from "components/atoms";
import { ChevronDown, ChevronUp, Close } from "components/atoms/Icon";
import { Card, PopupWindow } from "components/molecules";
import {
  windowHeightSelector,
  windowWidthSelector
} from "store/selectors/appSelectors";
import { ICON_SIZES } from "utils";
import { Nullable } from "utils/types";

import s from "./hierarchyModal.module.scss";

type TableData = Record<string, any>;

type TableColumns = Record<string, string>;

const getGridCols = (count: number) => `240px repeat(${count - 1}, 1fr)`;

const Row = ({ style, index: row, data }: ListChildComponentProps) => {
  return (
    <div
      style={{
        ...style,
        gridTemplateColumns: getGridCols(data[row].length)
      }}
      className={cn({
        [s.tableRow]: true,
        [s.tableRowEven]: row % 2 === 0
      })}
    >
      {(data[row] as string[]).map((value, col) => (
        <div key={`row-${row}-col-${col}`} className={s.tableCell}>
          {value}
        </div>
      ))}
    </div>
  );
};

const Table = ({
  data,
  columns
}: {
  data: TableData[];
  columns: TableColumns;
}) => {
  const [sorting, setSorting] = useState<{
    isDesc: boolean;
    key: Nullable<string>;
  }>({ isDesc: false, key: null });
  const width = useSelector(windowWidthSelector) - 150;
  const height = useSelector(windowHeightSelector) - 210;

  const tableData = useMemo(() => {
    let copy = [...data];

    if (sorting.key) {
      copy.sort((a, b) => {
        const valueA = String(a[sorting.key!]);
        const valueB = String(b[sorting.key!]);
        return sorting.isDesc
          ? valueB.localeCompare(valueA)
          : valueA.localeCompare(valueB);
      });
    }

    return copy.map(item => Object.keys(columns).map(key => item[key]));
  }, [columns, data, sorting.isDesc, sorting.key]);

  const handleSorting = (key: string) => {
    if (key === sorting.key) {
      setSorting({ isDesc: !sorting.isDesc, key });
    } else {
      setSorting({ isDesc: false, key });
    }
  };

  return (
    <div className={s.table}>
      <div
        className={s.tableRow}
        style={{
          width,
          gridTemplateColumns: getGridCols(Object.keys(columns).length)
        }}
      >
        {Object.keys(columns).map(key => (
          <div key={key} className={cn(s.tableCell, s.tableHeader)}>
            <button
              className={s.tableHeaderButton}
              onClick={() => handleSorting(key)}
            >
              <span>{columns[key]}</span>
              {sorting.key === key ? (
                sorting.isDesc ? (
                  <ChevronDown />
                ) : (
                  <ChevronUp />
                )
              ) : null}
            </button>
          </div>
        ))}
      </div>
      <List
        itemData={tableData}
        itemCount={data.length}
        itemSize={70}
        width={width}
        height={height}
      >
        {Row}
      </List>
    </div>
  );
};

export const HierarchyModal = ({
  id,
  data,
  columns,
  isFetching,
  isUrlFetching,
  downloadText,
  emptyText,
  onClose,
  onDownload
}: {
  id: string;
  data: TableData[];
  columns: TableColumns;
  isFetching: boolean;
  isUrlFetching: boolean;
  downloadText: string;
  emptyText: string;
  onClose: VoidFunction;
  onDownload: VoidFunction;
}) => {
  return (
    <Portal id={id}>
      <PopupWindow>
        <OutsideClickHandler onOutsideClick={onClose}>
          <Card className={{ base: s.popupWrapper }}>
            {isFetching ? (
              <SpinnerLoader />
            ) : data.length > 0 ? (
              <>
                <div className={s.topLineWrapper}>
                  <Button
                    type="button"
                    className={cn(s.downloadButton, {
                      [s.downloadButtonDisabled]: isUrlFetching
                    })}
                    onClick={isUrlFetching ? undefined : onDownload}
                  >
                    {isUrlFetching ? "Pobieranie..." : downloadText}
                  </Button>
                  <Button
                    type="button"
                    className={s.closeButton}
                    onClick={onClose}
                  >
                    <Close size={ICON_SIZES.EXTRA_SMALL} />
                  </Button>
                </div>
                <Table data={data} columns={columns} />
              </>
            ) : (
              <Text>{emptyText}</Text>
            )}
          </Card>
        </OutsideClickHandler>
      </PopupWindow>
    </Portal>
  );
};
