import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "components/molecules";
import { updateQueryParams } from "store/actions/routerActions";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { isPlanogramHiddenSelector } from "store/selectors/routerSelectors";
import { QP } from "utils/defaultQueryParams";
import { pushLogisticsReportAction } from "utils/googleTagManager/dataLayer";

import s from "./hidePlanogramCheckbox.module.scss";

export const HidePlanogramCheckbox: FC = () => {
  const dispatch = useDispatch();
  const isPlanogramHidden = useSelector(isPlanogramHiddenSelector);
  const isPowerUser = useSelector(isPowerUserSelector);

  const onChange = () => {
    dispatch(
      updateQueryParams({
        [QP.HIDE_PLANOGRAM]: String(!isPlanogramHidden)
      })
    );

    pushLogisticsReportAction("pokaż dystrybucję planogramową");
  };

  if (!isPowerUser) return null;

  return (
    <Checkbox
      className={{
        base: s.hidePlanogramBase,
        label: s.hidePlanogramLabel,
        checkbox: s.hidePlanogramCheckbox
      }}
      label="Pokaż dyst. planogr."
      onChange={onChange}
      isChecked={!isPlanogramHidden}
    />
  );
};
