import { PromotionDataType } from "pages/Reports/redux/reducers/promotionsReducer";

export const findPromotionsForProduct = (
  materialName: string,
  promotions: PromotionDataType[]
) =>
  promotions.filter(
    promo =>
      promo.materials.competingMaterials.some(material =>
        material.data.some(mat => materialName.includes(mat.display))
      ) ||
      promo.materials.ownMaterials.some(material =>
        material.data.some(mat => materialName.includes(mat.display))
      )
  );
