import { AggregationTypes } from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";

export const isAttributeType = (type: AggregationTypes) => {
  return [AggregationTypes.attr_group, AggregationTypes.attr_total].includes(
    type
  );
};

export const pickValue = ({
  query,
  fetching,
  param,
  state
}: {
  query: string;
  fetching: boolean;
  param: string;
  state: string;
}) => {
  return query.length > 0 && !fetching ? state : param;
};

export const isAttributesAggregationEnabled = ({
  isPowerUser,
  filtersState
}: {
  isPowerUser: boolean;
  filtersState: {
    company: string;
    attributes: string;
    brands: string;
    subBrands: string;
    products: string;
    competingCompanies: string;
    competingProducts: string;
    competingBrands: string;
    competingSubBrands: string;
  };
}) => {
  const hasAttributes = filtersState.attributes.length > 0;
  if (!hasAttributes) return false;

  const hasOtherFilters = [
    filtersState.brands,
    filtersState.subBrands,
    filtersState.products,
    filtersState.competingProducts,
    filtersState.competingBrands,
    filtersState.competingSubBrands
  ].some(filter => filter.length > 0);
  if (hasOtherFilters) return false;

  const hasCompetingCompanies = filtersState.competingCompanies.length > 0;
  if (!isPowerUser) return hasCompetingCompanies;

  const hasCompany = filtersState.company.length > 0;
  // [PMD-5036]: for power users options should be enabled when own and competing companies state is equal (both are selected or deselected)
  return hasCompany === hasCompetingCompanies;
};
