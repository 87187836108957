import { useSelector } from "react-redux";

import { useReceiptsTableData } from "api/receipts/mappers";

import { fetchedPromotionsSelector } from "pages/Reports/redux/selectors/promotionsSelectors";
import { getTableDataFromReceipts } from "pages/Reports/utils/getTableData";
import { tablePeriodSelector } from "store/selectors/routerSelectors";

export const useReceiptsOwnTableData = () => {
  const receipts = useReceiptsTableData();
  const promotions = useSelector(fetchedPromotionsSelector);
  const period = useSelector(tablePeriodSelector);

  return getTableDataFromReceipts(receipts, promotions, period);
};
