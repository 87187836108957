import * as React from "react";

import { APP_NAME } from "utils";
import { PRIVACY_POLICY_URL, TRUSTED_PARTNERS_URL } from "utils/constants";

import s from "./cookieConsent.module.scss";

const CookieConsentDetails: React.FC = () => (
  <>
    <p className={s.details}>
      Strona „Żabka {APP_NAME}” wykorzystuje pliki cookies. Klikając „Akceptuję
      wszystkie cookies”, wyrażasz zgodę na instalację plików cookies oraz
      przetwarzanie Twoich danych osobowych w celach analitycznych zgodnie z
      ustawieniami Twojej przeglądarki. Zgodę możesz wycofać w dowolnym momencie
      poprzez zmianę ustawień na stronie "Ustawienia cookies" dostępnej po
      zalogowaniu.
    </p>
    <p className={s.details}>
      Administratorem Twoich danych osobowych jest Żabka Polska sp. z o.o., z
      siedzibą przy ul. Stanisława Matyi 8, 61-586 Poznań. Twoje dane osobowe
      mogą być także przetwarzane przez naszych{" "}
      <a
        href={TRUSTED_PARTNERS_URL}
        className={s.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Zaufanych Partnerów
      </a>
      .
    </p>
    <p className={s.details}>
      Więcej informacji o przysługujących Ci prawach znajdziesz w naszej{" "}
      <a
        href={PRIVACY_POLICY_URL}
        className={s.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Polityce Prywatności
      </a>
      <span>.</span>
    </p>
  </>
);

export default CookieConsentDetails;
