import { useMemo } from "react";

import { ChartDataPart } from "components/D3/types";
import { Nullable } from "utils/types";

import { getTimelineDiffs } from "../../utils/getTimelineDiffs";

export const useDataWithChange = (leftChart: Nullable<ChartDataPart>) => {
  return useMemo(() => {
    const chart = leftChart?.chart[0];
    const id = chart?.id || "";

    if (!chart) return [];
    const diffs = getTimelineDiffs(chart.timeline, chart.lastYearTimeline);
    return chart.timeline.map((item, index) => ({
      ...item,
      change: diffs[index],
      id
    }));
  }, [leftChart]);
};
