import { useSelector } from "react-redux";

import { useAreTopFiltersSelected } from "pages/Reports/redux/reducers/filters/filtersReducer";
import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  hasFreeTrialSelector,
  isPowerUserSelector
} from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { APP_PATHS, isThisPage } from "utils";
import { Values } from "utils/types";

interface Settings {
  filters: Values<typeof FILTER_NAMES>[];
  text: string;
  isVisible: boolean;
}

export const useDisabledTooltip = (
  filter: Values<typeof FILTER_NAMES>,
  isDisabled: boolean
) => {
  const pathname = useSelector(pathnameSelector);
  const isFreeTrail = useSelector(hasFreeTrialSelector);
  const isPowerUser = useSelector(isPowerUserSelector);
  const areFiltersActive = useAreTopFiltersSelected();

  if (!areFiltersActive || !isDisabled) {
    return "";
  }

  const settings: Settings[] = [
    {
      filters: [FILTER_NAMES.VOIVODESHIP],
      text: "Dane z pozostałych województw dostępne w pełnej wersji portalu",
      isVisible: isFreeTrail
    },
    {
      filters: [FILTER_NAMES.VOIVODESHIP, FILTER_NAMES.COUNTY],
      text: "Dane dotyczą obszaru całej Polski",
      isVisible: [
        REPORTS_FULL_PATHS.SEGMENTS_PATH,
        REPORTS_FULL_PATHS.HOURS_PATH,
        REPORTS_FULL_PATHS.LOYALTY_PATH,
        REPORTS_FULL_PATHS.MULTIPACK_PATH
      ].some(url => isThisPage(pathname, url))
    },
    {
      filters: [FILTER_NAMES.VOIVODESHIP, FILTER_NAMES.COUNTY],
      text: "Opcje dostępne dla innego rodzaju danych w eksporcie",
      isVisible: isThisPage(pathname, APP_PATHS.EXPORT) && !isPowerUser
    },
    {
      filters: [FILTER_NAMES.VOIVODESHIP, FILTER_NAMES.COUNTY],
      text: "Wybór opcji możliwy tylko dla wszystkich rodzajów sklepów",
      isVisible: true
    },
    {
      filters: [
        FILTER_NAMES.STORE_TYPE,
        FILTER_NAMES.STORE_TYPE_META,
        FILTER_NAMES.LFL
      ],
      text: "Filtr niedostępny w ramach tego raportu",
      isVisible: isThisPage(pathname, REPORTS_FULL_PATHS.MULTIPACK_PATH)
    },
    {
      filters: [FILTER_NAMES.STORE_TYPE, FILTER_NAMES.STORE_TYPE_META],
      text: "Wybór rodzaju sklepu możliwy tylko dla obszaru całej Polski",
      isVisible: true
    }
  ];

  return (
    settings.find(option => option.isVisible && option.filters.includes(filter))
      ?.text || ""
  );
};
