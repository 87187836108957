import { uniq, values } from "ramda";

import {
  ceilToNearestMultiply,
  defaultTo0,
  floorToNearestMultiply,
  getMagnitudeOrder
} from "utils";
import { Nullable } from "utils/types";

import { MapChart } from "../types/locationChartTypes";

const MAXIMUM_THRESHOLD_COUNT = 6;

export const getMapChartDomain = (
  data: MapChart[],
  thresholdCount = MAXIMUM_THRESHOLD_COUNT
): number[] => {
  const numericValues = getMapChartNumericPrimaryValues(data);
  const thresholds = getMapChartThresholds(numericValues, thresholdCount);
  const updatedThresholdCount = getMapChartThresholdCount(
    numericValues,
    thresholds
  );

  switch (updatedThresholdCount) {
    case 0:
      return [];
    case 1:
      return thresholds.slice(0, 1);
    default: {
      return thresholds.length === updatedThresholdCount
        ? thresholds
        : getMapChartDomain(data, updatedThresholdCount);
    }
  }
};

const getNumbers = (value: Nullable<number>): value is number =>
  typeof value === "number";

const getMapChartNumericPrimaryValues = (charts: MapChart[]) =>
  charts.reduce<number[]>((result, { data, lastYearData }) => {
    const curentChartValues = [
      ...values(data),
      ...(lastYearData ? values(lastYearData) : [])
    ].filter(getNumbers);

    return [...result, ...curentChartValues];
  }, []);

const getMapChartThresholds = (
  numericValues: number[],
  thresholdCount = MAXIMUM_THRESHOLD_COUNT
) => {
  const min = Math.min(...numericValues);
  const max = Math.max(...numericValues);

  if (min === max) {
    return [min];
  }

  const minValMagnitudeOrder = getMagnitudeOrder(min);
  const maxValMagnitudeOrder = getMagnitudeOrder(max);

  const minThreshold = defaultTo0(
    floorToNearestMultiply(min, Math.pow(10, minValMagnitudeOrder))
  );

  const maxThreshold = defaultTo0(
    ceilToNearestMultiply(max, Math.pow(10, maxValMagnitudeOrder))
  );

  const step = (maxThreshold - minThreshold) / thresholdCount;
  const roundedStep = defaultTo0(
    ceilToNearestMultiply(step, Math.pow(10, getMagnitudeOrder(step) - 1))
  );

  return [...new Array(thresholdCount)].map(
    (_, i) => minThreshold + roundedStep * (i + 1)
  );
};

const getMapChartThresholdCount = (
  numericValues: number[],
  thresholds: number[]
) => {
  const uniqueThresholds = uniq(thresholds);

  if (uniqueThresholds.length === 1) {
    return uniqueThresholds[0] === 0 ? 0 : 1;
  }

  const groupedData = groupMapChartDataByThresholds(
    numericValues,
    uniqueThresholds
  );

  const firstNoEmptyThresholdIndex = findFristNoEmptyThresholdIndex(
    groupedData,
    uniqueThresholds
  );

  return uniqueThresholds.length - firstNoEmptyThresholdIndex;
};

export const findFristNoEmptyThresholdIndex = (
  groupedData: { [index: string]: number },
  thresholds: number[]
) => {
  const firstNoEmptyThreshold = thresholds.filter(
    threshold => !!groupedData[threshold]
  )[0];

  const result = thresholds.findIndex(th => th === firstNoEmptyThreshold);

  return result > -1 ? result : 0;
};

const groupMapChartDataByThresholds = (
  numericValues: number[],
  thresholds: number[]
) => {
  return numericValues.reduce<{ [index: string]: number }>(
    (groupedValues, value, i) => {
      const currentThreshold = thresholds.find(threshold => value < threshold);

      if (currentThreshold) {
        groupedValues[currentThreshold] =
          (groupedValues[currentThreshold] || 0) + 1;
      }

      return groupedValues;
    },
    {}
  );
};
