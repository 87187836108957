import { useSelector } from "react-redux";

import { validatedChartPeriodsSelector } from "pages/Reports/redux/reducers/periods/selectors";
import { chartPeriodSelector } from "store/selectors/routerSelectors";
import { PERIOD_TYPE } from "utils";

export const useIsRankingSelected = () => {
  const chartPeriods = useSelector(validatedChartPeriodsSelector);
  const isRankingEnabled = chartPeriods.includes(PERIOD_TYPE.RANKING);

  const chartPeriod = useSelector(chartPeriodSelector);

  return isRankingEnabled && chartPeriod === PERIOD_TYPE.RANKING;
};
