import * as React from "react";

import cn from "classnames";

import { ICON_SIZES } from "utils/";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const Tick = ({ className, color, size = ICON_SIZES.NORMAL }: Props) => {
  const currentSize = SIZES[size];
  return (
    <svg
      className={cn(className)}
      width={`${currentSize}px`}
      height={`${currentSize}px`}
      viewBox={`0 0 16 16`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="icons/tick-green"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12.3018101,4.28110249 L5.9958956,10.582856 L3.69818991,8.28666645 C3.30500578,7.90716709 2.68001992,7.91259447 2.29349452,8.29886482 C1.90696912,8.68513517 1.90153816,9.30970863 2.2812881,9.70263331 L5.2874447,12.7068063 C5.67874593,13.0977312 6.31304527,13.0977312 6.70434651,12.7068063 L13.7187119,5.69706935 C14.0984618,5.30414467 14.0930309,4.67957121 13.7065055,4.29330086 C13.3199801,3.90703051 12.6949942,3.90160313 12.3018101,4.28110249 Z"
          id="Path"
          fill={color || "currentColor"}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};
