import { useGetDynamicsByPeriod } from "api/dynamics/mappers";
import { useGetRanking } from "api/ranking/hooks";

export const useIsDynamicsLoading = () => {
  const {
    isLoading: isDynamicsLoading,
    isIdle: isDynamicsIdle
  } = useGetDynamicsByPeriod();
  const { isLoading: rankingLoading } = useGetRanking();

  return isDynamicsLoading || isDynamicsIdle || rankingLoading;
};
