import { useSelector } from "react-redux";

import { useZappkaSharesCheckboxState } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  chartPeriodSelector,
  firstChartDataTypeSelector,
  pathnameSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import {
  CHART_DATA_TYPE,
  getPolishLabelForChart,
  getPolishLabelForTimePeriod,
  isThisPage
} from "utils";

export const useChartLabels = () => {
  const firstType = useSelector(firstChartDataTypeSelector);
  const secondType = useSelector(secondChartDataTypeSelector);
  const period = useSelector(chartPeriodSelector);
  const pathname = useSelector(pathnameSelector);
  const zappkaShares = useZappkaSharesCheckboxState();

  const left = getPolishLabelForChart(firstType, zappkaShares.first);
  const right = getPolishLabelForChart(secondType, zappkaShares.second);
  const bottom = getPolishLabelForTimePeriod(period);

  if (isThisPage(pathname, REPORTS_FULL_PATHS.LOCATION_PATH)) {
    return { left, right, bottom };
  }

  return {
    left: firstType !== CHART_DATA_TYPE.NONE ? left : right,
    right,
    bottom
  };
};
