import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useDynamicsOwnTableData } from "api/dynamics/mappers";

import { fetchedPromotionsSelector } from "pages/Reports/redux/selectors/promotionsSelectors";
import { getTableDataFromDynamics } from "pages/Reports/utils/getTableData";
import { tablePeriodSelector } from "store/selectors/routerSelectors";

export const useDynamicsTableData = () => {
  const promotions = useSelector(fetchedPromotionsSelector);
  const period = useSelector(tablePeriodSelector);
  const preparedTableData = useDynamicsOwnTableData();

  const data = useMemo(
    () => getTableDataFromDynamics(preparedTableData, promotions, period),
    [period, preparedTableData, promotions]
  );

  return data;
};
