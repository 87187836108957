import { Fragment } from "react";
import { useDispatch } from "react-redux";

import { useLocationMapGeneralChartsData } from "api/location/mappers/useLocationMapGeneralChartData";
import { values } from "ramda";

import { Text } from "components/atoms";
import c from "components/common.module.scss";
import { VOIVODESHIPS } from "components/D3/Voivodeship/constants";
import { useChartTopLabel } from "hooks/useChartTopLabel";
import { useGetScaledColorFn } from "pages/Reports/sections/Location/hooks/useGetScaledColorFn";
import {
  HoveredRegion,
  updateHoveredRegion
} from "pages/Reports/sections/Location/redux/reducers/locationChartReducer";
import { MapChartData } from "pages/Reports/sections/Location/redux/types/locationChartTypes";
import { REGION_TYPES } from "utils";
import { Nullable } from "utils/types";

import s from "./mapChart.module.scss";
import { Region } from "./Region";

interface CountryMapProps {
  data: Nullable<MapChartData>;
  parentKey: string;
  onHover: (value: string) => void;
  onBlur: () => void;
}

const CountryMap = ({ data, parentKey, onHover, onBlur }: CountryMapProps) => {
  const getScaledColor = useGetScaledColorFn();

  return (
    <svg viewBox="0 0 2758.86 2563.47" className={s.canvas}>
      <g>
        {values(VOIVODESHIPS).map(voivodeship => (
          <Region
            id={voivodeship}
            key={`${parentKey}:${voivodeship}`}
            fill={getScaledColor(data ? data[voivodeship] : null)}
            type={REGION_TYPES.VOIVODESHIP}
            onHover={() => onHover(voivodeship)}
            onBlur={onBlur}
          />
        ))}
      </g>
    </svg>
  );
};

export const MapChartGeneral = () => {
  const dispatch = useDispatch();
  const chartData = useLocationMapGeneralChartsData();
  const { regularDateLabel, compareDateLabel } = useChartTopLabel({});

  const handleRegionHover = (region: HoveredRegion) => {
    dispatch(updateHoveredRegion(region));
  };

  const handleRegionBlur = () => {
    dispatch(updateHoveredRegion(null));
  };

  if (chartData.length === 0) {
    return (
      <CountryMap
        data={null}
        parentKey="empty"
        onHover={() => {}}
        onBlur={() => {}}
      />
    );
  }

  return (
    <>
      {chartData.map(({ label, data, lastYearData }, index) => (
        <Fragment key={`map-chart-general-${index}`}>
          <label className={s.label} data-testid="map-chart-general">
            <Text className={s.labelText}>{label}</Text>
            <span className={c.chartSectionDatesLabel}>{regularDateLabel}</span>
            <CountryMap
              data={data}
              parentKey={label}
              onHover={voivodeship =>
                handleRegionHover({
                  id: voivodeship,
                  chart: index
                })
              }
              onBlur={handleRegionBlur}
            />
          </label>
          {lastYearData ? (
            <label
              className={s.label}
              data-testid="map-chart-general-historical"
            >
              <Text className={s.labelText}>{label} - dane historyczne</Text>
              <span className={c.chartSectionDatesLabel}>
                {compareDateLabel}
              </span>
              <CountryMap
                data={lastYearData}
                parentKey={`${label}:historical`}
                onHover={voivodeship =>
                  handleRegionHover({
                    id: voivodeship,
                    chart: index
                  })
                }
                onBlur={handleRegionBlur}
              />
            </label>
          ) : null}
        </Fragment>
      ))}
    </>
  );
};
