import { LegendItem } from "pages/Reports/partials/Chart/Legend/LegendItems/LegendItems";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { WEATHER_CHART_COLOR } from "utils";
import { isWeatherDataType } from "utils/constants";

interface Segment {
  shortLabel: string;
  longLabel: string;
}

export const SEGMENTS_SHORT_LABELS = {
  A1: "A1",
  A2: "A2",
  A3: "A3",
  A4: "A4",
  A5: "A5",
  O1: "O1",
  O2: "O2",
  O3: "O3",
  T1: "T1",
  T2: "T2",
  T3: "T3",
  T4: "T4",
  K: "K",
  NC: "NC",
  NO: "NO",
  NT: "NT"
};

export const getSegmentsColor = (
  shortLabel: string,
  dataType: ChartDataTypes = ""
) => {
  if (isWeatherDataType(dataType)) return WEATHER_CHART_COLOR;

  switch (shortLabel) {
    case SEGMENTS_SHORT_LABELS.O1:
    case SEGMENTS_SHORT_LABELS.O2:
    case SEGMENTS_SHORT_LABELS.O3:
      return "#006420";
    case SEGMENTS_SHORT_LABELS.T1:
    case SEGMENTS_SHORT_LABELS.T2:
    case SEGMENTS_SHORT_LABELS.T3:
    case SEGMENTS_SHORT_LABELS.T4:
    case SEGMENTS_SHORT_LABELS.K:
      return "#ffa644";
    case SEGMENTS_SHORT_LABELS.A1:
    case SEGMENTS_SHORT_LABELS.A2:
    case SEGMENTS_SHORT_LABELS.A3:
    case SEGMENTS_SHORT_LABELS.A4:
    case SEGMENTS_SHORT_LABELS.A5:
      return "#5a65d9";
    default:
      return "#000";
  }
};

export const createLegendItem = (
  shortLabel: string,
  segments: Segment[],
  hiddenLines: string[]
): LegendItem => {
  const segment = segments.find(segment => segment.shortLabel === shortLabel);

  if (!segment) {
    return {
      text: "",
      color: "#000",
      id: "",
      isLineHidden: true
    };
  }

  return {
    text: segment.longLabel,
    id: `${segment.longLabel}`,
    color: getSegmentsColor(segment.shortLabel),
    isLineHidden: hiddenLines.includes(segment.longLabel)
  };
};

export const filterLegendItem = (item: LegendItem, segments: Segment[]) =>
  segments.map(segment => segment.longLabel).includes(item.text);

export const getUnknownShortLabels = (segments: Segment[]) => {
  const knownShortLabels = Object.values(SEGMENTS_SHORT_LABELS);

  return segments
    .filter(segment => !knownShortLabels.includes(segment.shortLabel))
    .map(segment => segment.shortLabel)
    .sort();
};
