import {
  addDays,
  differenceInCalendarDays,
  isLeapYear,
  isWithinInterval,
  subYears
} from "date-fns";
import { ParsedQuery } from "query-string";

import { DateItem } from "pages/Reports/redux/reducers/sweetStateHooks/useCalendar";
import { newDateByTimezone } from "store/utils";
import { BOOL_STRING_VALUES } from "utils";
import { QP } from "utils/defaultQueryParams";
import { Nullable } from "utils/types";

enum Source {
  initial,
  state
}

export enum LeapYearStatus {
  base,
  compare,
  bothOrNone
}

type Period = { start: Date; end: Date };

export const getYearBack = (dates: Period, isMonthCalendar: boolean) => {
  let diff = differenceInCalendarDays(dates.end, dates.start);
  if (isLeapYear(dates.start) !== isLeapYear(dates.end)) diff -= 1;
  const start = subYears(dates.start, 1);
  const end = addDays(start, diff);

  return { start, end };
};

const getSource = (state: DateItem) => {
  if (state.dateFrom && state.dateTo) {
    return Source.state;
  }

  return Source.initial;
};

export const getInitialDates = (
  state: DateItem,
  basePeriod: Period,
  isMonthCalendar: boolean
) => {
  const source = getSource(state);

  switch (source) {
    case Source.state:
      return {
        start: newDateByTimezone(state.dateFrom),
        end: newDateByTimezone(state.dateTo)
      };
    case Source.initial:
    default:
      return getYearBack(basePeriod, isMonthCalendar);
  }
};

export const declenseDays = (count: number) => {
  return count === 1 ? "dzień" : "dni";
};

export const declenseMonths = (count: number) => {
  if (count < 20) {
    switch (count) {
      case 1:
        return "miesiąc";
      case 2:
      case 3:
      case 4:
        return "miesiące";
      default:
        return "miesięcy";
    }
  }

  switch (count % 10) {
    case 2:
    case 3:
    case 4:
      return "miesiące";
    default:
      return "miesięcy";
  }
};

export const checkLeapYear = ({ start, end }: Period) => {
  for (let year = start.getFullYear(); year <= end.getFullYear(); year += 1) {
    const date = newDateByTimezone(`${year}-02-29`);

    if (isLeapYear(date) && isWithinInterval(date, { start, end })) {
      return true;
    }
  }

  return false;
};

export const getInitialLeapYear = (
  params: ParsedQuery,
  state: Nullable<boolean>
) => {
  if (state !== null) {
    return state;
  }

  const include_leap_year = String(params[QP.INCLUDE_LEAP_YEAR]).toLowerCase();
  return include_leap_year === BOOL_STRING_VALUES.TRUE;
};
