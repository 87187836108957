import { useGetRanking } from "api/ranking/hooks";
import { useGetSharesByPeriod } from "api/shares/mappers";

export const useIsSharesLoading = () => {
  const {
    isLoading: isSharesLoading,
    isIdle: isSharesIdle
  } = useGetSharesByPeriod();
  const { isLoading: rankingLoading } = useGetRanking();

  return isSharesLoading || isSharesIdle || rankingLoading;
};
