import { ChartData } from "components/D3/types";
import { COLOR } from "utils/constants";

import { getBarHorizontalPos } from "../BarsGroup";
import { useChartScales } from "../utils";
import { useDataWithChange } from "./hooks/useDataWithChange";
import { useOneLineSelection } from "./hooks/useOneLineSelected";
import { useCurrentDataset } from "./ShowDatasetSelect/hooks";

interface Props {
  lineChart: ChartData;
  width: number;
  originalWidth: number;
}

export const IncrementalComboChart = ({
  lineChart,
  width,
  originalWidth
}: Props) => {
  const { both: isBothDatasets } = useCurrentDataset();
  const { xScaleBand, yScale } = useChartScales(lineChart, originalWidth);
  const data = useDataWithChange(lineChart.leftChart);

  useOneLineSelection();

  const bandWidth = xScaleBand.bandwidth();
  const regularBarWidth = Math.min(bandWidth - bandWidth * 0.2, 60);
  const barsCount = data.length;

  return (
    <g>
      {data.map((item, index) => {
        const valueY = isBothDatasets ? item.change : item.valueY || 0;

        const [barX] = getBarHorizontalPos({
          barWidth: regularBarWidth,
          barIndex: index,
          barsCount,
          chartWidth: width,
          placeBarsAtCenter: false
        });

        return (
          <rect
            key={`incremental-combo-bar-${index}`}
            x={barX}
            y={yScale(Math.max(0, valueY))}
            width={regularBarWidth}
            height={Math.abs(yScale(valueY) - yScale(0))}
            fill={valueY < 0 ? COLOR.BAR_CHANGE_RED : COLOR.BAR_CHANGE_GREEN}
            id={item.id}
            data-multiline
          />
        );
      })}
    </g>
  );
};
