import { transformPromotionsForTable } from "components/atoms/PromotionContent/utils";
import { PromotionDataType } from "pages/Reports/redux/reducers/promotionsReducer";
import { getSidebarHeader } from "pages/Reports/redux/utils/getSidebarHeader";
import { PERIOD_TYPE } from "utils";

interface DataObject {
  dateFromQP: string;
  dateToQP: string;
  promotions: PromotionDataType[];
}

export const getRankingPromotionsSidebar = ({
  dateFromQP,
  dateToQP,
  promotions
}: DataObject) => {
  const dataset = { base: true, historical: false, both: false };
  const firstDate = getSidebarHeader(
    dateFromQP,
    null,
    PERIOD_TYPE.DAYS,
    dataset
  );
  const secondDate = getSidebarHeader(
    dateToQP,
    null,
    PERIOD_TYPE.DAYS,
    dataset
  );

  const header = `${firstDate} - ${secondDate}`;

  return {
    promotions: transformPromotionsForTable(promotions),
    data: [],
    header
  };
};
