import { formatLocale } from "d3";

import { CURRENCY, METRIC_TYPE } from "utils/constants";
import { Values } from "utils/types";

const K = 1000;
const MLN = 1000000;
const MLD = 1000 * MLN;

const customFormat = formatLocale({
  decimal: ",",
  thousands: ",",
  grouping: [3],
  currency: ["", "zł"]
});

export const addSuffix = (
  value: string,
  type: Values<typeof METRIC_TYPE> = METRIC_TYPE.ABSOLUTE
) => {
  switch (type) {
    case METRIC_TYPE.ABSOLUTE:
      return value;
    case METRIC_TYPE.PRICE:
      return `${value} ${CURRENCY}`;
    case METRIC_TYPE.PP:
      return `${value} p.p.`;
    case METRIC_TYPE.PIECE:
      return `${value} szt.`;
    default:
      return value;
  }
};

export const round = (
  value: number,
  type: Values<typeof METRIC_TYPE> = METRIC_TYPE.ABSOLUTE
) => {
  let formatted = "";
  const absValue = Math.abs(value);

  if (absValue < K) {
    formatted = customFormat.format("~")(value);
  }
  if (absValue >= K) {
    formatted = customFormat.format(".2~s")(value);
  }
  if (absValue >= 10 * K) {
    formatted = customFormat.format(",.3~s")(value);
  }
  if (absValue >= 100 * K) {
    formatted = customFormat.format(",.4~s")(value);
  }
  if (absValue >= MLN) {
    formatted = customFormat.format(`,.2~s`)(value);
  }
  if (absValue >= 10 * MLN) {
    formatted = customFormat.format(`,.3~s`)(value);
  }
  if (absValue >= 100 * MLN) {
    formatted = customFormat.format(`,.4~s`)(value);
  }
  if (absValue >= MLD) {
    formatted = customFormat
      .format(`,~r`)(value / MLN)
      .concat("M");
  }

  return addSuffix(formatted, type);
};
