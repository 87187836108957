import React from "react";

import cn from "classnames";

import { Checkbox } from "components/molecules";
import { useFiltersQP } from "hooks";
import s from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/LFLChoiceGroup/lflChoiceGroup.module.scss";
import { useAreTopFiltersSelected } from "pages/Reports/redux/reducers/filters/filtersReducer";
import { BrandHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useBrand";
import { BOOL_STRING_VALUES } from "utils";

type Props = { isDisabled: boolean };

export const AggregateReferenceBrands = ({ isDisabled }: Props) => {
  const areFiltersActive = useAreTopFiltersSelected();
  const { refBrandAggregatedQP } = useFiltersQP();
  const [state, actions] = BrandHooks.useBrand();

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    actions.updateReferenceAggregated(e.currentTarget.checked);
  };

  React.useEffect(() => {
    actions.updateReferenceAggregated(
      refBrandAggregatedQP === BOOL_STRING_VALUES.TRUE
    );
  }, [actions, refBrandAggregatedQP]);

  return (
    <div className={s.base}>
      <Checkbox
        className={{
          base: s.checkbox,
          label: cn(s.checkboxLabel, {
            [s.checkboxLabelChecked]: state.referenceBrandAggregated
          })
        }}
        label="Agreguj dane wybranych marek"
        isChecked={state.referenceBrandAggregated}
        onChange={handleChange}
        isDisabled={!areFiltersActive || isDisabled}
        testId="ref-brand-aggr-checkbox"
      />
    </div>
  );
};
