import { useRankingWarnings } from "api/ranking/mappers";

import { useGetMultipackByPeriod } from "./useGetMultipackByPeriod";
import { useMultipackGeneralWarnings } from "./useMultipackGeneralWarnings";

export const useMultipackWarnings = (): string[] => {
  const generalWarnings = useMultipackGeneralWarnings();
  const info = useGetMultipackByPeriod().data?.data.info || [];
  const rankingWarnings = useRankingWarnings();

  return [...generalWarnings, ...info, ...rankingWarnings].filter(
    warning => !!warning
  );
};
