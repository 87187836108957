import React from "react";

import cn from "classnames";

import { CardContent, Text } from "components/atoms";
import { Logo } from "components/atoms/Icon";
import { Card } from "components/molecules";
import { APP_NAME } from "utils";

import s from "./logoView.module.scss";

interface Props {
  children: React.ReactNode;
  className?: { base?: string };
}

export const LogoView = ({ children, className = {} }: Props) => {
  return (
    <div className={cn(s.base, className.base)}>
      <div className={s.cardWrapper}>
        <Card className={{ base: s.card }}>
          <CardContent className={s.cardContent}>
            <Logo className={s.logo} />
            <h2>
              <Text className={s.header}>{APP_NAME}</Text>
            </h2>
            {children}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
