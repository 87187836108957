import { ReactNode } from "react";

import cn from "classnames";

import s from "./toggleButton.module.scss";

interface Props {
  className?: {
    li?: string;
    button?: string;
    label?: string;
  };
  label?: string;
  tooltip?: string;
  isActive?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  testId?: string;
  ga?: {
    [key: string]: string;
  };
  isDisabled?: boolean;
  children?: ReactNode;
}

export const ToggleButton: React.FC<Props> = ({
  className = {
    li: "",
    button: "",
    label: ""
  },
  isDisabled = false,
  label = "",
  tooltip,
  isActive = false,
  onClick,
  testId = `toggle-button-${label}`,
  ga,
  children
}) => (
  <li
    className={cn(
      s.toggleElement,
      { [s.active]: isActive, [s.tooltip]: tooltip, [s.disabled]: isDisabled },
      className.li
    )}
    data-tooltip={tooltip}
    data-active={isActive}
    data-disabled={isDisabled}
  >
    <button
      {...ga}
      data-testid={testId}
      className={cn(s.button, className.button)}
      onClick={onClick}
      disabled={isDisabled}
      type="button"
    >
      {children ? (
        children
      ) : (
        <span className={cn(s.label, className.label)}>{label}</span>
      )}
    </button>
  </li>
);
