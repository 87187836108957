import * as React from "react";

import cn from "classnames";

import { HEADER_ELEMENTS, HEADER_TYPES } from "utils";
import { Values } from "utils/types";

import s from "./Heading.module.scss";

interface Props {
  children: React.ReactNode;
  type?: Values<typeof HEADER_TYPES>;
  element?: Values<typeof HEADER_ELEMENTS>;
  className?: string;
  testId?: string;
}

export const Heading = ({
  children,
  type = "h2",
  element,
  className,
  testId
}: Props) => {
  const Wrapper =
    element ||
    (["h2", "h3", "h4", "h5"].includes(type) &&
      (type as Values<typeof HEADER_ELEMENTS>)) ||
    "h5";

  return (
    <Wrapper data-testid={testId} className={cn(s.base, s[type], className)}>
      {children}
    </Wrapper>
  );
};
