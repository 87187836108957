import React from "react";
import { useSelector } from "react-redux";

import { SummaryItem } from "api/types";
import { useActiveCompanyName } from "api/utils/useActiveCompanyName";
import { format } from "date-fns";

import { Heading, Text } from "components/atoms";
import { Summary, SummaryCard, SummarySwitch } from "components/molecules";
import { isHistoricalDataEnabledSelector } from "components/molecules/DateDropdownMenu/HistoricalSection/selectors";
import { IDS } from "components/organisms/Tour";
import {
  compareDateFromQPSelector,
  compareDateToQPSelector
} from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { DATE_FNS_DOT_FORMAT, HEADER_TYPES } from "utils";
import { QP } from "utils/defaultQueryParams";

import {
  useSummaryClientTabs,
  useSummaryTabs,
  useSummaryWarning
} from "./hooks";
import s from "./reportSummary.module.scss";

interface Props {
  summary: SummaryItem[];
  isFetching: boolean;
}

export const ReportSummary: React.FC<Props> = ({ isFetching, summary }) => {
  const client = useSummaryClientTabs();
  const { primary, secondary } = useSummaryTabs();
  const warnings = useSummaryWarning();
  const activeCompany = useActiveCompanyName();

  const isHistoricalDataEnabled = useSelector(isHistoricalDataEnabledSelector);
  const compareDateFromQP = useSelector(compareDateFromQPSelector);
  const compareDateToQP = useSelector(compareDateToQPSelector);
  const isComparisonTimeVisible =
    isHistoricalDataEnabled && compareDateFromQP && compareDateToQP;

  const dataTypeLabel = isComparisonTimeVisible
    ? `Okres porównawczy (${format(
        newDateByTimezone(String(compareDateFromQP)),
        DATE_FNS_DOT_FORMAT
      )} - ${format(
        newDateByTimezone(String(compareDateToQP)),
        DATE_FNS_DOT_FORMAT
      )})`
    : "Poprzedni okres R/R";

  const getDividerBottomPosition = () => {
    if (typeof summary[0].changeBottom === "number") {
      return 68;
    }

    return 55;
  };

  return (
    <SummaryCard className={s.summaryCard} isLoading={isFetching}>
      <div className={s.headingWrapper} data-testid="report-summary-header">
        <Heading type={HEADER_TYPES.H4}>
          Podsumowanie - dane własne
          {activeCompany && ` (${activeCompany})`}
        </Heading>
        <div className={s.warningsWrapper}>
          {warnings
            ? warnings.map(text => (
                <Text
                  key={`warning-${text}`}
                  className={s.warning}
                  testId="summary-warning-text"
                >
                  {text}
                </Text>
              ))
            : null}
        </div>
      </div>
      <>
        {client.tabs.length > 0 && (
          <SummarySwitch
            buttonsId={IDS.SUMMARY_TRANSACTION}
            label="Rodzaj transakcji:"
            isSecondary={false}
            tabParam={QP.ZAPPKA_SUMMARY_TYPE}
            visibleTab={client.active}
            tabs={client.tabs}
          />
        )}
        {primary.tabs.length > 0 && (
          <SummarySwitch
            label="Pokaż:"
            isSecondary={client.tabs.length > 0}
            tabParam={primary.param}
            visibleTab={primary.active}
            tabs={primary.tabs}
          />
        )}
        {secondary.tabs.length > 0 && (
          <SummarySwitch
            isSecondary
            tabParam={secondary.param}
            visibleTab={secondary.active}
            tabs={secondary.tabs}
          />
        )}
        <div className={s.summaryContent} data-testid="report-summary-content">
          {summary.map(item => (
            <Summary
              change={item.change}
              changeBottom={item.changeBottom}
              key={`summary-${item.label}`}
              headingType={HEADER_TYPES.MAIN_NUMBER}
              label={item.label}
              firstValue={item.firstValue}
              firstValueBottom={item.firstValueBottom}
              secondValue={item.secondValue}
              secondValueBottom={item.secondValueBottom}
              thirdValue={item.thirdValue}
              thirdValueBottom={item.thirdValueBottom}
              tooltipText={item.tooltipText}
            />
          ))}
          {summary.length !== 0 && (
            <div
              style={{ bottom: getDividerBottomPosition() }}
              className={s.horizontalDivider}
            >
              <span className={s.dividerText}>{dataTypeLabel}</span>
              <div className={s.dividerLine} />
            </div>
          )}
        </div>
      </>
    </SummaryCard>
  );
};
