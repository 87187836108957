import { useEffect, useState } from "react";

export const useCallbackOnMount = (callback: Function) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (!mounted) {
      callback();
    }
    setMounted(true);
  }, [mounted, callback]);
};
