import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import cn from "classnames";

import { TooltipIcon } from "components/atoms/Icon";
import { Checkbox } from "components/molecules";
import { isBackDataSelectableSelector } from "components/molecules/DateDropdownMenu/HistoricalSection/selectors";
import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { LFLHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useLFL";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  lflQueryParamSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import { BOOL_STRING_VALUES, isThisPage } from "utils";
import { pushLflFilterHover } from "utils/googleTagManager/dataLayer";

import { useDisabledTooltip } from "../hooks/useDisabledTooltip";
import s from "./lflChoiceGroup.module.scss";

type Props = { isDisabled: boolean };

const useInfoTooltip = () => {
  const pathname = useSelector(pathnameSelector);

  if (isThisPage(pathname, REPORTS_FULL_PATHS.HOURS_PATH)) {
    return "Dane godzinowe są pokazywane z pominięciem LFL";
  }

  return "Dane z wybranego zakresu czasu z takiej samej puli sklepów jak rok wcześniej. Jest to LFL z przesunięciem, czyli porównujemy się do podobnych dni a nie dokładnie tych samych wg kalendarza.";
};

export const LFLChoiceGroup = ({ isDisabled }: Props) => {
  const [state, actions] = LFLHooks.useLFL();
  const lflQP = useSelector(lflQueryParamSelector);
  const isBackDataSelectable = useSelector(isBackDataSelectableSelector);
  const checkboxTooltip = useDisabledTooltip(FILTER_NAMES.LFL, isDisabled);
  const infoTooltip = useInfoTooltip();

  const checkbox = useMemo(() => {
    return {
      isDisabled: isDisabled || state.isDisabledByBackData,
      tooltip: checkboxTooltip
        ? checkboxTooltip
        : !isDisabled && state.isDisabledByBackData
        ? "Brak możliwości wyboru opcji LFL przy porównywaniu danych do okresu historycznego"
        : ""
    };
  }, [checkboxTooltip, isDisabled, state.isDisabledByBackData]);

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    actions.updateLFL(e.currentTarget.checked);
  };

  useEffect(() => {
    actions.updateLFL(
      isBackDataSelectable ? false : lflQP === BOOL_STRING_VALUES.TRUE
    );
    actions.updateDisabled(isBackDataSelectable);
  }, [actions, isBackDataSelectable, lflQP]);

  return (
    <div className={s.base}>
      <Checkbox
        className={{
          base: s.checkbox,
          label: cn(s.checkboxLabel, {
            [s.checkboxLabelChecked]: state.lfl
          })
        }}
        label="Uwzględnij LFL"
        isChecked={state.lfl}
        onChange={handleChange}
        isDisabled={checkbox.isDisabled}
        tooltipText={checkbox.tooltip}
        testId="lfl-checkbox"
      />
      <div
        className={s.tooltipWrapper}
        onMouseEnter={pushLflFilterHover}
        data-tooltip={infoTooltip}
      >
        <TooltipIcon />
      </div>
    </div>
  );
};
