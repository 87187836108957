import { Action, createHook, createStore } from "react-sweet-state";

import { ParsedQuery } from "query-string";

import { QP } from "utils/defaultQueryParams";

export type AttributesItemApi = {
  attribute_id: number;
  attribute_name: string;
  attribute_value_id: number;
  attribute_value_name: string;
  all_products_count: number;
  own_products_count: number;
  comp_products_count: number;
};

type State = {
  all: AttributesItemApi[];
  selected: string[];
};

type Actions = typeof actions;

const getSortedSelected = (state: State) => {
  return state.selected.sort();
};

const getInputValue = (state: State) => {
  return state.selected.length > 0
    ? `Wybrano ${state.selected.length} z ${state.all.length}`
    : "";
};

const actions = {
  updateAllAttributes: (attributes: AttributesItemApi[]): Action<State> => ({
    setState
  }) => {
    setState({ all: attributes });
  },
  updateSelectedAttributes: (ids: string[]): Action<State> => ({
    setState
  }) => {
    setState({ selected: ids });
  },
  checkPristineSelectionsAfterFetching: (
    attributes: AttributesItemApi[],
    params: ParsedQuery<string>
  ): Action<State> => ({ setState }) => {
    const attributesQP = String(params[QP.ATTRIBUTES] || "");
    const ids = attributesQP.split(",");
    const selected = attributes
      .filter(({ attribute_value_id }) => {
        return ids.includes(String(attribute_value_id));
      })
      .map(attribute => {
        return String(attribute.attribute_value_id);
      });
    setState({ selected });
  },
  checkSelectionsAfterFetching: (ids: string[]): Action<State> => ({
    getState,
    setState
  }) => {
    setState({
      selected: getState().selected.filter(id => {
        return ids.includes(id);
      })
    });
  },
  clearAttributes: (): Action<State> => ({ setState }) => {
    setState({ selected: [] });
  }
};

const AttributesStore = createStore<State, Actions>({
  name: "attributes",
  initialState: { all: [], selected: [] },
  actions
});

export const AttributesHooks = {
  useAttributes: createHook(AttributesStore),
  useSelectedAttributes: createHook(AttributesStore, {
    selector: getSortedSelected
  }),
  useInputValue: createHook(AttributesStore, {
    selector: getInputValue
  })
};
