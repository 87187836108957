import { useSelector } from "react-redux";

import { useFiltersQP } from "hooks";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { isPowerUserSelector, tierSelector } from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage } from "utils";
import {
  DYNAMICS_FIRST_CHART_DATA_TYPES,
  DYNAMICS_SECOND_CHART_DATA_TYPES,
  LOGISTICS_SHOPS_CHART_DATA_TYPES,
  LOGISTICS_SUPPLY_CHART_DATA_TYPES,
  LOGISTICS_WPZ30_CHART_DATA_TYPES,
  LOGISTICS_WPZ7_CHART_DATA_TYPES,
  LOGISTICS_WPZ90_CHART_DATA_TYPES,
  LOYALTY_ABSOLUTE_DATA_TYPES,
  LOYALTY_OTHER_CHART_DATA_TYPES,
  LOYALTY_PERCENT_DATA_TYPES,
  RECEIPTS_FIRST_CHART_DATA_TYPES,
  RECEIPTS_SECOND_CHART_DATA_TYPES,
  SEGMENTS_FIRST_CHART_DATA_TYPES,
  SEGMENTS_SECOND_CHART_DATA_TYPES,
  SELL_FIRST_CHART_DATA_TYPES,
  SELL_SECOND_CHART_DATA_TYPES,
  SHARES_FIRST_CHART_DATA_TYPES,
  SHARES_SECOND_CHART_DATA_TYPES
} from "utils/constants/dataTypes";

import {
  filterNaturalUnitsTypes,
  filterPlanogramTypes,
  filterTypesByTier
} from "../utils";

// this refers to data types' columns inside each dropdown, not index of ChartHeader dropdown
export enum MegaDropdownLevels {
  first = "first",
  second = "second",
  third = "third",
  fourth = "fourth",
  fifth = "fifth"
}

export const useMegaDropdown = (level: MegaDropdownLevels) => {
  const pathname = useSelector(pathnameSelector);
  const isPowerUser = useSelector(isPowerUserSelector);
  const tier = useSelector(tierSelector);
  const { countiesQP } = useFiltersQP();
  const base = { text: "", items: [] };

  if (isThisPage(pathname, REPORTS_FULL_PATHS.SELL_PATH)) {
    switch (level) {
      case MegaDropdownLevels.first:
        return {
          text: "Miary podstawowe:",
          items: filterNaturalUnitsTypes(
            SELL_FIRST_CHART_DATA_TYPES,
            isPowerUser,
            countiesQP
          )
        };
      case MegaDropdownLevels.second:
        return {
          text: "Miary dodatkowe:",
          items: filterTypesByTier(
            filterPlanogramTypes(SELL_SECOND_CHART_DATA_TYPES, isPowerUser),
            tier
          )
        };
      default:
        return base;
    }
  }

  if (isThisPage(pathname, REPORTS_FULL_PATHS.SHARES_PATH)) {
    switch (level) {
      case MegaDropdownLevels.first:
        return {
          text: "Miary podstawowe:",
          items: SHARES_FIRST_CHART_DATA_TYPES
        };
      case MegaDropdownLevels.second:
        return {
          text: "Miary dodatkowe:",
          items: filterTypesByTier(
            filterPlanogramTypes(SHARES_SECOND_CHART_DATA_TYPES, isPowerUser),
            tier
          )
        };
      default:
        return base;
    }
  }

  if (isThisPage(pathname, REPORTS_FULL_PATHS.DYNAMICS_PATH)) {
    switch (level) {
      case MegaDropdownLevels.first:
        return {
          text: "Miary podstawowe:",
          items: DYNAMICS_FIRST_CHART_DATA_TYPES
        };
      case MegaDropdownLevels.second:
        return {
          text: "Miary dodatkowe:",
          items: filterTypesByTier(
            filterPlanogramTypes(DYNAMICS_SECOND_CHART_DATA_TYPES, isPowerUser),
            tier
          )
        };
      default:
        return base;
    }
  }

  if (isThisPage(pathname, REPORTS_FULL_PATHS.RECEIPTS_PATH)) {
    switch (level) {
      case MegaDropdownLevels.first:
        return {
          text: "Miary podstawowe:",
          items: RECEIPTS_FIRST_CHART_DATA_TYPES
        };
      case MegaDropdownLevels.second:
        return {
          text: "Miary dodatkowe:",
          items: filterTypesByTier(
            filterPlanogramTypes(RECEIPTS_SECOND_CHART_DATA_TYPES, isPowerUser),
            tier
          )
        };
      default:
        return base;
    }
  }

  if (isThisPage(pathname, REPORTS_FULL_PATHS.SEGMENTS_PATH)) {
    switch (level) {
      case MegaDropdownLevels.first:
        return {
          text: "Miary podstawowe:",
          items: SEGMENTS_FIRST_CHART_DATA_TYPES
        };
      case MegaDropdownLevels.second:
        return {
          text: "Miary dodatkowe:",
          items: filterTypesByTier(
            filterPlanogramTypes(SEGMENTS_SECOND_CHART_DATA_TYPES, isPowerUser),
            tier
          )
        };
      default:
        return base;
    }
  }

  if (isThisPage(pathname, REPORTS_FULL_PATHS.LOYALTY_PATH)) {
    switch (level) {
      case MegaDropdownLevels.first:
        return {
          text: "Miary podstawowe:",
          items: filterPlanogramTypes(LOYALTY_ABSOLUTE_DATA_TYPES, isPowerUser)
        };
      case MegaDropdownLevels.second:
        return {
          text: "Miary podstawowe %:",
          items: filterPlanogramTypes(LOYALTY_PERCENT_DATA_TYPES, isPowerUser)
        };
      case MegaDropdownLevels.third:
        return {
          text: "Miary dodatkowe:",
          items: filterPlanogramTypes(
            LOYALTY_OTHER_CHART_DATA_TYPES,
            isPowerUser
          )
        };
      default:
        return base;
    }
  }

  if (isThisPage(pathname, REPORTS_FULL_PATHS.LOGISTICS_PATH)) {
    switch (level) {
      case MegaDropdownLevels.first:
        return {
          text: "Zapas:",
          items: LOGISTICS_SUPPLY_CHART_DATA_TYPES
        };
      case MegaDropdownLevels.second:
        return {
          text: "WPZ 7:",
          items: LOGISTICS_WPZ7_CHART_DATA_TYPES
        };
      case MegaDropdownLevels.third:
        return {
          text: "WPZ 30:",
          items: LOGISTICS_WPZ30_CHART_DATA_TYPES
        };
      case MegaDropdownLevels.fourth:
        return {
          text: "WPZ 90:",
          items: LOGISTICS_WPZ90_CHART_DATA_TYPES
        };
      case MegaDropdownLevels.fifth:
        return {
          text: "Liczba sklepów:",
          items: filterPlanogramTypes(
            LOGISTICS_SHOPS_CHART_DATA_TYPES,
            isPowerUser
          )
        };
      default:
        return base;
    }
  }

  return base;
};
