import { ParsedQuery } from "query-string";

import { QP } from "utils/defaultQueryParams";

export const adjustStoreTypes = (
  params: ParsedQuery<string | string[]>,
  defaultQueryParams: ParsedQuery<string | string[]>
) => {
  const isRegionAndStoreTypesSelected =
    (params[QP.VOIVODESHIPS]?.length || params[QP.COUNTIES]?.length) &&
    (params[QP.STORE_TYPES]?.length || params[QP.STORE_TYPES_META]?.length);

  if (isRegionAndStoreTypesSelected) {
    return {
      ...params,
      store_types: defaultQueryParams[QP.STORE_TYPES],
      store_types_meta: defaultQueryParams[QP.STORE_TYPES_META]
    };
  }

  return params;
};
