import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";

import { TooltipContent } from "api/types";
import cn from "classnames";

import {
  Button,
  NiceScrollbar,
  PromotionContent,
  SidebarData,
  SpinnerLoader,
  Text
} from "components/atoms";
import { ChevronLeft, ChevronRight, TooltipIcon } from "components/atoms/Icon";
import cardStyles from "components/molecules/Card/card.module.scss";
import {
  useFetchSidebarInsights,
  useSidebarInsightsAccess
} from "pages/Insights/hooks/useSidebarInsights";
import { InsightsDetails } from "pages/Insights/partials/InsightsDetails/InsightsDetails";
import { SidebarInsights } from "pages/Insights/partials/SidebarInsights/SidebarInsights";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { isSidebarMetricEnabledSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { updateChartSidebarWidth } from "store/actions/appActions";
import { chartSidebarWidthSelector } from "store/selectors/appSelectors";
import {
  activeInsightQPSelector,
  chartPeriodSelector
} from "store/selectors/routerSelectors";
import { ICON_SIZES, PERIOD_TYPE } from "utils";
import { pushSidebarEvent } from "utils/googleTagManager/dataLayer";
import { Nullable } from "utils/types";

import { ShowSidebarChange } from "../ShowSidebarChange/ShowSidebarChange";
import { SidebarMetricSelect } from "../SidebarMetricSelect/SidebarMetricSelect";
import { SidebarTour } from "../Tour/components/SidebarTour";
import s from "./chartSidebar.module.scss";
import { ResizeHandle } from "./ResizeHandle";
import {
  getSidebarInsightsCount,
  SIDEBAR_CONFIG,
  TabItem,
  useSegmentsSidebarOpenStatus,
  useSidebarPromotions,
  useSidebarState,
  useSidebarTabs
} from "./utils";

const SidebarHeader = ({ data }: { data: Nullable<TooltipContent> }) => {
  const isSidebarMetricEnabled = useSelector(isSidebarMetricEnabledSelector);
  const { both: isBothDatasets } = useCurrentDataset();

  return (
    <>
      <Text className={s.header} testId="main-sidebar-header">
        {data?.header || ""}
      </Text>
      <div className={s.options}>
        {isSidebarMetricEnabled && <SidebarMetricSelect />}
        {isBothDatasets && <ShowSidebarChange />}
      </div>
    </>
  );
};

const SidebarPreview = () => (
  <div className={s.chartSidebarPreviewWrapper}>
    <Text className={s.previewHeader}>Podgląd</Text>
    {[
      "Najedź myszą na wykres, aby zobaczyć szczegóły",
      "Kliknij na wykres, aby przypiąć podgląd danych na jeden dzień"
    ].map(text => (
      <div key={text} className={s.previewInfoWrapper}>
        <TooltipIcon className={s.tooltipIcon} />
        <Text className={s.previewText}>{text}</Text>
      </div>
    ))}
  </div>
);

const SidebarTabs = ({
  data,
  promotionsInfo,
  insightsCount,
  tabs,
  activeTabId,
  onTabChange
}: {
  data: Nullable<TooltipContent>;
  promotionsInfo: string[];
  insightsCount: number;
  tabs: TabItem[];
  activeTabId: TabItem["id"];
  onTabChange: (tab: TabItem) => void;
}) => {
  return (
    <div className={s.tabsWrapper}>
      {tabs.length > 0 && (
        <ul className={s.tabsList}>
          {tabs.map(tab => (
            <li key={tab.id}>
              <button
                className={cn(s.tabsButton, {
                  [s.active]: tab.id === activeTabId
                })}
                type="button"
                data-testid={`chart-sidebar-tab-${tab.label}`}
                disabled={tab.disabled}
                onClick={() => onTabChange(tab)}
              >
                <span>{tab.label}</span>
                {tab.id === "insights" && (
                  <span className={s.tabsBadge}>{insightsCount}</span>
                )}
              </button>
            </li>
          ))}
        </ul>
      )}
      <div className={s.tabsContent}>
        <NiceScrollbar>
          {activeTabId === "data" && <SidebarData sidebarData={data} />}
          {activeTabId === "promotions" && (
            <PromotionContent sidebarData={data} info={promotionsInfo} />
          )}
          {activeTabId === "insights" && <SidebarInsights />}
        </NiceScrollbar>
      </div>
    </div>
  );
};

export const ChartSidebar = ({
  data,
  isLoading
}: {
  data: Nullable<TooltipContent>;
  isLoading: boolean;
}) => {
  const [isSidebarBorderClicked, setSidebarBorderClicked] = useState(false);
  const dispatch = useDispatch();
  const period = useSelector(chartPeriodSelector);
  const chartSidebarWidth = useSelector(chartSidebarWidthSelector);
  const activeInsightQP = useSelector(activeInsightQPSelector);
  const { data: insights } = useFetchSidebarInsights();
  const promotions = useSidebarPromotions(data);
  const areInsightsEnabled = useSidebarInsightsAccess();
  const sidebarState = useSidebarState();
  const sidebarTabs = useSidebarTabs({
    showPromotionsTab: !promotions.isDisabled,
    showInsightsTab: areInsightsEnabled,
    hasSidebarDate: Boolean(data?.date)
  });

  const activeInsightIndex = insights
    ? insights.findIndex(insight => insight.insightId === activeInsightQP)
    : -1;
  const showInsightDetails = areInsightsEnabled && activeInsightIndex > -1;

  const showContent =
    sidebarTabs.items.filter(tab => !tab.disabled).length > 0 ||
    period === PERIOD_TYPE.RANKING;

  useSegmentsSidebarOpenStatus();

  return (
    <>
      <Rnd
        className={s.chartSidebarBox}
        enableResizing={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false
        }}
        disableDragging
        default={{ width: chartSidebarWidth, height: "auto", x: 0, y: 0 }}
        minWidth={sidebarState.isOpen ? SIDEBAR_CONFIG.MIN_WIDTH : "0%"}
        maxWidth={sidebarState.isOpen ? SIDEBAR_CONFIG.MAX_WIDTH : "0%"}
        resizeHandleComponent={{
          left: <ResizeHandle isSidebarBorderClicked={isSidebarBorderClicked} />
        }}
        size={{ width: chartSidebarWidth, height: "auto" }}
        onResizeStop={(e, dir, ref) => {
          if (chartSidebarWidth === ref.style.width) return;

          dispatch(updateChartSidebarWidth(ref.style.width));
          pushSidebarEvent("size change");
          setSidebarBorderClicked(false);
        }}
        onResizeStart={() => {
          setSidebarBorderClicked(true);
        }}
      >
        {isLoading && (
          <SpinnerLoader
            className={{
              wrapper: cardStyles.loader
            }}
          />
        )}
        <Button
          className={cn(s.button, { [s.buttonOpen]: sidebarState.isOpen })}
          onClick={sidebarState.toggle}
          data-testid="chart-sidebar-toggle-button"
        >
          {sidebarState.isOpen ? (
            <ChevronRight size={ICON_SIZES.EXTRA_LARGE} />
          ) : (
            <ChevronLeft size={ICON_SIZES.EXTRA_LARGE} />
          )}
        </Button>
        <div
          className={cn(s.chartSidebarAbsoluteWrapper, {
            [s.chartSidebarAbsoluteWrapperClosed]: !sidebarState.isOpen
          })}
          data-testid="chart-sidebar-content-wrapper"
        >
          {showContent ? (
            <>
              <SidebarHeader data={data} />
              <SidebarTabs
                data={data}
                promotionsInfo={promotions.info}
                insightsCount={getSidebarInsightsCount(insights || [])}
                tabs={sidebarTabs.items}
                activeTabId={sidebarTabs.activeId}
                onTabChange={sidebarTabs.onChange}
              />
            </>
          ) : (
            <SidebarPreview />
          )}
        </div>
      </Rnd>
      <SidebarTour />
      {showInsightDetails && (
        <InsightsDetails
          index={activeInsightIndex}
          data={insights![activeInsightIndex]}
          previousId={insights![activeInsightIndex - 1]?.insightId || ""}
          nextId={insights![activeInsightIndex + 1]?.insightId || ""}
        />
      )}
    </>
  );
};
