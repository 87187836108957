import { isProductWithdrawn } from "utils";

import { ProductItem } from "../reducers/sweetStateHooks/useProduct";

export const sortProductsByWithdrawness = (productItems: ProductItem[]) => {
  const sortedItems = productItems.sort((a, b) => {
    const aIsWithdrawn = isProductWithdrawn(a.label);
    const bIsWithdrawn = isProductWithdrawn(b.label);

    if (aIsWithdrawn && !bIsWithdrawn) {
      return 1;
    }

    if (!aIsWithdrawn && bIsWithdrawn) {
      return -1;
    }

    return 0;
  });

  return sortedItems;
};
