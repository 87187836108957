import { values } from "ramda";

import { PERIOD_TYPE } from "./constants";

export const isValidPeriod = (str: string) =>
  Object.values(PERIOD_TYPE).some(
    period => period.toLowerCase() === str.toLowerCase()
  );

export const validatePeriod = (str: string) => {
  const validPeriod = values(PERIOD_TYPE).find(
    period => period.toLowerCase() === str.toLowerCase()
  );

  if (validPeriod) return validPeriod;

  throw new Error(`${str} is not valid period name`);
};
