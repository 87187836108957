import { format, subDays } from "date-fns";
import { ParsedQuery } from "query-string";

import {
  CalendarOptionKey,
  mapOptionToItem
} from "components/molecules/DateDropdownMenu/PresetsSection/utils";
import {
  getDateRangeMonthBoundaries,
  TIERS_WITH_MONTH_PICKER
} from "components/organisms/DateDropdown/utils";
import {
  isDateMonthBoundaries,
  isDateQueryParamsRelationValid,
  isDateQueryParamValid
} from "pages/Reports/utils/queryParams/appendRequiredParams";
import { newDateByTimezone } from "store/utils";
import { BOOL_STRING_VALUES, DEFAULT_DATE_FORMAT } from "utils";
import { QP } from "utils/defaultQueryParams";
import { Tier } from "utils/types";

export const adjustDateParams = (
  params: ParsedQuery<string | string[]>,
  defaultParams: ParsedQuery<string | string[]>,
  minDate: Date,
  iteration: number
) => {
  let { date_from, date_to, compare_date_from, compare_date_to } = params;

  const isDynamicPeriodChecked =
    String(params[QP.DYNAMIC_PERIOD_CHECKED]).toLowerCase() ===
    BOOL_STRING_VALUES.TRUE;
  const dynamicPeriod = String(
    params[QP.DYNAMIC_PERIOD]
  ).toUpperCase() as CalendarOptionKey;
  const maxDate = subDays(newDateByTimezone(), 1);

  // dynamic period should apply changes only when user comes from url
  if (isDynamicPeriodChecked && iteration === 1) {
    const presetOption = mapOptionToItem(dynamicPeriod, minDate, maxDate);
    date_from = format(presetOption.dateFrom, DEFAULT_DATE_FORMAT);
    date_to = format(presetOption.dateTo, DEFAULT_DATE_FORMAT);
    compare_date_from = "";
    compare_date_to = "";
  }

  const dateFrom = newDateByTimezone(String(date_from));
  const dateTo = newDateByTimezone(String(date_to));
  const tier = Number(params[QP.TIER]) as Tier;

  const areDatesValid = [date_from, date_to].every(date =>
    isDateQueryParamValid(date as string)
  );
  const areDatesRelationValid = isDateQueryParamsRelationValid(
    String(date_from),
    String(date_to)
  );
  const isValidationSuccessful = areDatesValid && areDatesRelationValid;

  const shouldFixDatesForTier =
    isValidationSuccessful &&
    TIERS_WITH_MONTH_PICKER.includes(tier) &&
    !isDateMonthBoundaries(dateFrom, dateTo, tier);

  // set start and end of selected date range for tiers lower or equal 2
  if (shouldFixDatesForTier) {
    const {
      dateFrom: monthBoundaryStart,
      dateTo: monthBoundaryEnd
    } = getDateRangeMonthBoundaries(dateFrom, dateTo);

    return {
      ...params,
      date_from: monthBoundaryStart,
      date_to: monthBoundaryEnd,
      compare_date_from,
      compare_date_to
    };
  }

  return {
    ...params,
    date_from: isValidationSuccessful ? date_from : defaultParams[QP.DATE_FROM],
    date_to: isValidationSuccessful ? date_to : defaultParams[QP.DATE_TO],
    compare_date_from,
    compare_date_to
  };
};
