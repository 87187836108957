import { ReactNode } from "react";
import { Cell } from "react-table";

import { Text } from "components/atoms";

interface RegularCellProps<Data> {
  cell: Cell<Data>;
  className?: { wrapper?: string; base?: string };
}

interface ContentCellProps {
  className?: string;
  children: ReactNode;
}

//extends keyword must be included https://github.com/Microsoft/TypeScript/issues/4922
export const TableCell = <Data extends {}>({
  cell,
  className = {}
}: RegularCellProps<Data>) => {
  return (
    <div className={className.wrapper}>
      <Text className={className.base}>{cell.value}</Text>
    </div>
  );
};

export const ContentTableCell = ({
  className = "",
  children
}: ContentCellProps) => {
  return <div className={className}>{children}</div>;
};
