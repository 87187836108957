import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { AppState } from "store";

import { PERIOD_TYPE } from "./constants";

export type Keys<T extends object> = keyof T;
export type Values<T extends object> = T[Keys<T>];

export type Timeline = "day" | "week" | "month" | "year" | "total";

export type Thunk<T extends Action> = ThunkAction<void, AppState, undefined, T>;

export type Nullable<T> = T | null;

export type BaseSuffixes = "_change" | "_change_percentage" | "_last_year";
export type OfflineSuffixes = "_offline" | "_offline_change" | "_offline_change_percentage" | "_offline_last_year";
export type OnlineSuffixes = "_online" | "_online_change" | "_online_change_percentage" | "_online_last_year";
export type OnlineShareSuffixes = "_online_share" | "_online_share_change" | "_online_share_change_percentage" | "_online_share_last_year";
type AllSuffixes = BaseSuffixes | OfflineSuffixes | OnlineSuffixes | OnlineShareSuffixes;

export type WithSuffixes<T, S extends AllSuffixes[]> = {
  // tslint:disable-next-line: prettier - fixed in 2.2.0, we're using 1.19.1
  [K in keyof T as `${string & K}${S[number]}`]: T[K];
} &
  T;

export type RankingItemApi = {
  material_id: number;
  material_name: string;
  type: string;
  vendor_name: string;
  distribution_less_than_5: boolean;
};

type RankingSuffixes = "offline" | "online" | "online_share";

export type RankingWithSuffix<T extends string, U extends RankingSuffixes[]> = {
  [K in T as `top_${T}`]: {
    [P in `${T}` | `${T}_rank`]: number;
  } & RankingItemApi
} & {
  [K in U[number] as `top_${T}_${K}`]: {
    [P in `${T}_${K}` | `${T}_${K}_rank`]: number;
  } & RankingItemApi
};

type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S;

export type CamelCased<T> = {
  [K in keyof T as SnakeToCamelCase<string & K>]: T[K];
};

export type Periods = Values<typeof PERIOD_TYPE>;

export type Tier = 0 | 1 | 2 | 3 | 4;

export const isTier = (value: number): value is Tier => {
  return [0, 1, 2, 3, 4].includes(value);
};

export type ChangeTypeProperties<Type, NewPropertiesType> = {
  [key in keyof Type]: NewPropertiesType;
};

export type WeekStartDay = 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;

export type UnionToIntersection<U> = (U extends any
? (k: U) => void
: never) extends (k: infer I) => void
  ? I
  : never;
