import * as React from "react";

import cn from "classnames";

import s from "./toggleButtonList.module.scss";

interface Props {
  children: React.ReactNode;
  id?: string;
  className?: string;
  testId?: string;
}

export const ToggleButtonList: React.FC<Props> = ({
  id,
  children,
  className = "",
  testId
}) => (
  <ul
    data-testid={testId}
    id={id}
    className={cn(s.toggleButtonList, className)}
  >
    {children}
  </ul>
);
