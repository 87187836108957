import { ChartData } from "components/D3/types";
import { CHART_DATA_TYPE } from "utils";

export const getSegmentsChartType = (data: ChartData) => {
  const { showBarChart, leftChart } = data;

  if (!showBarChart) {
    return "line-chart";
  }

  if (leftChart?.dataType === CHART_DATA_TYPE.NONE) {
    return "line-on-bar-chart";
  }

  return "bar-chart";
};
