import {
  groupBy,
  indexBy,
  map,
  merge,
  mergeWith,
  pipe,
  prop,
  reduce,
  values
} from "ramda";

import { FetchedPromotion } from "pages/Reports/redux/reducers/promotionsReducer";
import {
  Dynamics,
  DynamicsTableRow
} from "pages/Reports/sections/Dynamics/redux/types";
import {
  Logistics,
  LogisticsTableRow
} from "pages/Reports/sections/Logistics/types";
import { ReceiptsTableRow } from "pages/Reports/sections/Receipts/ReceiptsTableSection";
import { Receipts } from "pages/Reports/sections/Receipts/redux/types";
import { getPromotionsColumn } from "pages/Reports/sections/Sell/redux/utils";
import { SharesNormalizedResponse } from "pages/Reports/sections/Shares/redux/types";
import { formatNumber, NUMBER_TYPE } from "pages/Reports/utils/formatNumber";
import { generateTableColumnId } from "pages/Reports/utils/generateTableColumnId";
import { newDateByTimezone } from "store/utils";
import { isProductWithdrawn } from "utils";
import { Periods } from "utils/types";

// https://stackoverflow.com/a/51218240
const mergeRows: <T extends object>(rows: T[][]) => T[] = pipe(
  // @ts-ignore - https://github.com/ramda/ramda/issues/2746
  map(indexBy(prop("period"))),
  reduce(mergeWith(merge), {}),
  values
);

export const getTableDataFromShares = (
  shares: SharesNormalizedResponse[],
  promotions: FetchedPromotion[],
  period: Periods
) => {
  const rows = shares.map(({ name, timeline }) => {
    const idPrefix = generateTableColumnId(name);

    return timeline.map(
      ({
        date,
        worthShare,
        quantityShare,
        avgShopsCount,
        distributionRange
      }) => ({
        period: date,
        [`${idPrefix}-worthShare`]: formatNumber(
          worthShare,
          NUMBER_TYPE.GROUPED_PRICE
        ),
        [`${idPrefix}-quantityShare`]: formatNumber(
          quantityShare,
          NUMBER_TYPE.GROUPED_PRICE
        ),
        [`${idPrefix}-avgShopsCount`]: formatNumber(avgShopsCount),
        [`${idPrefix}-distributionRange`]: formatNumber(
          distributionRange,
          NUMBER_TYPE.GROUPED_PRICE
        ),
        promotions: getPromotionsColumn(
          newDateByTimezone(date),
          promotions,
          period
        )
      })
    );
  });

  return mergeRows(rows);
};

export const getTableDataFromDynamics = (
  dynamics: Dynamics[],
  promotions: FetchedPromotion[],
  period: Periods
): DynamicsTableRow[] => {
  const rows = dynamics.map(({ name, timeline }) => {
    const idPrefix = generateTableColumnId(name);

    return timeline.map(
      ({
        date,
        worthChange,
        quantityChange,
        avgShopsCount,
        distributionRange
      }) => ({
        period: date,
        [`${idPrefix}-worthChange`]: formatNumber(
          worthChange,
          NUMBER_TYPE.GROUPED_PRICE
        ),
        [`${idPrefix}-quantityChange`]: formatNumber(
          quantityChange,
          NUMBER_TYPE.GROUPED_PRICE
        ),
        [`${idPrefix}-avgShopsCount`]: formatNumber(avgShopsCount),
        [`${idPrefix}-distributionRange`]: formatNumber(
          distributionRange,
          NUMBER_TYPE.GROUPED_PRICE
        ),
        promotions: getPromotionsColumn(
          newDateByTimezone(date),
          promotions,
          period
        )
      })
    );
  });

  return mergeRows(rows);
};

export const getTableDataFromReceipts = (
  receipts: Receipts[],
  promotions: FetchedPromotion[],
  period: Periods
): ReceiptsTableRow[] => {
  const rows = receipts.map(({ name, timeline }) => {
    const idPrefix = generateTableColumnId(name);

    return timeline.map(
      ({
        date,
        shareInReceiptsTotalShop,
        shareInReceiptsCategory,
        itemsPerReceipt,
        worthPerReceipt,
        receiptsQuantity,
        receiptsPerShop,
        avgShopsCount,
        distributionRange
      }) => ({
        period: date,
        [`${idPrefix}-SHARE_IN_RECEIPTS_TOTAL_SHOP`]: formatNumber(
          shareInReceiptsTotalShop,
          shareInReceiptsTotalShop && shareInReceiptsTotalShop < 1
            ? NUMBER_TYPE.PERCENT_FOUR_DECIMALS
            : NUMBER_TYPE.PERCENT
        ),
        [`${idPrefix}-SHARE_IN_RECEIPTS_CATEGORY`]: formatNumber(
          shareInReceiptsCategory,
          shareInReceiptsCategory && shareInReceiptsCategory < 1
            ? NUMBER_TYPE.PERCENT_FOUR_DECIMALS
            : NUMBER_TYPE.PERCENT
        ),
        [`${idPrefix}-ITEMS_PER_RECEIPT`]: formatNumber(
          itemsPerReceipt,
          NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
        ),
        [`${idPrefix}-WORTH_PER_RECEIPT`]: formatNumber(
          worthPerReceipt,
          NUMBER_TYPE.GROUPED_PRICE
        ),
        [`${idPrefix}-RECEIPTS_QUANTITY`]: formatNumber(
          receiptsQuantity,
          NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
        ),
        [`${idPrefix}-RECEIPTS_PER_SHOP`]: formatNumber(
          receiptsPerShop,
          NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
        ),
        [`${idPrefix}-AVG_SHOPS_COUNT`]: formatNumber(avgShopsCount),
        [`${idPrefix}-DISTRIBUTION_RANGE`]: formatNumber(
          distributionRange,
          NUMBER_TYPE.GROUPED_PRICE
        ),
        promotions: getPromotionsColumn(
          newDateByTimezone(date),
          promotions,
          period
        )
      })
    );
  });

  return mergeRows(rows);
};

export const getTableDataFromLogistics = (
  logistics: Logistics[]
): [LogisticsTableRow[], LogisticsTableRow[]] => {
  const rows = logistics.map(({ name, ean, segment, timeline }) => {
    const today = timeline.slice(-1)[0];

    return {
      name,
      ean,
      segment,
      totalSupply: formatNumber(
        today.totalSupply,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      shopsSupply: formatNumber(
        today.shopsSupply,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      kmSupply: formatNumber(
        today.kmSupply,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzShops7: formatNumber(
        today.wpzShops7,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzShops30: formatNumber(
        today.wpzShops30,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzShops90: formatNumber(
        today.wpzShops90,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      incomingSupply: formatNumber(
        today.incomingSupply,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      clTotalSupply: formatNumber(
        today.clTotalSupply,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzClTotal7: formatNumber(
        today.wpzClTotal7,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzKm7: formatNumber(today.wpzKm7, NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE),
      wpzKm30: formatNumber(today.wpzKm30, NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE),
      wpzKm90: formatNumber(today.wpzKm90, NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE),
      wpzClTotal30: formatNumber(
        today.wpzClTotal30,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzClTotal90: formatNumber(
        today.wpzClTotal90,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      bedSupply: formatNumber(
        today.bedSupply,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzBed7: formatNumber(today.wpzBed7, NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE),
      wpzBed30: formatNumber(
        today.wpzBed30,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzBed90: formatNumber(
        today.wpzBed90,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      nadSupply: formatNumber(
        today.nadSupply,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzNad7: formatNumber(today.wpzNad7, NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE),
      wpzNad30: formatNumber(
        today.wpzNad30,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzNad90: formatNumber(
        today.wpzNad90,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      pleSupply: formatNumber(
        today.pleSupply,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzPle7: formatNumber(today.wpzPle7, NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE),
      wpzPle30: formatNumber(
        today.wpzPle30,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzPle90: formatNumber(
        today.wpzPle90,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      szaSupply: formatNumber(
        today.szaSupply,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzSza7: formatNumber(today.wpzSza7, NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE),
      wpzSza30: formatNumber(
        today.wpzSza30,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzSza90: formatNumber(
        today.wpzSza90,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      tychSupply: formatNumber(
        today.tychSupply,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzTych7: formatNumber(
        today.wpzTych7,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzTych30: formatNumber(
        today.wpzTych30,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzTych90: formatNumber(
        today.wpzTych90,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      tynSupply: formatNumber(
        today.tynSupply,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzTyn7: formatNumber(today.wpzTyn7, NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE),
      wpzTyn30: formatNumber(
        today.wpzTyn30,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzTyn90: formatNumber(
        today.wpzTyn90,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      radzSupply: formatNumber(
        today.radzSupply,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzRadz7: formatNumber(
        today.wpzRadz7,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzRadz30: formatNumber(
        today.wpzRadz30,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      wpzRadz90: formatNumber(
        today.wpzRadz90,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      avgShopsCount: formatNumber(
        today.avgShopsCount,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      ),
      avgPlanogramShopsCount: formatNumber(
        today.avgPlanogramShopsCount,
        NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
      )
    };
  });

  const splitByActiveness = groupBy((data: LogisticsTableRow) =>
    isProductWithdrawn(data.name) ? "unactive" : "active"
  );

  const splittedData = splitByActiveness(rows);

  return [splittedData.active || [], splittedData.unactive || []];
};
