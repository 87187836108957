import { SVGAttributes } from "react";

import { Values } from "utils/types";

type Linecap = Values<Pick<SVGAttributes<SVGPathElement>, "strokeLinecap">>;

export type LineShape = [string, Linecap];

export const PRIMARY_RIGHT = ["5,5,1,5", "square"] as LineShape;
export const SECONDARY_LEFT = ["5,5", "square"] as LineShape;
export const SECONDARY_RIGHT = ["1,5", "square"] as LineShape;
export const REFERENCE_LEFT = ["0", "round"] as LineShape;
export const REFERENCE_RIGHT = ["5", "round"] as LineShape;
