import * as React from "react";

import { Button, Text } from "components/atoms";
import { TEXT_SIZES, TEXT_WEIGHTS } from "utils";

import s from "./filterErrorMsg.module.scss";

type Props = {
  errorMsg: string;
  label?: string;
  callback: () => void;
};

export const FilterErrorMsg = ({ errorMsg, label = "", callback }: Props) => (
  <>
    {label && (
      <Text
        size={TEXT_SIZES.SMALL}
        className={s.label}
        weight={TEXT_WEIGHTS.BOLD}
      >
        {label}
      </Text>
    )}

    <div className={s.errorContainer}>
      <Text className={s.errorText}>
        {errorMsg || "Brak dostępu do danych"}
        <br />
        <Button className={s.button} type="button" onClick={callback}>
          Spróbuj Ponownie
        </Button>
      </Text>
    </div>
  </>
);
