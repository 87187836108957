import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const Close: React.FC<Props> = ({
  className,
  color,
  size = ICON_SIZES.NORMAL
}) => {
  const iconSize = SIZES[size];
  return (
    <svg
      className={className}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 13 13"
      version="1.1"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill={color || "currentColor"}
        fillRule="evenodd"
      >
        <polygon
          fill={color || "currentColor"}
          fillRule="nonzero"
          points="10.855351 0 6 4.855351 1.144649 0 0 1.144649 4.855351 6 0 10.855351 1.144649 12 6 7.144649 10.855351 12 12 10.855351 7.144649 6 12 1.144649"
        ></polygon>
      </g>
    </svg>
  );
};
