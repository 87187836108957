import { useQueries } from "react-query";
import { useLocation } from "react-router-dom";

import { QueryKey } from "api/consts";
import { segmentsServices } from "api/segments/services/segments.services";
import { useFetchParams } from "api/utils";

import { REPORTS_PATHS } from "pages/Reports/utils";
import { isThisPage } from "utils";

export const useGetSegments = () => {
  const { queryKeys, isG1Selected } = useFetchParams();

  const { pathname } = useLocation();
  const isSegmentsPage = isThisPage(pathname, REPORTS_PATHS.SEGMENTS);

  const queries = useQueries(
    queryKeys.map(({ period, query }) => {
      return {
        queryKey: [QueryKey.SEGMENTS_DATA, query],
        queryFn: () => segmentsServices.fetchSegments(query, period),
        enabled: isG1Selected && isSegmentsPage,
        retry: false
      };
    })
  );

  const isLoading = queries.some(query => query.isLoading);
  const isError = queries.some(query => query.isError);
  const isIdle = queries.some(query => query.isIdle);

  const returnValue = {
    isLoading,
    isError,
    isIdle,
    queriesData: queries.map(query => query.data)
  };

  return returnValue;
};
