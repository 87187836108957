import { max, min } from "d3";
import { flatten } from "ramda";

import { ChartTimePoint } from "components/D3/types";
import { findMaxMinValueForGroupedBarChart } from "pages/Reports/utils";

import { getTimelineDiffs } from "../partials/Chart/StandardChart/utils/getTimelineDiffs";

interface Options {
  isRightChartData?: boolean;
  isGroupedBarChart?: boolean;
  isIncrementalComboChart?: boolean;
}

export const chartRange = <
  T extends {
    timeline: ChartTimePoint[];
    lastYearTimeline?: ChartTimePoint[];
    secondaryTimeline?: ChartTimePoint[];
    secondaryLastYearTimeline?: ChartTimePoint[];
  }
>(
  chartData: T[],
  {
    isRightChartData = false,
    isGroupedBarChart = false,
    isIncrementalComboChart = false
  }: Options = {}
) => {
  const timeline = chartData.map(chart => chart.timeline);
  const lastYearTimeline = chartData.map(chart => chart.lastYearTimeline || []);

  const flatTimeline = flatten(timeline);
  const flatLastYearTimeline = flatten(lastYearTimeline);
  const flatSecondaryTimeline = chartData.flatMap(
    chart => chart.secondaryTimeline || []
  );
  const flatSecondaryLastYearTimeline = chartData.flatMap(
    chart => chart.secondaryLastYearTimeline || []
  );

  const wholeFlatTimeline: ChartTimePoint[] = flatten([
    timeline,
    lastYearTimeline,
    flatSecondaryTimeline,
    flatSecondaryLastYearTimeline
  ]).filter(el => el);

  const flatValueY = flatten(wholeFlatTimeline.map(chart => chart.valueY || 0));

  if (!isRightChartData && isGroupedBarChart) {
    const { max, min } = findMaxMinValueForGroupedBarChart(flatTimeline);
    const {
      max: maxLastYear,
      min: minLastYear
    } = findMaxMinValueForGroupedBarChart(flatLastYearTimeline);

    const maxVal = Math.max(max, maxLastYear);
    const minVal = Math.min(min, minLastYear);

    return {
      maxValue: maxVal || 0,
      minValue: minVal || 0
    };
  }

  if (
    !isRightChartData &&
    lastYearTimeline[0]?.length > 0 &&
    isIncrementalComboChart
  ) {
    const diffs = getTimelineDiffs(timeline[0], lastYearTimeline[0]);
    // round hundredths (0.01) to decilams (0.1)
    const rounded = diffs.map(value => {
      const abs = Math.abs(value);
      if (abs > 0 && abs < 0.1) return value < 0 ? -0.1 : 0.1;
      return value;
    });
    let minValue = Math.min(...rounded);
    let maxValue = Math.max(...rounded);

    if (minValue > 0 && maxValue > 0) minValue = 0;
    if (minValue < 0 && maxValue < 0) maxValue = 0;

    // fix case when max is 0 and min is decimal
    if (maxValue === 0 && minValue < 0 && minValue > -1) maxValue = 0.1;

    return { maxValue, minValue };
  }

  const minVal = min(flatValueY) || 0;
  const maxVal = max(flatValueY) || 0;

  if (minVal === 0 && maxVal === 0) {
    return { minValue: 0, maxValue: 1 };
  }

  if (minVal < 0 && maxVal < 0) {
    return { minValue: minVal, maxValue: 0 };
  }

  if (minVal > 0 && maxVal > 0) {
    return { minValue: 0, maxValue: maxVal };
  }

  return {
    maxValue: max(flatValueY) || 0,
    minValue: min(flatValueY) || 0
  };
};
