import * as React from "react";

import cn from "classnames";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const ArrowUp = ({
  className,
  color,
  size = ICON_SIZES.NORMAL
}: Props) => {
  const currentSize = SIZES[size];
  return (
    <svg
      className={cn(className)}
      width={currentSize}
      height={currentSize}
      viewBox="0 0 12 12"
      version="1.1"
    >
      <g
        id="icons/arrow-trend-up"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M5.2164758,0.292786454 L0,4.99978645 L1.56704839,6.41378645 L4.8917621,3.41378645 L4.8917621,11.9997865 L7.1082379,11.9997865 L7.1082379,3.41378645 L10.4329516,6.41378645 L12,4.99978645 L6.7835242,0.292786454 C6.35075746,-0.0975954846 5.64924254,-0.0975954846 5.2164758,0.292786454 Z"
          id="Path"
          fill={color || "currentColor"}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};
