import { LoyaltySummary, LoyaltySummaryApi } from "pages/Reports/types/summary";
import { standarizeTimepointValue } from "utils";

export const normalizeLoyaltySummary = (
  summary: LoyaltySummaryApi
): LoyaltySummary => ({
  loyaltyNewClientsCount: standarizeTimepointValue(
    summary?.loyalty_new_clients_count
  ),
  loyaltyNewClientsCountChangePercentage: standarizeTimepointValue(
    summary?.loyalty_new_clients_count_change_percentage
  ),
  loyaltyNewClientsCountLastYear: standarizeTimepointValue(
    summary?.loyalty_new_clients_count_last_year
  ),

  loyaltyNewClientsPercent: standarizeTimepointValue(
    summary?.loyalty_new_clients_percent
  ),
  loyaltyNewClientsPercentChange: standarizeTimepointValue(
    summary?.loyalty_new_clients_percent_change
  ),
  loyaltyNewClientsPercentLastYear: standarizeTimepointValue(
    summary?.loyalty_new_clients_percent_last_year
  ),

  loyaltyBackCount: standarizeTimepointValue(summary?.loyalty_back_count),
  loyaltyBackCountChangePercentage: standarizeTimepointValue(
    summary?.loyalty_back_count_change_percentage
  ),
  loyaltyBackCountLastYear: standarizeTimepointValue(
    summary?.loyalty_back_count_last_year
  ),

  loyaltyBackPercent: standarizeTimepointValue(summary?.loyalty_back_percent),
  loyaltyBackPercentChange: standarizeTimepointValue(
    summary?.loyalty_back_percent_change
  ),
  loyaltyBackPercentLastYear: standarizeTimepointValue(
    summary?.loyalty_back_percent_last_year
  ),

  loyaltyBackShare1w: standarizeTimepointValue(summary?.loyalty_back_share_1w),
  loyaltyBackShare1wChange: standarizeTimepointValue(
    summary?.loyalty_back_share_1w_change
  ),
  loyaltyBackShare1wLastYear: standarizeTimepointValue(
    summary?.loyalty_back_share_1w_last_year
  ),

  loyaltyBackShare2w: standarizeTimepointValue(summary?.loyalty_back_share_2w),
  loyaltyBackShare2wChange: standarizeTimepointValue(
    summary?.loyalty_back_share_2w_change
  ),
  loyaltyBackShare2wLastYear: standarizeTimepointValue(
    summary?.loyalty_back_share_2w_last_year
  ),

  loyaltyBackShare4w: standarizeTimepointValue(summary?.loyalty_back_share_4w),
  loyaltyBackShare4wChange: standarizeTimepointValue(
    summary?.loyalty_back_share_4w_change
  ),
  loyaltyBackShare4wLastYear: standarizeTimepointValue(
    summary?.loyalty_back_share_4w_last_year
  ),

  loyaltyBackShare6w: standarizeTimepointValue(summary?.loyalty_back_share_6w),
  loyaltyBackShare6wChange: standarizeTimepointValue(
    summary?.loyalty_back_share_6w_change
  ),
  loyaltyBackShare6wLastYear: standarizeTimepointValue(
    summary?.loyalty_back_share_6w_last_year
  ),

  loyaltyBackShare8w: standarizeTimepointValue(summary?.loyalty_back_share_8w),
  loyaltyBackShare8wChange: standarizeTimepointValue(
    summary?.loyalty_back_share_8w_change
  ),
  loyaltyBackShare8wLastYear: standarizeTimepointValue(
    summary?.loyalty_back_share_8w_last_year
  ),

  loyaltyBackAbsolute1w: standarizeTimepointValue(
    summary?.loyalty_back_absolute_1w
  ),
  loyaltyBackAbsolute1wChangePercentage: standarizeTimepointValue(
    summary?.loyalty_back_absolute_1w_change_percentage
  ),
  loyaltyBackAbsolute1wLastYear: standarizeTimepointValue(
    summary?.loyalty_back_absolute_1w_last_year
  ),

  loyaltyBackAbsolute2w: standarizeTimepointValue(
    summary?.loyalty_back_absolute_2w
  ),
  loyaltyBackAbsolute2wChangePercentage: standarizeTimepointValue(
    summary?.loyalty_back_absolute_2w_change_percentage
  ),
  loyaltyBackAbsolute2wLastYear: standarizeTimepointValue(
    summary?.loyalty_back_absolute_2w_last_year
  ),

  loyaltyBackAbsolute4w: standarizeTimepointValue(
    summary?.loyalty_back_absolute_4w
  ),
  loyaltyBackAbsolute4wChangePercentage: standarizeTimepointValue(
    summary?.loyalty_back_absolute_4w_change_percentage
  ),
  loyaltyBackAbsolute4wLastYear: standarizeTimepointValue(
    summary?.loyalty_back_absolute_4w_last_year
  ),

  loyaltyBackAbsolute6w: standarizeTimepointValue(
    summary?.loyalty_back_absolute_6w
  ),
  loyaltyBackAbsolute6wChangePercentage: standarizeTimepointValue(
    summary?.loyalty_back_absolute_6w_change_percentage
  ),
  loyaltyBackAbsolute6wLastYear: standarizeTimepointValue(
    summary?.loyalty_back_absolute_6w_last_year
  ),

  loyaltyBackAbsolute8w: standarizeTimepointValue(
    summary?.loyalty_back_absolute_8w
  ),
  loyaltyBackAbsolute8wChangePercentage: standarizeTimepointValue(
    summary?.loyalty_back_absolute_8w_change_percentage
  ),
  loyaltyBackAbsolute8wLastYear: standarizeTimepointValue(
    summary?.loyalty_back_absolute_8w_last_year
  ),

  avgShopsCount: standarizeTimepointValue(summary?.avg_shops_count),
  avgShopsCountLastYear: standarizeTimepointValue(
    summary?.avg_shops_count_last_year
  ),
  avgShopsCountChange: standarizeTimepointValue(
    summary?.avg_shops_count_change
  ),

  avgPlanogramShopsCount: standarizeTimepointValue(
    summary?.avg_planogram_shops_count
  ),
  avgPlanogramShopsCountLastYear: standarizeTimepointValue(
    summary?.avg_planogram_shops_count_last_year
  ),
  avgPlanogramShopsCountChange: standarizeTimepointValue(
    summary?.avg_planogram_shops_count_change
  ),

  distributionRange: standarizeTimepointValue(summary?.distribution_range),
  distributionRangeLastYear: standarizeTimepointValue(
    summary?.distribution_range_last_year
  ),
  distributionRangeChange: standarizeTimepointValue(
    summary?.distribution_range_change
  ),

  planogramDistributionRange: standarizeTimepointValue(
    summary?.planogram_distribution_range
  ),
  planogramDistributionRangeLastYear: standarizeTimepointValue(
    summary?.planogram_distribution_range_last_year
  ),
  planogramDistributionRangeChange: standarizeTimepointValue(
    summary?.planogram_distribution_range_change
  )
});
