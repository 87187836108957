import { color as Color, ScaleBand, ScaleLinear } from "d3";

import { COLOR } from "utils/constants";
import { Nullable } from "utils/types";

import { HorizontalChart } from "./utils/getRankingData";

interface Props {
  data: HorizontalChart[];
  scaleX: ScaleLinear<number, number, never>;
  scaleY: ScaleBand<string>;
  tooltip: Nullable<HorizontalChart>;
  onMouse: (tooltip: Nullable<HorizontalChart>) => () => void;
}

export const ChartRects = ({
  data,
  scaleX,
  scaleY,
  tooltip,
  onMouse
}: Props) => (
  <g data-testid="ranking-data-bars">
    {data.map((rect, index) => {
      const color = !tooltip
        ? rect.color
        : tooltip.id === rect.id
        ? rect.color
        : COLOR.UI_15;

      return (
        <g key={`ranking-rect-${index}`}>
          <rect
            x={scaleX(Math.min(0, rect.primaryValue))}
            y={scaleY(String(rect.id))}
            width={Math.abs(scaleX(rect.primaryValue) - scaleX(0))}
            height={scaleY.bandwidth()}
            fill={color}
            onMouseEnter={onMouse(rect)}
            onMouseLeave={onMouse(null)}
          />
          {rect.sharesValue !== null && (
            <rect
              x={Math.abs(
                scaleX(rect.primaryValue * (1 - rect.sharesValue / 100)) -
                  scaleX(0)
              )}
              y={scaleY(String(rect.id))}
              width={Math.abs(
                scaleX(rect.primaryValue * (rect.sharesValue / 100)) - scaleX(0)
              )}
              height={scaleY.bandwidth()}
              fill={Color(color)
                ?.brighter(0.7)
                .toString()}
            />
          )}
          <text
            x={scaleX(Math.max(0, rect.primaryValue)) + 5}
            y={(scaleY(String(rect.id)) || 0) + scaleY.bandwidth() / 2}
            dy="0.32em"
            fontSize="12"
            fill={COLOR.UI_15}
          >
            {rect.sharesValue !== null
              ? `${rect.rectLabel} (${rect.sharesLabel})`
              : rect.rectLabel}
          </text>
        </g>
      );
    })}
  </g>
);
