import { RankingSorting } from "api/ranking/types";
import { omit, pick } from "ramda";

import { SidebarSortingTypes } from "hooks/useSidebarSorting";
import { Section } from "pages/Insights/utils/types";
import { EXPORT_DATA_TYPE } from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";
import { AggregationTypes } from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";
import { SUMMARY_SUB_SWITCH, SUMMARY_SWITCH } from "pages/Reports/utils";
import {
  BOOL_STRING_VALUES,
  CHART_DATA_TYPE,
  CLIENT_TYPES,
  DATASET_TYPES,
  LOGISTICS_DATA_TYPES_PARAMS,
  PERIOD_TYPE
} from "utils";
import {
  CHART_TYPES,
  isLogisticsSegmentsEnabled,
  isMetaSegmentFilterEnabled,
  isReferenceBrandsFilterEnabled
} from "utils/constants";

import {
  DYNAMICS_FIRST_CHART_DATA_TYPES,
  HOURS_FIRST_CHART_DATA_TYPES,
  HOURS_SECOND_CHART_DATA_TYPES,
  LOCATION_FIRST_CHART_DATA_TYPES,
  LOGISTICS_FIRST_CHART_DATA_TYPES,
  LOYALTY_ABSOLUTE_DATA_TYPES,
  MULTIPACK_CHART_DATA_TYPES,
  RECEIPTS_FIRST_CHART_DATA_TYPES,
  SEGMENTS_FIRST_CHART_DATA_TYPES,
  SELL_FIRST_CHART_DATA_TYPES,
  SHARES_FIRST_CHART_DATA_TYPES
} from "./constants/dataTypes";

const { NONE } = CHART_DATA_TYPE;

//this const refers to frontend layer query params only (in URL)
//backend query params might be slightly different
export const QP = {
  VENDOR_ID: "vendor_id",
  TIER: "tier",
  CATEGORY1: "category1",
  CATEGORY2: "category2",
  CATEGORY3: "category3",
  PRODUCT: "product",
  STORE_TYPES: "store_types",
  STORE_TYPES_META: "store_types_meta",
  COUNTIES: "counties",
  VOIVODESHIPS: "voivodeships",
  CLIENT: "client",
  AGGREGATED: "aggregated",
  LFL: "lfl",
  PROMOTIONS_CHART_DATA_TYPE: "promotions_chart_data_type",
  AGGREGATED_COMPETITION: "aggregated_competition",
  EXCLUDED_DAYS: "excluded_days",
  DYNAMIC_PERIOD_CHECKED: "dynamic_period_checked",
  DYNAMIC_PERIOD: "dynamic_period",
  INITIAL_DATE_FROM: "initial_date_from",
  INITIAL_DATE_TO: "initial_date_to",
  CHART_TYPE: "chart_type",
  COMPETING_COMPANIES: "competing_companies",
  COMPETING_MATERIALS: "competing_materials",
  CHART_PERIOD: "chart_period",
  TABLE_PERIOD: "table_period",
  TABLE_OPEN: "table_open",
  REF_CAT1: "ref_cat1",
  REF_CAT2: "ref_cat2",
  REF_CAT3: "ref_cat3",
  REF_BRAND: "ref_brand",
  REF_SUBBRAND: "ref_subbrand",
  REF_BRAND_AGGREGATED: "ref_brand_aggregated",
  REF_CAT_AGGREGATED: "ref_cat_aggregated",
  SUMMARY_SUB_TYPE: "summary_sub_type",
  BRANDS: "brands",
  SUB_BRANDS: "sub_brands",
  COMPETING_BRANDS: "competing_brands",
  COMPETING_SUB_BRANDS: "competing_sub_brands",
  COMPARE_DATE_FROM: "compare_date_from",
  COMPARE_DATE_TO: "compare_date_to",
  INCLUDE_LEAP_YEAR: "include_leap_year",
  DELAY: "delay",
  HIDE_PLANOGRAM: "hide_planogram",
  SIDEBAR_CHANGE: "sidebar_change",
  SIDEBAR_SORT_BY: "sidebar_sort_by",
  SIDEBAR_DESC: "sidebar_desc",
  SIDEBAR_SORT_TYPE: "sidebar_sort_type",
  SIDEBAR_SORT_GROUP: "sidebar_sort_group",
  SIDEBAR_SORT_END: "sidebar_sort_end",
  SIDEBAR_METRIC: "sidebar_metric",
  SORT_RANKING: "sort_ranking",
  HIDE_5_RANK: "hide_5_rank",
  REPORT_TYPE: "report_type",
  SENTIMENT: "sentiment",
  STATE: "state",
  CODE: "code",
  DATE_FROM: "date_from",
  DATE_TO: "date_to",
  CHART_DATA_TYPE_FIRST: "chart_data_type_first",
  CHART_DATA_TYPE_SECOND: "chart_data_type_second",
  SELL_SUMMARY_TYPE: "sell_summary_type",
  LOYALTY_SUMMARY_TYPE: "loyalty_summary_type",
  LOCATION_SUMMARY_TYPE: "location_summary_type",
  SHARES_SUMMARY_TYPE: "shares_summary_type",
  DYNAMICS_SUMMARY_TYPE: "dynamics_summary_type",
  RECEIPTS_SUMMARY_TYPE: "receipts_summary_type",
  MULTIPACK_SUMMARY_TYPE: "multipack_summary_type",
  EXPORT_DATA_TYPE: "export_data_type",
  SHARES_TYPE: "shares_type",
  LOGISTICS_VISIBLE_TYPE: "logistics_visible_type",
  HOURS_SUMMARY_TYPE: "hours_summary_type",
  INSIGHT_ID: "insight_id",
  ACTIVE_INSIGHT: "active_insight",
  GROUP_BY_SEGMENTS: "group_by_segments",
  HIDE_TILES: "hide_tiles",
  INSIGHT_STATE: "insight_state",
  PROMOTIONS: "promotions",
  ZAPPKA_SHARES_FIRST: "zappka_shares_first",
  ZAPPKA_SHARES_SECOND: "zappka_shares_second",
  ZAPPKA_TRANSACTION: "zappka_transaction",
  ZAPPKA_SUMMARY_TYPE: "zappka_summary_type",
  SHOW_BACK_DATA: "show_back_data", // TODO: to be removed once "ShowDatasetSelect" is implemented everywhere
  SHOW_DATASET: "show_dataset",
  ATTRIBUTES: "attributes",
  FULL_SCREEN: "full_screen"
} as const;

export const FETCHING_PARAMETERS = [
  QP.DATE_FROM,
  QP.DATE_TO,
  QP.COMPARE_DATE_FROM,
  QP.COMPARE_DATE_TO,
  QP.INCLUDE_LEAP_YEAR,
  QP.VENDOR_ID,
  QP.CATEGORY1,
  QP.CATEGORY2,
  QP.CATEGORY3,
  QP.REF_CAT1,
  QP.REF_CAT2,
  QP.REF_CAT3,
  QP.REF_CAT_AGGREGATED,
  QP.PRODUCT,
  QP.CLIENT,
  QP.COUNTIES,
  QP.VOIVODESHIPS,
  QP.STORE_TYPES,
  QP.STORE_TYPES_META,
  QP.LFL,
  QP.AGGREGATED,
  QP.AGGREGATED_COMPETITION,
  QP.BRANDS,
  QP.SUB_BRANDS,
  QP.COMPETING_COMPANIES,
  QP.COMPETING_MATERIALS,
  QP.COMPETING_BRANDS,
  QP.COMPETING_SUB_BRANDS,
  QP.DELAY,
  QP.EXCLUDED_DAYS,
  QP.INSIGHT_ID,
  QP.GROUP_BY_SEGMENTS,
  QP.REF_BRAND,
  QP.REF_SUBBRAND,
  QP.REF_BRAND_AGGREGATED,
  QP.INSIGHT_STATE,
  QP.ATTRIBUTES
] as const;

// === FILTERS AND SETTINGS PARAMETERS ===
const VENDOR_ID_QP = {
  [QP.VENDOR_ID]: ""
};

const TIER_QP = {
  [QP.TIER]: ""
};

const CATEGORY_QP = {
  [QP.CATEGORY1]: "",
  [QP.CATEGORY2]: "",
  [QP.CATEGORY3]: ""
};

const PRODUCT_QP = {
  [QP.PRODUCT]: ""
};

const STORE_TYPE_QP = {
  [QP.STORE_TYPES]: "",
  ...(isMetaSegmentFilterEnabled ? { [QP.STORE_TYPES_META]: "" } : {})
};

const LOCATION_QP = {
  [QP.COUNTIES]: "",
  [QP.VOIVODESHIPS]: ""
};

const CLIENT_TYPE_QP = {
  [QP.CLIENT]: CLIENT_TYPES.ALL.toLowerCase(),
  [QP.ZAPPKA_SHARES_FIRST]: BOOL_STRING_VALUES.FALSE.toLowerCase(),
  [QP.ZAPPKA_SHARES_SECOND]: BOOL_STRING_VALUES.FALSE.toLowerCase(),
  [QP.ZAPPKA_TRANSACTION]: "",
  [QP.ZAPPKA_SUMMARY_TYPE]: ""
};

const AGGREGATED_QP = {
  [QP.AGGREGATED]: AggregationTypes.false
};

const LFL_QP = {
  [QP.LFL]: BOOL_STRING_VALUES.FALSE.toLowerCase()
};

const PROMOTION_QP = {
  [QP.PROMOTIONS_CHART_DATA_TYPE]: CHART_DATA_TYPE.NONE
};

const EXCLUDED_DAYS_QP = { [QP.EXCLUDED_DAYS]: "" };

const DYNAMIC_PERIOD_QP = {
  [QP.DYNAMIC_PERIOD_CHECKED]: BOOL_STRING_VALUES.FALSE,
  [QP.DYNAMIC_PERIOD]: "",
  [QP.INITIAL_DATE_FROM]: "",
  [QP.INITIAL_DATE_TO]: ""
};

const CHART_QP = {
  [QP.CHART_TYPE]: CHART_TYPES.LINE,
  [QP.HIDE_TILES]: BOOL_STRING_VALUES.FALSE
};

const DEFAULT_FILTERS_QP = {
  ...VENDOR_ID_QP,
  ...TIER_QP,
  ...CATEGORY_QP,
  ...PRODUCT_QP,
  ...STORE_TYPE_QP,
  ...LOCATION_QP,
  ...CLIENT_TYPE_QP,
  ...AGGREGATED_QP,
  ...LFL_QP,
  ...PROMOTION_QP,
  ...EXCLUDED_DAYS_QP,
  ...DYNAMIC_PERIOD_QP
};

const COMPETITION_FILTERS_QP = {
  [QP.COMPETING_COMPANIES]: "",
  [QP.COMPETING_MATERIALS]: ""
};

const SELECTED_PERIOD_QP = {
  [QP.CHART_PERIOD]: PERIOD_TYPE.PROMO_WEEKS.toLowerCase(),
  [QP.TABLE_PERIOD]: PERIOD_TYPE.DAYS.toLowerCase(),
  [QP.TABLE_OPEN]: BOOL_STRING_VALUES.FALSE.toLowerCase()
};

const REFERENCE_CATEGORIES_QP = {
  [QP.REF_CAT1]: "",
  [QP.REF_CAT2]: "",
  [QP.REF_CAT3]: "",
  [QP.REF_CAT_AGGREGATED]: BOOL_STRING_VALUES.TRUE.toLowerCase(),
  [QP.SUMMARY_SUB_TYPE]: SUMMARY_SUB_SWITCH.OWN.toLowerCase()
};

const REFERENCE_BRANDS_QP = {
  ...(isReferenceBrandsFilterEnabled && {
    [QP.REF_BRAND]: "",
    [QP.REF_SUBBRAND]: "",
    [QP.REF_BRAND_AGGREGATED]: BOOL_STRING_VALUES.FALSE
  })
};

const BRANDS_QP = {
  [QP.BRANDS]: "",
  [QP.SUB_BRANDS]: "",
  [QP.COMPETING_BRANDS]: "",
  [QP.COMPETING_SUB_BRANDS]: ""
};

const HISTORICAL_DATA_QP = {
  [QP.COMPARE_DATE_FROM]: "",
  [QP.COMPARE_DATE_TO]: "",
  [QP.INCLUDE_LEAP_YEAR]: "",
  [QP.SIDEBAR_CHANGE]: BOOL_STRING_VALUES.TRUE.toLowerCase()
};

export const POWER_USER_QP = {
  [QP.DELAY]: BOOL_STRING_VALUES.TRUE.toLowerCase(),
  [QP.HIDE_PLANOGRAM]: BOOL_STRING_VALUES.TRUE.toLowerCase()
};

const CHART_SIDEBAR_QP = {
  [QP.SIDEBAR_SORT_BY]: "item",
  [QP.SIDEBAR_DESC]: BOOL_STRING_VALUES.FALSE,
  [QP.SIDEBAR_SORT_TYPE]: SidebarSortingTypes.BASE,
  [QP.SIDEBAR_SORT_GROUP]: BOOL_STRING_VALUES.FALSE,
  [QP.SIDEBAR_SORT_END]: BOOL_STRING_VALUES.FALSE,
  [QP.SIDEBAR_METRIC]: ""
};

const RANKING_QP = {
  [QP.SORT_RANKING]: RankingSorting.DESC,
  [QP.HIDE_5_RANK]: BOOL_STRING_VALUES.TRUE
};

// === HIDDEN PARAMETERS LIST ===
export const TIER_1_HIDDEN_PARAMS = [
  QP.LFL,
  QP.CLIENT,
  QP.COUNTIES,
  QP.VOIVODESHIPS,
  QP.COMPETING_COMPANIES,
  QP.COMPETING_MATERIALS,
  QP.COMPETING_BRANDS,
  QP.COMPETING_SUB_BRANDS,
  QP.PROMOTIONS_CHART_DATA_TYPE,
  QP.AGGREGATED_COMPETITION,
  QP.REF_CAT1,
  QP.REF_CAT2,
  QP.REF_CAT3,
  QP.REF_CAT_AGGREGATED,
  QP.EXCLUDED_DAYS,
  QP.CHART_TYPE,
  QP.ATTRIBUTES,
  ...Object.keys(DYNAMIC_PERIOD_QP)
];
export const TIER_2_HIDDEN_PARAMS = [
  QP.LFL,
  QP.CLIENT,
  QP.COUNTIES,
  QP.VOIVODESHIPS,
  QP.COMPETING_COMPANIES,
  QP.COMPETING_MATERIALS,
  QP.COMPETING_BRANDS,
  QP.COMPETING_SUB_BRANDS,
  QP.PROMOTIONS_CHART_DATA_TYPE,
  QP.EXCLUDED_DAYS,
  QP.CHART_TYPE,
  QP.ATTRIBUTES,
  ...Object.keys(DYNAMIC_PERIOD_QP)
];
export const TIER_3_HIDDEN_PARAMS = [QP.AGGREGATED_COMPETITION];
export const TIER_4_HIDDEN_PARAMS = [
  QP.AGGREGATED_COMPETITION,
  QP.COMPETING_MATERIALS,
  QP.COMPETING_COMPANIES,
  QP.ATTRIBUTES
];

export const REPORTS_BASE_DEFAULT_QP = {
  ...POWER_USER_QP,
  ...DEFAULT_FILTERS_QP,
  ...COMPETITION_FILTERS_QP,
  ...CHART_QP,
  ...REFERENCE_CATEGORIES_QP,
  ...REFERENCE_BRANDS_QP,
  [QP.SHOW_DATASET]: "both",
  [QP.FULL_SCREEN]: BOOL_STRING_VALUES.FALSE,
  [QP.ATTRIBUTES]: ""
};

export const DEFAULT_DATA_TYPES = {
  sell: [SELL_FIRST_CHART_DATA_TYPES[0].toLowerCase(), NONE],
  location: [LOCATION_FIRST_CHART_DATA_TYPES[0].toLowerCase(), NONE],
  shares: [SHARES_FIRST_CHART_DATA_TYPES[0].toLowerCase(), NONE],
  dynamics: [DYNAMICS_FIRST_CHART_DATA_TYPES[0].toLowerCase(), NONE],
  receipts: [RECEIPTS_FIRST_CHART_DATA_TYPES[0].toLowerCase(), NONE],
  segments: [SEGMENTS_FIRST_CHART_DATA_TYPES[0].toLowerCase(), NONE],
  hours: [
    HOURS_FIRST_CHART_DATA_TYPES[0].toLowerCase(),
    HOURS_SECOND_CHART_DATA_TYPES[2].toLowerCase(),
    NONE
  ],
  logistics: [LOGISTICS_FIRST_CHART_DATA_TYPES[0].toLowerCase(), NONE],
  loyalty: [LOYALTY_ABSOLUTE_DATA_TYPES[0].toLowerCase(), NONE],
  multipack: [MULTIPACK_CHART_DATA_TYPES[0].toLowerCase(), NONE]
};

const DEFAULT_SELL_QUERY_PARAMS = {
  ...REPORTS_BASE_DEFAULT_QP,
  ...SELECTED_PERIOD_QP,
  ...CHART_SIDEBAR_QP,
  ...BRANDS_QP,
  ...HISTORICAL_DATA_QP,
  ...RANKING_QP,
  [QP.CHART_DATA_TYPE_FIRST]: DEFAULT_DATA_TYPES.sell[0],
  [QP.CHART_DATA_TYPE_SECOND]: DEFAULT_DATA_TYPES.sell[1],
  [QP.AGGREGATED_COMPETITION]: BOOL_STRING_VALUES.FALSE.toLowerCase(),
  [QP.SELL_SUMMARY_TYPE]: SUMMARY_SWITCH.TOTAL.toLowerCase(),
  [QP.ACTIVE_INSIGHT]: ""
};

const DEFAULT_LOCATION_QUERY_PARAMS = {
  ...REPORTS_BASE_DEFAULT_QP,
  ...SELECTED_PERIOD_QP,
  ...CHART_SIDEBAR_QP,
  ...BRANDS_QP,
  ...HISTORICAL_DATA_QP,
  [QP.CHART_DATA_TYPE_FIRST]: DEFAULT_DATA_TYPES.location[0],
  [QP.CHART_DATA_TYPE_SECOND]: DEFAULT_DATA_TYPES.location[1],
  [QP.LOCATION_SUMMARY_TYPE]: SUMMARY_SWITCH.TOTAL.toLowerCase()
};

const DEFAULT_SHARES_QUERY_PARAMS = {
  ...REPORTS_BASE_DEFAULT_QP,
  ...SELECTED_PERIOD_QP,
  ...CHART_SIDEBAR_QP,
  ...BRANDS_QP,
  ...HISTORICAL_DATA_QP,
  ...RANKING_QP,
  [QP.CHART_DATA_TYPE_FIRST]: DEFAULT_DATA_TYPES.shares[0],
  [QP.CHART_DATA_TYPE_SECOND]: DEFAULT_DATA_TYPES.shares[1],
  [QP.SHARES_TYPE]: DATASET_TYPES.COMPETITOR.toLowerCase(),
  [QP.SHARES_SUMMARY_TYPE]: SUMMARY_SWITCH.VALUE_QUANTITY.toLowerCase(),
  [QP.ACTIVE_INSIGHT]: ""
};

const DEFAULT_DYNAMICS_QUERY_PARAMS = {
  ...REPORTS_BASE_DEFAULT_QP,
  ...SELECTED_PERIOD_QP,
  ...CHART_SIDEBAR_QP,
  ...BRANDS_QP,
  ...HISTORICAL_DATA_QP,
  ...RANKING_QP,
  [QP.CHART_DATA_TYPE_FIRST]: DEFAULT_DATA_TYPES.dynamics[0],
  [QP.CHART_DATA_TYPE_SECOND]: DEFAULT_DATA_TYPES.dynamics[1],
  [QP.DYNAMICS_SUMMARY_TYPE]: SUMMARY_SWITCH.WORTH.toLowerCase(),
  [QP.ACTIVE_INSIGHT]: ""
};

const DEFAULT_RECEIPTS_QUERY_PARAMS = {
  ...REPORTS_BASE_DEFAULT_QP,
  ...SELECTED_PERIOD_QP,
  ...CHART_SIDEBAR_QP,
  ...BRANDS_QP,
  ...HISTORICAL_DATA_QP,
  ...RANKING_QP,
  [QP.CHART_DATA_TYPE_FIRST]: DEFAULT_DATA_TYPES.receipts[0],
  [QP.CHART_DATA_TYPE_SECOND]: DEFAULT_DATA_TYPES.receipts[1],
  [QP.RECEIPTS_SUMMARY_TYPE]: SUMMARY_SWITCH.RECEIPTS_METRICS.toLowerCase()
};

const DEFAULT_SEGMENTS_QUERY_PARAMS = {
  ...REPORTS_BASE_DEFAULT_QP,
  ...BRANDS_QP,
  ...HISTORICAL_DATA_QP,
  [QP.GROUP_BY_SEGMENTS]: BOOL_STRING_VALUES.FALSE,
  [QP.CHART_DATA_TYPE_FIRST]: DEFAULT_DATA_TYPES.segments[0],
  [QP.CHART_DATA_TYPE_SECOND]: DEFAULT_DATA_TYPES.segments[1],
  [QP.CHART_PERIOD]: PERIOD_TYPE.DAYS.toLowerCase()
};

const DEFAULT_HOURS_QUERY_PARAMS = {
  ...REPORTS_BASE_DEFAULT_QP,
  ...BRANDS_QP,
  ...HISTORICAL_DATA_QP,
  [QP.CHART_DATA_TYPE_FIRST]: DEFAULT_DATA_TYPES.hours[0],
  [QP.CHART_DATA_TYPE_SECOND]: DEFAULT_DATA_TYPES.hours[1],
  [QP.HOURS_SUMMARY_TYPE]: SUMMARY_SWITCH.WORTH.toLowerCase(),
  [QP.CHART_PERIOD]: PERIOD_TYPE.DAYS.toLowerCase()
};

const DEFAULT_LOGISTICS_QUERY_PARAMS = {
  ...POWER_USER_QP,
  ...CHART_SIDEBAR_QP,
  ...VENDOR_ID_QP,
  ...TIER_QP,
  ...CATEGORY_QP,
  ...PRODUCT_QP,
  ...PROMOTION_QP,
  ...EXCLUDED_DAYS_QP,
  ...HISTORICAL_DATA_QP,
  ...DYNAMIC_PERIOD_QP,
  ...(isLogisticsSegmentsEnabled ? STORE_TYPE_QP : {}),
  ...BRANDS_QP,
  // below params are to keep state on route change only
  ...COMPETITION_FILTERS_QP,
  ...REFERENCE_CATEGORIES_QP,
  ...REFERENCE_BRANDS_QP,
  ...LFL_QP,
  // above params are to keep state on route change only
  [QP.CHART_DATA_TYPE_FIRST]: DEFAULT_DATA_TYPES.logistics[0],
  [QP.CHART_DATA_TYPE_SECOND]: DEFAULT_DATA_TYPES.logistics[1],
  [QP.LOGISTICS_VISIBLE_TYPE]: LOGISTICS_DATA_TYPES_PARAMS.SINGLE_DAY,
  [QP.HIDE_TILES]: BOOL_STRING_VALUES.FALSE,
  [QP.FULL_SCREEN]: BOOL_STRING_VALUES.FALSE
};

const DEFAULT_EXPORT_QUERY_PARAMS = {
  ...DEFAULT_FILTERS_QP,
  ...COMPETITION_FILTERS_QP,
  ...BRANDS_QP,
  ...REFERENCE_CATEGORIES_QP,
  ...REFERENCE_BRANDS_QP,
  ...pick(
    [QP.COMPARE_DATE_FROM, QP.COMPARE_DATE_TO, QP.INCLUDE_LEAP_YEAR],
    HISTORICAL_DATA_QP
  ),
  [QP.CHART_PERIOD]: PERIOD_TYPE.DAYS.toLowerCase(),
  [QP.EXPORT_DATA_TYPE]: EXPORT_DATA_TYPE.GENERAL,
  [QP.ATTRIBUTES]: ""
};

const DEFAULT_LOYALTY_QUERY_PARAMS = omit([QP.AGGREGATED], {
  ...REPORTS_BASE_DEFAULT_QP,
  ...SELECTED_PERIOD_QP,
  ...CHART_SIDEBAR_QP,
  ...BRANDS_QP,
  ...HISTORICAL_DATA_QP,
  ...RANKING_QP,
  [QP.CHART_DATA_TYPE_FIRST]: DEFAULT_DATA_TYPES.loyalty[0],
  [QP.CHART_DATA_TYPE_SECOND]: DEFAULT_DATA_TYPES.loyalty[1],
  [QP.AGGREGATED_COMPETITION]: BOOL_STRING_VALUES.FALSE.toLowerCase(),
  [QP.LOYALTY_SUMMARY_TYPE]: SUMMARY_SWITCH.TOTAL.toLowerCase(),
  [QP.CHART_TYPE]: CHART_TYPES.LINE.toLowerCase()
});

const DEFAULT_MULTIPACK_QUERY_PARAMS = {
  ...REPORTS_BASE_DEFAULT_QP,
  ...SELECTED_PERIOD_QP,
  ...CHART_SIDEBAR_QP,
  ...BRANDS_QP,
  ...HISTORICAL_DATA_QP,
  ...RANKING_QP,
  [QP.MULTIPACK_SUMMARY_TYPE]: SUMMARY_SWITCH.MULTIPACK_TOTAL.toLowerCase(),
  [QP.CHART_DATA_TYPE_FIRST]: DEFAULT_DATA_TYPES.multipack[0],
  [QP.CHART_DATA_TYPE_SECOND]: DEFAULT_DATA_TYPES.multipack[1]
};

const DEFAULT_PROMOTIONS_QUERY_PARAMS = {
  ...CATEGORY_QP,
  ...VENDOR_ID_QP,
  ...BRANDS_QP,
  ...PRODUCT_QP,
  ...COMPETITION_FILTERS_QP,
  [QP.PROMOTIONS]: ""
};

const DEFAULT_REPORTS_QUERY_PARAMS = {
  BASE: REPORTS_BASE_DEFAULT_QP,
  SELL: DEFAULT_SELL_QUERY_PARAMS,
  LOCATION: DEFAULT_LOCATION_QUERY_PARAMS,
  SHARES: DEFAULT_SHARES_QUERY_PARAMS,
  DYNAMICS: DEFAULT_DYNAMICS_QUERY_PARAMS,
  RECEIPTS: DEFAULT_RECEIPTS_QUERY_PARAMS,
  SEGMENTS: DEFAULT_SEGMENTS_QUERY_PARAMS,
  LOGISTICS: DEFAULT_LOGISTICS_QUERY_PARAMS,
  EXPORT: DEFAULT_EXPORT_QUERY_PARAMS,
  HOURS: DEFAULT_HOURS_QUERY_PARAMS,
  LOYALTY: DEFAULT_LOYALTY_QUERY_PARAMS,
  MULTIPACK: DEFAULT_MULTIPACK_QUERY_PARAMS,
  PROMOTIONS: DEFAULT_PROMOTIONS_QUERY_PARAMS
};

const DEFAULT_MAIN_QUERY_PARAMS = {
  ...DEFAULT_FILTERS_QP,
  ...COMPETITION_FILTERS_QP,
  [QP.PROMOTIONS_CHART_DATA_TYPE]: NONE
};

const DEFAULT_LOGIN_QUERY_PARAMS = {
  [QP.STATE]: undefined,
  [QP.CODE]: undefined
};

const DEFAULT_INSIGHTS_QUERY_PARAMS = {
  [QP.CATEGORY1]: "",
  [QP.CATEGORY2]: "",
  [QP.CATEGORY3]: "",
  [QP.VENDOR_ID]: "",
  [QP.BRANDS]: "",
  [QP.SUB_BRANDS]: "",
  [QP.PRODUCT]: "",
  [QP.REPORT_TYPE]: "",
  [QP.SENTIMENT]: "",
  [QP.INSIGHT_STATE]: Section.ACTIVE,
  [QP.ACTIVE_INSIGHT]: "",
  ...DYNAMIC_PERIOD_QP,
  // below params are to keep state on route change only
  [QP.COMPETING_COMPANIES]: "",
  [QP.COMPETING_BRANDS]: "",
  [QP.COMPETING_SUB_BRANDS]: "",
  [QP.COMPETING_MATERIALS]: "",
  [QP.REF_CAT1]: "",
  [QP.REF_CAT2]: "",
  [QP.REF_CAT3]: "",
  [QP.REF_CAT_AGGREGATED]: ""
  // above params are to keep state on route change only
};

export const DEFAULT_QUERY_PARAMS = {
  MAIN: DEFAULT_MAIN_QUERY_PARAMS,
  REPORTS: DEFAULT_REPORTS_QUERY_PARAMS,
  LOGIN: DEFAULT_LOGIN_QUERY_PARAMS,
  INSIGHTS: DEFAULT_INSIGHTS_QUERY_PARAMS
};
