import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const ChevronUp: React.FC<Props> = ({
  className,
  color,
  size = ICON_SIZES.NORMAL,
  testId
}) => {
  const iconSize = SIZES[size];

  return (
    <svg
      className={className}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 20 20"
      version="1.1"
      data-testid={testId}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon
          id="Path-7"
          fill={color || "currentColor"}
          transform="translate(10.000000, 10.000000) rotate(-90.000000) translate(-10.000000, -10.000000) "
          points="8 5 13 10 8 15 7 14 11 10 7 6"
        ></polygon>
      </g>
    </svg>
  );
};
