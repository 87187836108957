import { useMemo } from "react";
import { useSelector } from "react-redux";

import { rankingSummaryPeriodSelector } from "pages/Reports/redux/reducers/periods/selectors";
import {
  chartPeriodSelector,
  isReportTableOpenSelector,
  tablePeriodSelector
} from "store/selectors/routerSelectors";
import { PERIOD_TYPE } from "utils";

const { RANKING } = PERIOD_TYPE;

export const usePeriodsToFetch = () => {
  const isReportTableOpen = useSelector(isReportTableOpenSelector);
  const chartPeriod = useSelector(chartPeriodSelector);
  const tablePeriod = useSelector(tablePeriodSelector);
  const { period } = useSelector(rankingSummaryPeriodSelector);

  return useMemo(() => {
    const periods = isReportTableOpen
      ? [chartPeriod, tablePeriod]
      : [chartPeriod];

    if (chartPeriod === RANKING) {
      periods.push(period);
    }

    return periods;
  }, [isReportTableOpen, chartPeriod, tablePeriod, period]);
};
