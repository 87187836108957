import { useSelector } from "react-redux";

import { flatten } from "ramda";

import { useLegendContentNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendContentNames";
import { useLegendReportData } from "pages/Reports/partials/Chart/Legend/hooks/useLegendReportData";
import { LegendTab } from "pages/Reports/partials/Chart/Legend/Legend/Legend";
import { createContentArray } from "pages/Reports/partials/Chart/Legend/utils/createContent";
import { hiddenLinesByReportSelector } from "pages/Reports/redux/reducers/chartReducer";
import { checkedDataTypes } from "pages/Reports/sections/utils/chartData";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { ExistingMetrics } from "pages/Reports/types/metrics";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { DATASET_TYPES } from "utils";
import { Values } from "utils/types";

export type ChartInfo = {
  type?: string;
  label: string;
  color: string;
  existingMetrics?: ExistingMetrics;
  vendorId?: string;
};

export type CreateLegend = {
  dataType: ChartDataTypes;
  chartData: ChartInfo[];
};

export type CreateLegendContent = {
  vendorId: string;
  resultId: string;
  color: string;
  type: Values<typeof DATASET_TYPES>;
  existingMetrics: ExistingMetrics;
};

export const useLegend = (): LegendTab[] => {
  const contentData = useLegendReportData();
  const contentNames = useLegendContentNames();
  const hiddenLines = useSelector(hiddenLinesByReportSelector);
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const dataTypes = checkedDataTypes([firstChartType, secondChartType]);

  if (!contentData) return [];

  const sections = createContentArray(
    contentNames,
    contentData,
    hiddenLines,
    dataTypes
  );

  const isDataDisabled =
    flatten(sections.map(section => section.items)).length === 0;
  const isNoDataDisabled =
    flatten(sections.map(section => section.notExisting)).length === 0;

  const dataSection = sections
    .map(section => ({
      label: section.label,
      items: section.items
    }))
    .filter(section => section.items.length > 0);
  const noDataSection = sections
    .map(section => ({
      label: section.label,
      items: section.notExisting
    }))
    .filter(section => section.items.length > 0);

  const tabs: LegendTab[] = [
    {
      label: "Dane",
      disabled: isDataDisabled,
      sections: dataSection
    },
    {
      label: "Brak danych",
      disabled: isNoDataDisabled,
      sections: noDataSection
    }
  ];

  return tabs;
};
