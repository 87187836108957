import { useDispatch } from "react-redux";

import { ChevronRight, Close, Star } from "components/atoms/Icon";
import { useSidebarInsightMutations } from "pages/Insights/hooks/useSidebarInsights";
import { InsightBoxFooter } from "pages/Insights/partials/InsightsBox/InsightsBoxFooter/InsightsBoxFooter";
import { sendSingleInsightFeedback } from "pages/Insights/utils/analytics";
import { SingleInsightData } from "pages/Insights/utils/types";
import { updateQueryParams } from "store/actions/routerActions";
import { ICON_SIZES } from "utils/constants";
import { QP } from "utils/defaultQueryParams";
import { pushInsightsAction } from "utils/googleTagManager/dataLayer";

import s from "./sidebarInsightBox.module.scss";

interface Props {
  insight: SingleInsightData;
}

export const SidebarInsightBox = ({ insight }: Props) => {
  const dispatch = useDispatch();
  const {
    removeFromFavouritesMutation,
    addToFavouritesMutation,
    removeInsightMutation
  } = useSidebarInsightMutations();

  const showDetailsButton = insight.explainability !== null;

  const handleFavouriteButtonClick = () => {
    if (insight.isFavourite) {
      removeFromFavouritesMutation.mutate(insight.insightId);
    } else {
      addToFavouritesMutation.mutate(insight.insightId);
    }
  };

  const handleRemoveButtonClick = () => {
    removeInsightMutation.mutate(insight.insightId);
  };

  const handleDetailsButtonClick = () => {
    dispatch(updateQueryParams({ [QP.ACTIVE_INSIGHT]: insight.insightId }));
    sendSingleInsightFeedback("SHOW_EXPLAINABILITY", insight.insightId);
    pushInsightsAction("click", "Zobacz szczegóły");
  };

  return (
    <>
      <div className={s.headerButtons}>
        <button
          className={s.headerButton}
          onClick={handleFavouriteButtonClick}
          data-tooltip={
            insight.isFavourite ? "Usuń z ulubionych" : "Dodaj do ulubionych"
          }
        >
          <Star filled={insight.isFavourite} />
        </button>
        <button
          className={s.headerButton}
          onClick={handleRemoveButtonClick}
          data-tooltip="Usuń"
        >
          <Close size={ICON_SIZES.EXTRA_SMALL} />
        </button>
      </div>
      <p
        dangerouslySetInnerHTML={{ __html: insight.insightBulletContent }}
        className={s.injectedContent}
      />
      <div className={s.contentButtons}>
        {showDetailsButton && (
          <button
            className={s.detailsButton}
            onClick={handleDetailsButtonClick}
          >
            Zobacz szczegóły
          </button>
        )}
        {insight.allowReloadPage && (
          <a href={insight.insightUrl} className={s.linkButton}>
            <span>Sprawdź na wykresie</span>
            <ChevronRight />
          </a>
        )}
      </div>
      <span className={s.spacer} />
      <InsightBoxFooter
        isAnimated={false}
        insightId={insight.insightId}
        rateLevel={insight.rateLevel}
      />
    </>
  );
};
