import { Action, createHook, createStore } from "react-sweet-state";

import { DropdownItem } from "components/molecules/types";
import {
  getMatched,
  getSelectedCountLabel,
  selectElement
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import {
  CategoryItem,
  Parent
} from "pages/Reports/redux/reducers/filters/categoryFilters/categoryFiltersActions";
import { sortProductsBySelectionOrder } from "pages/Reports/redux/utils";
import {
  isAlreadySelected,
  isIncludedInInput,
  isParentCategorySelected
} from "store/utils/filtersUtils";
import { isProductWithdrawn } from "utils";
import { QP } from "utils/defaultQueryParams";
import { Nullable } from "utils/types";

import { sortProductsByWithdrawness } from "../../utils/sortProductsByWithdrawness";

export type ProductItem = DropdownItem<number> & {
  parent?: Nullable<Parent>;
};

type State = {
  all: ProductItem[];
  selected: ProductItem[];
  unactiveProductsBottom: boolean;
};
type Actions = typeof actions;

const actions = {
  updateAllProducts: (all: ProductItem[]): Action<State> => ({ setState }) =>
    setState({
      all
    }),
  updateSelectedProducts: (selected: ProductItem[]): Action<State> => ({
    setState
  }) =>
    setState({
      selected
    }),
  selectProduct: (selectedProduct: Nullable<ProductItem>): Action<State> => ({
    setState,
    getState
  }) =>
    setState({
      selected: selectElement(selectedProduct, getState().selected)
    }),
  sortProducts: (): Action<State> => ({ setState, getState }) => {
    const state = getState();

    const allProducts = state.all;
    const selectedProductsIds = state.selected.map(item => item.value);

    const sortedBySelection = sortProductsBySelectionOrder(
      selectedProductsIds,
      allProducts
    );

    const sortedByWithdrawness = state.unactiveProductsBottom
      ? sortProductsByWithdrawness(sortedBySelection)
      : sortedBySelection;

    setState({
      all: sortedByWithdrawness
    });
  },
  clearProducts: (): Action<State> => ({ setState }) =>
    setState({
      selected: []
    }),
  checkSelectionsAfterFetching: (ids: number[] | string[]): Action<State> => ({
    getState,
    setState
  }) =>
    setState({
      selected: getMatched(getState().selected, ids)
    }),
  checkPristineSelectionsAfterFetching: (
    products: ProductItem[],
    productQueryParam: string | string[]
  ): Action<State> => ({ setState }) => {
    const updatedSelectedProducts = products.filter(({ value }) => {
      if (typeof productQueryParam === "string") {
        return String(value) === productQueryParam;
      }
      return productQueryParam.includes(String(value));
    });

    setState({
      selected: updatedSelectedProducts
    });
  },
  selectAllActiveOrInactiveProducts: (
    inputValue: string,
    selectActiveItems = false,
    categories: [CategoryItem[], CategoryItem[], CategoryItem[]]
  ): Action<State> => ({ getState, setState }) => {
    const state = getState();
    const allProducts = state.all;
    const selectedProducts = state.selected;

    const selected = [
      ...selectedProducts,
      ...allProducts.filter(item => {
        const shouldBeReturned = selectActiveItems
          ? !isProductWithdrawn(item.label)
          : isProductWithdrawn(item.label);

        return (
          !isAlreadySelected(selectedProducts, item) &&
          isIncludedInInput(inputValue, item) &&
          isParentCategorySelected(
            QP.PRODUCT,
            item,
            categories[0],
            categories[1],
            categories[2]
          ) &&
          shouldBeReturned
        );
      })
    ];

    setState({
      selected: selected
    });
  },
  toggleUnactiveProductsBottom: (): Action<State> => ({ getState, setState }) =>
    setState({
      unactiveProductsBottom: !getState().unactiveProductsBottom
    })
};

export const ProductStore = createStore<State, Actions>({
  name: "product",
  initialState: {
    all: [],
    selected: [],
    unactiveProductsBottom: false
  },
  actions
});

const getProductLabel = (
  state: State,
  categories: [CategoryItem[], CategoryItem[], CategoryItem[]]
) => {
  const allProductsFilteredBySelectedCategory = state.all.filter(
    (productItem: ProductItem) =>
      isParentCategorySelected(
        QP.PRODUCT,
        productItem,
        categories[0],
        categories[1],
        categories[2]
      )
  );
  const selectedProductsFilteredAlreadySelected = state.selected.filter(
    ({ value }: ProductItem) =>
      !allProductsFilteredBySelectedCategory
        .map(({ value }) => value)
        .includes(value)
  );
  const availableProductsLength = [
    ...selectedProductsFilteredAlreadySelected,
    ...allProductsFilteredBySelectedCategory
  ].length;

  if (state.selected.length === 1) {
    return state.selected[0].label;
  }

  return getSelectedCountLabel(state.selected.length, availableProductsLength);
};

export const ProductHooks = {
  useProduct: createHook(ProductStore),
  useProductLabel: createHook(ProductStore, {
    selector: getProductLabel
  }),
  useSelectedProductsIds: createHook(ProductStore, {
    selector: (state: State) => state.selected.map(item => item.value)
  }),
  useSelectedProducts: createHook(ProductStore, {
    selector: (state: State) => state.selected
  }),
  useStringifiedSelectedProductsIds: createHook(ProductStore, {
    selector: (state: State) =>
      state.selected.map(item => item.label).join(", ")
  })
};
