import { LoyaltyDataset } from "api/loyalty/types";

import { appendInsightId } from "pages/Insights/partials/InsightsBox/utils";
import { getOwnSummary, getReferenceSummary } from "pages/Reports/utils";
import { createDatasetObject } from "pages/Reports/utils/createDatasetObject";
import { normalizeLoyaltyResponse } from "pages/Reports/utils/normalize/normalizeLoyaltyResponse";
import { normalizeLoyaltySummary } from "pages/Reports/utils/normalize/normalizeLoyaltySummary";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { pushReportFetchEvent } from "utils/googleTagManager/dataLayer";
import { Periods } from "utils/types";

import { LoyaltyApi } from "../types";

export const loyaltyServices = {
  fetchLoyalty: async (queryString: string, period: Periods) => {
    const url = appendInsightId(
      `${REST_API_ENDPOINTS.REPORTS.LOYALTY}?${queryString}`
    );
    const { data } = await HTTP.get<LoyaltyApi>(url);

    const ownSummary = getOwnSummary(data.summary);
    const referenceSummary = getReferenceSummary(data.summary);
    const standardizedData: LoyaltyDataset = createDatasetObject({
      query: queryString,
      currentPeriod: normalizeLoyaltyResponse(data.data),
      ownSummary: normalizeLoyaltySummary(ownSummary),
      referenceSummary: referenceSummary
        ? normalizeLoyaltySummary(referenceSummary)
        : null,
      periodType: period,
      info: data.info || []
    });

    pushReportFetchEvent("Lojalność");

    return standardizedData;
  }
};
