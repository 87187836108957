import { Segments } from "pages/Reports/sections/Segments/types";
import { DATASET_TYPES } from "utils";

export const getName = (
  { vendorName, name, type }: Segments,
  isForHistoricalChart: boolean
): string => {
  if (vendorName) {
    return `${name} - ${vendorName}`;
  }

  let label = name;

  switch (type) {
    case DATASET_TYPES.OWN:
      label = `${name} - dane własne`;
      break;
    case DATASET_TYPES.COMPETITOR:
      label = `${name} - dane konkurencji`;
      break;
    case DATASET_TYPES.ATTRIBUTES:
      label = `${name} - dane wspólne`;
      break;
    default:
      break;
  }

  if (isForHistoricalChart) {
    label += " (dane historyczne)";
  }

  return label;
};
