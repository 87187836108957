import { CHART_DATA_TYPE } from "utils";

export const getPolishLabelForDataTypeTooltip = (dataType: string): string => {
  switch (dataType) {
    case CHART_DATA_TYPE.NEW_CUSTOMERS_COUNT:
      return "Liczba klientów, którzy dokonali zakupu pierwszy raz od 3 mc";
    case CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT:
      return "To % z wszystkich klientów, którzy dokonali zakupu pierwszy raz od 3 mc";
    case CHART_DATA_TYPE.ALL_RETURNS_COUNT:
      return "Nowi klienci którzy wrócili do zakupu w ciągu 8 tygodni";
    case CHART_DATA_TYPE.ALL_RETURNS_PERCENT:
      return "To % z wszystkich nowych klientów, którzy wrócili do zakupu";
    case CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT:
      return "To % z wszystkich nowych klientów, którzy wrócili do zakupu w ciągu tygodnia";
    case CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT:
      return "To % z wszystkich nowych klientów, którzy wrócili do zakupu w 2 tygodniu";
    case CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT:
      return "To % z wszystkich nowych klientów, którzy wrócili do zakupu w 3-4 tygodniu";
    case CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT:
      return "To % z wszystkich nowych klientów, którzy wrócili do zakupu w 5-6 tygodniu";
    case CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT:
      return "To % z wszystkich nowych klientów, którzy wrócili do zakupu w 7-8 tygodniu";
    case CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE:
      return "To liczba z wszystkich nowych klientów, którzy wrócili do zakupu w ciągu tygodnia";
    case CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE:
      return "To liczba z wszystkich nowych klientów, którzy wrócili do zakupu w 2 tygodniu";
    case CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE:
      return "To liczba z wszystkich nowych klientów, którzy wrócili do zakupu w 3-4 tygodniu";
    case CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE:
      return "To liczba z wszystkich nowych klientów, którzy wrócili do zakupu w 5-6 tygodniu";
    case CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE:
      return "To liczba z wszystkich nowych klientów, którzy wrócili do zakupu w 7-8 tygodniu";
    default:
      return "";
  }
};
