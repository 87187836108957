import React from "react";
import { useSelector } from "react-redux";

import { Material } from "pages/Reports/redux/reducers/promotionsReducer";
import { VendorHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { userCompanySelector } from "store/reducers/userReducer";

import s from "./promotionContent.module.scss";
import { showPromotionProducts } from "./utils";

type Props = {
  materials: Material[];
};

export const OwnMaterials = ({ materials }: Props) => {
  const currentCompany = useSelector(userCompanySelector);
  const isCompaniesDropdownMultiChoice = useSelector(
    isCompaniesDropdownMultiChoiceSelector
  );
  const [
    stringifiedSelectedCompany
  ] = VendorHooks.useStringifiedSelectedVendorsIds(
    isCompaniesDropdownMultiChoice
  );

  if (!Boolean(materials.length)) return null;

  return (
    <div className={s.ownMaterials}>
      <p className={s.ownCompanyName}>
        {currentCompany?.name || stringifiedSelectedCompany}
      </p>
      <p className={s.productNames}>
        {showPromotionProducts(materials[0].data)}
      </p>
    </div>
  );
};
