import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Portal } from "components/atoms";
import { PopupWindow } from "components/molecules";
import {
  AlertOverlay,
  CenteredAlert,
  CookieConsent,
  RWDPopupWindow
} from "components/organisms";
import { showCookieConsentSelector } from "pages/Admin/redux/selectors/cookiesSettingsSelectors";
import { setGenericAlertMessage } from "store/actions/appActions";
import {
  genericAlertMessageSelector,
  windowWidthSelector
} from "store/selectors/appSelectors";
import { isMobile, RWD_POPUP } from "utils";

export const MainOverlay = () => {
  const dispatch = useDispatch();
  const windowWidth = useSelector(windowWidthSelector);
  const isConsentDisplayed = useSelector(showCookieConsentSelector);
  const genericAlertMessage = useSelector(genericAlertMessageSelector);

  const [isRWDPopupVisible, displayRWDPopup] = useState(
    sessionStorage.getItem(RWD_POPUP) !== "true" && isMobile(windowWidth)
  );
  const hideRWDPopup = () => {
    sessionStorage.setItem(RWD_POPUP, "true");
    displayRWDPopup(false);
  };

  return (
    <>
      {isRWDPopupVisible && (
        <Portal id="js-RWD-popup-window">
          <RWDPopupWindow onClose={hideRWDPopup} />
        </Portal>
      )}

      {isConsentDisplayed && (
        <Portal id="js-cookie-bar">
          <CookieConsent />
        </Portal>
      )}

      {genericAlertMessage && (
        <Portal id="js-generic-alert-message">
          <PopupWindow>
            <CenteredAlert
              onClose={() => dispatch(setGenericAlertMessage(""))}
              genericAlertMessage={genericAlertMessage}
            />
          </PopupWindow>
        </Portal>
      )}

      <AlertOverlay />
    </>
  );
};
