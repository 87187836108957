import { useEffect, useMemo } from "react";

import { clone } from "ramda";

import {
  InsightReportType,
  SingleInsightData
} from "pages/Insights/utils/types";

import { findColumnIndexes, removeColumnByIndexes } from "./utils";

const TABLE_HEAD = { is_header: true, columns: [] };

export const useTransformedData = (data: SingleInsightData) => {
  return useMemo(() => {
    if (!data.explainability) return null;

    const [baseKey, compareKey] = Object.keys(data.explainability);
    const { table, promotions } = clone(data.explainability[baseKey]);
    let head = table.find(row => row.is_header) || TABLE_HEAD;
    let body = table.filter(row => !row.is_header);

    // merge explainability compare object to base object to create single table
    if (compareKey) {
      const { table: compareTable } = clone(data.explainability[compareKey]);
      const compareHead = compareTable.find(row => row.is_header) || TABLE_HEAD;
      const compareBody = compareTable.filter(row => !row.is_header);

      head = {
        ...head,
        columns: [
          ...head.columns,
          ...removeColumnByIndexes(compareHead.columns, [0]) // remove first column to avoid duplicated labels
        ]
      };
      body = body.map((row, index) => ({
        ...row,
        columns: [
          ...row.columns,
          ...removeColumnByIndexes(compareBody[index].columns, [0]) // remove first column to avoid duplicated labels
        ]
      }));
    }

    const changeIndexes = findColumnIndexes(head.columns, "Zmiana");
    const percentageChangeIndexes = findColumnIndexes(head.columns, "Zmiana %");

    // remove "change" and "percentage change" columns if report type is "dynamics"
    if (data.reportType === InsightReportType.DYNAMICS) {
      const indexes = [...changeIndexes, ...percentageChangeIndexes];
      head = {
        ...head,
        columns: removeColumnByIndexes(head.columns, indexes)
      };
      body = body.map(row => ({
        ...row,
        columns: removeColumnByIndexes(row.columns, indexes)
      }));
    }

    const isCompare = typeof compareKey !== "undefined";
    const hasTableHead = head.columns.length > 0;
    const hasPromotions =
      promotions.base.length > 0 || promotions.compare.length > 0;
    const promotionsColSpanCount = head.columns.length - 3;

    return {
      tableHead: head,
      tableBody: body,
      promotions,
      isCompare,
      hasTableHead,
      changeIndexes,
      percentageChangeIndexes,
      hasPromotions,
      promotionsColSpanCount
    };
  }, [data]);
};

export const useKeyboardEvents = (
  previousId: string,
  nextId: string,
  action: (id: string) => void
) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowLeft":
          if (previousId) action(previousId);
          break;
        case "ArrowRight":
          if (nextId) action(nextId);
          break;
        case "Escape":
          action("");
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);
  }, [action, nextId, previousId]);
};
