import { SummaryItem } from "api/types";

import { formatSummaryNumber } from "pages/Reports/utils";
import { Nullable } from "utils/types";

export type SummaryItemData = {
  label: string;
  tooltipText?: string;

  firstValue: Nullable<number | string>;
  firstValueType: string;
  secondValue: Nullable<number>;
  secondValueType: Nullable<string>;
  thirdValue: Nullable<number | string>;
  thirdValueType?: string; // optional because it should be exactly the same as firstValueType by default
  change?: Nullable<number>;

  firstValueBottom?: Nullable<number | string>;
  firstValueBottomType?: string;
  secondValueBottom?: Nullable<number>;
  secondValueBottomType?: Nullable<string>;
  thirdValueBottom?: Nullable<number | string>;
  thirdValueBottomType?: string; // optional because it should be exactly the same as firstValueType by default
  changeBottom?: Nullable<number>;
};

export const createSummaryItemData = (
  summaryItemsData: SummaryItemData[]
): SummaryItem[] =>
  summaryItemsData.map(
    ({
      label,
      tooltipText = "",

      firstValue,
      firstValueType,
      secondValue,
      secondValueType = "",
      thirdValue,
      thirdValueType = firstValueType,
      change,

      firstValueBottom = "",
      firstValueBottomType = "",
      secondValueBottom = "",
      secondValueBottomType = "",
      thirdValueBottom = "",
      thirdValueBottomType = firstValueBottomType,
      changeBottom = null
    }) => {
      return {
        label,
        tooltipText,

        firstValue: formatSummaryNumber(firstValue, firstValueType),
        secondValue:
          secondValueType === null
            ? null
            : formatSummaryNumber(secondValue, secondValueType),
        thirdValue: formatSummaryNumber(thirdValue, thirdValueType),
        change: change || 0,

        firstValueBottom: formatSummaryNumber(
          firstValueBottom,
          firstValueBottomType
        ),
        secondValueBottom:
          secondValueBottomType === null
            ? null
            : formatSummaryNumber(secondValueBottom, secondValueBottomType),
        thirdValueBottom: formatSummaryNumber(
          thirdValueBottom,
          thirdValueBottomType
        ),
        changeBottom: changeBottom
      };
    }
  );
