import * as React from "react";

import cn from "classnames";

import s from "./spinnerLoader.module.scss";

interface Props {
  className?: {
    wrapper?: string;
    loader?: string;
  };
  size?: "small" | "medium";
}

export const SpinnerLoader = ({ className = {}, size }: Props) => {
  return (
    <div
      className={cn(
        s.spinnerLoaderWrapper,
        size === "small" && s.small,
        size === "medium" && s.medium,
        className.wrapper
      )}
    >
      <div className={className.loader}>
        <div className={s.spinner} />
        <div className={s.spinner} />
        <div className={s.spinner} />
        <div className={s.spinner} />
        <div className={s.spinnerLoaderBackground} />
      </div>
    </div>
  );
};
