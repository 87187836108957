import { formatNumber, NUMBER_TYPE } from "pages/Reports/utils/formatNumber";
import { CHART_DATA_TYPE, NO_DATA_SHORT } from "utils";
import { Nullable } from "utils/types";

const PERCENTAGE_TYPES = [
  CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT,
  CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT_LAST_YEAR,
  CHART_DATA_TYPE.ALL_RETURNS_PERCENT,
  CHART_DATA_TYPE.ALL_RETURNS_PERCENT_LAST_YEAR,
  CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT,
  CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT_LAST_YEAR,
  CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT,
  CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT_LAST_YEAR,
  CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT,
  CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT_LAST_YEAR,
  CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT,
  CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT_LAST_YEAR,
  CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT,
  CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT_LAST_YEAR,
  CHART_DATA_TYPE.VALUE_SHARES,
  CHART_DATA_TYPE.VALUE_SHARES_LAST_YEAR,
  CHART_DATA_TYPE.DISTRIBUTION_RANGE,
  CHART_DATA_TYPE.DISTRIBUTION_RANGE_LAST_YEAR,
  CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE,
  CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE_LAST_YEAR,
  CHART_DATA_TYPE.QUANTITY_SHARES,
  CHART_DATA_TYPE.QUANTITY_SHARES_LAST_YEAR,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_LAST_YEAR,
  CHART_DATA_TYPE.VALUE_DYNAMICS,
  CHART_DATA_TYPE.VALUE_DYNAMICS_LAST_YEAR,
  CHART_DATA_TYPE.QUANTITY_DYNAMICS,
  CHART_DATA_TYPE.QUANTITY_DYNAMICS_LAST_YEAR,
  CHART_DATA_TYPE.DYNAMICS_NET_QUANTITY_CHANGE,
  CHART_DATA_TYPE.DYNAMICS_NET_QUANTITY_CHANGE_LAST_YEAR,
  CHART_DATA_TYPE.SHARE_IN_RECEIPTS_CATEGORY,
  CHART_DATA_TYPE.SHARE_IN_RECEIPTS_CATEGORY_LAST_YEAR,
  CHART_DATA_TYPE.SHARE_IN_RECEIPTS_TOTAL_SHOP,
  CHART_DATA_TYPE.SHARE_IN_RECEIPTS_TOTAL_SHOP_LAST_YEAR,
  CHART_DATA_TYPE.VALUE_PERCENT,
  CHART_DATA_TYPE.QUANTITY_SUM_PERCENT,
  CHART_DATA_TYPE.VALUE_SHARES_WS,
  CHART_DATA_TYPE.VALUE_SHARES_WS_LAST_YEAR,
  CHART_DATA_TYPE.VALUE_SHARES_WSO,
  CHART_DATA_TYPE.VALUE_SHARES_WSO_LAST_YEAR,
  CHART_DATA_TYPE.VALUE_SHARES_WSA,
  CHART_DATA_TYPE.VALUE_SHARES_WSA_LAST_YEAR,
  CHART_DATA_TYPE.VALUE_SHARES_WS_WSO,
  CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_LAST_YEAR,
  CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA,
  CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA_LAST_YEAR,
  CHART_DATA_TYPE.QUANTITY_SHARES_WS,
  CHART_DATA_TYPE.QUANTITY_SHARES_WS_LAST_YEAR,
  CHART_DATA_TYPE.QUANTITY_SHARES_WSO,
  CHART_DATA_TYPE.QUANTITY_SHARES_WSO_LAST_YEAR,
  CHART_DATA_TYPE.QUANTITY_SHARES_WSA,
  CHART_DATA_TYPE.QUANTITY_SHARES_WSA_LAST_YEAR,
  CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO,
  CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_LAST_YEAR,
  CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA,
  CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA_LAST_YEAR,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_LAST_YEAR,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO_LAST_YEAR,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA_LAST_YEAR,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_LAST_YEAR,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA_LAST_YEAR
];

const ABSOLUTE_TYPES = [
  CHART_DATA_TYPE.NEW_CUSTOMERS_COUNT,
  CHART_DATA_TYPE.ALL_RETURNS_COUNT,
  CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE,
  CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE_LAST_YEAR,
  CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE,
  CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE_LAST_YEAR,
  CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE,
  CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE_LAST_YEAR,
  CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE,
  CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE_LAST_YEAR,
  CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE,
  CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE_LAST_YEAR,
  CHART_DATA_TYPE.RECEIPTS_PER_SHOP,
  CHART_DATA_TYPE.RECEIPTS_QUANTITY,
  CHART_DATA_TYPE.ITEMS_PER_RECEIPT,
  CHART_DATA_TYPE.VALUE_PER_SHOP,
  CHART_DATA_TYPE.WORTH_PER_RECEIPT,
  CHART_DATA_TYPE.AVG_PRICE,
  CHART_DATA_TYPE.TOTAL_SUPPLY,
  CHART_DATA_TYPE.SHOPS_SUPPLY,
  CHART_DATA_TYPE.INCOMING_SUPPLY,
  CHART_DATA_TYPE.CL_TOTAL_SUPPLY,
  CHART_DATA_TYPE.BED_SUPPLY,
  CHART_DATA_TYPE.NAD_SUPPLY,
  CHART_DATA_TYPE.PLE_SUPPLY,
  CHART_DATA_TYPE.SZA_SUPPLY,
  CHART_DATA_TYPE.TYCH_SUPPLY,
  CHART_DATA_TYPE.TYN_SUPPLY,
  CHART_DATA_TYPE.RADZ_SUPPLY,
  CHART_DATA_TYPE.KM_SUPPLY,
  CHART_DATA_TYPE.WPZ_SHOPS_7,
  CHART_DATA_TYPE.WPZ_CL_TOTAL_7,
  CHART_DATA_TYPE.WPZ_BED_7,
  CHART_DATA_TYPE.WPZ_NAD_7,
  CHART_DATA_TYPE.WPZ_PLE_7,
  CHART_DATA_TYPE.WPZ_SZA_7,
  CHART_DATA_TYPE.WPZ_TYCH_7,
  CHART_DATA_TYPE.WPZ_TYN_7,
  CHART_DATA_TYPE.WPZ_RADZ_7,
  CHART_DATA_TYPE.WPZ_KM_7,
  CHART_DATA_TYPE.WPZ_SHOPS_30,
  CHART_DATA_TYPE.WPZ_CL_TOTAL_30,
  CHART_DATA_TYPE.WPZ_BED_30,
  CHART_DATA_TYPE.WPZ_NAD_30,
  CHART_DATA_TYPE.WPZ_PLE_30,
  CHART_DATA_TYPE.WPZ_SZA_30,
  CHART_DATA_TYPE.WPZ_TYCH_30,
  CHART_DATA_TYPE.WPZ_TYN_30,
  CHART_DATA_TYPE.WPZ_RADZ_30,
  CHART_DATA_TYPE.WPZ_KM_30,
  CHART_DATA_TYPE.WPZ_SHOPS_90,
  CHART_DATA_TYPE.WPZ_CL_TOTAL_90,
  CHART_DATA_TYPE.WPZ_BED_90,
  CHART_DATA_TYPE.WPZ_NAD_90,
  CHART_DATA_TYPE.WPZ_PLE_90,
  CHART_DATA_TYPE.WPZ_SZA_90,
  CHART_DATA_TYPE.WPZ_TYCH_90,
  CHART_DATA_TYPE.WPZ_TYN_90,
  CHART_DATA_TYPE.WPZ_RADZ_90,
  CHART_DATA_TYPE.WPZ_KM_90
];

export const getIsPercentageType = (dataType: string) => {
  return PERCENTAGE_TYPES.includes(dataType);
};

const getIsAbsoluteType = (dataType: string) => {
  return ABSOLUTE_TYPES.includes(dataType);
};

export const formatTooltipValue = (
  value: Nullable<number>,
  dataType: string
): string => {
  if (value === null) {
    return NO_DATA_SHORT;
  }

  if (getIsPercentageType(dataType)) {
    return formatNumber(value, NUMBER_TYPE.PERCENT);
  }

  if (getIsAbsoluteType(dataType)) {
    return formatNumber(value, NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE);
  }

  return formatNumber(value);
};
