import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useFiltersFetchingParams } from "pages/Reports/redux/reducers/filters/hooks/useFiltersFetchingParams";
import {
  CountyHooks,
  CountyItem,
  CountyItemApi
} from "pages/Reports/redux/reducers/sweetStateHooks/useCounty";
import { countiesQueryParamSelector } from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";

type CountiesRequestParams = {
  voivodeship?: string;
};

const fetchCounties = async (params: CountiesRequestParams) => {
  const response = await HTTP.get<CountyItemApi[]>(
    REST_API_ENDPOINTS.COUNTIES,
    {
      params
    }
  );

  return response.data.map(item => ({
    value: item.name || "",
    label: item.name || "",
    voivodeship: item.voivodeship || ""
  }));
};

const useFilterParams = (): CountiesRequestParams => {
  const params = useFiltersFetchingParams(["voivodeships"]);

  // apply `voivodeship` only if it's truthy, otherwise BE will return empty array instead of all counties
  if (params.voivodeships) {
    return {
      voivodeship: params.voivodeships
    };
  }

  return {};
};

const useFetchingFlag = (
  params: CountiesRequestParams,
  isDisabled: boolean
) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching.counties;
  const lastQuery = useSelector(asyncFiltersSelector).query.counties;

  const query = qs.stringify(params);
  if (isLoading || isDisabled) {
    return false;
  }

  if (query === lastQuery) {
    return false;
  }

  return true;
};

export const useCountyFetching = (isDisabled: boolean) => {
  const filter = "counties";
  const [, actions] = CountyHooks.useCounty();
  const dispatch = useDispatch();
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const countyQP = useSelector(countiesQueryParamSelector);

  const params = useFilterParams();
  const isFetchingPossible = useFetchingFlag(params, isDisabled);

  const callback = React.useCallback(
    (counties: CountyItem[]) => {
      actions.updateAllCounties(counties);

      if (isPristine) {
        actions.checkPristineSelectionsAfterFetching(counties, countyQP);
      } else {
        actions.checkSelectionsAfterFetching(
          counties.map(county => county.value)
        );
      }
      actions.sortCounties();
    },
    [isPristine, actions, countyQP]
  );

  React.useEffect(() => {
    if (!isFetchingPossible) {
      return;
    }

    const fetch = async () => {
      try {
        dispatch(updateQuery({ filter, query: qs.stringify(params) }));

        dispatch(setFlag({ filter, isFetching: true }));
        const counties = await fetchCounties(params);
        dispatch(setError({ filter, status: "" }));

        return counties;
      } catch (e) {
        dispatch(setError({ filter, status: "Błąd pobierania powiatów" }));
        return [];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };

    fetch().then(callback);
  }, [dispatch, isFetchingPossible, params, callback]);
};
