import {
  AggregatedHooks,
  AggregationTypes
} from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";
import {
  pushFiltersEvent,
  pushInsightsAction
} from "utils/googleTagManager/dataLayer";

const dataLayerMap = {
  [AggregationTypes.false]: "brak agregacji",
  [AggregationTypes.attr_group]: "atrybuty z podziałem na grupy",
  [AggregationTypes.attr_total]: "atrybuty total",
  [AggregationTypes.own]: "własnych",
  [AggregationTypes.comp]: "konkurencyjnych",
  [AggregationTypes.both]: "własnych i konkurencyjnych",
  [AggregationTypes.total]: "total"
} as { [key: string]: string };

export const useSubmitDataLayer = (isInsights: boolean) => {
  const [{ selected: aggregated }] = AggregatedHooks.useAggregated();

  const pushSubmitDataLayer = () => {
    if (isInsights) {
      pushInsightsAction("click", "pokaż insights");
    } else if (aggregated in dataLayerMap) {
      pushFiltersEvent(`agregacja danych - ${dataLayerMap[aggregated]}`);
    }
  };

  return { pushSubmitDataLayer };
};
