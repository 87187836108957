import { useLocation } from "react-router";

import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { isThisPage } from "utils";

export type DropdownType = "radio" | "checkbox-single" | "checkbox-multi";

interface Settings {
  first: DropdownType;
  second: DropdownType;
  third: DropdownType;
}

export const useDropdownTypes = (): Settings => {
  const { pathname } = useLocation();

  if (
    isThisPage(pathname, REPORTS_FULL_PATHS.HOURS_PATH) ||
    isThisPage(pathname, REPORTS_FULL_PATHS.LOYALTY_PATH)
  ) {
    return {
      first: "radio",
      second: "radio",
      third: "checkbox-multi"
    };
  }

  return {
    first: "checkbox-single",
    second: "checkbox-single",
    third: "checkbox-multi"
  };
};
