import React from "react";
import { useParams } from "react-router-dom";

import { CountyElement } from "../County/types";
import { VOIVODESHIPS } from "./constants";

export const useCounties = () => {
  const { voivodeship = "" } = useParams<{ voivodeship: string }>();
  const [counties, setCounties] = React.useState<CountyElement[]>([]);

  React.useEffect(() => {
    let abort = false;

    const loadCounties = async (voivodeship: string) => {
      switch (voivodeship) {
        case VOIVODESHIPS.DS: {
          const module = await import("components/D3/County/DSCounties");
          return abort || setCounties(module.DSCounties);
        }
        case VOIVODESHIPS.KP: {
          const module = await import("components/D3/County/KPCounties");
          return abort || setCounties(module.KPCounties);
        }
        case VOIVODESHIPS.LB: {
          const module = await import("components/D3/County/LBCounties");
          return abort || setCounties(module.LBCounties);
        }
        case VOIVODESHIPS.LS: {
          const module = await import("components/D3/County/LSCounties");
          return abort || setCounties(module.LSCounties);
        }
        case VOIVODESHIPS.LD: {
          const module = await import("components/D3/County/LDCounties");
          return abort || setCounties(module.LDCounties);
        }
        case VOIVODESHIPS.MP: {
          const module = await import("components/D3/County/MPCounties");
          return abort || setCounties(module.MPCounties);
        }
        case VOIVODESHIPS.MZ: {
          const module = await import("components/D3/County/MZCounties");
          return abort || setCounties(module.MZCounties);
        }
        case VOIVODESHIPS.OP: {
          const module = await import("components/D3/County/OPCounties");
          return abort || setCounties(module.OPCounties);
        }
        case VOIVODESHIPS.PK: {
          const module = await import("components/D3/County/PKCounties");
          return abort || setCounties(module.PKCounties);
        }
        case VOIVODESHIPS.PL: {
          const module = await import("components/D3/County/PLCounties");
          return abort || setCounties(module.PLCounties);
        }
        case VOIVODESHIPS.PM: {
          const module = await import("components/D3/County/PMCounties");
          return abort || setCounties(module.PMCounties);
        }
        case VOIVODESHIPS.SL: {
          const module = await import("components/D3/County/SLCounties");
          return abort || setCounties(module.SLCounties);
        }
        case VOIVODESHIPS.SK: {
          const module = await import("components/D3/County/SKCounties");
          return abort || setCounties(module.SKCounties);
        }
        case VOIVODESHIPS.WM: {
          const module = await import("components/D3/County/WMCounties");
          return abort || setCounties(module.WMCounties);
        }
        case VOIVODESHIPS.WP: {
          const module = await import("components/D3/County/WPCounties");
          return abort || setCounties(module.WPCounties);
        }
        case VOIVODESHIPS.ZP: {
          const module = await import("components/D3/County/ZPCounties");
          return abort || setCounties(module.ZPCounties);
        }
      }
    };

    loadCounties(voivodeship);
    // avoid race condition
    return () => {
      abort = true;
    };
  }, [voivodeship]);

  return counties;
};
