import { EXPORT_DATA_TYPE } from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";
import { AggregationTypes } from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";
import { CLIENT_TYPE_ITEMS } from "pages/Reports/redux/reducers/sweetStateHooks/useClientType";
import { CLIENT_TYPES, NO_DATA } from "utils";
import { PERIOD_TYPE } from "utils/constants";

const { DAYS, WEEKS, PROMO_WEEKS, MONTHS, YEAR, RANKING, TOTAL } = PERIOD_TYPE;
const {
  GENERAL,
  SEGMENTS,
  COUNTIES,
  VOIVODESHIPS,
  SHOPS,
  HOURS,
  ATTRIBUTES
} = EXPORT_DATA_TYPE;

export const translateDataType = (dataType: string) => {
  switch (dataType) {
    case GENERAL:
      return "Ogólne";
    case SEGMENTS:
      return "Segmenty";
    case VOIVODESHIPS:
      return "Woj.";
    case COUNTIES:
      return "Powiat";
    case SHOPS:
      return "Sklepy";
    case HOURS:
      return "Godziny";
    case ATTRIBUTES:
      return "Atrybuty";
    default:
      return "";
  }
};

export const translateTimeAggregation = (timeAggregation: string) => {
  switch (timeAggregation.toUpperCase()) {
    case DAYS:
      return "dzień";
    case WEEKS:
      return "tydzień";
    case PROMO_WEEKS:
      return "tydzień promocyjny";
    case MONTHS:
      return "miesiąc";
    case YEAR:
      return "rok";
    case TOTAL:
      return "total";
    case RANKING:
      return "ranking";
    default:
      return "";
  }
};

export const translateClientType = (clientType: string) => {
  // items are in the same order as in CLIENT_TYPE_ITEMS
  const index = ["", CLIENT_TYPES.OFFLINE, CLIENT_TYPES.ONLINE].findIndex(
    value => value === clientType
  );

  if (index === -1) return NO_DATA;

  return CLIENT_TYPE_ITEMS.map(({ label }) => label)[index];
};

export const translateDataAggregation = (aggregation: AggregationTypes) => {
  switch (aggregation) {
    case AggregationTypes.false:
      return "Brak agregacji";
    case AggregationTypes.attr_group:
      return "Agregacja do atrybutów z podziałem na grupy";
    case AggregationTypes.attr_total:
      return "Agregacja do atrybutów total";
    case AggregationTypes.total:
      return "Agregacja danych własnych i total danych konkurencji";
    case AggregationTypes.own:
      return "Agregacja danych własnych";
    case AggregationTypes.comp:
      return "Agregacja danych konkurencyjnych";
    case AggregationTypes.both:
      return "Agregacja danych własnych i konkurencyjnych";
    default:
      return "";
  }
};

export const createDataAggregationTooltip = (aggregation: AggregationTypes) => {
  switch (aggregation) {
    case AggregationTypes.false:
      return "";
    case AggregationTypes.attr_group:
      return "Agregacja dostępna wyłącznie przy wyborze atrybutów oraz dostawcy konkurencji w filtrach. Wybór tej opcji zagreguje dane własne i konkurencyjne do wybranych atrybutów i grup.";
    case AggregationTypes.attr_total:
      return "Agregacja dostępna wyłącznie przy wyborze atrybutów oraz dostawcy konkurencji w filtrach. Wybór tej opcji zagreguje dane własne i konkurencyjne do wybranych atrybutów total.";
    case AggregationTypes.total:
      return "Agregacja całkowita danych własnych i konkurencyjnych";
    case AggregationTypes.own:
      return "Agregacja całkowita wyłącznie danych własnych";
    case AggregationTypes.comp:
      return "Agregacja danych konkurencyjnych per dostawca";
    case AggregationTypes.both:
      return "Agregacja całkowita danych własnych i agregacja danych konkurencyjnych per dostawca";
    default:
      return "";
  }
};
