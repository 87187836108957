import {
  Action,
  createHook,
  createStore,
  defaultRegistry
} from "react-sweet-state";

import { DropdownItem } from "components/molecules/types";
import {
  getMatched,
  getSelectedCountLabel,
  remainingElementsToSelect,
  selectElement
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import { sortItemsBySelectionOrder } from "pages/Reports/redux/utils";
import { Nullable } from "utils/types";

export type VendorItem = DropdownItem<string>;

type State = {
  all: VendorItem[];
  selected: VendorItem[];
};
type Actions = typeof actions;

const actions = {
  updateAllVendors: (items: VendorItem[]): Action<State> => ({ setState }) =>
    setState({ all: items }),
  updateSelectedVendors: (
    items: VendorItem[],
    isMultiChoice: boolean
  ): Action<State> => ({ setState }) => {
    if (items.length === 0) return;

    if (isMultiChoice) {
      return setState({ selected: items });
    }
    return setState({
      selected: [items[0]]
    });
  },
  selectVendor: (
    selectedVendor: Nullable<VendorItem>,
    isMultiChoice: boolean,
    cleanup: VoidFunction
  ): Action<State> => ({ setState, getState }) => {
    let selected: VendorItem[];
    if (!selectedVendor) {
      selected = [];
    } else {
      selected = isMultiChoice
        ? selectElement(selectedVendor, getState().selected)
        : [selectedVendor];
    }
    if (!selected.length) cleanup();
    setState({ selected });
  },
  selectAllVendors: (
    inputValue: string,
    isMultiChoice: boolean
  ): Action<State> => ({ setState, getState }) => {
    const state = getState();
    const allVendors = state.all;
    const selectedVendors = state.selected;

    if (!isMultiChoice) {
      return;
    }

    const remainingToSelect = remainingElementsToSelect(
      selectedVendors,
      allVendors,
      inputValue
    );
    setState({
      selected: [...selectedVendors, ...remainingToSelect]
    });
  },
  sortVendors: (): Action<State> => ({ setState, getState }) => {
    const state = getState();

    const allVendors = state.all;
    const selectedVendorsIds = state.selected.map(item => item.value);

    const sortedAllVendors = sortItemsBySelectionOrder(
      selectedVendorsIds,
      allVendors
    );
    setState({
      all: sortedAllVendors
    });
  },
  clearVendors: (): Action<State> => ({ setState }) =>
    setState({ selected: [] }),
  checkSelectionsAfterFetching: (ids: string[]): Action<State> => ({
    getState,
    setState
  }) =>
    setState({
      selected: getMatched(getState().selected, ids)
    }),
  checkPristineSelectionsAfterFetching: (
    companies: VendorItem[],
    venQueryParam: string | string[]
  ): Action<State> => ({ setState }) => {
    const updatedSelectedCompanies = companies.filter(({ value }) => {
      if (typeof venQueryParam === "string") {
        return value === venQueryParam;
      }
      return venQueryParam.includes(value);
    });

    setState({
      selected: updatedSelectedCompanies
    });
  }
};

export const VendorStore = createStore<State, Actions>({
  name: "vendor",
  initialState: {
    all: [],
    selected: []
  },
  actions
});

export const getSelectedCompanies = (state: State, isMultiChoice: boolean) => {
  if (!state.selected.length) return [];

  // workaround to recompute selected items on multi choice state change
  return isMultiChoice ? state.selected : [state.selected[0]];
};

export const getStringifiedSelectedVendorsIds = (
  state: State,
  isMultiChoice: boolean
) => {
  const companies = getSelectedCompanies(state, isMultiChoice);
  return companies.map(item => item.label).join(", ");
};

export const VendorHooks = {
  useVendor: createHook(VendorStore),
  useVendorLabel: createHook(VendorStore, {
    selector: (state: State, isMultiChoice: boolean) => {
      const companies = getSelectedCompanies(state, isMultiChoice);

      if (companies.length === 1) {
        return state.selected[0].label;
      }
      return getSelectedCountLabel(
        companies.length,
        state.all.length,
        isMultiChoice
      );
    }
  }),
  useSelectedVendors: createHook(VendorStore, {
    selector: (state: State, isMultiChoice: boolean) => {
      if (!state.selected.length) return [];

      // workaround to recompute selected items on multi choice state change
      return isMultiChoice ? state.selected : [state.selected[0]];
    }
  }),
  useSelectedVendorsIds: createHook(VendorStore, {
    selector: (state: State, isMultiChoice: boolean) => {
      const companies = getSelectedCompanies(state, isMultiChoice);
      return companies.map(item => item.value);
    }
  }),
  useStringifiedSelectedVendorsIds: createHook(VendorStore, {
    selector: (state: State, isMultiChoice: boolean) => {
      const companies = getSelectedCompanies(state, isMultiChoice);
      return companies.map(item => item.label).join(", ");
    }
  })
};

export const VendorStoreInstance = defaultRegistry.getStore(VendorStore);
