import { useEffect, useState } from "react";

export const useNavbarPosition = (
  navbarHeight: number,
  clampValue: number
): number => {
  const [navbarPosition, setNavbarPosition] = useState(navbarHeight);

  useEffect(() => {
    const listener = () => {
      setNavbarPosition(Math.max(navbarHeight - window.scrollY, clampValue));
    };

    document.addEventListener("scroll", listener);
    return () => document.removeEventListener("scroll", listener);
  }, [clampValue, navbarHeight]);

  return navbarPosition;
};
