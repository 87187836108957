import * as React from "react";
import { useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";

import { Text } from "components/atoms";
import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { CountyHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCounty";
import {
  VoivodeshipHooks,
  VoivodeshipItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useVoivodeship";
import { voivodeshipQueryParamSelector } from "store/selectors/routerSelectors";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

import { DROPDOWN_PLACEHOLDERS } from "../constants";
import { useDisabledTooltip } from "../hooks/useDisabledTooltip";
import { filterItems, isActiveFn } from "../utils";
import s from "./voivodeshipDropdown.module.scss";

type Props = {
  isDisabled: boolean;
};

const useInitialFetching = () => {
  const voivodeshipQP = useSelector(voivodeshipQueryParamSelector);
  const [, actions] = VoivodeshipHooks.useVoivodeship();

  React.useEffect(() => {
    actions.selectInitialVoivodeships(voivodeshipQP);
  }, [actions, voivodeshipQP]);
};

export const VoivodeshipDropdown = ({ isDisabled }: Props) => {
  const [state, actions] = VoivodeshipHooks.useVoivodeship();
  const [countyState, countyActions] = CountyHooks.useCounty();

  useInitialFetching();

  const [isOpen, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const tooltip = useDisabledTooltip(FILTER_NAMES.VOIVODESHIP, isDisabled);

  const [selectedVoivodeshipsLabel] = VoivodeshipHooks.useVoivodeshipLabel();

  const selectMatchingCounties = () => {
    const selectedVoivodeshipNames = state.selected.map(voiv => voiv.label);

    const matchingCounties = countyState.selected.filter(county =>
      selectedVoivodeshipNames.includes(county.voivodeship)
    );

    if (matchingCounties !== countyState.selected) {
      countyActions.updateSelectedCounties(matchingCounties);
    }
  };

  const handleInputValueChange = (value?: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (selectedItem?: VoivodeshipItem) => {
    setOpen(false);

    if (!selectedItem) {
      selectMatchingCounties();
      setInputValue(selectedVoivodeshipsLabel);
      actions.sortVoivodeships();
    }
  };

  const handleStateChange = (
    changes: StateChangeOptions<VoivodeshipItem[]>
  ) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleChange = (item: VoivodeshipItem | null) => {
    setOpen(true);
    actions.selectVoivodeship(item);
  };

  const handleSelectAll = () => {
    actions.selectAllVoivodeships(inputValue);
    pushFiltersEvent("voivodeship - select all");
  };

  const handleClear = () => {
    handleChange(null);
    pushFiltersEvent("voivodeship - clear");
  };

  React.useEffect(() => {
    if (!isOpen) setInputValue(selectedVoivodeshipsLabel);
  }, [isOpen, selectedVoivodeshipsLabel]);

  return (
    <Dropdown<VoivodeshipItem[]>
      isDisabled={isDisabled}
      isMultiChoice
      // @ts-ignore workoaround: downshift calls onInputValueCahnge  with result of itemToString prop call.
      // once we know this fn always returns undefined it makes downshift fully controled and inputValue
      // depends only on state of CategoryDropdown
      itemToString={() => {}}
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      items={state.all}
      selectedItem={state.selected}
      // @ts-ignore
      onChange={handleChange}
      onClear={handleClear}
      onStateChange={handleStateChange}
      openedMenuPlaceholder={DROPDOWN_PLACEHOLDERS.REGION.VOIVODESHIP.OPEN}
      closedMenuPlaceholder={DROPDOWN_PLACEHOLDERS.REGION.VOIVODESHIP.CLOSED}
      onSelectAll={handleSelectAll}
      input={
        <FilterDropdownInput
          isTypingEnabled
          automaticInputValue={selectedVoivodeshipsLabel}
          tooltip={tooltip}
          testId="voivodeship-dropdown"
        />
      }
      menu={
        <FilterDropdownMenu
          isItemActiveFn={isActiveFn}
          filterItemsFn={filterItems}
          placeholder={<Text className={s.placeholder}>pobieranie danych</Text>}
          listItem={<FilterDropdownListItem />}
        />
      }
    />
  );
};
