import { useGetLocationByPeriod } from "api/location/mappers/useGetLocationByPeriod";
import { useLocationGeneralWarnings } from "api/location/mappers/useLocationGeneralWarnings";
import { useRankingWarnings } from "api/ranking/mappers";

export const useLocationWarnings = (): string[] => {
  const generalWarnings = useLocationGeneralWarnings();
  const info = useGetLocationByPeriod().data?.data.info || [];
  const rankingWarnings = useRankingWarnings();

  return [...generalWarnings, ...info, ...rankingWarnings].filter(
    warning => !!warning
  );
};
