import React from "react";

import { Text } from "components/atoms";
import { Material } from "pages/Reports/redux/reducers/promotionsReducer";
import { TEXT_WRAPPERS } from "utils";

import s from "./promotionContent.module.scss";
import { showPromotionProducts } from "./utils";

type Props = {
  materials: Material[];
};

export const CompetingMaterials = ({ materials }: Props) => {
  if (!Boolean(materials.length)) return null;

  return (
    <div className={s.competitionMaterials}>
      <Text Wrapper={TEXT_WRAPPERS.P} className={s.competitionHeader}>
        Konkurencja
      </Text>
      {(materials as Material[]).map((company, index) => (
        <Text
          Wrapper={TEXT_WRAPPERS.P}
          className={s.productNames}
          key={`competing-${company?.vendor_name}-index`}
        >
          <Text
            Wrapper={TEXT_WRAPPERS.SPAN}
            className={s.competitionCompanyName}
          >
            {company.vendor_name}
          </Text>
          {showPromotionProducts(company.data)}
        </Text>
      ))}
    </div>
  );
};
