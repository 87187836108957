import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import {
  HierarchyProductsApi,
  HierarchyProductsDownloadExcelApi,
  HierarchyProductsMetrics
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/ProductHierarchy/utils";
import { getBackendReportType } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { APP_PATHS, HTTP, isThisPage, REST_API_ENDPOINTS } from "utils";
import { pushFiltersSectionToggleEvent } from "utils/googleTagManager/dataLayer";

import { useFiltersFetchingParams } from "./useFiltersFetchingParams";

const transformHierarchyProductResponse = (
  data: HierarchyProductsApi[]
): HierarchyProductsMetrics[] =>
  data.map(product => ({
    brand: product.brand || "",
    cat1Name: product.cat_1_name || "",
    cat2Name: product.cat_2_name || "",
    cat3Name: product.cat_3_name || "",
    inSale: product.in_sale || false,
    isOwn: product.is_own || false,
    name: product.object_name || "",
    sku: String(product.sku) || "",
    subBrand: product.sub_brand || "",
    vendorName: product.vendor_name || ""
  }));

const STALE_TIME = 1000 * 60 * 60 * 10; // 10 hours

const useProductHierarchyFilterParams = () => {
  const pathname = useSelector(pathnameSelector);
  const reportTypeParam = getBackendReportType(pathname);

  const isExportPage = isThisPage(pathname, APP_PATHS.EXPORT);
  const params = useFiltersFetchingParams([
    "category",
    "categoryLevel",
    "vendor",
    "brand",
    "subBrand",
    "product",
    "competitorCompany",
    "competitorBrand",
    "competitorSubBrand",
    "competitorProduct"
  ]);

  // in case of multiple vendors selected on export page,
  // all selected vendors should be moved to "companies" param
  const shouldMoveVendorToCompanies =
    isExportPage && params.vendor.includes(",");

  const vendorIdParam = shouldMoveVendorToCompanies ? "" : params.vendor;
  const companiesParam = shouldMoveVendorToCompanies
    ? params.vendor
    : params.competitorCompany;

  return {
    categories: params.category,
    category_level: params.categoryLevel,
    vendor_id: vendorIdParam, //own company
    companies: companiesParam, //competition companies
    brands: params.brand,
    sub_brands: params.subBrand,
    competing_brands: params.competitorBrand,
    competing_sub_brands: params.competitorSubBrand,
    materials: params.product,
    competing_materials: params.competitorProduct,
    report_type: reportTypeParam
  };
};

export const useHierarchyProductFetching = () => {
  const params = useProductHierarchyFilterParams();

  const { data, isFetching } = useQuery(
    ["productHierarchy", params],

    async () => {
      const response = await HTTP.get<HierarchyProductsApi[]>(
        REST_API_ENDPOINTS.HIERARCHY_PRODUCTS,
        {
          params
        }
      );

      return transformHierarchyProductResponse(response.data);
    },
    { staleTime: STALE_TIME, placeholderData: [] }
  );

  return { isFetching, data: data || [] };
};

export const useDownloadHierarchyProductExcel = () => {
  const params = useProductHierarchyFilterParams();

  const [isUrlFetching, setUrlFetching] = useState<boolean>(false);

  const downloadProductHierarchyExcel = async () => {
    setUrlFetching(true);
    pushFiltersSectionToggleEvent("Hierarchia - pobierz");
    try {
      const response = await HTTP.get<HierarchyProductsDownloadExcelApi>(
        REST_API_ENDPOINTS.HIERARCHY_PRODUCTS_EXPORT,
        {
          params
        }
      );

      window.location.assign(response.data.url);
    } finally {
      setUrlFetching(false);
    }
  };

  return { isUrlFetching, downloadProductHierarchyExcel };
};
