import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const BrokenCircle = ({
  className,
  color = "#77ABFF",
  size = ICON_SIZES.NORMAL
}: Props) => {
  const iconSize = SIZES[size];
  return (
    <svg
      className={className}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 16 16"
      version="1.1"
    >
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 8a7 7 0 111.783 4.667"
      />
    </svg>
  );
};
