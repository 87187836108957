import { useSelector } from "react-redux";

import { locationSelector } from "pages/Reports/sections/Location/redux/selectors/locationRawSelectors";

export const useLocationHoveredRegion = () => {
  const { chart } = useSelector(locationSelector);
  const { hoveredRegion } = chart;

  return hoveredRegion;
};
