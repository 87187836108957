import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarYears
} from "date-fns";

import { newDateByTimezone } from "store/utils";
import { PERIOD_TYPE, PROMO_WEEK_START_DAY } from "utils/constants";

export const countDatesDifference = (
  from: string,
  to: string,
  chartTimePeriod: string
) => {
  switch (chartTimePeriod) {
    case PERIOD_TYPE.DAYS:
      return differenceInCalendarDays(
        newDateByTimezone(to),
        newDateByTimezone(from)
      );
    case PERIOD_TYPE.WEEKS:
      return differenceInCalendarWeeks(
        newDateByTimezone(to),
        newDateByTimezone(from)
      );
    case PERIOD_TYPE.PROMO_WEEKS:
      return differenceInCalendarWeeks(
        newDateByTimezone(to),
        newDateByTimezone(from),
        { weekStartsOn: PROMO_WEEK_START_DAY }
      );
    case PERIOD_TYPE.MONTHS:
      return differenceInCalendarMonths(
        newDateByTimezone(to),
        newDateByTimezone(from)
      );
    case PERIOD_TYPE.YEAR:
      return differenceInCalendarYears(
        newDateByTimezone(to),
        newDateByTimezone(from)
      );
    default:
      return 1;
  }
};
