export const DROPDOWN_LABELS = {
  CATEGORY: "Kategoria",
  ATTRIBUTES: "Atrybuty",
  PRODUCT: "Produkt",
  COMPANY: "Dostawca",
  CHART_OPTIONS: "Opcje wykresu",
  EXPORT_OPTIONS: "Opcje eksportu",
  DELAY: "Dane konkurencji",
  BRAND: "Marka",
  SUB_BRAND: "Submarka",
  EXPORT_DATA_TYPE: "Rodzaj danych",
  TIME_AGGREGATION: "Agregacja czasowa",
  DATA_AGGREGATION: "Agregacja danych",
  DATE: "Przedział czasu",
  PROMOTIONS: "Typ promocji"
};

export const DROPDOWN_PLACEHOLDERS = {
  ATTRIBUTES: {
    CLOSED: "Wybierz atrybuty z listy",
    OPEN: "Wybierz atrybuty z listy"
  },
  PRODUCT: {
    CLOSED: "Wpisz nazwę produktu",
    OPEN: "Wpisz nazwę produktu"
  },
  BRAND: {
    CLOSED: "Wpisz nazwę marki",
    OPEN: "Wpisz nazwę marki"
  },
  SUB_BRAND: {
    CLOSED: "Wpisz nazwę submarki",
    OPEN: "Wpisz nazwę submarki"
  },
  COMPANY: {
    CLOSED: "Wpisz nazwę firmy",
    OPEN: "Wpisz nazwę firmy"
  },
  REGION: {
    VOIVODESHIP: {
      CLOSED: "Wszystkie województwa",
      OPEN: "Wpisz nazwę województwa"
    },
    COUNTY: { CLOSED: "Wszystkie powiaty", OPEN: "Wpisz nazwę powiatu" }
  },
  STORE_TYPE: {
    CLOSED: "Wszystkie segmenty",
    OPEN: "Wpisz nazwę rodzaju sklepu"
  },
  STORE_TYPE_META: {
    CLOSED: "Wszystkie metasegmenty",
    OPEN: "Wpisz nazwę rodzaju sklepu"
  },
  CLIENT_TYPE: {
    CLOSED: "Wpisz rodzaj transakcji",
    OPEN: "Wpisz rodzaj transakcji"
  },
  TIME_AGGREGATION: {
    CLOSED: "Wpisz nazwę agregacji czasowej",
    OPEN: "Wpisz nazwę agregacji czasowej"
  },
  DATA_AGGREGATION: {
    CLOSED: "Wybierz sposób agregacji",
    OPEN: "Wybierz sposób agregacji"
  },
  REFERENCE_CATEGORIES: {
    CLOSED: "Wybierz kategorie z listy",
    OPEN: "Wybierz kategorie z listy"
  },
  REFERENCE_BRANDS: {
    CLOSED: "Wybierz marki z listy",
    OPEN: "Wybierz marki z listy"
  },
  PROMOTIONS: {
    CLOSED: "Wybierz promocje z listy",
    OPEN: "Wybierz promocje z listy"
  }
};
