import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { ReferenceCategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useReferenceCategory";
import { queryParamsSelector } from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";

import { useFiltersFetchingParams } from "./useFiltersFetchingParams";

type FilterParams = ReturnType<typeof useFilterParams>;

type CategoryItemApi = {
  id: number;
  name: string;
  level: 1 | 2 | 3;
  start_date: string;
  is_fake: boolean;
  parent: CategoryItemApi | null;
};

const fetchCategoriesByLevel = (params: {
  level: string;
  tier: string;
  companies: string | undefined;
  ids: string | undefined;
  parents: string | undefined;
}) => {
  return HTTP.get<CategoryItemApi[]>(REST_API_ENDPOINTS.CATEGORIES, { params });
};

const fetchAndTransformCategories = async (params: FilterParams) => {
  const datasets: CategoryItemApi[][] = [[], [], []];
  const level = Number(params.level);
  // array of levels we need to fetch: 1 - [1, 2, 3], 2 - [2, 3], 3 - [3]
  const levelsToFetch = Array.from(
    { length: 4 - level },
    (_, index) => level + index
  );

  for (const currentLevel of levelsToFetch) {
    const first = currentLevel === level;
    // dataset of previous level e.g. for level 3 we need dataset of level 2 which is datasets[1]
    const prev = datasets[currentLevel - 2];

    if (!first && !prev.length) break;

    const response = await fetchCategoriesByLevel({
      level: String(currentLevel),
      tier: params.tier,
      companies: params.companies,
      ids: first ? params.category : undefined, // this should be defiend only for first level
      parents: first ? undefined : prev.map(({ id }) => String(id)).join(",") // this should be defined for subsequent levels
    });
    datasets[currentLevel - 1] = response.data;
  }

  return datasets.map(data =>
    data.map(item => ({
      label: item.name,
      value: item.id,
      parent: item.parent,
      startDate: item.start_date,
      isFake: item.is_fake,
      level: item.level
    }))
  );
};

const useFilterParams = () => {
  const params = useFiltersFetchingParams([
    "category1",
    "category2",
    "category3",
    "vendor",
    "tier"
  ]);

  const [category, level] = (() => {
    if (params.category1) return [params.category1, "1"];
    if (params.category2) return [params.category2, "2"];
    return [params.category3, "3"];
  })();

  return {
    category,
    level,
    tier: params.tier,
    companies: params.vendor ? params.vendor : undefined
  };
};

const useFetchingFlag = (params: FilterParams, disabled: boolean) => {
  const asyncFilters = useSelector(asyncFiltersSelector);
  const fetching = asyncFilters.fetching.referenceCategory;

  if (fetching || disabled) {
    return false;
  }

  const query = qs.stringify(params);
  const lastQuery = asyncFilters.query.referenceCategory;

  if (query === lastQuery) {
    return false;
  }

  if (!params.category || !params.level || !params.tier) {
    return false;
  }

  return true;
};

export const useReferenceCategoryBehaviour = (disabled: boolean) => {
  const dispatch = useDispatch();
  const queryParams = useSelector(queryParamsSelector);
  const { isFormPristine } = useSelector(asyncFiltersSelector);
  const filterParams = useFilterParams();
  const isFetchingPossible = useFetchingFlag(filterParams, disabled);
  const [, actions] = ReferenceCategoryHooks.useReferenceCategory();

  useEffect(() => {
    if (!isFetchingPossible) return;

    const handleFetching = async () => {
      const filter = "referenceCategory";
      try {
        dispatch(updateQuery({ filter, query: qs.stringify(filterParams) }));
        dispatch(setFlag({ filter, isFetching: true }));

        const datasets = await fetchAndTransformCategories(filterParams);
        actions.updateAllCategories(datasets[0], 1);
        actions.updateAllCategories(datasets[1], 2);
        actions.updateAllCategories(datasets[2], 3);

        if (isFormPristine) {
          actions.checkPristineSelectionsAfterFetching(datasets, queryParams);
        } else {
          actions.checkSelectionsAfterFetching(datasets);
        }

        dispatch(setError({ filter, status: "" }));
      } catch (error) {
        dispatch(
          setError({
            filter,
            status: "Błąd pobierania kategorii referencyjnych"
          })
        );
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };

    handleFetching();
  }, [
    actions,
    dispatch,
    filterParams,
    isFetchingPossible,
    isFormPristine,
    queryParams
  ]);
};
