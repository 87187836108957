import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { useLocationChartData } from "api/location/mappers/useLocationChartData";
import { useLocationMapGeneralChartsData } from "api/location/mappers/useLocationMapGeneralChartData";

import { CHART_PERIODS } from "components/D3/constants";
import MapChartDetailed from "components/D3/Map/MapChartDetailed";
import { MapChartGeneral } from "components/D3/Map/MapChartGeneral";
import { ChartSection } from "pages/Reports/partials/Chart/StandardChart/ChartSection";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { ShowDatasetSelect } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/ShowDatasetSelect";
import { isShowDatasetSelectEnabledSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { chartPeriodSelector } from "store/selectors/routerSelectors";

import { useChartTypeBoolean } from "../ChartTypeToggle/hooks";
import s from "./mapChartSwitch.module.scss";

const { LOCATION_PATH } = REPORTS_FULL_PATHS;

export const MapChartSwitch = () => {
  const chartPeriod = useSelector(chartPeriodSelector);
  const isShowDatasetSelectEnabled = useSelector(
    isShowDatasetSelectEnabledSelector
  );
  const { both: isBothDatasets } = useCurrentDataset();
  const chart = useLocationChartData({});
  const historicalChart = useLocationChartData({ isForHistoricalChart: true });
  const mapsCount = useLocationMapGeneralChartsData()?.length;
  const { isGroupedBarChart } = useChartTypeBoolean();
  const gridColumns = Math.min(
    isBothDatasets ? mapsCount * 2 : mapsCount,
    isBothDatasets ? 4 : 3
  );

  const showHistoricalGroupedBarChart =
    !chart.isTimelineMerged &&
    Boolean(historicalChart) &&
    isBothDatasets &&
    isGroupedBarChart;

  return (
    <div className={s.base}>
      {chartPeriod === CHART_PERIODS.TOTAL ? (
        <>
          {isShowDatasetSelectEnabled && <ShowDatasetSelect />}
          <div
            className={s.mapWrapper}
            style={{ gridTemplateColumns: `repeat(${gridColumns}, 1fr)` }}
          >
            <Switch>
              <Route
                path={`${LOCATION_PATH}/:voivodeship`}
                component={MapChartDetailed}
              />
              <Route component={MapChartGeneral} />
            </Switch>
          </div>
        </>
      ) : (
        <>
          <ChartSection lineChart={chart} />
          {showHistoricalGroupedBarChart && (
            <ChartSection
              key={`location-chartsection-${historicalChart.labels.bottom}-historical`}
              lineChart={historicalChart}
            />
          )}
        </>
      )}
    </div>
  );
};
