import { ParsedQuery } from "query-string";

import { MULTIPACK_TIER_4_HIDDEN_TYPES } from "components/molecules/ChartHeader/utils";
import { CHART_DATA_TYPE, isThisPage } from "utils";
import { QP } from "utils/defaultQueryParams";
import { Tier } from "utils/types";

import { REPORTS_FULL_PATHS } from "../constants";

// [PMD-4125]: disable sales related multipack metrics for non-power tier 4 users
export const adjustMultipackParams = (
  params: ParsedQuery<string>,
  pathname: string,
  isPowerUser: boolean,
  tier: Tier
): ParsedQuery<string> => {
  if (
    !isThisPage(pathname, REPORTS_FULL_PATHS.MULTIPACK_PATH) ||
    isPowerUser ||
    tier !== 4
  ) {
    return params;
  }

  const first = String(params[QP.CHART_DATA_TYPE_FIRST]).toUpperCase();
  const second = String(params[QP.CHART_DATA_TYPE_SECOND]).toUpperCase();

  if (MULTIPACK_TIER_4_HIDDEN_TYPES.includes(first)) {
    params[QP.CHART_DATA_TYPE_FIRST] = CHART_DATA_TYPE.NONE;
  }

  if (MULTIPACK_TIER_4_HIDDEN_TYPES.includes(second)) {
    params[QP.CHART_DATA_TYPE_SECOND] = CHART_DATA_TYPE.NONE;
  }

  return params;
};
