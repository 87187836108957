import { createHook, createStore } from "react-sweet-state";

import { BOOL_STRING_VALUES, TOURS_KEYS } from "utils";
import { MODAL_KEYS } from "utils/constants";

const {
  IS_SORTABLE_SIDEBAR_TOUR_HIDDEN,
  IS_SELECTING_SIDEBAR_TOUR_HIDDEN,
  IS_SIDEBAR_CHANGE_TOUR_HIDDEN,
  IS_INSIGHTS_NAV_TOUR_HIDDEN,
  IS_EXPORT_NAV_TOUR_HIDDEN,
  IS_ZAPPKA_SHARES_TOUR_HIDDEN
} = TOURS_KEYS;
const { TRUE } = BOOL_STRING_VALUES;

const getIsHidden = (key: string) => {
  return localStorage.getItem(key) === TRUE;
};

const Store = createStore({
  initialState: {
    isSortableSidebarTourHidden: getIsHidden(IS_SORTABLE_SIDEBAR_TOUR_HIDDEN),
    isSelectingSidebarTourHidden: getIsHidden(IS_SELECTING_SIDEBAR_TOUR_HIDDEN),
    isSidebarChangeTourHidden: getIsHidden(IS_SIDEBAR_CHANGE_TOUR_HIDDEN),
    isInsightsNavTourHidden: getIsHidden(IS_INSIGHTS_NAV_TOUR_HIDDEN),
    isExportNavTourHidden: getIsHidden(IS_EXPORT_NAV_TOUR_HIDDEN),
    isSharesCheckboxTourHidden: getIsHidden(IS_ZAPPKA_SHARES_TOUR_HIDDEN),
    isExportRedirectTourHidden: true,
    isHoursOnboaringModalHidden: getIsHidden(MODAL_KEYS.IS_HOURS_MODAL_HIDDEN),
    isLoyaltyOnboaringModalHidden: getIsHidden(
      MODAL_KEYS.IS_LOYALTY_MODAL_HIDDEN
    ),
    isLogisticsOnboaringModalHidden: getIsHidden(
      MODAL_KEYS.IS_LOGISTICS_BETA_VERSION_MODAL_HIDDEN
    ),
    isSellOnboardingModalHidden: getIsHidden(MODAL_KEYS.IS_SELL_MODAL_HIDDEN)
  },
  actions: {
    setSortableSidebarTourHidden: (value: boolean) => ({ setState }) => {
      setState({ isSortableSidebarTourHidden: value });
    },
    setSelectingSidebarTourHidden: (value: boolean) => ({ setState }) => {
      setState({ isSelectingSidebarTourHidden: value });
    },
    setSidebarChangeTourHidden: (value: boolean) => ({ setState }) => {
      setState({ isSidebarChangeTourHidden: value });
    },
    setInsightsNavTourHidden: (value: boolean) => ({ setState }) => {
      setState({ isInsightsNavTourHidden: value });
    },
    setExportNavTourHidden: (value: boolean) => ({ setState }) => {
      setState({ isExportNavTourHidden: value });
    },
    setSharesCheckboxTourHidden: (value: boolean) => ({ setState }) => {
      setState({ isSharesCheckboxTourHidden: value });
    },
    setExportRedirectTourHidden: (value: boolean) => ({ setState }) => {
      setState({ isExportRedirectTourHidden: value });
    },
    setHoursOnboardingModalHidden: (value: boolean) => ({ setState }) => {
      setState({ isHoursOnboaringModalHidden: value });
    },
    setLoyaltyOnboardingModalHidden: (value: boolean) => ({ setState }) => {
      setState({ isLoyaltyOnboaringModalHidden: value });
    },
    setLogisticsOnboardingModalHidden: (value: boolean) => ({ setState }) => {
      setState({ isLogisticsOnboaringModalHidden: value });
    },
    setSellOnboardingModalHidden: (value: boolean) => ({ setState }) => {
      setState({ isSellOnboardingModalHidden: value });
    }
  }
});

export const TourHooks = {
  useTours: createHook(Store),
  useIsSidebarTourHidden: createHook(Store, {
    selector: state => {
      return [
        state.isSortableSidebarTourHidden,
        state.isSelectingSidebarTourHidden,
        state.isSidebarChangeTourHidden
      ].every(Boolean);
    }
  }),
  useIsNavTourHidden: createHook(Store, {
    selector: state => {
      return [state.isInsightsNavTourHidden, state.isExportNavTourHidden].every(
        Boolean
      );
    }
  })
};
