import { DATASET_TYPES } from "utils";
import { Nullable, Values } from "utils/types";

const ORDER = Object.values(DATASET_TYPES);

export const sortByName = (a: string, b: string) => {
  return a.localeCompare(b);
};

export const sortByVendorName = (a: Nullable<string>, b: Nullable<string>) => {
  if (!a || !b) return -1;
  return sortByName(a, b);
};

export const sortByType = (
  a: Values<typeof DATASET_TYPES>,
  b: Values<typeof DATASET_TYPES>
) => {
  return ORDER.indexOf(a) - ORDER.indexOf(b);
};
