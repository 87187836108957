import qs, { ParseOptions, StringifyOptions } from "query-string";
import { toLower } from "ramda";

export const PARSE_OPTIONS: ParseOptions = {
  arrayFormat: "comma",
  decode: true
};

export const STRINGIFY_OPTIONS: StringifyOptions = {
  arrayFormat: "comma",
  encode: true
};

export const adjustObjectProperty = (
  key: string,
  availableValues: string[],
  defaultValue: string | string[],
  search: string
) => {
  let objQuery = qs.parse(search, PARSE_OPTIONS);

  const availableValuesLower = availableValues.map(toLower);
  const searchValueLower = toLower(String(objQuery[toLower(key)]));

  let defaultValueLower: string | string[] = "";

  if (Array.isArray(defaultValue)) {
    defaultValueLower = defaultValue.map(toLower);
  } else {
    defaultValueLower = toLower(defaultValue);
  }

  const searchValueIsValid = availableValuesLower.includes(searchValueLower);

  if (searchValueIsValid) {
    return search;
  }

  objQuery[toLower(key)] = defaultValueLower;
  return qs.stringify(objQuery, STRINGIFY_OPTIONS);
};
