import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { useLocationGridChartLegend } from "api/location/mappers/useLocationGridChartLegend";
import { flatten } from "ramda";

import { Button } from "components/atoms";
import { isRestrictedToOneLineSelector } from "components/molecules/ChartTypeToggle/hooks";
import c from "pages/Reports/partials/Chart/Legend/common.module.scss";
import { useLocationLegendLineNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendLineNames";
import s from "pages/Reports/partials/Chart/Legend/Legend/legends.module.scss";
import { LegendItems } from "pages/Reports/partials/Chart/Legend/LegendItems/LegendItems";
import { LegendSubSection } from "pages/Reports/partials/Chart/Legend/LegendSubSection/LegendSubSection";
import {
  hoveredLineSelector,
  toggleChart,
  updateHoveredLine
} from "pages/Reports/redux/reducers/chartReducer";
import { NO_DATA } from "utils/constants";

export type LocationLegendType = {
  label: string;
  isDisabled: boolean;
  sections: {
    label: string;
    regions: {
      label: string;
      region: {
        text: string;
        id: string;
        color: string;
        isLineHidden?: boolean;
      }[];
    }[];
  }[];
};

export const LocationLegend = () => {
  const dispatch = useDispatch();
  const tabs: LocationLegendType[] = useLocationGridChartLegend();
  const isRestrictedToOneLine = useSelector(isRestrictedToOneLineSelector);
  const legendLineNames = useLocationLegendLineNames();
  const hoveredLine = useSelector(hoveredLineSelector);

  const [tabIndex, setTabIndex] = useState(0);

  const onClick = (labels: string[]) => {
    if (isRestrictedToOneLine) {
      dispatch(toggleChart([]));
      dispatch(toggleChart(legendLineNames));
    }

    dispatch(toggleChart(labels));
  };

  const onMouse = (labels: string[]) => {
    if (!isRestrictedToOneLine) {
      dispatch(updateHoveredLine(labels));
    }
  };

  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList>
          <li className={s.legend}>Legenda:</li>
          {tabs.map((tab, index) => (
            <Tab
              key={`location-legend-tab-${index}`}
              data-testid={`location-legend-tab-${tab.label}`}
              disabled={tab.isDisabled}
              selectedClassName={c.activeTab}
              disabledClassName={s.disabledTab}
            >
              <Button
                disabled={tab.isDisabled}
                className={s.tabTitle}
                data-ga-graph-legend={tab.label}
              >
                {tab.label}
              </Button>
            </Tab>
          ))}
        </TabList>

        {tabs.map((tab, index) => (
          <TabPanel
            key={`location-legend-tab-panel-${index}`}
            data-testid={`location-legend-tab-panel-${tab.label}`}
          >
            {tab.sections.map(section => {
              const isDisabled = tab.label === NO_DATA;

              return (
                <div
                  key={`location-legend-sub-section-${section.label}`}
                  data-testid={`location-legend-sub-section-${section.label}`}
                >
                  <LegendSubSection
                    isDisabled={isDisabled}
                    sectionTitle={section.label}
                    onClick={isRestrictedToOneLine ? () => {} : onClick}
                    items={flatten(
                      section.regions.map(regions =>
                        regions.region.map(reg => ({
                          ...reg,
                          text: `${regions.label} - ${reg.text}`
                        }))
                      )
                    )}
                  >
                    {section.regions.map(regions => {
                      return (
                        <div
                          key={`location-legend-region-${regions.label}`}
                          data-testid={`location-legend-region-${regions.label}`}
                        >
                          <LegendSubSection
                            isDisabled={isDisabled}
                            onClick={isRestrictedToOneLine ? () => {} : onClick}
                            sectionTitle={regions.label}
                            items={flatten(
                              regions.region.map(reg => ({
                                ...reg,
                                text: `${regions.label} - ${reg.text}`
                              }))
                            )}
                          >
                            <LegendItems
                              isDisabled={isDisabled}
                              items={regions.region}
                              itemsTitle={regions.label}
                              hoveredLine={hoveredLine}
                              onClick={text => onClick(text)}
                              onMouse={text => onMouse(text ? text : [])}
                            />
                          </LegendSubSection>
                        </div>
                      );
                    })}
                  </LegendSubSection>
                </div>
              );
            })}
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
};
