import React from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import { DropdownItem } from "components/molecules/types";
import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useFiltersFetchingParams } from "pages/Reports/redux/reducers/filters/hooks/useFiltersFetchingParams";
import { VendorHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { vendorIdQueryParamSelector } from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";

type Company = {
  id: string;
  display: string;
};

type CompanyItem = DropdownItem<string>;

type CompanyRequestParams = {
  categories?: string;
  category_level?: string;
  attributes?: string;
  vendor_id?: [];
};

const fetchCompanies = async (params: CompanyRequestParams) => {
  const response = await HTTP.get<Company[]>(
    REST_API_ENDPOINTS.COMPANIES.GET_POWERUSER_COMPANIES,
    { params }
  );
  return response.data.map(data => ({
    value: data.id,
    label: data.display
  }));
};

const useFilterParams = () => {
  const params = useFiltersFetchingParams([
    "category",
    "categoryLevel",
    "attributes"
  ]);

  const base: CompanyRequestParams = {
    categories: params.category,
    category_level: params.categoryLevel,
    vendor_id: []
  };

  if (params.attributes) {
    Object.assign(base, { attributes: params.attributes });
  }

  return base;
};

const useFetchingFlag = (
  params: {
    categories?: string;
    category_level?: string;
  },
  isDisabled: boolean
) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching.company;
  const lastQuery = useSelector(asyncFiltersSelector).query.company;

  const query = qs.stringify(params);
  if (isLoading || isDisabled) {
    return false;
  }

  if (query === lastQuery) {
    return false;
  }

  if (!params.categories || !params.category_level) {
    return false;
  }

  return true;
};

const useSupplierFetching = (isDisabled: boolean) => {
  const filter = "company";
  const dispatch = useDispatch();

  const params = useFilterParams();
  const isFetchingPossible = useFetchingFlag(params, isDisabled);

  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;

  const vendorQP = String(useSelector(vendorIdQueryParamSelector));
  const [, actions] = VendorHooks.useVendor();

  const saveAndSelect = React.useCallback(
    (companies: CompanyItem[]) => {
      actions.updateAllVendors(companies);
      if (isPristine) {
        actions.checkPristineSelectionsAfterFetching(companies, vendorQP);
      } else {
        actions.checkSelectionsAfterFetching(companies.map(item => item.value));
      }
      actions.sortVendors();
    },
    [isPristine, actions, vendorQP]
  );

  React.useEffect(() => {
    if (!isFetchingPossible) {
      return;
    }

    const fetch = async () => {
      try {
        dispatch(updateQuery({ filter, query: qs.stringify(params) }));
        dispatch(setFlag({ filter, isFetching: true }));
        const companies = await fetchCompanies(params);
        saveAndSelect(companies);
        dispatch(setError({ filter, status: "" }));
      } catch (e) {
        dispatch(setError({ filter, status: "Błąd pobierania dostawców" }));
        return [];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };

    fetch();
  }, [dispatch, isFetchingPossible, params, saveAndSelect]);
};

export const useSupplierBehaviour = (isDisabled: boolean) => {
  useSupplierFetching(isDisabled);
};
