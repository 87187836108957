import {
  useDownloadHierarchyProductExcel,
  useHierarchyProductFetching
} from "pages/Reports/redux/reducers/filters/hooks/useHierarchyProductFetching";

import { HierarchyModal } from "../HierarchyModal/HierarchyModal";

export const ProductHierarchyModal = ({
  onClose
}: {
  onClose: VoidFunction;
}) => {
  const { isFetching, data } = useHierarchyProductFetching();
  const {
    isUrlFetching,
    downloadProductHierarchyExcel: downloadExcel
  } = useDownloadHierarchyProductExcel();

  return (
    <HierarchyModal
      id="js-product-hierarchy-popup"
      data={data}
      columns={{
        name: "Nazwa produktu",
        vendorName: "Nazwa dostawcy",
        brand: "Marka",
        subBrand: "Submarka",
        sku: "Numer SKU",
        cat1Name: "Kategoria G1",
        cat2Name: "Kategoria G2",
        cat3Name: "Kategoria G3"
      }}
      isFetching={isFetching}
      isUrlFetching={isUrlFetching}
      downloadText="Pobierz hierarchię do Excela"
      emptyText="Brak produktów"
      onDownload={() => downloadExcel()}
      onClose={onClose}
    />
  );
};
