import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { format } from "d3";
import { axisRight, ScaleLinear, select } from "d3";

import c from "pages/Reports/partials/Chart/common.module.scss";
import { secondChartDataTypeSelector } from "store/selectors/routerSelectors";

import { getShowDecimals } from "./utils";

type Props = {
  yScale: ScaleLinear<number, number>;
  width: number;
  ticks: number[];
  maxValue: number;
};

// Right axis is rendered on the left side of the chart, so we have to translate it by chart width to the right side
export const RightAxis: React.FC<Props> = ({
  yScale,
  width,
  ticks,
  maxValue
}) => {
  const secondDataType = useSelector(secondChartDataTypeSelector);
  const showDecimals = getShowDecimals(maxValue, secondDataType);

  const gRef = useRef<SVGSVGElement>(null);
  useEffect(() => {
    if (gRef.current) {
      const axis = select(gRef.current);

      axis.call(axisRight(yScale).tickValues(ticks));
      axis.call((g: any) => g.select(".domain").remove());
      axis.selectAll("line").remove();
      axis.selectAll("text").attr("class", c.text);

      if (showDecimals) {
        axis
          .selectAll("text")
          .text(value => format(",")(value as number).replace(/,/g, " "));
        axis
          .selectAll<SVGGElement, string>("text")
          .text((stringValue, i: number) => {
            const value = Number(stringValue);
            return value.toFixed(2).replace(".", ",");
          });
      }
    }
  }, [gRef, yScale, ticks, showDecimals]);

  return (
    <g
      className="rightAxisTicks"
      transform={`translate(${width}, 0)`}
      ref={gRef}
    />
  );
};
