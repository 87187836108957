import { useDispatch, useSelector } from "react-redux";

import { uniq } from "ramda";

import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import {
  fetchedHistoricalPromotions,
  fetchedPromotionsSelector
} from "pages/Reports/redux/selectors/promotionsSelectors";
import { updateQueryParams } from "store/actions/routerActions";
import { queryParamsSelector } from "store/selectors/routerSelectors";
import { QP } from "utils/defaultQueryParams";

export const usePromotionsOptions = () => {
  const dispatch = useDispatch();
  const params = useSelector(queryParamsSelector);
  const basePromotions = useSelector(fetchedPromotionsSelector);
  const historicalPromotions = useSelector(fetchedHistoricalPromotions);
  const dataset = useCurrentDataset();
  const { isGroupedBarChart } = useChartTypeBoolean();

  const baseOptions = basePromotions.map(p => p.display);
  const historicalOptions = historicalPromotions.map(p => p.display);
  let options: string[] = [];

  if (dataset.both && isGroupedBarChart) {
    options = uniq([...baseOptions, ...historicalOptions]);
  } else if (dataset.both || dataset.base) {
    options = uniq(baseOptions);
  } else if (dataset.historical) {
    options = uniq(historicalOptions);
  }

  const promotionsQP = String(
    params[QP.PROMOTIONS_CHART_DATA_TYPE] || ""
  ).split(",");
  const selected = options.filter(o => promotionsQP.includes(o.toLowerCase()));

  return {
    selected,
    options,
    onChange(promotion: string) {
      const newPromotions = selected.includes(promotion)
        ? selected.filter(p => p !== promotion)
        : [...selected, promotion];
      dispatch(
        updateQueryParams({
          [QP.PROMOTIONS_CHART_DATA_TYPE]: newPromotions.join(",").toLowerCase()
        })
      );
    },
    onSelectAll() {
      dispatch(
        updateQueryParams({
          [QP.PROMOTIONS_CHART_DATA_TYPE]: options.join(",").toLowerCase()
        })
      );
    },
    onClear() {
      dispatch(
        updateQueryParams({
          [QP.PROMOTIONS_CHART_DATA_TYPE]: ""
        })
      );
    }
  };
};
