import { useSelector } from "react-redux";

import { useOwnSharesTableData } from "api/shares/mappers";

import { fetchedPromotionsSelector } from "pages/Reports/redux/selectors/promotionsSelectors";
import { getTableDataFromShares } from "pages/Reports/utils/getTableData";
import { tablePeriodSelector } from "store/selectors/routerSelectors";

export const useSharesTableData = () => {
  const ownShares = useOwnSharesTableData();
  const promotions = useSelector(fetchedPromotionsSelector);
  const period = useSelector(tablePeriodSelector);

  return getTableDataFromShares(ownShares, promotions, period);
};
