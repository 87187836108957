import { useGetRanking } from "api/ranking/hooks/useGetRanking";
import { useGetSegmentsByPeriod } from "api/segments/mappers/useGetSegmentsByPeriod";

export const useIsSegmentsLoading = () => {
  const {
    isLoading: isSegmentsLoading,
    isIdle: isSegmentsIdle
  } = useGetSegmentsByPeriod();
  const { isLoading: rankingLoading } = useGetRanking();

  return isSegmentsLoading || isSegmentsIdle || rankingLoading;
};
