import { useDispatch } from "react-redux";

import { ChartData } from "components/D3/types";
import { useChartScales } from "pages/Reports/partials/Chart/StandardChart/utils/useChartScales";
import {
  ChartOnClickAction,
  ChartOnHoverAction
} from "pages/Reports/redux/reducers/chartReducer";

import { ChartDot } from "./ChartDot";

interface Props {
  lineChart: ChartData;
  originalWidth: number;
  width: number;
  onTimelineHover: ChartOnHoverAction;
  onTimelineClick: ChartOnClickAction;
}

export const DotsChart = ({
  lineChart,
  originalWidth,
  width,
  onTimelineClick,
  onTimelineHover
}: Props) => {
  const dispatch = useDispatch();
  const { yScaleRight } = useChartScales(lineChart, originalWidth);

  const rightData = lineChart.rightChart?.chart;
  const { showBarChart } = lineChart;

  if (!rightData || !showBarChart) return null;

  return (
    <g>
      {rightData.map((element, dotIndex) => {
        return (
          <ChartDot
            transform={`translate(${width / 2},${yScaleRight(
              element.timeline[0].valueY || 0
            )})`}
            fill={element.color}
            key={`${dotIndex}-${element.label}-dot`}
            id={`${dotIndex}-${element.label}-dot`}
            onTimelineClick={() => dispatch(onTimelineClick(0))}
            onMouseMove={() => dispatch(onTimelineHover(0))}
            onMouseLeave={() => dispatch(onTimelineHover(null))}
          />
        );
      })}
    </g>
  );
};
