import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { resetQuery } from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { clearExportDataTypeFilter } from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";
import { AggregatedHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";
import { AggregatedCompetitionHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useAggregatedCompetition";
import { AttributesHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useAttributes";
import {
  BrandHooks,
  BrandType
} from "pages/Reports/redux/reducers/sweetStateHooks/useBrand";
import { CategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import { ChartPeriodHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useChartPeriod";
import { ClientTypeHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useClientType";
import { CompetingCompaniesHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCompetingCompanies";
import { CompetingProductsHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCompetingProducts";
import { CountyHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCounty";
import { DelayHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useDelay";
import { ExcludedDaysHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useExcludedDays";
import { LFLHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useLFL";
import { ProductHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";
import { PromotionsHooks } from "pages/Reports/redux/reducers/sweetStateHooks/usePromotions";
import { ReferenceCategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useReferenceCategory";
import { ShopTypeHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useShopType";
import { ShopTypeMetaHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useShopTypeMeta";
import { VendorHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { VoivodeshipHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVoivodeship";

export const useFiltersClear = () => {
  const dispatch = useDispatch();
  const [, voivodeshipActions] = VoivodeshipHooks.useVoivodeship();
  const [, countyActions] = CountyHooks.useCounty();
  const [, aggregatedActions] = AggregatedHooks.useAggregated();
  const [, lflActions] = LFLHooks.useLFL();
  const [, delayActions] = DelayHooks.useDelay();
  const [, brandActions] = BrandHooks.useBrand();
  const [, vendorActions] = VendorHooks.useVendor();
  const [, attributesActions] = AttributesHooks.useAttributes();
  const [, categoryActions] = CategoryHooks.useCategory();
  const [, productActions] = ProductHooks.useProduct();
  const [, promotionsActions] = PromotionsHooks.usePromotions();
  const [, chartPeriodActions] = ChartPeriodHooks.useChartPeriod();
  const [, shopTypeActions] = ShopTypeHooks.useShopType();
  const [, shopTypeMetaActions] = ShopTypeMetaHooks.useShopTypeMeta();
  const [, clientTypeActions] = ClientTypeHooks.useClientType();
  const [
    ,
    aggregatedCompetitionActions
  ] = AggregatedCompetitionHooks.useAggregatedCompetition();
  const [
    ,
    referenceCategoryActions
  ] = ReferenceCategoryHooks.useReferenceCategory();
  const [, excludedDaysActions] = ExcludedDaysHooks.useExcludedDays();
  const [
    ,
    competingCompaniesActions
  ] = CompetingCompaniesHooks.useCompetingCompanies();
  const [
    ,
    competingProductsActions
  ] = CompetingProductsHooks.useCompetingProducts();

  const clearAllFilters = useCallback(() => {
    dispatch(clearExportDataTypeFilter()); // clear redux based filter

    categoryActions.clearCategories();
    attributesActions.clearAttributes();
    vendorActions.clearVendors();
    productActions.clearProducts();
    chartPeriodActions.clearChartPeriodFilter();
    shopTypeActions.clearShopTypes();
    shopTypeMetaActions.clearShopTypesMeta();
    clientTypeActions.clearClientType();
    aggregatedCompetitionActions.clearAggregatedCompetition();
    aggregatedActions.clearAggregated();
    voivodeshipActions.clearVoivodeships();
    countyActions.clearCounties();
    lflActions.clearLFL();
    delayActions.clearDelay();
    referenceCategoryActions.clearCategories();
    brandActions.clearBrands(BrandType.brand);
    brandActions.clearBrands(BrandType.subBrand);
    brandActions.clearBrands(BrandType.competingBrand);
    brandActions.clearBrands(BrandType.competingSubBrand);
    brandActions.clearBrands(BrandType.referenceBrand);
    brandActions.clearBrands(BrandType.referenceSubBrand);
    competingCompaniesActions.updateSelected([]);
    competingProductsActions.updateSelected([]);
    promotionsActions.clearPromotions();
    excludedDaysActions.clearExcludedDays();
    dispatch(resetQuery());
  }, [
    dispatch,
    categoryActions,
    attributesActions,
    vendorActions,
    productActions,
    chartPeriodActions,
    shopTypeActions,
    shopTypeMetaActions,
    clientTypeActions,
    aggregatedCompetitionActions,
    aggregatedActions,
    voivodeshipActions,
    countyActions,
    lflActions,
    delayActions,
    referenceCategoryActions,
    brandActions,
    competingCompaniesActions,
    competingProductsActions,
    promotionsActions,
    excludedDaysActions
  ]);

  return clearAllFilters;
};
