import { POLISH_WEEK_DAYS, POLISH_WEEK_DAYS_SHORT } from "./constants";

export const getPolishWeekDayName = (
  dayIndex: number,
  shortName: boolean = false
) => {
  const day = POLISH_WEEK_DAYS[dayIndex];
  const dayShort = POLISH_WEEK_DAYS_SHORT[dayIndex];

  if (!day || !dayShort) {
    throw new Error(
      `Invalid dayIndex for getPolishWeekDayName util, number between 0-6 was expected but ${dayIndex} was given.`
    );
  }

  if (shortName) return dayShort;
  return day;
};
