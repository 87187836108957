import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { QueryKey } from "api/consts";
import { logisticsServices } from "api/logistics/services/logistics.services";
import { useReportQueryString } from "api/utils/useReportQueryString";
import qs from "query-string";

import { useFiltersQP } from "hooks";
import { updateLogisticsHiddenLines } from "pages/Reports/redux/reducers/chartReducer";
import { REPORTS_PATHS } from "pages/Reports/utils";
import { normalizeLogisticsResponse } from "pages/Reports/utils/normalize/normalizeLogisticsResponse";
import {
  PARSE_OPTIONS,
  STRINGIFY_OPTIONS
} from "pages/Reports/utils/validateParams/utils/adjustObjectProperty";
import {
  logisticsPeriodTypeSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import {
  isProductWithdrawn,
  isThisPage,
  LOGISTICS_DATA_TYPES_PARAMS
} from "utils";
import { QP } from "utils/defaultQueryParams";
import { translateFeParamsToBeParams } from "utils/translateFEParamsToBEParams";

export const useGetLogistics = () => {
  const dispatch = useDispatch();
  const pathname = useSelector(pathnameSelector);
  const period = useSelector(logisticsPeriodTypeSelector);
  const reportQueryString = useReportQueryString();
  const { cat1QP, cat2QP, cat3QP, vendorIdQP } = useFiltersQP();

  const isCategorySelected = [cat1QP, cat2QP, cat3QP].some(Boolean);
  const isLogisticPage = isThisPage(pathname, REPORTS_PATHS.LOGISTICS);

  let fetchParams = qs.parse(reportQueryString, PARSE_OPTIONS);
  // [PMD-5211]: vendor_id must be added to the logistics requests
  if (!fetchParams[QP.VENDOR_ID]) {
    fetchParams = { ...fetchParams, [QP.VENDOR_ID]: vendorIdQP };
  }
  if (period === LOGISTICS_DATA_TYPES_PARAMS.SINGLE_DAY) {
    fetchParams = { ...fetchParams, [QP.DATE_FROM]: fetchParams[QP.DATE_TO] };
  }
  if (period === LOGISTICS_DATA_TYPES_PARAMS.HISTORY) {
    fetchParams = { ...fetchParams, [QP.STORE_TYPES]: "" };
  }
  const fetchQuery = translateFeParamsToBeParams(
    qs.stringify(fetchParams, STRINGIFY_OPTIONS)
  );

  return useQuery([QueryKey.LOGISTICS_DATA, fetchQuery], {
    queryFn: async () => {
      const response = await logisticsServices.fetchLogistics(fetchQuery);
      return normalizeLogisticsResponse(response.data);
    },
    onSuccess: data => {
      //hide lines with unactive products by defualt
      const inactiveProducts = data.filter(item =>
        isProductWithdrawn(item.name)
      );
      dispatch(
        updateLogisticsHiddenLines(
          inactiveProducts.map(item => item.name) || [""]
        )
      );
    },
    enabled: isCategorySelected && isLogisticPage,
    retry: false,
    staleTime: 5 * 60 * 1000 // 5 minutes
  });
};
