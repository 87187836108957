import { SummaryTab } from "components/molecules/SummarySwitch/SummarySwitch";
import { SUMMARY_SWITCH } from "pages/Reports/utils";
import {
  DYNAMICS_SUMMARY_TABS,
  LOYALTY_SUMMARY_TABS,
  MULTIPACK_SUMMARY_SUB_TABS,
  MULTIPACK_SUMMARY_TABS,
  RECEIPTS_SUMMARY_TABS,
  SHARES_SUMMARY_TABS,
  SUMMARY_SUB_SWITCH,
  SUMMARY_SUB_TABS
} from "pages/Reports/utils/constants";

const getValues = ({ value }: SummaryTab) => value;

// SUMMARY
export const SELL_AVAILABLE_SUMMARY = [
  SUMMARY_SWITCH.TOTAL,
  SUMMARY_SWITCH.DISTRIBUTION,
  SUMMARY_SWITCH.PER_SHOP,
  SUMMARY_SWITCH.NATURAL_UNITS
];

export const LOCATION_AVAILABLE_SUMMARY = [
  SUMMARY_SWITCH.TOTAL,
  SUMMARY_SWITCH.PER_SHOP,
  SUMMARY_SWITCH.DISTRIBUTION
];

export const SHARES_AVAILABLE_SUMMARY = SHARES_SUMMARY_TABS.map(getValues);
export const DYNAMICS_AVAILABLE_SUMMARY = DYNAMICS_SUMMARY_TABS.map(getValues);
export const RECEIPTS_AVAILABLE_SUMMARY = RECEIPTS_SUMMARY_TABS.map(getValues);
export const LOYALTY_AVAILABLE_SUMMARY = LOYALTY_SUMMARY_TABS.map(getValues);
export const MULTIPACK_AVAILABLE_SUMMARY = MULTIPACK_SUMMARY_TABS.map(
  getValues
);

export const SELL_DEFAULT_SUMMARY = SUMMARY_SWITCH.TOTAL;
export const LOCATION_DEFAULT_SUMMARY = SUMMARY_SWITCH.TOTAL;
export const DYNAMICS_DEFAULT_SUMMARY = SUMMARY_SWITCH.WORTH;
export const SHARES_DEFAULT_SUMMARY = SUMMARY_SWITCH.VALUE_QUANTITY;
export const RECEIPTS_DEFAULT_SUMMARY = SUMMARY_SWITCH.RECEIPTS_METRICS;
export const LOYALTY_DEFAULT_SUMMARY = SUMMARY_SWITCH.TOTAL;
export const MULTIPACK_DEFAULT_SUMMARY = SUMMARY_SWITCH.MULTIPACK_TOTAL;

// SUB SUMMARY
export const REGULAR_AVAILABLE_SUB_SUMMARY = SUMMARY_SUB_TABS.map(getValues);
export const MULTIPACK_AVAILABLE_SUB_SUMMARY = MULTIPACK_SUMMARY_SUB_TABS.map(
  getValues
);

export const REGULAR_DEFAULT_SUB_SUMMARY = SUMMARY_SUB_SWITCH.OWN;
export const MULTIPACK_DEFAULT_SUB_SUMMARY = SUMMARY_SUB_SWITCH.SELL_OWN;
