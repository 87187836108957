import { FC } from "react";
import Joyride, {
  CallBackProps,
  Props as JoyrideProps,
  STATUS
} from "react-joyride";

import { COLORS } from "components/atoms/Icon/utils/constants";
import { PRIMARY_NAVBAR_HEIGHT, SECONDARY_NAVBAR_HEIGHT } from "utils";

import { TourTooltip } from "./TourTooltip";

interface Props extends JoyrideProps {
  onClose: () => void;
}

export const Tour: FC<Props> = ({ onClose, ...props }) => {
  const handleCallback = (data: CallBackProps) => {
    switch (data.status) {
      case STATUS.RUNNING:
        document.body.style.overflow = "hidden";
        break;
      case STATUS.FINISHED:
      case STATUS.SKIPPED:
        document.body.style.overflow = "auto";
        onClose();
        break;
      default:
        break;
    }
  };

  return (
    <Joyride
      scrollOffset={PRIMARY_NAVBAR_HEIGHT + SECONDARY_NAVBAR_HEIGHT}
      disableScrollParentFix
      disableCloseOnEsc
      callback={handleCallback}
      tooltipComponent={TourTooltip}
      styles={{
        overlay: {
          backgroundColor: COLORS.OVERLAY,
          opacity: 0.7
        }
      }}
      floaterProps={{ disableAnimation: true, hideArrow: true }}
      {...props}
    />
  );
};
