import { CreateLegendContent } from "pages/Reports/partials/Chart/Legend/hooks/useLegend";
import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { valueByDataType } from "pages/Reports/utils";

type LegendContentItems = {
  text: string;
  color: string;
  id: string;
  isLineHidden: boolean;
};

type LegendContent = {
  label: string;
  items: LegendContentItems[];
  notExisting: LegendContentItems[];
};

export const matchByType = (data: CreateLegendContent[], type: string) =>
  data.filter(item => item.type === type);

export const appendId = (items: CreateLegendContent[], label: string) =>
  items.map(item => {
    const text = getChartLabel({
      vendorId: item.vendorId,
      resultId: item.resultId,
      type: item.type
    });
    const id = `${text}-${label}`;

    return {
      ...item,
      text,
      id
    };
  });

export const hideItem = (
  items: (CreateLegendContent & { id: string; text: string })[],
  hiddenLines: string[]
) =>
  items.map(item => ({
    ...item,
    isLineHidden: hiddenLines.includes(item.id)
  }));

export const splitExistingValues = <T extends {}>(
  items: (T & CreateLegendContent)[],
  dataTypes: ChartDataTypes[]
) => {
  const existing = items.filter(item =>
    dataTypes.some(type => {
      return (
        valueByDataType(type, item.existingMetrics) ||
        valueByDataType(`${type}_LAST_YEAR`, item.existingMetrics)
      );
    })
  );
  const notExisting = items.filter(item =>
    dataTypes.every(type => {
      return (
        !valueByDataType(type, item.existingMetrics) &&
        !valueByDataType(`${type}_LAST_YEAR`, item.existingMetrics)
      );
    })
  );
  return [existing, notExisting];
};

const createContent = (
  type: string,
  label: string,
  rawItems: CreateLegendContent[],
  hiddenLines: string[],
  dataTypes: ChartDataTypes[]
): LegendContent => {
  let items;

  items = matchByType(rawItems, type);
  items = appendId(items, label);
  items = hideItem(items, hiddenLines);
  const [existing, notExisting] = splitExistingValues(items, dataTypes);

  return {
    label,
    items: existing,
    notExisting
  };
};

export const createContentArray = (
  contentNames: {
    type: string;
    label: string;
  }[],
  data: CreateLegendContent[],
  hiddenLines: string[],
  dataTypes: ChartDataTypes[]
) =>
  contentNames.map(name =>
    createContent(name.type, name.label, data, hiddenLines, dataTypes)
  );
