import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";

import cn from "classnames";
import { format, isAfter, isBefore } from "date-fns";
import moment from "moment";
import { toLower } from "ramda";

import { Button, TierValidator } from "components/atoms";
import { ChevronRight } from "components/atoms/Icon";
import { DateRangeCalendar, MonthRangePicker } from "components/molecules";
import { DateDropdownMenuChangeFn } from "components/molecules/types";
import { ConfirmModal } from "components/organisms";
import {
  getDateRangeMonthBoundaries,
  getIncludedDays,
  TIERS_WITH_MONTH_PICKER
} from "components/organisms/DateDropdown/utils";
import { CalendarTour } from "components/organisms/Tour";
import { asyncFiltersSelector } from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useCategoryDateRange } from "pages/Reports/redux/reducers/filters/categoryFilters/categoryFiltersSelectors";
import { CalendarHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCalendar";
import { ExcludedDaysHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useExcludedDays";
import { LFLHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useLFL";
import { tierSelector } from "store/reducers/userReducer";
import {
  dateFromQueryParamSelector,
  dateToQueryParamSelector
} from "store/selectors/routerSelectors";
import {
  BOOL_STRING_VALUES,
  DATE_FNS_DOT_FORMAT,
  DEFAULT_MOMENT_DATE_FORMAT,
  getCalendarRange,
  ICON_SIZES,
  pushDateGenerate,
  pushDatePickerEvent
} from "utils";
import { PROMPT_KEYS } from "utils/constants";

import { DropdownItem, PropGetter } from "../types";
import s from "./dateDropdownMenu.module.scss";
import { ExcludedDaysSection } from "./ExcludedDaysSection/ExcludedDaysSection";
import { HistoricalSection } from "./HistoricalSection/HistoricalSection";
import { useHistorical } from "./HistoricalSection/useHistorical";
import { PresetsSection } from "./PresetsSection/PresetsSection";
import { usePresets } from "./PresetsSection/usePresets";

type MenuPropGetter = PropGetter<"getMenuProps">;
type ItemPropGetter = PropGetter<"getItemProps">;

interface Props {
  isDisabled?: boolean;
  className?: {
    base?: string;
    label?: string;
    list?: string;
    item?: string;
    itemText?: string;
  };
  label?: string;
  getMenuProps?: MenuPropGetter;
  getItemProps?: ItemPropGetter;
  inputValue?: string;
  filterItemsFn?: (
    inputValue: string
  ) => (item: DropdownItem, index: number, array: DropdownItem[]) => boolean;
  onChange?: (item: DropdownItem | null) => void;
  closeDropdown: () => void;
  handleChange: DateDropdownMenuChangeFn;
  handleOutsideClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const {
  IS_INCONSISTENT_DATA_PROMPT_HIDDEN,
  IS_REMOVE_HISTORICAL_PROMPT_HIDDEN
} = PROMPT_KEYS;
const { TRUE } = BOOL_STRING_VALUES;
const INCONSISTENT_DATA_PROMPT = `Uwaga\n
Wybranie opcji "porównaj do okresu historycznego" oraz wykluczenie wybranych dni może spowodować brak spójności w prezentowanych danych.\n
Rekomendujemy niewykluczanie dni przy porównywaniu danych do okresu historycznego jeśli chcesz uniknąć takiej sytuacji.
`;
const REMOVE_HISTORICAL_PROMPT = `Uwaga!\n
Opcja dynamiczny zakres dat nie uwzględnia porównania do okresu historycznego.\n
Wybierając Dynamiczny zakres dat i zapisując link, po powrocie do niego zobaczysz zaktualizowane daty dla okresu bazowego, okres historyczny nie zostanie zapamiętany i należy wybrać go ponownie.`;

export function DateDropdownMenu({
  className = {},
  inputValue = "",
  onChange = () => {},
  closeDropdown,
  handleChange,
  handleOutsideClick,
  ...props
}: Props) {
  const getMenuProps = props.getMenuProps as MenuPropGetter;

  const tier = useSelector(tierSelector);
  const isMonthCalendar = TIERS_WITH_MONTH_PICKER.includes(tier);

  const [{ selected, calendar }, actions] = CalendarHooks.useCalendar();
  const [{ excludedDays }] = ExcludedDaysHooks.useExcludedDays();
  const [, lflActions] = LFLHooks.useLFL();
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const [calendarRenderKey, setCalendarRenderKey] = useState(
    calendar.dateFrom?.toString()
  );

  const dateFromQP = useSelector(dateFromQueryParamSelector);
  const initialDateFrom = isPristine ? dateFromQP : selected.dateFrom;
  const dateToQP = useSelector(dateToQueryParamSelector);
  const initialDateTo = isPristine ? dateToQP : selected.dateTo;

  useEffect(() => {
    actions.updateCalendarDates({
      dateFrom: moment(initialDateFrom),
      dateTo: moment(initialDateTo)
    });
  }, [actions, initialDateFrom, initialDateTo]);

  const [categoryStartDate, yesterday] = useCategoryDateRange();
  const [calendarMinDate, calendarMaxDate] = getCalendarRange(
    categoryStartDate,
    tier
  );

  const [validationError, setValidationError] = useState("");
  const [isInconsistentDataPrompted, setInconsistentDataPrompt] = useState(
    false
  );
  const [isRemoveHistoricalPrompted, setRemoveHistoricalPrompt] = useState(
    false
  );

  const historical = useHistorical(isMonthCalendar);
  const { dataForStore: dynamicPeriodData, ...presets } = usePresets(
    calendarMinDate,
    calendarMaxDate
  );

  const hasExcludedDays = excludedDays.length > 0;
  const hasDynamicPeriod = dynamicPeriodData.period.length > 0;

  const getValidationError = () => {
    const dates = {
      start: calendar.dateFrom?.toDate(),
      end: calendar.dateTo?.toDate()
    };

    if (!dates.start || !dates.end) {
      return "Wybierz poprawny zakres dat";
    }

    if (isBefore(dates.end, dates.start)) {
      return 'Data "od" musi być wcześniejsza niż "do"';
    }

    if (
      isBefore(dates.start, categoryStartDate) ||
      isAfter(dates.end, yesterday)
    ) {
      const start = format(categoryStartDate, DATE_FNS_DOT_FORMAT);
      const end = format(yesterday, DATE_FNS_DOT_FORMAT);

      return `Możesz wybrać zakres dat od ${start} do ${end}`;
    }

    return historical.validate();
  };

  const getDisplayPrompt = (key: string, condition: boolean) => {
    return (
      localStorage.getItem(key) !== TRUE &&
      historical.isEnabled &&
      historical.isOpen &&
      condition
    );
  };

  const handleUpdate = () => {
    if (!calendar.dateFrom || !calendar.dateTo) return;

    let dateFrom = calendar.dateFrom.format(DEFAULT_MOMENT_DATE_FORMAT);
    let dateTo = calendar.dateTo.format(DEFAULT_MOMENT_DATE_FORMAT);

    if (isMonthCalendar) {
      const range = getDateRangeMonthBoundaries(
        calendar.dateFrom.toDate(),
        calendar.dateTo.toDate()
      );
      dateFrom = range.dateFrom;
      dateTo = range.dateTo;
    }

    const selectedItem = {
      label: `${dateFrom} - ${dateTo}`,
      value: { dateFrom, dateTo }
    };
    const historicalOptions = {
      ...historical.getParsedValuesBeforeApprove(),
      isOpen: historical.isOpen
    };

    handleChange(selectedItem, historicalOptions, dynamicPeriodData);
    pushDateGenerate(dateFrom, dateTo);

    const daysSelectedDataLayer = toLower(
      getIncludedDays(excludedDays)
        .replaceAll(".", "")
        .replaceAll(" ", "")
    );
    pushDatePickerEvent(`dni tygodnia - ${daysSelectedDataLayer}`);

    if (historicalOptions.isOpen) {
      pushDatePickerEvent("historical date");

      // [PMD-4530]: disable lfl when back data is selected
      lflActions.updateDisabled(true);
      lflActions.updateLFL(false);
    } else {
      lflActions.updateDisabled(false);
    }

    if (hasDynamicPeriod) pushDatePickerEvent("dynamic date range");

    setValidationError("");
    setInconsistentDataPrompt(false);
  };

  const handleUpdateClick = () => {
    const validationError = getValidationError();

    if (validationError) {
      return setValidationError(validationError);
    }

    if (getDisplayPrompt(IS_INCONSISTENT_DATA_PROMPT_HIDDEN, hasExcludedDays)) {
      return setInconsistentDataPrompt(true);
    }

    if (
      getDisplayPrompt(IS_REMOVE_HISTORICAL_PROMPT_HIDDEN, hasDynamicPeriod)
    ) {
      return setRemoveHistoricalPrompt(true);
    }

    handleUpdate();
  };

  const handleDateChange = (start: Date, end: Date) => {
    historical.onBasePeriodChange(start, end);
    presets.setClickedItem(null);
  };

  useEffect(() => {
    if (!isMonthCalendar) {
      const startInput = document.getElementById(
        "rangePickerStartDate"
      ) as HTMLInputElement;
      const endInput = document.getElementById(
        "rangePickerEndDate"
      ) as HTMLInputElement;
      startInput.setAttribute("data-ga-datepicker", "wprowadź datę");
      endInput.setAttribute("data-ga-datepicker", "wprowadź datę");
    }
  }, [isMonthCalendar]);

  useEffect(() => {
    //this is workaround for datepicker (PMD-3763) forcing rerender
    //flickering was accepted by the client
    if (!calendar.dateFrom) return;
    setCalendarRenderKey(calendar.dateFrom.toString());
  }, [calendar]);

  return (
    <OutsideClickHandler
      onOutsideClick={event => {
        const isBlocked = [
          isInconsistentDataPrompted,
          isRemoveHistoricalPrompted
        ].some(Boolean);

        if (!isBlocked) {
          handleOutsideClick(event);
        }
      }}
    >
      <div
        {...getMenuProps({
          className: cn(s.base, className.base, {
            [s.baseShort]: isMonthCalendar
          })
        })}
      >
        <div className={cn(s.listWrapper)}>
          <div className={s.dateRangeCalendarWrapper}>
            <div
              className={cn(s.calendarWrapper, {
                [s.calendarWrapperShort]: isMonthCalendar
              })}
              data-testid="date-dropdown-menu"
            >
              {isMonthCalendar ? (
                <MonthRangePicker
                  minDate={calendarMinDate}
                  maxDate={calendarMaxDate}
                  setValidationError={setValidationError}
                  onChange={handleDateChange}
                />
              ) : (
                <DateRangeCalendar
                  key={calendarRenderKey}
                  minDate={calendarMinDate}
                  maxDate={calendarMaxDate}
                  onChange={handleDateChange}
                  defaultFocusedInput={
                    calendarRenderKey ? "endDate" : "startDate"
                  }
                />
              )}
            </div>
            <HistoricalSection
              isMonthCalendar={isMonthCalendar}
              {...historical}
            />
            <div className={cn(s.calendarSection, s.calendarFooter)}>
              <div className={s.warnings}>
                {historical.isEnabled && historical.isOpen && (
                  <span className={cn(s.alert, s.error)}>
                    Wybranie opcji "Porównaj do okresu historycznego" może
                    spowodować dłuższy czas pobierania danych.
                  </span>
                )}
                {!!validationError && (
                  <span className={s.calendarError}>{validationError}</span>
                )}
              </div>
              <div className={s.calendarButtons}>
                <Button
                  onClick={() => closeDropdown()}
                  className={s.cancelButton}
                  type="button"
                  data-testid="date-dropdown-cancel-button"
                  {...{
                    "data-ga-datepicker": "anuluj"
                  }}
                >
                  Anuluj
                </Button>
                <Button
                  onClick={handleUpdateClick}
                  type="button"
                  className={s.acceptButton}
                  data-testid="date-dropdown-submit-button"
                  {...{
                    "data-ga-datepicker": "aktualizuj"
                  }}
                >
                  Aktualizuj
                  <ChevronRight
                    size={ICON_SIZES.LARGE}
                    className={s.chevronRightIcon}
                  />
                </Button>
              </div>
            </div>
          </div>
          <TierValidator accessLevels={[3, 4]}>
            <ExcludedDaysSection />
          </TierValidator>
          <PresetsSection
            onOptionClick={historical.onBasePeriodChange}
            {...presets}
          />
        </div>
        <CalendarTour isHistoricalSectionOpen={historical.isOpen} />
      </div>
      {isInconsistentDataPrompted && (
        <ConfirmModal
          onConfirm={() => {
            localStorage.setItem(IS_INCONSISTENT_DATA_PROMPT_HIDDEN, TRUE);
            handleUpdateClick();
          }}
          onCancel={() => setInconsistentDataPrompt(false)}
          position={{ top: 0 }}
          message={INCONSISTENT_DATA_PROMPT}
          confirmText="Aktualizuj"
          cancelText="Powrót"
          testId="inconsistent-data-prompt"
          ga={{
            confirm: {
              "data-ga-inconsistent-data-prompt": "aktualizuj"
            },
            cancel: {
              "data-ga-inconsistent-data-prompt": "przerwij"
            }
          }}
        />
      )}
      {isRemoveHistoricalPrompted && (
        <ConfirmModal
          onConfirm={() => {
            localStorage.setItem(IS_REMOVE_HISTORICAL_PROMPT_HIDDEN, TRUE);
            handleUpdate();
          }}
          onCancel={() => setRemoveHistoricalPrompt(false)}
          position={{ top: 0 }}
          message={REMOVE_HISTORICAL_PROMPT}
          confirmText="Aktualizuj"
          cancelText="Powrót"
          testId="remove-historical-data-prompt"
          ga={{
            confirm: {
              "data-ga-remove-historical-data-prompt": "aktualizuj"
            },
            cancel: {
              "data-ga-remove-historical-data-prompt": "przerwij"
            }
          }}
        />
      )}
    </OutsideClickHandler>
  );
}
