import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";

import cn from "classnames";

import { TooltipIcon } from "components/atoms/Icon";
import { Checkbox } from "components/molecules";
import { Props as FilterDropdownMenuProps } from "components/molecules/FilterDropdownMenu/FilterDropdownMenu";
import { MultiList } from "components/organisms/MultiListMenu/MultiList";
import aggregationStyles from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/AggregationFilters/aggregationFilters.module.scss";
import {
  ReferenceCategoryHooks,
  ReferenceCategoryItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useReferenceCategory";
import { REPORTS_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage } from "utils";

import s from "./referenceCategories.module.scss";
import { ReferenceCategoriesList } from "./ReferenceCategoriesList";

type Props = {
  onClose: () => void;
  isAggregationDisabled: boolean;
};

const TEST_IDS = {
  menu: "reference-category-menu",
  close: "reference-category-close",
  aggregation: "reference-category-aggregation",
  accept: "reference-category-accept"
};

const AggregationCheckbox = ({
  isDisabled,
  testId
}: {
  isDisabled: boolean;
  testId: string;
}) => {
  const [, actions] = ReferenceCategoryHooks.useReferenceCategory();
  const [aggregated] = ReferenceCategoryHooks.useAggregated();
  const pathname = useSelector(pathnameSelector);
  const isLoyaltyPage = isThisPage(pathname, REPORTS_PATHS.LOYALTY);

  return (
    <div className={s.checkboxWrapper}>
      <Checkbox
        className={{
          label: cn(s.checkboxLabel, {
            [s.checkboxLabelChecked]: aggregated
          })
        }}
        isChecked={aggregated}
        isDisabled={isDisabled}
        onChange={() => actions.updateAggregated(!aggregated)}
        label="Agreguj dane wybranych kategorii"
        testId={testId}
      />
      {isLoyaltyPage && (
        <div
          className={aggregationStyles.tooltipWrapper}
          data-tooltip={
            "W raporcie LOJALNOŚCI dane są prezentowane bez możliwości agregacji"
          }
        >
          <TooltipIcon />
        </div>
      )}
    </div>
  );
};

export const ReferenceCategoriesMenu = ({
  onClose,
  isAggregationDisabled,
  ...props
}: Props & FilterDropdownMenuProps<ReferenceCategoryItem>) => {
  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <MultiList
        onClose={onClose}
        testIds={TEST_IDS}
        footer={
          <AggregationCheckbox
            isDisabled={isAggregationDisabled}
            testId={TEST_IDS.aggregation}
          />
        }
        {...props}
      >
        <>
          <ReferenceCategoriesList level={1} {...props} />
          <ReferenceCategoriesList level={2} {...props} />
          <ReferenceCategoriesList level={3} {...props} />
        </>
      </MultiList>
    </OutsideClickHandler>
  );
};
