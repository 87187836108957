import { format } from "date-fns";

import { dateToStringFormat, DEFAULT_DATE_FORMAT } from "utils";

export const newDateByTimezone = (
  date: string | number | Date = dateToStringFormat(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  )
) => {
  const stringDate =
    typeof date === "object" ? format(date, DEFAULT_DATE_FORMAT) : date;
  return new Date(stringDate + "T00:00"); // T00:00 is needed to reset the timezone's day changing due to actual computer's timezone
};
