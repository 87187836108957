import { appendInsightId } from "pages/Insights/partials/InsightsBox/utils";
import { HoursApi, HoursDataset } from "pages/Reports/sections/Hours/types";
import { normalizeHoursResponse } from "pages/Reports/sections/Hours/utils/normalizeHoursResponse";
import { normalizeHoursSummary } from "pages/Reports/sections/Hours/utils/normalizeHoursSummary";
import { getOwnSummary, getReferenceSummary } from "pages/Reports/utils";
import { createDatasetObject } from "pages/Reports/utils/createDatasetObject";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { pushReportFetchEvent } from "utils/googleTagManager/dataLayer";

export const hoursServices = {
  fetchHours: async (queryString: string) => {
    const url = appendInsightId(
      `${REST_API_ENDPOINTS.REPORTS.HOURS}?${queryString}`
    );
    const { data } = await HTTP.get<HoursApi>(url);

    const ownSummary = getOwnSummary(data.summary);
    const referenceSummary = getReferenceSummary(data.summary);
    const standardizedData: HoursDataset = {
      ...createDatasetObject({
        query: queryString,
        currentPeriod: normalizeHoursResponse(data.data),
        ownSummary: normalizeHoursSummary(ownSummary),
        referenceSummary: referenceSummary
          ? normalizeHoursSummary(referenceSummary)
          : null
      }),
      label: data.label,
      info: data.info
    };

    pushReportFetchEvent("Godziny");

    return standardizedData;
  }
};
