import React, { FC } from "react";

import cn from "classnames";
import { isNil } from "ramda";

import { Nullable } from "utils/types";

import s from "./toolTip.module.scss";

interface Props {
  className?: string;
  testId?: string;
}

interface TooltipCellProps {
  label: string;
  value: Nullable<string | number>;
  valueSecondary?: string;
}

export const TooltipCell: FC<TooltipCellProps> = ({
  label,
  value,
  valueSecondary
}) => {
  if (isNil(value)) {
    return null;
  }
  return (
    <li className={s.item}>
      <p className={s.title}>{label}</p>
      <p className={s.value}>{String(value)}</p>
      {valueSecondary ? (
        <p className={s.valueSecondary}>{valueSecondary}</p>
      ) : null}
    </li>
  );
};

export const Tooltip: FC<Props> = ({ children, className, testId }) => {
  return (
    <div className={cn(s.tooltip, className)} data-testid={testId}>
      {children}
    </div>
  );
};

export const TooltipHeader: FC<Props> = ({ children, className, testId }) => {
  return (
    <div className={cn(s.date, className)} data-testid={testId}>
      {children}
    </div>
  );
};

export const TooltipBody: FC<Props> = ({ children, className }) => {
  return <ul className={cn(s.list, className)}>{children}</ul>;
};
