import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const Download: React.FC<Props> = ({
  className,
  color,
  size = ICON_SIZES.NORMAL
}) => {
  const iconSize = SIZES[size];
  return (
    <svg
      width={iconSize}
      height={iconSize}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#6D89B6" fillRule="nonzero">
        <path d="M10 10l4-4h-3V2H9v4H6z" />
        <path d="M17 2h-3v2h2v12H4V4h2V2H3c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1zM4 16v-2h12v2H4z" />
      </g>
    </svg>
  );
};
