import { endOfWeek, getISOWeek, getMonth, getYear } from "date-fns";

import { newDateByTimezone } from "store/utils";
import { capitalize, getPolishMonthName, PERIOD_TYPE } from "utils";

export const getRowPeriod = <T extends { period: string }>(
  period: string,
  row: T
) => {
  switch (period) {
    case PERIOD_TYPE.DAYS:
      return row.period;
    case PERIOD_TYPE.WEEKS:
      return `${String(getISOWeek(newDateByTimezone(row.period)))} (${String(
        getYear(endOfWeek(newDateByTimezone(row.period), { weekStartsOn: 1 }))
      )})`;
    case PERIOD_TYPE.MONTHS:
      const monthName = capitalize(
        getPolishMonthName(getMonth(newDateByTimezone(row.period)))
      );
      return `${monthName} ${getYear(newDateByTimezone(row.period))}`;
    case PERIOD_TYPE.YEAR:
      return `${getYear(newDateByTimezone(row.period))}`;
    case PERIOD_TYPE.TOTAL:
      return row.period;
    default:
      return row.period;
  }
};
