import { useSelector } from "react-redux";

import cn from "classnames";

import { ChartDropdown, ReportDownloadDropdown } from "components/molecules";
import { IDS } from "components/organisms/Tour";
import { arePromotionsDisabledSelector } from "pages/Reports/redux/selectors/promotionsSelectors";
import { showMegaDropdownSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { ChartDropdown as NewChartDropdown } from "pages/Reports/sections/NewChartDropdown/ChartDropdown";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { REPORT_TYPES } from "store/utils/constants";
import { PROMOTION_COLORS, PROMOTIONS_FOR_DAY_PERIOD_ONLY } from "utils";

import s from "./chartHeader.module.scss";
import {
  useDataTypeAccess,
  useDataTypes,
  useDropdownTypes,
  useSettings,
  useTypeChangeAction
} from "./hooks";

export enum ChartDropdownIndex {
  first,
  second
}

interface Props {
  isDownloadHidden?: boolean;
}

export const ChartHeader = ({ isDownloadHidden = false }: Props) => {
  const { all, active } = useDataTypes();
  const megaDropdownCheckedFirst = useSelector(firstChartDataTypeSelector);
  const megaDropdownCheckedSecond = useSelector(secondChartDataTypeSelector);
  const access = useDataTypeAccess();
  const actions = useTypeChangeAction();
  const dropdownTypes = useDropdownTypes();
  const settings = useSettings();
  const arePromotionsDisabled = useSelector(arePromotionsDisabledSelector);
  const showMegaDropdown = useSelector(showMegaDropdownSelector);

  return (
    <>
      {access.first && !showMegaDropdown && (
        <ChartDropdown
          id={{ inputWrapper: IDS.FIRST_DATA_TYPE_DROPDOWN }}
          dataTypes={all.first}
          checkedDataType={active.first}
          onChangeDataType={actions.first}
          prefix={settings.prefixes[0]}
          dropdownType={dropdownTypes.first}
        />
      )}
      {access.second && !showMegaDropdown && (
        <ChartDropdown
          id={{ inputWrapper: IDS.SECOND_DATA_TYPE_DROPDOWN }}
          className={s.secondChoiceGroupWrapper}
          dataTypes={all.second}
          checkedDataType={active.second}
          onChangeDataType={actions.second}
          prefix={settings.prefixes[1]}
          dropdownType={dropdownTypes.second}
        />
      )}
      {access.first && showMegaDropdown && (
        <NewChartDropdown
          id={{ inputWrapper: IDS.FIRST_DATA_TYPE_DROPDOWN }}
          onChangeDataType={actions.first}
          checkedDataType={megaDropdownCheckedFirst}
          prefix={settings.prefixes[0]}
          dropdownType={dropdownTypes.first}
          dropdownIndex={ChartDropdownIndex.first}
        />
      )}
      {access.second && showMegaDropdown && (
        <NewChartDropdown
          className={s.secondChoiceGroupWrapper}
          onChangeDataType={actions.second}
          checkedDataType={megaDropdownCheckedSecond}
          prefix={settings.prefixes[1]}
          dropdownType={dropdownTypes.second}
          dropdownIndex={ChartDropdownIndex.second}
        />
      )}
      {access.third && (
        <ChartDropdown
          className={s.thirdChoiceGroupWrapper}
          dataTypes={all.third}
          checkedDataType={active.third}
          onChangeDataType={actions.third}
          gradientColors={PROMOTION_COLORS}
          onSelectAll={actions.thirdAll}
          onClear={actions.thirdClear}
          prefix="Promocje"
          dropdownType={dropdownTypes.third}
          showInputCounter
          isDisabled={arePromotionsDisabled}
          tooltipText={
            arePromotionsDisabled ? PROMOTIONS_FOR_DAY_PERIOD_ONLY : undefined
          }
        />
      )}

      {isDownloadHidden ? null : (
        <ul className={cn(s.wrapper, s.exportWrapper)}>
          <li className={s.item}>
            <ReportDownloadDropdown
              withHeader={false}
              wrapperClassName={s.downloadButton}
              type={REPORT_TYPES.CHART}
              reportDownloadHeader={settings.downloadHeader}
              reportFormatsAllowed={settings.downloadFormats}
              ga={{ "data-ga-graph-metric": "Eksport danych" }}
            />
          </li>
        </ul>
      )}
    </>
  );
};
