import { useSelector } from "react-redux";

import { useLocationMapDetailedChartsData } from "api/location/mappers/useLocationMapDetailedChartsData";
import { useLocationMapGeneralChartsData } from "api/location/mappers/useLocationMapGeneralChartData";
import { values } from "ramda";

import { VOIVODESHIPS } from "components/D3/Voivodeship/constants";
import { getMapChartDomain } from "pages/Reports/sections/Location/redux/utils/locationChartUtils";
import { LOCATION_RANGES } from "pages/Reports/sections/Sell/constants";
import { urlParamSelector } from "store/selectors/routerSelectors";

export const useLocationMapChartScale = () => {
  const urlParam = useSelector(urlParamSelector);
  const isGeneral = values(VOIVODESHIPS).every(
    voivoodeship => voivoodeship !== urlParam
  );

  const generalMapChart = useLocationMapGeneralChartsData();
  const mapChartDomain = getMapChartDomain(generalMapChart);
  const general = {
    domain: mapChartDomain,
    range: LOCATION_RANGES[mapChartDomain.length]
  };

  const detailedMapChart = useLocationMapDetailedChartsData();
  const mapDetailedDomain = getMapChartDomain(detailedMapChart);
  const detailed = {
    domain: mapDetailedDomain,
    range: LOCATION_RANGES[mapDetailedDomain.length]
  };

  return isGeneral ? general : detailed;
};
