import { PromotionsApi } from "pages/Reports/redux/reducers/promotionsReducer";
import { Nullable } from "utils/types";

export enum InsightRateLevel {
  NO_RATING,
  THUMB,
  MODAL
}

export enum ExplainabilityUnit {
  QUANTITY = "szt",
  NATURAL_UNITS = "jn",
  CURRENCY = "PLN",
  PERCENT_POINT = "p.p.",
  PERCENT = "%"
}

export type InsightExplainability = {
  [key: string]: {
    table: {
      is_header: boolean;
      columns: {
        value: Nullable<number | string>;
        unit: Nullable<ExplainabilityUnit>;
      }[];
    }[];
    promotions: {
      base: PromotionsApi[];
      compare: PromotionsApi[];
    };
  };
};

export interface InsightDataApi {
  insight_id: string;
  user_id: string;
  insight_name: string;
  insight_bullet_content: string;
  insight_url: string;
  timestamp: string;
  valid_from: string;
  valid_to: string;
  is_deleted: boolean;
  is_favourite: boolean;
  is_new: boolean;
  rate_level: InsightRateLevel;
  insight_title: string;
  user_contact_email: string;
  vendor_name?: string;
  report_type: string;
  insight_content_sentiment: string;
  allow_reload_page?: boolean;
  explainability?: InsightExplainability;
  similarity: number;
}

export interface InsightApi {
  insights: InsightDataApi[];
  page: Nullable<number>;
  total_insights_count: number;
  total_pages: number;
}

export interface SingleInsightData {
  insightId: string;
  userId: string;
  insightName: string;
  insightBulletContent: string;
  insightUrl: string;
  timestamp: string;
  validFrom: string;
  validTo: string;
  isDeleted: boolean;
  isFavourite: boolean;
  isNew: boolean;
  rateLevel: InsightRateLevel;
  insightTitle: string;
  userContactEmail: string;
  vendorName?: string;
  reportType: string;
  insightContentSentiment: string;
  allowReloadPage?: boolean;
  explainability: Nullable<InsightExplainability>;
  similarity: number;
}

export interface Insight {
  insights: SingleInsightData[];
  page: Nullable<number>;
  totalInsightsCount: Nullable<number>;
  totalPages: Nullable<number>;
}

export interface SidebarInsightDataApi {
  insights: InsightDataApi[];
  page: Nullable<number>;
  total_insights_count: Number;
  total_pages: Number;
}

export enum Section {
  ACTIVE = "active",
  FAVOURITE = "favourite",
  DELETED = "deleted"
}

export enum InsightReportType {
  DYNAMICS = "DYNAMICS",
  SHARES = "SHARES",
  SELL = "SELL"
}

export enum InsightSentiment {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL"
}
