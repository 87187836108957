import { Fragment, useEffect } from "react";
import { useExpanded, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";

import cn from "classnames";

import { TableProps } from "../../types";
import s from "./staticTable.module.scss";

export function StaticTable<Data>({
  className = {},
  renderHeader,
  renderCell,
  testId,
  renderSubRowComponent,
  disableFixedColumns = false,
  minColumnWidth,
  onSortChange,
  ...tableOptions
}: TableProps<Data>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { sortBy }
  } = useTable(tableOptions, useSortBy, useExpanded, useSticky);

  useEffect(() => {
    if (onSortChange) {
      onSortChange(sortBy);
    }
  }, [sortBy, onSortChange]);

  return (
    <table {...getTableProps({ className: cn(s.table, className.table) })}>
      <thead className={cn(s.tableHead)} data-testid={`${testId}-head`}>
        {headerGroups.map(({ getHeaderGroupProps, headers }, i) => (
          <tr
            {...getHeaderGroupProps({
              className: cn(s.tableHeaderRow, className.tableHeaderRow),
              "data-testid": `${testId}-head-row-${i}`
            })}
          >
            {headers.map(header => (
              <th
                {...header.getHeaderProps({
                  className: cn(
                    s.tableCell,
                    className.tableHeader,
                    //@ts-ignore missing types in react-table
                    header.headerClassName
                  ),
                  style: {
                    minWidth: disableFixedColumns ? "auto" : minColumnWidth
                  }
                })}
              >
                {renderHeader ? renderHeader(header) : header.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody
        {...getTableBodyProps({
          className: cn(s.tableBody, className.tableBody),
          "data-testid": `${testId}-body`
        })}
      >
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <Fragment key={`${testId}-body-row-${i}`}>
              <tr
                {...row.getRowProps({
                  className: cn(s.tableRow, className.tableRow),
                  "data-testid": `${testId}-body-row-${i}`
                })}
              >
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps({
                      className: cn(
                        s.tableCell,
                        className.tableCell,
                        //@ts-ignore missing types in react-table
                        cell.column.className
                      )
                    })}
                  >
                    {renderCell ? renderCell(cell) : cell.render("Cell")}
                  </td>
                ))}
              </tr>
              {!!renderSubRowComponent && row.isExpanded ? (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    {renderSubRowComponent(row)}
                  </td>
                </tr>
              ) : null}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
}
