import { useSelector } from "react-redux";

import { hiddenLinesByReportSelector } from "pages/Reports/redux/reducers/chartReducer";
import { PromotionDataType } from "pages/Reports/redux/reducers/promotionsReducer";
import { promotionsSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import {
  filterHiddenProductPromotions,
  reducePromotionDatesToCalendarDates
} from "pages/Reports/redux/utils";
import { usePromotionsOptions } from "pages/Reports/sections/NewChartDropdown/hooks";
import { PERIOD_TYPE } from "utils";

import { useFiltersQP } from "./useFiltersQP";

export enum FetchedPromotionTypes {
  REGULAR = "REGULAR",
  HISTORICAL = "HISTORICAL",
  BOTH = "BOTH"
}

interface Props {
  fetchedPromotionTypes: FetchedPromotionTypes;
}

export const usePromotionsForDates = ({
  fetchedPromotionTypes
}: Props): PromotionDataType[] => {
  const {
    dateFromQP,
    dateToQP,
    compareDateFromQP,
    compareDateToQP,
    chartPeriodQP
  } = useFiltersQP();

  const promotions = useSelector(promotionsSelector);
  const hiddenLinesByReport = useSelector(hiddenLinesByReportSelector);
  const options = usePromotionsOptions();

  const getDates = () => {
    switch (fetchedPromotionTypes) {
      case FetchedPromotionTypes.BOTH:
        return {
          dateFrom: compareDateFromQP,
          dateTo: dateToQP
        };
      case FetchedPromotionTypes.HISTORICAL:
        return {
          dateFrom: compareDateFromQP,
          dateTo: compareDateToQP
        };

      case FetchedPromotionTypes.REGULAR:
      default:
        return {
          dateFrom: dateFromQP,
          dateTo: dateToQP
        };
    }
  };

  const getPromotions = () => {
    switch (fetchedPromotionTypes) {
      case FetchedPromotionTypes.BOTH:
        return [
          ...promotions.fetchedPromotions,
          ...promotions.fetchedHistoricalPromotions
        ];
      case FetchedPromotionTypes.REGULAR:
        return promotions.fetchedPromotions;
      case FetchedPromotionTypes.HISTORICAL:
        return promotions.fetchedHistoricalPromotions;

      default:
        return [];
    }
  };

  const { dateFrom, dateTo } = getDates();
  const fetchedPromotions = getPromotions();

  if (fetchedPromotions.length > 0 && options.selected.length > 0) {
    //filter out promotions that products' lines has been disabled in legend
    const visiblePromotions = filterHiddenProductPromotions(
      fetchedPromotions,
      hiddenLinesByReport
    );

    //filter out promotions that are not selected in promotions dropdown
    const selectedPromotions = visiblePromotions.filter(promo =>
      options.selected.includes(promo.display)
    );

    const uppercasedTimePeriod = chartPeriodQP.toUpperCase();

    switch (uppercasedTimePeriod) {
      case PERIOD_TYPE.RANKING:
      case PERIOD_TYPE.DAYS:
      case PERIOD_TYPE.PROMO_WEEKS:
        return selectedPromotions.map(promotion => {
          return reducePromotionDatesToCalendarDates(
            promotion,
            dateFrom,
            dateTo,
            uppercasedTimePeriod
          );
        });
      case PERIOD_TYPE.WEEKS:
      case PERIOD_TYPE.MONTHS:
      case PERIOD_TYPE.YEAR:
      case PERIOD_TYPE.TOTAL:
      default:
        return [];
    }
  }
  return [];
};
