import { useDispatch, useSelector } from "react-redux";

import { RadioButton } from "components/atoms";
import { IDS } from "components/organisms/Tour";
import {
  INITIAL_HIDDEN_LINES,
  toggleSingleLegend
} from "pages/Reports/redux/reducers/chartReducer";
import { updateQueryParams } from "store/actions/routerActions";
import { groupBySegmentsQPSelector } from "store/selectors/routerSelectors";
import { BOOL_STRING_VALUES } from "utils";
import { QP } from "utils/defaultQueryParams";

import s from "./segmentsChartSection.module.scss";

type Props = {
  onOptionChange: (value: string) => void;
};

const GROUP_BY_OPTIONS = [
  {
    label: "Produkt",
    value: BOOL_STRING_VALUES.FALSE
  },
  {
    label: "Segment",
    value: BOOL_STRING_VALUES.TRUE
  }
];

export const GroupByOptions = ({ onOptionChange }: Props) => {
  const dispatch = useDispatch();
  const groupBySegmentsQP = useSelector(groupBySegmentsQPSelector);

  const handleClick = (value: string) => {
    dispatch(updateQueryParams({ [QP.GROUP_BY_SEGMENTS]: value }));
    onOptionChange(value);
    dispatch(toggleSingleLegend(INITIAL_HIDDEN_LINES));
  };

  return (
    <div id={IDS.GROUP_BY_SEGMENTS} className={s.groupByWrapper}>
      <p>Pokaż wykres per:</p>
      {GROUP_BY_OPTIONS.map(item => (
        <RadioButton
          key={`radio-${item.label}`}
          className={{
            checkbox: s.radio,
            label: s.radioLabel
          }}
          label={item.label}
          isChecked={groupBySegmentsQP === item.value}
          onChange={() => handleClick(item.value)}
        />
      ))}
    </div>
  );
};
