import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isAfter,
  isBefore,
  isThisMonth,
  isToday,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from "date-fns";

import { newDateByTimezone } from "store/utils";
import { capitalize, DATE_FROM_RESTRICTION, DEFAULT_DATE_FORMAT } from "utils";
import { POLISH_ENGLISH_WEEK_DAYS_SHORT } from "utils/constants";
import { Values } from "utils/types";

const LABEL_DATE_FORMAT = "yyy.MM.dd";

const PERIODS = {
  WEEK: "week",
  MONTH: "month",
  YEAR: "year"
};

const GET_FIXED_PERIOD_METHODS = {
  [PERIODS.WEEK]: {
    addPeriods: subWeeks,
    startOfPeriod: startOfWeek,
    endOfPeriod: endOfWeek
  },
  [PERIODS.MONTH]: {
    addPeriods: subMonths,
    startOfPeriod: startOfMonth,
    endOfPeriod: endOfMonth
  },
  [PERIODS.YEAR]: {
    addPeriods: subYears,
    startOfPeriod: startOfYear,
    endOfPeriod: endOfYear
  }
};

export const TIERS_WITH_MONTH_PICKER = [1, 2];
export const TIERS_WITH_CURRENT_MONTH_DISABLED = [1, 2];

const getItemLabel = (dateFrom: Date, dateTo: Date) =>
  `${format(dateFrom, LABEL_DATE_FORMAT)} - ${format(
    dateTo,
    LABEL_DATE_FORMAT
  )}`;

const getItemValue = (dateFrom: Date, dateTo: Date, isDisabled?: boolean) => {
  return {
    dateFrom: format(dateFrom, DEFAULT_DATE_FORMAT),
    dateTo: format(dateTo, DEFAULT_DATE_FORMAT),
    isDisabled
  };
};

export const getFixedPeriodDateItem = (
  period: Values<typeof PERIODS>,
  periodsToSubtract: number,
  minDate: Date = newDateByTimezone(DATE_FROM_RESTRICTION),
  maxDate: Date = newDateByTimezone()
) => {
  const { addPeriods, startOfPeriod, endOfPeriod } = GET_FIXED_PERIOD_METHODS[
    period
  ];

  const date = addPeriods(newDateByTimezone(), periodsToSubtract);

  const firstDayOfPeriod = startOfPeriod(date, { weekStartsOn: 1 });
  const lastDayOfPeriod = periodsToSubtract
    ? endOfPeriod(date, { weekStartsOn: 1 })
    : subDays(newDateByTimezone(), 1);

  const isOutOfAccessibleTimeScope =
    isBefore(firstDayOfPeriod, minDate) || isAfter(lastDayOfPeriod, maxDate);

  return {
    label: getItemLabel(firstDayOfPeriod, lastDayOfPeriod),
    value: getItemValue(
      firstDayOfPeriod,
      lastDayOfPeriod,
      isToday(firstDayOfPeriod) || isOutOfAccessibleTimeScope
    )
  };
};

//always exclude current month from month boundaries by subtracting on month
export const getDateRangeMonthBoundaries = (startDate: Date, endDate: Date) => {
  const startOfPreviousMonthDate = isThisMonth(startDate)
    ? subMonths(startDate, 1)
    : startDate;
  const dateFrom = format(
    startOfMonth(startOfPreviousMonthDate),
    DEFAULT_DATE_FORMAT
  );

  const endOfPreviousMonthDate = isThisMonth(endDate)
    ? subMonths(endDate, 1)
    : endDate;
  const dateTo = format(
    endOfMonth(endOfPreviousMonthDate),
    DEFAULT_DATE_FORMAT
  );

  return { dateFrom, dateTo };
};

export const getIncludedDays = (excludedDays: string[]) => {
  if (excludedDays.length === 0) return "Wszystkie";

  return POLISH_ENGLISH_WEEK_DAYS_SHORT.filter(
    day => !excludedDays.includes(day.englishName)
  )
    .map(day => capitalize(day.polishName))
    .join(", ");
};
