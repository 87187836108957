import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { showMegaDropdownSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { usePromotionsOptions } from "pages/Reports/sections/NewChartDropdown/hooks";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { isPowerUserSelector, tierSelector } from "store/reducers/userReducer";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { isThisPage } from "utils";
import {
  HOURS_FIRST_CHART_DATA_TYPES,
  HOURS_SECOND_CHART_DATA_TYPES,
  LOCATION_FIRST_CHART_DATA_TYPES,
  LOCATION_SECOND_CHART_DATA_TYPES
} from "utils/constants/dataTypes";

import { filterPlanogramTypes, filterTypesByTier } from "../utils";

interface DataTypes {
  all: {
    first: ChartDataTypes[];
    second: ChartDataTypes[];
    third: ChartDataTypes[];
  };
  active: {
    first: ChartDataTypes;
    second: ChartDataTypes;
    third: ChartDataTypes[];
  };
}

const { LOCATION_PATH, HOURS_PATH } = REPORTS_FULL_PATHS;

export const useDataTypes = (): DataTypes => {
  const { pathname } = useLocation();

  const activeFirst = useSelector(firstChartDataTypeSelector);
  const activeSecond = useSelector(secondChartDataTypeSelector);
  const isPowerUser = useSelector(isPowerUserSelector);
  const currentTier = useSelector(tierSelector);
  const showMegaDropdown = useSelector(showMegaDropdownSelector);
  const { options: third, selected: activeThird } = usePromotionsOptions();

  if (isThisPage(pathname, LOCATION_PATH)) {
    const dataTypesWithoutPlanogramDistribution = filterPlanogramTypes(
      LOCATION_SECOND_CHART_DATA_TYPES,
      isPowerUser
    );

    const typesFilteredByTier = filterTypesByTier(
      dataTypesWithoutPlanogramDistribution,
      currentTier
    );

    return {
      all: {
        first: LOCATION_FIRST_CHART_DATA_TYPES,
        second: typesFilteredByTier,
        third
      },
      active: { first: activeFirst, second: activeSecond, third: activeThird }
    };
  }

  if (isThisPage(pathname, HOURS_PATH)) {
    const dataTypesWithoutPlanogramDistribution = filterPlanogramTypes(
      HOURS_SECOND_CHART_DATA_TYPES,
      isPowerUser
    );

    const typesFilteredByTier = filterTypesByTier(
      dataTypesWithoutPlanogramDistribution,
      currentTier
    );

    return {
      all: {
        first: HOURS_FIRST_CHART_DATA_TYPES,
        second: typesFilteredByTier,
        third
      },
      active: { first: activeFirst, second: activeSecond, third: activeThird }
    };
  }

  // return promotions only for reports with mega-dropdown
  if (showMegaDropdown) {
    return {
      all: { first: [], second: [], third },
      active: { first: "", second: "", third: activeThird }
    };
  }

  return {
    all: { first: [], second: [], third: [] },
    active: { first: "", second: "", third: [] }
  };
};
