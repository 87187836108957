import { CHART_DATA_TYPE } from "utils/constants";

import { getPolishLabelForTooltip } from "./getPolishLabelForTooltip";

export const checkChangeColumn = (key: string) => {
  return key.includes(CHART_DATA_TYPE.DYNAMICS_NET_QUANTITY_CHANGE)
    ? false
    : key.includes("_CHANGE");
};

export const getPolishLabelForSidebar = (key: string) => {
  if (checkChangeColumn(key)) return "Zmiana";
  const suffixes = ["_ONLINE_SHARE", "_OFFLINE", "_ONLINE"];
  const index = suffixes.findIndex(suffix => key.includes(suffix));
  if (index === -1) return getPolishLabelForTooltip(key);
  const polishSuffixes = ["udział Żappki", "bez Żappki", "z Żappką"];
  const label = getPolishLabelForTooltip(key.split(suffixes[index])[0]);
  return [label, polishSuffixes[index]].join(" - ");
};
