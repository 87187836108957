import { Text } from "components/atoms";
import { useLoadingInfo } from "hooks/useLoadingInfo";

import s from "./loadingInfo.module.scss";

export const LoadingInfo = () => {
  const info = useLoadingInfo();

  if (!info.length) return null;

  return (
    <div className={s.loadingInfo}>
      {info.map(paragraph => (
        <Text key={paragraph} className={s.paragraph}>
          {paragraph}
        </Text>
      ))}
    </div>
  );
};
