import { useSelector } from "react-redux";

import { useIsLocationsLoading } from "api/location/mappers/useIsLocationLoading";
import { useLocationWarnings } from "api/location/mappers/useLocationWarnings";

import { CHART_PERIODS } from "components/D3/constants";
import {
  ChartSectionHeader,
  LocationLegend,
  MapChartLegend,
  MapChartSwitch
} from "components/molecules";
import { chartPeriodSelector } from "store/selectors/routerSelectors";

import s from "./locationChartContent.module.scss";

export const LocationChartContent = () => {
  const period = useSelector(chartPeriodSelector);
  const isLoading = useIsLocationsLoading();

  const isTotal = period === CHART_PERIODS.TOTAL;

  const warnings = useLocationWarnings();

  return (
    <div>
      <ChartSectionHeader warnings={warnings} isLoading={isLoading} />
      <div className={s.base} data-testid="location-chart-box">
        <div className={s.chartContainer}>
          <MapChartSwitch />
        </div>
        {isTotal && (
          <div className={s.legendContainer}>
            <MapChartLegend />
          </div>
        )}
      </div>
      {!isTotal && <LocationLegend />}
    </div>
  );
};
