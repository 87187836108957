import { useSelector } from "react-redux";

import { useGetSegmentsByPeriod } from "api/segments/mappers";
import { getName } from "api/segments/utils/getName";

import { getLegendItemColor } from "pages/Reports/sections/utils/color";
import { isGroupedBySegmentsSelector } from "store/selectors/routerSelectors";
import { COLOR } from "utils/constants";

export const useProductTabLegendItems = (): {
  label: string;
  color: string;
}[] => {
  const isGroupedBySegments = useSelector(isGroupedBySegmentsSelector);
  const { data } = useGetSegmentsByPeriod();

  if (!data?.currentPeriod.length) return [];

  if (isGroupedBySegments) {
    return data.currentPeriod[0].segments.map((product, index) => {
      const [resultId, vendorId] = product.label.split(" - ");

      return {
        label: product.label,
        color: getLegendItemColor({
          vendorId,
          resultId,
          index
        })
      };
    });
  }

  return data.currentPeriod.map(period => ({
    label: getName(period, false),
    color: COLOR.UI_9
  }));
};
