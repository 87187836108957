import { SidebarSortingTypes } from "hooks/useSidebarSorting";
import { Metrics } from "pages/Reports/types/metrics";
import { Keys } from "utils/types";

enum SidebarValueType {
  HISTORICAL_PERIOD = "HISTORICAL_PERIOD",
  CHANGE_PERCENTAGE = "CHANGE_PERCENTAGE"
}

export const transformSortTypeToSidebarValueType = (
  sidebarSortType: string
) => {
  switch (sidebarSortType) {
    case SidebarSortingTypes.BY_HISTORICAL_PERIOD:
      return SidebarValueType.HISTORICAL_PERIOD;
    case SidebarSortingTypes.BY_CHANGE_PERCENTAGE:
      return SidebarValueType.CHANGE_PERCENTAGE;
    default:
      return SidebarValueType.HISTORICAL_PERIOD;
  }
};

export const getSecondValueMetricKey = (
  key: string,
  sidebarValueType: SidebarValueType
) => {
  if (sidebarValueType === SidebarValueType.HISTORICAL_PERIOD) {
    return `${key}LastYear` as Keys<Metrics>;
  }

  if (sidebarValueType === SidebarValueType.CHANGE_PERCENTAGE) {
    return `${key}Percentage` as Keys<Metrics>;
  }

  return key as Keys<Metrics>;
};
