import {
  MultipackSummary,
  MultipackSummaryApi
} from "pages/Reports/types/summary";
import { standarizeTimepointValue } from "utils";

export const normalizeMultipackSummary = (
  summary: MultipackSummaryApi
): MultipackSummary => ({
  // WS + WSO + WSA
  worthSumWsWsoWsa: standarizeTimepointValue(summary?.worth_sum_ws_wso_wsa),
  worthSumWsWsoWsaLastYear: standarizeTimepointValue(
    summary?.worth_sum_ws_wso_wsa_last_year
  ),
  worthSumWsWsoWsaChange: standarizeTimepointValue(
    summary?.worth_sum_ws_wso_wsa_change
  ),

  quantitySumWsWsoWsa: standarizeTimepointValue(
    summary?.quantity_sum_ws_wso_wsa
  ),
  quantitySumWsWsoWsaLastYear: standarizeTimepointValue(
    summary?.quantity_sum_ws_wso_wsa_last_year
  ),
  quantitySumWsWsoWsaChange: standarizeTimepointValue(
    summary?.quantity_sum_ws_wso_wsa_change
  ),

  priceAvgWsWsoWsa: standarizeTimepointValue(summary?.price_avg_ws_wso_wsa),
  priceAvgWsWsoWsaLastYear: standarizeTimepointValue(
    summary?.price_avg_ws_wso_wsa_last_year
  ),
  priceAvgWsWsoWsaChange: standarizeTimepointValue(
    summary?.price_avg_ws_wso_wsa_change
  ),

  worthAvgDailyWsWsoWsa: standarizeTimepointValue(
    summary?.worth_avg_daily_ws_wso_wsa
  ),
  worthAvgDailyWsWsoWsaLastYear: standarizeTimepointValue(
    summary?.worth_avg_daily_ws_wso_wsa_last_year
  ),
  worthAvgDailyWsWsoWsaChange: standarizeTimepointValue(
    summary?.worth_avg_daily_ws_wso_wsa_change
  ),

  worthSumPerShopWsWsoWsa: standarizeTimepointValue(
    summary?.worth_sum_per_shop_ws_wso_wsa
  ),
  worthSumPerShopWsWsoWsaLastYear: standarizeTimepointValue(
    summary?.worth_sum_per_shop_ws_wso_wsa_last_year
  ),
  worthSumPerShopWsWsoWsaChange: standarizeTimepointValue(
    summary?.worth_sum_per_shop_ws_wso_wsa_change
  ),

  quantitySumPerShopWsWsoWsa: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_ws_wso_wsa
  ),
  quantitySumPerShopWsWsoWsaLastYear: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_ws_wso_wsa_last_year
  ),
  quantitySumPerShopWsWsoWsaChange: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_ws_wso_wsa_change
  ),

  quantityShareTotalWsWsoWsa: standarizeTimepointValue(
    summary?.quantity_share_total_ws_wso_wsa
  ),
  quantityShareTotalWsWsoWsaLastYear: standarizeTimepointValue(
    summary?.quantity_share_total_ws_wso_wsa_last_year
  ),
  quantityShareTotalWsWsoWsaChange: standarizeTimepointValue(
    summary?.quantity_share_total_ws_wso_wsa_change
  ),

  worthShareTotalWsWsoWsa: standarizeTimepointValue(
    summary?.worth_share_total_ws_wso_wsa
  ),
  worthShareTotalWsWsoWsaLastYear: standarizeTimepointValue(
    summary?.worth_share_total_ws_wso_wsa_last_year
  ),
  worthShareTotalWsWsoWsaChange: standarizeTimepointValue(
    summary?.worth_share_total_ws_wso_wsa_change
  ),

  netQuantityShareTotalWsWsoWsa: standarizeTimepointValue(
    summary?.net_quantity_share_total_ws_wso_wsa
  ),
  netQuantityShareTotalWsWsoWsaLastYear: standarizeTimepointValue(
    summary?.net_quantity_share_total_ws_wso_wsa_last_year
  ),
  netQuantityShareTotalWsWsoWsaChange: standarizeTimepointValue(
    summary?.net_quantity_share_total_ws_wso_wsa_change
  ),

  netQuantitySumWsWsoWsa: standarizeTimepointValue(
    summary?.net_quantity_sum_ws_wso_wsa
  ),
  netQuantitySumWsWsoWsaLastYear: standarizeTimepointValue(
    summary?.net_quantity_sum_ws_wso_wsa_last_year
  ),
  netQuantitySumWsWsoWsaChange: standarizeTimepointValue(
    summary?.net_quantity_sum_ws_wso_wsa_change
  ),

  avgNetQuantityPerShopWsWsoWsa: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_ws_wso_wsa
  ),
  avgNetQuantityPerShopWsWsoWsaLastYear: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_ws_wso_wsa_last_year
  ),
  avgNetQuantityPerShopWsWsoWsaChange: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_ws_wso_wsa_change
  ),

  // WS
  worthSumWs: standarizeTimepointValue(summary?.worth_sum_ws),
  worthSumWsLastYear: standarizeTimepointValue(summary?.worth_sum_ws_last_year),
  worthSumWsChange: standarizeTimepointValue(summary?.worth_sum_ws_change),

  quantitySumWs: standarizeTimepointValue(summary?.quantity_sum_ws),
  quantitySumWsLastYear: standarizeTimepointValue(
    summary?.quantity_sum_ws_last_year
  ),
  quantitySumWsChange: standarizeTimepointValue(
    summary?.quantity_sum_ws_change
  ),

  priceAvgWs: standarizeTimepointValue(summary?.price_avg_ws),
  priceAvgWsLastYear: standarizeTimepointValue(summary?.price_avg_ws_last_year),
  priceAvgWsChange: standarizeTimepointValue(summary?.price_avg_ws_change),

  worthAvgDailyWs: standarizeTimepointValue(summary?.worth_avg_daily_ws),
  worthAvgDailyWsLastYear: standarizeTimepointValue(
    summary?.worth_avg_daily_ws_last_year
  ),
  worthAvgDailyWsChange: standarizeTimepointValue(
    summary?.worth_avg_daily_ws_change
  ),

  worthSumPerShopWs: standarizeTimepointValue(summary.worth_sum_per_shop_ws),
  worthSumPerShopWsLastYear: standarizeTimepointValue(
    summary.worth_sum_per_shop_ws_last_year
  ),
  worthSumPerShopWsChange: standarizeTimepointValue(
    summary.worth_sum_per_shop_ws_change
  ),

  quantitySumPerShopWs: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_ws
  ),
  quantitySumPerShopWsLastYear: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_ws_last_year
  ),
  quantitySumPerShopWsChange: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_ws_change
  ),

  quantityShareTotalWs: standarizeTimepointValue(
    summary?.quantity_share_total_ws
  ),
  quantityShareTotalWsLastYear: standarizeTimepointValue(
    summary?.quantity_share_total_ws_last_year
  ),
  quantityShareTotalWsChange: standarizeTimepointValue(
    summary?.quantity_share_total_ws_change
  ),

  worthShareTotalWs: standarizeTimepointValue(summary?.worth_share_total_ws),
  worthShareTotalWsLastYear: standarizeTimepointValue(
    summary?.worth_share_total_ws_last_year
  ),
  worthShareTotalWsChange: standarizeTimepointValue(
    summary?.worth_share_total_ws_change
  ),

  netQuantityShareTotalWs: standarizeTimepointValue(
    summary?.net_quantity_share_total_ws
  ),
  netQuantityShareTotalWsLastYear: standarizeTimepointValue(
    summary?.net_quantity_share_total_ws_last_year
  ),
  netQuantityShareTotalWsChange: standarizeTimepointValue(
    summary?.net_quantity_share_total_ws_change
  ),

  netQuantitySumWs: standarizeTimepointValue(summary?.net_quantity_sum_ws),
  netQuantitySumWsLastYear: standarizeTimepointValue(
    summary?.net_quantity_sum_ws_last_year
  ),
  netQuantitySumWsChange: standarizeTimepointValue(
    summary?.net_quantity_sum_ws_change
  ),

  avgNetQuantityPerShopWs: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_ws
  ),
  avgNetQuantityPerShopWsLastYear: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_ws_last_year
  ),
  avgNetQuantityPerShopWsChange: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_ws_change
  ),

  // WSO
  worthSumWso: standarizeTimepointValue(summary?.worth_sum_wso),
  worthSumWsoLastYear: standarizeTimepointValue(
    summary?.worth_sum_wso_last_year
  ),
  worthSumWsoChange: standarizeTimepointValue(summary?.worth_sum_wso_change),

  quantitySumWso: standarizeTimepointValue(summary?.quantity_sum_wso),
  quantitySumWsoLastYear: standarizeTimepointValue(
    summary?.quantity_sum_wso_last_year
  ),
  quantitySumWsoChange: standarizeTimepointValue(
    summary?.quantity_sum_wso_change
  ),

  priceAvgWso: standarizeTimepointValue(summary?.price_avg_wso),
  priceAvgWsoLastYear: standarizeTimepointValue(
    summary?.price_avg_wso_last_year
  ),
  priceAvgWsoChange: standarizeTimepointValue(summary?.price_avg_wso_change),

  worthAvgDailyWso: standarizeTimepointValue(summary?.worth_avg_daily_wso),
  worthAvgDailyWsoLastYear: standarizeTimepointValue(
    summary?.worth_avg_daily_wso_last_year
  ),
  worthAvgDailyWsoChange: standarizeTimepointValue(
    summary?.worth_avg_daily_wso_change
  ),

  worthSumPerShopWso: standarizeTimepointValue(summary?.worth_sum_per_shop_wso),
  worthSumPerShopWsoLastYear: standarizeTimepointValue(
    summary?.worth_sum_per_shop_wso_last_year
  ),
  worthSumPerShopWsoChange: standarizeTimepointValue(
    summary?.worth_sum_per_shop_wso_change
  ),

  quantitySumPerShopWso: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_wso
  ),
  quantitySumPerShopWsoLastYear: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_wso_last_year
  ),
  quantitySumPerShopWsoChange: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_wso_change
  ),

  quantityShareTotalWso: standarizeTimepointValue(
    summary?.quantity_share_total_wso
  ),
  quantityShareTotalWsoLastYear: standarizeTimepointValue(
    summary?.quantity_share_total_wso_last_year
  ),
  quantityShareTotalWsoChange: standarizeTimepointValue(
    summary?.quantity_share_total_wso_change
  ),

  worthShareTotalWso: standarizeTimepointValue(summary?.worth_share_total_wso),
  worthShareTotalWsoLastYear: standarizeTimepointValue(
    summary?.worth_share_total_wso_last_year
  ),
  worthShareTotalWsoChange: standarizeTimepointValue(
    summary?.worth_share_total_wso_change
  ),

  netQuantityShareTotalWso: standarizeTimepointValue(
    summary?.net_quantity_share_total_wso
  ),
  netQuantityShareTotalWsoLastYear: standarizeTimepointValue(
    summary?.net_quantity_share_total_wso_last_year
  ),
  netQuantityShareTotalWsoChange: standarizeTimepointValue(
    summary?.net_quantity_share_total_wso_change
  ),

  netQuantitySumWso: standarizeTimepointValue(summary?.net_quantity_sum_wso),
  netQuantitySumWsoLastYear: standarizeTimepointValue(
    summary?.net_quantity_sum_wso_last_year
  ),
  netQuantitySumWsoChange: standarizeTimepointValue(
    summary?.net_quantity_sum_wso_change
  ),

  avgNetQuantityPerShopWso: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_wso
  ),
  avgNetQuantityPerShopWsoLastYear: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_wso_last_year
  ),
  avgNetQuantityPerShopWsoChange: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_wso_change
  ),

  // WSA
  worthSumWsa: standarizeTimepointValue(summary?.worth_sum_wsa),
  worthSumWsaLastYear: standarizeTimepointValue(
    summary?.worth_sum_wsa_last_year
  ),
  worthSumWsaChange: standarizeTimepointValue(summary?.worth_sum_wsa_change),

  quantitySumWsa: standarizeTimepointValue(summary?.quantity_sum_wsa),
  quantitySumWsaLastYear: standarizeTimepointValue(
    summary?.quantity_sum_wsa_last_year
  ),
  quantitySumWsaChange: standarizeTimepointValue(
    summary?.quantity_sum_wsa_change
  ),

  priceAvgWsa: standarizeTimepointValue(summary?.price_avg_wsa),
  priceAvgWsaLastYear: standarizeTimepointValue(
    summary?.price_avg_wsa_last_year
  ),
  priceAvgWsaChange: standarizeTimepointValue(summary?.price_avg_wsa_change),

  worthAvgDailyWsa: standarizeTimepointValue(summary?.worth_avg_daily_wsa),
  worthAvgDailyWsaLastYear: standarizeTimepointValue(
    summary?.worth_avg_daily_wsa_last_year
  ),
  worthAvgDailyWsaChange: standarizeTimepointValue(
    summary?.worth_avg_daily_wsa_change
  ),

  worthSumPerShopWsa: standarizeTimepointValue(summary?.worth_sum_per_shop_wsa),
  worthSumPerShopWsaLastYear: standarizeTimepointValue(
    summary?.worth_sum_per_shop_wsa_last_year
  ),
  worthSumPerShopWsaChange: standarizeTimepointValue(
    summary?.worth_sum_per_shop_wsa_change
  ),

  quantitySumPerShopWsa: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_wsa
  ),
  quantitySumPerShopWsaLastYear: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_wsa_last_year
  ),
  quantitySumPerShopWsaChange: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_wsa_change
  ),

  quantityShareTotalWsa: standarizeTimepointValue(
    summary?.quantity_share_total_wsa
  ),
  quantityShareTotalWsaLastYear: standarizeTimepointValue(
    summary?.quantity_share_total_wsa_last_year
  ),
  quantityShareTotalWsaChange: standarizeTimepointValue(
    summary?.quantity_share_total_wsa_change
  ),

  worthShareTotalWsa: standarizeTimepointValue(summary?.worth_share_total_wsa),
  worthShareTotalWsaLastYear: standarizeTimepointValue(
    summary?.worth_share_total_wsa_last_year
  ),
  worthShareTotalWsaChange: standarizeTimepointValue(
    summary?.worth_share_total_wsa_change
  ),

  netQuantityShareTotalWsa: standarizeTimepointValue(
    summary?.net_quantity_share_total_wsa
  ),
  netQuantityShareTotalWsaLastYear: standarizeTimepointValue(
    summary?.net_quantity_share_total_wsa_last_year
  ),
  netQuantityShareTotalWsaChange: standarizeTimepointValue(
    summary?.net_quantity_share_total_wsa_change
  ),

  netQuantitySumWsa: standarizeTimepointValue(summary?.net_quantity_sum_wsa),
  netQuantitySumWsaLastYear: standarizeTimepointValue(
    summary?.net_quantity_sum_wsa_last_year
  ),
  netQuantitySumWsaChange: standarizeTimepointValue(
    summary?.net_quantity_sum_wsa_change
  ),

  avgNetQuantityPerShopWsa: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_wsa
  ),
  avgNetQuantityPerShopWsaLastYear: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_wsa_last_year
  ),
  avgNetQuantityPerShopWsaChange: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_wsa_change
  ),

  // WS + WSA - these metrics are temporary and should be deleted in the future
  worthSumWsWsa: standarizeTimepointValue(summary?.worth_sum_ws_wsa),
  worthSumWsWsaLastYear: standarizeTimepointValue(
    summary?.worth_sum_ws_wsa_last_year
  ),
  worthSumWsWsaChange: standarizeTimepointValue(
    summary?.worth_sum_ws_wsa_change
  ),

  quantitySumWsWsa: standarizeTimepointValue(summary?.quantity_sum_ws_wsa),
  quantitySumWsWsaLastYear: standarizeTimepointValue(
    summary?.quantity_sum_ws_wsa_last_year
  ),
  quantitySumWsWsaChange: standarizeTimepointValue(
    summary?.quantity_sum_ws_wsa_change
  ),

  priceAvgWsWsa: standarizeTimepointValue(summary?.price_avg_ws_wsa),
  priceAvgWsWsaLastYear: standarizeTimepointValue(
    summary?.price_avg_ws_wsa_last_year
  ),
  priceAvgWsWsaChange: standarizeTimepointValue(
    summary?.price_avg_ws_wsa_change
  ),

  worthAvgDailyWsWsa: standarizeTimepointValue(summary?.worth_avg_daily_ws_wsa),
  worthAvgDailyWsWsaLastYear: standarizeTimepointValue(
    summary?.worth_avg_daily_ws_wsa_last_year
  ),
  worthAvgDailyWsWsaChange: standarizeTimepointValue(
    summary?.worth_avg_daily_ws_wsa_change
  ),

  worthSumPerShopWsWsa: standarizeTimepointValue(
    summary.worth_sum_per_shop_ws_wsa
  ),
  worthSumPerShopWsWsaLastYear: standarizeTimepointValue(
    summary.worth_sum_per_shop_ws_wsa_last_year
  ),
  worthSumPerShopWsWsaChange: standarizeTimepointValue(
    summary.worth_sum_per_shop_ws_wsa_change
  ),

  quantitySumPerShopWsWsa: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_ws_wsa
  ),
  quantitySumPerShopWsWsaLastYear: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_ws_wsa_last_year
  ),
  quantitySumPerShopWsWsaChange: standarizeTimepointValue(
    summary?.quantity_sum_per_shop_ws_wsa_change
  ),

  quantityShareTotalWsWsa: standarizeTimepointValue(
    summary?.quantity_share_total_ws_wsa
  ),
  quantityShareTotalWsWsaLastYear: standarizeTimepointValue(
    summary?.quantity_share_total_ws_wsa_last_year
  ),
  quantityShareTotalWsWsaChange: standarizeTimepointValue(
    summary?.quantity_share_total_ws_wsa_change
  ),

  worthShareTotalWsWsa: standarizeTimepointValue(
    summary?.worth_share_total_ws_wsa
  ),
  worthShareTotalWsWsaLastYear: standarizeTimepointValue(
    summary?.worth_share_total_ws_wsa_last_year
  ),
  worthShareTotalWsWsaChange: standarizeTimepointValue(
    summary?.worth_share_total_ws_wsa_change
  ),

  netQuantityShareTotalWsWsa: standarizeTimepointValue(
    summary?.net_quantity_share_total_ws_wsa
  ),
  netQuantityShareTotalWsWsaLastYear: standarizeTimepointValue(
    summary?.net_quantity_share_total_ws_wsa_last_year
  ),
  netQuantityShareTotalWsWsaChange: standarizeTimepointValue(
    summary?.net_quantity_share_total_ws_wsa_change
  ),

  netQuantitySumWsWsa: standarizeTimepointValue(
    summary?.net_quantity_sum_ws_wsa
  ),
  netQuantitySumWsWsaLastYear: standarizeTimepointValue(
    summary?.net_quantity_sum_ws_wsa_last_year
  ),
  netQuantitySumWsWsaChange: standarizeTimepointValue(
    summary?.net_quantity_sum_ws_wsa_change
  ),

  avgNetQuantityPerShopWsWsa: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_ws_wsa
  ),
  avgNetQuantityPerShopWsWsaLastYear: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_ws_wsa_last_year
  ),
  avgNetQuantityPerShopWsWsaChange: standarizeTimepointValue(
    summary?.avg_net_quantity_per_shop_ws_wsa_change
  )
});
