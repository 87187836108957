import { ThunkAction } from "redux-thunk";

import { AppState } from "store";
import {
  RESET_DATA,
  resetData,
  setSuccessMessage
} from "store/actions/appActions";
import { Company, CompanyApi, User, UserApi } from "store/reducers/userReducer";
import { SettingSuccessMessageAction } from "store/types/appTypes";
import { standarizeCompanyData, standarizeUserData } from "store/utils";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { Values } from "utils/types";

//CONSTS
const FETCH_USERS = "UserManagement.FETCH_USERS";
const FETCH_USERS_SUCCESS = "UserManagement.FETCH_USERS_SUCCESS";
const FETCH_USERS_ERROR = "UserManagement.FETCH_USERS_ERROR";

const FETCH_COMPANIES = "UserManagement.FETCH_COMPANIES";
const FETCH_COMPANIES_SUCCESS = "UserManagement.FETCH_COMPANIES_SUCCESS";
const FETCH_COMPANIES_ERROR = "UserManagement.FETCH_COMPANIES_ERROR";

export const BLOCK_USER = "UserManagement.BLOCK_USER";
const BLOCK_USER_SUCCESS = "UserManagement.BLOCK_USER_SUCCESS";
const BLOCK_USER_ERROR = "UserManagement.BLOCK_USER_ERROR";

export const DELETE_USER = "UserManagement.DELETE_USER";
const DELETE_USER_SUCCESS = "UserManagement.DELETE_USER_SUCCESS";
const DELETE_USER_ERROR = "UserManagement.DELETE_USER_ERROR";

export const RESET_PASSWORD_USER = "UserManagement.RESET_PASSWORD_USER";
const RESET_PASSWORD_USER_SUCCESS =
  "UserManagement.RESET_PASSWORD_USER_SUCCESS";
const RESET_PASSWORD_USER_ERROR = "UserManagement.RESET_PASSWORD_USER_ERROR";

const REACTIVATE_USER = "UserManagement.REACTIVATE_USER";
const REACTIVATE_USER_SUCCESS = "UserManagement.REACTIVATE_USER_SUCCESS";
const REACTIVATE_USER_ERROR = "UserManagement.REACTIVATE_USER_ERROR";

export const UNLOCK_USER = "UserManagement.UNLOCK_USER";

const SET_USER_POPUP = "UserManagement.SET_USER_POPUP";

const {
  BASE,
  BLOCK,
  RESET_PASSWORD,
  REACTIVATE
} = REST_API_ENDPOINTS.ADMIN.USER_MANAGEMENT;

//TYPES

type UsersApi = {
  max_users: number;
  users_count: number;
  results: UserApi[];
};

type UsersData = {
  maxUsers: number;
  usersCount: number;
  fetchedUsers: User[];
};

type FetchUsersAction = {
  type: typeof FETCH_USERS;
};
type FetchUsersSuccessAction = {
  payload: UsersData;
  type: typeof FETCH_USERS_SUCCESS;
};
type FetchUsersErrorAction = {
  payload: string;
  type: typeof FETCH_USERS_ERROR;
};

type FetchCompaniesAction = {
  type: typeof FETCH_COMPANIES;
};
type FetchCompaniesSuccessAction = {
  payload: Company[];
  type: typeof FETCH_COMPANIES_SUCCESS;
};
type FetchCompaniesErrorAction = {
  payload: string;
  type: typeof FETCH_COMPANIES_ERROR;
};

type BlockUserAction = {
  type: typeof BLOCK_USER;
};
type BlockUserSuccessAction = {
  type: typeof BLOCK_USER_SUCCESS;
};
type BlockUserErrorAction = {
  payload: string;
  type: typeof BLOCK_USER_ERROR;
};

type DeleteUserAction = {
  type: typeof DELETE_USER;
};
type DeleteUserSuccessAction = {
  type: typeof DELETE_USER_SUCCESS;
};
type DeleteUserErrorAction = {
  payload: string;
  type: typeof DELETE_USER_ERROR;
};

type ResetPasswordUserAction = {
  type: typeof RESET_PASSWORD_USER;
};
type ResetPasswordUserSuccessAction = {
  type: typeof RESET_PASSWORD_USER_SUCCESS;
};
type ResetPasswordUserErrorAction = {
  payload: string;
  type: typeof RESET_PASSWORD_USER_ERROR;
};

type ReactivateUserAction = {
  type: typeof REACTIVATE_USER;
};
type ReactivateUserSuccessAction = {
  type: typeof REACTIVATE_USER_SUCCESS;
};
type ReactivateUserErrorAction = {
  payload: string;
  type: typeof REACTIVATE_USER_ERROR;
};

type SetUserPopupAction = {
  payload: string;
  type: typeof SET_USER_POPUP;
};

type Actions = {
  FETCH_USERS: FetchUsersAction;
  FETCH_USERS_SUCCESS: FetchUsersSuccessAction;
  FETCH_USERS_ERROR: FetchUsersErrorAction;
  FETCH_COMPANIES: FetchCompaniesAction;
  FETCH_COMPANIES_SUCCESS: FetchCompaniesSuccessAction;
  FETCH_COMPANIES_ERROR: FetchCompaniesErrorAction;
  BLOCK_USER: BlockUserAction;
  BLOCK_USER_SUCCESS: BlockUserSuccessAction;
  BLOCK_USER_ERROR: BlockUserErrorAction;
  DELETE_USER: DeleteUserAction;
  DELETE_USER_SUCCESS: DeleteUserSuccessAction;
  DELETE_USER_ERROR: DeleteUserErrorAction;
  RESET_PASSWORD_USER: ResetPasswordUserAction;
  RESET_PASSWORD_USER_SUCCESS: ResetPasswordUserSuccessAction;
  RESET_PASSWORD_USER_ERROR: ResetPasswordUserErrorAction;
  REACTIVATE_USER: ReactivateUserAction;
  REACTIVATE_USER_SUCCESS: ReactivateUserSuccessAction;
  REACTIVATE_USER_ERROR: ReactivateUserErrorAction;
  SET_USER_POPUP: SetUserPopupAction;
};

interface UserManagementState {
  fetchedUsers: User[];
  fetchedCompanies: Company[];
  isFetching: boolean;
  error: string;
  isUserActionInProgress: boolean;
  visibleUserPopup: string;
  maxUsers: number;
  usersCount: number;
}

//ACTIONS
const fetchUsers = (): FetchUsersAction => ({
  type: FETCH_USERS
});
const fetchUsersSuccess = (payload: UsersData): FetchUsersSuccessAction => ({
  payload,
  type: FETCH_USERS_SUCCESS
});
const fetchUsersError = (payload: string): FetchUsersErrorAction => ({
  payload,
  type: FETCH_USERS_ERROR
});

const fetchCompanies = (): FetchCompaniesAction => ({
  type: FETCH_COMPANIES
});
const fetchCompaniesSuccess = (
  payload: Company[]
): FetchCompaniesSuccessAction => ({
  payload,
  type: FETCH_COMPANIES_SUCCESS
});
const fetchCompaniesError = (payload: string): FetchCompaniesErrorAction => ({
  payload,
  type: FETCH_COMPANIES_ERROR
});

const blockUser = (): BlockUserAction => ({
  type: BLOCK_USER
});
const blockUserSuccess = (): BlockUserSuccessAction => ({
  type: BLOCK_USER_SUCCESS
});
const blockUserError = (payload: string): BlockUserErrorAction => ({
  payload,
  type: BLOCK_USER_ERROR
});

const deleteUser = (): DeleteUserAction => ({
  type: DELETE_USER
});
const deleteUserSuccess = (): DeleteUserSuccessAction => ({
  type: DELETE_USER_SUCCESS
});
const deleteUserError = (payload: string): DeleteUserErrorAction => ({
  payload,
  type: DELETE_USER_ERROR
});

const resetPasswordUser = (): ResetPasswordUserAction => ({
  type: RESET_PASSWORD_USER
});
const resetPasswordUserSuccess = (): ResetPasswordUserSuccessAction => ({
  type: RESET_PASSWORD_USER_SUCCESS
});
const resetPasswordUserError = (
  payload: string
): ResetPasswordUserErrorAction => ({
  payload,
  type: RESET_PASSWORD_USER_ERROR
});

const reactivateUser = (): ReactivateUserAction => ({
  type: REACTIVATE_USER
});
const reactivateUserSuccess = (): ReactivateUserSuccessAction => ({
  type: REACTIVATE_USER_SUCCESS
});
const reactivateUserError = (payload: string): ReactivateUserErrorAction => ({
  payload,
  type: REACTIVATE_USER_ERROR
});

export const setUserPopup = (payload: string): SetUserPopupAction => ({
  payload,
  type: SET_USER_POPUP
});

//THUNKS
export const getUsers = (): ThunkAction<
  void,
  AppState,
  undefined,
  FetchUsersAction | FetchUsersSuccessAction | FetchUsersErrorAction
> => async dispatch => {
  try {
    dispatch(fetchUsers());

    const response = await HTTP.get<UsersApi>(
      REST_API_ENDPOINTS.ADMIN.USER_MANAGEMENT.BASE
    );

    const users = response.data.results.map(user =>
      standarizeUserData({ user, currentTier: 0 })
    );

    const usersData = {
      maxUsers: response.data.max_users || 0,
      usersCount: response.data.users_count || 0,
      fetchedUsers: users || []
    };

    dispatch(fetchUsersSuccess(usersData));
  } catch (err) {
    dispatch(fetchUsersError(err));
  }
};

export const getCompanies = (): ThunkAction<
  void,
  AppState,
  undefined,
  FetchCompaniesAction | FetchCompaniesSuccessAction | FetchCompaniesErrorAction
> => async dispatch => {
  try {
    dispatch(fetchCompanies());

    const response = await HTTP.get<CompanyApi[]>(
      REST_API_ENDPOINTS.ADMIN.USER_MANAGEMENT.COMPANIES
    );

    const companies = response?.data.map(company =>
      standarizeCompanyData(company)
    );

    dispatch(fetchCompaniesSuccess(companies));
  } catch (err) {
    dispatch(fetchCompaniesError(err));
  }
};

export const blockUserThunk = (
  userId: string,
  userEmail: string
): ThunkAction<
  void,
  AppState,
  undefined,
  | BlockUserAction
  | BlockUserSuccessAction
  | BlockUserErrorAction
  | SetUserPopupAction
  | SettingSuccessMessageAction
> => async dispatch => {
  try {
    dispatch(blockUser());

    await HTTP.post<unknown>(`${BASE}${userId}${BLOCK}`);

    dispatch(blockUserSuccess());
    dispatch(setSuccessMessage(`Zablokowano użytkownika ${userEmail}`));
    dispatch(getUsers());
    dispatch(setUserPopup(""));
  } catch (err) {
    dispatch(blockUserError(err.message));
  }
};

export const deleteUserThunk = (
  userId: string,
  userEmail: string
): ThunkAction<
  void,
  AppState,
  undefined,
  | DeleteUserAction
  | DeleteUserSuccessAction
  | DeleteUserErrorAction
  | SetUserPopupAction
  | SettingSuccessMessageAction
> => async dispatch => {
  try {
    dispatch(deleteUser());

    await HTTP.delete<unknown>(`${BASE}${userId}`);

    dispatch(deleteUserSuccess());
    dispatch(setSuccessMessage(`Usunięto użytkownika ${userEmail}`));
    dispatch(getUsers());
    dispatch(setUserPopup(""));
  } catch (err) {
    dispatch(deleteUserError(err.message));
  }
};

export const resetPasswordThunk = (
  userId: string,
  userEmail: string
): ThunkAction<
  void,
  AppState,
  undefined,
  | ResetPasswordUserAction
  | ResetPasswordUserSuccessAction
  | ResetPasswordUserErrorAction
  | SetUserPopupAction
  | SettingSuccessMessageAction
> => async dispatch => {
  try {
    dispatch(resetPasswordUser());

    await HTTP.post<unknown>(`${BASE}${userId}${RESET_PASSWORD}`);

    dispatch(resetPasswordUserSuccess());
    dispatch(setSuccessMessage(`Zresetowano hasło użytkownika ${userEmail}`));
    dispatch(getUsers());
    dispatch(setUserPopup(""));
  } catch (err) {
    dispatch(resetPasswordUserError(err.message));
  }
};

export const reactivateUserThunk = (
  userId: string,
  userEmail: string
): ThunkAction<
  void,
  AppState,
  undefined,
  | ReactivateUserAction
  | ReactivateUserSuccessAction
  | ReactivateUserErrorAction
  | SetUserPopupAction
  | SettingSuccessMessageAction
> => async dispatch => {
  try {
    dispatch(reactivateUser());

    await HTTP.post<unknown>(`${BASE}${userId}${REACTIVATE}`);

    dispatch(reactivateUserSuccess());
    dispatch(setSuccessMessage(`Aktywowano użytkownika ${userEmail}`));
    dispatch(getUsers());
    dispatch(setUserPopup(""));
  } catch (err) {
    dispatch(reactivateUserError(err.message));
  }
};

//REDUCER
const initialState: UserManagementState = {
  fetchedUsers: [],
  fetchedCompanies: [],
  isFetching: false,
  error: "",
  isUserActionInProgress: false,
  visibleUserPopup: "",
  maxUsers: 0,
  usersCount: 0
};

export const userManagementReducer = (
  state: UserManagementState = initialState,
  action: Values<Actions> | ReturnType<typeof resetData>
): UserManagementState => {
  switch (action.type) {
    case FETCH_USERS:
    case FETCH_COMPANIES:
      return {
        ...state,
        isFetching: true,
        error: ""
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: ""
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        fetchedCompanies: action.payload,
        isFetching: false,
        error: ""
      };
    case FETCH_USERS_ERROR:
    case FETCH_COMPANIES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case BLOCK_USER:
    case DELETE_USER:
    case RESET_PASSWORD_USER:
      return {
        ...state,
        isUserActionInProgress: true,
        error: ""
      };
    case BLOCK_USER_SUCCESS:
    case DELETE_USER_SUCCESS:
    case RESET_PASSWORD_USER_SUCCESS:
      return {
        ...state,
        isUserActionInProgress: false,
        error: ""
      };
    case BLOCK_USER_ERROR:
    case DELETE_USER_ERROR:
    case RESET_PASSWORD_USER_ERROR:
      return {
        ...state,
        isUserActionInProgress: false,
        error: action.payload
      };
    case SET_USER_POPUP:
      return {
        ...state,
        visibleUserPopup: action.payload,
        error: ""
      };

    case RESET_DATA:
      return initialState;

    default:
      return state;
  }
};
