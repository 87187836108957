import React from "react";

import { pathShape } from "pages/Reports/partials/Chart/StandardChart/utils";
import { Nullable } from "utils/types";

import { SingleTileData } from "../ChartValueTiles/ChartValueSingleTile";
import { BarTile } from "./BarTile";

type Props = {
  id: string;
  color: string;
  height: number;
  width: number;
  horizontalPos: number;
  verticalPos: number;
  radius: number;
  onMouseMove: (e: React.MouseEvent) => void;
  onMouseLeave: () => void;
  onTimelineClick?: () => void;
  isBarUpsideDown?: boolean;
  showFakeBar?: boolean;
  tile?: Nullable<SingleTileData>;
  isHistorical?: boolean;
};

const lightGreyColor = "rgba(43,43,43,0.33)";
export const Bar: React.FC<Props> = ({
  id,
  height,
  color,
  width,
  horizontalPos,
  verticalPos,
  radius,
  onMouseMove,
  onMouseLeave,
  onTimelineClick = () => {},
  isBarUpsideDown = false,
  showFakeBar = false,
  tile = null,
  isHistorical = false
}) => {
  const d = isBarUpsideDown
    ? pathShape(width, height, 0, 0, radius, radius)
    : pathShape(width, height, radius, radius, 0, 0);
  return (
    <g>
      <path
        stroke={showFakeBar ? lightGreyColor : "none"}
        strokeDasharray={showFakeBar ? "2,2" : "0"}
        id={id}
        fillOpacity={showFakeBar ? "0.15" : "1"}
        d={d}
        fill={showFakeBar ? "transparent" : color}
        transform={`translate(${horizontalPos},${verticalPos})`}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onClick={onTimelineClick}
        data-multiline
        data-historical={isHistorical}
        data-secondary
      />
      {tile && (
        <BarTile
          tile={tile}
          barWidth={width}
          horizontalPosition={horizontalPos}
          verticalPosition={verticalPos}
        />
      )}
    </g>
  );
};
