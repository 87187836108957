import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";

type State<T> = {
  isOpen: boolean;
  setOpen: (shouldOpen: boolean, ...args: T[]) => void;
};

type OnClickOutsideOption = "open" | "close" | "toggle" | null;

interface Props<T> {
  isInitiallyOpen?: boolean;
  children: (state: State<T>) => React.ReactElement;
  onClickOutside?: OnClickOutsideOption;
  onChange?: (shouldOpen: boolean, ...args: T[]) => void;
}

export const Toggle = <T extends any>({
  isInitiallyOpen = false,
  onClickOutside,
  children,
  onChange
}: Props<T>) => {
  const [isOpen, setOpen] = React.useState(isInitiallyOpen || false);

  const handleChange = (shouldOpen: boolean, ...args: T[]) => {
    setOpen(shouldOpen);
    if (onChange) {
      onChange(shouldOpen, ...args);
    }
  };

  if (!onClickOutside) {
    return children({ isOpen, setOpen: handleChange });
  }

  const handleClickOutside = () => {
    switch (onClickOutside) {
      case "open":
        return setOpen(true);
      case "close":
        return setOpen(false);
      case "toggle":
        return setOpen(!isOpen);
      default:
        return;
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      {children({ isOpen, setOpen: handleChange })}
    </OutsideClickHandler>
  );
};
