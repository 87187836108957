import { format } from "date-fns";

import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import { normalizeLocationSummary } from "pages/Reports/utils/normalize/normalizeLocationSummary";
import {
  DEFAULT_DATE_FORMAT,
  getValidId,
  standarizeTimepointValue
} from "utils";
import { Periods } from "utils/types";

import {
  Location,
  LocationApi,
  LocationDatum,
  LocationDatumApi,
  LocationRawDataset,
  LocationRegion,
  LocationRegionApi,
  LocationTimePoint,
  LocationTimePointApi
} from "../types/locationRawTypes";

const standarizeDatum = (datum: LocationDatumApi): LocationDatum => {
  const vendorId = datum.vendor_id || "";
  const resultId = datum.result_id;
  const type = datum.type;
  const name = getChartLabel({
    vendorId,
    resultId,
    type
  });

  return {
    vendorId,
    resultId,
    name,
    type,
    voivodeships: datum.voivodeships.map(standarizeRegion()),
    counties: datum.counties.map(standarizeRegion(true))
  };
};

const standarizeRegion = (isCounty?: boolean) => (
  region: LocationRegionApi
): LocationRegion => ({
  id: isCounty ? getValidId(region.id || "") : region.id || "",
  voivodeship: region.voivodeship,
  timeline: region.timeline.map(standarizeTimepoint),
  existingMetrics: {
    worthSum: region?.existing_metrics?.worth_sum || false,
    worthSumChange: region?.existing_metrics?.worth_sum_change || false,
    worthSumChangePercentage:
      region?.existing_metrics?.worth_sum_change_percentage || false,
    worthSumLastYear: region?.existing_metrics?.worth_sum_last_year || false,
    quantitySum: region?.existing_metrics?.quantity_sum || false,
    quantitySumChange: region?.existing_metrics?.quantity_sum_change || false,
    quantitySumChangePercentage:
      region?.existing_metrics?.quantity_sum_change_percentage || false,
    quantitySumLastYear:
      region?.existing_metrics?.quantity_sum_last_year || false,
    worthSumPerShop: region?.existing_metrics?.worth_sum_per_shop || false,
    worthSumPerShopChange:
      region?.existing_metrics?.worth_sum_per_shop_change || false,
    worthSumPerShopChangePercentage:
      region?.existing_metrics?.worth_sum_per_shop_change_percentage || false,
    worthSumPerShopLastYear:
      region?.existing_metrics?.worth_sum_per_shop_last_year || false,
    quantitySumPerShop:
      region?.existing_metrics?.quantity_sum_per_shop || false,
    quantitySumPerShopChange:
      region?.existing_metrics?.quantity_sum_per_shop_change || false,
    quantitySumPerShopChangePercentage:
      region?.existing_metrics?.quantity_sum_per_shop_change_percentage ||
      false,
    quantitySumPerShopLastYear:
      region?.existing_metrics?.quantity_sum_per_shop_last_year || false,
    priceAvg: region?.existing_metrics?.price_avg || false,
    priceAvgChange: region?.existing_metrics?.price_avg_change || false,
    priceAvgChangePercentage:
      region?.existing_metrics?.price_avg_change_percentage || false,
    priceAvgLastYear: region?.existing_metrics?.price_avg_last_year || false,
    avgShopsCount: region?.existing_metrics?.avg_shops_count || false,
    avgShopsCountChange:
      region?.existing_metrics?.avg_shops_count_change || false,
    avgShopsCountChangePercentage:
      region?.existing_metrics?.avg_shops_count_change_percentage || false,
    avgShopsCountLastYear:
      region?.existing_metrics?.avg_shops_count_last_year || false,
    distributionRange: region?.existing_metrics?.distribution_range || false,
    distributionRangeChange:
      region?.existing_metrics?.distribution_range_change || false,
    distributionRangeChangePercentage:
      region?.existing_metrics?.distribution_range_change_percentage || false,
    distributionRangeLastYear:
      region?.existing_metrics?.distribution_range_last_year || false,
    avgPlanogramShopsCount:
      region?.existing_metrics?.avg_planogram_shops_count || false,
    avgPlanogramShopsCountChange:
      region?.existing_metrics?.avg_planogram_shops_count_change || false,
    avgPlanogramShopsCountChangePercentage:
      region?.existing_metrics?.avg_planogram_shops_count_change_percentage ||
      false,
    avgPlanogramShopsCountLastYear:
      region?.existing_metrics?.avg_planogram_shops_count_last_year || false,
    planogramDistributionRange:
      region?.existing_metrics?.planogram_distribution_range || false,
    planogramDistributionRangeChange:
      region?.existing_metrics?.planogram_distribution_range_change || false,
    planogramDistributionRangeChangePercentage:
      region?.existing_metrics
        ?.planogram_distribution_range_change_percentage || false,
    planogramDistributionRangeLastYear:
      region?.existing_metrics?.planogram_distribution_range_last_year || false,
    avgTemp: region?.existing_metrics?.avg_temp || false,
    avgTempLastYear: region?.existing_metrics?.avg_temp_last_year || false,
    avgRainfall: region?.existing_metrics?.avg_rainfall || false,
    avgRainfallLastYear:
      region?.existing_metrics?.avg_rainfall_last_year || false
  }
});

const standarizeTimepoint = (
  dataPoint: LocationTimePointApi
): LocationTimePoint => ({
  date: format(new Date(dataPoint.date || Date.now()), DEFAULT_DATE_FORMAT),
  dateLastYear: dataPoint.date_last_year
    ? format(
        new Date(dataPoint.date_last_year || Date.now()),
        DEFAULT_DATE_FORMAT
      )
    : null,
  worthSum: standarizeTimepointValue(dataPoint.worth_sum),
  worthSumChange: standarizeTimepointValue(dataPoint.worth_sum_change),
  worthSumChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_change_percentage
  ),
  worthSumLastYear: standarizeTimepointValue(dataPoint.worth_sum_last_year),

  quantitySum: standarizeTimepointValue(dataPoint.quantity_sum),
  quantitySumChange: standarizeTimepointValue(dataPoint.quantity_sum_change),
  quantitySumChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_change_percentage
  ),
  quantitySumLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_last_year
  ),

  worthSumPerShop: standarizeTimepointValue(dataPoint.worth_sum_per_shop),
  worthSumPerShopChange: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_change
  ),
  worthSumPerShopChangePercentage: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_change_percentage
  ),
  worthSumPerShopLastYear: standarizeTimepointValue(
    dataPoint.worth_sum_per_shop_last_year
  ),

  quantitySumPerShop: standarizeTimepointValue(dataPoint.quantity_sum_per_shop),
  quantitySumPerShopChange: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_change
  ),
  quantitySumPerShopChangePercentage: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_change_percentage
  ),
  quantitySumPerShopLastYear: standarizeTimepointValue(
    dataPoint.quantity_sum_per_shop_last_year
  ),

  priceAvg: standarizeTimepointValue(dataPoint.price_avg),
  priceAvgChange: standarizeTimepointValue(dataPoint.price_avg_change),
  priceAvgChangePercentage: standarizeTimepointValue(
    dataPoint.price_avg_change_percentage
  ),
  priceAvgLastYear: standarizeTimepointValue(dataPoint.price_avg_last_year),

  avgShopsCount: standarizeTimepointValue(dataPoint.avg_shops_count),
  avgShopsCountChange: standarizeTimepointValue(
    dataPoint.avg_shops_count_change
  ),
  avgShopsCountChangePercentage: standarizeTimepointValue(
    dataPoint.avg_shops_count_change_percentage
  ),
  avgShopsCountLastYear: standarizeTimepointValue(
    dataPoint.avg_shops_count_last_year
  ),

  distributionRange: standarizeTimepointValue(dataPoint.distribution_range),
  distributionRangeChange: standarizeTimepointValue(
    dataPoint.distribution_range_change
  ),
  distributionRangeChangePercentage: standarizeTimepointValue(
    dataPoint.distribution_range_change_percentage
  ),
  distributionRangeLastYear: standarizeTimepointValue(
    dataPoint.distribution_range_last_year
  ),

  avgPlanogramShopsCount: standarizeTimepointValue(
    dataPoint.avg_planogram_shops_count
  ),
  avgPlanogramShopsCountChange: standarizeTimepointValue(
    dataPoint.avg_planogram_shops_count_change
  ),
  avgPlanogramShopsCountChangePercentage: standarizeTimepointValue(
    dataPoint.avg_planogram_shops_count_change_percentage
  ),
  avgPlanogramShopsCountLastYear: standarizeTimepointValue(
    dataPoint.avg_planogram_shops_count_last_year
  ),

  planogramDistributionRange: standarizeTimepointValue(
    dataPoint.planogram_distribution_range
  ),
  planogramDistributionRangeChange: standarizeTimepointValue(
    dataPoint.planogram_distribution_range_change
  ),
  planogramDistributionRangeChangePercentage: standarizeTimepointValue(
    dataPoint.planogram_distribution_range_change_percentage
  ),
  planogramDistributionRangeLastYear: standarizeTimepointValue(
    dataPoint.planogram_distribution_range_last_year
  ),

  avgTemp: standarizeTimepointValue(dataPoint.avg_temp),
  avgTempLastYear: standarizeTimepointValue(dataPoint.avg_temp_last_year),
  avgRainfall: standarizeTimepointValue(dataPoint.avg_rainfall),
  avgRainfallLastYear: standarizeTimepointValue(
    dataPoint.avg_rainfall_last_year
  )
});

export const standarizeLocation = (location: LocationApi): Location => ({
  summary: normalizeLocationSummary(location.summary),
  data: location.data.map(standarizeDatum),
  info: location.info || []
});

export const getStandarizedDataset = (
  query: string,
  location: Location,
  period: Periods
): LocationRawDataset => ({
  query,
  timestamp: Date.now(),
  isActive: false,
  data: location,
  periodType: period
});
