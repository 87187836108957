import * as React from "react";
import { useSelector } from "react-redux";
import { EnterHandler } from "react-transition-group/Transition";

import { Fade, Portal } from "components/atoms";
import { windowWidthSelector } from "store/selectors/appSelectors";
import { TOOLTIP_ORIENTATIONS } from "utils";
import { Values } from "utils/types";

import s from "./movableTooltipWrapper.module.scss";

interface Props {
  positionX: number;
  positionY: number;
  orientation: Values<typeof TOOLTIP_ORIENTATIONS>;
  children: React.ReactNode;
  isPinTooltip?: boolean;
}

export const MovableTooltipWrapper = ({
  positionX,
  positionY,
  orientation,
  children,
  isPinTooltip = false
}: Props) => {
  const [tooltipHeight, setTooltipHeight] = React.useState(0);
  const [tooltipWidth, setTooltipWidth] = React.useState(0);
  const [node, setNode] = React.useState<Node>();
  const windowWidth = useSelector(windowWidthSelector);
  const isVertical = orientation === TOOLTIP_ORIENTATIONS.VERTICAL;
  const isCursorOnLeft = isPinTooltip ? true : positionX < windowWidth / 2;
  const tooltipXPosition = isPinTooltip
    ? positionX
    : isCursorOnLeft
    ? positionX + 10
    : positionX - 10;

  const handleTooltipAppear: EnterHandler = node => {
    setNode(node);
    // @ts-ignore
    setTooltipWidth(node.childNodes[0]?.getBoundingClientRect().width || 0);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (node) {
      // @ts-ignore
      const height = node.childNodes[0]?.getBoundingClientRect()?.height || 0;
      setTooltipHeight(height);
      // @ts-ignore
      const width = node.childNodes[0]?.getBoundingClientRect()?.width || 0;
      setTooltipWidth(width);
    }
  });

  const cursorSideStyle = isCursorOnLeft
    ? {}
    : {
        transform:
          !isPinTooltip && tooltipWidth < tooltipXPosition
            ? `translate(-${tooltipWidth}px, 0)`
            : `translate(-100%, 0)`
      };

  return (
    <Portal id="js-movable-tooltip">
      <div
        className={s.horizontal}
        style={{
          ...cursorSideStyle,
          top: isVertical
            ? positionY - tooltipHeight
            : positionY - tooltipHeight / 2,
          left: isPinTooltip
            ? tooltipXPosition - tooltipWidth / 2
            : tooltipXPosition
        }}
      >
        <Fade timeout={50} onEntered={handleTooltipAppear} appear in>
          {children}
        </Fade>
      </div>
    </Portal>
  );
};
