import { Action, createHook, createStore } from "react-sweet-state";

type State = {
  visible: boolean;
};

type Actions = typeof actions;

const initialState: State = {
  visible: false
};

const actions = {
  updateVisible: (visible: boolean): Action<State> => ({ setState }) => {
    setState({ visible });
  }
};

const CurtainStore = createStore<State, Actions>({
  name: "chartCurtain",
  initialState,
  actions
});

export const useCurtainState = createHook(CurtainStore);
