import { useSelector } from "react-redux";

import qs from "query-string";
import { equals } from "ramda";

import { SpinnerLoader, Text } from "components/atoms";
import { Accordion } from "components/organisms";
import { useFetchSidebarInsights } from "pages/Insights/hooks/useSidebarInsights";
import { SidebarInsightBox } from "pages/Insights/partials/SidebarInsights/SidebarInsightBox";
import { SingleInsightData } from "pages/Insights/utils/types";
import {
  pathnameSelector,
  queryParamsSelector
} from "store/selectors/routerSelectors";

import s from "./sidebarInsights.module.scss";

const SidebarInsightsList = ({
  title,
  insights,
  open,
  indexStart = 0
}: {
  title: string;
  insights: SingleInsightData[];
  open: "none" | "all" | "first";
  indexStart?: number;
}) => {
  const pathname = useSelector(pathnameSelector);
  const queryParams = useSelector(queryParamsSelector);

  if (!insights.length) {
    return null;
  }

  const getOpen = (index: number) => {
    if (open === "none") return false;
    if (open === "all") return true;
    return index === 0;
  };

  return (
    <section>
      <h2 className={s.listTitle}>{title}</h2>
      {insights?.map((insight, index) => {
        const { insightUrl } = insight;
        const insightQueryParams = qs.parse(insightUrl.split("?")[1]);
        const isCurrentInsight =
          insightUrl.includes(pathname) &&
          equals(insightQueryParams, queryParams);
        const currentIndex = indexStart + index;

        return (
          <Accordion
            key={insight.insightId}
            headerText={`${currentIndex + 1}. ${insight.insightTitle}`}
            sectionKey={insight.insightId}
            className={{
              headerText: s.headerText,
              sectionContent: s.sectionContent
            }}
            initialAnimate={false}
            isInitiallyOpen={isCurrentInsight || getOpen(index)}
          >
            <SidebarInsightBox insight={insight} />
          </Accordion>
        );
      })}
    </section>
  );
};

export const SidebarInsights = () => {
  const { data, isLoading } = useFetchSidebarInsights();

  const matchedInsights =
    data?.filter(insight => insight.similarity >= 1) || [];
  const unmatchedInsights =
    data?.filter(insight => insight.similarity < 1).slice(0, 3) || [];

  if (isLoading) {
    return (
      <div className={s.loaderWrapper}>
        <SpinnerLoader />
      </div>
    );
  }

  if (!data?.length) {
    return (
      <div className={s.noInsightsWrapper}>
        <Text className={s.noInsightsText}>
          Wygląda na to, że nie udało nam się znaleźć insightów pasujących do
          tego wykresu.
        </Text>
        <Text className={s.noInsightsText}>
          Zmień ustawienia filtrów (np. zakres czasu) to spróbujemy jeszcze raz.
        </Text>
      </div>
    );
  }

  return (
    <>
      <SidebarInsightsList
        title={`Insight'y dopasowane do Twojego zapytania (${matchedInsights.length})`}
        insights={matchedInsights}
        open="all"
      />
      <SidebarInsightsList
        title={`Insight'y zbliżone do Twojego zapytania (${unmatchedInsights.length})`}
        insights={unmatchedInsights}
        indexStart={matchedInsights.length}
        open={matchedInsights.length > 0 ? "none" : "first"}
      />
    </>
  );
};
