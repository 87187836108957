import { useGetReceiptsByPeriod } from "api/receipts/mappers/useGetReceiptsByPeriod";
import { ReportTypes } from "api/shares/types";

import { DATASET_TYPES } from "utils";

export const useReceiptsTableData = () => {
  const { data } = useGetReceiptsByPeriod(ReportTypes.TABLE);

  if (!data) {
    return [];
  }
  return (
    data.currentPeriod.filter(({ type }) => type === DATASET_TYPES.OWN) || []
  );
};
