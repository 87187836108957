import { appendInsightId } from "pages/Insights/partials/InsightsBox/utils";
import { SellApi } from "pages/Reports/sections/Sell/redux/types/sellRawTypes";
import { getOwnSummary, getReferenceSummary } from "pages/Reports/utils";
import { normalizeSellResponse } from "pages/Reports/utils/normalize/normalizeSellResponse";
import { normalizeSellSummary } from "pages/Reports/utils/normalize/normalizeSellSummary";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { pushReportFetchEvent } from "utils/googleTagManager/dataLayer";
import { Periods } from "utils/types";

export const sellsServices = {
  fetchSells: async (queryString: string, period: Periods) => {
    const url = appendInsightId(
      `${REST_API_ENDPOINTS.REPORTS.SALES}?${queryString}`
    );
    const { data } = await HTTP.get<SellApi>(url);

    const ownSummary = getOwnSummary(data.summary);
    const referenceSummary = getReferenceSummary(data.summary);
    const standardizedData = {
      ownSummary: normalizeSellSummary(ownSummary),
      referenceSummary: referenceSummary
        ? normalizeSellSummary(referenceSummary)
        : null,
      data: normalizeSellResponse(data.data),
      info: data.info || []
    };

    pushReportFetchEvent("Sprzedaż");

    return {
      query: queryString,
      timestamp: Date.now(),
      isActive: false,
      data: standardizedData,
      periodType: period
    };
  }
};
