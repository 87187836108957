import { getName } from "api/segments/utils/getName";
import {
  sortByName,
  sortByType,
  sortByVendorName
} from "api/segments/utils/sortCharts";
import {
  sortByIndex,
  sortByPrefix,
  sortUnknown
} from "api/segments/utils/sortSegments";
import { format } from "date-fns";
import { omit, uniq } from "ramda";

import {
  Segments,
  SegmentsDataByProductApi,
  SegmentsDataBySegmentApi
} from "pages/Reports/sections/Segments/types";
import {
  ExistingSegmentsMetrics,
  ExistingSegmentsMetricsApi,
  SegmentsMetrics,
  SegmentsMetricsApi
} from "pages/Reports/types/metrics";
import { newDateByTimezone } from "store/utils";
import { TimelineApi } from "store/utils/types";
import { DATASET_TYPES, DEFAULT_DATE_FORMAT } from "utils";

import { snake2camel } from "../snake2camel";

const correctVendorName = (vendor: string, isOneSupplier: boolean) => {
  if (isOneSupplier) return "";
  if (vendor === DATASET_TYPES.OWN) return "Dane wspólne";
  if (vendor === DATASET_TYPES.COMPETITOR) return "Dane konkurencji";
  if (vendor === DATASET_TYPES.ATTRIBUTES) return "Dane wspólne";
  return vendor;
};

const prepareExistingMetrics = (metrics: ExistingSegmentsMetricsApi) => {
  return Object.keys(metrics).reduce(
    (acc, key) => ({
      ...acc,
      [snake2camel(key)]: metrics[key as keyof SegmentsMetricsApi]
    }),
    {}
  ) as ExistingSegmentsMetrics;
};

const prepareTimeline = (time: TimelineApi<SegmentsMetricsApi>) => {
  return {
    date: format(
      newDateByTimezone(new Date(time.date || Date.now())),
      DEFAULT_DATE_FORMAT
    ),
    dateLastYear: time.date_last_year
      ? format(
          newDateByTimezone(new Date(time.date_last_year || Date.now())),
          DEFAULT_DATE_FORMAT
        )
      : null,
    unit: time.unit?.toLowerCase() || "",
    ...(Object.keys(omit(["date", "date_last_year", "unit"], time)).reduce(
      (acc, key) => ({
        ...acc,
        [snake2camel(key)]: time[key as keyof SegmentsMetricsApi] ?? null
      }),
      {}
    ) as SegmentsMetrics)
  };
};

export const normalizeSegmentsResponseByProduct = (
  data: SegmentsDataByProductApi[]
): Segments[] => {
  const isOneSupplier = uniq(data.map(data => data.vendor_id)).length === 1;

  data
    // sort products by name, vendor and type
    .sort((a, b) => sortByName(a.result_id, b.result_id))
    .sort((a, b) => sortByVendorName(a.vendor_id, b.vendor_id))
    .sort((a, b) => sortByType(a.type, b.type))
    .forEach(segment =>
      // sort segments by prefix
      segment.segments
        .sort((a, b) => sortByIndex(a.short_name, b.short_name))
        .sort((a, b) => sortByPrefix(a.short_name, b.short_name))
        .sort((a, b) => sortUnknown(a.short_name, b.short_name))
    );

  return data.map(data => ({
    name: data.result_id,
    type: data.type,
    vendorName: correctVendorName(data.vendor_id || "", isOneSupplier),
    segments: data.segments.map(segment => ({
      label: segment.name,
      shortLabel: segment.short_name,
      existingMetrics: prepareExistingMetrics(segment.existing_metrics),
      timeline: segment.timeline.map(prepareTimeline)
    }))
  }));
};

export const normalizeSegmentsResponseBySegment = (
  data: SegmentsDataBySegmentApi[]
): Segments[] => {
  data
    // sort segments by prefix
    .sort((a, b) => sortByIndex(a.short_name, b.short_name))
    .sort((a, b) => sortByPrefix(a.short_name, b.short_name))
    .sort((a, b) => sortUnknown(a.short_name, b.short_name))
    .forEach(product =>
      product.products
        // sort products by name, vendor and type
        .sort((a, b) => sortByName(a.result_id, b.result_id))
        .sort((a, b) => sortByVendorName(a.vendor_id, b.vendor_id))
        .sort((a, b) => sortByType(a.type, b.type))
    );

  return data.map(data => ({
    name: data.short_name,
    type: DATASET_TYPES.OWN,
    vendorName: data.name,
    segments: data.products.map(product => ({
      label: getName(
        {
          vendorName: product.vendor_id || "",
          name: product.result_id,
          type: product.type,
          segments: []
        },
        false
      ),
      shortLabel: product.result_id,
      existingMetrics: prepareExistingMetrics(product.existing_metrics),
      timeline: product.timeline.map(prepareTimeline)
    }))
  }));
};
