import * as React from "react";

import cn from "classnames";

import { Text } from "components/atoms";
import { INPUT_TYPE } from "utils";

import s from "./textarea.module.scss";

interface Props
  extends Omit<React.AllHTMLAttributes<HTMLTextAreaElement>, "className"> {
  label?: string;
  error?: string;
  className?: {
    input?: string;
    base?: string;
    label?: string;
    labelText?: string;
  };
  tooltipText?: string;
  testId?: string;
}

export const Textarea = ({
  className = {},
  type = INPUT_TYPE.TEXT,
  label = "",
  value,
  error = "",
  tooltipText = "",
  testId,
  ...rest
}: Props) => {
  return (
    <div
      className={cn(className.base, { [s.tooltip]: tooltipText })}
      data-tooltip={tooltipText}
    >
      <label className={cn(className.label)}>
        {label && (
          <Text className={cn(s.labelText, className.labelText)}>{label}</Text>
        )}
        <textarea
          className={cn(
            s.input,
            {
              [s.filled]: type === INPUT_TYPE.TEXT && !!value
            },
            className.input
          )}
          value={value}
          {...rest}
          disabled={rest.disabled}
          data-testid={testId}
        />
      </label>
      {error && <Text className={s.errorText}>{error}</Text>}
    </div>
  );
};
