import { useSelector } from "react-redux";

import { useClientDataOptions } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import {
  clientTypeQueryParamSelector,
  queryParamsSelector
} from "store/selectors/routerSelectors";
import { CLIENT_TYPES } from "utils";
import { QP } from "utils/defaultQueryParams";

export const useSummaryClientTabs = () => {
  const params = useSelector(queryParamsSelector);
  const options = useClientDataOptions().filter(
    option => option.value !== "both"
  );

  if (!options.length) {
    return { active: "", tabs: [] };
  }

  const zappkaSummaryTypeQP = String(params[QP.ZAPPKA_SUMMARY_TYPE] || "");
  const hasOption = options.some(
    option => option.value.toLowerCase() === zappkaSummaryTypeQP
  );
  const active = hasOption ? zappkaSummaryTypeQP : options[0].value;

  return {
    active: active.toUpperCase(),
    tabs: options.map(option => ({
      label: option.display,
      value: option.value.toUpperCase(),
      ga: { "data-ga-summary-tab": option.display }
    }))
  };
};

export const useSummaryDataTypeSuffix = () => {
  const clientQP = useSelector(clientTypeQueryParamSelector);
  const { active } = useSummaryClientTabs();

  if (!active) {
    if (clientQP === CLIENT_TYPES.OFFLINE) return "Offline";
    if (clientQP === CLIENT_TYPES.ONLINE) return "Online";
    return "";
  }

  if (active === "OFFLINE") return "Offline";
  if (active === "ONLINE") return "Online";
  return "";
};
