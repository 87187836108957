import { useSelector } from "react-redux";

import { EMPTY_CHART } from "api/consts";
import { useGetLogistics } from "api/logistics/hooks/useGetLogistics";
import { useIsLogisticsLoading } from "api/logistics/mappers/useIsLogisticsLoading";
import { sortBarChartData } from "api/utils";
import { useChartLabels } from "api/utils/useChartLabels";
import { flatten, omit } from "ramda";

import { ChartData } from "components/D3/types";
import { useBarChartSorting } from "hooks/useBarChartSorting";
import { createLegendForLogistics } from "pages/Reports/partials/Chart/Legend/utils/createLegendForLogistics";
import {
  hiddenLinesByReportSelector,
  updateHoveredTimepoint,
  updatePinnedTimepoint
} from "pages/Reports/redux/reducers/chartReducer";
import {
  getStringifiedSelectedVendorsIds,
  VendorStoreInstance
} from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { findDateDomain } from "pages/Reports/sections/utils/chartData";
import { getChartItemColor } from "pages/Reports/sections/utils/color";
import {
  chartRange,
  valueByDataType,
  valueByDataTypeBoolean
} from "pages/Reports/utils";
import { userCompanySelector } from "store/reducers/userReducer";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { PERIOD_TYPE } from "utils";

export const useLogisticsChartData = (): ChartData[] => {
  const logistics = useGetLogistics();
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const hiddenLines = useSelector(hiddenLinesByReportSelector);
  const isFetching = useIsLogisticsLoading();
  const userCompany = useSelector(userCompanySelector);
  const isMultiChoice = useSelector(isCompaniesDropdownMultiChoiceSelector);
  const labels = useChartLabels();
  const barChartSorting = useBarChartSorting();

  const vendorStore = VendorStoreInstance.storeState.getState();
  const stringifiedSelectedCompany = getStringifiedSelectedVendorsIds(
    vendorStore,
    isMultiChoice
  );

  const dataTypes = [firstChartType, secondChartType];

  if (!logistics.data?.length || dataTypes.length === 0) {
    return [EMPTY_CHART];
  }

  const timeline = logistics.data[0]?.timeline || [];
  const domain = findDateDomain(timeline);
  const showBarChart = domain.length === 1;
  const canSortBarChart =
    showBarChart &&
    barChartSorting.dataKey &&
    dataTypes.includes(barChartSorting.dataType);

  // Sorting is important. By name and type and then by existing metrics, so existing are first in array and color is not wasted on non existing metrics
  const sorted = logistics.data
    .sort((a, b) => a.name.toString().localeCompare(b.name))
    .sort(
      (a, b) =>
        Number(valueByDataType(dataTypes[0], b.existingMetrics)) -
        Number(valueByDataType(dataTypes[0], a.existingMetrics))
    );

  const chartData = dataTypes.map(dataType => {
    const dataWithColor = sorted.map((data, index) => ({
      ...data,
      color: getChartItemColor({
        dataType,
        vendorId: data.vendorId,
        resultId: data.name,
        index
      })
    }));

    if (canSortBarChart) {
      dataWithColor.sort(
        sortBarChartData(barChartSorting.dataKey, barChartSorting.isDesc)
      );
    }

    const allLines = dataWithColor.map(logistics => ({
      ...omit(["timeline"], logistics),
      dataType: dataType,
      timeline: logistics.timeline.map(data => ({
        valueX: data.date,
        valueY: valueByDataType(dataType, data)
      }))
    }));

    const visibleLines = allLines
      .filter(timeline => !hiddenLines.includes(timeline.name))
      .filter(timeline =>
        valueByDataTypeBoolean(dataType, timeline.existingMetrics, {
          base: true,
          historical: false,
          both: false
        })
      );

    return {
      dataType,
      visibleLines,
      allLines,
      ...chartRange(visibleLines)
    };
  });

  const legendData = chartData.length >= 2 ? [chartData[0]] : chartData;

  const logisticsLegend = createLegendForLogistics(
    legendData.map(res => ({
      dataType: res.dataType,
      chartData: res.allLines.map(chart => ({
        ...chart,
        label: chart.name
      }))
    })),
    hiddenLines,
    userCompany?.name || stringifiedSelectedCompany || ""
  );

  const filteredData = chartData.map(chart => ({
    dataType: chart.dataType,
    minValue: chart.minValue,
    maxValue: chart.maxValue,
    chart: chart.visibleLines.map(chart => ({
      dataType: chart.dataType,
      id: chart.name,
      label: chart.name,
      color: chart.color,
      timeline: chart.timeline
    }))
  }));

  const groupedData = {
    leftChart: [filteredData[0]],
    rightChart: [filteredData[1]]
  };

  const leftChart = {
    maxValue: Math.max(
      ...(groupedData?.leftChart?.map(data => data.maxValue) || [1])
    ),
    minValue: 0,
    chart: flatten(groupedData?.leftChart?.map(data => data.chart) || []),
    dataType: firstChartType
  };

  const rightChart = groupedData.rightChart
    ? {
        maxValue: Math.max(
          ...(groupedData.rightChart.map(data => data.maxValue) || 1)
        ),
        minValue: 0,
        chart: flatten(groupedData.rightChart.map(data => data.chart) || []),
        dataType: secondChartType
      }
    : null;

  return [
    {
      leftChart: leftChart || null,
      rightChart,
      period: PERIOD_TYPE.DAYS,
      domain,
      legend: logisticsLegend,
      labels,
      isFetching,
      showBarChart: domain?.length === 1,
      onTimelineHover: updateHoveredTimepoint,
      onTimelineClick: updatePinnedTimepoint,
      isHistoricalGroupedChart: false
    }
  ];
};
