import { useSelector } from "react-redux";

import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { EXPORT_TYPES } from "pages/Reports/utils/constants";
import {
  chartPeriodSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import {
  DEFAULT_DATA_EXPORT_DROPDOWN_HEADER,
  isThisPage,
  LOGISTICS_DATA_EXPORT_DROPDOWN_HEADER,
  PERIOD_TYPE
} from "utils";
import { Values } from "utils/types";

interface Settings {
  downloadHeader: string;
  downloadFormats: Values<typeof EXPORT_TYPES>[];
  prefixes: string[];
}

const {
  LOCATION_PATH,
  SEGMENTS_PATH,
  HOURS_PATH,
  LOGISTICS_PATH
} = REPORTS_FULL_PATHS;

export const useSettings = (): Settings => {
  const pathname = useSelector(pathnameSelector);
  const period = useSelector(chartPeriodSelector);

  const base = {
    downloadHeader: DEFAULT_DATA_EXPORT_DROPDOWN_HEADER,
    downloadFormats: [EXPORT_TYPES.CSV, EXPORT_TYPES.EXCEL],
    prefixes: ["Pokaż", "i"]
  };

  if (
    isThisPage(pathname, LOCATION_PATH) &&
    period.toUpperCase() === PERIOD_TYPE.TOTAL
  ) {
    return {
      ...base,
      prefixes: ["Mapa:", "Panel boczny:"]
    };
  }

  if ([SEGMENTS_PATH, HOURS_PATH].some(url => isThisPage(pathname, url))) {
    return {
      ...base,
      downloadFormats: [EXPORT_TYPES.CSV]
    };
  }

  if (isThisPage(pathname, LOGISTICS_PATH)) {
    return {
      ...base,
      downloadHeader: LOGISTICS_DATA_EXPORT_DROPDOWN_HEADER,
      downloadFormats: [
        EXPORT_TYPES.LOGISTICS_SINGLE_DAY,
        EXPORT_TYPES.LOGITICS_SELECTED_PERIOD
      ]
    };
  }

  return base;
};
