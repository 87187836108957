import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps as Props } from "react-router-dom";

import { Agreement } from "pages/Agreement/Agreement";
import {
  isAgreementAcceptedSelector,
  isLoggedInSelector
} from "store/reducers/userReducer";
import { pathSelector } from "store/selectors/routerSelectors";
import { APP_PATHS, setLoginRedirectLocalStorage } from "utils";

import { Loader } from "../";

export const PrivateRoute = ({ children, ...props }: Props) => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const routerPath = useSelector(pathSelector);
  const isAgreementAccepted = useSelector(isAgreementAcceptedSelector);

  React.useEffect(() => {
    if (!isLoggedIn) {
      setLoginRedirectLocalStorage(routerPath);
    }
  }, [isLoggedIn, routerPath]);

  if (isLoggedIn && isAgreementAccepted) {
    return <Route {...props}>{children}</Route>;
  }

  return (
    <Route {...props}>
      <React.Suspense fallback={<Loader />}>
        {isLoggedIn && !isAgreementAccepted ? (
          <Agreement />
        ) : (
          <Redirect to={APP_PATHS.LOGIN} />
        )}
      </React.Suspense>
    </Route>
  );
};
