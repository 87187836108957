import { useFiltersQP } from "hooks";
import {
  BOOL_STRING_VALUES,
  CLIENT_TYPES,
  FILTER_SECTIONS
} from "utils/constants";

const {
  MAIN,
  COMPETITION,
  REFERENCE_CATEGORIES,
  REFERENCE_BRANDS,
  REGION,
  SHOP_TYPE,
  CLIENT_TYPE,
  LFL,
  EXPORT_DATA_TYPE
} = FILTER_SECTIONS;

const hasNonEmptyString = (values: string[]) => {
  return values.some(value => value.length > 0);
};

export const useIntitialFiltersSectionOpen = (sectionKey: string): boolean => {
  const {
    refCat1QP,
    refCat2QP,
    refCat3QP,
    refBrandQP,
    refSubBrandQP,
    shopTypeQP,
    shopTypeMetaQP,
    voivodeshipsQP,
    countiesQP,
    competitionCompaniesQP,
    competitionProductsQP,
    competitorBrandQP,
    competitorSubBrandQP,
    clientTypesQP,
    lflQP,
    exportDataTypeQP
  } = useFiltersQP();

  switch (sectionKey) {
    case MAIN.key:
      return true;
    case COMPETITION.key:
      return hasNonEmptyString([
        competitionCompaniesQP,
        competitionProductsQP,
        competitorBrandQP,
        competitorSubBrandQP
      ]);
    case REFERENCE_CATEGORIES.key:
      return hasNonEmptyString([refCat1QP, refCat2QP, refCat3QP]);
    case REFERENCE_BRANDS.key:
      return hasNonEmptyString([refBrandQP, refSubBrandQP]);
    case REGION.key:
      return hasNonEmptyString([voivodeshipsQP, countiesQP]);
    case SHOP_TYPE.key:
      return hasNonEmptyString([shopTypeQP, shopTypeMetaQP]);
    case CLIENT_TYPE.key:
      return clientTypesQP.length > 0 && clientTypesQP !== CLIENT_TYPES.ALL;
    case LFL.key:
      return lflQP === BOOL_STRING_VALUES.TRUE;
    case EXPORT_DATA_TYPE.key:
      return exportDataTypeQP.length > 0;
    default:
      return false;
  }
};
