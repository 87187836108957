import { FC } from "react";
import { useSelector } from "react-redux";

import cn from "classnames";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";

import { Button, Text } from "components/atoms";
import { Dot } from "components/atoms/Dot/Dot";
import c from "pages/Reports/partials/Chart/Legend/common.module.scss";
import s from "pages/Reports/partials/Chart/Legend/LegendItems/legendItems.module.scss";
import { REPORTS_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";

const TEST_IDS = {
  LEGEND_ITEM: "legend-item"
};

export type LegendItem = {
  text: string;
  id: string;
  color: string;
  isLineHidden?: boolean;
};

type LegendItemsProps = {
  items: LegendItem[];
  onClick: (labels: string[]) => void;
  onMouse: (labels: string[]) => void;
  hoveredLine: string[];
  isDisabled: boolean;
  itemsTitle: string;
};

export const LegendItems: FC<LegendItemsProps> = ({
  itemsTitle,
  isDisabled,
  items,
  onClick,
  onMouse,
  hoveredLine
}) => {
  const pathname = useSelector(pathnameSelector);
  const isSegmentsPage = pathname.includes(REPORTS_PATHS.SEGMENTS);

  // Debounce is required because mouse events may not be fired when lots of lines are shown and hidden in a short time period
  const [onMouseEnter] = useDebouncedCallback((item: LegendItem) => {
    onMouse([item.id]);
  }, 50);

  const [onMouseLeave] = useDebouncedCallback(() => {
    onMouse([]);
  }, 50);

  if (isDisabled) {
    return (
      <p>
        <Button type="button" disabled={isDisabled} className={c.button}>
          <Text className={s.label}>
            {items.map(item => item.text).join(", ")}
          </Text>
        </Button>
      </p>
    );
  }

  // onMouseLeave is not on the button because we don't want chart lines to blink
  return (
    <div onMouseLeave={onMouseLeave}>
      {items.map(item => (
        <div
          key={item.text}
          className={s.item}
          style={{
            width: isSegmentsPage ? "100%" : "auto" // classNames do not override each other
          }}
        >
          <>
            <Dot style={{ backgroundColor: item.color }} />
            <Button
              type="button"
              className={cn(c.button, {
                [s.grey]: item.isLineHidden,
                [c.buttonHovered]: hoveredLine.includes(item.id)
              })}
              onClick={() => onClick([item.id])}
              onMouseEnter={() => onMouseEnter(item)}
              data-ga-graph-legend-line={item.text}
              data-testid={`${TEST_IDS.LEGEND_ITEM}-${item.text}`}
              data-active={!item.isLineHidden}
            >
              <Text className={cn(s.label)}>
                <span
                  style={{
                    color: hoveredLine.includes(`${itemsTitle} - ${item.text}`)
                      ? item.color
                      : undefined
                  }}
                >
                  {item.text}
                </span>
              </Text>
            </Button>
          </>
        </div>
      ))}
    </div>
  );
};
