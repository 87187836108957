import { areIntervalsOverlapping, isWithinInterval } from "date-fns";

import { PromotionDataType } from "pages/Reports/redux/reducers/promotionsReducer";
import { weekStartEndDates } from "pages/Reports/utils";
import { newDateByTimezone } from "store/utils";
import { PERIOD_TYPE } from "utils";
import { PROMO_WEEK_START_DAY } from "utils/constants";
import { Periods } from "utils/types";

export const checkPromotionOccurence = (
  selectedDay: Date, // in case of year/month/week it is a first day of whole period
  promotions: PromotionDataType[],
  period: Periods = PERIOD_TYPE.DAYS
) => {
  const selectedDate = newDateByTimezone(selectedDay);

  switch (period) {
    case PERIOD_TYPE.RANKING:
      return promotions;
    case PERIOD_TYPE.DAYS:
      const promotionsInSelectedDay = promotions.filter(promotion => {
        if ((promotion?.datesDifference || 0) >= 1) {
          return isWithinInterval(
            selectedDate,
            {
              start: newDateByTimezone(promotion.validFrom),
              end: newDateByTimezone(promotion.validTo)
            } || []
          );
        }

        return false;
      });

      return promotionsInSelectedDay;

    case PERIOD_TYPE.PROMO_WEEKS:
      const promotionsInSelectedPromoWeek = promotions.filter(promotion => {
        if ((promotion?.datesDifference || 0) >= 1) {
          const [startOfPromoWeek, endOfPromoWeek] = weekStartEndDates(
            newDateByTimezone(selectedDate),
            PROMO_WEEK_START_DAY
          );

          const areOverlapping = areIntervalsOverlapping(
            {
              start: newDateByTimezone(startOfPromoWeek),
              end: newDateByTimezone(endOfPromoWeek)
            },
            {
              start: newDateByTimezone(promotion.validFrom),
              end: newDateByTimezone(promotion.validTo)
            },
            { inclusive: true }
          );

          return areOverlapping;
        }

        return false;
      });
      return promotionsInSelectedPromoWeek;

    default:
      return [];
  }
};
