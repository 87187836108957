import * as React from "react";

import cn from "classnames";

import s from "./index.module.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const CardHeader = ({ className, children }: Props) => (
  <header className={cn(s.base, className)}>{children}</header>
);
