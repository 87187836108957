import { groupBy } from "ramda";

import {
  ChartInfo,
  CreateLegend
} from "pages/Reports/partials/Chart/Legend/hooks/useLegend";
import { isProductWithdrawn } from "utils";

const TABS = [
  { label: "Produkty aktywne", showActive: true },
  { label: "Produkty nieaktywne", showActive: false }
];

export const createLegendForLogistics = (
  legendData: CreateLegend[],
  hiddenLines: string[] = [],
  userCompany: string = ""
) => {
  const productsPerCompany = groupBy(
    (product: ChartInfo) => product.vendorId || ""
  )(legendData[0].chartData);

  const vendorSections = Object.keys(productsPerCompany).map(vendorName => {
    return productsPerCompany[vendorName];
  });

  const tabs = TABS.map(tab => ({
    label: tab.label,
    sections: vendorSections
      .map(vendorSection => ({
        label: vendorSection[0].vendorId || userCompany,
        items:
          vendorSection
            .map(line => ({
              text: line.label,
              id: line.label,
              color: line.color,
              isLineHidden: hiddenLines.includes(line.label)
            }))
            .filter(line =>
              tab.showActive
                ? !isProductWithdrawn(line.text)
                : isProductWithdrawn(line.text)
            ) || []
      }))
      .filter(section => section.items.length)
  }));

  const filteredEmptyTabs = tabs.filter(tab =>
    tab.sections.find(section => section.items.length)
  );

  return filteredEmptyTabs;
};
