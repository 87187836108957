import { useEffect, useRef } from "react";

import cn from "classnames";
import { axisBottom, ScaleLinear, select } from "d3";

import c from "pages/Reports/partials/Chart/common.module.scss";
import { round } from "utils/round";
import { Nullable } from "utils/types";

interface Props {
  transform: string;
  scaleX: ScaleLinear<number, number, never>;
  height: number;
}

export const BottomAxis = ({ transform, scaleX, height }: Props) => {
  const axisBottomRef = useRef<Nullable<SVGGElement>>(null);

  useEffect(() => {
    if (!axisBottomRef.current) return;

    select(axisBottomRef.current)
      .call(
        axisBottom(scaleX)
          .tickSize(-height)
          .tickFormat(value => round(Number(value)))
      )
      .selectAll("g > .domain")
      .remove();
  }, [height, scaleX]);

  return (
    <g
      ref={axisBottomRef}
      transform={transform}
      className={cn(c.grid, c.text)}
      data-testid="ranking-bottom-index-axis"
    />
  );
};
