import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const Users = ({
  className,
  color = "#6D89B6",
  size = ICON_SIZES.LARGE
}: Props) => {
  const currentSize = SIZES[size];
  return (
    <svg
      className={className}
      width={currentSize}
      height={currentSize}
      viewBox="0 0 20 20"
    >
      <g fill={color}>
        <path d="M19.14 13.268l-2.59-.72a1.164 1.164 0 01-.836-.883l-.159-.777c1.264-.56 2.075-1.786 2.077-3.135V6.172c.03-1.902-1.489-3.489-3.444-3.597-1.435-.042-2.756.76-3.346 2.032.565.8.868 1.746.869 2.716v1.58a4.435 4.435 0 01-.183 1.237c.307.313.672.566 1.075.748l-.16.777a1.164 1.164 0 01-.835.882l-1 .277 1.753.487c1.016.285 1.715 1.187 1.718 2.213v2.009a1.663 1.663 0 01-.109.575h5.438a.584.584 0 00.592-.575v-3.16c0-.513-.35-.964-.86-1.105z" />
        <path d="M10.983 14.418l-2.59-.72a1.164 1.164 0 01-.837-.883l-.159-.777c1.264-.56 2.075-1.785 2.077-3.135v-1.58c.03-1.902-1.489-3.49-3.443-3.597a3.61 3.61 0 00-2.584.972 3.404 3.404 0 00-1.079 2.48v1.725c.002 1.35.813 2.576 2.077 3.137l-.16.775a1.164 1.164 0 01-.835.882l-2.59.721c-.51.141-.86.593-.86 1.106v2.009c0 .317.265.575.592.575H11.25a.584.584 0 00.592-.575v-2.009c0-.513-.35-.965-.859-1.106z" />
      </g>
    </svg>
  );
};
