import { useSelector } from "react-redux";

import { isVoivodeshipPage } from "pages/Reports/redux/selectors/dataTypeWarningsSelectors";
import { isDistributionDisabledSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage } from "utils";

const { LOGISTICS_PATH } = REPORTS_FULL_PATHS;

export const useIsDistributionDataDisabled = () => {
  const pathname = useSelector(pathnameSelector);
  const isDistributionDisabled = useSelector(isDistributionDisabledSelector);

  const isLogisticsPage = isThisPage(pathname, LOGISTICS_PATH);

  if (isLogisticsPage) return false;

  return isVoivodeshipPage(pathname) || isDistributionDisabled;
};
