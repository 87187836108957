import { SVGProps } from "react";

export function IconLineChart(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="m4.67 28l6.39-12l7.3 6.49a2 2 0 0 0 1.7.47a2 2 0 0 0 1.42-1.07L27 10.9l-1.82-.9l-5.49 11l-7.3-6.49a2 2 0 0 0-1.68-.51a2 2 0 0 0-1.42 1L4 25V2H2v26a2 2 0 0 0 2 2h26v-2Z"
      />
    </svg>
  );
}

export function IconAreaChart(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M28 2v5.722l-7.532 6.952l-8.086-8.086a1.997 1.997 0 0 0-2.731-.09L4 11.442V2H2v26c0 1.103.897 2 2 2h24c1.103 0 2-.897 2-2V2zm0 8.444v11.762l-6.116-6.116zM10.968 8.002l8.03 8.03L15.783 19l-3.414-3.415a2 2 0 0 0-2.732-.09L4 20.425V14.1zM4 28v-4.916l6.955-6.085l3.414 3.414a1.992 1.992 0 0 0 2.77.056l3.274-3.022L28 25.034V28z"
      />
    </svg>
  );
}

export function IconGroupedBarChart(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M27 28V16h-8v12h-4V12H7v16H4V2H2v26a2 2 0 0 0 2 2h26v-2ZM13 14v6H9v-6Zm12 4v6h-4v-6Z"
      />
      <path
        fill="currentColor"
        d="M22.786 14a1.988 1.988 0 0 1-1.18-.386L11.205 5.987L8.242 10L6.637 8.806l2.982-4a1.998 1.998 0 0 1 2.749-.446L22.789 12l3.605-4.86L28 8.33l-3.604 4.862a2.001 2.001 0 0 1-1.61.808"
      />
    </svg>
  );
}

export function IconIncrementalChart(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M28 28V6h-8v22h-4V14H8v14H4V2H2v26a2 2 0 0 0 2 2h26v-2ZM22 8h4v10h-4Zm-12 8h4v6h-4Z"
      />
    </svg>
  );
}

export function IconComboChart(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M29 8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v7h-2V3a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v12h-2V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v10H3v12a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V17h2v5a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-5h2v12a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V17h4zM7 26H5v-9h2zm8-5h-2v-4h2zm2-6V4h2v11zm10 0h-2V9h2z"
      />
    </svg>
  );
}
