import {
  updateHoveredTimepoint,
  updatePinnedTimepoint
} from "pages/Reports/redux/reducers/chartReducer";
import { PERIOD_TYPE } from "utils";

export const QueryKey = {
  LOCATION_DATA: "LOCATION_DATA",
  DYNAMICS_DATA: "DYNAMICS_DATA",
  SHARES_DATA: "SHARES_DATA",
  RANKING_DATA: "RANKING_DATA",
  RECEIPTS_DATA: "RECEIPTS_DATA",
  SELLS_DATA: "SELLS_DATA",
  LOYALTY_DATA: "LOYALTY_DATA",
  SEGMENTS_DATA: "SEGMENTS_DATA",
  LOGISTICS_DATA: "LOGISTICS_DATA",
  HOURS_DATA: "HOURS_DATA",
  MULTIPACK_DATA: "MULTIPACK_DATA"
};

export const EMPTY_CHART = {
  period: PERIOD_TYPE.DAYS,
  domain: [],
  legend: [],
  labels: {
    left: "",
    right: "",
    bottom: ""
  },
  id: "",
  showBarChart: false,
  isFetching: false,
  leftChart: null,
  rightChart: null,
  onTimelineHover: updateHoveredTimepoint,
  onTimelineClick: updatePinnedTimepoint,
  isHistoricalGroupedChart: false
};
