import React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const TooltipIcon = ({
  className,
  color = "#6D89B6",
  size = ICON_SIZES.LARGE
}: Props) => {
  const currentSize = SIZES[size];
  return (
    <svg
      width={currentSize}
      height={currentSize}
      className={className}
      viewBox={`0 0 ${currentSize} ${currentSize}`}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="10" cy="10" r="9.5" stroke="#43B649" />
        <g fill="#43B649" fillRule="nonzero" transform="translate(8.75 6.25)">
          <path d="M.506 3.125h1.489V7.5H.506z" />
          <circle cx="1.25" cy=".938" r="1" />
        </g>
      </g>
    </svg>
  );
};
