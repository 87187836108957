import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { QP } from "utils/defaultQueryParams";

// filter names that should be included in requests and their corresponding query params
export const filtersParamsMap = {
  [FILTER_NAMES.CATEGORY_1]: QP.CATEGORY1,
  [FILTER_NAMES.CATEGORY_2]: QP.CATEGORY2,
  [FILTER_NAMES.CATEGORY_3]: QP.CATEGORY3,
  [FILTER_NAMES.ATTRIBUTES]: QP.ATTRIBUTES,
  [FILTER_NAMES.COMPANY]: QP.VENDOR_ID,
  [FILTER_NAMES.DELAY]: QP.DELAY,
  [FILTER_NAMES.COMPETITOR_COMPANY]: QP.COMPETING_COMPANIES,
  [FILTER_NAMES.COMPETITOR_PRODUCT]: QP.COMPETING_MATERIALS,
  [FILTER_NAMES.PRODUCT]: QP.PRODUCT,
  [FILTER_NAMES.BRAND]: QP.BRANDS,
  [FILTER_NAMES.SUB_BRAND]: QP.SUB_BRANDS,
  [FILTER_NAMES.COMPETITOR_BRAND]: QP.COMPETING_BRANDS,
  [FILTER_NAMES.COMPETITOR_SUB_BRAND]: QP.COMPETING_SUB_BRANDS,
  [FILTER_NAMES.REFERENCE_BRAND]: [QP.REF_BRAND, QP.REF_BRAND_AGGREGATED],
  [FILTER_NAMES.REFERENCE_SUB_BRAND]: QP.REF_SUBBRAND,
  [FILTER_NAMES.VOIVODESHIP]: QP.VOIVODESHIPS,
  [FILTER_NAMES.COUNTY]: QP.COUNTIES,
  [FILTER_NAMES.STORE_TYPE_META]: QP.STORE_TYPES_META,
  [FILTER_NAMES.STORE_TYPE]: QP.STORE_TYPES,
  [FILTER_NAMES.LFL]: QP.LFL,
  [FILTER_NAMES.AGGREGATION]: QP.AGGREGATED,
  [FILTER_NAMES.COMPETITOR_AGGREGATION]: QP.AGGREGATED_COMPETITION,
  [FILTER_NAMES.DATE]: [QP.DATE_FROM, QP.DATE_TO],
  [FILTER_NAMES.REFERENCE_CATEGORY]: [QP.REF_CAT1, QP.REF_CAT2, QP.REF_CAT3],
  [FILTER_NAMES.REFERENCE_CATEGORY_AGGREGATION]: QP.REF_CAT_AGGREGATED,
  [FILTER_NAMES.PROMOTIONS]: QP.PROMOTIONS
} as { [key: string]: string | string[] };
