import {
  useGetSegmentsByPeriod,
  useSegmentsGeneralWarnings
} from "api/segments/mappers";

export const useSegmentsWarnings = (): string[] => {
  const generalWarnings = useSegmentsGeneralWarnings();
  const info = useGetSegmentsByPeriod().data?.info || [];

  return [...generalWarnings, ...info].filter(warning => !!warning);
};
