import { appendInsightId } from "pages/Insights/partials/InsightsBox/utils";
import {
  SegmentsApi,
  SegmentsDataByProductApi,
  SegmentsDataBySegmentApi,
  SegmentsDataset
} from "pages/Reports/sections/Segments/types";
import { createDatasetObject } from "pages/Reports/utils/createDatasetObject";
import {
  normalizeSegmentsResponseByProduct,
  normalizeSegmentsResponseBySegment
} from "pages/Reports/utils/normalize/normalizeSegmentsResponse";
import { BOOL_STRING_VALUES, HTTP, REST_API_ENDPOINTS } from "utils";
import { QP } from "utils/defaultQueryParams";
import { pushReportFetchEvent } from "utils/googleTagManager/dataLayer";
import { Periods } from "utils/types";

const fieldsToFetch =
  "&selected_fields=worth_sum,quantity_sum,worth_sum_per_shop,quantity_sum_per_shop,avg_shops_count,distribution_range,net_quantity_sales,avg_net_quantity_per_shop";

export const segmentsServices = {
  fetchSegments: async (queryString: string, period: Periods) => {
    const url = REST_API_ENDPOINTS.REPORTS.SEGMENTS;
    const urlWithQueries = `${url}?${queryString}`;
    const fullUrl = appendInsightId(urlWithQueries + fieldsToFetch);

    const searchParams = new URLSearchParams(queryString);
    const isGroupedBySegments =
      searchParams.get(QP.GROUP_BY_SEGMENTS) === BOOL_STRING_VALUES.TRUE;

    const { data } = await HTTP.get<SegmentsApi>(fullUrl);
    const normalized = isGroupedBySegments
      ? normalizeSegmentsResponseBySegment(
          data.data as SegmentsDataBySegmentApi[]
        )
      : normalizeSegmentsResponseByProduct(
          data.data as SegmentsDataByProductApi[]
        );
    const standardizedData: SegmentsDataset = createDatasetObject({
      query: queryString,
      currentPeriod: normalized,
      ownSummary: {},
      periodType: period,
      info: data.info || []
    });

    pushReportFetchEvent("Segmenty");

    return standardizedData;
  }
};
