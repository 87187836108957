import React from "react";
import { useSelector } from "react-redux";

import cn from "classnames";

import { Checkbox } from "components/molecules";
import s from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/LFLChoiceGroup/lflChoiceGroup.module.scss";
import { useAreTopFiltersSelected } from "pages/Reports/redux/reducers/filters/filtersReducer";
import { DelayHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useDelay";
import { delayCompetitionQueryParamSelector } from "store/selectors/routerSelectors";

type Props = { isDisabled: boolean };

export const DelayCompetition = ({ isDisabled }: Props) => {
  const areFiltersActive = useAreTopFiltersSelected();
  const delayQP = useSelector(delayCompetitionQueryParamSelector);
  const [state, actions] = DelayHooks.useDelay();

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    actions.updateDelay(e.currentTarget.checked);
  };

  React.useEffect(() => {
    actions.getInitialDelayState(delayQP);
  }, [actions, delayQP]);

  return (
    <div className={s.base}>
      <Checkbox
        className={{
          base: s.checkbox,
          label: cn(s.checkboxLabel, {
            [s.checkboxLabelChecked]: state.delay
          })
        }}
        label="Pokaż dane konkurencji z opóźnieniem"
        isChecked={state.delay}
        onChange={handleChange}
        isDisabled={!areFiltersActive || isDisabled}
        testId="delay-checkbox"
      />
    </div>
  );
};
