import * as React from "react";
import { Manager, Reference } from "react-popper";

type ComponentForwardProps = {
  id: string;
  className?: string;
  onClick?: (e: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
  fill: string;
};

type BaseComponentProps = ComponentForwardProps & {
  ref: React.Ref<any>;
  onMouseEnter: (e: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
  onMouseLeave: (e: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
};
type Props<T> = {
  Component:
    | React.ForwardRefRenderFunction<T, BaseComponentProps>
    | React.ForwardRefExoticComponent<BaseComponentProps>;
  onComponentHover?: (e: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
  onComponentBlur?: () => void;
  isDisabled?: boolean;
  componentProps: ComponentForwardProps;
};

export const TooltipWrapper = <T extends {}>({
  Component,
  onComponentHover = () => {},
  onComponentBlur = () => {},

  isDisabled,
  componentProps
}: Props<T>) => {
  const handleComponentHover = (
    e: React.MouseEvent<SVGPathElement, MouseEvent>
  ) => {
    if (isDisabled) {
      return;
    }

    onComponentHover(e);
  };

  const handleComponentBlur = () => {
    if (isDisabled) {
      return;
    }
    onComponentBlur();
  };

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <Component
            ref={ref}
            onMouseEnter={handleComponentHover}
            onMouseLeave={handleComponentBlur}
            {...componentProps}
          />
        )}
      </Reference>
    </Manager>
  );
};
