import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import { QueryKey } from "api/consts";
import { hoursServices } from "api/hours/services";
import { useFetchParams } from "api/utils";

import { REPORTS_PATHS } from "pages/Reports/utils";
import { isThisPage } from "utils";

export const useGetHours = () => {
  const { queryKeys, isG1Selected } = useFetchParams();

  const queryString = queryKeys[0]?.query || ""; // there's only one query for hours

  const { pathname } = useLocation();
  const isHoursPage = isThisPage(pathname, REPORTS_PATHS.HOURS);

  const queryResponse = useQuery(
    [QueryKey.HOURS_DATA, queryString],
    () => hoursServices.fetchHours(queryString),
    {
      enabled: isG1Selected && isHoursPage,
      notifyOnChangeProps: "tracked"
    }
  );

  return queryResponse;
};
