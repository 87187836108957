import * as React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";

import cn from "classnames";

import s from "./Link.module.scss";

type ExpectedElement = React.JSXElementConstructor<any>;

interface Props<P, T extends ExpectedElement>
  extends Omit<LinkProps, "href" | "to"> {
  children: React.ReactElement<P & { className: string }, T>;
  href: LinkProps["to"];
  isActive?: boolean;
  className?: string;
  activeClassName?: string;
}

export const Link = <P, T extends ExpectedElement>({
  children,
  href,
  isActive,
  className,
  activeClassName,
  ...linkProps
}: Props<P, T>) => (
  <RouterLink
    {...linkProps}
    to={href}
    className={cn(s.base, className, { [s.active]: isActive })}
  >
    {React.cloneElement<P>(children, {
      ...children.props,
      className: cn(
        children.props.className,
        activeClassName && isActive && activeClassName
      )
    })}
  </RouterLink>
);
