import { useGetLocationByPeriod } from "api/location/mappers/useGetLocationByPeriod";
import { useLocationMapChartScale } from "api/location/mappers/useLocationMapChartScale";
import { scaleThreshold } from "d3";

import { DEFAULT_COLOR } from "components/D3/Voivodeship/constants";
import { Nullable } from "utils/types";

export const useGetScaledColorFn = () => {
  const {
    isLoading: isLocationLoading,
    isIdle: isLocationIdle
  } = useGetLocationByPeriod();
  const isLoading = isLocationLoading || isLocationIdle;
  const { domain, range } = useLocationMapChartScale();

  const scale = scaleThreshold<number, string>()
    .domain(domain)
    .range(range);

  return (datum: Nullable<number>): string =>
    datum === null || isLoading ? DEFAULT_COLOR : scale(datum);
};
