import * as React from "react";
import { useSelector } from "react-redux";

import cn from "classnames";
import { StateChangeOptions } from "downshift";

import { Text } from "components/atoms";
import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import { ConfirmModal } from "components/organisms";
import { CategoryItem } from "pages/Reports/redux/reducers/filters/categoryFilters/categoryFiltersActions";
import { useAreTopFiltersSelected } from "pages/Reports/redux/reducers/filters/filtersReducer";
import { useCategoryBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useCategory";
import { CategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import { userCompanySelector } from "store/reducers/userReducer";
import { isParentCategorySelected } from "store/utils/filtersUtils";
import { UNLOCKED_CATEGORIES_COMPANIES } from "utils";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

import { filterItems, isActiveFn } from "../utils";
import s from "./categoryDropdown.module.scss";

type Props = {
  level: 1 | 2 | 3;
  promptOnChange?: boolean;
  promptOnClear?: boolean;
  label?: string;
  className?: string;
};

export const CategoryDropdown = ({
  label = "",
  level,
  promptOnChange,
  className = "",
  promptOnClear
}: Props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  // mutable object passed to confirm modal while it is displayed
  const itemToSelectRef = React.useRef<CategoryItem>(
    null
  ) as React.MutableRefObject<CategoryItem | null>;

  const [isOpen, setOpen] = React.useState(false);
  const [isChangePrompted, promptChange] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  // workaround requested by client to give access to G3 category without selecting G2 for bacardi company
  const currentCompanyId = useSelector(userCompanySelector)?.warehouseId || "0";
  const isCategoryChoiceUnlocked = UNLOCKED_CATEGORIES_COMPANIES.includes(
    currentCompanyId
  );

  const [, actions] = CategoryHooks.useCategory();
  const [topLevel] = CategoryHooks.useCategoriesTopLevel();
  const [categoryItems] = CategoryHooks.useAllCategories(level);
  const [selectedL1Items] = CategoryHooks.useSelectedCategories(1);
  const [selectedL2Items] = CategoryHooks.useSelectedCategories(2);
  const [selectedL3Items] = CategoryHooks.useSelectedCategories(3);

  const selectedCategoryItems =
    (level === 1 && selectedL1Items) ||
    (level === 2 && selectedL2Items) ||
    selectedL3Items;

  const areFiltersActive = useAreTopFiltersSelected();

  const handleInputValueChange = (value?: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    handleInputValueChange("");
  };

  const handleClose = (selectedItem?: CategoryItem | null) => {
    setOpen(false);

    if (!selectedItem) {
      actions.sortCategories(level);
    }
  };

  const handleStateChange = (changes: StateChangeOptions<CategoryItem[]>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handlePrompt = (item: CategoryItem | null) => {
    if (containerRef.current) {
      // clear item to select if user selected currently selected item
      itemToSelectRef.current = itemToSelectRef.current === item ? null : item;
    }

    promptChange(true);
  };

  const handleCancelModal = () => {
    promptChange(false);
    itemToSelectRef.current = null;
  };

  const handleConfirm = () => {
    actions.clearCategories();
    actions.selectCategory(itemToSelectRef.current, level, level === 1);
    handleClose(null);
    promptChange(false);
  };

  const willClear = (item: CategoryItem | null) => {
    if (item === null) return true;

    return (
      selectedCategoryItems.length === 1 &&
      selectedCategoryItems[0].value === item.value
    );
  };

  const checkItemSelection = (
    selectedItems: CategoryItem[],
    item: CategoryItem
  ) => selectedItems.some(({ value }) => value === item.value);

  const filteredCategoriesByParentAndSelectedCategories = categoryItems.filter(
    item =>
      isParentCategorySelected(level, item, selectedL1Items, selectedL2Items) ||
      checkItemSelection(selectedCategoryItems, item)
  );

  const handleChange = (item: CategoryItem | null) => {
    if (level !== 1) {
      setOpen(true);
    }

    if (promptOnChange && selectedCategoryItems.length !== 0) {
      return handlePrompt(item);
    }

    if (promptOnClear && willClear(item)) {
      return handlePrompt(null);
    }

    actions.selectCategory(item, level, level === 1);
  };

  const handleClear = () => {
    if (filteredCategoriesByParentAndSelectedCategories.length <= 1) {
      return;
    }
    pushFiltersEvent(`G${level} - clear`);
    return handleChange(null);
  };

  const filterCategoriesByInput = (inputValue: string) => (
    item: CategoryItem
  ) => {
    const isItemSelected = checkItemSelection(selectedCategoryItems, item);

    //always show selected item
    if (isItemSelected) {
      return true;
    }

    return filterItems<CategoryItem>(inputValue)(item);
  };

  const [
    selectedCategoriesLabel
  ] = CategoryHooks.useStringifiedSelectedCategories(level);

  const handleSelectAll = () => {
    actions.selectAllCategories(inputValue, level);
    pushFiltersEvent(`G${level} - select all`);
  };

  useCategoryBehaviour(level);

  React.useEffect(() => {
    if (!isOpen) setInputValue(selectedCategoriesLabel);
  }, [isOpen, selectedCategoriesLabel]);

  return (
    <div ref={containerRef}>
      <Dropdown<CategoryItem[]>
        className={cn(s.categoryDropdownWrapper, className)}
        isMultiChoice={level !== 1}
        isDisabled={
          level !== topLevel && !areFiltersActive && !isCategoryChoiceUnlocked
        }
        // @ts-ignore workaround: downshift calls onInputValueCahnge  with result of itemToString prop call.
        // once we know this fn always returns undefined it makes downshift fully controled and inputValue
        // depends only on state of CategoryDropdown
        itemToString={() => {}}
        label={label}
        inputValue={inputValue}
        onInputValueChange={handleInputValueChange}
        isOpen={isOpen}
        items={filteredCategoriesByParentAndSelectedCategories}
        selectedItem={selectedCategoryItems}
        // @ts-ignore
        onChange={handleChange}
        onClear={handleClear}
        onStateChange={handleStateChange}
        openedMenuPlaceholder="Wpisz nazwę kategorii"
        closedMenuPlaceholder={`Wybierz kategorię G${level}`}
        onSelectAll={handleSelectAll}
        input={
          <FilterDropdownInput
            isTypingEnabled
            automaticInputValue={selectedCategoriesLabel}
            required={level === 1}
            testId={`category-dropdown-level-${level}`}
          />
        }
        menu={
          <FilterDropdownMenu<CategoryItem>
            isItemActiveFn={isActiveFn}
            filterItemsFn={filterCategoriesByInput}
            placeholder={
              <Text className={s.placeholder}>pobieranie danych</Text>
            }
            listItem={<FilterDropdownListItem />}
          />
        }
      />
      {isChangePrompted && (
        <ConfirmModal
          onCancel={handleCancelModal}
          onConfirm={handleConfirm}
          ga={{
            confirm: {
              "data-ga-filter-option": "tak"
            },
            cancel: {
              "data-ga-filter-option": "nie"
            }
          }}
          position={{ top: 200 }}
          message={`${
            willClear(itemToSelectRef.current)
              ? "Wyczyszczenie"
              : "Zmiana wartości"
          } tego filtra spowoduje wyczyszczenie pozostałych filtrów. Czy chcesz kontynuować?`}
        />
      )}
    </div>
  );
};
