import { MouseEvent, ReactNode } from "react";

import cn from "classnames";

import { Button, NiceScrollbar } from "components/atoms";
import { ChevronLeft, ChevronRight } from "components/atoms/Icon";
import { useNavbarPosition } from "hooks";
import {
  FULL_NAVBAR_HEIGHT,
  ICON_SIZES,
  PRIMARY_NAVBAR_HEIGHT,
  SECONDARY_NAVBAR_HEIGHT
} from "utils";

import s from "./filterSidebar.module.scss";

interface Props {
  children: ReactNode;
  isOpen: boolean;
  withOffset: boolean;
  onToggleOpen: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const FilterSidebar = ({
  children,
  isOpen,
  withOffset,
  onToggleOpen
}: Props) => {
  const navbarPosition = useNavbarPosition(
    withOffset ? FULL_NAVBAR_HEIGHT : PRIMARY_NAVBAR_HEIGHT,
    withOffset ? SECONDARY_NAVBAR_HEIGHT : 0
  );

  return (
    <aside
      className={cn(s.base, { [s.open]: isOpen })}
      style={{ top: navbarPosition, paddingBottom: navbarPosition }}
    >
      <Button
        className={cn(s.button, { [s.buttonOpen]: isOpen })}
        onClick={onToggleOpen}
        data-ga-filter="ukryj pokaż"
        data-testid="filters-box-toggle"
        data-active={isOpen}
      >
        {isOpen ? (
          <ChevronLeft size={ICON_SIZES.EXTRA_LARGE} />
        ) : (
          <ChevronRight size={ICON_SIZES.EXTRA_LARGE} />
        )}
      </Button>
      <div className={s.bodyWrapper}>
        <NiceScrollbar>
          <div className={s.body}>{children}</div>
        </NiceScrollbar>
      </div>
    </aside>
  );
};
