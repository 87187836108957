import { useDispatch, useSelector } from "react-redux";

import { ChartSectionSelect } from "components/organisms";
import { IDS } from "components/organisms/Tour";
import { sortClientType } from "pages/Reports/redux/reducers/sweetStateHooks/useClientType";
import {
  isClientTypeMultiChoiceSelector,
  isShowDatasetSelectEnabledSelector
} from "pages/Reports/redux/selectors/reportsSelectors";
import { updateQueryParams } from "store/actions/routerActions";
import {
  chartPeriodSelector,
  chartTypeQueryParamSelector,
  clientTypeQueryParamSelector,
  queryParamsSelector
} from "store/selectors/routerSelectors";
import { capitalize, CHART_TYPES, CLIENT_TYPES, PERIOD_TYPE } from "utils";
import { QP } from "utils/defaultQueryParams";
import { pushChartSwitchEvent } from "utils/googleTagManager/dataLayer";

const labelMap = {
  [CLIENT_TYPES.OFFLINE]: "bez Żappki",
  [CLIENT_TYPES.ONLINE]: "z Żappką",
  [CLIENT_TYPES.ALL]: "wszystkie"
};

const dataTypeSuffixMap = {
  [CLIENT_TYPES.OFFLINE]: "_OFFLINE",
  [CLIENT_TYPES.ONLINE]: "_ONLINE"
};

export const useClientDataOptions = () => {
  const isClientTypeMultiChoice = useSelector(isClientTypeMultiChoiceSelector);
  const clientQP = useSelector(clientTypeQueryParamSelector);
  const chartTypeQP = useSelector(chartTypeQueryParamSelector);
  const period = useSelector(chartPeriodSelector);

  const client = clientQP.split(",").sort(sortClientType);
  const disabled =
    !isClientTypeMultiChoice || // client type needs to be multi choice
    client.length !== 2 || // at least two client types need to be selected
    client.some(type => !(type in labelMap)); // all client types need to be valid

  if (disabled) return [];

  const labels = client.map(type => labelMap[type]);

  return [
    {
      value: "both",
      display: capitalize(labels.join(" i ")),
      disabled:
        chartTypeQP !== CHART_TYPES.LINE || period === PERIOD_TYPE.RANKING
    },
    {
      value: client[0],
      display: capitalize(labels[0]),
      disabled: false
    },
    {
      value: client[1],
      display: capitalize(labels[1]),
      disabled: false
    }
  ];
};

export const useZappkaTransaction = () => {
  const params = useSelector(queryParamsSelector);
  const options = useClientDataOptions();
  const enabledOptions = options.filter(option => !option.disabled);

  if (!enabledOptions.length) return { value: "", options };

  const transactionQP = String(params[QP.ZAPPKA_TRANSACTION] || "");
  const hasOption = enabledOptions.some(({ value }) => value === transactionQP);

  return {
    value: hasOption ? transactionQP : enabledOptions[0].value,
    options
  };
};

export const useDataTypeSuffix = () => {
  const clientQP = useSelector(clientTypeQueryParamSelector);
  const transaction = useZappkaTransaction();

  if (!transaction.options.length) {
    return [dataTypeSuffixMap[clientQP] || ""];
  }

  const values = transaction.options.map(option => option.value);
  const keys =
    transaction.value === "both"
      ? [values[1], values[2]] // take values from each option except "both"
      : [transaction.value];

  return keys.map(key => dataTypeSuffixMap[key] || "");
};

const handleDataLayer = (
  value: string,
  options: { value: string; display: string; disabled: boolean }[]
) => {
  let dataLayerValue = value.toLowerCase();
  if (value === "both") {
    dataLayerValue = options
      .map(option => option.value)
      .filter(value => value !== "both")
      .join("&")
      .toLowerCase();
  }
  pushChartSwitchEvent(dataLayerValue);
};

export const ZappkaTransactionSelect = () => {
  const dispatch = useDispatch();
  const isShowDatasetSelectEnabled = useSelector(
    isShowDatasetSelectEnabledSelector
  );
  const { options, value } = useZappkaTransaction();

  if (!options.length) return null;

  return (
    <ChartSectionSelect
      id="zappka-transaction-select"
      wrapperId={IDS.TRANSACTION_SELECT}
      label={
        isShowDatasetSelectEnabled
          ? "Rodzaj transakcji"
          : "Pokaż dane dla transakcji"
      }
      value={value}
      options={options}
      onChange={value => {
        dispatch(updateQueryParams({ [QP.ZAPPKA_TRANSACTION]: value }));
        handleDataLayer(value, options);
      }}
    />
  );
};
