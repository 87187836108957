import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ItemNameColumn, TooltipContent } from "api/types";
import { slice } from "ramda";

import {
  hiddenLinesByReportSelector,
  toggleChart
} from "pages/Reports/redux/reducers/chartReducer";
import { pushSidebarRowEvent } from "utils/googleTagManager/dataLayer";
import { Nullable } from "utils/types";

import { SidebarMultiselectHooks } from "./useSidebarMultiselect";

export const useSidebarToggleElements = (
  sidebarData: Nullable<TooltipContent>
) => {
  const dispatch = useDispatch();
  const hiddenLines = useSelector(hiddenLinesByReportSelector);
  const [
    { startElement, endElement },
    { clearStartEndElements }
  ] = SidebarMultiselectHooks.useSidebarMultiselect();

  const toggleStartEndElements = useCallback(() => {
    if (startElement === null || endElement === null || !sidebarData) {
      return;
    }

    const legendIds = sidebarData.data.map(
      rowElement => (rowElement?.item as ItemNameColumn).legendId || ""
    );

    const selectedLegendIds =
      startElement > endElement
        ? slice(endElement, startElement + 1, legendIds)
        : slice(startElement, endElement + 1, legendIds);
    const isInHiddenLines = selectedLegendIds.every(line =>
      hiddenLines.includes(line)
    );

    dispatch(toggleChart(selectedLegendIds));
    clearStartEndElements();
    pushSidebarRowEvent(isInHiddenLines);
  }, [
    startElement,
    endElement,
    sidebarData,
    hiddenLines,
    dispatch,
    clearStartEndElements
  ]);

  useEffect(() => {
    return toggleStartEndElements();
  }, [startElement, endElement, toggleStartEndElements]);
};
