import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import { DropdownItem } from "components/molecules/types";
import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { PromotionsHooks } from "pages/Reports/redux/reducers/sweetStateHooks/usePromotions";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { isPowerUserSelector } from "store/reducers/userReducer";
import {
  promotionsQPSelector,
  vendorIdQueryParamSelector
} from "store/selectors/routerSelectors";

import { VendorHooks } from "../../sweetStateHooks/useVendor";
import { useFiltersFetchingParams } from "./useFiltersFetchingParams";

// TODO: remove once API is ready
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

// TODO: add missing params if needed once API is ready
const usePromotionsFilterParams = () => {
  const isFormPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const isMultiChoice = useSelector(isCompaniesDropdownMultiChoiceSelector);
  const [selectedCompany] = VendorHooks.useSelectedVendorsIds(isMultiChoice);

  const isPowerUser = useSelector(isPowerUserSelector);

  const selectedCompanyQP = useSelector(vendorIdQueryParamSelector)
    .toString()
    .split(",");

  const areMultipleCompaniesSelected = isFormPristine
    ? selectedCompanyQP.length > 1
    : selectedCompany.length > 1;

  const params = useFiltersFetchingParams([
    "category",
    "categoryLevel",
    "vendor"
  ]);

  const commonParams = {
    categories: params.category,
    category_level: params.categoryLevel
  };

  if (areMultipleCompaniesSelected) {
    return {
      ...commonParams,
      companies: params.vendor
    };
  }

  if (isPowerUser) {
    return {
      ...commonParams,
      vendor_id: params.vendor
    };
  }

  return commonParams;
};

const useFetchingFlag = (params: {}, isDisabled: boolean) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching.promotions;
  const lastQuery = useSelector(asyncFiltersSelector).query.promotions;

  if (isLoading || isDisabled) {
    return false;
  }

  const query = qs.stringify(params);
  if (query === lastQuery) {
    return false;
  }

  return true;
};

export const usePromotionsFetching = (isDisabled: boolean) => {
  const filter = "promotions";
  const [, actions] = PromotionsHooks.usePromotions();
  const params = usePromotionsFilterParams();
  const dispatch = useDispatch();
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const promotionsQP = useSelector(promotionsQPSelector);
  const isFetchingPossible = useFetchingFlag(params, isDisabled);

  const callback = useCallback(
    (promotions: DropdownItem<string>[]) => {
      actions.updateAllPromotions(promotions);
      if (isPristine) {
        actions.checkPristineSelectionsAfterFetching(promotions, promotionsQP);
      }
      actions.checkSelectionsAfterFetching(
        promotions.map(promotion => promotion.value)
      );
    },
    [actions, isPristine, promotionsQP]
  );

  useEffect(() => {
    if (!isFetchingPossible) {
      return;
    }

    const fetch = async () => {
      try {
        dispatch(updateQuery({ filter, query: qs.stringify(params) }));

        dispatch(setFlag({ filter, isFetching: true }));
        // TODO: replace with API request once it's ready
        const promotions = await delay(1000).then(() => [
          { value: "gaz", label: "Gazetka" },
          { value: "apl", label: "Aplikacja" },
          { value: "kat", label: "Katalog" }
        ]);
        dispatch(setError({ filter, status: "" }));

        return promotions;
      } catch (e) {
        dispatch(setError({ filter, status: "Błąd pobierania promocji" }));
        return [];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };

    fetch().then(callback);
  }, [callback, dispatch, isFetchingPossible, params]);
};
