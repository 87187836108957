import { omit } from "ramda";

import {
  SharesSummary,
  SharesSummaryApi,
  SharesSummaryItem,
  SharesSummaryItemApi
} from "pages/Reports/types/summary";

import { snake2camel } from "../snake2camel";

const normalizeSharesItem = (item: SharesSummaryItemApi): SharesSummaryItem => {
  return Object.keys(item).reduce(
    (acc, key) => ({
      ...acc,
      [snake2camel(key)]: item[key as keyof SharesSummaryItemApi] ?? null
    }),
    {}
  ) as SharesSummaryItem;
};

export const normalizeSharesSummary = (
  summary: SharesSummaryApi
): SharesSummary => {
  return {
    "1": summary?.["1"] ? normalizeSharesItem(summary[1]) : null,
    "2": summary?.["2"] ? normalizeSharesItem(summary[2]) : null,
    "3": summary?.["3"] ? normalizeSharesItem(summary[3]) : null,
    unit: summary.unit?.toLowerCase() || "",
    ...Object.keys(omit(["1", "2", "3", "unit"], summary)).reduce(
      (acc, key) => ({
        ...acc,
        [snake2camel(key)]: summary[key as keyof SharesSummaryApi] ?? null
      }),
      {}
    )
  } as SharesSummary;
};
