import { useState } from "react";
import { useSelector } from "react-redux";

import cn from "classnames";

import { Button } from "components/atoms";
import { IDS } from "components/organisms/Tour";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { tierSelector } from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { APP_PATHS, isThisPage } from "utils";
import { pushFiltersSectionToggleEvent } from "utils/googleTagManager/dataLayer";

import s from "./productHierarchy.module.scss";
import { ProductHierarchyModal } from "./ProductHierarchyModal";

const { INSIGHTS, EXPORT } = APP_PATHS;
const { LOGISTICS_PATH } = REPORTS_FULL_PATHS;
interface Props {
  isDisabled: boolean;
}

export const ProductHierarchy = ({ isDisabled }: Props) => {
  const [open, setOpen] = useState(false);

  const currentTier = useSelector(tierSelector);
  const pathname = useSelector(pathnameSelector);
  const isInsightsPage = isThisPage(pathname, INSIGHTS);
  const isLogisticsPage = isThisPage(pathname, LOGISTICS_PATH);
  const isExportPage = isThisPage(pathname, EXPORT);
  const isTooltipVisible =
    currentTier === 3 && !isInsightsPage && !isLogisticsPage && !isExportPage;

  const openHierarchyModal = () => {
    pushFiltersSectionToggleEvent("Hierarchia - pokaż");
    setOpen(true);
  };

  return (
    <>
      <Button
        onClick={!isDisabled ? () => openHierarchyModal() : undefined}
        type="button"
        className={cn(s.buttonLink, {
          [s.buttonLinkDisabled]: isDisabled,
          [s.buttonTooltip]: isTooltipVisible
        })}
        data-tooltip={
          isTooltipVisible
            ? `Jeśli chcesz sprawdzić hierarchię produktów konkurencyjnych, ustaw odpowiednie filtry w sekcji "Konkurencja" poniżej`
            : ""
        }
        testId={IDS.SHOW_HIERARCHY_BUTTON}
      >
        Pokaż hierarchię
      </Button>
      {open && <ProductHierarchyModal onClose={() => setOpen(false)} />}
    </>
  );
};
