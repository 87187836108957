import * as React from "react";
import { VariableSizeList as List } from "react-window";

import cn from "classnames";

import { NiceScrollbar } from "components/atoms";

import { DropdownItem, PropGetter } from "../types";
import s from "./filterDropdownList.module.scss";

type ItemPropGetter = PropGetter<"getItemProps">;

interface Props<Item extends DropdownItem> {
  minimumItemsToVirtualize: number;
  items: Item[];
  listItem: React.ReactElement<any, any>;
  isItemActive: (item: Item) => boolean;
  getItemProps: ItemPropGetter;
  className: string;
  isMultiChoice?: boolean;
  basicItemHeight?: number;
  maxCharactersInLine?: number;
}

const FONT_SIZE = 16;
const MAX_LIST_HEIGHT = 266;

export function FilterDropdownList<Item extends DropdownItem>({
  minimumItemsToVirtualize,
  items,
  listItem,
  getItemProps,
  isItemActive,
  isMultiChoice,
  className,
  maxCharactersInLine = 26, //estimated characters to fit in one line to calculate if text will break line
  basicItemHeight = 32 //it is needed for estimating list height to create proper scrollbar size
}: Props<Item>) {
  const shouldVirtualize =
    minimumItemsToVirtualize && items.length >= minimumItemsToVirtualize;

  const getItemSize = (index: number) => {
    const item = items[index] || {};
    const text = item.menuLabel || item.label || "";
    const charactersInLine = text.length;

    return (
      basicItemHeight +
      FONT_SIZE * Math.floor(charactersInLine / maxCharactersInLine)
    );
  };

  if (shouldVirtualize) {
    return (
      <List
        height={MAX_LIST_HEIGHT}
        width="100%"
        estimatedItemSize={basicItemHeight}
        itemSize={getItemSize}
        itemCount={items.length}
        className={cn(s.listWrapper, className)}
        innerElementType="ul"
        outerElementType={NiceScrollbar}
      >
        {({ index, style }) =>
          React.cloneElement(listItem, {
            ...listItem.props,
            getItemProps,
            item: items[index] || {},
            isActive: isItemActive(items[index]),
            style,
            isMultiChoice
          })
        }
      </List>
    );
  }

  return (
    <NiceScrollbar autoHeight autoHeightMax={300}>
      <ul className={cn(className)}>
        {items.map(item => {
          if (!item.label || !item.value) {
            return null;
          }

          return React.cloneElement(listItem, {
            ...listItem.props,
            getItemProps,
            item,
            //@ts-ignore temporary fix until zabka fixes counties duplicates in database
            key: `${item.value}-${item?.voivodeship || ""}`,
            isActive: isItemActive(item),
            isMultiChoice
          });
        })}
      </ul>
    </NiceScrollbar>
  );
}
