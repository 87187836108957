import * as React from "react";

import { Button, Text } from "components/atoms";
import c from "pages/Reports/partials/Chart/Legend/common.module.scss";
import { LegendItem } from "pages/Reports/partials/Chart/Legend/LegendItems/LegendItems";

const TEST_IDS = {
  LEGEND_SUB_SECTION: "legend-sub-section"
};

type TabPanelSubSectionProps = {
  isDisabled: boolean;
  onClick: (labels: string[]) => void;
  items: LegendItem[];
  sectionTitle: string;
};

const adjustOpacity = (labels: boolean[]) => {
  const areAllLinesHidden = labels.every(isLineHidden => isLineHidden);
  return areAllLinesHidden ? "0.2" : "1";
};

export const LegendSubSection: React.FC<TabPanelSubSectionProps> = ({
  isDisabled,
  items,
  sectionTitle,
  onClick,
  children
}) => (
  <>
    <Button
      type="button"
      disabled={isDisabled}
      className={c.button}
      onClick={() => {
        onClick(items.map(item => item.id));
      }}
      testId={`${TEST_IDS.LEGEND_SUB_SECTION}-${sectionTitle}`}
    >
      <Text className={c.activeTab}>
        <span
          style={{
            opacity: adjustOpacity(
              items.map(item => item.isLineHidden || false)
            )
          }}
        >
          {sectionTitle}
        </span>
      </Text>
    </Button>
    {children}
  </>
);
