import {
  addMonths,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getMonth,
  isSameDay,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subYears
} from "date-fns";

import { newDateByTimezone } from "store/utils";
import { DATE_FNS_DOT_FORMAT } from "utils";
import { Keys, Nullable, Tier } from "utils/types";

export const CALENDAR_OPTIONS = {
  YESTERDAY: "Wczoraj",
  LAST_SEVEN_DAYS: "Ostatnie 7 dni",
  LAST_THIRTY_DAYS: "Ostatnie 30 dni",
  THIS_WEEK: "Ten tydzień",
  LAST_WEEK: "Poprzedni tydzień",
  THIS_MONTH: "Ten miesiąc",
  LAST_MONTH: "Poprzedni miesiąc",
  LAST_THREE_MONTHS: "Ostatnie 3 miesiące",
  LAST_SIX_MONTHS: "Ostatnie 6 miesięcy",
  THIS_YEAR: "Ten rok",
  YEAR_TO_LAST_MONTH: "Ten rok",
  LAST_YEAR: "Poprzedni rok",
  MAT: "MAT",
  MAX: "Max"
} as const;

export type CalendarOptionKey = Keys<typeof CALENDAR_OPTIONS>;

export const filterOptionByTier = (key: CalendarOptionKey, tier: Tier) => {
  switch (tier) {
    case 1:
    case 2:
      return ["YEAR_TO_LAST_MONTH", "LAST_YEAR", "MAT", "MAX"].includes(key);
    default:
      return !["YEAR_TO_LAST_MONTH"].includes(key);
  }
};

const getDatesByKey = (
  key: CalendarOptionKey,
  minDate: Date,
  maxDate: Date
) => {
  const now = newDateByTimezone();
  const yesterday = subDays(now, 1);

  switch (key) {
    case "LAST_SEVEN_DAYS":
      return {
        dateFrom: subDays(now, 7),
        dateTo: yesterday
      };
    case "LAST_THIRTY_DAYS":
      return {
        dateFrom: subDays(now, 30),
        dateTo: yesterday
      };
    case "THIS_WEEK":
      return {
        dateFrom: startOfWeek(now, { weekStartsOn: 1 }),
        dateTo: yesterday
      };
    case "LAST_WEEK":
      return {
        dateFrom: startOfWeek(subDays(now, 7), { weekStartsOn: 1 }),
        dateTo: startOfDay(endOfWeek(subDays(now, 7), { weekStartsOn: 1 }))
      };
    case "THIS_MONTH":
      return {
        dateFrom: startOfMonth(now),
        dateTo: yesterday
      };
    case "LAST_MONTH":
      return {
        dateFrom: startOfMonth(subMonths(now, 1)),
        dateTo: startOfDay(endOfMonth(subMonths(now, 1)))
      };
    case "LAST_THREE_MONTHS":
      return {
        dateFrom: subMonths(now, 3),
        dateTo: yesterday
      };
    case "LAST_SIX_MONTHS":
      return {
        dateFrom: subMonths(now, 6),
        dateTo: yesterday
      };
    case "THIS_YEAR":
      return {
        dateFrom: startOfYear(now),
        dateTo: yesterday
      };
    case "YEAR_TO_LAST_MONTH":
      return {
        dateFrom: startOfYear(now),
        dateTo: !getMonth(now)
          ? yesterday // use "yesterday" for January to disable option
          : startOfDay(endOfMonth(subMonths(now, 1)))
      };
    case "LAST_YEAR":
      return {
        dateFrom: startOfYear(subYears(now, 1)),
        dateTo: startOfDay(endOfYear(subYears(now, 1)))
      };
    case "MAT": // last full 12 months e.g. 21/07/2022 -> 01/07/2021 - 30/06/2022
      const matStart = startOfMonth(subYears(now, 1));
      return {
        dateFrom: matStart,
        dateTo: startOfDay(endOfMonth(addMonths(matStart, 11)))
      };
    case "MAX":
      return {
        dateFrom: minDate,
        dateTo: maxDate
      };
    case "YESTERDAY":
    default:
      return {
        dateFrom: yesterday,
        dateTo: yesterday
      };
  }
};

const isItemDisabled = (
  dateFrom: Date,
  dateTo: Date,
  minDate: Date,
  maxDate: Date
) => {
  return ![dateFrom, dateTo].every(date =>
    isWithinInterval(date, { start: minDate, end: maxDate })
  );
};

export const isItemActive = (
  dateFrom: Date,
  dateTo: Date,
  calendarDateFrom: Nullable<Date>,
  calendarDateTo: Nullable<Date>
) => {
  if (!calendarDateFrom || !calendarDateTo) {
    return false;
  }

  return (
    isSameDay(dateFrom, calendarDateFrom) && isSameDay(dateTo, calendarDateTo)
  );
};

export const mapOptionToItem = (
  key: CalendarOptionKey,
  minDate: Date,
  maxDate: Date
) => {
  const { dateFrom, dateTo } = getDatesByKey(key, minDate, maxDate);
  const title = String(CALENDAR_OPTIONS[key]);
  const item = {
    id: key.toLowerCase(),
    title,
    display: title,
    dateFrom,
    dateTo,
    isDisabled: isItemDisabled(dateFrom, dateTo, minDate, maxDate)
  };

  // append dates to MAT and MAX options
  if (["MAT", "MAX"].includes(key)) {
    item.display += ` (${format(item.dateFrom, DATE_FNS_DOT_FORMAT)} - ${format(
      item.dateTo,
      DATE_FNS_DOT_FORMAT
    )})`;
  }

  return item;
};
