import { useMemo } from "react";

import { useFiltersQP } from "hooks";
import { AggregationTypes } from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";

import { BOOL_STRING_VALUES } from "./../utils/constants";

const CATEGORIES_BRANDS_PRODUCTS_INFO =
  "Wybranie tak dużej liczby kategorii/marek/submarek/produktów powoduje znacznie dłuższe ładowanie raportu.";
const REGIONS_SHOPTYPE_LFL_INFO =
  "Wybranie regionów/rodzaju sklepów/LFL może spowodować dłuższy czas pobierania danych. W każdej chwili w filtrach po lewej stronie możesz zmienić swój wybór.";
const CATEGORIES_BRANDS_PRODUCTS_REGIONS_SHOPTYPE_LFL_INFO =
  'Wybranie regionów/rodzaju sklepów/LFL oraz tak dużej liczby produktów/kategorii/firm konkurencyjnych do porównania bez włączonej opcji "Pokaż zagregowane dane" może spowodować dłuższy czas pobierania danych, oraz doprowadzić do spowolnienia działania Twojej przeglądarki.';

const USE_AGGREGATION =
  "Jeżeli chcesz krócej czekać na dane, to w filtrach po lewej stronie możesz zawęzić swój wybór lub skorzystać z agregacji danych.";

const CATEGORY_LIMIT = 5;
const PRODUCTS_BRANDS_LIMIT = 40;
const COMPANIES_LIMIT = 10;

const toArray = (value: string) => value.split(",").filter(val => val !== "");

export const useLoadingInfo = () => {
  const {
    cat1QP,
    cat2QP,
    cat3QP,
    productsQP,
    brandQP,
    subBrandQP,
    competitionCompaniesQP,
    competitionProductsQP,
    voivodeshipsQP,
    countiesQP,
    shopTypeQP,
    lflQP,
    aggregatedQP
  } = useFiltersQP();

  return useMemo(() => {
    const isTooManyCategories =
      toArray(cat3QP || cat2QP || cat1QP).length >= CATEGORY_LIMIT;
    const isTooManyProductsOrBrands =
      toArray(productsQP || subBrandQP || brandQP).length >=
      PRODUCTS_BRANDS_LIMIT;
    const isTooManyCompetitionCompanies =
      toArray(competitionCompaniesQP).length >= COMPANIES_LIMIT;
    const isTooManyCompetitionProducts =
      toArray(competitionProductsQP).length >= PRODUCTS_BRANDS_LIMIT;
    const isLfl = lflQP === BOOL_STRING_VALUES.TRUE;

    const isAnyRegionOrShopTypeOrLflSelected =
      toArray(countiesQP || voivodeshipsQP || shopTypeQP).length >= 1 || isLfl;

    const isTooManyOwn = isTooManyCategories || isTooManyProductsOrBrands;
    const isTooManyCompetitor =
      isTooManyCompetitionCompanies || isTooManyCompetitionProducts;

    const isTooManyCategoriesOrProductsOrBrands =
      isTooManyOwn || isTooManyCompetitor;

    const isWrongAggregationSelected = () => {
      switch (aggregatedQP) {
        case AggregationTypes.comp:
          return isTooManyOwn;
        case AggregationTypes.own:
          return isTooManyCompetitor;
        case AggregationTypes.false:
          return isTooManyCategoriesOrProductsOrBrands;
        case AggregationTypes.both:
        case AggregationTypes.total:
        default:
          return false;
      }
    };

    if (
      isTooManyCategoriesOrProductsOrBrands &&
      isAnyRegionOrShopTypeOrLflSelected &&
      isWrongAggregationSelected()
    ) {
      return [
        CATEGORIES_BRANDS_PRODUCTS_REGIONS_SHOPTYPE_LFL_INFO,
        USE_AGGREGATION
      ];
    }

    if (isTooManyCategoriesOrProductsOrBrands && isWrongAggregationSelected()) {
      return [CATEGORIES_BRANDS_PRODUCTS_INFO, USE_AGGREGATION];
    }

    if (isAnyRegionOrShopTypeOrLflSelected) return [REGIONS_SHOPTYPE_LFL_INFO];

    return [];
  }, [
    countiesQP,
    voivodeshipsQP,
    shopTypeQP,
    lflQP,
    aggregatedQP,
    cat1QP,
    cat2QP,
    cat3QP,
    productsQP,
    brandQP,
    subBrandQP,
    competitionCompaniesQP,
    competitionProductsQP
  ]);
};
