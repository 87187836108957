import { useDispatch, useSelector } from "react-redux";

import { Portal } from "components/atoms";
import { ErrorAlert, SuccessAlert } from "components/molecules";
import { setErrorMessage, setSuccessMessage } from "store/actions/appActions";
import {
  errorMessageSelector,
  successMessageSelector
} from "store/selectors/appSelectors";

export const AlertOverlay = () => {
  const dispatch = useDispatch();

  const errorMessage = useSelector(errorMessageSelector);
  const successMessage = useSelector(successMessageSelector);

  if (errorMessage) {
    return (
      <Portal id="js-alert">
        <ErrorAlert onClose={() => dispatch(setErrorMessage(""))}>
          {errorMessage}
        </ErrorAlert>
      </Portal>
    );
  }

  if (successMessage) {
    return (
      <Portal id="js-alert">
        <SuccessAlert onClose={() => dispatch(setSuccessMessage(""))}>
          {successMessage}
        </SuccessAlert>
      </Portal>
    );
  }

  return null;
};
