import { omit } from "ramda";

import {
  DynamicsSummary,
  DynamicsSummaryApi,
  DynamicsSummaryItem,
  DynamicsSummaryItemApi
} from "pages/Reports/types/summary";

import { snake2camel } from "../snake2camel";

const normalizeDynamicsItem = (
  item: DynamicsSummaryItemApi
): DynamicsSummaryItem => {
  return Object.keys(item).reduce(
    (acc, key) => ({
      ...acc,
      [snake2camel(key)]: item[key as keyof DynamicsSummaryItemApi] ?? null
    }),
    {}
  ) as DynamicsSummaryItem;
};

export const normalizeDynamicsSummary = (
  summary: DynamicsSummaryApi
): DynamicsSummary => {
  return {
    "1": summary?.["1"] ? normalizeDynamicsItem(summary[1]) : null,
    "2": summary?.["2"] ? normalizeDynamicsItem(summary[2]) : null,
    "3": summary?.["3"] ? normalizeDynamicsItem(summary[3]) : null,
    unit: summary.unit?.toLowerCase() || "",
    ...Object.keys(omit(["1", "2", "3", "unit"], summary)).reduce(
      (acc, key) => ({
        ...acc,
        [snake2camel(key)]: summary[key as keyof DynamicsSummaryApi] ?? null
      }),
      {}
    )
  } as DynamicsSummary;
};
