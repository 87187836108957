import React, { useEffect, useRef, useState } from "react";
import { positionValues } from "react-custom-scrollbars";

import cn from "classnames";

import { NiceScrollbar } from "components/atoms";
import { StaticTable, VirtualizedTable } from "components/molecules";
import {
  MAX_TABLE_ROWS,
  MIN_COLUMN_WIDTH,
  ROW_HEIGHT
} from "components/molecules/constants";
import { TableProps } from "components/types";

import s from "./table.module.scss";

const MIN_ROWS_TO_VIRTUALIZE = 7;

export function Table<Data>({
  isLoading,
  customTableHeight,
  data,
  minColumnWidth = MIN_COLUMN_WIDTH,
  forceVirtualized = false,
  className = {},
  ...props
}: TableProps<Data>) {
  // === table height measures
  const rowsCount = data?.length || 0;
  const tableHeightBase =
    isLoading && rowsCount === 0 ? MAX_TABLE_ROWS : rowsCount;
  const tableHeight =
    (customTableHeight ||
      Math.min(tableHeightBase, MAX_TABLE_ROWS) * ROW_HEIGHT) + 2; // +2 for table top and bottom borders

  // === vertical scroll positioning
  const responsiveWrapperRef = useRef<HTMLDivElement>(null);

  const responsiveWrapperWidth =
    responsiveWrapperRef?.current?.getBoundingClientRect()?.width || 0;

  const [verticalScrollPosition, setVerticalScrollPosition] = useState<number>(
    0
  );

  useEffect(() => {
    setVerticalScrollPosition(responsiveWrapperWidth - 10);
  }, [responsiveWrapperWidth]);

  const handleScrollValues = (values: positionValues) => {
    setVerticalScrollPosition(values.clientWidth + values.scrollLeft - 10);
  };

  return (
    <div
      className={cn(s.tableResponsiveWrapper, className.tableResponsiveWrapper)}
      data-ga-table="data-ga-table"
      ref={responsiveWrapperRef}
    >
      <NiceScrollbar
        autoHeight
        hideVertical //this NiceScrollbar is for horizontal scroll only (wraps whole table container)
        autoHeightMin={tableHeight + ROW_HEIGHT + 15}
        autoHeightMax="unset"
        handleScroll={handleScrollValues}
      >
        {data.length >= MIN_ROWS_TO_VIRTUALIZE || forceVirtualized ? (
          <VirtualizedTable
            data={data}
            customTableHeight={tableHeight}
            minColumnWidth={minColumnWidth}
            className={className}
            verticalScrollPosition={verticalScrollPosition}
            {...props}
          />
        ) : (
          <StaticTable
            minColumnWidth={minColumnWidth}
            data={data}
            className={className}
            customTableHeight={tableHeight}
            {...props}
          />
        )}
      </NiceScrollbar>
    </div>
  );
}
