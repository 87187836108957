import { Action, createHook, createStore } from "react-sweet-state";

import { DropdownItem } from "components/molecules/types";
import { CLIENT_TYPES } from "utils/constants";

export type ClientTypeItem = DropdownItem<string>;

type State = { all: ClientTypeItem[]; selected: ClientTypeItem[] };
type Actions = typeof actions;

export const CLIENT_TYPE_ITEMS = [
  { value: CLIENT_TYPES.ALL, label: "Wszystkie" },
  { value: CLIENT_TYPES.OFFLINE, label: "Bez użycia Żappki" },
  { value: CLIENT_TYPES.ONLINE, label: "Z Żappką" }
];

const SORTING_ORDER = CLIENT_TYPE_ITEMS.map(({ value }) => value);

export const sortClientType = (a: string, b: string) => {
  return SORTING_ORDER.indexOf(a) - SORTING_ORDER.indexOf(b);
};

const getPristineClientItems = (value: string, isMultiChoice: boolean) => {
  const items = value.split(",");
  const filtered = CLIENT_TYPE_ITEMS.filter(item => items.includes(item.value));
  if (!filtered.length) return [CLIENT_TYPE_ITEMS[0]]; // min 1 item selected
  if (!isMultiChoice) return [filtered[0]];
  if (filtered.length > 2) return [filtered[0], filtered[1]]; // max 2 items selected
  return filtered;
};

const actions = {
  updateSelectedClientType: (
    item: ClientTypeItem,
    isMultiChoice: boolean
  ): Action<State> => ({ getState, setState }) => {
    if (!isMultiChoice) {
      setState({ selected: [item] });
      return;
    }

    const selected = [...getState().selected];
    const index = selected.findIndex(({ value }) => value === item.value);

    if (index > -1) {
      if (selected.length > 1) selected.splice(index, 1); // min 1 item selected
    } else {
      if (selected.length < 2) selected.push(item); // max 2 items selected
    }

    const sorted = selected.sort((a, b) => sortClientType(a.value, b.value));
    setState({ selected: sorted });
  },
  setPristineClientType: (
    clientQP: string,
    isMultiChoice: boolean
  ): Action<State> => ({ setState }) => {
    if (clientQP) {
      setState({ selected: getPristineClientItems(clientQP, isMultiChoice) });
    }
  },
  clearClientType: (): Action<State> => ({ setState }) => {
    setState({ selected: [CLIENT_TYPE_ITEMS[0]] });
  }
};

export const ClientTypeStore = createStore<State, Actions>({
  name: "clientType",
  initialState: {
    all: CLIENT_TYPE_ITEMS,
    selected: [CLIENT_TYPE_ITEMS[0]]
  },
  actions
});

const getClientTypeLabel = (state: State) => {
  const values = CLIENT_TYPE_ITEMS.map(item => item.value);
  const filtered = state.selected.filter(item => values.includes(item.value));
  const labels = filtered.map(item => item.label);
  return labels.length ? labels.join(", ") : "";
};

const getClientTypeValue = (state: State) => {
  return state.selected.map(item => item.value).join(",");
};

export const ClientTypeHooks = {
  useClientType: createHook(ClientTypeStore),
  useClientTypeLabel: createHook(ClientTypeStore, {
    selector: getClientTypeLabel
  }),
  useClientTypeValue: createHook(ClientTypeStore, {
    selector: getClientTypeValue
  })
};
