import { useRankingWarnings } from "api/ranking/mappers";
import { useGetSellsByPeriod } from "api/sells/mappers/useGetSellsByPeriod";
import { useSellsGeneralWarnings } from "api/sells/mappers/useSellsGeneralWarnings";

export const useSellsWarnings = (): string[] => {
  const generalWarnings = useSellsGeneralWarnings();
  const info = useGetSellsByPeriod().data?.data.info || [];
  const rankingWarnings = useRankingWarnings();

  return [...generalWarnings, ...info, ...rankingWarnings].filter(
    warning => !!warning
  );
};
