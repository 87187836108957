import { useSelector } from "react-redux";

import { useGetLocationByPeriod } from "api/location/mappers/useGetLocationByPeriod";
import { getCountiesIdsByVoivodeship } from "api/location/utils/getCountiesIdsByVoivodeship";
import { getMapChartData } from "api/location/utils/getMapChartData";
import { sortMapCharts } from "api/location/utils/sortMapCharts";
import { values } from "ramda";

import { VOIVODESHIPS } from "components/D3/Voivodeship/constants";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { getChartYKey } from "pages/Reports/redux/utils";
import { MapChartData } from "pages/Reports/sections/Location/redux/types/locationChartTypes";
import {
  firstChartDataTypeSelector,
  urlParamSelector
} from "store/selectors/routerSelectors";
import { DATASET_TYPES } from "utils";
import { Values } from "utils/types";

interface LocationMapDetailedChartsData {
  label: string;
  type: Values<typeof DATASET_TYPES>;
  data: MapChartData;
  lastYearData?: MapChartData;
}

export const useLocationMapDetailedChartsData = (): LocationMapDetailedChartsData[] => {
  const urlParam = useSelector(urlParamSelector);
  const dataType = useSelector(firstChartDataTypeSelector);
  const { data } = useGetLocationByPeriod();
  const dataset = useCurrentDataset();

  const key = getChartYKey(dataType);

  const voivodeship = values(VOIVODESHIPS).find(
    voivodeship => voivodeship === urlParam
  );

  if (!voivodeship || !data || !key) return [];

  const countiesIds = getCountiesIdsByVoivodeship(voivodeship);
  const lastYear = `${key}LastYear`;
  const base = dataset.historical ? lastYear : key;

  return data.data.data
    .map(chart => ({
      label: chart.name,
      type: chart.type,
      // @ts-ignore TS complains about key value since it comes from util that's being used for both sales and location
      data: countiesIds.reduce(getMapChartData(chart.counties, base), {}),
      ...(dataset.both && {
        lastYearData: countiesIds.reduce(
          // @ts-ignore TS complains about key value since it comes from util that's being used for both sales and location
          getMapChartData(chart.counties, lastYear),
          {}
        )
      })
    }))
    .sort(sortMapCharts);
};
