import React, { FC } from "react";
import { Cell } from "react-table";

import { RowData } from "api/types";
import cn from "classnames";

import { Text } from "components/atoms";
import { ContentTableCell } from "components/molecules";
import { useFiltersQP } from "hooks";
import { PromotionDataType } from "pages/Reports/redux/reducers/promotionsReducer";
import {
  BOOL_STRING_VALUES,
  DATASET_TYPES,
  getPolishLabelForChartHeader,
  TEXT_WRAPPERS
} from "utils";
import { Values } from "utils/types";

import s from "./cells.module.scss";

interface Props {
  cell: Cell<RowData>;
  emptyLabel?: string;
}

const convertValueType = (type: Values<typeof DATASET_TYPES>) => {
  switch (type) {
    case DATASET_TYPES.OWN:
      return "Dane własne";
    case DATASET_TYPES.COMPETITOR:
      return "Dane konkurencji";
    case DATASET_TYPES.ATTRIBUTES:
      return "Dane wspólne";
    case DATASET_TYPES.REFERENCE:
      return "Kategoria referencyjna";
    case DATASET_TYPES.REFERENCE_BRAND:
      return "Marka referencyjna";
    default:
      return "";
  }
};

export const MainInfoCell: FC<Props> = ({ cell }) => {
  const { aggregatedQP } = useFiltersQP();
  const isCompetitorData = cell.value.type === DATASET_TYPES.COMPETITOR;

  return (
    <ContentTableCell>
      <Text className={cn(s.mainInfoText, s.label)} Wrapper={TEXT_WRAPPERS.P}>
        {cell.value.label}
      </Text>
      {aggregatedQP === BOOL_STRING_VALUES.TRUE ? (
        <Text
          className={cn(s.mainInfoText, s.valueType)}
          Wrapper={TEXT_WRAPPERS.P}
        >
          {convertValueType(cell.value.type || "")}
        </Text>
      ) : (
        <Text
          className={cn(s.mainInfoText, {
            [s.vendorId]: !isCompetitorData,
            [s.competitorVendorId]: isCompetitorData
          })}
          Wrapper={TEXT_WRAPPERS.P}
        >
          {cell.value.vendorId || convertValueType(cell.value.type || "")}
        </Text>
      )}
      {cell.value?.promotions?.length > 0 && (
        <Text
          className={cn(s.mainInfoText, s.promotion)}
          Wrapper={TEXT_WRAPPERS.P}
        >
          {cell.value.promotions
            .map((promo: PromotionDataType) =>
              getPolishLabelForChartHeader(promo.display)
            )
            .join(", ") || ""}
        </Text>
      )}
    </ContentTableCell>
  );
};
