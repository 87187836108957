import { flatten } from "ramda";

import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { APP_PATHS, REST_API_ENDPOINTS } from "utils/constants";

export const reportUrls = [
  {
    backend: [
      REST_API_ENDPOINTS.REPORTS.SALES,
      REST_API_ENDPOINTS.REPORTS.SALES_EXPORT
    ],
    frontend: REPORTS_FULL_PATHS.SELL_PATH
  },
  {
    backend: [
      REST_API_ENDPOINTS.REPORTS.SALES_BY_LOCATION,
      REST_API_ENDPOINTS.REPORTS.SALES_BY_LOCATION_EXPORT
    ],
    frontend: REPORTS_FULL_PATHS.LOCATION_PATH
  },
  {
    backend: [
      REST_API_ENDPOINTS.REPORTS.SHARES,
      REST_API_ENDPOINTS.REPORTS.SHARES_EXPORT
    ],
    frontend: REPORTS_FULL_PATHS.SHARES_PATH
  },
  {
    backend: [
      REST_API_ENDPOINTS.REPORTS.DYNAMIC_SALES,
      REST_API_ENDPOINTS.REPORTS.DYNAMICS_EXPORT
    ],
    frontend: REPORTS_FULL_PATHS.DYNAMICS_PATH
  },
  {
    backend: [
      REST_API_ENDPOINTS.REPORTS.RECEIPTS,
      REST_API_ENDPOINTS.REPORTS.RECEIPTS_EXPORT
    ],
    frontend: REPORTS_FULL_PATHS.RECEIPTS_PATH
  },
  {
    backend: [REST_API_ENDPOINTS.REPORTS.SEGMENTS],
    frontend: REPORTS_FULL_PATHS.SEGMENTS_PATH
  },
  {
    backend: [
      REST_API_ENDPOINTS.REPORTS.SUPER_EXPORT,
      REST_API_ENDPOINTS.REPORTS.GENERATE_SUPER_EXPORT
    ],
    frontend: APP_PATHS.EXPORT
  },
  {
    backend: [
      REST_API_ENDPOINTS.REPORTS.LOGISTICS,
      REST_API_ENDPOINTS.REPORTS.LOGISTICS_EXPORT
    ],
    frontend: REPORTS_FULL_PATHS.LOGISTICS_PATH
  }
];

export const hideNotRelevantError = (
  currentRequestUrl: string,
  currentPathname: string
) => {
  const backendUrls = flatten(reportUrls.map(rep => rep.backend));
  const frontendUrls = flatten(reportUrls.map(rep => rep.frontend));

  const isReportRelatedRequestUrl = backendUrls.includes(currentRequestUrl);
  const isReportRelatedPathname = frontendUrls.includes(currentPathname);

  if (!isReportRelatedRequestUrl || !isReportRelatedPathname) return false;

  const frontendUrlForRequestUrl = reportUrls.find(rep =>
    flatten(rep.backend).includes(currentRequestUrl)
  )?.frontend;

  const isCurrentPathnameRelatedToRequestUrl =
    currentPathname === frontendUrlForRequestUrl;

  return !isCurrentPathnameRelatedToRequestUrl;
};
