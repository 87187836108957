import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import cn from "classnames";
import qs from "query-string";

import { Button } from "components/atoms";
import { Logo, SettingsOutline } from "components/atoms/Icon";
import { BACK_DATA_REPORTS } from "components/molecules/DateDropdownMenu/HistoricalSection/selectors";
import { NavbarTour } from "components/organisms/Tour/components/NavbarTour";
import { useNavbarPosition } from "hooks";
import { logoutUser } from "pages/Login/redux/reducer";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { STRINGIFY_OPTIONS } from "pages/Reports/utils/validateParams/utils/adjustObjectProperty";
import { validateParamsPerReport } from "pages/Reports/utils/validateParams/validateParamsPerReport";
import {
  isAgreementAcceptedSelector,
  userEmailSelector
} from "store/reducers/userReducer";
import { queryParamsSelector } from "store/selectors/routerSelectors";
import {
  APP_PATHS,
  BOOL_STRING_VALUES,
  CLIENT_TYPES,
  PRIMARY_NAVBAR_HEIGHT
} from "utils";
import { QP } from "utils/defaultQueryParams";

import { MenuItem, PRIMARY_MENU_ITEMS, SecondaryMenuType } from "./constants";
import {
  useFilteredItems,
  useInsightsBadge,
  useKnowledgeBaseBadge,
  useNewsBadge,
  useSecondaryMenu
} from "./hooks";
import s from "./navbar.module.scss";

export const Navbar = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAgreementAccepted = useSelector(isAgreementAcceptedSelector);
  const email = useSelector(userEmailSelector);
  const params = useSelector(queryParamsSelector);
  const primary = useFilteredItems(PRIMARY_MENU_ITEMS);
  const secondary = useSecondaryMenu();
  const insightsBadge = useInsightsBadge();
  const knowledgeBaseBadge = useKnowledgeBaseBadge();
  const newsBadge = useNewsBadge();
  const position = useNavbarPosition(PRIMARY_NAVBAR_HEIGHT, 0);

  const isActive = (path: string) => pathname.includes(path);

  const getPrimarySearch = (item: MenuItem) => {
    const search = qs.stringify(params, STRINGIFY_OPTIONS);

    switch (item.path) {
      case APP_PATHS.INSIGHTS:
        return search;
      case APP_PATHS.EXPORT:
      case REPORTS_FULL_PATHS.SELL_PATH:
        return validateParamsPerReport(item.path, search);
      default:
        return "";
    }
  };

  const getSecondarySearch = (item: MenuItem) => {
    if (!isReports) return "";

    let outputParams = { ...params };

    // [PMD-4696]: reset client filter when switching between reports with single/multi choice select
    if (
      String(params[QP.CLIENT] || "").includes(",") &&
      ![
        REPORTS_FULL_PATHS.SELL_PATH,
        REPORTS_FULL_PATHS.SHARES_PATH,
        REPORTS_FULL_PATHS.DYNAMICS_PATH,
        REPORTS_FULL_PATHS.RECEIPTS_PATH,
        REPORTS_FULL_PATHS.SEGMENTS_PATH
      ].includes(item.path)
    ) {
      outputParams = { ...outputParams, [QP.CLIENT]: CLIENT_TYPES.ALL };
    }

    // [PMD-4880]: disable lfl when back data is selected
    if (
      params[QP.LFL] === BOOL_STRING_VALUES.TRUE &&
      params[QP.COMPARE_DATE_FROM] &&
      params[QP.COMPARE_DATE_TO] &&
      BACK_DATA_REPORTS.includes(item.path)
    ) {
      outputParams = { ...outputParams, [QP.LFL]: BOOL_STRING_VALUES.FALSE };
    }

    return validateParamsPerReport(
      item.path,
      qs.stringify(outputParams, STRINGIFY_OPTIONS)
    );
  };

  const getBadge = (item: MenuItem) => {
    switch (item.path) {
      case APP_PATHS.INSIGHTS:
        return insightsBadge;
      case APP_PATHS.KNOWLEDGE_BASE:
        return knowledgeBaseBadge;
      case APP_PATHS.NEWS:
        return newsBadge;
      default:
        return item.badge || "";
    }
  };

  const getSecondaryTestId = (type: SecondaryMenuType) => {
    switch (type) {
      case "reports":
        return "report-nav-button";
      case "news":
        return "news-navbar-item";
      case "admin":
        return "admin-navbar-item";
      default:
        return "";
    }
  };

  const isReports = secondary.type === "reports";
  const secondaryTestId = getSecondaryTestId(secondary.type);

  return (
    <>
      <header>
        <nav className={s.primaryNavbar}>
          <div className={s.primaryNavbarContent}>
            <Link to="/" className={s.logoLink}>
              <Logo />
            </Link>
            {isAgreementAccepted && !!primary.length ? (
              <ul className={s.menu}>
                {primary.map(item => {
                  const search = getPrimarySearch(item);
                  const badge = getBadge(item);

                  return (
                    <li
                      key={`primary-menu-item-${item.label}`}
                      className={s.menuItem}
                    >
                      {badge ? <span className={s.badge}>{badge}</span> : null}
                      <Link
                        to={`${item.path}?${search}`}
                        data-testid={`main-navbar-item-${item.label}`}
                        className={cn({
                          [s.menuLink]: true,
                          [s.menuLinkActive]: isActive(
                            item.activePath ?? item.path
                          )
                        })}
                      >
                        {item.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
          <div className={s.primaryNavbarContent}>
            <span className={s.email}>{email}</span>
            {isAgreementAccepted ? (
              <Link
                to={APP_PATHS.ADMIN}
                data-testid="settings-button"
                className={s.settingsButton}
              >
                <SettingsOutline />
              </Link>
            ) : null}
            <Button
              testId="logout-button"
              className={s.logoutButton}
              onClick={() => dispatch(logoutUser())}
            >
              Wyloguj
            </Button>
          </div>
        </nav>
        {isAgreementAccepted && !!secondary.items.length ? (
          <nav
            className={cn({
              [s.secondaryNavbar]: true,
              [s.secondaryNavbarFixed]: isReports
            })}
            style={{ top: position }}
          >
            <ul className={s.menu}>
              {secondary.items.map(item => {
                const search = getSecondarySearch(item);

                return (
                  <li
                    key={`secondary-menu-item-${item.label}`}
                    className={s.menuItem}
                  >
                    {item.badge ? (
                      <span className={s.badge}>{item.badge}</span>
                    ) : null}
                    <Link
                      to={`${item.path}?${search}`}
                      data-testid={
                        secondaryTestId
                          ? `${secondaryTestId}-${item.label}`
                          : undefined
                      }
                      className={cn(s.menuLink, {
                        [s.secondaryMenuLinkActive]: isActive(
                          item.activePath ?? item.path
                        )
                      })}
                    >
                      {item.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        ) : null}
      </header>
      <NavbarTour />
    </>
  );
};
