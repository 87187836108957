import { isNil } from "ramda";
import { ThunkAction } from "redux-thunk";

import { AppState } from "store";
import { RESET_DATA, resetData } from "store/actions/appActions";
import { dispatchAll } from "store/actions/dispatchAll";
import { USER_ANALYTICS_COOKIE_NAME, USER_REQUIRED_COOKIE_NAME } from "utils";
import {
  getAnalyticsCookie,
  getUserCookies,
  setUserCookies
} from "utils/cookiesUtils";
import { Keys, Values } from "utils/types";

// TYPES
export type CookiesState = {
  showCookieConsent: boolean;
  allowedCookies: AllowedCookies;
};

type SetShowCookieConsent = {
  type: typeof SET_SHOW_COOKIE_CONSENT;
  payload: boolean;
};

type SetAllowedCookies = {
  type: typeof SET_ALLOWED_COOKIES;
  payload: AllowedCookies;
};

type CookiesActions = {
  SET_SHOW_COOKIE_CONSENT: SetShowCookieConsent;
  SET_ALLOWED_COOKIES: SetAllowedCookies;
};

export type AllowedCookies = typeof USER_COOKIES;

export type AllowedCookiesKeys =
  | typeof USER_REQUIRED_COOKIE_NAME
  | Keys<AllowedCookies>;

// CONST
const USER_COOKIES = getUserCookies();

const SET_SHOW_COOKIE_CONSENT = "Cookies.SET_SHOW_COOKIE_CONSENT" as const;
const SET_ALLOWED_COOKIES = "Cookies.SET_ALLOWED_COOKIES" as const;

// UTILS
const initialShowCookieConsent: boolean = isNil(getAnalyticsCookie());

// ACTIONS
const setShowCookieConsent = (payload: boolean): SetShowCookieConsent => ({
  type: SET_SHOW_COOKIE_CONSENT,
  payload
});

const setAllowedCookies = (payload: AllowedCookies): SetAllowedCookies => ({
  type: SET_ALLOWED_COOKIES,
  payload
});

// THUNKS
export const acceptCookieConsentSettings = (
  allowedCookies: AllowedCookies
): ThunkAction<
  void,
  AppState,
  undefined,
  SetShowCookieConsent | SetAllowedCookies
> => dispatch => {
  setUserCookies(allowedCookies[USER_ANALYTICS_COOKIE_NAME]);

  dispatch(
    dispatchAll([
      setShowCookieConsent(false),
      setAllowedCookies(allowedCookies)
    ])
  );
};

export const saveCookiesSettings = (
  allowedCookies: AllowedCookies
): ThunkAction<void, AppState, undefined, SetAllowedCookies> => dispatch => {
  setUserCookies(allowedCookies[USER_ANALYTICS_COOKIE_NAME]);

  dispatch(setAllowedCookies(allowedCookies));
};

// REDUCER
const initialState: CookiesState = {
  showCookieConsent: initialShowCookieConsent,
  allowedCookies: USER_COOKIES
};

export const cookiesSettingsReducer = (
  state: CookiesState = initialState,
  action: Values<CookiesActions> | ReturnType<typeof resetData>
) => {
  switch (action.type) {
    case SET_SHOW_COOKIE_CONSENT:
      return {
        ...state,
        showCookieConsent: action.payload
      };
    case SET_ALLOWED_COOKIES:
      return {
        ...state,
        allowedCookies: action.payload
      };

    case RESET_DATA:
      return initialState;

    default:
      return state;
  }
};
