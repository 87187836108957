import { Action, createHook, createStore } from "react-sweet-state";

import { uniq } from "ramda";

import { getSelectedCountLabel } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";

export type DropdownItem = {
  label: string;
  value: number;
};

type CompetingProductsItemsPlacement = "regular" | "inactive-bottom";

type State = {
  all: DropdownItem[];
  selected: DropdownItem[];
  itemsPlacement: CompetingProductsItemsPlacement;
};

type Actions = typeof actions;

export const sortByName = (
  labelA: string,
  labelB: string,
  itemsPlacement: CompetingProductsItemsPlacement
) => {
  if (itemsPlacement === "inactive-bottom") {
    if (labelA.startsWith("!") && !labelB.startsWith("!")) return 1;
    if (!labelA.startsWith("!") && labelB.startsWith("!")) return -1;
  }
  // regular sorting
  const a = labelA.startsWith("!") ? labelA.slice(1).trim() : labelA;
  const b = labelB.startsWith("!") ? labelB.slice(1).trim() : labelB;
  return a.localeCompare(b);
};

const actions = {
  updateAll: (items: DropdownItem[]): Action<State> => ({ setState }) => {
    setState({ all: items });
  },
  updateSelected: (items: DropdownItem[]): Action<State> => ({ setState }) => {
    setState({ selected: items });
  },
  updateItemsPlacement: (
    position: CompetingProductsItemsPlacement
  ): Action<State> => ({ setState }) => {
    setState({ itemsPlacement: position });
  },
  sortItems: (): Action<State> => ({ getState, setState }) => {
    const state = getState();
    if (state.itemsPlacement === "inactive-bottom") {
      setState({
        all: state.all.sort((a, b) =>
          sortByName(a.label, b.label, state.itemsPlacement)
        )
      });
    }
    if (state.itemsPlacement === "regular") {
      setState({
        all: uniq([
          // sort selected first
          ...state.selected.sort((a, b) =>
            sortByName(a.label, b.label, state.itemsPlacement)
          ),
          // then sort all other unselected items
          ...state.all
            .filter(item => !state.selected.some(i => i.value === item.value))
            .sort((a, b) => sortByName(a.label, b.label, state.itemsPlacement))
        ])
      });
    }
  }
};

const CompetingProductsStore = createStore<State, Actions>({
  name: "competingProducts",
  initialState: {
    all: [],
    selected: [],
    itemsPlacement: "regular"
  },
  actions
});

export const CompetingProductsHooks = {
  useCompetingProducts: createHook(CompetingProductsStore),
  useSelectedCountLabel: createHook(CompetingProductsStore, {
    selector: state =>
      getSelectedCountLabel(state.selected.length, state.all.length)
  }),
  useSelectedIds: createHook(CompetingProductsStore, {
    selector: state => state.selected.map(item => String(item.value))
  })
};
