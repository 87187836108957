import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";
import moment from "moment";

import {
  DateDropdownInput,
  DateDropdownMenu,
  Dropdown
} from "components/molecules";
import { DateDropdownMenuChangeFn, DateItem } from "components/molecules/types";
import { useFiltersQP } from "hooks";
import { asyncFiltersSelector } from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useAreTopFiltersSelected } from "pages/Reports/redux/reducers/filters/filtersReducer";
import { CalendarHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCalendar";
import { ExcludedDaysHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useExcludedDays";
import { updateQueryParams } from "store/actions/routerActions";
import { formatCalendarInputDates } from "store/utils/formatCalendarInputDates";
import { DATE_DROPDOWN_INPUT_ID, DATE_DROPDOWN_REGEXP } from "utils";
import { QP } from "utils/defaultQueryParams";
import { Nullable } from "utils/types";

interface Props {
  className?: string;
  label?: string;
}

const DateDropdown = ({ className = "", label = "" }: Props) => {
  const dispatch = useDispatch();
  const {
    excludedDaysQP,
    dateFromQP,
    dateToQP,
    compareDateFromQP,
    compareDateToQP,
    includeLeapYearQP,
    dynamicPeriodCheckedQP,
    dynamicPeriodQP,
    initialDateFromQP,
    initialDateToQP
  } = useFiltersQP();
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const areFiltersActive = useAreTopFiltersSelected();

  const [, actions] = CalendarHooks.useCalendar();
  const [{ regularDateLabel }] = CalendarHooks.useCalendarDatesLabel();
  const [isOpen, setOpen] = React.useState(false);

  const [, excludedDaysActions] = ExcludedDaysHooks.useExcludedDays();

  useEffect(() => {
    excludedDaysActions.getInitialExcludedDaysState(excludedDaysQP);
  }, [excludedDaysQP, excludedDaysActions]);

  useEffect(() => {
    if (isPristine) {
      actions.checkPristineSelections(
        { dateFrom: dateFromQP, dateTo: dateToQP },
        { dateFrom: compareDateFromQP, dateTo: compareDateToQP },
        includeLeapYearQP,
        { dateFrom: initialDateFromQP, dateTo: initialDateToQP },
        dynamicPeriodCheckedQP,
        dynamicPeriodQP
      );
    }
  }, [
    actions,
    compareDateFromQP,
    compareDateToQP,
    dateFromQP,
    dateToQP,
    dynamicPeriodCheckedQP,
    dynamicPeriodQP,
    includeLeapYearQP,
    initialDateFromQP,
    initialDateToQP,
    isPristine
  ]);

  // WORKAROUND this component rerenders immediately when /logout path is pushed to history
  // which causes it gets empty strings as dates effecting in date validation error
  // therefore it should return null if dates are empty strings
  if (!dateFromQP || !dateToQP) return null;

  const getItemForStore = (selectedItem: DateItem) => {
    return {
      dateFrom: selectedItem?.value.dateFrom || "",
      dateTo: selectedItem?.value.dateTo || ""
    };
  };

  const handleInputValueChange = (value?: string) => {
    if (!value && value !== "") {
      return;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, currentTarget } = e;

    if (key === "Enter" && DATE_DROPDOWN_REGEXP.test(currentTarget.value)) {
      const [dateFrom, dateTo] = formatCalendarInputDates(currentTarget.value);
      dispatch(
        updateQueryParams(
          { [QP.DATE_FROM]: dateFrom, [QP.DATE_TO]: dateTo },
          "push"
        )
      );

      // https://github.com/Microsoft/TypeScript/issues/5901#issuecomment-431649653
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };

  const handleStateChange = (changes: StateChangeOptions<DateItem>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      setOpen(true);
    }
  };

  const handleClose = (selectedItem?: Nullable<DateItem>) => {
    setOpen(false);
    if (!selectedItem) {
      return;
    }

    const dateItem = getItemForStore(selectedItem);
    actions.updateSelectedDates(dateItem);
  };

  const handleChange: DateDropdownMenuChangeFn = (
    selectedItem,
    historical,
    dynamicPeriod
  ) => {
    if (!selectedItem) {
      return;
    }

    const dateItem = getItemForStore(selectedItem);
    actions.updateSelectedDates(dateItem);

    const historicalDateItem = getItemForStore(historical.selectedItem);
    actions.updateSelectedHistorical(historicalDateItem);
    actions.updateIncludeLeapYear(historical.includeLeapYear);
    actions.updateHistoricalOpen(historical.isOpen);

    const dynamicPeriodDateItem = getItemForStore(dynamicPeriod.selectedItem);
    actions.updateSelectedDynamicPeriod(dynamicPeriodDateItem);
    actions.updateDynamicPeriodChecked(dynamicPeriod.isChecked);
    actions.updateDynamicPeriod(dynamicPeriod.period);

    handleClose(selectedItem);
  };

  const handlePredefinedOptionChange = (selected: Nullable<DateItem>) => {
    if (!selected) return;

    const dateItem = getItemForStore(selected);
    actions.updateCalendarDates({
      dateFrom: moment(dateItem.dateFrom),
      dateTo: moment(dateItem.dateTo)
    });
  };

  const handleMenuOutsideClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    const target = event.target as HTMLDivElement;
    const preventClose = [
      `#${DATE_DROPDOWN_INPUT_ID}`,
      "#react-joyride-portal",
      "#react-joyride-step-0"
    ].some(selector => target.closest(selector));

    // prevent close on input & onboarding click
    if (preventClose) return;

    setOpen(false);
  };

  return (
    <Dropdown<DateItem>
      // @ts-ignore
      itemToString={() => {}}
      isDisabled={!areFiltersActive}
      className={className}
      label={label}
      isOpen={isOpen}
      onStateChange={handleStateChange}
      input={
        <DateDropdownInput
          toggleOnClick={() => setOpen(!isOpen)}
          onKeyDown={handleKeyDown}
        />
      }
      menu={
        <DateDropdownMenu
          closeDropdown={() => setOpen(false)}
          handleChange={handleChange}
          handleOutsideClick={handleMenuOutsideClick}
        />
      }
      onChange={handlePredefinedOptionChange}
      inputValue={regularDateLabel}
      onInputValueChange={handleInputValueChange}
    />
  );
};

export default DateDropdown;
