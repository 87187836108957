import { Fragment } from "react";
import { useSelector } from "react-redux";

import { TooltipContent } from "api/types";
import { getDataTypeKey } from "api/utils";

import {
  Tooltip,
  TooltipBody,
  TooltipCell,
  TooltipHeader
} from "components/D3/Tooltip/ToolTip";
import s from "components/visualisation/charts/ChartTooltip/chartTooltips.module.scss";
import { useDataTypeSuffix } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import { useGetZappkaSharesSuffix } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import { checkedDataTypes } from "pages/Reports/sections/utils/chartData";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { getPolishLabelForSidebar } from "utils";

type Props = {
  tooltipData: TooltipContent;
};

export const ChartTooltipCell = ({ tooltipData }: Props) => {
  const { header, data } = tooltipData;
  const firstDataType = useSelector(firstChartDataTypeSelector);
  const secondDataType = useSelector(secondChartDataTypeSelector);
  const [suffix1, suffix2] = useDataTypeSuffix();
  const getZappkaSharesSuffix = useGetZappkaSharesSuffix();

  if (!data[0]) return null;

  const dataTypes = checkedDataTypes([firstDataType, secondDataType]).map(
    (dataType, index) => {
      const sharesSuffix = getZappkaSharesSuffix(index);
      const keys = {
        primaryKey: getDataTypeKey({
          dataType,
          suffix: suffix1,
          sharesSuffix
        }),
        secondaryKey: getDataTypeKey({
          dataType,
          suffix: suffix2,
          sharesSuffix,
          isSecondary: true
        })
      };

      return {
        ...keys,
        primaryBase: String(data[0][keys.primaryKey] || ""),
        primaryLastYear: String(data[0][`${keys.primaryKey}_LAST_YEAR`] || ""),
        secondaryBase: String(data[0][keys.secondaryKey] || ""),
        secondaryLastYear: String(
          data[0][`${keys.secondaryKey}_LAST_YEAR`] || ""
        )
      };
    }
  );

  return (
    <Tooltip>
      <TooltipHeader>{header}</TooltipHeader>
      <TooltipBody className={s.cellTooltipBody}>
        {dataTypes.map(dataType => (
          <Fragment key={dataType.primaryKey}>
            <TooltipCell
              label={getPolishLabelForSidebar(dataType.primaryKey)}
              value={dataType.primaryBase}
              valueSecondary={dataType.primaryLastYear}
            />
            {Boolean(dataType.secondaryKey) && (
              <TooltipCell
                label={getPolishLabelForSidebar(dataType.secondaryKey)}
                value={dataType.secondaryBase}
                valueSecondary={dataType.secondaryLastYear}
              />
            )}
          </Fragment>
        ))}
      </TooltipBody>
    </Tooltip>
  );
};
