import { Accordion } from "components/organisms";
import { useFiltersSectionHeader } from "hooks/useFiltersSectionHeader";
import { useIntitialFiltersSectionOpen } from "hooks/useInitialFilterSectionOpen";
import { pushFiltersSectionToggleEvent } from "utils/googleTagManager/dataLayer";

import s from "./filtersSectionToggle.module.scss";

type Props = {
  children: React.ReactNode;
  sectionKey: string;
  refToScroll?: React.RefObject<HTMLElement>;
};

export const FiltersSectionToggle = ({
  children,
  sectionKey,
  refToScroll
}: Props) => {
  const isInitiallyOpen = useIntitialFiltersSectionOpen(sectionKey);
  const sectionHeader = useFiltersSectionHeader(sectionKey);

  const onToggle = (isOpen: boolean) => {
    const gTagValue = `${sectionHeader.label} - ${
      isOpen ? "zwinięcie" : "rozwinięcie"
    }`;
    pushFiltersSectionToggleEvent(gTagValue);
  };

  return (
    <Accordion
      sectionKey={sectionKey}
      refToScroll={refToScroll}
      headerText={sectionHeader.label}
      isInitiallyOpen={isInitiallyOpen}
      onToggle={onToggle}
      className={{ headerTextOpen: s.headerTextOpen }}
    >
      {children}
    </Accordion>
  );
};
