import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const Clear: React.FC<Props> = ({
  className,
  color = "#CA1D08",
  size = ICON_SIZES.NORMAL
}) => {
  const iconSize = SIZES[size];

  return (
    <svg
      className={className}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 12 12"
      version="1.1"
    >
      <g
        id="icons/remove-small"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M11.25,0 L0.75,0 C0.3,0 0,0.3 0,0.75 L0,11.25 C0,11.7 0.3,12 0.75,12 L11.25,12 C11.7,12 12,11.7 12,11.25 L12,0.75 C12,0.3 11.7,0 11.25,0 Z M8.625,7.575 L7.575,8.625 L6,7.05 L4.425,8.625 L3.375,7.575 L4.95,6 L3.375,4.425 L4.425,3.375 L6,4.95 L7.575,3.375 L8.625,4.425 L7.05,6 L8.625,7.575 Z"
          id="Shape"
          fill={color}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};
