import { Fragment } from "react";
import { useSelector } from "react-redux";

import { TooltipContent } from "api/types";
import { getDataTypeKey } from "api/utils";

import {
  Tooltip,
  TooltipBody,
  TooltipHeader
} from "components/D3/Tooltip/ToolTip";
import s from "components/visualisation/charts/ChartTooltip/chartTooltips.module.scss";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { useDataTypeSuffix } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import { useGetZappkaSharesSuffix } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import { checkedDataTypes } from "pages/Reports/sections/utils/chartData";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { getPolishLabelForSidebar } from "utils";

type Props = {
  tooltipData: TooltipContent;
  className?: string;
};

export const ChartTooltipTable = ({ tooltipData, className }: Props) => {
  const { header, data, date } = tooltipData;
  const firstDataType = useSelector(firstChartDataTypeSelector);
  const secondDataType = useSelector(secondChartDataTypeSelector);
  const { both: isBothDatasets } = useCurrentDataset();
  const [suffix1, suffix2] = useDataTypeSuffix();
  const getZappkaSharesSuffix = useGetZappkaSharesSuffix();

  if (!data[0] || !date) return null;

  const dataTypes = checkedDataTypes([firstDataType, secondDataType]).map(
    (dataType, index) => {
      const sharesSuffix = getZappkaSharesSuffix(index);
      return {
        primaryKey: getDataTypeKey({
          dataType,
          suffix: suffix1,
          sharesSuffix
        }),
        secondaryKey: getDataTypeKey({
          dataType,
          suffix: suffix2,
          sharesSuffix,
          isSecondary: true
        })
      };
    }
  );

  return (
    <Tooltip className={className} testId="chart-tooltip-wrapper">
      <TooltipHeader testId="chart-tooltip-header">{header}</TooltipHeader>
      <TooltipBody className={s.cellTooltipBody}>
        <div className={s.tableWrapper}>
          <table className={s.table}>
            <thead>
              <tr>
                <th>Segment</th>
                {dataTypes.map(({ primaryKey, secondaryKey }) => (
                  <Fragment key={`head-${primaryKey}`}>
                    <th>{getPolishLabelForSidebar(primaryKey)}</th>
                    {Boolean(secondaryKey) && (
                      <th>{getPolishLabelForSidebar(secondaryKey)}</th>
                    )}
                  </Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((datum, index) => {
                return (
                  <tr key={`row-${index}`}>
                    <td>{datum.itemLabel}</td>
                    {dataTypes.map(({ primaryKey, secondaryKey }) => {
                      return (
                        <Fragment key={`row-${index}-${primaryKey}`}>
                          <td>
                            <p>{datum[primaryKey]}</p>
                            {isBothDatasets && (
                              <p className={s.historical}>
                                {datum[`${primaryKey}_LAST_YEAR`]}
                              </p>
                            )}
                          </td>
                          {Boolean(secondaryKey) && (
                            <td>
                              <p>{datum[secondaryKey]}</p>
                              {isBothDatasets && (
                                <p className={s.historical}>
                                  {datum[`${secondaryKey}_LAST_YEAR`]}
                                </p>
                              )}
                            </td>
                          )}
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </TooltipBody>
    </Tooltip>
  );
};
