import * as React from "react";

import cn from "classnames";

import s from "./button.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  testId?: string;
  children: React.ReactNode;
  className?: string;
  isDisabled?: boolean;
}

export const Button = ({
  onClick = () => {},
  testId,
  children,
  className,
  isDisabled,
  type = "button",
  ...rest
}: Props) => (
  <button
    data-testid={testId}
    onClick={onClick}
    className={cn(s.base, className)}
    disabled={isDisabled}
    type={type}
    {...rest}
  >
    {children}
  </button>
);
