import { useSelector } from "react-redux";

import { useGetReceiptsByPeriod } from "api/receipts/mappers/useGetReceiptsByPeriod";
import { transformSummary } from "api/utils";

import { useSummaryDataTypeSuffix } from "components/organisms/ReportSummary/hooks/useSummaryClientTabs";
import { ReceiptsSummary } from "pages/Reports/types/summary";
import {
  createSummaryItemData,
  SUMMARY_SUB_SWITCH,
  SUMMARY_SWITCH
} from "pages/Reports/utils";
import { NUMBER_TYPE } from "pages/Reports/utils/formatNumber";
import { isPowerUserSelector } from "store/reducers/userReducer";
import {
  productsQueryParamSelector,
  uppercasedReceiptsSummaryTabSelector,
  uppercasedSummarySubTypeSelector
} from "store/selectors/routerSelectors";
import { ONLY_SINGLE_SKU_VISIBLE, REPORTS_SUMMARY_LABELS } from "utils";

const KEYS_WITH_SUFFIX = [
  "itemsPerReceipt",
  "receiptsQuantity",
  "receiptsPerShop",
  "shareInReceiptsCategory",
  "shareInReceiptsTotalShop",
  "worthPerReceipt",
  "netQuantityPerReceipt"
].flatMap(key => [key, `${key}Change`, `${key}LastYear`]);

export const useReceiptsSummary = () => {
  const summarySubType = useSelector(uppercasedSummarySubTypeSelector);
  const type = useSelector(uppercasedReceiptsSummaryTabSelector);
  const productsQueryParam = useSelector(productsQueryParamSelector);
  const isPowerUser = useSelector(isPowerUserSelector);

  const { data } = useGetReceiptsByPeriod();
  const suffix = useSummaryDataTypeSuffix();
  const isReferenceType = summarySubType === SUMMARY_SUB_SWITCH.REF_CATEGORY;
  const rawSummary = isReferenceType
    ? data?.referenceSummary
    : data?.ownSummary;

  if (!rawSummary) {
    return [];
  }

  const summary = transformSummary<ReceiptsSummary>(
    rawSummary,
    KEYS_WITH_SUFFIX,
    suffix
  );

  const isOneProductSelected =
    typeof productsQueryParam === "string" && !!productsQueryParam.length;

  const distributionTabItems = isReferenceType
    ? []
    : [
        {
          label: REPORTS_SUMMARY_LABELS.AVG_SHOPS_COUNT_WITH_LINEBREAK,
          firstValue: summary.avgShopsCount,
          firstValueType: isOneProductSelected
            ? NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
            : NUMBER_TYPE.NOT_APPLICABLE,
          secondValue: isOneProductSelected
            ? summary.avgShopsCountChange
            : null,
          secondValueType: NUMBER_TYPE.PERCENT,
          thirdValue: isOneProductSelected
            ? summary.avgShopsCountLastYear
            : null,
          thirdValueType: NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
          change: isOneProductSelected ? summary.avgShopsCountChange : 0,
          tooltipText: !isOneProductSelected ? ONLY_SINGLE_SKU_VISIBLE : ""
        },
        {
          label: REPORTS_SUMMARY_LABELS.DISTRIBUTION_RANGE,
          firstValue: summary.distributionRange,
          firstValueType: isOneProductSelected
            ? NUMBER_TYPE.PERCENT
            : NUMBER_TYPE.NOT_APPLICABLE,
          secondValue: isOneProductSelected
            ? summary.distributionRangeChange
            : null,
          secondValueType: NUMBER_TYPE.PERCENT_POINTS,
          thirdValue: isOneProductSelected
            ? summary.distributionRangeLastYear
            : null,
          thirdValueType: NUMBER_TYPE.PERCENT,
          change: isOneProductSelected ? summary.distributionRangeChange : 0,
          tooltipText: !isOneProductSelected ? ONLY_SINGLE_SKU_VISIBLE : ""
        },
        ...(isPowerUser
          ? [
              {
                label: REPORTS_SUMMARY_LABELS.AVG_PLANOGRAM_SHOPS_COUNT,
                firstValue: summary.avgPlanogramShopsCount,
                firstValueType: isOneProductSelected
                  ? NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
                  : NUMBER_TYPE.NOT_APPLICABLE,
                secondValue: isOneProductSelected
                  ? summary.avgPlanogramShopsCountChange
                  : null,
                secondValueType: NUMBER_TYPE.PERCENT,
                thirdValue: isOneProductSelected
                  ? summary.avgPlanogramShopsCountLastYear
                  : null,
                thirdValueType: NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
                change: isOneProductSelected
                  ? summary.avgPlanogramShopsCountChange
                  : 0,
                tooltipText: !isOneProductSelected
                  ? ONLY_SINGLE_SKU_VISIBLE
                  : ""
              },
              {
                label: REPORTS_SUMMARY_LABELS.PLANOGRAM_DISTRIBUTION_RANGE,
                firstValue: summary.planogramDistributionRange,
                firstValueType: isOneProductSelected
                  ? NUMBER_TYPE.PERCENT
                  : NUMBER_TYPE.NOT_APPLICABLE,
                secondValue: isOneProductSelected
                  ? summary.planogramDistributionRangeChange
                  : null,
                secondValueType: NUMBER_TYPE.PERCENT_POINTS,
                thirdValue: isOneProductSelected
                  ? summary.planogramDistributionRangeLastYear
                  : null,
                thirdValueType: NUMBER_TYPE.PERCENT,
                change: isOneProductSelected
                  ? summary.planogramDistributionRangeChange
                  : 0,
                tooltipText: !isOneProductSelected
                  ? ONLY_SINGLE_SKU_VISIBLE
                  : ""
              }
            ]
          : [])
      ];

  const mainTabItems = [
    {
      label: REPORTS_SUMMARY_LABELS.SHARE_IN_RECEIPTS_CATEGORY,
      firstValue: summary.shareInReceiptsCategory,
      firstValueType:
        summary.shareInReceiptsCategory || 0 < 1
          ? NUMBER_TYPE.PERCENT_FOUR_DECIMALS
          : NUMBER_TYPE.PERCENT,
      secondValue: summary.shareInReceiptsCategoryChange,
      secondValueType:
        summary.shareInReceiptsCategoryChange || 0 < 1
          ? NUMBER_TYPE.PERCENT_POINTS_FOUR_DECIMALS
          : NUMBER_TYPE.PERCENT_POINTS,
      thirdValue: summary.shareInReceiptsCategoryLastYear,
      change: summary.shareInReceiptsCategoryChange
    },
    {
      label: REPORTS_SUMMARY_LABELS.SHARE_IN_RECEIPTS_TOTAL_SHOP,
      firstValue: summary.shareInReceiptsTotalShop,
      firstValueType:
        summary.shareInReceiptsTotalShop || 0 < 1
          ? NUMBER_TYPE.PERCENT_FOUR_DECIMALS
          : NUMBER_TYPE.PERCENT,
      secondValue: summary.shareInReceiptsTotalShopChange,
      secondValueType:
        summary.shareInReceiptsTotalShopChange || 0 < 1
          ? NUMBER_TYPE.PERCENT_POINTS_FOUR_DECIMALS
          : NUMBER_TYPE.PERCENT_POINTS,
      thirdValue: summary.shareInReceiptsTotalShopLastYear,
      change: summary.shareInReceiptsTotalShopChange
    },
    {
      label: REPORTS_SUMMARY_LABELS.ITEMS_PER_RECEIPT,
      firstValue: summary.itemsPerReceipt,
      firstValueType: NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
      secondValue: summary.itemsPerReceiptChange,
      secondValueType: NUMBER_TYPE.PERCENT,
      thirdValue: summary.itemsPerReceiptLastYear,
      change: summary.itemsPerReceiptChange
    },
    {
      label: REPORTS_SUMMARY_LABELS.WORTH_PER_RECEIPT,
      firstValue: summary.worthPerReceipt,
      firstValueType: NUMBER_TYPE.GROUPED_PRICE,
      secondValue: summary.worthPerReceiptChange,
      secondValueType: NUMBER_TYPE.PERCENT,
      thirdValue: summary.worthPerReceiptLastYear,
      change: summary.worthPerReceiptChange
    },
    {
      label: REPORTS_SUMMARY_LABELS.RECEIPTS_PER_SHOP,
      firstValue: summary.receiptsPerShop,
      firstValueType: NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
      secondValue: summary.receiptsPerShopChange,
      secondValueType: NUMBER_TYPE.PERCENT,
      thirdValue: summary.receiptsPerShopLastYear,
      change: summary.receiptsPerShopChange
    },
    {
      label: REPORTS_SUMMARY_LABELS.RECEIPTS_QUANTITY,
      firstValue: summary.receiptsQuantity,
      firstValueType: NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
      secondValue: summary.receiptsQuantityChange,
      secondValueType: NUMBER_TYPE.PERCENT,
      thirdValue: summary.receiptsQuantityLastYear,
      change: summary.receiptsQuantityChange
    }
  ];

  const naturalUnitsTabItems = [
    {
      label: REPORTS_SUMMARY_LABELS.NET_QUANTITY_PER_RECEIPT,
      firstValue: summary.netQuantityPerReceipt,
      firstValueType: NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
      secondValue: summary.netQuantityPerReceiptChange,
      secondValueType: NUMBER_TYPE.PERCENT,
      thirdValue: summary.netQuantityPerReceiptLastYear,
      change: summary.netQuantityPerReceiptChange
    }
  ];

  if (type === SUMMARY_SWITCH.RECEIPTS_METRICS) {
    return createSummaryItemData(mainTabItems);
  }

  if (type === SUMMARY_SWITCH.DISTRIBUTION) {
    return createSummaryItemData(distributionTabItems);
  }

  if (type === SUMMARY_SWITCH.NATURAL_UNITS) {
    return createSummaryItemData(naturalUnitsTabItems);
  }

  return [];
};
