import { ReactNode } from "react";

import { Dispatch } from "redux";

import {
  SetGenericAlertMessageAction,
  SettingErrorMessageAction,
  SettingSuccessMessageAction,
  ToggleChartSidebarAction,
  ToggleFilterSidebarAction,
  ToggleSegmentsLegendAction,
  UpdateChartSidebarWidthAction,
  UpdateChartWidthAction,
  UpdateItemsPerChartAction
} from "store/types/appTypes";

export const RESIZE_WINDOW = "RESIZE_WINDOW" as const;
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE" as const;
export const RESET_DATA = "RESET_DATA" as const;
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE" as const;
export const TOGGLE_FILTER_SIDEBAR = "TOGGLE_FILTER_SIDEBAR" as const;
export const TOGGLE_CHART_SIDEBAR = "TOGGLE_CHART_SIDEBAR" as const;
export const TOGGLE_SEGMENTS_LEGEND = "TOGGLE_SEGMENTS_LEGEND" as const;
export const SET_GENERIC_ALERT_MESSAGE = "SET_GENERIC_ALERT_MESSAGE" as const;
export const UPDATE_ITEMS_PER_CHART = "Ranking.UPDATE_ITEMS_PER_CHART" as const;
export const UPDATE_CHART_SIDEBAR_WIDTH = "UPDATE_CHART_SIDEBAR_WIDTH" as const;
export const UPDATE_CHART_WIDTH = "UPDATE_CHART_WIDTH" as const;

export const updateItemsPerChart = (
  payload: number
): UpdateItemsPerChartAction => ({
  type: UPDATE_ITEMS_PER_CHART,
  payload
});

export const setResizedWindowDimensions = (payload: {
  windowWidth: Number;
  windowHeight: Number;
}) => (dispatch: Dispatch) => {
  dispatch({
    type: RESIZE_WINDOW,
    payload
  });
};

export const setErrorMessage = (
  payload: ReactNode
): SettingErrorMessageAction => ({
  type: SET_ERROR_MESSAGE,
  payload
});

export const setSuccessMessage = (
  payload: ReactNode
): SettingSuccessMessageAction => ({
  type: SET_SUCCESS_MESSAGE,
  payload
});

export const toggleFilterSidebar = (
  payload: boolean
): ToggleFilterSidebarAction => ({
  type: TOGGLE_FILTER_SIDEBAR,
  payload
});

export const toggleSegmentsLegend = (
  payload: boolean
): ToggleSegmentsLegendAction => ({
  type: TOGGLE_SEGMENTS_LEGEND,
  payload
});

export const toggleChartSidebar = (
  payload: boolean
): ToggleChartSidebarAction => ({
  type: TOGGLE_CHART_SIDEBAR,
  payload
});

export const setGenericAlertMessage = (
  payload: ReactNode
): SetGenericAlertMessageAction => ({
  type: SET_GENERIC_ALERT_MESSAGE,
  payload
});

export const updateChartSidebarWidth = (
  payload: number | string
): UpdateChartSidebarWidthAction => ({
  type: UPDATE_CHART_SIDEBAR_WIDTH,
  payload
});

export const updateChartWidth = (payload: number): UpdateChartWidthAction => ({
  type: UPDATE_CHART_WIDTH,
  payload
});

export const resetData = () => ({ type: RESET_DATA });
