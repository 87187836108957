import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const Settings: React.FC<Props> = ({
  className,
  color = "#6D89B6",
  size = ICON_SIZES.NORMAL
}) => {
  const iconSize = SIZES[size];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 14 14"
    >
      <path
        fill={color}
        d="M11.637 4.55l.963-1.837-1.225-1.226-1.838.963c-.262-.175-.612-.263-.962-.35l-.7-2.1h-1.75l-.7 2.013c-.263.087-.612.175-.875.35L2.713 1.4 1.4 2.713l.962 1.837c-.175.263-.262.612-.35.875L0 6.125v1.75l2.013.7c.087.35.262.613.35.963L1.4 11.374 2.625 12.6l1.838-.963c.262.175.612.263.962.35l.7 2.013h1.75l.7-2.012c.35-.088.613-.263.963-.35l1.837.962 1.225-1.225-.963-1.838c.175-.262.263-.612.35-.962l2.013-.7v-1.75l-2.012-.7c-.088-.263-.175-.612-.35-.875zM7 9.625C5.513 9.625 4.375 8.488 4.375 7c0-1.487 1.138-2.625 2.625-2.625 1.488 0 2.625 1.138 2.625 2.625 0 1.488-1.137 2.625-2.625 2.625z"
      />
    </svg>
  );
};
