import { ScaleLinear } from "d3";

import { ChartData, ChartTimePoint } from "components/D3/types";
import { useChartScales } from "pages/Reports/partials/Chart/StandardChart/utils";
import {
  useChartValueTiles,
  ValueTileData
} from "pages/Reports/partials/Chart/StandardChart/utils/useChartValueTiles";
import { NO_DATA_SHORT } from "utils";
import { round } from "utils/round";

import { ChartValueSingleTile } from "./ChartValueSingleTile";

const timeline2tiles = ({
  timeline,
  tiles,
  yScale,
  bandwidth
}: {
  timeline: ChartTimePoint[];
  tiles: ValueTileData[];
  yScale: ScaleLinear<number, number>;
  bandwidth: number;
}) => {
  return timeline
    .map((dataset, index) => {
      const tile = tiles.find(tile => {
        return tile.lineName.startsWith(dataset.valueX);
      });
      if (!tile) {
        return { x: 0, y: 0, display: "", tileData: null };
      }
      return {
        x: index * bandwidth + bandwidth / 2,
        y: yScale(dataset.valueY || 0),
        display:
          dataset.valueY === null ? NO_DATA_SHORT : round(dataset.valueY),
        tileData: tile
      };
    })
    .filter(tile => {
      return tile.tileData !== null;
    });
};

const Tile = ({
  x,
  y,
  display,
  color,
  onClick
}: {
  x: number;
  y: number;
  display: string;
  color: string;
  onClick: () => void;
}) => {
  return (
    <foreignObject x={x} y={y} width={40} height={20} overflow="visible">
      <ChartValueSingleTile
        tile={{
          backgroundColor: color,
          firstValue: display,
          opacity: "1",
          onClick
        }}
      />
    </foreignObject>
  );
};

// tiles for segments right chart data only
export const SegmentsRightChartTiles = ({
  lineChart,
  width
}: {
  lineChart: ChartData;
  width: number;
}) => {
  const { yScaleRight, xScaleBand } = useChartScales(lineChart, width);
  const [tilesState, tilesActions] = useChartValueTiles();
  const data = lineChart.rightChart?.chart;
  const tiles = {
    base: tilesState.tiles.filter(tile => {
      return tile.isRightChart && !tile.isSecondary && !tile.isHistorical;
    }),
    historical: tilesState.tiles.filter(tile => {
      return tile.isRightChart && !tile.isSecondary && tile.isHistorical;
    })
  };

  if (!data?.length || (!tiles.base.length && !tiles.historical.length)) {
    return null;
  }

  const bandwidth = xScaleBand.bandwidth();
  const baseTiles = timeline2tiles({
    timeline: data[0].timeline,
    tiles: tiles.base,
    yScale: yScaleRight,
    bandwidth
  });
  const historicalTiles = timeline2tiles({
    timeline: data[0].lastYearTimeline ?? [],
    tiles: tiles.historical,
    yScale: yScaleRight,
    bandwidth
  });

  if (!baseTiles.length && !historicalTiles.length) {
    return null;
  }

  return (
    <>
      {baseTiles.map((tile, index) => (
        <Tile
          key={`base-tile-${index}`}
          x={tile.x}
          y={tile.y}
          display={tile.display}
          color={data[0].color}
          onClick={() => {
            tilesActions.handleTiles([tile.tileData!]);
          }}
        />
      ))}
      {historicalTiles.map((tile, index) => (
        <Tile
          key={`historical-tile-${index}`}
          x={tile.x}
          y={tile.y}
          display={tile.display}
          color={data[0].color}
          onClick={() => {
            tilesActions.handleTiles([tile.tileData!]);
          }}
        />
      ))}
    </>
  );
};
