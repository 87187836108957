import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";

import { Button } from "components/atoms";
import { Close } from "components/atoms/Icon";
import { ChartDropdownIndex } from "components/molecules/ChartHeader/ChartHeader";
import { MegaDropdownLevels } from "components/molecules/ChartHeader/hooks/useMegaDropdown";
import { useMultipackDropdown } from "components/molecules/ChartHeader/hooks/useMultipackDropdown";
import { Props as FilterDropdownMenuProps } from "components/molecules/FilterDropdownMenu/FilterDropdownMenu";
import { DropdownItem } from "components/molecules/types";
import { Tour, ZAPPKA_SHARES_CHECKBOX_STEPS } from "components/organisms/Tour";
import { TourHooks } from "components/organisms/Tour/hooks/useTours";
import { isClientTypeMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import s from "pages/Reports/sections/NewChartDropdown/chartDropdown.module.scss";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { BOOL_STRING_VALUES, ICON_SIZES, isThisPage, TOURS_KEYS } from "utils";
import { pushChartSwitchEvent } from "utils/googleTagManager/dataLayer";

import { ChartDropdownColumn } from "./ChartDropdownColumn";
import { MetricsDetailsModal } from "./MetricsDetailsModal/MetricsDetailsModal";
import { MultipackGroup } from "./MultipackGroup";
import { useShowZappkaSharesTour } from "./ZappkaSharesCheckbox/hooks";
import { ZappkaSharesCheckbox } from "./ZappkaSharesCheckbox/ZappkaSharesCheckbox";

const TEST_IDS = {
  menu: "chart-dropdown-menu",
  close: "chart-dropdown-close",
  accept: "chart-dropdown-accept"
};

type Props = {
  checkedDataType: ChartDataTypes;
  onClose: () => void;
  handleChange: (type: ChartDataTypes) => void;
  dropdownIndex: ChartDropdownIndex;
};

const { IS_ZAPPKA_SHARES_TOUR_HIDDEN } = TOURS_KEYS;
const { TRUE } = BOOL_STRING_VALUES;

export const ChartDropdownBox = ({
  onClose,
  checkedDataType,
  handleChange,
  dropdownIndex
}: Props & FilterDropdownMenuProps<DropdownItem<ChartDataTypes>>) => {
  const [isMetricsModalOpen, setMetricsModalOpen] = useState(false);
  const pathname = useSelector(pathnameSelector);
  const showZappkaSharesCheckbox = useSelector(isClientTypeMultiChoiceSelector);
  const [, actions] = TourHooks.useTours();
  const multipackMetrics = useMultipackDropdown();
  const showZappkaSharesTour = useShowZappkaSharesTour();
  const isLoyaltyPage = isThisPage(pathname, REPORTS_FULL_PATHS.LOYALTY_PATH);

  const openModal = () => {
    pushChartSwitchEvent("loyalty - additional info about metrics");
    setMetricsModalOpen(true);
  };

  return (
    <>
      <OutsideClickHandler onOutsideClick={onClose}>
        <div className={s.chartDropdownMenu} data-testid={TEST_IDS.menu}>
          <button
            className={s.closeButton}
            type="button"
            onClick={onClose}
            data-testid={TEST_IDS.close}
          >
            <Close size={ICON_SIZES.EXTRA_SMALL} />
          </button>
          {multipackMetrics.length > 0 ? (
            <MultipackGroup
              rows={multipackMetrics}
              checkedDataType={checkedDataType}
              handleChange={handleChange}
              dropdownIndex={dropdownIndex}
            />
          ) : (
            <div className={s.chartDropdownGroup}>
              {Object.values(MegaDropdownLevels).map(level => (
                <ChartDropdownColumn
                  key={level}
                  level={level}
                  checkedDataType={checkedDataType}
                  handleChange={handleChange}
                  dropdownIndex={dropdownIndex}
                />
              ))}
            </div>
          )}
          <div className={s.bottomWrapper}>
            {isLoyaltyPage && (
              <Button
                onClick={openModal}
                type="button"
                className={s.buttonLink}
              >
                Pokaż szczegółowe definicje miar
              </Button>
            )}
            {showZappkaSharesCheckbox && (
              <ZappkaSharesCheckbox dropdownIndex={dropdownIndex} />
            )}
            <Button
              className={s.acceptButton}
              onClick={onClose}
              testId={TEST_IDS.accept}
            >
              OK
            </Button>
          </div>
        </div>
        {isMetricsModalOpen && (
          <MetricsDetailsModal onClose={() => setMetricsModalOpen(false)} />
        )}
      </OutsideClickHandler>
      {showZappkaSharesTour ? (
        <Tour
          run
          steps={ZAPPKA_SHARES_CHECKBOX_STEPS}
          onClose={() => {
            localStorage.setItem(IS_ZAPPKA_SHARES_TOUR_HIDDEN, TRUE);
            actions.setSharesCheckboxTourHidden(true);
          }}
          disableScrolling
        />
      ) : null}
    </>
  );
};
