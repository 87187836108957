import { stringify } from "query-string";
import { createSelector } from "reselect";

import { generatePromotionsParams } from "pages/Reports/utils";
import { tierSelector } from "store/reducers/userReducer";
import {
  chartPeriodSelector,
  isCategorySelector,
  pathnameSelector,
  searchStringSelector
} from "store/selectors/routerSelectors";
import { PROMOTIONS_ENABLED_PERIODS } from "utils/constants";

import { promotionsSelector } from "./reportsSelectors";

export const TIERS_WITH_PROMOTIONS = [3, 4];

export const fetchedPromotionsSelector = createSelector(
  promotionsSelector,
  promotions => promotions.fetchedPromotions
);

export const fetchedHistoricalPromotions = createSelector(
  promotionsSelector,
  promotions => promotions.fetchedHistoricalPromotions
);

export const fetchingStatusPromotionsSelector = createSelector(
  promotionsSelector,
  promotions => promotions.isFetching
);

const currentPromotionQueryStringSelector = createSelector(
  promotionsSelector,
  promotions => promotions.query
);

const newPromotionQueryStringSelector = createSelector(
  pathnameSelector,
  searchStringSelector,
  (pathname, search) => {
    const promotionParams = generatePromotionsParams(pathname, search);
    return stringify(promotionParams);
  }
);

export const shouldFetchNewPromotionsSelector = createSelector(
  [
    currentPromotionQueryStringSelector,
    newPromotionQueryStringSelector,
    tierSelector,
    isCategorySelector,
    fetchingStatusPromotionsSelector
  ],
  (
    currentPromotionQueryString,
    newPromotionQueryString,
    tier,
    isCategory,
    isFetching
  ) => {
    return (
      TIERS_WITH_PROMOTIONS.includes(tier) &&
      currentPromotionQueryString !== newPromotionQueryString &&
      isCategory &&
      !isFetching
    );
  }
);

export const arePromotionsDisabledSelector = createSelector(
  chartPeriodSelector,
  chartPeriod => {
    return !PROMOTIONS_ENABLED_PERIODS.includes(chartPeriod);
  }
);
