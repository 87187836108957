import { ParsedQuery } from "query-string";
import { omit } from "ramda";

import { T1_T2_HIDDEN_PERIODS } from "pages/Reports/redux/reducers/periods/constants";
import {
  PERIOD_TYPE,
  TIER_1_HIDDEN_PARAMS,
  TIER_2_HIDDEN_PARAMS,
  TIER_3_HIDDEN_PARAMS,
  TIER_4_HIDDEN_PARAMS
} from "utils";
import { QP } from "utils/defaultQueryParams";
import { Tier } from "utils/types";

const adjustTierPeriodParams = (params: ParsedQuery<string | string[]>) => {
  let newParams = params;

  [QP.CHART_PERIOD, QP.TABLE_PERIOD].forEach(param => {
    if (T1_T2_HIDDEN_PERIODS.includes(String(params[param]).toUpperCase())) {
      newParams[param] = PERIOD_TYPE.MONTHS.toLowerCase();
    }
  });

  return newParams;
};

export const adjustParamsByTier = (
  params: ParsedQuery<string | string[]>,
  tier: Tier
) => {
  const accessibleParams = [
    TIER_1_HIDDEN_PARAMS,
    TIER_2_HIDDEN_PARAMS,
    TIER_3_HIDDEN_PARAMS,
    TIER_4_HIDDEN_PARAMS
  ].map(paramsToHide => omit(paramsToHide, params));

  switch (tier) {
    case 1:
    case 2:
      return {
        ...adjustTierPeriodParams(accessibleParams[tier - 1]),
        tier: String(tier)
      };
    case 3:
    case 4:
      return {
        ...accessibleParams[tier - 1],
        tier: String(tier)
      };
    default:
      return { ...params, tier: String(tier) };
  }
};
