import { useSelector } from "react-redux";

import cn from "classnames";

import { Checkbox } from "components/molecules";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage } from "utils";
import {
  BOOL_STRING_VALUES,
  FEATURE_LOCAL_STORAGE_KEYS,
  FILTER_SECTIONS,
  isMultipackWsaEnabled
} from "utils/constants";

import { FiltersSectionToggle } from "../FiltersSectionToggle/FiltersSectionToggle";
import s from "./superUserSettings.module.scss";

export const SuperUserSettings = () => {
  const isPowerUser = useSelector(isPowerUserSelector);
  const pathname = useSelector(pathnameSelector);
  const isVisible =
    isPowerUser && isThisPage(pathname, REPORTS_FULL_PATHS.MULTIPACK_PATH);

  if (!isVisible) return null;

  return (
    <FiltersSectionToggle sectionKey={FILTER_SECTIONS.SUPER_USER_SETTINGS.key}>
      <Checkbox
        className={{
          base: s.checkbox,
          label: cn(s.checkboxLabel, {
            [s.checkboxLabelChecked]: isMultipackWsaEnabled
          })
        }}
        label="Pokaż miary WSA w osobnej kolumnie"
        tooltipText="Zmiana spowoduje przeładowanie strony"
        isChecked={isMultipackWsaEnabled}
        onChange={() => {
          localStorage.setItem(
            FEATURE_LOCAL_STORAGE_KEYS.MULTIPACK_WSA_METRICS,
            isMultipackWsaEnabled
              ? BOOL_STRING_VALUES.FALSE
              : BOOL_STRING_VALUES.TRUE
          );
          window.location.reload();
        }}
      />
    </FiltersSectionToggle>
  );
};
