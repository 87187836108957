import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { axisLeft, ScaleLinear, select, selectAll } from "d3";

import { ChartDataPart } from "components/D3/types";
import s from "pages/Reports/partials/Chart/StandardChart/grid.module.scss";
import {
  hoveredTimepointPositionSelector,
  pinnedTimepointPositionSelector,
  pinnedTimepointSelector,
  Timepoint,
  updatePinnedTimepointPosition
} from "pages/Reports/redux/reducers/chartReducer";
import { Nullable } from "utils/types";

import { areTicksBothPositiveAndNegative } from "./utils/areTicksBothPositiveAndNegative";
import { useChartHover } from "./utils/useChartHover";

interface Props {
  yScale: ScaleLinear<number, number>;
  gridWidth: number;
  isIndicatorAllowed: boolean;
  tickValues: number[];
  domain: string[];
  leftChart: Nullable<ChartDataPart>;
}

export const INDICATOR_WIDTH = 2;
export const INDICATOR_COLOR = "#888889";

export const getGridPosition = (
  gridWidth: number,
  timepointCount: number,
  timepoint: Timepoint
) => {
  if (timepoint === null) return 0;

  const pxBetweenPoints = gridWidth / timepointCount;
  const halfPointSpace = pxBetweenPoints / 2;

  let position;
  if (timepoint === 0) {
    // position for first timepoint as it's only half of the point-space from the left
    position = (timepoint + 1) * halfPointSpace;
  } else {
    // position for all other timepoints on axis
    position = (timepoint + 1) * pxBetweenPoints - halfPointSpace;
  }

  return position;
};

export const Grid: React.FC<Props> = ({
  domain,
  yScale,
  gridWidth,
  tickValues,
  isIndicatorAllowed,
  leftChart
}) => {
  const dispatch = useDispatch();
  const gridRef = React.useRef<SVGGElement | null>(null);

  const [boundHeight, setBoundHeight] = React.useState(0);
  const [boundX, setBoundX] = React.useState(0);

  const pinnedTimepoint = useSelector(pinnedTimepointSelector);
  const pinnedTimepointPosition = useSelector(pinnedTimepointPositionSelector);
  const hoveredTimepointPosition = useSelector(
    hoveredTimepointPositionSelector
  );
  const { onMouseLeave } = useChartHover({});

  const makeZeroLineThick = React.useCallback(() => {
    selectAll("#chart-grid > g.tick")
      .filter(d => d === 0)
      .attr("class", `tick ${s.zeroLine}`);
  }, []);

  const calculatePinnedPosition = useCallback(() => {
    if (pinnedTimepoint === null) {
      dispatch(updatePinnedTimepointPosition(null));
      return;
    }

    const position = getGridPosition(gridWidth, domain.length, pinnedTimepoint);

    dispatch(updatePinnedTimepointPosition(position - INDICATOR_WIDTH));
  }, [gridWidth, dispatch, domain.length, pinnedTimepoint]);

  useEffect(() => {
    calculatePinnedPosition();
  }, [calculatePinnedPosition, pinnedTimepoint]);

  React.useEffect(() => {
    if (gridRef.current) {
      select(gridRef.current)
        .attr("class", s.grid)
        .call(
          axisLeft(yScale)
            .tickValues(tickValues)
            .tickSize(-gridWidth)
            // @ts-ignore To hide default ticks
            .tickFormat(""),
          0
        );

      const areTicksNegativeAndPositive = areTicksBothPositiveAndNegative(
        tickValues
      );
      if (areTicksNegativeAndPositive && leftChart?.dataType) {
        makeZeroLineThick();
      }
    }
    const bounds: Nullable<DOMRect> =
      gridRef?.current?.getBoundingClientRect() || null;

    if (bounds) {
      const { height, x } = bounds;

      height !== boundHeight && setBoundHeight(height);
      x !== boundX && setBoundX(x);
    }
  }, [
    boundHeight,
    gridWidth,
    boundX,
    makeZeroLineThick,
    tickValues,
    yScale,
    leftChart?.dataType
  ]);

  const isIndicatorVisible = Boolean(
    pinnedTimepointPosition ?? hoveredTimepointPosition
  );

  return (
    <g>
      <g id="chart-grid" ref={gridRef} onMouseLeave={onMouseLeave} />
      {isIndicatorAllowed && (
        <>
          <rect
            pointerEvents="all"
            id="chart-grid-rect"
            width={gridWidth}
            height={boundHeight}
            fill="transparent"
          />
          {isIndicatorVisible && (
            <rect
              pointerEvents="none"
              width={INDICATOR_WIDTH}
              fill={INDICATOR_COLOR}
              height={boundHeight}
              transform={`translate(${pinnedTimepointPosition ??
                hoveredTimepointPosition}, 0)`}
            />
          )}
        </>
      )}
    </g>
  );
};
