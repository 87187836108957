import { createSelector } from "reselect";

import { AppState } from "store";

const appSelector = (store: AppState) => store.app;

export const windowWidthSelector = createSelector(
  appSelector,
  app => app.windowWidth
);

export const windowHeightSelector = createSelector(
  appSelector,
  app => app.windowHeight
);

export const errorMessageSelector = createSelector(
  appSelector,
  app => app.errorMessage
);

export const successMessageSelector = createSelector(
  appSelector,
  app => app.successMessage
);

export const isFilterSidebarOpenSelector = createSelector(
  appSelector,
  app => app.isFilterSidebarOpen
);

export const isSegmentsLegendOpenSelector = createSelector(
  appSelector,
  app => app.isSegmentsLegendOpen
);

export const isChartSidebarOpenSelector = createSelector(
  appSelector,
  app => app.isChartSidebarOpen
);

export const genericAlertMessageSelector = createSelector(
  appSelector,
  app => app.genericAlertMessage
);

export const itemsPerChartSelector = createSelector(
  appSelector,
  ({ itemsPerChart }) => itemsPerChart
);

export const chartSidebarWidthSelector = createSelector(
  appSelector,
  ({ chartSidebarWidth }) => chartSidebarWidth
);

export const chartWidthSelector = createSelector(
  appSelector,
  ({ chartWidth }) => chartWidth
);
