import * as React from "react";

import c from "components/atoms/CheckExport/checkExport.module.scss";

type Props = {
  onClick: () => void;
};
export const CheckExport: React.FC<Props> = ({ onClick }) => (
  <div>
    <p>
      Przygotowujemy plik z danymi, poinformujemy Cię mailowo, jak tylko będzie
      gotowy.
    </p>
    <p>
      Możesz kontynuować przeglądanie ACIT lub przejść do zakładki{" "}
      <button type="button" onClick={onClick} className={c.button}>
        Eksport danych
      </button>
      .
    </p>
  </div>
);
