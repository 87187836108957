import * as React from "react";

import { Checkbox } from "components/molecules";
import {
  AllowedCookies,
  AllowedCookiesKeys
} from "pages/Admin/redux/reducers/cookiesSettingsReducer";
import { USER_ANALYTICS_COOKIE_NAME, USER_REQUIRED_COOKIE_NAME } from "utils";
import { PRIVACY_POLICY_URL, TRUSTED_PARTNERS_URL } from "utils/constants";

import s from "./cookieConsent.module.scss";

interface CookieContent {
  type: AllowedCookiesKeys;
  title: string;
  description: string;
}

interface Props {
  settings: AllowedCookies;
  onChange: (type: AllowedCookiesKeys) => void;
}

export const cookiesContent: CookieContent[] = [
  {
    type: USER_REQUIRED_COOKIE_NAME,
    title: "Cookies serwisowe/podstawowe",
    description: `Pliki cookie, które są niezbędne do świadczenia usług drogą elektroniczną
    oraz poprawy jakości tych usług. Możemy używać plików cookies
    i technologii monitorowania w także celu poprawy bezpieczeństwa i administrowania systemem.
    Serwisowe pliki cookie są niezbędne do działania strony.`
  },
  {
    type: USER_ANALYTICS_COOKIE_NAME,
    title: "Cookies analityczne",
    description: `Analityczne pliki cookies służą do oceny wydajności 
    “Żabka Advanced Customer Insight Tool” i poprawy jakości 
    treści oferowanych za jego pośrednictwem.  
    Administrator wykorzystuje analityczne pliki cookies również 
    w celach analitycznych i statystycznych`
  }
];

const CookieConsentSettings: React.FC<Props> = ({ settings, onChange }) => {
  const isTypeChecked = (type: AllowedCookiesKeys) => {
    if (type === USER_REQUIRED_COOKIE_NAME) return true;

    return settings[type];
  };

  return (
    <>
      <p className={s.details}>
        Poniżej możesz zarządzać swoimi preferencjami w zakresie instalowania
        analitycznych plików cookies na Twoim urządzeniu w związku z
        korzystaniem z tej strony. Możesz także zapoznać się z listą naszych{" "}
        <a
          href={TRUSTED_PARTNERS_URL}
          className={s.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Zaufanych Partnerów
        </a>{" "}
        i dowiedzieć się, jakie pliki cookies są instalowane przez poszczególne
        podmioty.
      </p>
      <ul>
        {cookiesContent.map(({ type, title, description }) => {
          const isDisabled = type === USER_REQUIRED_COOKIE_NAME;
          const isChecked = isTypeChecked(type);

          return (
            <li className={s.cookieItem} key={type}>
              <label className={s.cookieClickableArea}>
                <Checkbox
                  isDisabled={isDisabled}
                  isChecked={isChecked}
                  onChange={() => onChange(type)}
                />
                <span className={s.cookieLabel}>{title}</span>
              </label>
              <p>{description}</p>
            </li>
          );
        })}
      </ul>
      <p className={s.details}>
        <span>
          Więcej informacji o przetwarzaniu danych osobowych, w tym o
          przysługujących Ci prawach, znajduje się w naszej{" "}
        </span>
        <a
          href={PRIVACY_POLICY_URL}
          className={s.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Polityce Prywatności
        </a>
        <span>.</span>
      </p>
    </>
  );
};

export default CookieConsentSettings;
