import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TooltipIcon } from "components/atoms/Icon";
import { useGetDisabledDataTypeTooltip } from "components/molecules/ChartCheckboxDropdown/hooks";
import { useDataTypes } from "components/molecules/ChartHeader/hooks";
import {
  MegaDropdownLevels,
  useMegaDropdown
} from "components/molecules/ChartHeader/hooks/useMegaDropdown";
import { useMultipackDropdown } from "components/molecules/ChartHeader/hooks/useMultipackDropdown";
import { isSidebarMetricEnabledSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { updateQueryParams } from "store/actions/routerActions";
import {
  firstChartDataTypeSelector,
  queryParamsSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { getPolishLabelForChartHeader } from "utils";
import { QP } from "utils/defaultQueryParams";

import s from "./sidebarMetricSelect.module.scss";

const useGetDisabledMetric = () => {
  const firstType = useSelector(firstChartDataTypeSelector);
  const secondType = useSelector(secondChartDataTypeSelector);
  const getDisabledTooltip = useGetDisabledDataTypeTooltip();

  return (metric: string) => {
    return (
      [firstType, secondType].includes(metric) ||
      getDisabledTooltip(metric).isDisabled
    );
  };
};

export const useSidebarMetric = () => {
  const dispatch = useDispatch();
  const params = useSelector(queryParamsSelector);
  const enabled = useSelector(isSidebarMetricEnabledSelector);
  const getDisabledMetric = useGetDisabledMetric();

  // metrics for each report except multipack and location
  const metrics1 = useMegaDropdown(MegaDropdownLevels.first);
  const metrics2 = useMegaDropdown(MegaDropdownLevels.second);
  const metrics3 = useMegaDropdown(MegaDropdownLevels.third); // for loyalty
  const metrics4 = useMegaDropdown(MegaDropdownLevels.fourth); // for logistics
  const metrics5 = useMegaDropdown(MegaDropdownLevels.fifth); // for logistics

  // metrics for location report
  const { all: locationMetrics } = useDataTypes();

  // metrics for multipack report
  const multipackMetrics = useMultipackDropdown();

  const [
    options1,
    options2,
    options3,
    options4,
    options5,
    location1,
    location2
  ] = useMemo(() => {
    return [
      metrics1.items,
      metrics2.items,
      metrics3.items,
      metrics4.items,
      metrics5.items,
      locationMetrics.first,
      locationMetrics.second
    ].map(metrics =>
      metrics
        .map(metric => ({
          value: metric,
          disabled: getDisabledMetric(metric)
        }))
        .filter(option => Boolean(option.value))
    );
  }, [
    metrics1.items,
    metrics2.items,
    metrics3.items,
    metrics4.items,
    metrics5.items,
    locationMetrics.first,
    locationMetrics.second,
    getDisabledMetric
  ]);

  const multipack = useMemo(() => {
    return multipackMetrics.map(item => ({
      label: item.label,
      options: item.metrics.map(metric => ({
        value: metric.value,
        disabled: metric.disabled || getDisabledMetric(metric.value)
      }))
    }));
  }, [multipackMetrics, getDisabledMetric]);

  if (!enabled) {
    return { groups: [], value: "", onChange: () => {} };
  }

  let value = String(params[QP.SIDEBAR_METRIC] || "")
    .trim()
    .toUpperCase();
  const found = [
    options1,
    options2,
    options3,
    options4,
    options5,
    location1,
    location2,
    ...multipack.map(group => group.options)
  ]
    .flat()
    .find(option => option.value === value);

  if (!found || found.disabled) {
    value = "";
  }

  return {
    groups: [
      { label: metrics1.text, options: options1 },
      { label: metrics2.text, options: options2 },
      { label: metrics3.text, options: options3 },
      { label: metrics4.text, options: options4 },
      { label: metrics5.text, options: options5 },
      { label: "Miary podstawowe:", options: location1 },
      { label: "Miary dodatkowe:", options: location2 },
      ...multipack
    ],
    value,
    onChange: (value: string) => {
      dispatch(updateQueryParams({ [QP.SIDEBAR_METRIC]: value.toLowerCase() }));
    }
  };
};

const OptionsGroup = ({
  label,
  options
}: {
  label: string;
  options: { value: string; disabled: boolean }[];
}) => {
  return !options.length ? null : (
    <optgroup label={label}>
      {options.map(option => (
        <option
          key={option.value}
          value={option.value}
          disabled={option.disabled}
        >
          {getPolishLabelForChartHeader(option.value)}
        </option>
      ))}
    </optgroup>
  );
};

export const SidebarMetricSelect = () => {
  const sidebarMetric = useSidebarMetric();

  return (
    <div>
      <div className={s.labelWrapper}>
        <label htmlFor="sidebar-metric-select" className={s.label}>
          Dodaj miarę
        </label>
        <span className={s.tooltip}>
          <TooltipIcon />
        </span>
      </div>
      <select
        id="sidebar-metric-select"
        className={s.select}
        value={sidebarMetric.value}
        onChange={event => {
          sidebarMetric.onChange(event.target.value);
        }}
      >
        <option value="">Brak miary</option>
        {sidebarMetric.groups.map((group, index) => (
          <OptionsGroup
            key={`group-${index}`}
            label={group.label}
            options={group.options}
          />
        ))}
      </select>
    </div>
  );
};
