import { useSelector } from "react-redux";

import {
  FILTER_NAMES,
  FILTERS
} from "pages/Reports/redux/reducers/filters/hooks/constants";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  isPowerUserSelector,
  tierSelector,
  userRoleSelector,
  userSelector
} from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage, USER_ROLES } from "utils";
import { APP_PATHS, isReferenceBrandsFilterEnabled } from "utils/constants";
import { RESTRICTIONS } from "utils/restrictions";

type Filter = {
  name: string;
  accessLevels: number[];
  roles: string[];
  page: string[];
};

const filtersKeyMap = {
  [REPORTS_FULL_PATHS.SELL_PATH]: "sales",
  [REPORTS_FULL_PATHS.SHARES_PATH]: "shares",
  [REPORTS_FULL_PATHS.DYNAMICS_PATH]: "dynamics",
  [REPORTS_FULL_PATHS.RECEIPTS_PATH]: "receipts",
  [REPORTS_FULL_PATHS.SEGMENTS_PATH]: "segments",
  [REPORTS_FULL_PATHS.HOURS_PATH]: "hours",
  [REPORTS_FULL_PATHS.LOYALTY_PATH]: "loyalty",
  [REPORTS_FULL_PATHS.MULTIPACK_PATH]: "multipack",
  [APP_PATHS.EXPORT]: "export"
};

const useRestricted = (filters: Filter[]) => {
  const user = useSelector(userSelector);
  const pathname = useSelector(pathnameSelector);
  const restrictions = RESTRICTIONS.find(
    ({ id }) => id === user.company?.warehouseId
  );

  if (!restrictions) return filters;

  const key = pathname in filtersKeyMap ? filtersKeyMap[pathname] : "";
  if (!key) return filters;

  const enabled = restrictions.enabled.filters[key];
  if (enabled?.length) {
    return filters.filter(({ name }) => enabled.includes(name));
  }

  const disabled = restrictions.disabled.filters[key];
  if (disabled?.length) {
    return filters.filter(({ name }) => !disabled.includes(name));
  }

  return filters;
};

const usePageVisibility = (filters: Filter[]) => {
  const pathname = useSelector(pathnameSelector);

  return filters.filter(filter =>
    filter.page.some(page => pathname.includes(page))
  );
};

const useRoleVisibility = (filters: Filter[]) => {
  const userRole = useSelector(userRoleSelector);

  return filters.filter(filter => filter.roles.includes(userRole));
};

const useTierVisibility = (filters: Filter[]) => {
  const tier = useSelector(tierSelector);
  const role = useSelector(userRoleSelector);

  const generalFilters = filters.filter(filter =>
    filter.accessLevels.includes(tier)
  );

  const rolesToCheck = [USER_ROLES.ADMIN, USER_ROLES.ANALYST] as string[];
  if (tier === 4 && rolesToCheck.includes(role)) {
    const competitorFilters: string[] = [
      FILTER_NAMES.COMPETITOR_COMPANY,
      FILTER_NAMES.COMPETITOR_BRAND,
      FILTER_NAMES.COMPETITOR_SUB_BRAND,
      FILTER_NAMES.COMPETITOR_PRODUCT,
      FILTER_NAMES.COMPETITOR_AGGREGATION
    ];
    return generalFilters.filter(
      filter => !competitorFilters.includes(filter.name)
    );
  }
  return generalFilters;
};

const useShopTypeVisibility = (filters: Filter[]) => {
  // [PMD-5245]: temporarily disable segments
  // const pathname = useSelector(pathnameSelector);
  // const isLocationPage = pathname.includes(REPORTS_PATHS.LOCATION);
  const isPowerUser = useSelector(isPowerUserSelector);

  const hiddenFilters: string[] = [
    FILTER_NAMES.STORE_TYPE,
    FILTER_NAMES.STORE_TYPE_META
  ];

  // if (isLocationPage) {
  //   return isPowerUser
  //     ? filters
  //     : filters.filter(filter => !hiddenFilters.includes(filter.name));
  // }

  // return filters;

  return isPowerUser
    ? filters
    : filters.filter(({ name }) => !hiddenFilters.includes(name));
};

const useReferenceBrandsFiltersVisibilty = (filters: Filter[]) => {
  if (isReferenceBrandsFilterEnabled) {
    return filters;
  }

  return filters.filter(filter => filter.name !== FILTER_NAMES.REFERENCE_BRAND);
};

const useAttributesVisibility = (filters: Filter[]) => {
  const pathname = useSelector(pathnameSelector);
  const isPowerUser = useSelector(isPowerUserSelector);

  if (isThisPage(pathname, APP_PATHS.EXPORT) && !isPowerUser) {
    return filters.filter(filter => filter.name !== FILTER_NAMES.ATTRIBUTES);
  }

  return filters;
};

export const useVisibleList = (): { name: string }[] => {
  const restrictedFilters = useRestricted(FILTERS);
  const pageFilters = usePageVisibility(restrictedFilters);
  const roleFilters = useRoleVisibility(pageFilters);
  const tierFilters = useTierVisibility(roleFilters);
  const shopTypeFilters = useShopTypeVisibility(tierFilters);
  const referenceBrandsFilters = useReferenceBrandsFiltersVisibilty(
    shopTypeFilters
  );
  const attributesFilters = useAttributesVisibility(referenceBrandsFilters);

  return attributesFilters;
};
