import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useSegmentsTooltip } from "api/segments/mappers/useSegmentsTooltip";
import { TooltipContent } from "api/types";
import { axisLeft, ScaleLinear, select } from "d3";

import { SegmentsTooltip } from "pages/Reports/partials/Chart/ReportBasedTooltip/SegmentsTooltip";
import s from "pages/Reports/partials/Chart/StandardChart/grid.module.scss";
import {
  hoveredTimepointPositionSelector,
  pinnedTimepointSelector,
  updatePinnedTimepointPosition
} from "pages/Reports/redux/reducers/chartReducer";
import { Nullable } from "utils/types";

import { INDICATOR_COLOR, INDICATOR_WIDTH } from "./Grid";
import { useChartHover } from "./utils/useChartHover";

interface Props {
  yScale: ScaleLinear<number, number>;
  width: number;
  isLineChart: boolean;
  showTooltip: boolean;
  tickValues: number[];
  domain: string[];
  id: string;
  positionY: number;
}

export const SegmentsGrid: React.FC<Props> = ({
  yScale,
  width,
  tickValues,
  isLineChart,
  domain,
  id,
  showTooltip,
  positionY
}) => {
  const dispatch = useDispatch();
  const pinnedTimepoint = useSelector(pinnedTimepointSelector);
  const hoveredTimepointPosition = useSelector(
    hoveredTimepointPositionSelector
  );
  const { onMouseLeave } = useChartHover({});
  const gridRef = React.useRef<Nullable<SVGGElement>>(null);

  const [boundHeight, setBoundHeight] = React.useState(0);
  const [boundWidth, setBoundWidth] = React.useState(0);
  const [boundX, setBoundX] = React.useState(0);
  const [boundY, setBoundY] = React.useState(0);

  const segmentsTooltip = useSegmentsTooltip();

  const tooltip: Nullable<TooltipContent> = segmentsTooltip
    ? segmentsTooltip.find(segment => segment?.name === id) ?? null
    : null;
  const isTooltipVisible = showTooltip && Boolean(tooltip);

  const pinnedPosition = React.useMemo(() => {
    if (pinnedTimepoint === null) {
      return null;
    }

    const width = boundWidth / domain.length;
    return width * pinnedTimepoint + width / 2;
  }, [boundWidth, domain.length, pinnedTimepoint]);

  const indicatorPosition = React.useMemo(() => {
    return pinnedTimepoint === null ? hoveredTimepointPosition : pinnedPosition;
  }, [hoveredTimepointPosition, pinnedPosition, pinnedTimepoint]);

  const tooltipPosition = React.useMemo(() => {
    if (pinnedTimepoint !== null) {
      const offset = window.scrollY + boundY;

      return {
        x: indicatorPosition || 0,
        y: offset + boundHeight / 2
      };
    }

    return {
      x: indicatorPosition || 0,
      y: positionY
    };
  }, [boundHeight, boundY, indicatorPosition, pinnedTimepoint, positionY]);

  // draw ticks
  React.useEffect(() => {
    if (gridRef.current) {
      select(gridRef.current)
        .attr("class", s.grid)
        .call(
          axisLeft(yScale)
            .tickValues(tickValues)
            .tickSize(-width)
            // @ts-ignore To hide default ticks
            .tickFormat(""),
          0
        );
    }
  }, [tickValues, width, yScale]);

  // set bounds on resize
  React.useEffect(() => {
    if (gridRef.current) {
      const bounds = gridRef.current.getBoundingClientRect();

      setBoundWidth(bounds.width);
      setBoundHeight(bounds.height);
      setBoundX(bounds.x);
      setBoundY(bounds.y);
    }
  }, [width]);

  // update y position on scroll
  React.useEffect(() => {
    const handleScroll = () => {
      if (gridRef.current) {
        const bounds = gridRef.current?.getBoundingClientRect();
        setBoundY(bounds.y);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  // update pinned position on change
  React.useEffect(() => {
    dispatch(updatePinnedTimepointPosition(pinnedPosition));
  }, [pinnedPosition, dispatch]);

  return (
    <g>
      <g id="chart-grid" pointerEvents="none" ref={gridRef} />
      {isTooltipVisible && (
        <SegmentsTooltip
          positionX={tooltipPosition.x}
          positionY={tooltipPosition.y}
          tooltipData={tooltip}
          boundX={boundX}
        />
      )}
      {isLineChart && (
        <>
          <rect
            width={boundWidth}
            height={boundHeight}
            pointerEvents="all"
            fill="transparent"
            id="chart-grid-rect"
            onMouseLeave={onMouseLeave}
            data-segment-id={id}
          />
          {indicatorPosition !== null && (
            <rect
              pointerEvents="none"
              width={INDICATOR_WIDTH}
              fill={INDICATOR_COLOR}
              height={boundHeight}
              transform={`translate(${indicatorPosition}, 0)`}
            />
          )}
        </>
      )}
    </g>
  );
};
