import { ReactNode } from "react";

import { Alert as AlertIcon } from "components/atoms/Icon/Alert";
import { Alert } from "components/molecules";
import { ICON_SIZES } from "utils";

import s from "./errorAlert.module.scss";

type Props = {
  children: ReactNode;
  onClose: () => void;
};

export const ErrorAlert = ({ children, onClose }: Props) => {
  return (
    <Alert
      className={{
        alert: s.errorAlert,
        text: s.errorInterior,
        icon: s.closeIcon
      }}
      onClose={onClose}
    >
      <AlertIcon size={ICON_SIZES.EXTRA_LARGE} className={s.alertIcon} />
      {children}
    </Alert>
  );
};
