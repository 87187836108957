import { useFiltersQP } from "hooks";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";

interface Options {
  isGroupedBarChart?: boolean;
  isHistoricalGroupedChart?: boolean;
}

export const useChartTopLabel = ({
  isHistoricalGroupedChart = false,
  isGroupedBarChart = false
}: Options) => {
  const {
    dateFromQP,
    dateToQP,
    compareDateFromQP,
    compareDateToQP
  } = useFiltersQP();
  const dataset = useCurrentDataset();

  const formattedDateFrom = dateFromQP.replace(/-/g, ".");
  const formattedDateTo = dateToQP.replace(/-/g, ".");
  const formattedCompareDateFrom = compareDateFromQP.replace(/-/g, ".");
  const formattedCompareDateTo = compareDateToQP.replace(/-/g, ".");

  const regularDateLabel = `${formattedDateFrom} - ${formattedDateTo}`;
  const compareDateLabel = formattedCompareDateFrom
    ? `${formattedCompareDateFrom} - ${formattedCompareDateTo}`
    : "";

  const shouldShowBothDates =
    dataset.both && compareDateLabel && !isGroupedBarChart;

  const wholeDateLabel = (() => {
    if (isHistoricalGroupedChart) return compareDateLabel;
    if (shouldShowBothDates)
      return `${regularDateLabel} vs ${compareDateLabel}`;
    return dataset.historical ? compareDateLabel : regularDateLabel;
  })();

  return {
    wholeDateLabel,
    regularDateLabel,
    compareDateLabel
  };
};
