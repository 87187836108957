import { endOfMonth, startOfMonth, subDays, subMonths } from "date-fns";

import { TIERS_WITH_CURRENT_MONTH_DISABLED } from "components/organisms/DateDropdown/utils";
import { newDateByTimezone } from "store/utils";
import { Tier } from "utils/types";

export const getCalendarRange = (categoryStartDate: Date, tier: Tier) => {
  const isCurrentMonthDisabled = TIERS_WITH_CURRENT_MONTH_DISABLED.includes(
    tier
  );
  const minDate = startOfMonth(categoryStartDate);
  const maxDate = isCurrentMonthDisabled
    ? endOfMonth(subMonths(newDateByTimezone(), 1))
    : subDays(newDateByTimezone(), 1);

  return [minDate, maxDate];
};
