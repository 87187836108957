import { useSelector } from "react-redux";

import qs from "query-string";
import { pick } from "ramda";

import { isHistoricalDataEnabledSelector } from "components/molecules/DateDropdownMenu/HistoricalSection/selectors";
import { useVisibleList } from "pages/Reports/redux/reducers/filters/hooks/useVisibleFilters";
import { sortClientType } from "pages/Reports/redux/reducers/sweetStateHooks/useClientType";
import { isClientTypeMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { STRINGIFY_OPTIONS } from "pages/Reports/utils/validateParams/utils/adjustObjectProperty";
import {
  pathnameSelector,
  queryParamsSelector
} from "store/selectors/routerSelectors";
import { BOOL_STRING_VALUES, isThisPage } from "utils";
import { QP } from "utils/defaultQueryParams";

import { filtersParamsMap } from "./constants";

const useValidParams = () => {
  const params = { ...useSelector(queryParamsSelector) }; // copy is needed to prevent overriding
  const isClientTypeMultiChoice = useSelector(isClientTypeMultiChoiceSelector);
  const clientQP = String(params[QP.CLIENT] || "");
  const groupBySegmentsQP = String(params[QP.GROUP_BY_SEGMENTS] || "");

  // if client type is single choice, then only first value is taken
  if (!isClientTypeMultiChoice) {
    params[QP.CLIENT] = clientQP.split(",").sort(sortClientType)[0];
  }

  // if group by segments is not boolean, then set it to "false"
  if (
    ![BOOL_STRING_VALUES.TRUE, BOOL_STRING_VALUES.FALSE].includes(
      groupBySegmentsQP
    )
  ) {
    params[QP.GROUP_BY_SEGMENTS] = BOOL_STRING_VALUES.FALSE;
  }

  return params;
};

export const useReportQueryString = () => {
  const params = useValidParams();
  const pathname = useSelector(pathnameSelector);
  const isHistoricalDataEnabled = useSelector(isHistoricalDataEnabledSelector);
  const isClientTypeMultiChoice = useSelector(isClientTypeMultiChoiceSelector);
  const filters = useVisibleList().map(({ name }) => name);

  // include params related to filters
  const paramsToPick = filters
    .flatMap(filter => filtersParamsMap[filter])
    .filter(Boolean);

  // include historical data params
  if (isHistoricalDataEnabled) {
    paramsToPick.push(
      ...[QP.COMPARE_DATE_FROM, QP.COMPARE_DATE_TO, QP.INCLUDE_LEAP_YEAR]
    );
  }

  // include client type if filter is single choice
  if (!isClientTypeMultiChoice) {
    paramsToPick.push(QP.CLIENT);
  }

  // include segments params
  if (isThisPage(pathname, REPORTS_FULL_PATHS.SEGMENTS_PATH)) {
    paramsToPick.push(QP.GROUP_BY_SEGMENTS);
  }

  // other params shared between reports
  paramsToPick.push(...[QP.EXCLUDED_DAYS, QP.INSIGHT_ID]);

  return qs.stringify(pick(paramsToPick, params), STRINGIFY_OPTIONS);
};
