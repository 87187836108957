import { motion } from "framer-motion";

import s from "./LinearProgressBar.module.scss";

interface Props {
  duration?: number;
}

const LinearProgressBar = ({ duration = 4 }: Props) => {
  const animate = {
    scaleX: 0,
    transition: { duration }
  };

  return (
    <div className={s.outerBar}>
      <motion.div
        style={{ originX: "left" }}
        animate={animate}
        className={s.innerBar}
      />
    </div>
  );
};

export default LinearProgressBar;
