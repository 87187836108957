import { CHART_DATA_TYPE, METRIC_TYPE } from "utils/constants";
import { Values } from "utils/types";

export const dataTypeToMetricType = (
  dataType: Values<typeof CHART_DATA_TYPE>
) => {
  switch (dataType) {
    case CHART_DATA_TYPE.VALUE:
    case CHART_DATA_TYPE.VALUE_PER_SHOP:
    case CHART_DATA_TYPE.AVG_PRICE:
    case CHART_DATA_TYPE.WORTH_PER_RECEIPT:
      return METRIC_TYPE.PRICE;

    case CHART_DATA_TYPE.TRANSACTIONS:
    case CHART_DATA_TYPE.SHOPS:
    case CHART_DATA_TYPE.ITEMS_PER_RECEIPT:
    case CHART_DATA_TYPE.RECEIPTS_PER_SHOP:
    case CHART_DATA_TYPE.RECEIPTS_QUANTITY:
    case CHART_DATA_TYPE.AVG_SHOPS_COUNT:
      return METRIC_TYPE.ABSOLUTE;

    case CHART_DATA_TYPE.VALUE_SHARES:
    case CHART_DATA_TYPE.VALUE_DYNAMICS:
    case CHART_DATA_TYPE.QUANTITY_DYNAMICS:
    case CHART_DATA_TYPE.QUANTITY_SHARES:
    case CHART_DATA_TYPE.SHARE_IN_RECEIPTS_CATEGORY:
    case CHART_DATA_TYPE.SHARE_IN_RECEIPTS_TOTAL_SHOP:
    case CHART_DATA_TYPE.DISTRIBUTION_RANGE:
    case CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE:
    case CHART_DATA_TYPE.DYNAMICS_NET_QUANTITY_CHANGE:
      return METRIC_TYPE.PERCENT;

    case CHART_DATA_TYPE.QUANTITY:
    case CHART_DATA_TYPE.QUANTITY_PER_SHOP:
      return METRIC_TYPE.PIECE;

    default:
      return METRIC_TYPE.ABSOLUTE;
  }
};
