import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { format } from "date-fns";

import { CalendarHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCalendar";
import { tierSelector } from "store/reducers/userReducer";
import { DEFAULT_DATE_FORMAT } from "utils";
import { Nullable } from "utils/types";

import {
  CALENDAR_OPTIONS,
  filterOptionByTier,
  isItemActive,
  mapOptionToItem
} from "./utils";

type Key = keyof typeof CALENDAR_OPTIONS;
type Item = ReturnType<typeof mapOptionToItem>;

export const usePresets = (minDate: Date, maxDate: Date) => {
  const tier = useSelector(tierSelector);
  const [{ calendar, isDynamicPeriodChecked }] = CalendarHooks.useCalendar();
  const [isChecked, setChecked] = useState(isDynamicPeriodChecked);
  const [clickedItem, setClickedItem] = useState<Nullable<Item>>(null);

  const keys = Object.keys(CALENDAR_OPTIONS) as Key[];

  const calendarDates = useMemo(() => {
    return {
      dateFrom: calendar.dateFrom?.toDate() || null,
      dateTo: calendar.dateTo?.toDate() || null
    };
  }, [calendar]);

  const items = useMemo(() => {
    return keys
      .filter(key => filterOptionByTier(key, tier))
      .map(key => mapOptionToItem(key, minDate, maxDate));
  }, [keys, maxDate, minDate, tier]);

  const activeItems = items.filter(item =>
    isItemActive(
      item.dateFrom,
      item.dateTo,
      calendarDates.dateFrom,
      calendarDates.dateTo
    )
  );

  // in case there's more than one active item select the clicked one
  const selectedItem = activeItems.length
    ? activeItems.length > 1
      ? clickedItem
      : activeItems[0]
    : null;

  const dataForStore = useMemo(() => {
    if (!isChecked || !selectedItem) {
      return {
        selectedItem: { label: "", value: { dateFrom: "", dateTo: "" } },
        isChecked,
        period: ""
      };
    }

    const { id, dateFrom, dateTo } = selectedItem;

    return {
      selectedItem: {
        label: "",
        value: {
          dateFrom: format(dateFrom, DEFAULT_DATE_FORMAT),
          dateTo: format(dateTo, DEFAULT_DATE_FORMAT)
        }
      },
      isChecked,
      period: id
    };
  }, [isChecked, selectedItem]);

  return {
    isChecked,
    setChecked,
    clickedItem,
    setClickedItem,
    items,
    activeItems,
    selectedItem,
    dataForStore
  };
};
