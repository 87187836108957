import * as React from "react";

import cn from "classnames";

import { Text } from "components/atoms";
import { ChevronDown, ChevronUp, Clear } from "components/atoms/Icon";
import { DATE_DROPDOWN_INPUT_ID, INPUT_TYPE, TEXT_WEIGHTS } from "utils";

import { InputText } from "../";
import { PropGetter } from "../types";
import s from "./dateDropdownInput.module.scss";

type ToggleButtonPropsGetter = PropGetter<"getToggleButtonProps">;
type LabelPropGetter = PropGetter<"getLabelProps">;
type InputPropGetter = PropGetter<"getInputProps">;

interface Props {
  isDisabled?: boolean;
  className?: {
    base?: string;
    label?: string;
    toggleButton?: string;
    labelText?: string;
    input?: string;
  };
  getToggleButtonProps?: ToggleButtonPropsGetter;
  getLabelProps?: LabelPropGetter;
  getInputProps?: InputPropGetter;
  inputValue?: string;
  isOpen?: boolean;
  label?: string;
  onInputValueChange?: (value: string) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  openMenu?: () => void;
  toggleOnClick: () => void;
}

export function DateDropdownInput({
  className = {},
  isDisabled = false,
  onClick = () => {},
  onFocus = () => {},
  isOpen = false,
  label,
  onBlur = () => {},
  onInputValueChange = () => {},
  openMenu = () => {},
  inputValue,
  onKeyDown,
  toggleOnClick,
  ...props
}: Props) {
  const getInputProps = props.getInputProps as InputPropGetter;
  const getToggleButtonProps = props.getToggleButtonProps as ToggleButtonPropsGetter;
  const getLabelProps = props.getLabelProps as LabelPropGetter;

  const [showClearButton, setShowClearButton] = React.useState(false);

  const input = React.useRef<HTMLInputElement>(null);
  const clearButton = React.useRef<HTMLButtonElement>(null);

  const handleFocus = (e: React.MouseEvent<HTMLInputElement>) => {
    setShowClearButton(true);
    openMenu();
    onFocus(e);
  };

  const handleBlur = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e.relatedTarget !== clearButton.current) {
      setShowClearButton(false);
    }

    onBlur(e);
  };

  const handleClear = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    onInputValueChange("");
    input.current && input.current.focus();
  };

  const handleLabelClick = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    // prevent focusing input on label click
    // @ts-ignore this is not included in downshift definition but necessary here
    // https://github.com/downshift-js/downshift#customizing-handlers
    e.nativeEvent.preventDownshiftDefault = true;
    e.preventDefault();

    if (isOpen) {
      toggleOnClick();
    }
  };

  return (
    <div id={DATE_DROPDOWN_INPUT_ID} className={cn(s.base, className.base)}>
      {label && (
        <label
          {...getLabelProps({
            className: cn(s.label, className.label),
            onClick: handleLabelClick
          })}
        >
          <Text
            size="small"
            className={cn(s.labelText, className.labelText)}
            weight={TEXT_WEIGHTS.BOLD}
          >
            {label}
          </Text>
        </label>
      )}
      <div className={s.inputContainer}>
        <button
          {...getToggleButtonProps({
            className: cn(s.toggleButton, className.toggleButton),
            disabled: isDisabled
          })}
          onClick={toggleOnClick}
          {...{
            "data-ga-datepicker": isOpen ? "zwiń" : "rozwiń"
          }}
        >
          {isOpen ? <ChevronUp size="large" /> : <ChevronDown size="large" />}
        </button>
        {showClearButton && (
          <button
            className={s.clearButton}
            ref={clearButton}
            onClick={handleClear}
            type="button"
          >
            <Clear />
          </button>
        )}
        <InputText
          {...getInputProps({
            className: {
              input: cn(s.input, className.input, {
                [s.inputWithClearButton]: showClearButton
              })
            },
            disabled: isDisabled,
            onFocus: handleFocus,
            onBlur: handleBlur,
            onClick,
            onKeyDown: onKeyDown,
            ref: input,
            type: INPUT_TYPE.TEXT
          })}
        />
      </div>
    </div>
  );
}
