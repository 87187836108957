import * as React from "react";

import { ROW_HEIGHT } from "../constants";

interface Props {
  index: number;
  setRowSize: (index: number, size: number) => void;
}

export const MeasureRow: React.FC<Props> = ({
  children,
  index,
  setRowSize
}) => {
  const rowRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setRowSize(
      index,
      rowRef.current?.getBoundingClientRect().height || ROW_HEIGHT
    );
  }, [index, setRowSize]);

  return <div ref={rowRef}>{children}</div>;
};
