import { uniq } from "ramda";

import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { APP_PATHS, PERIOD_TYPE, USER_ROLES } from "utils";

import { ChartPeriodSettings, TablePeriodSettings } from "./types";

const {
  SELL_PATH,
  LOCATION_PATH,
  SHARES_PATH,
  DYNAMICS_PATH,
  RECEIPTS_PATH,
  SEGMENTS_PATH,
  HOURS_PATH,
  LOYALTY_PATH,
  MULTIPACK_PATH
} = REPORTS_FULL_PATHS;
const { SUPERUSER, CATMAN, ADMIN, ANALYST } = USER_ROLES;

export const CHART_PERIODS_SETTINGS: ChartPeriodSettings[] = [
  {
    type: PERIOD_TYPE.RANKING,
    tier: [1, 2, 3, 4],
    role: [SUPERUSER, CATMAN, ADMIN, ANALYST],
    report: [
      SELL_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH,
      LOYALTY_PATH,
      MULTIPACK_PATH
    ]
  },
  {
    type: PERIOD_TYPE.TOTAL,
    tier: [1, 2, 3, 4],
    role: [SUPERUSER, CATMAN, ADMIN, ANALYST],
    report: [
      SELL_PATH,
      LOCATION_PATH,
      DYNAMICS_PATH,
      SHARES_PATH,
      RECEIPTS_PATH,
      SEGMENTS_PATH,
      HOURS_PATH,
      APP_PATHS.EXPORT,
      LOYALTY_PATH,
      MULTIPACK_PATH
    ]
  },
  {
    type: PERIOD_TYPE.YEAR,
    tier: [1, 2, 3, 4],
    role: [SUPERUSER, CATMAN, ADMIN, ANALYST],
    report: [
      SELL_PATH,
      LOCATION_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH,
      APP_PATHS.EXPORT,
      SEGMENTS_PATH,
      HOURS_PATH,
      LOYALTY_PATH,
      MULTIPACK_PATH
    ]
  },
  {
    type: PERIOD_TYPE.MONTHS,
    tier: [1, 2, 3, 4],
    role: [SUPERUSER, CATMAN, ADMIN, ANALYST],
    report: [
      SELL_PATH,
      LOCATION_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH,
      APP_PATHS.EXPORT,
      SEGMENTS_PATH,
      HOURS_PATH,
      LOYALTY_PATH,
      MULTIPACK_PATH
    ]
  },
  {
    type: PERIOD_TYPE.PROMO_WEEKS,
    tier: [3, 4],
    role: [SUPERUSER, CATMAN, ADMIN, ANALYST],
    report: [
      SELL_PATH,
      LOCATION_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH,
      APP_PATHS.EXPORT,
      SEGMENTS_PATH,
      HOURS_PATH,
      LOYALTY_PATH,
      MULTIPACK_PATH
    ]
  },
  {
    type: PERIOD_TYPE.WEEKS,
    tier: [3, 4],
    role: [SUPERUSER, CATMAN, ADMIN, ANALYST],
    report: [
      SELL_PATH,
      LOCATION_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH,
      APP_PATHS.EXPORT,
      SEGMENTS_PATH,
      HOURS_PATH,
      LOYALTY_PATH,
      MULTIPACK_PATH
    ]
  },
  {
    type: PERIOD_TYPE.DAYS,
    tier: [3, 4],
    role: [SUPERUSER, CATMAN, ADMIN, ANALYST],
    report: [
      SELL_PATH,
      LOCATION_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH,
      APP_PATHS.EXPORT,
      SEGMENTS_PATH,
      HOURS_PATH,
      LOYALTY_PATH,
      MULTIPACK_PATH
    ]
  }
];

export const TABLE_PERIODS_SETTINGS: TablePeriodSettings[] = [
  {
    type: PERIOD_TYPE.TOTAL,
    tier: [1, 2, 3, 4],
    report: [LOCATION_PATH]
  },
  {
    type: PERIOD_TYPE.YEAR,
    tier: [1, 2, 3, 4],
    report: [
      SELL_PATH,
      LOCATION_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH
    ]
  },
  {
    type: PERIOD_TYPE.MONTHS,
    tier: [1, 2, 3, 4],
    report: [
      SELL_PATH,
      LOCATION_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH
    ]
  },
  {
    type: PERIOD_TYPE.WEEKS,
    tier: [3, 4],
    report: [
      SELL_PATH,
      LOCATION_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH
    ]
  },
  {
    type: PERIOD_TYPE.DAYS,
    tier: [3, 4],
    report: [
      SELL_PATH,
      LOCATION_PATH,
      SHARES_PATH,
      DYNAMICS_PATH,
      RECEIPTS_PATH
    ]
  }
];

const filterPeriodsByTier = (
  settings: ChartPeriodSettings[] | TablePeriodSettings[]
) =>
  settings
    .filter(period => period.tier.every(tier => tier >= 3))
    .map(period => String(period.type));

export const T1_T2_HIDDEN_PERIODS = uniq([
  ...filterPeriodsByTier(CHART_PERIODS_SETTINGS),
  ...filterPeriodsByTier(TABLE_PERIODS_SETTINGS)
]);
