import { Option } from "components/molecules/SelectMenu/SelectMenu";
import { INPUT_TYPE } from "utils";

export const ADMIN_PATHS = {
  USER_MANAGEMENT: "/user-management",
  COOKIES_SETTINGS: "/cookies-settings"
};

export const userColumnIDs = {
  id: "id",
  name: "name",
  email: "email",
  phoneNumber: "phoneNumber",
  isActive: "isActive",
  invitedAt: "invitedAt",
  signedUpAt: "signedUpAt",
  company: "company",
  options: "options"
};

type NewUserInputsIds = "email" | "phoneNumber" | "company" | "role";

export type NewUserData = {
  [K in NewUserInputsIds]: string;
};
interface NewUserInput {
  label: string;
  id: NewUserInputsIds;
  type: string;
  placeholder?: string;
  options?: Option[];
  suggestion?: string;
}

export const NEW_USER_INPUTS: NewUserInput[] = [
  {
    label: "E-mail",
    id: "email",
    type: INPUT_TYPE.TEXT,
    placeholder: "Podaj adres e-mail"
  },
  {
    label: "Telefon komórkowy",
    id: "phoneNumber",
    type: INPUT_TYPE.TEXT,
    placeholder: "Podaj numer telefonu",
    suggestion: "Prawidłowy format: 123123123"
  },
  {
    label: "Firma",
    id: "company",
    type: INPUT_TYPE.SELECT,
    placeholder: "Wybierz firmę"
  },
  {
    label: "Typ konta",
    id: "role",
    type: INPUT_TYPE.SELECT,
    options: [
      {
        label: "Admin",
        value: "1"
      },
      {
        label: "Analityk",
        value: "2"
      },
      {
        label: "Logistyk",
        value: "4"
      }
    ],
    placeholder: "Wybierz typ konta"
  }
];
