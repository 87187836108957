import { FC, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Cell } from "react-table";

import { RowData } from "api/types";
import cn from "classnames";

import { KeyboardHooks } from "hooks/useKeyboardKeys";
import {
  ChartActions,
  HoverLineAction
} from "pages/Reports/redux/reducers/chartReducer";
import { isBetween } from "pages/Reports/utils";
import { isChartSidebarOpenSelector } from "store/selectors/appSelectors";
import { SetGenericAlertMessageAction } from "store/types/appTypes";
import { DATASET_TYPES, TABLE_COLUMN_ITEM_LABEL } from "utils";
import { pushSidebarRowEvent } from "utils/googleTagManager/dataLayer";
import { checkChangeColumn } from "utils/polishLabels/getPolishLabelForSidebar";
import { Thunk, Values } from "utils/types";

import { SidebarMultiselectHooks } from "../hooks/useSidebarMultiselect";
import s from "./cells.module.scss";
import { MainInfoCell } from "./MainInfoCell";

interface Props {
  cell: Cell<RowData>;
  toggleChartDispatch: (labels: string[]) => Thunk<Values<ChartActions>>;
  updateHoveredLineDispatch: (lines: string[]) => HoverLineAction;
  isRestrictedToOneLine: boolean;
  legendLineNames: string[];
  setGenericAlertMessageDispatch: () => SetGenericAlertMessageAction;
  isPowerUser: boolean;
  hoveredLine: string[];
}

export const SidebarDataCell: FC<Props> = ({
  cell,
  toggleChartDispatch,
  updateHoveredLineDispatch,
  isRestrictedToOneLine,
  legendLineNames,
  setGenericAlertMessageDispatch,
  isPowerUser,
  hoveredLine
}) => {
  const isSidebarOpen = useSelector(isChartSidebarOpenSelector);
  const [{ keyDown }] = KeyboardHooks.useKeyboardKeys();
  const [
    { startElement, hoveredElementIndex },
    { setStartElement, setEndElement, setHoveredElementIndex }
  ] = SidebarMultiselectHooks.useSidebarMultiselect();

  const originalItem = cell.row?.original?.item;
  const cellRef = useRef<HTMLDivElement>(null);
  const hoveredLineStr = JSON.stringify(hoveredLine); // stringify to prevent unnecessary rerenders caused by array comparison

  useEffect(() => {
    if (!isSidebarOpen) return;

    const row = cellRef.current?.parentElement?.parentElement;
    if (!row) return;

    const hoveredLine = JSON.parse(hoveredLineStr);
    const hovered = hoveredLine.includes(originalItem.legendId);
    if (!hovered) return;

    row.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start"
    });
  }, [cellRef, hoveredLineStr, isSidebarOpen, originalItem.legendId]);

  if (!originalItem) return null;

  const { legendId, isInHiddenLines } = originalItem;
  const isCopyingDataDisabled =
    originalItem.type === DATASET_TYPES.COMPETITOR && !isPowerUser;

  const firstValue = cell.value?.firstValue;
  const cellIndex = cell.row?.index;
  const cellKey = cell.column?.id;
  const secondValue = cell.row?.original[cellKey]?.secondValue || null;

  const shouldRemoveLeftPadding = checkChangeColumn(String(cellKey));

  const isCellHighlighted =
    (typeof startElement === "number" &&
      typeof hoveredElementIndex === "number" &&
      isBetween(cellIndex, startElement, hoveredElementIndex)) ||
    hoveredElementIndex === cellIndex ||
    hoveredLine.includes(legendId);

  const onMouseUp = () => {
    if (isCopyingDataDisabled) {
      setGenericAlertMessageDispatch();
    }
  };

  const setStartEndElements = () => {
    if (startElement === null) {
      setStartElement(cellIndex);
      return;
    }

    setEndElement(cellIndex);
  };

  const onClick = () => {
    if (isRestrictedToOneLine) {
      toggleChartDispatch([]);
      toggleChartDispatch(legendLineNames);
    }

    if (keyDown.includes("Shift") && !isRestrictedToOneLine) {
      setStartEndElements();
      return;
    }

    toggleChartDispatch([legendId]);
    pushSidebarRowEvent(isInHiddenLines);
  };

  const onMouseEnter = () => {
    setHoveredElementIndex(cellIndex);

    if (!isRestrictedToOneLine) {
      updateHoveredLineDispatch([legendId]);
    }
  };

  const onMouseLeave = () => {
    setHoveredElementIndex(null);
    updateHoveredLineDispatch([]);
  };

  return (
    <div
      className={cn(
        {
          [s.noCopyText]: isCopyingDataDisabled,
          [s.hiddenLinesCell]: isInHiddenLines,
          [s.dataCellHighlighted]: isCellHighlighted,
          [s.noLeftPadding]: shouldRemoveLeftPadding
        },
        s.dataCell
      )}
      onMouseUp={onMouseUp}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={cellRef}
    >
      {cell.column.id === TABLE_COLUMN_ITEM_LABEL ? (
        <MainInfoCell cell={cell} />
      ) : (
        <div className={s.contentCell}>
          <p>{firstValue}</p>
          {secondValue && (
            <p
              className={s.lastYearContent}
              data-testid="sidebar-second-value-content"
            >
              {secondValue}
            </p>
          )}
        </div>
      )}
    </div>
  );
};
