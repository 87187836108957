import { getPageTitle, getPolishLabelForChartHeader } from "utils";

declare global {
  interface Window {
    dataLayer: any;
  }
}

const PATHS_WITH_NO_GTM = ["localhost"];

const initialize = (callback: Function) => {
  if (PATHS_WITH_NO_GTM.includes(window.location.hostname)) return;

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  callback();
};

export const pushPageView = ({
  pathname,
  pagePath,
  userId,
  userType
}: {
  pathname: string;
  pagePath: string;
  userId: string;
  userType: string;
}) =>
  initialize(() => {
    window.dataLayer.push({
      event: "Pageview",
      pagePath,
      pageTitle: getPageTitle(pathname),
      userId,
      userType
    });
  });

export const pushDateGenerate = (from: string, to: string) =>
  initialize(() => {
    window.dataLayer.push({
      event: "datePickerClick",
      action: "generate",
      value: `${from} - ${to}`
    });
  });

export const pushChartTypeChange = (type: string, isChecked: boolean) =>
  initialize(() => {
    window.dataLayer.push({
      event: "chart",
      action: isChecked ? "hide" : "show",
      value: getPolishLabelForChartHeader(type)
    });
  });

export const pushLflFilterHover = () =>
  initialize(() => {
    window.dataLayer.push({
      event: "additionalInfo",
      action: "hover",
      value: "Filtr LFL"
    });
  });

export const pushLogisticsReportAction = (value: string) =>
  initialize(() => {
    window.dataLayer.push({
      event: "logisticsReportAction",
      action: "click",
      value
    });
  });

export const pushSuperExportEvent = (value: string) =>
  initialize(() => {
    window.dataLayer.push({
      event: "dataExportEvent",
      action: "click",
      value
    });
  });

export const pushSidebarEvent = (value: string) =>
  initialize(() => {
    window.dataLayer.push({
      event: "sidePanel",
      action: "click",
      value
    });
  });

export const pushSidebarRowEvent = (isInHiddenLines: boolean) =>
  initialize(() => {
    window.dataLayer.push({
      event: "sidePanel",
      action: "click",
      value: isInHiddenLines ? "row selection" : "row deselection"
    });
  });

export const pushSegmentLegendTabToggleEvent = (
  value: "show Products" | "show Segments"
) =>
  initialize(() => {
    window.dataLayer.push({
      event: "Segments",
      action: "click",
      value
    });
  });

export const pushSegmentsLegendEvent = (value: "show legend" | "hide Legend") =>
  initialize(() => {
    window.dataLayer.push({
      event: "Segments",
      action: "click",
      value
    });
  });

export const pushFiltersSectionToggleEvent = (value: string) =>
  initialize(() => {
    window.dataLayer.push({
      event: "Filtry",
      action: "click",
      value
    });
  });

export const pushRankingEvent = (value: string) =>
  initialize(() => {
    window.dataLayer.push({
      event: "Ranking",
      action: "click",
      value
    });
  });

export const pushSummaryEvent = (value: string) =>
  initialize(() => {
    window.dataLayer.push({
      event: "Podsumowanie",
      action: "click",
      value
    });
  });

export const pushChartSwitchEvent = (value: string) =>
  initialize(() => {
    window.dataLayer.push({ event: "chart", action: "click", value });
  });

export const pushChartMetricsEvent = (
  firstType: string,
  secondType: string,
  isDefault: boolean = false
) =>
  initialize(() => {
    const value = `metrics - ${getPolishLabelForChartHeader(
      firstType.toUpperCase()
    )} + ${getPolishLabelForChartHeader(secondType.toUpperCase())} ${
      isDefault ? "(default)" : ""
    }`;

    window.dataLayer.push({
      event: "chart",
      action: "show",
      value
    });
  });

export const pushDatePickerEvent = (value: string) =>
  initialize(() => {
    window.dataLayer.push({
      event: "datePicker",
      action: "click",
      value: value
    });
  });

export const pushFiltersEvent = (value: string) =>
  initialize(() => {
    window.dataLayer.push({ event: "filters", action: "click", value });
  });

export const pushTableEvent = (value: string) =>
  initialize(() => {
    window.dataLayer.push({ event: "table", action: "click", value });
  });

export const pushLegendEvent = (value: string) =>
  initialize(() => {
    window.dataLayer.push({ event: "chartLegend", action: "click", value });
  });

export const pushKnowledgeBaseEvent = (value: string) =>
  initialize(() => {
    window.dataLayer.push({ event: "knowledgeBase", action: "click", value });
  });

export const pushInsightsAction = (action: "click" | "show", value: string) =>
  initialize(() => {
    window.dataLayer.push({ event: "Insights", action, value });
  });

export const pushMultipackEvent = ({
  action,
  value
}: {
  action: "warning";
  value: string;
}) =>
  initialize(() => {
    window.dataLayer.push({ event: "multipack", action, value });
  });

export const pushReportFetchEvent = (value: string) =>
  initialize(() => {
    window.dataLayer.push({ event: "report", action: "show", value });
  });
