import { FC, useEffect, useState } from "react";

import cn from "classnames";

import { Button, Portal, Text } from "components/atoms";
import { PopupWindow } from "components/molecules";
import { BOOL_STRING_VALUES, TEXT_WRAPPERS } from "utils";

import s from "./onboardingModal.module.scss";

interface Props {
  title: string;
  localStorageKey: string;
  button?: { text?: string; center?: boolean };
  onClose?: VoidFunction;
}

const { TRUE } = BOOL_STRING_VALUES;

export const OnboardingModal: FC<Props> = ({
  children,
  title,
  localStorageKey,
  button = { text: "Rozumiem", center: false },
  onClose
}) => {
  const [isHidden, setHidden] = useState(
    localStorage.getItem(localStorageKey) === TRUE
  );

  useEffect(() => {
    if (isHidden) return;

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isHidden]);

  const handleClick = () => {
    setHidden(true);
    localStorage.setItem(localStorageKey, TRUE);
    if (onClose) onClose();
  };

  if (isHidden) return null;

  return (
    <Portal id="js-onboarding-modal">
      <PopupWindow className={s.overlay}>
        <div className={s.container}>
          <Text className={s.title} Wrapper={TEXT_WRAPPERS.SPAN}>
            {title}
          </Text>
          <div className={s.copy}>{children}</div>
          <Button
            className={cn(s.button, { [s.center]: button.center })}
            onClick={handleClick}
          >
            {button.text}
          </Button>
        </div>
      </PopupWindow>
    </Portal>
  );
};
