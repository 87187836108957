import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useDynamicsOwnTableData } from "api/dynamics/mappers";
import { flatten } from "ramda";

import { isDistributionDisabledSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import {
  DynamicsTableColumn,
  DynamicsTableRow
} from "pages/Reports/sections/Dynamics/redux/types";
import { getRowPeriod } from "pages/Reports/utils";
import { generateTableColumnId } from "pages/Reports/utils/generateTableColumnId";
import { tierSelector } from "store/reducers/userReducer";
import { tablePeriodSelector } from "store/selectors/routerSelectors";
import { NO_DATA, sortTypeByFloatValue, sortTypeByPeriod } from "utils";
import { getPolishLabelForTableTimePeriod } from "utils/polishLabels/getPolishLabelForTableTimePeriod";

export const useDynamicsTableColumns = (): DynamicsTableColumn[] => {
  const period = useSelector(tablePeriodSelector);
  const isDistributionDisabled = useSelector(isDistributionDisabledSelector);
  const tier = useSelector(tierSelector);

  const preparedTableData = useDynamicsOwnTableData();

  const tablePeriodHeader = getPolishLabelForTableTimePeriod(period);

  const columns = useMemo(
    () =>
      flatten(
        preparedTableData.map(({ name }) => {
          const idPrefix = generateTableColumnId(name);
          const distributionColumns: DynamicsTableColumn[] = isDistributionDisabled
            ? []
            : [
                {
                  Header: `${name} - liczba sklepów`,
                  accessor: row => String(row[`${idPrefix}-avgShopsCount`]),
                  id: `${idPrefix}-avgShopsCount`,
                  sortType: sortTypeByFloatValue(`${idPrefix}-avgShopsCount`)
                },
                {
                  Header: `${name} - dystrybucja %`,
                  accessor: row => String(row[`${idPrefix}-distributionRange`]),
                  id: `${idPrefix}-distributionRange`,
                  sortType: sortTypeByFloatValue(
                    `${idPrefix}-distributionRange`
                  )
                }
              ];

          return [
            {
              Header: `${name} - dynamika wart. (%)`,
              accessor: (row: DynamicsTableRow) =>
                String(row[`${idPrefix}-worthChange`] || NO_DATA),
              id: `${idPrefix}-worthChange`,
              sortType: sortTypeByFloatValue(`${idPrefix}-worthChange`)
            },
            {
              Header: `${name} - dynamika ilość. (%)`,
              accessor: (row: DynamicsTableRow) =>
                String(row[`${idPrefix}-quantityChange`] || NO_DATA),
              id: `${idPrefix}-quantityChange`,
              sortType: sortTypeByFloatValue(`${idPrefix}-quantityChange`)
            },
            ...distributionColumns
          ];
        })
      ),
    [isDistributionDisabled, preparedTableData]
  );

  const promotionColumns: DynamicsTableColumn[] = useMemo(
    () =>
      tier < 3
        ? []
        : [
            {
              Header: "Promocje",
              accessor: row => row.promotions,
              id: "promotions",
              sortType: "alphanumeric"
            }
          ],
    [tier]
  );

  const data: DynamicsTableColumn[] = useMemo(
    () => [
      {
        Header: tablePeriodHeader,
        accessor: (row: DynamicsTableRow) => getRowPeriod(period, row),
        id: "period",
        sortType: sortTypeByPeriod(period)
      },
      ...columns,
      ...promotionColumns
    ],
    [columns, period, promotionColumns, tablePeriodHeader]
  );

  return data;
};
