import { appendInsightId } from "pages/Insights/partials/InsightsBox/utils";
import { RankingApiUnion } from "pages/Reports/types/ranking";
import { HTTP } from "utils";

export const rankingServices = {
  fetchRanking: async (params: string, endpoint: string) => {
    const url = appendInsightId(`${endpoint}?${params}`);
    const response = await HTTP.get<RankingApiUnion>(url);
    const data = await response.data;

    return data;
  }
};
