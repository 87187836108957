import { useSelector } from "react-redux";

import { TooltipContent } from "api/types";

import {
  checkPromotionOccurence,
  transformPromotionsForTable
} from "components/atoms/PromotionContent/utils";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import {
  FetchedPromotionTypes,
  usePromotionsForDates
} from "hooks/usePromotionsForDates";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import {
  hiddenChartsSelector,
  hiddenLinesSelector,
  timepointSelector
} from "pages/Reports/redux/reducers/chartReducer";
import { getSidebarHeader } from "pages/Reports/redux/utils/getSidebarHeader";
import { chartPeriodSelector } from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { Nullable } from "utils/types";

import { getName } from "../utils/getName";
import { useGetSegmentsByPeriod } from "./useGetSegmentsByPeriod";

export const useSegmentsSidebarData = (): Nullable<TooltipContent> => {
  const timepoint = useSelector(timepointSelector);
  const hiddenCharts = useSelector(hiddenChartsSelector);
  const hiddenLines = useSelector(hiddenLinesSelector).segments;
  const chartPeriod = useSelector(chartPeriodSelector);
  const dataset = useCurrentDataset();
  const promotions = usePromotionsForDates({
    fetchedPromotionTypes: FetchedPromotionTypes.BOTH
  });
  const { isGroupedBarChart } = useChartTypeBoolean();
  const { data: segments } = useGetSegmentsByPeriod();

  if (timepoint === null || !segments) {
    return null;
  }

  const isBarChart =
    segments.currentPeriod[0]?.segments[0]?.timeline.length <= 1;

  const data = segments.currentPeriod
    .filter(chart => !hiddenCharts.includes(chart.name))
    .map(period => {
      const name = getName(period, false);

      return period.segments
        .map(segment => ({
          tooltipId: name,
          label: segment.label,
          name: period.name,
          shortLabel: segment.shortLabel,
          data: segment.timeline[isBarChart ? 0 : timepoint]
        }))
        .filter(segment => !hiddenLines.includes(segment.label));
    });

  const firstDataElement = data[0][0]?.data;
  const regularDate = firstDataElement?.date;
  const lastYearDate = firstDataElement?.dateLastYear;
  const hoveredDate = newDateByTimezone(regularDate);
  const hoveredLastYearDate = newDateByTimezone(
    firstDataElement?.dateLastYear || undefined
  );

  const header = getSidebarHeader(
    regularDate,
    lastYearDate,
    chartPeriod,
    dataset
  );

  const promotionsInSelectedPeriod = checkPromotionOccurence(
    hoveredDate,
    promotions,
    chartPeriod
  );

  const promotionsInSelectedLastYearPeriod =
    isGroupedBarChart && dataset.both
      ? checkPromotionOccurence(hoveredLastYearDate, promotions, chartPeriod)
      : [];

  const promotionsForSidebar = transformPromotionsForTable([
    ...promotionsInSelectedPeriod,
    ...promotionsInSelectedLastYearPeriod
  ]);

  // [PMD-3186]: sidebar on segments should display promotions and insights only, other data stays in tooltip
  return {
    date: hoveredDate,
    header,
    promotions: promotionsForSidebar,
    data: []
  };
};
