import { isEmpty } from "ramda";

import {
  FetchedPromotion,
  Material
} from "pages/Reports/redux/reducers/promotionsReducer";

const filterVendors = (materialsList: Material[], hiddenLines: string[]) =>
  materialsList
    .map(vendor => {
      return {
        ...vendor,
        // remove materials that lines has been hidden
        data: vendor.data.filter(
          material =>
            !hiddenLines.some(line => line?.includes(material.display))
        )
      };
    })
    // remove vendor if it has no materials visible
    .filter(materials => !isEmpty(materials.data));

export const filterHiddenProductPromotions = (
  promotions: FetchedPromotion[],
  hiddenLines: string[]
): FetchedPromotion[] =>
  promotions
    .map(promotion => {
      const { competingMaterials, ownMaterials } = promotion.materials;

      const filteredCompetingMaterials = filterVendors(
        competingMaterials,
        hiddenLines
      );
      const filteredOwnMaterials = filterVendors(ownMaterials, hiddenLines);

      return {
        ...promotion,
        materials: {
          ...promotion.materials,
          competingMaterials: filteredCompetingMaterials,
          ownMaterials: filteredOwnMaterials
        }
      };
    })
    // remove full promotion if there is no vendor visible
    .filter(
      promotion =>
        !isEmpty(promotion.materials.competingMaterials) ||
        !isEmpty(promotion.materials.ownMaterials)
    );
