import { useSelector } from "react-redux";

import { ChartData, Margins } from "components/D3/types";
import { LinesGroup } from "pages/Reports/partials/Chart/StandardChart/LinesGroup";
import { RightAxis } from "pages/Reports/partials/Chart/StandardChart/RightAxis";
import { RightLabel } from "pages/Reports/partials/Chart/StandardChart/RightLabel";
import { getAxisTickValues } from "pages/Reports/partials/Chart/StandardChart/utils";
import { useChartScales } from "pages/Reports/partials/Chart/StandardChart/utils/useChartScales";
import { hoveredLineSelector } from "pages/Reports/redux/reducers/chartReducer";
import { REPORTS_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { CHART_DATA_TYPE } from "utils";

type Props = {
  height: number;
  width: number;
  margins: Margins;
  axisLabel: string;
  lineChart: ChartData;
  isOneTimepoint: boolean;
  isSecondary?: boolean;
};

export const RightChart: React.FC<Props> = ({
  width,
  height,
  margins,
  axisLabel,
  lineChart,
  isOneTimepoint
}) => {
  const hoveredLine = useSelector(hoveredLineSelector);
  const pathname = useSelector(pathnameSelector);
  const isSegmentsPage = pathname.includes(REPORTS_PATHS.SEGMENTS);

  const { rightChart } = lineChart;

  const { yScaleRight, xScaleBand } = useChartScales(lineChart, width);

  if (!rightChart) {
    return null;
  }

  const rightTicksValues = getAxisTickValues({
    min: rightChart.minValue,
    max: rightChart.maxValue,
    fillWithZero: false
  });

  return (
    <>
      {rightChart.dataType !== CHART_DATA_TYPE.NONE && rightChart.chart && (
        <>
          <RightAxis
            yScale={yScaleRight}
            ticks={rightTicksValues}
            width={width - margins.right - margins.left}
            maxValue={rightChart.maxValue}
          />
          <RightLabel
            axisLabel={axisLabel}
            offsetTop={height / 2}
            offsetLeft={width - margins.left}
            smallerOffset={isSegmentsPage}
          />
          <LinesGroup
            xScale={xScaleBand}
            yScale={yScaleRight}
            data={rightChart}
            isHistoricalGroupedChart={lineChart.isHistoricalGroupedChart}
            hoveredLine={hoveredLine}
            isOneTimepoint={isOneTimepoint}
            isRightChart
          />
        </>
      )}
    </>
  );
};
