import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";
import qs from "query-string";

import { SpinnerLoader } from "components/atoms";
import { Search } from "components/atoms/Icon";
import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import { FilterErrorMsg } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/FilterErrorMsg/FilterErrorMsg";
import {
  asyncFiltersSelector,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { useShopTypeMetaFetching } from "pages/Reports/redux/reducers/filters/hooks/useShopTypeMetaFetching";
import { ShopTypeItem } from "pages/Reports/redux/reducers/sweetStateHooks/useShopType";
import {
  getShopTypeMetaLabel,
  ShopTypeMetaHooks
} from "pages/Reports/redux/reducers/sweetStateHooks/useShopTypeMeta";

import { DROPDOWN_PLACEHOLDERS } from "../constants";
import { useDisabledTooltip } from "../hooks/useDisabledTooltip";
import { filterItems, isActiveFn } from "../utils";

export const ShopTypeMetaDropdown = ({
  isDisabled
}: {
  isDisabled: boolean;
}) => {
  const [state, actions] = ShopTypeMetaHooks.useShopTypeMeta();
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const selectedShopTypesMetaLabel = getShopTypeMetaLabel(state);

  const dispatch = useDispatch();
  const errorMsg = useSelector(asyncFiltersSelector).error.shopTypesMeta;
  const isLoading = useSelector(asyncFiltersSelector).fetching.shopTypesMeta;
  const tooltip = useDisabledTooltip(FILTER_NAMES.STORE_TYPE_META, isDisabled);

  useShopTypeMetaFetching(isDisabled);

  const handleInputValueChange = (value?: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (selectedItem?: ShopTypeItem) => {
    setOpen(false);

    if (!selectedItem) {
      setInputValue(selectedShopTypesMetaLabel);
      actions.sortShopTypesMeta();
    }
  };

  const handleStateChange = (changes: StateChangeOptions<ShopTypeItem[]>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleChange = (item: ShopTypeItem | null) => {
    setOpen(true);
    actions.selectShopTypeMeta(item);
  };

  const handleSelectAll = () => actions.selectAllShopTypesMeta(inputValue);

  useEffect(() => {
    if (!isOpen) setInputValue(selectedShopTypesMetaLabel);
  }, [isOpen, selectedShopTypesMetaLabel]);

  if (!isLoading && errorMsg) {
    return (
      <FilterErrorMsg
        callback={() => {
          dispatch(
            updateQuery({ filter: "shopTypesMeta", query: qs.stringify({}) })
          );
        }}
        errorMsg={errorMsg}
      />
    );
  }

  return (
    <Dropdown<ShopTypeItem[]>
      isMultiChoice
      isDisabled={isDisabled || isLoading}
      // @ts-ignore workoaround: downshift calls onInputValueCahnge  with result of itemToString prop call.
      // once we know this fn always returns undefined it makes downshift fully controled and inputValue
      // depends only on state of CategoryDropdown
      itemToString={() => {}}
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      items={state.all}
      selectedItem={state.selected}
      // @ts-ignore
      onChange={handleChange}
      onClear={() => handleChange(null)}
      onStateChange={handleStateChange}
      openedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.STORE_TYPE_META.OPEN
      }
      closedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.STORE_TYPE_META.CLOSED
      }
      onSelectAll={handleSelectAll}
      input={
        <FilterDropdownInput
          customIcon={isLoading ? <SpinnerLoader size="small" /> : <Search />}
          isTypingEnabled
          automaticInputValue={selectedShopTypesMetaLabel}
          tooltip={tooltip}
          testId="shop-type-meta-dropdown"
        />
      }
      menu={
        <FilterDropdownMenu
          isItemActiveFn={isActiveFn}
          filterItemsFn={filterItems}
          listItem={<FilterDropdownListItem />}
        />
      }
    />
  );
};
