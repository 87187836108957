import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { filtersParamsMap } from "api/utils/constants";
import qs from "query-string";
import { pick } from "ramda";

import { getReportUrl } from "hooks/useExcelExportUrl";
import { useVisibleList } from "pages/Reports/redux/reducers/filters/hooks/useVisibleFilters";
import {
  PARSE_OPTIONS,
  STRINGIFY_OPTIONS
} from "pages/Reports/utils/validateParams/utils/adjustObjectProperty";
import { queryParamsSelector } from "store/selectors/routerSelectors";
import { HTTP, LOGISTICS_DATA_TYPES_PARAMS, REST_API_ENDPOINTS } from "utils";
import { QP } from "utils/defaultQueryParams";
import { pushLogisticsReportAction } from "utils/googleTagManager/dataLayer";
import { translateFeParamsToBeParams } from "utils/translateFEParamsToBEParams";
import { Values } from "utils/types";

export const useLogisticsExportUrl = (
  type: Values<typeof LOGISTICS_DATA_TYPES_PARAMS>
) => {
  const params = useSelector(queryParamsSelector);
  const filters = useVisibleList().map(({ name }) => name);

  const paramsToPick = filters
    .flatMap(filter => filtersParamsMap[filter])
    .filter(Boolean);
  const fetchParams = pick(paramsToPick, params);

  // for "single day" type replace date_from with date_to
  const dateFrom =
    type === LOGISTICS_DATA_TYPES_PARAMS.SINGLE_DAY
      ? params[QP.DATE_TO]
      : params[QP.DATE_FROM];
  fetchParams[QP.DATE_FROM] = dateFrom;

  // [PMD-5211]: vendor_id must be added to the logistics requests
  fetchParams[QP.VENDOR_ID] = fetchParams[QP.VENDOR_ID] || params[QP.VENDOR_ID];

  const translatedString = translateFeParamsToBeParams(
    qs.stringify(fetchParams, STRINGIFY_OPTIONS)
  );
  const translatedParams = qs.parse(translatedString, PARSE_OPTIONS);

  return useQuery(["logistics-export-url", type, translatedString], {
    queryFn: async () => {
      const response = await HTTP.post<{ url: string }>(
        REST_API_ENDPOINTS.REPORTS.LOGISTICS_EXPORT,
        { ...translatedParams, report_url: getReportUrl() }
      );
      return response.data.url;
    },
    onSettled: () => {
      pushLogisticsReportAction(
        type === LOGISTICS_DATA_TYPES_PARAMS.SINGLE_DAY
          ? `tabelka - eksport danych - ${dateFrom}`
          : `wykres - eksport danych - ${dateFrom} - ${params[QP.DATE_TO]}`
      );
    },
    enabled: false,
    retry: false,
    staleTime: 60 * 60 * 1000 // 1 hour
  });
};
