import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { useFullScreen } from "pages/Reports/partials/FullScreenWrapper/FullScreenWrapper";
import { timepointSelector } from "pages/Reports/redux/reducers/chartReducer";
import { BOOL_STRING_VALUES, TOURS_KEYS } from "utils";

import {
  SELECTING_SIDEBAR_STEPS,
  SIDEBAR_CHANGE_STEPS,
  SORTABLE_SIDEBAR_STEPS
} from "../constants";
import { TourHooks } from "../hooks/useTours";
import { Tour } from "../Tour";

const {
  IS_SORTABLE_SIDEBAR_TOUR_HIDDEN,
  IS_SELECTING_SIDEBAR_TOUR_HIDDEN,
  IS_SIDEBAR_CHANGE_TOUR_HIDDEN
} = TOURS_KEYS;
const { TRUE } = BOOL_STRING_VALUES;

export const SidebarTour = () => {
  const timepoint = useSelector(timepointSelector);
  const [state, actions] = TourHooks.useTours();
  const { both: isBothDatasets } = useCurrentDataset();
  const { visible: isFullScreen } = useFullScreen();

  useEffect(() => {
    const isSidebarChangeTourHidden =
      localStorage.getItem(IS_SIDEBAR_CHANGE_TOUR_HIDDEN) === TRUE;

    if (!isSidebarChangeTourHidden) {
      actions.setSidebarChangeTourHidden(!isBothDatasets);
    }
  }, [actions, isBothDatasets]);

  const activeTour = [
    {
      key: IS_SORTABLE_SIDEBAR_TOUR_HIDDEN,
      steps: SORTABLE_SIDEBAR_STEPS,
      isHidden: state.isSortableSidebarTourHidden,
      setter: actions.setSortableSidebarTourHidden
    },
    {
      key: IS_SELECTING_SIDEBAR_TOUR_HIDDEN,
      steps: SELECTING_SIDEBAR_STEPS,
      isHidden: state.isSelectingSidebarTourHidden,
      setter: actions.setSelectingSidebarTourHidden
    },
    {
      key: IS_SIDEBAR_CHANGE_TOUR_HIDDEN,
      steps: SIDEBAR_CHANGE_STEPS,
      isHidden: state.isSidebarChangeTourHidden,
      setter: actions.setSidebarChangeTourHidden
    }
  ].find(({ isHidden }) => !isHidden);

  const handleClose = () => {
    if (!activeTour) return;

    localStorage.setItem(activeTour.key, TRUE);
    activeTour.setter(true);
  };

  if (isFullScreen || !activeTour || timepoint === null) {
    return null;
  }

  return (
    <Tour
      key={activeTour.key}
      steps={activeTour.steps}
      disableScrollParentFix={false}
      onClose={handleClose}
    />
  );
};
