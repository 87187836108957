import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";

import { ConnectedRouter } from "connected-react-router";

import { InternetExplorerAlert } from "components/organisms";
import { createNewStore, store } from "store";
import { history } from "store/utils";

import { MainView } from "./MainView";
import { isInternetExplorer } from "./utils";

const isTestEnv = process.env.NODE_ENV === "test";
const isDev = window.location.hostname === "localhost";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 24 * 60 * 60 * 1000
    }
  }
});

const App: React.FC = (p: any) => {
  if (isInternetExplorer()) {
    return <InternetExplorerAlert />;
  }

  if (process.env.REACT_APP_REST_API_URL?.includes("staging")) {
    //@ts-ignore it doesn't exist because it's added here
    window.store = store;
  }

  return (
    <Provider store={isTestEnv ? createNewStore() : store}>
      <QueryClientProvider client={queryClient}>
        <ConnectedRouter history={history}>
          <MainView />
        </ConnectedRouter>
        {isDev ? <ReactQueryDevtools /> : null}
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
