import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";

import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import s from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/CategoryDropdown/categoryDropdown.module.scss";
import { validatedChartPeriodsSelector } from "pages/Reports/redux/reducers/periods/selectors";
import {
  ChartPeriodHooks,
  ChartPeriodItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useChartPeriod";
import { chartPeriodSelector } from "store/selectors/routerSelectors";

import { DROPDOWN_LABELS, DROPDOWN_PLACEHOLDERS } from "../constants";
import { filterItems, isActiveFn } from "../utils";

type Props = {
  isDisabled: boolean;
};

export const TimeAggregationDropdown = ({ isDisabled }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const chartPeriodParam = useSelector(chartPeriodSelector);
  const periods = useSelector(validatedChartPeriodsSelector);

  const [state, actions] = ChartPeriodHooks.useChartPeriod();

  const handleInputValueChange = (value?: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (item?: ChartPeriodItem) => {
    setOpen(false);

    if (!item) {
      setInputValue(state.selected.label);
    }
  };

  const handleStateChange = (
    changes: StateChangeOptions<ChartPeriodItem[]>
  ) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleChange = (item: ChartPeriodItem) => {
    setOpen(false);
    actions.updateSelectedChartPeriod(item);
  };

  useEffect(() => {
    actions.setPristineChartPeriod(chartPeriodParam, periods);
  }, [periods, chartPeriodParam, actions]);

  useEffect(() => {
    if (!isOpen) setInputValue(state.selected.label);
  }, [isOpen, state]);

  return (
    <Dropdown<ChartPeriodItem[]>
      className={s.timeAggregationDropdownWrapper}
      isDisabled={isDisabled}
      // @ts-ignore workoaround: downshift calls onInputValueCahnge  with result of itemToString prop call.
      // once we know this fn always returns undefined it makes downshift fully controled and inputValue
      // depends only on state of CategoryDropdown
      itemToString={() => {}}
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      label={DROPDOWN_LABELS.TIME_AGGREGATION}
      items={state.all}
      selectedItem={[state.selected]}
      // @ts-ignore
      onChange={handleChange}
      onStateChange={handleStateChange}
      openedMenuPlaceholder={DROPDOWN_PLACEHOLDERS.TIME_AGGREGATION.OPEN}
      closedMenuPlaceholder={DROPDOWN_PLACEHOLDERS.TIME_AGGREGATION.CLOSED}
      input={
        <FilterDropdownInput
          isTypingEnabled
          automaticInputValue={state.selected.label}
          testId="time-aggregation-dropdown"
        />
      }
      menu={
        <FilterDropdownMenu
          isItemActiveFn={isActiveFn}
          filterItemsFn={filterItems}
          listItem={<FilterDropdownListItem />}
        />
      }
    />
  );
};
