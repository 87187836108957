import { ChartData } from "components/D3/types";

export const useChartToShow = (chart: ChartData) => {
  const leftChart = chart?.leftChart?.chart || [];

  const values = leftChart[0]?.timeline.map(time => time.valueY || 0) || [];
  const lastYearValues =
    leftChart[0]?.lastYearTimeline?.map(item => item.valueY || 0) || [];

  const id = leftChart?.[0]?.id || "";

  return {
    domain: chart?.domain || [],
    chartToShow: leftChart[0],
    values,
    lastYearValues,
    minValue: chart?.leftChart?.minValue || 0,
    maxValue: chart?.leftChart?.maxValue || 0,
    id
  };
};
