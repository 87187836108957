import {
  Action,
  createHook,
  createStore,
  defaultRegistry
} from "react-sweet-state";

import { DropdownItem } from "components/molecules/types";
import { getSelectedCountLabel } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import {
  getCategoryIds,
  Parent
} from "pages/Reports/redux/reducers/filters/categoryFilters/categoryFiltersActions";
import { sortItemsBySelectionOrder } from "pages/Reports/redux/utils";
import {
  isParentCategorySelected,
  shouldSelectItem
} from "store/utils/filtersUtils";
import { getCategoriesAndLevel } from "utils";
import { Nullable } from "utils/types";

export type CategoryItem = DropdownItem<number> & {
  parent?: Nullable<Parent>;
  startDate: string;
  isFake: boolean;
};

type State = {
  level1: {
    all: CategoryItem[];
    selected: CategoryItem[];
  };
  level2: {
    all: CategoryItem[];
    selected: CategoryItem[];
  };
  level3: {
    all: CategoryItem[];
    selected: CategoryItem[];
  };
};
type Actions = typeof actions;

const getProperty = (level: 1 | 2 | 3) => {
  if (level === 1) return `level1`;
  if (level === 2) return `level2`;
  return `level3`;
};

const actions = {
  updateAllCategories: (
    all: CategoryItem[],
    level: 1 | 2 | 3
  ): Action<State> => ({ setState, getState }) => {
    const property = getProperty(level);

    const selected = getState()[property].selected;

    const remainingCategories = selected.filter(cat =>
      all.map(item => item.value).includes(cat.value)
    );

    setState({
      ...getState(),
      [property]: {
        selected: remainingCategories,
        all
      }
    });
  },
  updateSelectedCategories: (
    selected: CategoryItem[],
    level: 1 | 2 | 3
  ): Action<State> => ({ setState, getState }) => {
    const property = getProperty(level);

    setState({
      [property]: {
        ...getState()[property],
        selected
      }
    });
  },
  selectAllCategories: (
    inputValue: string,
    level: 1 | 2 | 3
  ): Action<State> => ({ getState, dispatch }) => {
    const state = getState();
    const allCategories = getAllCategories(state, level);

    const selectedL1Categories = getSelectedCategories(state, 1);
    const selectedL2Categories = getSelectedCategories(state, 2);
    const selectedCategories = getSelectedCategories(state, 3);

    const selected = [
      ...selectedCategories,
      ...allCategories.filter(
        shouldSelectItem(
          level,
          inputValue,
          selectedCategories,
          selectedL1Categories,
          selectedL2Categories
        )
      )
    ];
    dispatch(actions.updateSelectedCategories(selected, level));
  },
  selectCategory: (
    selectedCategory: Nullable<CategoryItem>,
    level: 1 | 2 | 3,
    replaceItems?: boolean
  ): Action<State> => ({ getState, dispatch }) => {
    const state = getState();
    const selectedCategories = getSelectedCategories(state, level);

    if (!selectedCategory) {
      return dispatch(actions.updateSelectedCategories([], level));
    }

    const isItemAlreadySelected = selectedCategories.some(
      ({ value }) => value === selectedCategory.value
    );

    if (isItemAlreadySelected) {
      const selected = selectedCategories.filter(
        ({ value }) => value !== selectedCategory.value
      );

      if (level === 2) {
        const selected3CategoriesItems = getSelectedCategories(state, 3);

        const filteredSelected3CategoryItems = selected3CategoriesItems.filter(
          category3Item => {
            if (!selected.length) {
              return category3Item;
            }

            return !!selected.find(
              category2Item => category3Item.parent?.id === category2Item.value
            );
          }
        );

        dispatch(
          actions.updateSelectedCategories(filteredSelected3CategoryItems, 3)
        );
      }

      return dispatch(actions.updateSelectedCategories(selected, level));
    }

    if (replaceItems) {
      return dispatch(
        actions.updateSelectedCategories([selectedCategory], level)
      );
    }

    return dispatch(
      actions.updateSelectedCategories(
        [...selectedCategories, selectedCategory],
        level
      )
    );
  },
  sortCategories: (level: 1 | 2 | 3): Action<State> => ({
    getState,
    dispatch
  }) => {
    const state = getState();

    const property = getProperty(level);
    const all = state[property].all;
    const selected = state[property].selected;

    const selectedCategoryIds = selected.map(item => item.value);

    const sortedAllCategoryItems = sortItemsBySelectionOrder(
      selectedCategoryIds,
      all
    );
    dispatch(actions.updateAllCategories(sortedAllCategoryItems, level));
  },
  clearCategories: (): Action<State> => ({ setState, getState }) =>
    setState({
      level1: {
        all: getState().level1.all,
        selected: []
      },
      level2: {
        all: getState().level2.all,
        selected: []
      },
      level3: {
        all: getState().level3.all,
        selected: []
      }
    }),
  selectInitialCategoryItems: (
    level: 1 | 2 | 3,
    categoryParam: string[]
  ): Action<State> => ({ getState, dispatch }) => {
    const state = getState();

    const allItemsByLevel = getAllCategories(state, level);

    const selectedCat1Items = getSelectedCategories(state, 1);
    const selectedCat2Items = getSelectedCategories(state, 2);
    const selectedCat3Items = getSelectedCategories(state, 3);

    const itemsByParentCategorySelection = allItemsByLevel.filter(item =>
      isParentCategorySelected(
        level,
        item,
        selectedCat1Items,
        selectedCat2Items,
        selectedCat3Items
      )
    );

    const ids = getCategoryIds(categoryParam);

    if (level === 1 && ids.length > 1) {
      return;
    }

    const selectedItems = itemsByParentCategorySelection.filter(({ value }) =>
      ids.includes(value)
    );

    if (selectedItems.length) {
      dispatch(actions.updateSelectedCategories(selectedItems, level));
      dispatch(actions.sortCategories(level));
    }
  }
};

export const CategoryStore = createStore<State, Actions>({
  name: "category",
  initialState: {
    level1: {
      all: [],
      selected: []
    },
    level2: {
      all: [],
      selected: []
    },
    level3: {
      all: [],
      selected: []
    }
  },
  actions
});

export const getAllCategories = (state: State, level: 1 | 2 | 3) => {
  const property = getProperty(level);
  return state[property].all;
};

export const getSelectedCategories = (state: State, level: 1 | 2 | 3) => {
  const property = getProperty(level);
  return state[property].selected;
};

const getSelectedCategoriesIds = (state: State, level: 1 | 2 | 3) => {
  const categories = getSelectedCategories(state, level);

  return categories.map(cate => cate.value);
};

const getStringifiedSelectedCategories = (state: State, level: 1 | 2 | 3) => {
  const property = getProperty(level);
  const selected = state[property].selected;
  const all = state[property].all;

  const checkItemSelection = (
    selectedItems: CategoryItem[],
    item: CategoryItem
  ) => selectedItems.some(({ value }) => value === item.value);

  const selectedL1Items = getSelectedCategories(state, 1);
  const selectedL2Items = getSelectedCategories(state, 2);

  const filteredCategoriesByParentAndSelectedCategories = all.filter(
    item =>
      isParentCategorySelected(level, item, selectedL1Items, selectedL2Items) ||
      checkItemSelection(selected, item)
  );

  if (level === 2 || level === 3) {
    return getSelectedCountLabel(
      selected.length,
      filteredCategoriesByParentAndSelectedCategories.length
    );
  }
  return selected.map(item => item.label).join(", ");
};

const getCategoriesTopLevel = (state: State) => {
  const level1 = state.level1.all;
  const level2 = state.level2.all;
  const level3 = state.level3.all;

  if (level1.length) return 1;
  if (level2.length) return 2;
  if (level3.length) return 3;
  return 1;
};

export const getCategoryWithLevel = (state: State) => {
  const level1 = getSelectedCategoriesIds(state, 1);
  const level2 = getSelectedCategoriesIds(state, 2);
  const level3 = getSelectedCategoriesIds(state, 3);
  return getCategoriesAndLevel(level1, level2, level3);
};

const getLastSelectedCategories = (state: State) => {
  const level3 = getSelectedCategories(state, 3);
  if (level3.length) return level3;

  const level2 = getSelectedCategories(state, 2);
  if (level2.length) return level2;

  const level1 = getSelectedCategories(state, 1);
  if (level1.length) return level1;

  return [];
};

export const CategoryHooks = {
  useCategory: createHook(CategoryStore),
  useAllCategories: createHook(CategoryStore, {
    selector: getAllCategories
  }),
  useSelectedCategories: createHook(CategoryStore, {
    selector: getSelectedCategories
  }),
  useSelectedCategoriesIds: createHook(CategoryStore, {
    selector: getSelectedCategoriesIds
  }),
  useSelectedTopLevelCategory: createHook(CategoryStore, {
    selector: (state: State) => {
      const level1 = getSelectedCategories(state, 1);
      const level2 = getSelectedCategories(state, 2);
      const level3 = getSelectedCategories(state, 3);
      const level = getCategoriesTopLevel(state);

      return [level1, level2, level3][level - 1];
    }
  }),
  useCategoryWithLevel: createHook(CategoryStore, {
    selector: getCategoryWithLevel
  }),
  useSelectedCategoriesFakeIndicator: createHook(CategoryStore, {
    selector: (state: State) => {
      const selected = getLastSelectedCategories(state);
      return selected.some(({ isFake }) => isFake);
    }
  }),
  useStringifiedSelectedCategories: createHook(CategoryStore, {
    selector: getStringifiedSelectedCategories
  }),
  useCategoriesTopLevel: createHook(CategoryStore, {
    selector: getCategoriesTopLevel
  })
};

export const CategoryStoreInstance = defaultRegistry.getStore(CategoryStore);
