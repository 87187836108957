import * as React from "react";
import Transition, {
  ENTERED,
  ENTERING,
  EXITED,
  EXITING,
  TransitionProps,
  UNMOUNTED
} from "react-transition-group/Transition";

import s from "./fade.module.scss";

type Opacities = {
  entering: number;
  entered: number;
  exiting: number;
  exited: number;
  unmounted: number;
};

const DEFAULT_OPACITIES: Opacities = {
  [ENTERING]: 0,
  [ENTERED]: 1,
  [EXITING]: 0,
  [EXITED]: 1,
  [UNMOUNTED]: 0
};

export const Fade = ({
  children,
  opacities,
  styles,
  ...props
}: TransitionProps & { styles?: React.CSSProperties }) => {
  const opacityValues: Opacities = { ...DEFAULT_OPACITIES, ...opacities };
  return (
    <Transition {...props}>
      {state => {
        return (
          <div
            className={s.base}
            style={{ opacity: opacityValues[state], ...styles }}
          >
            {children}
          </div>
        );
      }}
    </Transition>
  );
};
