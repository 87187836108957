import React from "react";

import cn from "classnames";

import { Text } from "components/atoms";
import { LogoView } from "components/molecules";
import { APP_NAME, CONTACT_EMAIL } from "utils";
import { PRODUCTION_URL } from "utils/constants";

import s from "./loginError.module.scss";

export const LoginError = () => {
  return (
    <LogoView>
      <div className={s.textWrapper}>
        <Text className={s.regularText}>
          Niestety w tej chwili nie możesz zalogować się
        </Text>
        <Text className={cn(s.regularText, s.textGap)}>
          do platformy Żabka {APP_NAME}
        </Text>
        <Text className={s.regularText}>
          Prosimy o kontakt z nami pod adresem:
        </Text>
        <a
          href={`mailto:${CONTACT_EMAIL}?subject=Zg%C5%82oszenie%20problemu%20z%20${PRODUCTION_URL}`}
          className={s.regularText}
        >
          [{CONTACT_EMAIL}]
        </a>
      </div>
    </LogoView>
  );
};
