import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";

import { SpinnerLoader } from "components/atoms";
import { ChevronDown } from "components/atoms/Icon";
import { Dropdown, FilterDropdownInput } from "components/molecules";
import { FilterErrorMsg } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/FilterErrorMsg/FilterErrorMsg";
import {
  asyncFiltersSelector,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useReferenceCategoryBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useReferenceCategoryFetching";
import {
  ReferenceCategoryHooks,
  ReferenceCategoryItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useReferenceCategory";
import { Nullable } from "utils/types";

import { DROPDOWN_PLACEHOLDERS } from "../constants";
import s from "./referenceCategories.module.scss";
import { ReferenceCategoriesMenu } from "./ReferenceCategoriesMenu";

interface Props {
  isDisabled: boolean;
  isAggregationDisabled: boolean;
}

export const ReferenceCategories = ({
  isDisabled,
  isAggregationDisabled
}: Props) => {
  const dispatch = useDispatch();

  const [, actions] = ReferenceCategoryHooks.useReferenceCategory();
  const [inputValue] = ReferenceCategoryHooks.useInputValue();
  const isLoading = useSelector(asyncFiltersSelector).fetching
    .referenceCategory;
  const errorMsg = useSelector(asyncFiltersSelector).error.referenceCategory;

  const [isOpen, setOpen] = useState(false);

  const handleStateChange = (changes: StateChangeOptions<any>) => {
    if (changes.isOpen === true) {
      setOpen(true);
    }
  };

  const handleChange = (item: Nullable<ReferenceCategoryItem>) => {
    actions.selectCategory(item?.level || 1, item);
  };

  const handleSelectionClear = () => {
    actions.clearCategories();
  };

  useReferenceCategoryBehaviour(isDisabled);

  if (!isLoading && errorMsg) {
    return (
      <FilterErrorMsg
        callback={() => {
          dispatch(
            updateQuery({
              filter: "referenceCategory",
              query: ""
            })
          );
        }}
        errorMsg={errorMsg}
      />
    );
  }

  return (
    <Dropdown<ReferenceCategoryItem>
      isDisabled={isLoading || isDisabled}
      openedMenuPlaceholder={
        isLoading
          ? "Pobieranie"
          : DROPDOWN_PLACEHOLDERS.REFERENCE_CATEGORIES.OPEN
      }
      closedMenuPlaceholder={
        isLoading
          ? "Pobieranie"
          : DROPDOWN_PLACEHOLDERS.REFERENCE_CATEGORIES.CLOSED
      }
      inputValue={isLoading ? "Pobieranie" : isOpen ? "" : inputValue}
      isOpen={isOpen}
      onSelect={handleChange}
      onStateChange={handleStateChange}
      input={
        <FilterDropdownInput
          customIcon={
            isLoading ? <SpinnerLoader size="small" /> : <ChevronDown />
          }
          className={{ toggleButton: s.inputToggleButton }}
          isTypingEnabled
          automaticInputValue={""}
          onInputClear={handleSelectionClear}
          testId="reference-category-dropdown"
        />
      }
      menu={
        <ReferenceCategoriesMenu
          onClose={() => setOpen(false)}
          isAggregationDisabled={isAggregationDisabled}
        />
      }
    />
  );
};
