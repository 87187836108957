import {
  Action,
  createHook,
  createStore,
  defaultRegistry
} from "react-sweet-state";

import { ParsedQuery } from "query-string";

import { DropdownItem } from "components/molecules/types";
import { isAlreadySelected } from "store/utils/filtersUtils";
import { BOOL_STRING_VALUES, getCategoriesAndLevel } from "utils";
import { QP } from "utils/defaultQueryParams";
import { Nullable } from "utils/types";

const { TRUE } = BOOL_STRING_VALUES;

type Parent = { id: number; parent?: Nullable<Parent> };

export type CategoryLevel = 1 | 2 | 3;

export type ReferenceCategoryItem = DropdownItem<number> & {
  parent?: Nullable<Parent>;
  startDate: string;
  isFake: boolean;
  level: CategoryLevel;
};

interface State {
  level1: {
    all: ReferenceCategoryItem[];
    selected: ReferenceCategoryItem[];
  };
  level2: {
    all: ReferenceCategoryItem[];
    selected: ReferenceCategoryItem[];
  };
  level3: {
    all: ReferenceCategoryItem[];
    selected: ReferenceCategoryItem[];
  };
  aggregated: boolean;
}

type Actions = typeof actions;

const getProperty = (level: CategoryLevel) => {
  if (level === 1) return "level1";
  if (level === 2) return "level2";
  if (level === 3) return "level3";
  return "";
};

const getAllCategories = (state: State, level: CategoryLevel) => {
  const property = getProperty(level);
  if (!property) return [];
  return state[property].all;
};

const getSelectedCategories = (state: State, level: CategoryLevel) => {
  const property = getProperty(level);
  if (!property) return [];
  return state[property].selected;
};

const getAggregated = (state: State) => state.aggregated;

const getSelectedAllAndLevel = (
  state: State
): {
  all: ReferenceCategoryItem[];
  selected: ReferenceCategoryItem[];
  level: number;
} => {
  const [, _level] = getSelectedReferenceCategoryWithLevel(state);
  const level = Number(_level) as CategoryLevel;

  if (!level) return { all: [], selected: [], level: 1 };

  const all = getAllCategories(state, level);
  const selected = getSelectedCategories(state, level);

  return { all, selected, level };
};

const getInputValue = (state: State) => {
  const { all, selected, level } = getSelectedAllAndLevel(state);

  if (!all.length || !selected.length) return "";

  return `Wybrano G${level} - ${selected.length} z ${all.length}`;
};

const getSelectedIds = (state: State) => {
  const level1 = getSelectedCategories(state, 1);
  const level2 = getSelectedCategories(state, 2);
  const level3 = getSelectedCategories(state, 3);

  return {
    ref_cat1: level1.map(({ value }) => value),
    ref_cat2: level2.map(({ value }) => value),
    ref_cat3: level3.map(({ value }) => value)
  };
};

export const getSelectedReferenceCategoryWithLevel = (state: State) => {
  const { ref_cat1, ref_cat2, ref_cat3 } = getSelectedIds(state);
  return getCategoriesAndLevel(ref_cat1, ref_cat2, ref_cat3);
};

const actions = {
  updateAllCategories: (
    all: ReferenceCategoryItem[],
    level: CategoryLevel
  ): Action<State> => ({ getState, setState }) => {
    const property = getProperty(level);

    if (!property) return;

    setState({
      [property]: {
        ...getState()[property],
        all
      }
    });
  },
  updateSelectedCategories: (
    selected: ReferenceCategoryItem[],
    level: CategoryLevel
  ): Action<State> => ({ getState, setState }) => {
    const property = getProperty(level);

    if (!property) return;

    setState({
      [property]: {
        ...getState()[property],
        selected
      }
    });
  },
  updateAggregated: (aggregated: boolean): Action<State> => ({ setState }) => {
    setState({ aggregated });
  },
  selectCategory: (
    level: CategoryLevel,
    item: Nullable<ReferenceCategoryItem>
  ): Action<State> => ({ getState, dispatch }) => {
    if (!item) return;

    const selected = getSelectedCategories(getState(), level);

    if (isAlreadySelected(selected, item)) {
      dispatch(
        actions.updateSelectedCategories(
          selected.filter(({ value }) => value !== item.value),
          level
        )
      );
    } else {
      dispatch(actions.updateSelectedCategories([...selected, item], level));
    }
  },
  selectAllCategories: (level: CategoryLevel): Action<State> => ({
    getState,
    dispatch
  }) => {
    const all = getAllCategories(getState(), level);
    dispatch(actions.updateSelectedCategories(all, level));
  },
  checkPristineSelectionsAfterFetching: (
    datasets: ReferenceCategoryItem[][],
    params: ParsedQuery<string>
  ): Action<State> => ({ dispatch }) => {
    [params[QP.REF_CAT1], params[QP.REF_CAT2], params[QP.REF_CAT3]].forEach(
      (categories, index) => {
        const level = (index + 1) as CategoryLevel;
        const ids = String(categories || "").split(",");
        const selected = datasets[index].filter(({ value }) =>
          ids.includes(String(value))
        );
        dispatch(actions.updateSelectedCategories(selected, level));
      }
    );
    dispatch(actions.updateAggregated(params[QP.REF_CAT_AGGREGATED] === TRUE));
  },
  checkSelectionsAfterFetching: (
    categories: ReferenceCategoryItem[][]
  ): Action<State> => ({ getState, dispatch }) => {
    const levels: CategoryLevel[] = [1, 2, 3];
    const values = categories.map(category =>
      category.map(({ value }) => value)
    );

    levels.forEach((level, index) => {
      const selected = getSelectedCategories(getState(), level);
      const matched = selected.filter(({ value }) =>
        values[index].includes(value)
      );

      dispatch(actions.updateSelectedCategories(matched, level));
    });
  },
  clearCategories: (): Action<State> => ({ dispatch }) => {
    dispatch(actions.updateSelectedCategories([], 1));
    dispatch(actions.updateSelectedCategories([], 2));
    dispatch(actions.updateSelectedCategories([], 3));
  }
};

export const ReferenceCategoryStore = createStore<State, Actions>({
  name: "referenceCategory",
  initialState: {
    level1: { all: [], selected: [] },
    level2: { all: [], selected: [] },
    level3: { all: [], selected: [] },
    aggregated: true
  },
  actions
});

export const ReferenceCategoryHooks = {
  useReferenceCategory: createHook(ReferenceCategoryStore),
  useAllCategories: createHook(ReferenceCategoryStore, {
    selector: getAllCategories
  }),
  useSelectedCategories: createHook(ReferenceCategoryStore, {
    selector: getSelectedCategories
  }),
  useTopLevelCategories: createHook(ReferenceCategoryStore, {
    selector: getSelectedAllAndLevel
  }),
  useAggregated: createHook(ReferenceCategoryStore, {
    selector: getAggregated
  }),
  useInputValue: createHook(ReferenceCategoryStore, {
    selector: getInputValue
  }),
  useSelectedIds: createHook(ReferenceCategoryStore, {
    selector: getSelectedIds
  })
};

export const ReferenceCategoryInstance = defaultRegistry.getStore(
  ReferenceCategoryStore
);
