import { HTTP, REST_API_ENDPOINTS } from "utils";

type SingleActions =
  | "DELETE"
  | "ADD_TO_FAVOURITES"
  | "SHOW_CHART"
  | "THUMB_UP"
  | "THUMB_DOWN"
  | "SURVEY_SHOW"
  | "SURVEY_NPS"
  | "SURVEY_QUESTION_1"
  | "SURVEY_QUESTION_2"
  | "FILTER"
  | "SHOW_EXPLAINABILITY";

type ListActions = "VIEW_INSIGHTS_LIST";

type SinglePayload = {
  action: SingleActions;
  insight_id: string;
  page_url: string;
  action_additional_info_1?: string;
  action_additional_info_2?: string;
};

type ListPayload = {
  action: ListActions;
  insight_id: string[];
  page_url: string;
};

const initialize = (callback: Function) => {
  if (window.location.hostname === "localhost") return;

  callback();
};

export const sendSingleInsightFeedback = async (
  action: SingleActions,
  insightId: string,
  additionalInfo1?: string,
  additionalInfo2?: string
) =>
  initialize(() => {
    const payload: SinglePayload = {
      action,
      insight_id: insightId,
      page_url: window.location.href,
      action_additional_info_1: additionalInfo1,
      action_additional_info_2: additionalInfo2
    };

    HTTP.post<void>(REST_API_ENDPOINTS.INSIGHTS.FEEDBACK_SINGLE, payload);
  });

export const sendInsightsListFeedback = (insightsIds: string[]) =>
  initialize(() => {
    const payload: ListPayload = {
      action: "VIEW_INSIGHTS_LIST",
      insight_id: insightsIds,
      page_url: window.location.href
    };

    HTTP.post<void>(REST_API_ENDPOINTS.INSIGHTS.FEEDBACK_LIST, payload);
  });
