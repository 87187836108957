import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";
import qs from "query-string";

import { SpinnerLoader } from "components/atoms";
import { Search } from "components/atoms/Icon";
import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import { FilterErrorMsg } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/FilterErrorMsg/FilterErrorMsg";
import {
  asyncFiltersSelector,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { useCountyFetching } from "pages/Reports/redux/reducers/filters/hooks/useCountyFetching";
import {
  CountyHooks,
  CountyItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useCounty";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

import { DROPDOWN_PLACEHOLDERS } from "../constants";
import { useDisabledTooltip } from "../hooks/useDisabledTooltip";
import { filterItems, isActiveFn } from "../utils";
import s from "./countyDropdown.module.scss";

type Props = {
  isDisabled: boolean;
};

export const CountyDropdown = ({ isDisabled }: Props) => {
  const [countyState, actions] = CountyHooks.useCounty();
  const tooltip = useDisabledTooltip(FILTER_NAMES.COUNTY, isDisabled);

  const [isOpen, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  useCountyFetching(isDisabled);

  const dispatch = useDispatch();
  const isLoading = useSelector(asyncFiltersSelector).fetching.counties;
  const errorMsg = useSelector(asyncFiltersSelector).error.counties;

  const [selectedCountiesLabel] = CountyHooks.useCountyLabel();

  const handleInputValueChange = (value?: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (selectedItem?: CountyItem) => {
    setOpen(false);

    if (!selectedItem) {
      setInputValue(selectedCountiesLabel);
      actions.sortCounties();
    }
  };

  const handleStateChange = (changes: StateChangeOptions<CountyItem[]>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleChange = (item: CountyItem | null) => {
    setOpen(true);
    actions.selectCounty(item);
  };

  const filterCountiesByInput = (inputValue: string) => (item: CountyItem) => {
    const isItemSelected = countyState.selected.some(
      ({ value }) => value === item.value
    );

    //always show selected item
    if (isItemSelected) {
      return true;
    }

    const countyMatchInputValue = filterItems<CountyItem>(inputValue)(item);

    return countyMatchInputValue;
  };

  const handleSelectAll = () => {
    actions.selectAllCounties(inputValue);
    pushFiltersEvent("county - select all");
  };

  const handleClear = () => {
    handleChange(null);
    pushFiltersEvent("county - clear");
  };

  useEffect(() => {
    if (!isOpen) setInputValue(selectedCountiesLabel);
  }, [isOpen, selectedCountiesLabel]);

  if (!isLoading && errorMsg) {
    return (
      <FilterErrorMsg
        callback={() => {
          dispatch(
            updateQuery({
              filter: "counties",
              query: qs.stringify({})
            })
          );
        }}
        errorMsg={errorMsg}
      />
    );
  }

  return (
    <Dropdown<CountyItem[]>
      className={s.countyDropdownWrapper}
      isMultiChoice
      isDisabled={isDisabled || isLoading}
      // @ts-ignore workoaround: downshift calls onInputValueCahnge  with result of itemToString prop call.
      // once we know this fn always returns undefined it makes downshift fully controled and inputValue
      // depends only on state of CategoryDropdown
      itemToString={() => {}}
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      items={countyState.all}
      selectedItem={countyState.selected}
      // @ts-ignore
      onChange={handleChange}
      onClear={handleClear}
      onStateChange={handleStateChange}
      openedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.REGION.COUNTY.OPEN
      }
      closedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.REGION.COUNTY.CLOSED
      }
      onSelectAll={handleSelectAll}
      input={
        <FilterDropdownInput
          customIcon={isLoading ? <SpinnerLoader size="small" /> : <Search />}
          isTypingEnabled
          automaticInputValue={selectedCountiesLabel}
          tooltip={tooltip}
          testId="county-dropdown"
        />
      }
      menu={
        <FilterDropdownMenu<CountyItem>
          isItemActiveFn={isActiveFn}
          filterItemsFn={filterCountiesByInput}
          listItem={<FilterDropdownListItem />}
        />
      }
    />
  );
};
