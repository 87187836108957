import React, { CSSProperties } from "react";

import s from "components/atoms/Dot/dot.module.scss";

type Props = {
  style?: CSSProperties;
};

export const Dot: React.FC<Props> = ({ style = {} }) => (
  <span style={style} className={s.dot} />
);
