import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { EXPORT_DATA_TYPE } from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";
import { useSelectedFilters } from "pages/Reports/redux/reducers/filters/filtersReducer";
import { CategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import { ReferenceCategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useReferenceCategory";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { REPORTS_PATHS } from "pages/Reports/utils";
import { setSuccessMessage } from "store/actions/appActions";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { pushReportFetchEvent } from "utils/googleTagManager/dataLayer";

import { ExportTableData, ExportTableDataApi } from "./types";
import {
  mapExportBodyForBE,
  normalizeExportTableData
} from "./utils/mapBEValues";

const refetchInterval = 30_000; // 30 seconds

const pageSize = 50;

const queryKey = "export-table-data";

export const tabMenu = {
  unarchived: "unarchived",
  archived: "archived"
} as const;

export const tableIds = {
  none: "none",
  date: "date",
  datePeriod: "datePeriod",
  vendor: "vendor",
  categories: "categories",
  refCategories: "refCategories",
  segments: "segments",
  dataType: "dataType",
  timeAggregation: "timeAggregation",
  options: "options",
  file: "file"
} as const;

const nextSortOrder: Record<
  "none" | "asc" | "desc",
  "asc" | "desc" | "none"
> = {
  none: "asc",
  asc: "desc",
  desc: "none"
};

const sortingStrategies = {
  [tableIds.none]: () => "",
  [tableIds.date]: (item: ExportTableData) => item.date,
  [tableIds.datePeriod]: (item: ExportTableData) =>
    String([
      item.dateFrom,
      item.dateTo,
      item.compareDateFrom,
      item.compareDateTo
    ]),
  [tableIds.vendor]: (item: ExportTableData) => String(item.vendorNames),
  [tableIds.categories]: (item: ExportTableData) => String(item.categories),
  [tableIds.refCategories]: (item: ExportTableData) =>
    String(item.referenceObjectsNames),
  [tableIds.segments]: (item: ExportTableData) => String(item.segments),
  [tableIds.dataType]: (item: ExportTableData) => item.dataType,
  [tableIds.timeAggregation]: (item: ExportTableData) => item.timelineUnit,
  [tableIds.options]: () => "",
  [tableIds.file]: (item: ExportTableData) => item.status
};

const useExportTableData = () => {
  return useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const response = await HTTP.get<{
        archived: ExportTableDataApi[];
        unarchived: ExportTableDataApi[];
      }>(REST_API_ENDPOINTS.REPORTS.SUPER_EXPORT);
      return normalizeExportTableData(response.data);
    },
    refetchInterval,
    refetchIntervalInBackground: false, // prevent parallel requests
    staleTime: refetchInterval / 2, // half the refetch interval to prevent refreshes
    retry: false
  });
};

export const useGenerateExport = () => {
  const queryClient = useQueryClient();
  const pathname = useSelector(pathnameSelector);
  const isMultiChoice = useSelector(isCompaniesDropdownMultiChoiceSelector);
  const isPowerUser = useSelector(isPowerUserSelector);
  const [[categories, category_level]] = CategoryHooks.useCategoryWithLevel();
  const [refCat] = ReferenceCategoryHooks.useTopLevelCategories();
  const filters = useSelectedFilters();

  let companies = [];
  if (isMultiChoice && filters.vendor_id.length > 1) {
    companies = filters.vendor_id;
  }

  let split_types = filters.export_data_type;
  if (pathname.includes(REPORTS_PATHS.SEGMENTS)) {
    split_types = EXPORT_DATA_TYPE.SEGMENTS;
  }
  if (pathname.includes(REPORTS_PATHS.HOURS)) {
    split_types = EXPORT_DATA_TYPE.HOURS;
  }

  const reference_categories = String(refCat.selected.map(item => item.value));
  let reference_category_level = "";
  if (reference_categories.length) {
    reference_category_level = String(refCat.level);
  }

  const params = mapExportBodyForBE({
    date_from: filters.date_from,
    date_to: filters.date_to,
    compare_date_from: filters.compare_date_from,
    compare_date_to: filters.compare_date_to,
    include_leap_year_day: filters.include_leap_year,
    categories,
    category_level,
    timeline_unit: filters.chart_period,
    materials: filters.product,
    vendor_id: filters.vendor_id,
    companies,
    aggregation: filters.aggregated,
    lfl: filters.lfl,
    voivodeships: filters.voivodeships,
    counties: filters.counties,
    // segments: filters.store_types,
    segments: isPowerUser ? filters.store_types : "", // [PMD-5245]: temporarily disable segments
    split_types,
    client_type: filters.client,
    reference_categories,
    reference_category_level,
    brands: filters.brands,
    sub_brands: filters.sub_brands,
    excluded_days: filters.excluded_days,
    attributes: filters.attributes
  });
  const valid = [
    params.date_from,
    params.date_to,
    params.categories?.length,
    params.category_level,
    params.timeline_unit
  ].every(Boolean);

  return useMutation({
    mutationFn: async () => {
      if (!valid) {
        throw new Error("Invalid parameters");
      }
      await HTTP.post<{ status: string }>(
        REST_API_ENDPOINTS.REPORTS.GENERATE_SUPER_EXPORT,
        params
      );
    },
    onSuccess: () => {
      pushReportFetchEvent("Eksport danych");
      queryClient.invalidateQueries([queryKey]);
    },
    retry: false
  });
};

export const useArchiveExport = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async (id: string) => {
      await HTTP.put(
        `${REST_API_ENDPOINTS.REPORTS.UPDATE_SUPER_EXPORT}${id}/`,
        { is_archived: true }
      );
    },
    onSuccess: () => {
      dispatch(setSuccessMessage("Pomyślnie zarchiwizowano plik."));
      queryClient.invalidateQueries([queryKey]);
    }
  });
};

export const useUpdateStatus = (fetching: boolean) => {
  const initialSeconds = refetchInterval / 1000;
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    if (fetching) {
      setSeconds(initialSeconds);
      return;
    }
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(s => s - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [fetching, initialSeconds, seconds]);
  return fetching
    ? "Pobieranie aktualnej listy plików..."
    : `Automatyczne odświeżenie danych nastąpi za ${seconds} sek.`;
};

export const useTable = () => {
  const [tab, setTab] = useState<keyof typeof tabMenu>(tabMenu.unarchived);
  const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState<{
    id: keyof typeof tableIds;
    order: keyof typeof nextSortOrder;
  }>({ id: tableIds.none, order: "none" });
  const { data, isFetching } = useExportTableData();

  const selectedDataset = useMemo(() => {
    let dataset = data?.unarchived;
    if (tab === tabMenu.archived) dataset = data?.archived;
    return dataset || [];
  }, [data?.archived, data?.unarchived, tab]);

  const sortedDataset = useMemo(() => {
    if (sorting.id === tableIds.none || sorting.order === "none") {
      return selectedDataset;
    }
    return [...selectedDataset].sort((a, b) => {
      const valueA = sortingStrategies[sorting.id](a) || "";
      const valueB = sortingStrategies[sorting.id](b) || "";
      return sorting.order === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
  }, [selectedDataset, sorting.id, sorting.order]);

  const paginatedData = useMemo(() => {
    const startIndex = (page - 1) * pageSize;
    return sortedDataset.slice(startIndex, startIndex + pageSize);
  }, [sortedDataset, page]);

  const onTabChange = useCallback((tab: keyof typeof tabMenu) => {
    setTab(tab);
    setPage(1);
  }, []);

  const onSortingChange = useCallback((id: keyof typeof tableIds) => {
    setSorting(state => ({
      id,
      order: id === state.id ? nextSortOrder[state.order] : "asc"
    }));
    setPage(1);
  }, []);

  const totalPages = Math.ceil(sortedDataset.length / pageSize);

  const onPageChange = useCallback(
    (page: number) => {
      setPage(Math.min(Math.max(1, page), totalPages));
    },
    [totalPages]
  );

  return {
    tab,
    sorting,
    data: paginatedData,
    isFetching,
    currentPage: page,
    totalPages,
    onTabChange,
    onSortingChange,
    onPageChange
  };
};
