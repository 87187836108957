import * as React from "react";

import cn from "classnames";

import { ICON_SIZES } from "utils/";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const Plus = ({
  className,
  color = "#fff",
  size = ICON_SIZES.NORMAL
}: Props) => {
  const currentSize = SIZES[size];
  return (
    <svg
      className={cn(className)}
      width={currentSize}
      height={currentSize}
      viewBox={`0 0 16 16`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M12 6H8V2a1 1 0 10-2 0v4H2a1 1 0 100 2h4v4a1 1 0 002 0V8h4a1 1 0 000-2z"
      />
    </svg>
  );
};
