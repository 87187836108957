export const transformSummary = <T>(
  data: T,
  keys: string[],
  suffix: string
) => {
  return Object.keys(data).reduce((acc, key) => {
    const dataKey = (() => {
      if (!suffix || !keys.includes(key)) {
        return key;
      }

      if (key.endsWith("Change")) {
        return key.replace("Change", `${suffix}Change`);
      }

      if (key.endsWith("LastYear")) {
        return key.replace("LastYear", `${suffix}LastYear`);
      }

      return `${key}${suffix}`;
    })() as keyof T;

    return { ...acc, [key]: data[dataKey] };
  }, {}) as T;
};
