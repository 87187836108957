import React, { FC } from "react";
import { TooltipRenderProps } from "react-joyride";

import { Button, Text } from "components/atoms";
import { TEXT_WRAPPERS } from "utils";

import s from "./tourTooltip.module.scss";

export const TourTooltip: FC<TooltipRenderProps> = ({
  step,
  primaryProps,
  tooltipProps
}) => {
  return (
    <div className={s.tooltip} {...tooltipProps}>
      {step.title && (
        <Text Wrapper={TEXT_WRAPPERS.SPAN} className={s.title}>
          {step.title}
        </Text>
      )}
      <Text Wrapper={TEXT_WRAPPERS.P} className={s.content}>
        {step.content}
      </Text>
      <Button className={s.button} {...primaryProps}>
        Ok, rozumiem
      </Button>
    </div>
  );
};
