import { appendInsightId } from "pages/Insights/partials/InsightsBox/utils";
import {
  SharesApi,
  SharesDataset
} from "pages/Reports/sections/Shares/redux/types";
import { getOwnSummary, getReferenceSummary } from "pages/Reports/utils";
import { createDatasetObject } from "pages/Reports/utils/createDatasetObject";
import { normalizeSharesResponse } from "pages/Reports/utils/normalize/normalizeSharesResponse";
import { normalizeSharesSummary } from "pages/Reports/utils/normalize/normalizeSharesSummary";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { pushReportFetchEvent } from "utils/googleTagManager/dataLayer";
import { Periods } from "utils/types";

export const SharesService = {
  fetchSharesData: async (queryString: string, period: Periods) => {
    const url = appendInsightId(
      `${REST_API_ENDPOINTS.REPORTS.SHARES}?${queryString}`
    );
    const { data } = await HTTP.get<SharesApi>(url);

    const ownSummary = getOwnSummary(data.summary);
    const referenceSummary = getReferenceSummary(data.summary);
    const standardizedData: SharesDataset = createDatasetObject({
      query: queryString,
      currentPeriod: normalizeSharesResponse(data.data),
      ownSummary: normalizeSharesSummary(ownSummary),
      referenceSummary: referenceSummary
        ? normalizeSharesSummary(referenceSummary)
        : null,
      periodType: period,
      info: data.info
    });

    pushReportFetchEvent("Udziały");

    return standardizedData;
  }
};
