import { useSelector } from "react-redux";

import { useGetDynamicsByPeriod } from "api/dynamics/mappers";
import { getDataTypeKey, getRankingPromotionsSidebar } from "api/utils";
import { formatSidebarValue } from "api/utils/formatSidebarValue";
import { mergeAll } from "ramda";

import {
  checkPromotionOccurence,
  transformPromotionsForTable
} from "components/atoms/PromotionContent/utils";
import { useSidebarMetric } from "components/organisms/SidebarMetricSelect/SidebarMetricSelect";
import { useFiltersQP } from "hooks";
import {
  FetchedPromotionTypes,
  usePromotionsForDates
} from "hooks/usePromotionsForDates";
import { useLegendContentNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendContentNames";
import { createLegendId } from "pages/Reports/partials/Chart/Legend/utils/createLegendId";
import { useDataTypeSuffix } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import {
  hiddenLinesSelector,
  timepointSelector
} from "pages/Reports/redux/reducers/chartReducer";
import { findPromotionsForProduct } from "pages/Reports/redux/utils";
import { getSidebarHeader } from "pages/Reports/redux/utils/getSidebarHeader";
import { sortSidebarData } from "pages/Reports/redux/utils/sortSidebarData";
import { useGetZappkaSharesSuffix } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import { checkedDataTypes } from "pages/Reports/sections/utils/chartData";
import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import { valueByDataTypeBoolean } from "pages/Reports/utils";
import {
  chartPeriodSelector,
  chartSidebarQueryParamsSelector,
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { PERIOD_TYPE } from "utils";

export const useDynamicsSidebarData = () => {
  const chartPeriod = useSelector(chartPeriodSelector);
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const timepoint = useSelector(timepointSelector);
  const hiddenLines = useSelector(hiddenLinesSelector);
  const promotions = usePromotionsForDates({
    fetchedPromotionTypes: FetchedPromotionTypes.BOTH
  });
  const { dateFromQP, dateToQP } = useFiltersQP();
  const {
    sidebarSortBy,
    sidebarDesc,
    sidebarSortType,
    sidebarSortGroup,
    sidebarSortEnd
  } = useSelector(chartSidebarQueryParamsSelector);
  const contentNames = useLegendContentNames();
  const [suffix1, suffix2] = useDataTypeSuffix();
  const getZappkaSharesSuffix = useGetZappkaSharesSuffix();
  const { value: sidebarDataType } = useSidebarMetric();

  const { data: dynamics } = useGetDynamicsByPeriod();

  if (chartPeriod === PERIOD_TYPE.RANKING) {
    return getRankingPromotionsSidebar({
      dateFromQP,
      dateToQP,
      promotions
    });
  }

  if (timepoint === null || timepoint < 0 || !dynamics) {
    return null;
  }

  const dataTypes = checkedDataTypes([
    firstChartType,
    secondChartType,
    sidebarDataType
  ]).map((dataType, index) => {
    const sharesSuffix = getZappkaSharesSuffix(firstChartType ? index : 1);
    return {
      baseKey: dataType,
      primaryKey: getDataTypeKey({
        dataType,
        suffix: suffix1,
        sharesSuffix
      }),
      secondaryKey: getDataTypeKey({
        dataType,
        suffix: suffix2,
        sharesSuffix,
        isSecondary: true
      })
    };
  });

  const data = dynamics.currentPeriod
    .filter(period =>
      dataTypes.some(type =>
        valueByDataTypeBoolean(
          type.primaryKey,
          period.existingMetrics,
          { base: true, historical: false, both: false },
          type.secondaryKey
        )
      )
    )
    .map(period => {
      const { vendorId, resultId, type, timeline } = period;

      const legendId = createLegendId(
        getChartLabel({
          vendorId,
          type,
          resultId
        }),
        contentNames,
        period.type
      );

      const isInHiddenLines = hiddenLines.other.includes(legendId);

      return {
        label: resultId,
        vendorId,
        type,
        data: timeline[timepoint],
        legendId,
        isInHiddenLines
      };
    });

  const regularDate = data[0]?.data?.date || "";

  if (!data.length || !regularDate) {
    return null;
  }

  const sortedData = sortSidebarData(
    sidebarSortBy,
    sidebarDesc,
    sidebarSortType,
    sidebarSortGroup,
    sidebarSortEnd
  )(data);

  const hoveredDate = newDateByTimezone(data[0]?.data?.date);
  const promotionsInDate = checkPromotionOccurence(hoveredDate, promotions);
  const promotionsForSidebar = transformPromotionsForTable(promotionsInDate);

  const lastYearDate = data[0]?.data?.dateLastYear;
  const header = getSidebarHeader(regularDate, lastYearDate, chartPeriod, {
    base: true,
    historical: false,
    both: false
  });

  return {
    header,
    date: newDateByTimezone(data[0].data.date),
    promotions: promotionsForSidebar,
    data: sortedData.map(data =>
      mergeAll(
        dataTypes.map(type => {
          return {
            item: {
              vendorId: data.vendorId,
              type: data.type,
              label: data.label,
              promotions:
                findPromotionsForProduct(data.label, promotionsInDate) || null,
              legendId: data.legendId,
              isInHiddenLines: data.isInHiddenLines
            },
            [type.primaryKey]: {
              firstValue: formatSidebarValue(
                type.baseKey,
                type.primaryKey,
                data.data
              )
            },
            ...(Boolean(type.secondaryKey) && {
              [type.secondaryKey]: {
                firstValue: formatSidebarValue(
                  type.baseKey,
                  type.secondaryKey,
                  data.data
                )
              }
            })
          };
        })
      )
    )
  };
};
