import React from "react";

import { StateChangeOptions } from "downshift";

import { SpinnerLoader } from "components/atoms";
import { Search } from "components/atoms/Icon";
import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import { useBrandBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useBrandFetching";
import { useCompetitorBrandBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useCompetitorBrandFetching";
import { useCompetitorSubBrandBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useCompetitorSubBrandFetching";
import { useReferenceBrandBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useReferenceBrandFetching";
import { useReferenceSubBrandBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useReferenceSubBrandFetching";
import { useSubBrandBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useSubBrandFetching";
import {
  BrandHooks,
  BrandItem,
  BrandType
} from "pages/Reports/redux/reducers/sweetStateHooks/useBrand";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

import { DROPDOWN_LABELS, DROPDOWN_PLACEHOLDERS } from "../constants";
import { filterItems, isActiveFn } from "../utils";
import s from "./brandDropdown.module.scss";

type Props = {
  id: BrandType;
  label: string;
  isDisabled: boolean;
  isLoading: boolean;
};

const mapToDataLayer = (type: BrandType) => {
  switch (type) {
    case BrandType.brand:
      return "brand";
    case BrandType.subBrand:
      return "subrand";
    case BrandType.competingBrand:
      return "suppliers brand";
    case BrandType.competingSubBrand:
      return "suppliers subrand";
    default:
      return type;
  }
};

const config = {
  brand: {
    hook: useBrandBehaviour
  },
  subBrand: {
    hook: useSubBrandBehaviour
  },
  competitorBrand: {
    hook: useCompetitorBrandBehaviour
  },
  competitorSubBrand: {
    hook: useCompetitorSubBrandBehaviour
  },
  referenceBrand: {
    hook: useReferenceBrandBehaviour
  },
  referenceSubBrand: {
    hook: useReferenceSubBrandBehaviour
  }
};

export const BrandDropdown: React.FC<Props> = ({
  id,
  isDisabled,
  isLoading,
  label
}) => {
  const [, actions] = BrandHooks.useBrand();
  const [isOpen, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const [selectedItems] = BrandHooks.useSelectedBrands(id);
  const [allItems] = BrandHooks.useAllBrands(id);
  const [selectedLabel] = BrandHooks.useBrandLabel(id);

  let hook: any = () => {};

  switch (id) {
    case BrandType.brand:
      hook = config.brand.hook;
      break;
    case BrandType.subBrand:
      hook = config.subBrand.hook;
      break;
    case BrandType.competingBrand:
      hook = config.competitorBrand.hook;
      break;
    case BrandType.competingSubBrand:
      hook = config.competitorSubBrand.hook;
      break;
    case BrandType.referenceBrand:
      hook = config.referenceBrand.hook;
      break;
    case BrandType.referenceSubBrand:
      hook = config.referenceSubBrand.hook;
      break;
  }

  hook(isDisabled);

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (selectedItem?: BrandItem | null) => {
    setOpen(false);
    if (!selectedItem) {
      setInputValue(selectedLabel);
      actions.sortBrands(id);
    }
  };

  const handleChange = (item: BrandItem | null) => {
    setOpen(true);
    actions.selectBrand(item, id);
  };

  const handleStateChange = (changes: StateChangeOptions<BrandItem[]>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleInputValueChange = (value: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const openedMenuPlaceholder =
    label === DROPDOWN_LABELS.BRAND
      ? DROPDOWN_PLACEHOLDERS.BRAND.OPEN
      : DROPDOWN_PLACEHOLDERS.SUB_BRAND.OPEN;
  const closedMenuPlaceholder =
    label === DROPDOWN_LABELS.BRAND
      ? DROPDOWN_PLACEHOLDERS.BRAND.CLOSED
      : DROPDOWN_PLACEHOLDERS.SUB_BRAND.CLOSED;

  const tooManyBrandsSelectedAlert =
    (id === BrandType.referenceBrand || id === BrandType.referenceSubBrand) &&
    selectedItems.length > 10
      ? "Zalecana maksymalna ilość wybranych marek referencyjnych to 10. Przy wyborze większej ilości nie gwarantujemy wygenerowania się raportu, więc sugerujemy agregację wybranych marek lub skorzystanie z kategorii referencyjnej."
      : "";

  const handleSelectAllActive = () => {
    actions.selectAllBrands(inputValue, id);
    pushFiltersEvent(`${mapToDataLayer(id)} - select all`);
  };

  const handleClear = () => {
    handleChange(null);
    pushFiltersEvent(`${mapToDataLayer(id)} - clear`);
  };

  React.useEffect(() => {
    if (!isOpen) setInputValue(selectedLabel);
  }, [isOpen, selectedLabel]);

  return (
    <Dropdown<BrandItem[]>
      isMultiChoice
      isDisabled={isDisabled || isLoading}
      className={
        id === "brand" ? s.brandDropdownWrapper : s.subBrandDropdownWrapper
      }
      items={allItems}
      // @ts-ignore
      itemToString={() => {}}
      selectedItem={selectedItems}
      label={label === DROPDOWN_LABELS.BRAND ? label : ""}
      openedMenuPlaceholder={isLoading ? "Pobieranie" : openedMenuPlaceholder}
      closedMenuPlaceholder={isLoading ? "Pobieranie" : closedMenuPlaceholder}
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      onStateChange={handleStateChange}
      // @ts-ignore
      onChange={handleChange}
      onSelectAll={handleSelectAllActive}
      onClear={handleClear}
      input={
        <FilterDropdownInput
          customIcon={isLoading ? <SpinnerLoader size="small" /> : <Search />}
          isTypingEnabled
          automaticInputValue={selectedLabel}
          testId={id}
        />
      }
      menu={
        <FilterDropdownMenu<BrandItem>
          isItemActiveFn={(item, items) => {
            return isActiveFn(item, items);
          }}
          filterItemsFn={filterItems}
          listItem={<FilterDropdownListItem />}
          dropdownAlertText={tooManyBrandsSelectedAlert}
        />
      }
      onOuterClick={() => setOpen(false)}
    />
  );
};
