import { without } from "ramda";

import { ChartDataTypes } from "pages/Reports/types/chart";
import { CHART_DATA_TYPE } from "utils";
import { Tier } from "utils/types";

const PLANOGRAM_DATA_TYPES = [
  CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE,
  CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT
];

const TIER_1_2_HIDDEN_DATA_TYPES = [
  CHART_DATA_TYPE.AVG_RAINFALL,
  CHART_DATA_TYPE.AVG_TEMP
];

export const MULTIPACK_TIER_4_HIDDEN_TYPES = [
  CHART_DATA_TYPE.VALUE_WS,
  CHART_DATA_TYPE.VALUE_WSO,
  CHART_DATA_TYPE.VALUE_WSA,
  CHART_DATA_TYPE.VALUE_WS_WSO,
  CHART_DATA_TYPE.VALUE_WS_WSO_WSA,
  CHART_DATA_TYPE.VALUE_PER_SHOP_WS,
  CHART_DATA_TYPE.VALUE_PER_SHOP_WSO,
  CHART_DATA_TYPE.VALUE_PER_SHOP_WSA,
  CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO,
  CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_WSA,
  CHART_DATA_TYPE.VALUE_SHARES_WS,
  CHART_DATA_TYPE.VALUE_SHARES_WSO,
  CHART_DATA_TYPE.VALUE_SHARES_WSA,
  CHART_DATA_TYPE.VALUE_SHARES_WS_WSO,
  CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA,
  CHART_DATA_TYPE.QUANTITY_SHARES_WS,
  CHART_DATA_TYPE.QUANTITY_SHARES_WSO,
  CHART_DATA_TYPE.QUANTITY_SHARES_WSA,
  CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO,
  CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO,
  CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA,
  CHART_DATA_TYPE.PRICE_AVG_WS,
  CHART_DATA_TYPE.PRICE_AVG_WSO,
  CHART_DATA_TYPE.PRICE_AVG_WSA,
  CHART_DATA_TYPE.PRICE_AVG_WS_WSO,
  CHART_DATA_TYPE.PRICE_AVG_WS_WSO_WSA,
  CHART_DATA_TYPE.VALUE,
  CHART_DATA_TYPE.VALUE_PER_SHOP,
  CHART_DATA_TYPE.AVG_PRICE
];

export const filterPlanogramTypes = (
  dataTypes: ChartDataTypes[],
  isPowerUser: boolean
) => {
  if (isPowerUser) {
    return dataTypes;
  }

  return without(PLANOGRAM_DATA_TYPES, dataTypes);
};

export const filterNaturalUnitsTypes = (
  dataTypes: ChartDataTypes[],
  isPowerUser: boolean,
  countiesQP: string
) => {
  if (isPowerUser || countiesQP === "") {
    return dataTypes;
  }

  return without([CHART_DATA_TYPE.NET_QUANTITY_SALES], dataTypes);
};

export const filterTypesByTier = (dataTypes: ChartDataTypes[], tier: Tier) => {
  if (tier === 1 || tier === 2) {
    return without(TIER_1_2_HIDDEN_DATA_TYPES, dataTypes);
  }

  return dataTypes;
};
