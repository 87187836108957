import { useSelector } from "react-redux";

import { useRankingData } from "api/ranking/mappers";

import { chartPeriodSelector } from "store/selectors/routerSelectors";
import { PERIOD_TYPE } from "utils";

export const useRankingWarnings = (): string[] => {
  const chartPeriod = useSelector(chartPeriodSelector);
  const rankingData = useRankingData();

  if (chartPeriod !== PERIOD_TYPE.RANKING) return [];

  const warnings = rankingData[0]?.info || [];

  return warnings;
};
