import { useSelector } from "react-redux";

import { useGetLocationByPeriod } from "api/location/mappers/useGetLocationByPeriod";
import { getMapChartData } from "api/location/utils/getMapChartData";
import { sortMapCharts } from "api/location/utils/sortMapCharts";
import { values } from "ramda";

import { VOIVODESHIPS } from "components/D3/Voivodeship/constants";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { getChartYKey } from "pages/Reports/redux/utils";
import { MapChartData } from "pages/Reports/sections/Location/redux/types/locationChartTypes";
import { firstChartDataTypeSelector } from "store/selectors/routerSelectors";
import { DATASET_TYPES } from "utils";
import { Values } from "utils/types";

interface LocationMapGeneralChartsData {
  label: string;
  type: Values<typeof DATASET_TYPES>;
  data: MapChartData;
  lastYearData?: MapChartData;
}

export const useLocationMapGeneralChartsData = (): LocationMapGeneralChartsData[] => {
  const dataType = useSelector(firstChartDataTypeSelector);
  const { data } = useGetLocationByPeriod();
  const dataset = useCurrentDataset();

  if (!data) return [];

  const key = getChartYKey(dataType);
  const lastYear = key ? `${key}LastYear` : undefined;
  const base = dataset.historical ? lastYear : key || undefined;

  return data.data.data
    .map(chart => ({
      label: chart.name,
      type: chart.type,
      data: values(VOIVODESHIPS).reduce(
        // @ts-ignore TS complains about key value since it comes from util that's being used for both sales and location
        getMapChartData(chart.voivodeships, base),
        {}
      ),
      ...(dataset.both && {
        lastYearData: values(VOIVODESHIPS).reduce(
          // @ts-ignore TS complains about key value since it comes from util that's being used for both sales and location
          getMapChartData(chart.voivodeships, lastYear),
          {}
        )
      })
    }))
    .sort(sortMapCharts);
};
