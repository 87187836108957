import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { stack, stackOffsetDiverging } from "d3";

import { ChartData } from "components/D3/types";
import s from "pages/Reports/partials/Chart/StandardChart/linesGroup.module.scss";
import { getLineOpacity } from "pages/Reports/partials/Chart/StandardChart/utils/getLineOpacity";
import { useChartScales } from "pages/Reports/partials/Chart/StandardChart/utils/useChartScales";
import {
  SELECT_ALL_TOOLTIP,
  useChartValueTiles
} from "pages/Reports/partials/Chart/StandardChart/utils/useChartValueTiles";
import { hoveredLineSelector } from "pages/Reports/redux/reducers/chartReducer";
import { REPORTS_PATHS } from "pages/Reports/utils";
import { getIsPercentageType } from "pages/Reports/utils/formatTooltipValue";
import {
  areTilesHiddenSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import { isThisPage } from "utils";
import { COLOR, DATASET_TYPES } from "utils/constants";
import { round } from "utils/round";

export const GroupedBarChart = ({
  lineChart,
  width,
  originalWidth
}: {
  lineChart: ChartData;
  width: number;
  originalWidth: number;
}) => {
  const pathname = useSelector(pathnameSelector);
  const hoveredLine = useSelector(hoveredLineSelector);
  const areTilesHidden = useSelector(areTilesHiddenSelector);
  const [{ tiles, showBarChartLabels }, actions] = useChartValueTiles();
  const { yScale } = useChartScales(lineChart, originalWidth);
  const isLocationPage = isThisPage(pathname, REPORTS_PATHS.LOCATION);

  const details = useMemo(() => {
    const chartData = lineChart.leftChart?.chart || [];
    const nonReferenceData = chartData.filter(
      ({ type }) => type !== DATASET_TYPES.REFERENCE
    );
    const dataType = lineChart.leftChart?.dataType || "";
    return {
      chart: nonReferenceData,
      colors: ["", nonReferenceData.map(({ color }) => color)].flat(),
      isPercentageType: getIsPercentageType(dataType),
      isHistoricalGroupedChart: lineChart.isHistoricalGroupedChart
    };
  }, [
    lineChart.isHistoricalGroupedChart,
    lineChart.leftChart?.chart,
    lineChart.leftChart?.dataType
  ]);

  // structurize data as the d3.csvParse() does, but we can't use it due to CSP restrictions
  const data = useMemo(() => {
    const resultMap = new Map();
    details.chart.forEach(c => {
      const timeline = details.isHistoricalGroupedChart
        ? c.lastYearTimeline || []
        : c.timeline;
      timeline.forEach(t => {
        if (!resultMap.has(t.valueX)) {
          resultMap.set(t.valueX, { date: t.valueX });
        }
        const dateEntry = resultMap.get(t.valueX);
        const id = isLocationPage ? `${c.chartName}-${c.label}` : c.id || "";
        dateEntry[id] = String(t.valueY || 0);
      });
    });
    const resultArray: { date: string; [key: string]: string }[] = [];
    resultMap.forEach(value => {
      resultArray.push(value);
    });
    return resultArray;
  }, [details.chart, details.isHistoricalGroupedChart, isLocationPage]);
  const subgroups = data.length ? Object.keys(data[0]) : [];
  const stacked = stack()
    .keys(subgroups)
    // @ts-ignore - issue with d3 types
    .offset(stackOffsetDiverging)(data);

  const labels = useMemo(() => {
    if (!showBarChartLabels) return [];

    return data.map(dataset => {
      return Object.entries(dataset).reduce(
        (acc, [key, strValue]) => {
          if (key !== "date" && strValue !== "0") {
            acc.count += 1;
            acc.sum += Math.round(Number(strValue) * 100);
          }
          return acc;
        },
        { count: 0, sum: 0 }
      );
    });
  }, [data, showBarChartLabels]);

  const timepoint = width / data.length;
  const barWidth = Math.min(60, timepoint) * 0.85; // 0.15 padding
  const canFitTileWidth = barWidth >= 30; // min width for tile

  useEffect(() => {
    actions.updateSelectAllTooltip(
      canFitTileWidth
        ? SELECT_ALL_TOOLTIP
        : "Brak możliwości wyświetlenia etykiet ze względu na zbyt wąskie kolumny"
    );
    return () => {
      actions.updateSelectAllTooltip(SELECT_ALL_TOOLTIP);
    };
  }, [actions, canFitTileWidth]);

  return (
    <>
      {labels.map((label, index) => {
        if (!label.count) {
          return null;
        }

        let { sum } = label;
        if (details.isPercentageType && sum > 9995 && sum < 10005) {
          sum = 10000; // [PMD-4974]: if the sum is really close to 100% then round it
        }

        return (
          <text
            key={`label-${index}`}
            x={timepoint * (index + 0.5)}
            y={-8}
            fontSize={12}
            fill={COLOR.UI_15}
            textAnchor="middle"
          >
            {`${label.count} (${round(sum / 100)})`}
          </text>
        );
      })}
      {stacked.map(data => (
        <g key={`group-${data.index}`} fill={details.colors[data.index]}>
          {data.map((d, index) => {
            const x = timepoint * index + (timepoint - barWidth) / 2;
            const y0 = yScale(d[0]);
            const y1 = yScale(d[1]);
            const rectHeight = y0 - y1 || 0;
            const tile = tiles.find(
              t =>
                t.lineName === data.key &&
                t.timepoint === index &&
                !t.isRightChart &&
                !t.isHistorical
            );
            const canShowTile =
              Boolean(tile) &&
              !areTilesHidden &&
              canFitTileWidth &&
              rectHeight >= 15; // min height for tile
            const isNegative = y1 >= yScale(0);

            return (
              <g
                key={`rect-${index}`}
                className={s.groupedStackChartRect}
                opacity={getLineOpacity({
                  id: data.key,
                  isLocationPage,
                  hoveredLine
                })}
              >
                <rect
                  id={data.key}
                  x={x}
                  y={y1}
                  width={barWidth}
                  height={rectHeight}
                  data-multiline
                />
                {canShowTile && (
                  <text
                    x={x + barWidth / 2}
                    y={y1 + rectHeight / 2}
                    fontSize={12}
                    fill="white"
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    {round(isNegative ? d[0] - d[1] : d[1] - d[0])}
                  </text>
                )}
              </g>
            );
          })}
        </g>
      ))}
    </>
  );
};
