import { groupBy, prop } from "ramda";

import { ChartTimePoint } from "components/D3/types";

export const findMaxMinValueForGroupedBarChart = (
  timeline: ChartTimePoint[]
) => {
  if (!timeline.length) return { max: 0, min: 0 };

  const groupByTimepoint = groupBy<ChartTimePoint>(prop("valueX"));
  const groupedByTimepoint = Object.values(groupByTimepoint(timeline));

  const max = Math.max(
    ...groupedByTimepoint.map(tim =>
      tim.reduce((current, next) => {
        const nextValue = next.valueY ?? 0;
        return current + (nextValue < 0 ? 0 : nextValue);
      }, 0)
    )
  );

  const min = Math.min(
    ...groupedByTimepoint.map(tim =>
      tim.reduce((current, next) => {
        const nextValue = next.valueY ?? 0;
        return current + (nextValue > 0 ? 0 : nextValue);
      }, 0)
    )
  );

  return { max, min };
};
