import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";

import { ToggleButton } from "components/atoms";
import { CHART_PERIODS } from "components/D3/constants";
import { ToggleButtonList } from "components/molecules";
import {
  validatedChartPeriodsSelector,
  validatedTablePeriodsSelector
} from "pages/Reports/redux/reducers/periods/selectors";
import { ChartPeriodHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useChartPeriod";
import { REPORTS_PATHS } from "pages/Reports/utils";
import { updateQueryParams } from "store/actions/routerActions";
import {
  chartPeriodSelector,
  pathnameSelector,
  tablePeriodSelector
} from "store/selectors/routerSelectors";
import { isThisPage } from "utils";
import { CHART_TYPES, PERIOD_TYPE, PERIOD_TYPE_PL } from "utils/constants";
import { QP } from "utils/defaultQueryParams";
import {
  pushChartSwitchEvent,
  pushTableEvent
} from "utils/googleTagManager/dataLayer";
import { Values } from "utils/types";

import s from "./periodMenu.module.scss";
interface Props {
  type?: "chart" | "table";
  className?: string;
  isSmall?: boolean;
}

interface Settings {
  isActive: boolean;
  isShort: boolean;
  label: string;
  shortLabel: string;
  tooltip?: string;
}

export const PeriodMenu = ({
  type = "chart",
  className,
  isSmall = false
}: Props) => {
  const dispatch = useDispatch();
  const pathname = useSelector(pathnameSelector);
  const period = useSelector(chartPeriodSelector);
  const isLocationPage = isThisPage(pathname, REPORTS_PATHS.LOCATION);
  const isTotal = period === CHART_PERIODS.TOTAL;

  const isChart = type === "chart";
  const periods = useSelector(
    isChart ? validatedChartPeriodsSelector : validatedTablePeriodsSelector
  );
  const activePeriod = useSelector(
    isChart ? chartPeriodSelector : tablePeriodSelector
  );

  const [
    chartPeriodState,
    chartPeriodActions
  ] = ChartPeriodHooks.useChartPeriod();

  const changePeriod = (periodType: Values<typeof PERIOD_TYPE>) => {
    const param = type === "chart" ? QP.CHART_PERIOD : QP.TABLE_PERIOD;

    const isRanking = periodType === PERIOD_TYPE.RANKING;
    const isChartPeriod = param === QP.CHART_PERIOD;
    const updateChartType = isRanking || (isLocationPage && isTotal);

    if (isChartPeriod && updateChartType) {
      dispatch(
        updateQueryParams({
          [QP.CHART_TYPE]: CHART_TYPES.LINE
        })
      );
    }

    dispatch(updateQueryParams({ [param]: periodType.toLowerCase() }));

    chartPeriodActions.updateSelectedChartPeriod(
      chartPeriodState.all.find(
        chartPeriod => chartPeriod.value === periodType.toLowerCase()
      ) || chartPeriodState.all[0]
    );

    const translatedPeriod = PERIOD_TYPE_PL[periodType].toLowerCase();
    if (type === "chart") pushChartSwitchEvent(translatedPeriod);
    if (type === "table") pushTableEvent(translatedPeriod);
  };

  const getSettings = (type: Values<typeof PERIOD_TYPE>): Settings => {
    const isActive = type === activePeriod;
    const isShort = !isActive && isSmall;
    const label = PERIOD_TYPE_PL[type] || "";
    const shortLabel = label
      .split(" ")
      .map(text => text.charAt(0).toUpperCase())
      .join("");

    return { isActive, isShort, label, shortLabel };
  };

  return (
    <ToggleButtonList className={className}>
      {periods.map(periodType => {
        const { isActive, isShort, label, shortLabel } = getSettings(
          periodType
        );

        return (
          <ToggleButton
            key={periodType}
            className={{ li: cn({ [s.toggleButtonShort]: isShort }) }}
            label={isShort ? shortLabel : label}
            tooltip={isShort ? label : undefined}
            onClick={() => changePeriod(periodType)}
            isActive={isActive}
            testId={`period-button-${periodType}`}
            ga={{
              [isChart ? "data-ga-graph-time" : "data-ga-table-time"]: label
            }}
          />
        );
      })}
    </ToggleButtonList>
  );
};
