import { useSelector } from "react-redux";

import { useGetShares } from "api/shares/hooks";
import { ReportTypes } from "api/shares/types";

import {
  chartPeriodSelector,
  isReportTableOpenQPSelector,
  tablePeriodSelector
} from "store/selectors/routerSelectors";
import { PERIOD_TYPE } from "utils";

export const useGetSharesByPeriod = (
  dataType: ReportTypes = ReportTypes.CHART
) => {
  const chartPeriod = useSelector(chartPeriodSelector);
  const tablePeriod = useSelector(tablePeriodSelector);

  const isTableOpen = useSelector(isReportTableOpenQPSelector);

  const { queriesData, isLoading, isError, isIdle } = useGetShares();

  if (isLoading || isError) {
    return {
      isLoading,
      isError,
      isIdle,
      data: undefined
    };
  }

  if (dataType === ReportTypes.TABLE && isTableOpen) {
    const query = queriesData.find(query => query?.periodType === tablePeriod);

    return {
      isLoading,
      isError,
      isIdle,
      data: query
    };
  }

  // it's for summary data. Ranking doesn't return summary data so we have to display aggregation total in the summary tabs
  if (dataType === ReportTypes.CHART && chartPeriod === PERIOD_TYPE.RANKING) {
    const query = queriesData.find(
      query => query?.periodType === PERIOD_TYPE.TOTAL
    );
    return {
      isLoading,
      isError,
      isIdle,
      data: query
    };
  }

  const query = queriesData.find(query => query?.periodType === chartPeriod);

  return {
    isLoading,
    isError,
    isIdle,
    data: query
  };
};
