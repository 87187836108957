import * as React from "react";
import { CSSProperties } from "react";
import { useSelector } from "react-redux";

import cn from "classnames";

import { RadioButton, Text, Toggle } from "components/atoms";
import { ChevronDown, ChevronUp } from "components/atoms/Icon";
import { Checkbox, InputText } from "components/molecules";
import c from "components/molecules/ChartHeader/chartHeader.module.scss";
import { getSelectedCountLabel } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  chartPeriodSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import { toArray } from "store/utils";
import {
  CHART_DATA_TYPE,
  getPolishLabelForChartHeader,
  ICON_SIZES,
  isThisPage,
  PERIOD_TYPE,
  pushChartTypeChange
} from "utils";

import { DropdownType } from "../ChartHeader/hooks/useDropdownTypes";
import s from "./chartCheckboxDropdown.module.scss";
import { useGetDisabledDataTypeTooltip } from "./hooks";

interface Props {
  dataTypes: ChartDataTypes[];
  checkedDataType: ChartDataTypes | ChartDataTypes[];
  onChangeDataType: (
    dataType: ChartDataTypes,
    dropdownType: DropdownType
  ) => void;
  dropdownType: DropdownType;
  prefix?: string;
  gradientColors?: { [key: string]: string };
  onSelectAll?: () => void;
  onClear?: () => void;
  showInputCounter?: boolean;
  noDataTypesLabel?: string;
  id?: {
    base?: string;
    inputWrapper?: string;
  };
  className?: string;
  isDisabled?: boolean;
  tooltipText?: string;
}

interface ActionButtonsProps {
  disabled: boolean;
  buttons: { testId: string; label: string; onClick?: () => void }[];
}

const ActionButtons = ({ disabled, buttons }: ActionButtonsProps) => {
  const filtered = buttons.filter(({ onClick }) => onClick);

  if (!filtered.length) return null;

  return (
    <div className={s.actionButtons}>
      {filtered.map(({ testId, label, onClick }) => (
        <button
          key={testId}
          data-testid={testId}
          type="button"
          className={cn({
            [s.actionButton]: true,
            [s.actionButtonDisabled]: disabled
          })}
          onClick={onClick}
          disabled={disabled}
        >
          <Text className={s.actionButtonText}>{label}</Text>
        </button>
      ))}
    </div>
  );
};

export const ChartDropdown: React.FC<Props> = ({
  dataTypes,
  checkedDataType,
  onChangeDataType,
  dropdownType,
  prefix = "",
  gradientColors = {},
  onSelectAll,
  onClear,
  showInputCounter = false,
  noDataTypesLabel = "Brak promocji w wybranym zakresie czasu",
  id,
  className,
  isDisabled = false,
  tooltipText = ""
}) => {
  const pathname = useSelector(pathnameSelector);
  const period = useSelector(chartPeriodSelector);
  const getDisabledDataTypeTooltip = useGetDisabledDataTypeTooltip();

  const types = dataTypes.map(type => {
    const tooltip = getDisabledDataTypeTooltip(type);

    return {
      value: type,
      tooltip: tooltip.value,
      isDisabled: tooltip.isDisabled
    };
  });

  const isLocationTotal =
    isThisPage(pathname, REPORTS_FULL_PATHS.LOCATION_PATH) &&
    period.toUpperCase() === PERIOD_TYPE.TOTAL;

  const checkedDataTypeArray = toArray(checkedDataType);
  const checkedDataTypeLabel = getPolishLabelForChartHeader(
    checkedDataTypeArray[0]
  );

  const handleChange = (type: ChartDataTypes, isChecked: boolean) => {
    onChangeDataType(type, dropdownType);
    pushChartTypeChange(type, isChecked);
  };

  return (
    <div
      id={id?.base}
      className={cn(c.wrapper, className, isLocationTotal && c.locationTotal)}
    >
      {prefix && <Text className={c.prefix}>{prefix}</Text>}
      <Toggle onClickOutside="close">
        {({ isOpen, setOpen }) => (
          <div className={s.relative}>
            <div
              id={id?.inputWrapper}
              className={s.inputWrapper}
              data-testid="chart-checkbox-dropdown"
            >
              <InputText
                className={{ input: s.input }}
                readOnly
                value={
                  showInputCounter && checkedDataTypeArray[0] !== ""
                    ? getSelectedCountLabel(
                        checkedDataTypeArray.length,
                        types.length
                      )
                    : checkedDataTypeLabel
                }
                onClick={() => setOpen(!isOpen)}
                placeholder="Wybierz"
                disabled={isDisabled}
                tooltipText={tooltipText}
              />
              {isOpen ? (
                <ChevronUp className={s.chevronIcon} size={ICON_SIZES.LARGE} />
              ) : (
                <ChevronDown
                  className={s.chevronIcon}
                  size={ICON_SIZES.LARGE}
                />
              )}
            </div>
            {isOpen &&
              (types.length ? (
                <div
                  className={s.dropdownWrapper}
                  data-testid="chart-checkbox-dropdown-wrapper"
                >
                  <ActionButtons
                    disabled={types.every(({ isDisabled }) => isDisabled)}
                    buttons={[
                      {
                        testId: "action-button-select-all",
                        label: "Zaznacz wszystkie",
                        onClick: onSelectAll
                      },
                      {
                        testId: "action-button-clear",
                        label: "Wyczyść",
                        onClick: onClear
                      }
                    ]}
                  />
                  <ul className={s.dropdownList}>
                    {types.map(({ value, tooltip, isDisabled }) => {
                      if (value === CHART_DATA_TYPE.NONE) return null;

                      const isChecked = checkedDataTypeArray.includes(value);

                      const onChange = () => {
                        handleChange(value, isChecked);

                        if (!onSelectAll) {
                          setOpen(false);
                        }
                      };

                      return (
                        <li
                          key={value}
                          className={cn({
                            [s.dropdownElement]: true,
                            [s.blockedDropdownElement]: !!tooltip,
                            [s.dropdownElementGradient]:
                              !tooltip && Boolean(gradientColors)
                          })}
                          style={
                            {
                              "--gradient-color": gradientColors[value]
                            } as CSSProperties
                          }
                          data-tooltip={tooltip}
                        >
                          {dropdownType === "radio" ? (
                            <RadioButton
                              className={{ base: s.radioButtonBase }}
                              isChecked={isChecked}
                              label={getPolishLabelForChartHeader(value)}
                              onChange={onChange}
                              isDisabled={isDisabled}
                            />
                          ) : (
                            <Checkbox
                              className={{ base: s.radioButtonBase }}
                              isChecked={isChecked}
                              label={getPolishLabelForChartHeader(value)}
                              onChange={onChange}
                              isDisabled={isDisabled}
                              isSmall
                            />
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <div className={s.dropdownWrapper}>
                  <Text className={s.noDataTypesLabel}>{noDataTypesLabel}</Text>
                </div>
              ))}
          </div>
        )}
      </Toggle>
    </div>
  );
};
