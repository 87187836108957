import { createSelector } from "reselect";

import { Option } from "components/molecules/SelectMenu/SelectMenu";
import { AppState } from "store";

const userManagementSelector = (state: AppState) => state.admin.userManagement;

export const fetchedUsersSelector = createSelector(
  userManagementSelector,
  userManagement => userManagement.fetchedUsers
);

export const isFetchingUsersSelector = createSelector(
  userManagementSelector,
  userManagement => userManagement.isFetching
);

export const usersErrorSelector = createSelector(
  userManagementSelector,
  userManagement => userManagement.error
);

export const isUserActionInProgressSelector = createSelector(
  userManagementSelector,
  userManagement => userManagement.isUserActionInProgress
);

export const visibleUserPopupSelector = createSelector(
  userManagementSelector,
  userManagement => userManagement.visibleUserPopup
);

export const maxUsersSelector = createSelector(
  userManagementSelector,
  userManagement => userManagement.maxUsers || 0
);

export const usersCountSelector = createSelector(
  userManagementSelector,
  userManagement => userManagement.usersCount || 0
);

export const fetchedCompaniesSelector = createSelector(
  userManagementSelector,
  (userManagement): Option[] => {
    return userManagement.fetchedCompanies.map(company => ({
      label: company.name,
      value: company.id
    }));
  }
);
