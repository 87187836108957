import { getRange } from "pages/Reports/partials/Chart/StandardChart/utils/getRange";
import { DEFAULT_CHART_TICKS_AMOUNT } from "utils";

export const getAxisTickValues = ({
  max,
  min,
  ticksAmount = DEFAULT_CHART_TICKS_AMOUNT,
  fillWithZero = false
}: {
  max: number;
  min: number;
  ticksAmount?: number;
  fillWithZero?: boolean; // this specifies if ticks should have "0" added
}) => {
  if (max === 0 && min === 0) {
    return [0];
  }
  return getRange(min, max, ticksAmount, fillWithZero);
};
