import React, { FC } from "react";

import cn from "classnames";

import { Heading, Text } from "components/atoms";
import { TooltipIcon } from "components/atoms/Icon";
import { SummaryTrend } from "components/molecules";
import {
  HEADER_TYPES,
  TEXT_SIZES,
  TEXT_TYPES,
  TEXT_WRAPPERS
} from "utils/constants";
import { Nullable } from "utils/types";

import s from "./Summary.module.scss";

interface Props {
  firstValue: string;
  secondValue: Nullable<string>;
  thirdValue: Nullable<string>;
  firstValueBottom?: Nullable<string>;
  secondValueBottom?: Nullable<string>;
  thirdValueBottom?: Nullable<string>;
  label?: string;
  className?: string;
  headingType: typeof HEADER_TYPES.MAIN_NUMBER | typeof HEADER_TYPES.H3;
  change: number;
  changeBottom?: Nullable<number>;
  tooltipText?: string;
}

export const Summary: FC<Props> = ({
  headingType,
  firstValue,
  secondValue,
  thirdValue,
  firstValueBottom = null,
  secondValueBottom = null,
  thirdValueBottom = null,
  changeBottom = null,
  label,
  className,
  change,
  tooltipText = ""
}) => {
  return (
    <div className={s.base}>
      <Text
        type={TEXT_TYPES.LABEL}
        Wrapper={TEXT_WRAPPERS.SPAN}
        size={TEXT_SIZES.EXTRA_SMALL}
        className={cn(s.label, className, {
          [s.labelWithTooltip]: !!tooltipText
        })}
      >
        {label}
        {tooltipText && (
          <div className={s.tooltipIconWrapper} data-tooltip={tooltipText}>
            <TooltipIcon className={s.tooltipIcon} />
          </div>
        )}
      </Text>
      <div className={s.headingWrapper}>
        <Heading
          type={headingType}
          element={HEADER_TYPES.H3}
          className={cn(s.headingValue, s.marginRight)}
        >
          {firstValue}
        </Heading>
        <Heading
          type={headingType}
          element={HEADER_TYPES.H5}
          className={s.headingValueBottom}
        >
          {firstValueBottom}
        </Heading>
      </div>
      <SummaryTrend
        leftValue={secondValue}
        rightValue={thirdValue}
        change={change}
        leftValueBottom={secondValueBottom}
        rightValueBottom={thirdValueBottom}
        changeBottom={changeBottom}
        outputFormat={",.2f"}
      />
    </div>
  );
};
