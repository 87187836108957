const KEYS = {
  REQUEST_INSIGHT: "REQUEST_INSIGHT"
};

export const REQUEST_INSIGHT = {
  get() {
    return localStorage.getItem(KEYS.REQUEST_INSIGHT);
  },
  set(id: string) {
    localStorage.setItem(KEYS.REQUEST_INSIGHT, id);
  },
  remove() {
    localStorage.removeItem(KEYS.REQUEST_INSIGHT);
  }
};

export const appendInsightId = (url: string) => {
  const insightId = REQUEST_INSIGHT.get();

  if (insightId) {
    REQUEST_INSIGHT.remove();
    return `${url}&insight_id=${insightId}`;
  }

  return url;
};
