import { ScaleLinear } from "d3";

export const scaleAndVertical = (
  yScale: ScaleLinear<number, number>,
  height: number,
  valueY: number,
  marginTop: number = 0
) => {
  const [firstDomain, secondDomain] = yScale.domain();

  const fakeValue = (Math.abs(firstDomain) + Math.abs(secondDomain)) / 7 / 2;
  const isDomainPositive = firstDomain >= 0 && secondDomain >= 0;
  const isDomainNegative = firstDomain <= 0 && secondDomain <= 0;
  const isDomainPositiveAndNegative = firstDomain >= 0 && secondDomain <= 0;

  let scaledHeight = 0;
  let offsetTop = 0;

  if (isDomainPositive) {
    if (valueY === 0) {
      scaledHeight = yScale(firstDomain - fakeValue);
      offsetTop = yScale(fakeValue) + marginTop;
    }

    const showFakeBar = valueY < fakeValue;
    if (showFakeBar) {
      scaledHeight = height - yScale(fakeValue);
      offsetTop = yScale(fakeValue) + marginTop;
    } else {
      scaledHeight = height - yScale(valueY);
      offsetTop = yScale(valueY) + marginTop;
    }
    return {
      scaledHeight,
      offsetTop
    };
  }

  if (isDomainNegative) {
    if (valueY === 0) {
      scaledHeight = yScale(firstDomain - fakeValue);
      offsetTop = yScale(fakeValue) + marginTop;
    }
    const showFakeBar = Math.abs(valueY) < Math.abs(fakeValue);
    if (showFakeBar) {
      scaledHeight = yScale(fakeValue);
      offsetTop = yScale(0) + marginTop;
    } else {
      scaledHeight = yScale(valueY);
      offsetTop = yScale(0) + marginTop;
    }
    return {
      scaledHeight,
      offsetTop
    };
  }
  if (isDomainPositiveAndNegative) {
    if (valueY === 0) {
      scaledHeight = yScale(firstDomain - fakeValue);
      offsetTop = yScale(fakeValue) + marginTop;
    }

    const showFakeBar = Math.abs(valueY) < Math.abs(fakeValue);
    if (showFakeBar) {
      if (valueY < 0) {
        scaledHeight = yScale(firstDomain - fakeValue);
        offsetTop = yScale(0) + marginTop;
      }
      if (valueY > 0) {
        scaledHeight = yScale(firstDomain - fakeValue);
        offsetTop = yScale(fakeValue) + marginTop;
      }
    } else {
      if (valueY < 0) {
        scaledHeight = yScale(valueY + firstDomain);
        offsetTop = yScale(0) + marginTop;
      }
      if (valueY > 0) {
        scaledHeight = height - yScale(valueY + secondDomain);
        offsetTop = yScale(valueY);
      }
    }
    return {
      scaledHeight,
      offsetTop
    };
  }
  return {
    scaledHeight,
    offsetTop
  };
};
