import Image from "assets/images/super-export-modal.png";
import cn from "classnames";

import { Button, Portal } from "components/atoms";
import { Close } from "components/atoms/Icon";
import { PopupWindow } from "components/molecules";

import s from "./exportRedirectModal.module.scss";

interface Props {
  onClose: VoidFunction;
  onPrimaryClick: VoidFunction;
  onSecondaryClick: VoidFunction;
}

export const ExportRedirectModal = ({
  onClose,
  onPrimaryClick,
  onSecondaryClick
}: Props) => {
  return (
    <Portal id="js-export-redirect-modal">
      <PopupWindow className={s.overlay}>
        <div className={s.container}>
          <button className={s.closeButton} onClick={onClose}>
            <Close />
          </button>
          <div>
            <h2 className={s.title}>
              Potrzebujesz pliku Excel z danymi sprzedażowymi Twoich produktów?
            </h2>
            <p className={s.text}>
              Zachęcamy do korzystania z zakładki Eksport Danych, gdzie można
              pobrać rozszerzone pliki z danymi!
            </p>
            <h3 className={s.subtitle}>
              Dlaczego warto korzystać z nowego rodzaju eksportu danych?
            </h3>
            <ol className={cn(s.text, s.list)}>
              <li>
                Pobierzesz od razu wszystkie miary (ok. 100) dostępnych w
                portalu plus kilka dodatkowych!
              </li>
              <li>
                Wygenerujesz plik z danymi dla wszystkich produktów własnych w
                ramach wybranej kategorii G1.
              </li>
              <li>
                Zobaczysz dodatkowe informacje o produktach (np. informacje o
                przypisaniu produktów do konkretnych kategorii i marek).
              </li>
              <li>
                Uzyskasz szczegółowe dane (per województwo lub powiat, per
                segment, sprzedaż godzinowa)".
              </li>
            </ol>
            <Button className={s.buttonPrimary} onClick={onPrimaryClick}>
              Wygeneruj rozszerzony eksport i przejdź na zakładkę Eksport Danych
            </Button>
            <button className={s.buttonSecondary} onClick={onSecondaryClick}>
              Wygeneruj dotychczasowy uproszczony plik w formacie Excel
            </button>
          </div>
          <img className={s.image} src={Image} alt="" />
        </div>
      </PopupWindow>
    </Portal>
  );
};
