import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Manager, Popper, Reference } from "react-popper";
import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";

import { Button, Portal, RadioButton, Text } from "components/atoms";
import { ChevronDown } from "components/atoms/Icon";
import { Card, Checkbox } from "components/molecules";
import { TableColumnHeader } from "components/types";
import {
  SidebarSortingTypes,
  useSidebarSorting
} from "hooks/useSidebarSorting";
import { isCompetitionEnabledSelector } from "pages/Reports/redux/selectors/competitionSelectors";
import { updateQueryParams } from "store/actions/routerActions";
import { chartSidebarQueryParamsSelector } from "store/selectors/routerSelectors";
import {
  BOOL_STRING_VALUES,
  getPolishLabelForChartHeader,
  ICON_SIZES,
  TEXT_WRAPPERS
} from "utils";
import { QP } from "utils/defaultQueryParams";
import { pushSidebarEvent } from "utils/googleTagManager/dataLayer";

import s from "./sidebarTableHeader.module.scss";

interface Props<Data> {
  column: TableColumnHeader<Data>;
  testId?: string;
}

const sendSortDataLayer = (
  sidebarSortBy: string,
  sidebarDesc: string,
  sidebarSortGroup: string
) => {
  const sidebarSortByPolishLabel = getPolishLabelForChartHeader(sidebarSortBy);
  const sidebarDescValue =
    sidebarDesc === BOOL_STRING_VALUES.TRUE ? "DESC" : "ASC";
  const sidebarGroupValue =
    sidebarSortGroup === BOOL_STRING_VALUES.TRUE ? "grouped" : "";

  const sidebarEventValue = `sort: ${sidebarSortByPolishLabel} ${sidebarDescValue} ${sidebarGroupValue}`;
  pushSidebarEvent(sidebarEventValue);
};

//extends keyword must be included https://github.com/Microsoft/TypeScript/issues/4922
export const SidebarTableHeader = <Data extends {}>({
  column,
  testId
}: Props<Data>) => {
  const dispatch = useDispatch();
  const [isSortingBoxOpen, setSortingBoxOpen] = useState<boolean>(false);
  const {
    sidebarSortBy,
    sidebarDesc,
    sidebarSortType,
    sidebarSortGroup,
    sidebarSortEnd
  } = useSelector(chartSidebarQueryParamsSelector);
  const isCompetitionEnabled = useSelector(isCompetitionEnabledSelector);
  const sidebarSorting = useSidebarSorting(column.id);
  const isSortingActive = sidebarSortBy === column.id;

  const onSortingBoxClose = () => {
    setSortingBoxOpen(false);
    sendSortDataLayer(sidebarSortBy, sidebarDesc, sidebarSortGroup);
  };

  const handleGroupChange = () => {
    dispatch(
      updateQueryParams({
        [QP.SIDEBAR_SORT_GROUP]:
          sidebarSortGroup === BOOL_STRING_VALUES.TRUE
            ? BOOL_STRING_VALUES.FALSE
            : BOOL_STRING_VALUES.TRUE
      })
    );
  };

  const handleSortEndChange = () => {
    dispatch(
      updateQueryParams({
        [QP.SIDEBAR_SORT_END]:
          sidebarSortEnd === BOOL_STRING_VALUES.TRUE
            ? BOOL_STRING_VALUES.FALSE
            : BOOL_STRING_VALUES.TRUE
      })
    );
  };

  const handleSortChange = (
    isDescending: boolean,
    sortingType: SidebarSortingTypes
  ) => {
    dispatch(
      updateQueryParams({
        [QP.SIDEBAR_SORT_BY]: String(column.id),
        [QP.SIDEBAR_SORT_TYPE]: String(sortingType),
        [QP.SIDEBAR_DESC]: String(isDescending)
      })
    );
  };

  if (typeof column.Header !== "string") {
    return column.render("Header");
  }

  return (
    <Manager>
      <div className={s.headerWrapper}>
        <Text>
          <Text
            className={s.headerText}
            testId={testId}
            Wrapper={TEXT_WRAPPERS.SPAN}
          >
            {column.Header}
          </Text>
        </Text>
        <Reference>
          {({ ref }) => (
            <button
              ref={ref}
              type="button"
              data-testid={`${column.id}-sidebar-sort-button`}
              className={cn(s.sortHeaderButton, {
                [s.sortHeaderButtonActive]: isSortingActive
              })}
              onClick={() => setSortingBoxOpen(!isSortingBoxOpen)}
            >
              <ChevronDown
                size={ICON_SIZES.NORMAL}
                className={cn(s.chevron, {
                  [s.chevronActive]: isSortingActive
                })}
              />
            </button>
          )}
        </Reference>
        {isSortingBoxOpen && (
          <Popper modifiers={{ offset: { offset: "0,15" } }}>
            {({ ref, style, placement }) => (
              <Portal id="js-sidebar-sortbox">
                <div
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  className={s.sidebarSortBox}
                >
                  <OutsideClickHandler onOutsideClick={onSortingBoxClose}>
                    <Card
                      className={{
                        base: s.sidebarSortBoxWrapper
                      }}
                    >
                      <p className={s.sortingTitle}>{sidebarSorting.title}</p>
                      {sidebarSorting.options.map(
                        ({ label, isDescending, sortingType }) => {
                          const isChecked =
                            sidebarSortBy === column.id &&
                            sidebarDesc === String(isDescending) &&
                            sidebarSortType === sortingType;

                          return (
                            <RadioButton
                              className={{ base: s.sortingRadioButton }}
                              label={label}
                              isChecked={isChecked}
                              onChange={() =>
                                handleSortChange(isDescending, sortingType)
                              }
                              key={`${column.id}-${label}`}
                            />
                          );
                        }
                      )}
                      {isCompetitionEnabled && (
                        <Checkbox
                          className={{ label: s.sortingCheckboxButton }}
                          isChecked={
                            sidebarSortGroup === BOOL_STRING_VALUES.TRUE
                          }
                          label="Pogrupuj (najpierw dane własne, potem konkurencji)"
                          onChange={handleGroupChange}
                          isSmall
                        />
                      )}
                      <Checkbox
                        className={{
                          label: s.sortingCheckboxButton,
                          tooltipIcon: s.tooltipIcon
                        }}
                        isChecked={sidebarSortEnd === BOOL_STRING_VALUES.TRUE}
                        label="Przenieś odznaczone produkty na dół panelu bocznego"
                        iconTooltipText="1. Zaznacz ten checkbox jeśli chcesz, aby odznaczone produkty automatycznie były przesuwane na sam dół, a zaznaczone wracały na górę panelu bocznego. 2. Jeżeli chcesz wybrać wiele elementów naraz przytrzymaj przycisk Shift i kliknij w pierwszy oraz ostatni element (analogicznie jak w Excelu)."
                        onChange={handleSortEndChange}
                        isSmall
                      />
                      <Button
                        className={s.acceptButton}
                        onClick={onSortingBoxClose}
                      >
                        OK
                      </Button>
                    </Card>
                  </OutsideClickHandler>
                </div>
              </Portal>
            )}
          </Popper>
        )}
      </div>
    </Manager>
  );
};
