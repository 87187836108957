import { useActiveCompanyName } from "api/utils/useActiveCompanyName";

import { Heading } from "components/atoms";
import { PeriodMenu, ReportDownloadDropdown } from "components/molecules";
import s from "pages/Reports/sections/sections.module.scss";
import { EXPORT_TYPES } from "pages/Reports/utils/constants";
import { REPORT_TYPES } from "store/utils/constants";
import { DEFAULT_DATA_EXPORT_DROPDOWN_HEADER, HEADER_TYPES } from "utils";

export const TableSectionHeading = () => {
  const activeCompany = useActiveCompanyName();

  return (
    <>
      <Heading className={s.heading} type={HEADER_TYPES.H4}>
        Tabela - dane własne {activeCompany && `(${activeCompany})`}
      </Heading>
      <div className={s.periodMenuWrapper}>
        <PeriodMenu type="table" className={s.tablePeriodMenu} />
        <ReportDownloadDropdown
          withHeader={false}
          type={REPORT_TYPES.TABLE}
          reportFormatsAllowed={[EXPORT_TYPES.EXCEL]}
          ga={{ "data-ga-table": "Eksport danych" }}
          reportDownloadHeader={DEFAULT_DATA_EXPORT_DROPDOWN_HEADER}
        />
      </div>
    </>
  );
};
