import { MovableTooltipWrapper } from "components/atoms";
import { Tooltip as TooltipCmp } from "components/D3";
import {
  TooltipBody,
  TooltipCell,
  TooltipHeader
} from "components/D3/Tooltip/ToolTip";
import { TOOLTIP_ORIENTATIONS } from "utils";
import { Nullable } from "utils/types";

import { HorizontalChart } from "./utils/getRankingData";

interface Props {
  x: number;
  y: number;
  tooltip: Nullable<HorizontalChart>;
}

export const Tooltip = ({ x, y, tooltip }: Props) => {
  if (!tooltip) return null;

  return (
    <MovableTooltipWrapper
      positionX={x}
      positionY={y}
      orientation={TOOLTIP_ORIENTATIONS.VERTICAL}
    >
      <TooltipCmp>
        <TooltipHeader>{tooltip.company}</TooltipHeader>
        <TooltipBody>
          <TooltipCell
            label={tooltip.label}
            value={tooltip.tooltipLabel}
            valueSecondary={tooltip.sharesLabel}
          />
        </TooltipBody>
      </TooltipCmp>
    </MovableTooltipWrapper>
  );
};
