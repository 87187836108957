import React from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useFiltersFetchingParams } from "pages/Reports/redux/reducers/filters/hooks/useFiltersFetchingParams";
import {
  BrandHooks,
  BrandItem,
  BrandType
} from "pages/Reports/redux/reducers/sweetStateHooks/useBrand";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { brandQueryParamSelector } from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";

type BrandRequestParams = {
  tier: string;
  categories: string;
  attributes?: string;
  companies?: string;
};

const useFilterParams = (): BrandRequestParams => {
  const isPowerUser = useSelector(isPowerUserSelector);
  const params = useFiltersFetchingParams([
    "category",
    "tier",
    "vendor",
    "attributes"
  ]);

  const base: BrandRequestParams = {
    categories: params.category,
    tier: params.tier
  };

  if (params.attributes) {
    Object.assign(base, { attributes: params.attributes });
  }

  if (isPowerUser) {
    Object.assign(base, { companies: params.vendor });
  }

  return base;
};

const useFetchingFlag = (
  params: {
    categories: string;
    tier: string;
  },
  isDisabled: boolean
) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching.brand;
  const lastQuery = useSelector(asyncFiltersSelector).query.brand;

  const query = qs.stringify(params);
  if (isLoading || isDisabled) {
    return false;
  }

  if (query === lastQuery) {
    return false;
  }

  if (!params.categories || !params.tier) {
    return false;
  }

  return true;
};

const useBrandFetching = (isDisabled: boolean) => {
  const filter = BrandType.brand;
  const [, actions] = BrandHooks.useBrand();
  const dispatch = useDispatch();
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const brandsQP = useSelector(brandQueryParamSelector);

  const params = useFilterParams();
  const isFetchingPossible = useFetchingFlag(params, isDisabled);

  const saveAndSelect = React.useCallback(
    (brands: BrandItem[]) => {
      actions.updateAllBrands(brands, filter);
      if (isPristine) {
        actions.checkPristineSelectionsAfterFetching(brands, brandsQP, filter);
      } else {
        actions.checkSelectionsAfterFetching(
          brands.map(cmp => cmp.value),
          filter
        );
      }
      actions.sortBrands(filter);
    },
    [isPristine, actions, brandsQP, filter]
  );

  React.useEffect(() => {
    if (!isFetchingPossible) {
      return;
    }

    const fetch = async () => {
      try {
        dispatch(updateQuery({ filter, query: qs.stringify(params) }));
        dispatch(setFlag({ filter, isFetching: true }));
        const response = await HTTP.get<{ id: number; name: string }[]>(
          REST_API_ENDPOINTS.BRANDS,
          { params }
        );
        const brands = response.data.map(brand => ({
          value: String(brand.id),
          label: brand.name
        }));
        saveAndSelect(brands);
        dispatch(setError({ filter, status: "" }));
      } catch (e) {
        dispatch(setError({ filter, status: "Błąd pobierania marek" }));
        return [];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };

    fetch();
  }, [dispatch, isFetchingPossible, params, filter, saveAndSelect]);
};

export const useBrandBehaviour = (isDisabled: boolean) => {
  useBrandFetching(isDisabled);
};
