import { useSelector } from "react-redux";

import { ExportDataTypeState } from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";
import {
  aggregatedCompetitionQueryParamSelector,
  aggregatedQueryParamSelector,
  brandQueryParamSelector,
  chartPeriodSelector,
  clientTypeQueryParamSelector,
  compareDateFromQPSelector,
  compareDateToQPSelector,
  competitionCompaniesQueryParamSelector,
  competitionProductsQueryParamSelector,
  competitorBrandQueryParamSelector,
  competitorSubBrandQueryParamSelector,
  countiesQueryParamSelector,
  dateFromQueryParamSelector,
  dateToQueryParamSelector,
  delayCompetitionQueryParamSelector,
  dynamicPeriodCheckedQPSelector,
  dynamicPeriodQPSelector,
  excludedDaysQueryParamSelector,
  exportDataTypeQueryParamsSelector,
  includeLeapYearQPSelector,
  initialDateFromQPSelector,
  initialDateToQPSelector,
  lflQueryParamSelector,
  productsQueryParamSelector,
  queryParamsSelector,
  referenceBrandAggragetedSelector,
  referenceBrandQueryParamSelector,
  referenceCategoryAggragetedSelector,
  referenceSubBrandQueryParamSelector,
  shopTypesMetaQueryParamsSelector,
  shopTypesQueryParamsSelector,
  subBrandQueryParamSelector,
  vendorIdQueryParamSelector,
  voivodeshipQueryParamSelector
} from "store/selectors/routerSelectors";

export const useFiltersQP = () => {
  const vendorIdQP = useSelector(vendorIdQueryParamSelector);
  const {
    category1: cat1QP,
    category2: cat2QP,
    category3: cat3QP,
    ref_cat1: refCat1QP,
    ref_cat2: refCat2QP,
    ref_cat3: refCat3QP,
    ref_brand: refBrandQP,
    ref_subbrand: refSubBrandQP
  } = useSelector(queryParamsSelector);
  const refCatAggregatedQP = useSelector(referenceCategoryAggragetedSelector);
  const refBrandAggregatedQP = useSelector(referenceBrandAggragetedSelector);
  const dateFromQP = useSelector(dateFromQueryParamSelector);
  const dateToQP = useSelector(dateToQueryParamSelector);
  const compareDateFromQP = useSelector(compareDateFromQPSelector);
  const compareDateToQP = useSelector(compareDateToQPSelector);
  const includeLeapYearQP = useSelector(includeLeapYearQPSelector);
  const shopTypeQP = useSelector(shopTypesQueryParamsSelector);
  const shopTypeMetaQP = useSelector(shopTypesMetaQueryParamsSelector);
  const productsQP = useSelector(productsQueryParamSelector);
  const voivodeshipsQP = useSelector(voivodeshipQueryParamSelector);
  const countiesQP = useSelector(countiesQueryParamSelector);
  const competitionCompaniesQP = useSelector(
    competitionCompaniesQueryParamSelector
  );
  const competitionProductsQP = useSelector(
    competitionProductsQueryParamSelector
  );
  const delayCompetitionQP = useSelector(delayCompetitionQueryParamSelector);
  const aggregatedCompetitionQP = useSelector(
    aggregatedCompetitionQueryParamSelector
  );
  const clientTypesQP = useSelector(clientTypeQueryParamSelector);
  const lflQP = useSelector(lflQueryParamSelector);
  const exportDataTypeQP = useSelector(exportDataTypeQueryParamsSelector);
  const aggregatedQP = useSelector(aggregatedQueryParamSelector);
  const excludedDaysQP = useSelector(excludedDaysQueryParamSelector);
  const dynamicPeriodCheckedQP = useSelector(dynamicPeriodCheckedQPSelector);
  const dynamicPeriodQP = useSelector(dynamicPeriodQPSelector);
  const initialDateFromQP = useSelector(initialDateFromQPSelector);
  const initialDateToQP = useSelector(initialDateToQPSelector);
  const chartPeriodQP = useSelector(chartPeriodSelector);
  const brandQP = useSelector(brandQueryParamSelector);
  const subBrandQP = useSelector(subBrandQueryParamSelector);
  const competitorBrandQP = useSelector(competitorBrandQueryParamSelector);
  const competitorSubBrandQP = useSelector(
    competitorSubBrandQueryParamSelector
  );
  const referenceBrandQP = useSelector(referenceBrandQueryParamSelector);
  const referenceSubBrandQP = useSelector(referenceSubBrandQueryParamSelector);

  return {
    vendorIdQP: String(vendorIdQP),
    cat1QP: String(cat1QP),
    cat2QP: String(cat2QP),
    cat3QP: String(cat3QP),
    refCat1QP: String(refCat1QP),
    refCat2QP: String(refCat2QP),
    refCat3QP: String(refCat3QP),
    brandQP: String(brandQP),
    subBrandQP: String(subBrandQP),
    competitorBrandQP: String(competitorBrandQP),
    competitorSubBrandQP: String(competitorSubBrandQP),
    referenceBrandQP: String(referenceBrandQP),
    referenceSubBrandQP: String(referenceSubBrandQP),
    refBrandQP: String(refBrandQP),
    refSubBrandQP: String(refSubBrandQP),
    refBrandAggregatedQP: String(refBrandAggregatedQP),
    refCatAggregatedQP: String(refCatAggregatedQP),
    shopTypeQP: String(shopTypeQP),
    shopTypeMetaQP: String(shopTypeMetaQP),
    productsQP: String(productsQP),
    voivodeshipsQP: String(voivodeshipsQP),
    countiesQP: String(countiesQP),
    competitionCompaniesQP: String(competitionCompaniesQP),
    competitionProductsQP: String(competitionProductsQP),
    delayCompetitionQP: String(delayCompetitionQP),
    aggregatedCompetitionQP: String(aggregatedCompetitionQP),
    clientTypesQP: clientTypesQP,
    lflQP: String(lflQP),
    exportDataTypeQP: String(exportDataTypeQP) as ExportDataTypeState,
    aggregatedQP: String(aggregatedQP),
    excludedDaysQP: String(excludedDaysQP),
    dateFromQP: String(dateFromQP),
    dateToQP: String(dateToQP),
    compareDateFromQP: String(compareDateFromQP),
    compareDateToQP: String(compareDateToQP),
    includeLeapYearQP: String(includeLeapYearQP),
    dynamicPeriodCheckedQP: String(dynamicPeriodCheckedQP),
    dynamicPeriodQP: String(dynamicPeriodQP),
    initialDateFromQP: String(initialDateFromQP),
    initialDateToQP: String(initialDateToQP),
    chartPeriodQP: String(chartPeriodQP)
  };
};
