import { useMemo } from "react";
import { useSelector } from "react-redux";

import { EMPTY_CHART } from "api/consts";
import {
  useGetDynamicsByPeriod,
  useIsDynamicsLoading
} from "api/dynamics/mappers";
import { ChartDataOptions } from "api/sells/mappers/useSellsChartData";
import { createTimeline, getDataTypeKey, sortBarChartData } from "api/utils";
import { useChartLabels } from "api/utils/useChartLabels";
import { omit } from "ramda";

import { ChartData } from "components/D3/types";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useBarChartSorting } from "hooks/useBarChartSorting";
import { useLegendContentNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendContentNames";
import { createLegendId } from "pages/Reports/partials/Chart/Legend/utils/createLegendId";
import { useDataTypeSuffix } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import {
  hiddenLinesByReportSelector,
  pinnedTimepointSelector,
  updateHoveredTimepoint,
  updatePinnedTimepoint
} from "pages/Reports/redux/reducers/chartReducer";
import { useGetZappkaSharesSuffix } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import {
  checkedDataTypes,
  findDateDomain
} from "pages/Reports/sections/utils/chartData";
import { addColor } from "pages/Reports/sections/utils/color";
import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import {
  chartRange,
  valueByDataType,
  valueByDataTypeBoolean
} from "pages/Reports/utils";
import {
  chartPeriodSelector,
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { mapTimelineByType } from "store/utils/chartUtils";
import { CHART_SORT_ORDER_BY_TYPE } from "utils";
import { isSameScaleMetrics } from "utils/isSameScaleMetrics";

export const useDynamicsChartData = ({
  isForHistoricalChart = false
}: ChartDataOptions): ChartData[] => {
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const hiddenLines = useSelector(hiddenLinesByReportSelector);
  const period = useSelector(chartPeriodSelector);
  const pinnedTimepoint = useSelector(pinnedTimepointSelector);
  const labels = useChartLabels();
  const isReportLoading = useIsDynamicsLoading();
  const { isGroupedBarChart } = useChartTypeBoolean();
  const contentNames = useLegendContentNames();
  const [suffix1, suffix2] = useDataTypeSuffix();
  const getZappkaSharesSuffix = useGetZappkaSharesSuffix();
  const barChartSorting = useBarChartSorting();

  const { data: dynamics } = useGetDynamicsByPeriod();

  const dataTypes = checkedDataTypes([firstChartType, secondChartType]);
  const timeline = dynamics?.currentPeriod[0]?.timeline || [];
  const domain = findDateDomain(timeline, isForHistoricalChart);
  const showBarChart = domain.length === 1;
  const barChartSortingTimepoint = barChartSorting.getSortingTimepoint({
    dataTypes,
    showBarChart,
    isGroupedBarChart,
    pinnedTimepoint
  });

  // Sorting is important. By name and type and then by existing metrics, so existing are first in array and color is not wasted on non existing metrics
  const sorted = useMemo(
    () =>
      dynamics?.currentPeriod
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort(
          (a, b) =>
            CHART_SORT_ORDER_BY_TYPE.indexOf(a.type) -
            CHART_SORT_ORDER_BY_TYPE.indexOf(b.type)
        )
        .sort(
          (a, b) =>
            Number(valueByDataType(firstChartType, b.existingMetrics)) -
            Number(valueByDataType(firstChartType, a.existingMetrics))
        ),
    [dynamics?.currentPeriod, firstChartType]
  );

  const dataTypeKeys = dataTypes.map((dataType, index) => {
    const sharesSuffix = getZappkaSharesSuffix(firstChartType ? index : 1);
    const primaryKey = getDataTypeKey({
      dataType,
      suffix: suffix1,
      sharesSuffix
    });
    const secondaryKey = getDataTypeKey({
      dataType,
      suffix: suffix2,
      sharesSuffix,
      isSecondary: true
    });
    return { primaryKey, secondaryKey };
  });

  const chartData = useMemo(() => {
    return dataTypes.map((dataType, index) => {
      const dataWithColor = addColor(sorted ?? [], dataType);
      const { primaryKey, secondaryKey } = dataTypeKeys[index];

      if (barChartSortingTimepoint !== null) {
        dataWithColor.sort(
          sortBarChartData(
            barChartSorting.dataKey,
            barChartSorting.isDesc,
            barChartSortingTimepoint
          )
        );
      }

      const allLines = dataWithColor.map(dynamics => {
        const chartLabel = getChartLabel({
          vendorId: dynamics.vendorId,
          resultId: dynamics.resultId,
          type: dynamics.type
        });

        const id = createLegendId(chartLabel, contentNames, dynamics.type);

        return {
          ...omit(["timeline"], dynamics),
          chartLabel,
          id,
          timeline: createTimeline(primaryKey, dynamics.timeline),
          ...(Boolean(secondaryKey) && {
            secondaryTimeline: createTimeline(secondaryKey, dynamics.timeline)
          })
        };
      });

      const visibleLines = allLines
        .filter(timeline => !hiddenLines.includes(timeline.id))
        .filter(timeline =>
          valueByDataTypeBoolean(
            primaryKey,
            timeline.existingMetrics,
            { base: true, historical: false, both: false },
            secondaryKey
          )
        );

      return {
        dataType,
        visibleLines,
        allLines,
        ...chartRange(visibleLines, {
          isRightChartData: index === 1,
          isGroupedBarChart
        })
      };
    });
  }, [
    barChartSorting.dataKey,
    barChartSorting.isDesc,
    barChartSortingTimepoint,
    contentNames,
    dataTypeKeys,
    dataTypes,
    hiddenLines,
    isGroupedBarChart,
    sorted
  ]);

  const isSameScale = isSameScaleMetrics(
    dataTypeKeys.map(dataType => dataType.primaryKey)
  );
  const overallMaxValue = isSameScale
    ? Math.max(...chartData.map(data => data.maxValue))
    : 0;

  const filtered = useMemo(
    () =>
      chartData.map(chart => ({
        dataType: chart.dataType,
        minValue: chart.minValue,
        maxValue: overallMaxValue || chart.maxValue,
        chart: chart.visibleLines.map(chart => ({
          id: chart.id,
          label: chart.chartLabel,
          color: chart.color,
          type: chart.type,
          timeline: mapTimelineByType(chart.type, chart.timeline),
          ...(chart.secondaryTimeline && {
            secondaryTimeline: mapTimelineByType(
              chart.type,
              chart.secondaryTimeline
            )
          })
        }))
      })),
    [chartData, overallMaxValue]
  );

  if (!dynamics) {
    return [EMPTY_CHART];
  }

  return [
    {
      leftChart: filtered[0],
      rightChart: filtered[1],
      domain,
      period: period,
      labels,
      isFetching: isReportLoading,
      showBarChart,
      onTimelineHover: updateHoveredTimepoint,
      onTimelineClick: updatePinnedTimepoint,
      isHistoricalGroupedChart: isForHistoricalChart
    }
  ];
};
