import { ParsedQuery } from "query-string";

import { isVoivodeshipPage } from "pages/Reports/redux/selectors/dataTypeWarningsSelectors";
import { REPORTS_PATHS } from "pages/Reports/utils/constants";
import { CHART_DATA_TYPE } from "utils";
import { QP } from "utils/defaultQueryParams";

const { LOCATION } = REPORTS_PATHS;
const {
  VALUE_PER_SHOP,
  NONE,
  AVG_SHOPS_COUNT,
  DISTRIBUTION_RANGE,
  VALUE,
  QUANTITY
} = CHART_DATA_TYPE;

export const adjustParamsByLocationMapVoivodeship = (
  params: ParsedQuery<string | string[]>,
  pathname: string,
  isPowerUser: boolean
) => {
  const isLocationPage = pathname.includes(LOCATION);
  const hasFullAccess = isPowerUser && isLocationPage; // [PMD-2212]: power users should have access to all first types on location

  if (!isVoivodeshipPage(pathname)) return params;

  const firstDataType = String(params[QP.CHART_DATA_TYPE_FIRST]).toUpperCase();
  const secondDataType = String(
    params[QP.CHART_DATA_TYPE_SECOND]
  ).toUpperCase();

  // @ts-ignore - no point to compare type of query param to chart data type
  if ([VALUE, QUANTITY].includes(firstDataType) && !hasFullAccess) {
    params[QP.CHART_DATA_TYPE_FIRST] = VALUE_PER_SHOP.toLowerCase();
  }

  // @ts-ignore - no point to compare type of query param to chart data type
  if ([AVG_SHOPS_COUNT, DISTRIBUTION_RANGE].includes(secondDataType)) {
    params[QP.CHART_DATA_TYPE_SECOND] = NONE;
  }

  return params;
};
