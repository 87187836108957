import { useActiveCompanyName } from "api/utils/useActiveCompanyName";

import { DATASET_TYPES } from "utils";

export const useLegendContentNames = () => {
  const activeCompany = useActiveCompanyName();

  return [
    {
      type: DATASET_TYPES.OWN,
      label: activeCompany || "Kategorie / produkty własne:"
    },
    {
      type: DATASET_TYPES.COMPETITOR,
      label: "Firmy / produkty konkurencyjne:"
    },
    {
      type: DATASET_TYPES.ATTRIBUTES,
      label: "Dane wspólne - Atrybuty:"
    },
    {
      type: DATASET_TYPES.REFERENCE,
      label: "Kategoria referencyjna:"
    },
    {
      type: DATASET_TYPES.REFERENCE_BRAND,
      label: "Marka referencyjna:"
    }
  ];
};
