import { ParsedQuery } from "query-string";

import { EXPORT_DATA_TYPE } from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";
import { APP_PATHS, isThisPage } from "utils";
import { QP } from "utils/defaultQueryParams";
import { Tier } from "utils/types";

const { GENERAL, SEGMENTS, COUNTIES } = EXPORT_DATA_TYPE;

export const adjustExportParams = (
  params: ParsedQuery<string>,
  pathname: string,
  tier: Tier,
  isPowerUser: boolean
): ParsedQuery<string> => {
  const isExportPage = isThisPage(pathname, APP_PATHS.EXPORT);

  if (!isExportPage) return params;

  // if export page filter out the chart_data_type_first param
  const filteredParams: ParsedQuery<string> = Object.keys(params)
    .filter(param => param !== QP.CHART_DATA_TYPE_FIRST)
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: params[key]
      };
    }, {});

  const { export_data_type } = filteredParams;

  // block "counties" export data type for specific tiers
  if ([1, 2].includes(tier) && export_data_type === COUNTIES) {
    filteredParams[QP.EXPORT_DATA_TYPE] = GENERAL;
  }

  // block "segments" export data type for non-power users
  if (isPowerUser) return filteredParams;

  const { voivodeships, counties } = filteredParams;

  const isRegionSelected =
    (!!voivodeships?.length || !!counties?.length) &&
    export_data_type === SEGMENTS;

  if (isRegionSelected) {
    filteredParams[QP.EXPORT_DATA_TYPE] = GENERAL;
  }

  return filteredParams;
};
