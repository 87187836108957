import { ADMIN_PATHS } from "pages/Admin/utils";
import { NEWS_PATHS } from "pages/News/constants";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils/constants";
import { APP_PATHS } from "utils";

const { LOGIN, ADMIN, NEWS, KNOWLEDGE_BASE, INSIGHTS, EXPORT } = APP_PATHS;

const {
  SELL_PATH,
  LOCATION_PATH,
  SHARES_PATH,
  DYNAMICS_PATH,
  RECEIPTS_PATH,
  LOGISTICS_PATH,
  SEGMENTS_PATH,
  HOURS_PATH,
  LOYALTY_PATH,
  MULTIPACK_PATH
} = REPORTS_FULL_PATHS;

const COOKIES_SETTINGS_PATH = ADMIN + ADMIN_PATHS.COOKIES_SETTINGS;
const USER_MANAGEMENT_PATH = ADMIN + ADMIN_PATHS.USER_MANAGEMENT;

const NEWSLETTER_PATH = NEWS + NEWS_PATHS.NEWSLETTER;

export const AD_LOGIN_URL = "ADLoginUrl";

export const getPageTitle = (pathname: string) => {
  switch (pathname) {
    case LOGIN:
      return "Logowanie";
    case AD_LOGIN_URL:
      return "Przekierowanie na MS";
    case SELL_PATH:
      return "Raport SPRZEDAŻ";
    case LOCATION_PATH:
      return "Raport LOKALIZACJA";
    case SHARES_PATH:
      return "Raport UDZIAŁY";
    case DYNAMICS_PATH:
      return "Raport DYNAMIKA";
    case RECEIPTS_PATH:
      return "Raport PARAGONY";
    case LOGISTICS_PATH:
      return "Raport LOGISTYKA";
    case SEGMENTS_PATH:
      return "Raport SEGMENTY";
    case HOURS_PATH:
      return "Raport GODZINY";
    case LOYALTY_PATH:
      return "Raport LOJALNOŚĆ";
    case MULTIPACK_PATH:
      return "Raport WIELOSZTUKI";
    case COOKIES_SETTINGS_PATH:
      return "Cookies";
    case USER_MANAGEMENT_PATH:
      return "Zarządzanie użytkownikami";
    case NEWSLETTER_PATH:
      return "Newsletter ACIT";
    case INSIGHTS:
      return "Insights";
    case EXPORT:
      return "Eksport";
    case KNOWLEDGE_BASE:
      return "Baza wiedzy";
    default:
      return "";
  }
};
