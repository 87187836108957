import { ICON_SIZES } from "utils";

import { IconProps } from "./types";
import { SIZES } from "./utils/constants";

interface Props extends IconProps {
  filled?: boolean;
}

export const Star = ({
  className,
  color = "#6D89B6",
  size = ICON_SIZES.LARGE,
  filled = false
}: Props) => {
  const iconSize = SIZES[size];

  return (
    <svg
      className={className}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      fill={filled ? color : "none"}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
    </svg>
  );
};
