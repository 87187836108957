import { ScaleBand } from "d3";

import { COLOR } from "utils/constants";

import { HorizontalChart } from "./utils/getRankingData";

interface Props {
  transform: string;
  data: HorizontalChart[];
  scaleY: ScaleBand<string>;
}

export const IndexAxis = ({ transform, data, scaleY }: Props) => (
  <g transform={transform} data-testid="ranking-left-index-axis">
    {data.map((rect, index) => {
      return (
        <g
          key={`ranking-index-${index}`}
          transform={`translate(0, ${(scaleY(String(rect.id)) || 0) +
            scaleY.bandwidth() / 2})`}
        >
          <text dy="0.32em" fontSize="12" fontWeight="600" fill={COLOR.UI_15}>
            {`${index + 1}.`}
          </text>
        </g>
      );
    })}
  </g>
);
