import { useGetLoyaltyByPeriod } from "api/loyalty/mappers";
import { useGetRanking } from "api/ranking/hooks";

export const useIsLoyaltyLoading = () => {
  const {
    isLoading: isLoyaltyLoading,
    isIdle: isLoyaltyIdle
  } = useGetLoyaltyByPeriod();
  const { isLoading: rankingLoading } = useGetRanking();

  return isLoyaltyLoading || isLoyaltyIdle || rankingLoading;
};
