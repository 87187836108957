import { format, startOfDay, subDays } from "date-fns";

import { newDateByTimezone } from "store/utils";
import { DEFAULT_DATE_FORMAT, PERIOD_TYPE } from "utils";
import { Values } from "utils/types";

export const getMergedTimelineDetails = ({
  isBothDatasets,
  isGroupedBarChart,
  domainLength,
  period
}: {
  isBothDatasets: boolean;
  isGroupedBarChart: boolean;
  domainLength: number;
  period: Values<typeof PERIOD_TYPE> | undefined;
}) => {
  const today = startOfDay(newDateByTimezone());
  return {
    shouldMerge: isBothDatasets && isGroupedBarChart && domainLength === 1,
    // [PMD-5154]: apply fake domain for the "total" period since back-end return `null` for `date` and `date_last_year` in this case
    fakeDomain:
      period === PERIOD_TYPE.TOTAL
        ? [
            format(subDays(today, 1), DEFAULT_DATE_FORMAT),
            format(today, DEFAULT_DATE_FORMAT)
          ]
        : undefined
  };
};
