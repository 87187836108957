import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";

import { transformSingleInsight } from "pages/Insights/utils/transformInsightData";
import {
  SidebarInsightDataApi,
  SingleInsightData
} from "pages/Insights/utils/types";
import {
  fetchData,
  getBackendReportType,
  REPORTS_FULL_PATHS
} from "pages/Reports/utils";
import {
  isLogisticUserSelector,
  tierSelector
} from "store/reducers/userReducer";
import {
  pathnameSelector,
  searchStringSelector
} from "store/selectors/routerSelectors";
import {
  BOOL_STRING_VALUES,
  HTTP,
  isThisPage,
  REST_API_ENDPOINTS
} from "utils";
import {
  FEATURE_LOCAL_STORAGE_KEYS,
  INSIGHTS_ALLOWED_TIERS
} from "utils/constants";
import { translateFeParamsToBeParams } from "utils/translateFEParamsToBEParams";

const QUERY_KEY = "sidebarInsights";

const mapInsightsAndSetFavourites = (
  insightId: string,
  isFavourite: boolean
) => (data?: SingleInsightData[]) => {
  if (!data) return [];
  return data.map(insight =>
    insight.insightId === insightId ? { ...insight, isFavourite } : insight
  );
};

export const useSidebarInsightsAccess = () => {
  const pathname = useSelector(pathnameSelector);
  const tier = useSelector(tierSelector);
  const isLogisticUser = useSelector(isLogisticUserSelector);

  const isKeyEnabled =
    localStorage.getItem(FEATURE_LOCAL_STORAGE_KEYS.SIDEBAR_INSIGHTS_KEY) ===
    BOOL_STRING_VALUES.TRUE;

  if (
    !isKeyEnabled ||
    !INSIGHTS_ALLOWED_TIERS.includes(tier) ||
    isLogisticUser
  ) {
    return false;
  }

  return [
    REPORTS_FULL_PATHS.SELL_PATH,
    REPORTS_FULL_PATHS.DYNAMICS_PATH,
    REPORTS_FULL_PATHS.SHARES_PATH
  ].some(url => isThisPage(pathname, url));
};

const useSidebarInsightsQuery = () => {
  const pathname = useSelector(pathnameSelector);
  const search = useSelector(searchStringSelector);

  return `${translateFeParamsToBeParams(
    search
  )}&report_type=${getBackendReportType(pathname)}`;
};

export const useSidebarInsightMutations = () => {
  const queryClient = useQueryClient();
  const sidebarQuery = useSidebarInsightsQuery();

  const removeFromFavouritesMutation = useMutation({
    mutationFn: (insightId: string) => {
      return HTTP.delete<{ insight_id: string }>(
        REST_API_ENDPOINTS.INSIGHTS.FAVOURITES + insightId + "/"
      );
    },
    onSuccess: (data, insightId) => {
      queryClient.setQueryData<SingleInsightData[]>(
        [QUERY_KEY, sidebarQuery],
        mapInsightsAndSetFavourites(insightId, false)
      );
    }
  });

  const addToFavouritesMutation = useMutation({
    mutationFn: (insightId: string) => {
      return HTTP.post<{ insight_id: string }>(
        REST_API_ENDPOINTS.INSIGHTS.FAVOURITES,
        { insight_id: insightId }
      );
    },
    onSuccess: (data, insightId) => {
      queryClient.setQueryData<SingleInsightData[]>(
        [QUERY_KEY, sidebarQuery],
        mapInsightsAndSetFavourites(insightId, true)
      );
    }
  });

  const removeInsightMutation = useMutation({
    mutationFn: (insightId: string) => {
      return HTTP.post<{}>(REST_API_ENDPOINTS.INSIGHTS.DELETED, {
        insight_id: insightId
      });
    },
    onSuccess: (data, insightId) => {
      queryClient.setQueryData<SingleInsightData[]>(
        [QUERY_KEY, sidebarQuery],
        oldData => {
          return (oldData || []).filter(
            insight => insight.insightId !== insightId
          );
        }
      );
    }
  });

  return {
    removeFromFavouritesMutation,
    addToFavouritesMutation,
    removeInsightMutation
  };
};

export const useFetchSidebarInsights = () => {
  const areSidebarInsightsEnabled = useSidebarInsightsAccess();
  const sidebarQuery = useSidebarInsightsQuery();

  const fetchSidebarInsights = async () => {
    try {
      const responseData = await fetchData<SidebarInsightDataApi>(
        sidebarQuery,
        REST_API_ENDPOINTS.INSIGHTS.GET_SIDEBAR_DATA
      );

      if (responseData instanceof Error) {
        throw new Error("invalid response");
      }

      return responseData?.insights
        .filter(insight => !insight.is_deleted) // exlcude deleted insights from sidebar list
        .map(transformSingleInsight);
    } catch (err) {
      console.error(err);
    }
  };

  const info = useQuery({
    queryKey: [QUERY_KEY, sidebarQuery],
    queryFn: fetchSidebarInsights,
    enabled: areSidebarInsightsEnabled
  });

  return info;
};
