import * as React from "react";

import { Button, CardContent, Portal, Text } from "components/atoms";
import { Close, Tick } from "components/atoms/Icon";
import { Card, PopupWindow } from "components/molecules";
import { ICON_SIZES } from "utils";
import { Nullable } from "utils/types";

import s from "./confirmModal.module.scss";

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  ga?: {
    confirm: object;
    cancel: object;
  };
  position: {
    top?: Nullable<number>;
    bottom?: Nullable<number>;
  };
  message: string;
  confirmText?: string;
  cancelText?: string;
  testId?: string;
};

export const ConfirmModal = ({
  onConfirm,
  onCancel,
  position = {
    top: null,
    bottom: null
  },
  message,
  confirmText = "",
  cancelText = "",
  testId,
  ga = {
    confirm: {},
    cancel: {}
  }
}: Props) => {
  const { top, bottom } = position;
  React.useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "scroll";
    };
  });

  return (
    <Portal id="js-clear-filter-confirm-modal">
      <PopupWindow className={s.popup}>
        <div
          className={s.cardContainer}
          style={{
            top: top ? top : "unset",
            bottom: bottom ? bottom : "unset"
          }}
        >
          <Card className={{ base: s.card }}>
            <CardContent className={s.base}>
              <Text className={s.modalText}>{message}</Text>
              <div className={s.buttonContainer} data-testid={testId}>
                <Button
                  onClick={onConfirm}
                  className={s.confirmButton}
                  {...ga.confirm}
                >
                  {confirmText && (
                    <Text className={s.buttonText}>{confirmText}</Text>
                  )}
                  <span className={s.svg}>
                    <Tick />
                  </span>
                </Button>
                <Button
                  onClick={onCancel}
                  className={s.cancelButton}
                  {...ga.cancel}
                >
                  {cancelText && (
                    <Text className={s.buttonText}>{cancelText}</Text>
                  )}
                  <span className={s.svg}>
                    <Close size={ICON_SIZES.EXTRA_SMALL} />
                  </span>
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </PopupWindow>
    </Portal>
  );
};
