import { createSelector } from "reselect";

import { tierSelector } from "store/reducers/userReducer";

const DYNAMIC_PERIOD_TIERS = [3, 4];

export const isDynamicPeriodEnabledSelector = createSelector(
  tierSelector,
  tier => DYNAMIC_PERIOD_TIERS.includes(tier)
);
