import { endOfWeek, format, startOfWeek } from "date-fns";

import { DEFAULT_DATE_FORMAT } from "utils";
import { WeekStartDay } from "utils/types";

export const weekStartEndDates = (
  date: Date,
  weekStartsOn: WeekStartDay = 1
) => {
  const startOfCurrentWeekPeriod = format(
    startOfWeek(date, { weekStartsOn: weekStartsOn }),
    DEFAULT_DATE_FORMAT
  );

  const endOfCurrentWeekPeriod = format(
    endOfWeek(date, { weekStartsOn: weekStartsOn }),
    DEFAULT_DATE_FORMAT
  );

  return [startOfCurrentWeekPeriod, endOfCurrentWeekPeriod];
};
