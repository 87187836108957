import { ParsedQuery } from "query-string";

import { PROMOTIONS_ENABLED_PERIODS } from "utils/constants";
import { QP } from "utils/defaultQueryParams";

export const adjustParamsByPeriod = (
  params: ParsedQuery<string | string[]>
) => {
  const period = params[QP.CHART_PERIOD]
    ? String(params[QP.CHART_PERIOD]).toUpperCase()
    : undefined;
  const arePromotionsDisabled =
    // @ts-ignore - no point to compare type of query param to chart data type
    period !== undefined && !PROMOTIONS_ENABLED_PERIODS.includes(period);

  if (arePromotionsDisabled) {
    params[QP.PROMOTIONS_CHART_DATA_TYPE] = "";
  }

  return params;
};
