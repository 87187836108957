import React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const RemoveUser = ({
  className,
  color = "#CA1D08",
  size = ICON_SIZES.SMALL
}: Props) => {
  const currentSize = SIZES[size];
  return (
    <svg width={currentSize} height={currentSize} className={className}>
      <path
        fill={color}
        d="M11.25 0H.75C.3 0 0 .3 0 .75v10.5c0 .45.3.75.75.75h10.5c.45 0 .75-.3.75-.75V.75c0-.45-.3-.75-.75-.75zM8.625 7.575l-1.05 1.05L6 7.05 4.425 8.625l-1.05-1.05L4.95 6 3.375 4.425l1.05-1.05L6 4.95l1.575-1.575 1.05 1.05L7.05 6l1.575 1.575z"
      />
    </svg>
  );
};
