import { adjustObjectProperty } from "pages/Reports/utils/validateParams/utils/adjustObjectProperty";

import { MetricValidationRequiredInfo } from "../validateParamsPerReport";

export const validateMultipleProperties = (
  keysToChange: MetricValidationRequiredInfo[],
  search: string
) => {
  let searchToChange = search;

  keysToChange.forEach(({ key, availableValues, defaultValue }) => {
    searchToChange = adjustObjectProperty(
      key,
      availableValues,
      defaultValue,
      searchToChange
    );
  });

  return searchToChange;
};
