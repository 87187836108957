import { valueByDataType } from "../../pages/Reports/utils";

export const createTimeline = (dataType: string, timeline: any[]) => {
  return timeline.map(data => ({
    valueX: data.date,
    valueY: valueByDataType(dataType, data)
  }));
};

export const createHistoricalTimeline = (
  dataType: string,
  timeline: any[],
  isForHistoricalChart: boolean
) => {
  return timeline.map(data => ({
    valueX: isForHistoricalChart ? data.dateLastYear || "" : data.date,
    valueY: valueByDataType(
      dataType === "" ? "" : `${dataType}_LAST_YEAR`,
      data
    )
  }));
};

export const createMergedTimeline = (dataType: string, timeline: any[]) => {
  return [
    createHistoricalTimeline(dataType, timeline, true),
    createTimeline(dataType, timeline)
  ].flat();
};
