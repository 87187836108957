import {
  LoyaltyDataApi,
  LoyaltyDataPointApi,
  LoyaltyNormalizedResponse,
  LoyaltyTimeline
} from "api/loyalty/types";
import { format } from "date-fns";

import { getChartLabel } from "pages/Reports/sections/utils/getChartLabel";
import { newDateByTimezone } from "store/utils";
import { DEFAULT_DATE_FORMAT, standarizeTimepointValue } from "utils";

export const normalizeLoyaltyResponse = (
  data: LoyaltyDataApi[]
): LoyaltyNormalizedResponse[] =>
  data.map(data => {
    const vendorId = data.vendor_id || "";
    const resultId = data.result_id || "";
    const type = data.type;
    const name = getChartLabel({
      vendorId,
      resultId,
      type
    });

    return {
      vendorId,
      resultId,
      type,
      name,
      timeline: data.timeline.map(standarizeDataPoint),
      existingMetrics: {
        loyaltyNewClientsCount:
          data?.existing_metrics?.loyalty_new_clients_count || false,
        loyaltyNewClientsCountChange:
          data?.existing_metrics?.loyalty_new_clients_count_change || false,
        loyaltyNewClientsCountChangePercentage:
          data?.existing_metrics?.loyalty_new_clients_count_change_percentage ||
          false,
        loyaltyNewClientsCountLastYear:
          data?.existing_metrics?.loyalty_new_clients_count_last_year || false,

        loyaltyNewClientsPercent:
          data?.existing_metrics?.loyalty_new_clients_percent || false,
        loyaltyNewClientsPercentChange:
          data?.existing_metrics?.loyalty_new_clients_percent_change || false,
        loyaltyNewClientsPercentChangePercentage:
          data?.existing_metrics
            ?.loyalty_new_clients_percent_change_percentage || false,
        loyaltyNewClientsPercentLastYear:
          data?.existing_metrics?.loyalty_new_clients_percent_last_year ||
          false,

        loyaltyBackPercent:
          data?.existing_metrics?.loyalty_back_percent || false,
        loyaltyBackPercentChange:
          data?.existing_metrics?.loyalty_back_percent_change || false,
        loyaltyBackPercentChangePercentage:
          data?.existing_metrics?.loyalty_back_percent_change_percentage ||
          false,
        loyaltyBackPercentLastYear:
          data?.existing_metrics?.loyalty_back_percent_last_year || false,

        loyaltyBackCount: data?.existing_metrics?.loyalty_back_count || false,
        loyaltyBackCountChange:
          data?.existing_metrics?.loyalty_back_count_change || false,
        loyaltyBackCountChangePercentage:
          data?.existing_metrics?.loyalty_back_count_change_percentage || false,
        loyaltyBackCountLastYear:
          data?.existing_metrics?.loyalty_back_count_last_year || false,

        loyaltyBackShare1w:
          data?.existing_metrics?.loyalty_back_share_1w || false,
        loyaltyBackShare1wChange:
          data?.existing_metrics?.loyalty_back_share_1w_change || false,
        loyaltyBackShare1wChangePercentage:
          data?.existing_metrics?.loyalty_back_share_1w_change_percentage ||
          false,
        loyaltyBackShare1wLastYear:
          data?.existing_metrics?.loyalty_back_share_1w_last_year || false,

        loyaltyBackShare2w:
          data?.existing_metrics?.loyalty_back_share_2w || false,
        loyaltyBackShare2wChange:
          data?.existing_metrics?.loyalty_back_share_2w_change || false,
        loyaltyBackShare2wChangePercentage:
          data?.existing_metrics?.loyalty_back_share_2w_change_percentage ||
          false,
        loyaltyBackShare2wLastYear:
          data?.existing_metrics?.loyalty_back_share_2w_last_year || false,

        loyaltyBackShare4w:
          data?.existing_metrics?.loyalty_back_share_4w || false,
        loyaltyBackShare4wChange:
          data?.existing_metrics?.loyalty_back_share_4w_change || false,
        loyaltyBackShare4wChangePercentage:
          data?.existing_metrics?.loyalty_back_share_4w_change_percentage ||
          false,
        loyaltyBackShare4wLastYear:
          data?.existing_metrics?.loyalty_back_share_4w_last_year || false,

        loyaltyBackShare6w:
          data?.existing_metrics?.loyalty_back_share_6w || false,
        loyaltyBackShare6wChange:
          data?.existing_metrics?.loyalty_back_share_6w_change || false,
        loyaltyBackShare6wChangePercentage:
          data?.existing_metrics?.loyalty_back_share_6w_change_percentage ||
          false,
        loyaltyBackShare6wLastYear:
          data?.existing_metrics?.loyalty_back_share_6w_last_year || false,

        loyaltyBackShare8w:
          data?.existing_metrics?.loyalty_back_share_8w || false,
        loyaltyBackShare8wChange:
          data?.existing_metrics?.loyalty_back_share_8w_change || false,
        loyaltyBackShare8wChangePercentage:
          data?.existing_metrics?.loyalty_back_share_8w_change_percentage ||
          false,
        loyaltyBackShare8wLastYear:
          data?.existing_metrics?.loyalty_back_share_8w_last_year || false,

        loyaltyBackAbsolute1w:
          data?.existing_metrics?.loyalty_back_absolute_1w || false,
        loyaltyBackAbsolute1wChange:
          data?.existing_metrics?.loyalty_back_absolute_1w_change || false,
        loyaltyBackAbsolute1wChangePercentage:
          data?.existing_metrics?.loyalty_back_absolute_1w_change_percentage ||
          false,
        loyaltyBackAbsolute1wLastYear:
          data?.existing_metrics?.loyalty_back_absolute_1w_last_year || false,

        loyaltyBackAbsolute2w:
          data?.existing_metrics?.loyalty_back_absolute_2w || false,
        loyaltyBackAbsolute2wChange:
          data?.existing_metrics?.loyalty_back_absolute_2w_change || false,
        loyaltyBackAbsolute2wChangePercentage:
          data?.existing_metrics?.loyalty_back_absolute_2w_change_percentage ||
          false,
        loyaltyBackAbsolute2wLastYear:
          data?.existing_metrics?.loyalty_back_absolute_2w_last_year || false,

        loyaltyBackAbsolute4w:
          data?.existing_metrics?.loyalty_back_absolute_4w || false,
        loyaltyBackAbsolute4wChange:
          data?.existing_metrics?.loyalty_back_absolute_4w_change || false,
        loyaltyBackAbsolute4wChangePercentage:
          data?.existing_metrics?.loyalty_back_absolute_4w_change_percentage ||
          false,
        loyaltyBackAbsolute4wLastYear:
          data?.existing_metrics?.loyalty_back_absolute_4w_last_year || false,

        loyaltyBackAbsolute6w:
          data?.existing_metrics?.loyalty_back_absolute_6w || false,
        loyaltyBackAbsolute6wChange:
          data?.existing_metrics?.loyalty_back_absolute_6w_change || false,
        loyaltyBackAbsolute6wChangePercentage:
          data?.existing_metrics?.loyalty_back_absolute_6w_change_percentage ||
          false,
        loyaltyBackAbsolute6wLastYear:
          data?.existing_metrics?.loyalty_back_absolute_6w_last_year || false,

        loyaltyBackAbsolute8w:
          data?.existing_metrics?.loyalty_back_absolute_8w || false,
        loyaltyBackAbsolute8wChange:
          data?.existing_metrics?.loyalty_back_absolute_8w_change || false,
        loyaltyBackAbsolute8wChangePercentage:
          data?.existing_metrics?.loyalty_back_absolute_8w_change_percentage ||
          false,
        loyaltyBackAbsolute8wLastYear:
          data?.existing_metrics?.loyalty_back_absolute_8w_last_year || false,

        priceAvg: data?.existing_metrics?.price_avg || false,
        priceAvgChange: data?.existing_metrics?.price_avg_change || false,
        priceAvgChangePercentage:
          data?.existing_metrics?.price_avg_change_percentage || false,
        priceAvgLastYear: data?.existing_metrics?.price_avg_last_year || false,

        //distribution
        avgShopsCount: data?.existing_metrics?.avg_shops_count || false,
        avgShopsCountChange:
          data?.existing_metrics?.avg_shops_count_change || false,
        avgShopsCountChangePercentage:
          data?.existing_metrics?.avg_shops_count_change_percentage || false,
        avgShopsCountLastYear:
          data?.existing_metrics?.avg_shops_count_last_year || false,
        avgPlanogramShopsCount:
          data?.existing_metrics?.avg_planogram_shops_count || false,
        avgPlanogramShopsCountChange:
          data?.existing_metrics?.avg_planogram_shops_count_change || false,
        avgPlanogramShopsCountChangePercentage:
          data?.existing_metrics?.avg_planogram_shops_count_change_percentage ||
          false,
        avgPlanogramShopsCountLastYear:
          data?.existing_metrics?.avg_planogram_shops_count_last_year || false,
        distributionRange: data?.existing_metrics?.distribution_range || false,
        distributionRangeChange:
          data?.existing_metrics?.distribution_range_change || false,
        distributionRangeChangePercentage:
          data?.existing_metrics?.distribution_range_change_percentage || false,
        distributionRangeLastYear:
          data?.existing_metrics?.distribution_range_last_year || false,
        planogramDistributionRange:
          data?.existing_metrics?.planogram_distribution_range || false,
        planogramDistributionRangeChange:
          data?.existing_metrics?.planogram_distribution_range_change || false,
        planogramDistributionRangeChangePercentage:
          data?.existing_metrics
            ?.planogram_distribution_range_change_percentage || false,
        planogramDistributionRangeLastYear:
          data?.existing_metrics?.planogram_distribution_range_last_year ||
          false,

        //weather
        avgTemp: data?.existing_metrics?.avg_temp || false,
        avgTempLastYear: data?.existing_metrics?.avg_temp_last_year || false,
        avgRainfall: data?.existing_metrics?.avg_rainfall || false,
        avgRainfallLastYear:
          data?.existing_metrics?.avg_rainfall_last_year || false
      }
    };
  });

const standarizeDataPoint = (
  dataPoint: LoyaltyDataPointApi
): LoyaltyTimeline => ({
  date: format(
    newDateByTimezone(new Date(dataPoint.date || Date.now())),
    DEFAULT_DATE_FORMAT
  ),
  dateLastYear: dataPoint.date_last_year
    ? format(
        newDateByTimezone(new Date(dataPoint.date_last_year)),
        DEFAULT_DATE_FORMAT
      )
    : null,
  loyaltyNewClientsCount: standarizeTimepointValue(
    dataPoint.loyalty_new_clients_count
  ),
  loyaltyNewClientsCountChange: standarizeTimepointValue(
    dataPoint.loyalty_new_clients_count_change
  ),
  loyaltyNewClientsCountChangePercentage: standarizeTimepointValue(
    dataPoint.loyalty_new_clients_count_change_percentage
  ),
  loyaltyNewClientsCountLastYear: standarizeTimepointValue(
    dataPoint.loyalty_new_clients_count_last_year
  ),

  loyaltyNewClientsPercent: standarizeTimepointValue(
    dataPoint.loyalty_new_clients_percent
  ),
  loyaltyNewClientsPercentChange: standarizeTimepointValue(
    dataPoint.loyalty_new_clients_percent_change
  ),
  loyaltyNewClientsPercentChangePercentage: standarizeTimepointValue(
    dataPoint.loyalty_new_clients_percent_change_percentage
  ),
  loyaltyNewClientsPercentLastYear: standarizeTimepointValue(
    dataPoint.loyalty_new_clients_percent_last_year
  ),

  loyaltyBackCount: standarizeTimepointValue(dataPoint.loyalty_back_count),
  loyaltyBackCountChange: standarizeTimepointValue(
    dataPoint.loyalty_back_count_change
  ),
  loyaltyBackCountChangePercentage: standarizeTimepointValue(
    dataPoint.loyalty_back_count_change_percentage
  ),
  loyaltyBackCountLastYear: standarizeTimepointValue(
    dataPoint.loyalty_back_count_last_year
  ),

  loyaltyBackPercent: standarizeTimepointValue(dataPoint.loyalty_back_percent),
  loyaltyBackPercentChange: standarizeTimepointValue(
    dataPoint.loyalty_back_percent_change
  ),
  loyaltyBackPercentChangePercentage: standarizeTimepointValue(
    dataPoint.loyalty_back_percent_change_percentage
  ),
  loyaltyBackPercentLastYear: standarizeTimepointValue(
    dataPoint.loyalty_back_percent_last_year
  ),

  loyaltyBackShare1w: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_1w
  ),
  loyaltyBackShare1wChange: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_1w_change
  ),
  loyaltyBackShare1wChangePercentage: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_1w_change_percentage
  ),
  loyaltyBackShare1wLastYear: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_1w_last_year
  ),

  loyaltyBackShare2w: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_2w
  ),
  loyaltyBackShare2wChange: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_2w_change
  ),
  loyaltyBackShare2wChangePercentage: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_2w_change_percentage
  ),
  loyaltyBackShare2wLastYear: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_2w_last_year
  ),

  loyaltyBackShare4w: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_4w
  ),
  loyaltyBackShare4wChange: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_4w_change
  ),
  loyaltyBackShare4wChangePercentage: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_4w_change_percentage
  ),
  loyaltyBackShare4wLastYear: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_4w_last_year
  ),

  loyaltyBackShare6w: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_6w
  ),
  loyaltyBackShare6wChange: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_6w_change
  ),
  loyaltyBackShare6wChangePercentage: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_6w_change_percentage
  ),
  loyaltyBackShare6wLastYear: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_6w_last_year
  ),

  loyaltyBackShare8w: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_8w
  ),
  loyaltyBackShare8wChange: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_8w_change
  ),
  loyaltyBackShare8wChangePercentage: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_8w_change_percentage
  ),
  loyaltyBackShare8wLastYear: standarizeTimepointValue(
    dataPoint?.loyalty_back_share_8w_last_year
  ),

  loyaltyBackAbsolute1w: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_1w
  ),
  loyaltyBackAbsolute1wChange: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_1w_change
  ),
  loyaltyBackAbsolute1wChangePercentage: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_1w_change_percentage
  ),
  loyaltyBackAbsolute1wLastYear: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_1w_last_year
  ),

  loyaltyBackAbsolute2w: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_2w
  ),
  loyaltyBackAbsolute2wChange: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_2w_change
  ),
  loyaltyBackAbsolute2wChangePercentage: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_2w_change_percentage
  ),
  loyaltyBackAbsolute2wLastYear: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_2w_last_year
  ),

  loyaltyBackAbsolute4w: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_4w
  ),
  loyaltyBackAbsolute4wChange: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_4w_change
  ),
  loyaltyBackAbsolute4wChangePercentage: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_4w_change_percentage
  ),
  loyaltyBackAbsolute4wLastYear: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_4w_last_year
  ),

  loyaltyBackAbsolute6w: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_6w
  ),
  loyaltyBackAbsolute6wChange: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_6w_change
  ),
  loyaltyBackAbsolute6wChangePercentage: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_6w_change_percentage
  ),
  loyaltyBackAbsolute6wLastYear: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_6w_last_year
  ),

  loyaltyBackAbsolute8w: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_8w
  ),
  loyaltyBackAbsolute8wChange: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_8w_change
  ),
  loyaltyBackAbsolute8wChangePercentage: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_8w_change_percentage
  ),
  loyaltyBackAbsolute8wLastYear: standarizeTimepointValue(
    dataPoint?.loyalty_back_absolute_8w_last_year
  ),

  priceAvg: standarizeTimepointValue(dataPoint.price_avg),
  priceAvgChange: standarizeTimepointValue(dataPoint.price_avg_change),
  priceAvgChangePercentage: standarizeTimepointValue(
    dataPoint.price_avg_change_percentage
  ),
  priceAvgLastYear: standarizeTimepointValue(dataPoint.price_avg_last_year),

  //distribution
  avgShopsCount: standarizeTimepointValue(dataPoint.avg_shops_count),
  avgShopsCountChange: standarizeTimepointValue(
    dataPoint.avg_shops_count_change
  ),
  avgShopsCountChangePercentage: standarizeTimepointValue(
    dataPoint.avg_shops_count_change_percentage
  ),
  avgShopsCountLastYear: standarizeTimepointValue(
    dataPoint.avg_shops_count_last_year
  ),
  avgPlanogramShopsCount: standarizeTimepointValue(
    dataPoint.avg_planogram_shops_count
  ),
  avgPlanogramShopsCountChange: standarizeTimepointValue(
    dataPoint.avg_planogram_shops_count_change
  ),
  avgPlanogramShopsCountChangePercentage: standarizeTimepointValue(
    dataPoint.avg_planogram_shops_count_change_percentage
  ),
  avgPlanogramShopsCountLastYear: standarizeTimepointValue(
    dataPoint.avg_planogram_shops_count_last_year
  ),
  distributionRange: standarizeTimepointValue(dataPoint.distribution_range),
  distributionRangeChange: standarizeTimepointValue(
    dataPoint.distribution_range_change
  ),
  distributionRangeChangePercentage: standarizeTimepointValue(
    dataPoint.distribution_range_change_percentage
  ),
  distributionRangeLastYear: standarizeTimepointValue(
    dataPoint.distribution_range_last_year
  ),
  planogramDistributionRange: standarizeTimepointValue(
    dataPoint.planogram_distribution_range
  ),
  planogramDistributionRangeChange: standarizeTimepointValue(
    dataPoint.planogram_distribution_range_change
  ),
  planogramDistributionRangeChangePercentage: standarizeTimepointValue(
    dataPoint.planogram_distribution_range_change_percentage
  ),
  planogramDistributionRangeLastYear: standarizeTimepointValue(
    dataPoint.planogram_distribution_range_last_year
  ),

  //weather
  avgTemp: standarizeTimepointValue(dataPoint.avg_temp),
  avgTempLastYear: standarizeTimepointValue(dataPoint.avg_temp_last_year),
  avgRainfall: standarizeTimepointValue(dataPoint.avg_rainfall),
  avgRainfallLastYear: standarizeTimepointValue(
    dataPoint.avg_rainfall_last_year
  )
});
