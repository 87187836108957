import { useEffect, useState } from "react";

import { useFullScreen } from "pages/Reports/partials/FullScreenWrapper/FullScreenWrapper";
import { BOOL_STRING_VALUES, TOURS_KEYS } from "utils";

import { EXPORT_NAV_STEPS, INSIGHTS_NAV_STEPS } from "../constants";
import { TourHooks } from "../hooks/useTours";
import { Tour } from "../Tour";

const { IS_INSIGHTS_NAV_TOUR_HIDDEN, IS_EXPORT_NAV_TOUR_HIDDEN } = TOURS_KEYS;
const { TRUE } = BOOL_STRING_VALUES;

export const NavbarTour = () => {
  const [run, setRun] = useState(false);
  const [
    { isInsightsNavTourHidden, isExportNavTourHidden },
    actions
  ] = TourHooks.useTours();
  const { visible: isFullScreen } = useFullScreen();

  const activeTour = [
    {
      key: IS_INSIGHTS_NAV_TOUR_HIDDEN,
      steps: INSIGHTS_NAV_STEPS,
      isHidden: isInsightsNavTourHidden,
      setter: actions.setInsightsNavTourHidden
    },
    {
      key: IS_EXPORT_NAV_TOUR_HIDDEN,
      steps: EXPORT_NAV_STEPS,
      isHidden: isExportNavTourHidden,
      setter: actions.setExportNavTourHidden
    }
  ].find(({ isHidden }) => !isHidden);

  const handleClose = () => {
    if (!activeTour) return;

    localStorage.setItem(activeTour.key, TRUE);
    activeTour.setter(true);
  };

  useEffect(() => {
    // timeout is needed due to Navbar component being wrapped in Suspense
    let timeout = setTimeout(() => {
      setRun(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (isFullScreen || !activeTour) {
    return null;
  }

  return (
    <Tour
      key={activeTour.key}
      run={run}
      steps={activeTour.steps}
      onClose={handleClose}
    />
  );
};
