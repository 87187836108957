import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { format } from "date-fns";
import qs from "query-string";

import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { CategoryItemApi } from "pages/Reports/redux/reducers/filters/categoryFilters/categoryFiltersReducer";
import {
  CategoryHooks,
  CategoryItem,
  CategoryStoreInstance,
  getAllCategories,
  getSelectedCategories
} from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import { isSuperUserSelector, tierSelector } from "store/reducers/userReducer";
import { categoriesSelector } from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { DEFAULT_DATE_FORMAT, HTTP, REST_API_ENDPOINTS } from "utils";

type CategoryRequestParams = {
  level: number;
  vendor_id?: string;
  tier: string;
};

const useFilterParams = (level: 1 | 2 | 3): CategoryRequestParams => {
  const isSuperUser = useSelector(isSuperUserSelector);
  const tier = useSelector(tierSelector);

  if (isSuperUser) {
    return {
      level,
      tier: String(tier),
      vendor_id: ""
    };
  }

  return {
    level,
    tier: String(tier)
  };
};

const useFetchingFlag = (
  level: 1 | 2 | 3,
  params: {
    vendor_id?: string;
  }
) => {
  const fetching = useSelector(asyncFiltersSelector).fetching;
  const isLoading =
    level === 1
      ? fetching.category1
      : level === 2
      ? fetching.category2
      : fetching.category3;
  const lastQueryObj = useSelector(asyncFiltersSelector).query;
  const lastQuery =
    level === 1
      ? lastQueryObj.category1
      : level === 2
      ? lastQueryObj.category2
      : lastQueryObj.category3;

  const query = qs.stringify(params);
  if (isLoading) {
    return false;
  }

  if (query === lastQuery) {
    return false;
  }

  return true;
};

const useCategoryFetching = (level: 1 | 2 | 3) => {
  const [, actions] = CategoryHooks.useCategory();
  const dispatch = useDispatch();
  const categoriesQP = useSelector(categoriesSelector);
  const params = useFilterParams(level);
  const isFetchingPossible = useFetchingFlag(level, params);
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;

  const saveAndSelectCallback = React.useCallback(
    (categories: CategoryItem[]) => {
      actions.updateAllCategories(categories, level);
      if (isPristine) {
        const categoryStore = CategoryStoreInstance.storeState.getState();
        const cat1 = getAllCategories(categoryStore, 1);
        const cat2 = getAllCategories(categoryStore, 2);
        const cat3 = getAllCategories(categoryStore, 3);

        if (cat1.length || cat2.length || cat3.length) {
          if (categoriesQP.category1) {
            const toSelect = cat1.filter(cat =>
              categoriesQP.category1.includes(String(cat.value))
            );
            actions.updateSelectedCategories(toSelect, 1);
          }

          if (categoriesQP.category2) {
            const toSelect = cat2.filter(cat =>
              categoriesQP.category2.includes(String(cat.value))
            );
            actions.updateSelectedCategories(toSelect, 2);
          }

          if (categoriesQP.category3) {
            const toSelect = cat3.filter(cat =>
              categoriesQP.category3.includes(String(cat.value))
            );
            actions.updateSelectedCategories(toSelect, 3);
          }
        } else {
          actions.updateSelectedCategories(cat3.length === 1 ? cat3 : [], 3);
          actions.updateSelectedCategories(cat2.length === 1 ? cat2 : [], 2);
          actions.updateSelectedCategories(cat1.length === 1 ? cat1 : [], 1);
        }
      } else {
        const categoryStore = CategoryStoreInstance.storeState.getState();
        const cat1 = getSelectedCategories(categoryStore, 1);
        const cat2 = getSelectedCategories(categoryStore, 2);
        const cat3 = getSelectedCategories(categoryStore, 3);

        const newCat1 = cat1.filter(cat =>
          categoriesQP.category1.includes(String(cat.value))
        );
        const newCat2 = cat2.filter(cat =>
          categoriesQP.category2.includes(String(cat.value))
        );
        const newCat3 = cat3.filter(cat =>
          categoriesQP.category3.includes(String(cat.value))
        );
        actions.updateSelectedCategories(newCat1, 1);
        actions.updateSelectedCategories(newCat2, 2);
        actions.updateSelectedCategories(newCat3, 3);
      }

      actions.sortCategories(level);
    },
    [
      actions,
      level,
      isPristine,
      categoriesQP.category1,
      categoriesQP.category2,
      categoriesQP.category3
    ]
  );

  React.useEffect(() => {
    if (!isFetchingPossible) {
      return;
    }

    const fetch = async () => {
      const filter =
        level === 1 ? "category1" : level === 2 ? "category2" : "category3";
      dispatch(updateQuery({ filter, query: qs.stringify(params) }));

      dispatch(setFlag({ filter, isFetching: true }));
      try {
        const response = await HTTP.get<CategoryItemApi[]>(
          REST_API_ENDPOINTS.CATEGORIES,
          {
            params
          }
        );

        const categories = await response.data.map(
          ({ id, name, parent, start_date, is_fake }) => ({
            value: id || 0,
            label: name || "",
            parent: parent?.id
              ? {
                  id: parent.id,
                  parent: parent?.parent?.id ? { id: parent.parent.id } : null
                }
              : null,
            startDate:
              start_date || format(newDateByTimezone(), DEFAULT_DATE_FORMAT),
            isFake: is_fake || false
          })
        );

        dispatch(setError({ filter, status: "" }));

        return categories;
      } catch (e) {
        dispatch(setError({ filter, status: "Błąd pobierania kategorii" }));
        return [];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };
    fetch().then(saveAndSelectCallback);
  }, [dispatch, isFetchingPossible, params, saveAndSelectCallback, level]);
};

export const useCategoryBehaviour = (level: 1 | 2 | 3) => {
  useCategoryFetching(level);
};
