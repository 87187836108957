import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { TABLE_COLUMN_ITEM_LABEL } from "utils/constants";
import { checkChangeColumn } from "utils/polishLabels/getPolishLabelForSidebar";

export enum SidebarSortingTypes {
  BASE = "BASE",
  BY_BASE_PERIOD = "BY_BASE_PERIOD",
  BY_HISTORICAL_PERIOD = "BY_HISTORICAL_PERIOD",
  BY_CHANGE_INTEGER = "BY_CHANGE_INTEGER",
  BY_CHANGE_PERCENTAGE = "BY_CHANGE_PERCENTAGE"
}

const NAME_SORTING = [
  {
    label: "od A do Z",
    isDescending: false,
    sortingType: SidebarSortingTypes.BASE
  },
  {
    label: "od Z do A",
    isDescending: true,
    sortingType: SidebarSortingTypes.BASE
  }
];

const BASE_VALUES_SORTING = [
  {
    label: "bazowy (rosnąco)",
    isDescending: false,
    sortingType: SidebarSortingTypes.BY_BASE_PERIOD
  },
  {
    label: "bazowy (malejąco)",
    isDescending: true,
    sortingType: SidebarSortingTypes.BY_BASE_PERIOD
  }
];

const HISTORICAL_VALUES_SORTING = [
  {
    label: "historyczny (rosnąco)",
    isDescending: false,
    sortingType: SidebarSortingTypes.BY_HISTORICAL_PERIOD
  },
  {
    label: "historyczny (malejąco)",
    isDescending: true,
    sortingType: SidebarSortingTypes.BY_HISTORICAL_PERIOD
  }
];

const CHANGE_VALUES_SORTING = [
  {
    label: "całkowita (rosnąco)",
    isDescending: false,
    sortingType: SidebarSortingTypes.BY_CHANGE_INTEGER
  },
  {
    label: "całkowita (malejąco)",
    isDescending: true,
    sortingType: SidebarSortingTypes.BY_CHANGE_INTEGER
  },
  {
    label: "procentowa (rosnąco)",
    isDescending: false,
    sortingType: SidebarSortingTypes.BY_CHANGE_PERCENTAGE
  },
  {
    label: "procentowa (malejąco)",
    isDescending: true,
    sortingType: SidebarSortingTypes.BY_CHANGE_PERCENTAGE
  }
];

export const useSidebarSorting = (columnId: string | number) => {
  const dataset = useCurrentDataset();

  if (columnId === TABLE_COLUMN_ITEM_LABEL) {
    return {
      title: "Alfabetycznie:",
      options: NAME_SORTING
    };
  }

  if (checkChangeColumn(String(columnId))) {
    return {
      title: "Zmiana:",
      options: CHANGE_VALUES_SORTING
    };
  }

  if (dataset.base) {
    return {
      title: "Okres:",
      options: BASE_VALUES_SORTING
    };
  }

  if (dataset.historical) {
    return {
      title: "Okres:",
      options: HISTORICAL_VALUES_SORTING
    };
  }

  return {
    title: "Okres:",
    options: [...BASE_VALUES_SORTING, ...HISTORICAL_VALUES_SORTING]
  };
};
