import { values } from "ramda";

import { APP_PATHS, PROMOTION_DATA_TYPES } from "utils";
import { isMultipackWsaEnabled } from "utils/constants";

export const SECTIONS = {
  SELL: "Sprzedaż",
  SHARES: "Udziały",
  DYNAMICS: "Dynamika",
  RECEIPTS: "Paragony",
  SEGMENTS: "Segmenty",
  LOCATION: "Lokalizacja",
  LOGISTICS: "Logistyka",
  HOURS: "Godziny",
  LOYALTY: "Lojalność",
  MULTIPACK: "Wielosztuki",
  PROMOTIONS: "Promocje"
} as const;

export const REPORTS_PATHS = {
  SELL: "/sell",
  SHARES: "/shares",
  LOCATION: "/location",
  DYNAMICS: "/dynamics",
  RECEIPTS: "/receipts",
  LOGISTICS: "/logistics",
  SEGMENTS: "/segments",
  EXPORT: "/export",
  HOURS: "/hours",
  LOYALTY: "/loyalty",
  MULTIPACK: "/multipack",
  PROMOTIONS: "/promotions"
};

export const REPORTS_FULL_PATHS = {
  SELL_PATH: APP_PATHS.REPORTS + REPORTS_PATHS.SELL,
  SHARES_PATH: APP_PATHS.REPORTS + REPORTS_PATHS.SHARES,
  LOCATION_PATH: APP_PATHS.REPORTS + REPORTS_PATHS.LOCATION,
  DYNAMICS_PATH: APP_PATHS.REPORTS + REPORTS_PATHS.DYNAMICS,
  RECEIPTS_PATH: APP_PATHS.REPORTS + REPORTS_PATHS.RECEIPTS,
  LOGISTICS_PATH: APP_PATHS.REPORTS + REPORTS_PATHS.LOGISTICS,
  SEGMENTS_PATH: APP_PATHS.REPORTS + REPORTS_PATHS.SEGMENTS,
  EXPORT_PATH: APP_PATHS.REPORTS + REPORTS_PATHS.EXPORT,
  HOURS_PATH: APP_PATHS.REPORTS + REPORTS_PATHS.HOURS,
  LOYALTY_PATH: APP_PATHS.REPORTS + REPORTS_PATHS.LOYALTY,
  MULTIPACK_PATH: APP_PATHS.REPORTS + REPORTS_PATHS.MULTIPACK,
  PROMOTIONS_PATH: APP_PATHS.REPORTS + REPORTS_PATHS.PROMOTIONS
};

// SUMMARY
export const SUMMARY_SWITCH = {
  TOTAL: "TOTAL",
  PER_SHOP: "PER_SHOP",
  QUANTITY: "QUANTITY",
  WORTH: "WORTH",
  WORTH_PERCENTAGE: "WORTH_PERCENTAGE",
  VALUE_QUANTITY: "VALUE_QUANTITY",
  RECEIPTS_METRICS: "RECEIPTS_METRICS",
  LOYALTY_METRICS: "LOYALTY_METRICS",
  DISTRIBUTION: "DISTRIBUTION",
  NATURAL_UNITS: "NATURAL_UNITS",
  MULTIPACK: "MULTIPACK",
  MULTIPACK_TOTAL: "MULTIPACK_TOTAL",
  MULTIPACK_WSO: "MULTIPACK_WSO",
  MULTIPACK_WSA: "MULTIPACK_WSA"
} as const;

export const SUMMARY_SUB_SWITCH = {
  OWN: "OWN",
  REF_CATEGORY: "REF_CATEGORY",
  SELL_OWN: "SELL_OWN",
  SELL_REF_CATEGORY: "SELL_REF_CATEGORY",
  SHARES_OWN: "SHARES_OWN",
  SHARES_REF_CATEGORY: "SHARES_REF_CATEGORY",
  NATURAL_UNITS_OWN: "NATURAL_UNITS_OWN",
  NATURAL_UNITS_REF_CATEGORY: "NATURAL_UNITS_REF_CATEGORY"
};

const DISTRIBUTION_SUMMARY_TAB = {
  label: "Dystrybucja",
  value: SUMMARY_SWITCH.DISTRIBUTION,
  ga: { "data-ga-summary-tab": "Dystrybucja" }
};

const NATURAL_UNITS_SUMMARY_TAB = {
  label: "Jednostki naturalne",
  value: SUMMARY_SWITCH.NATURAL_UNITS,
  ga: { "data-ga-summary-tab": "Jednostki naturalne" }
};

export const LOCATION_SUMMARY_TABS = [
  {
    label: "Dane total",
    value: SUMMARY_SWITCH.TOTAL,
    ga: { "data-ga-summary-tab": "Dane total" }
  },
  {
    label: "Dane per sklep",
    value: SUMMARY_SWITCH.PER_SHOP,
    ga: { "data-ga-summary-tab": "Dane per sklep" }
  },
  DISTRIBUTION_SUMMARY_TAB
];

export const SELL_SUMMARY_TABS = [
  ...LOCATION_SUMMARY_TABS,
  NATURAL_UNITS_SUMMARY_TAB
];

export const SHARES_SUMMARY_TABS = [
  {
    label: "Wartość / ilość",
    value: SUMMARY_SWITCH.VALUE_QUANTITY,
    ga: { "data-ga-summary-tab": "Wartość / ilość" }
  },
  DISTRIBUTION_SUMMARY_TAB,
  NATURAL_UNITS_SUMMARY_TAB
];

export const DYNAMICS_SUMMARY_TABS = [
  {
    label: "Wartość",
    value: SUMMARY_SWITCH.WORTH,
    ga: { "data-ga-summary-tab": "Wartość" }
  },
  {
    label: "Sztuki",
    value: SUMMARY_SWITCH.QUANTITY,
    ga: { "data-ga-summary-tab": "Sztuki" }
  },
  DISTRIBUTION_SUMMARY_TAB,
  NATURAL_UNITS_SUMMARY_TAB
];

export const RECEIPTS_SUMMARY_TABS = [
  {
    label: "Miary paragonowe",
    value: SUMMARY_SWITCH.RECEIPTS_METRICS,
    ga: { "data-ga-summary-tab": "Miary paragonowe" }
  },
  DISTRIBUTION_SUMMARY_TAB,
  NATURAL_UNITS_SUMMARY_TAB
];

export const LOYALTY_SUMMARY_TABS = [
  {
    label: "Dane własne",
    value: SUMMARY_SWITCH.TOTAL,
    ga: { "data-ga-summary-tab": "Miary lojalnościowe" }
  },
  DISTRIBUTION_SUMMARY_TAB
];

export const MULTIPACK_SUMMARY_TABS = [
  {
    label: "Wielosztuki total",
    value: SUMMARY_SWITCH.MULTIPACK_TOTAL,
    ga: { "data-ga-summary-tab": "Wielosztuki total" }
  },
  {
    label: "Wielosztuki",
    value: SUMMARY_SWITCH.MULTIPACK,
    ga: { "data-ga-summary-tab": "Wielosztuki" }
  },
  {
    label: "Wielosztuki Super Oferta (WSO)",
    value: SUMMARY_SWITCH.MULTIPACK_WSO,
    ga: { "data-ga-summary-tab": "Wielosztuki Super Oferta (WSO)" }
  },
  ...(isMultipackWsaEnabled
    ? [
        {
          label: "Wielosztuki Standardowe Aplikacyjne (WSA)",
          value: SUMMARY_SWITCH.MULTIPACK_WSA,
          ga: {
            "data-ga-summary-tab": "Wielosztuki Standardowe Aplikacyjne (WSA)"
          }
        }
      ]
    : [])
];

export const SUMMARY_SUB_TABS = [
  {
    label: "Dane własne",
    value: SUMMARY_SUB_SWITCH.OWN,
    ga: { "data-ga-summary-sub-tab": "Dane własne" }
  },
  {
    label: "Dane kategorii referencyjnej",
    value: SUMMARY_SUB_SWITCH.REF_CATEGORY,
    ga: { "data-ga-summary-sub-tab": "Dane kategorii referencyjnej" }
  }
];

export const MULTIPACK_SUMMARY_SUB_TABS = [
  {
    label: "Sprzedaż - dane własne",
    value: SUMMARY_SUB_SWITCH.SELL_OWN,
    ga: { "data-ga-summary-sub-tab": "Sprzedaż - dane własne" }
  },
  {
    label: "Udziały - dane własne",
    value: SUMMARY_SUB_SWITCH.SHARES_OWN,
    ga: { "data-ga-summary-sub-tab": "Udziały - dane własne" }
  },
  {
    label: "Jednostki naturalne - dane własne",
    value: SUMMARY_SUB_SWITCH.NATURAL_UNITS_OWN,
    ga: { "data-ga-summary-sub-tab": "Jednostki naturalne - dane własne" }
  },
  {
    label: "Sprzedaż - dane kat. ref.",
    value: SUMMARY_SUB_SWITCH.SELL_REF_CATEGORY,
    ga: { "data-ga-summary-sub-tab": "Sprzedaż - dane kat. ref." }
  },
  {
    label: "Udziały - dane kat. ref.",
    value: SUMMARY_SUB_SWITCH.SHARES_REF_CATEGORY,
    ga: { "data-ga-summary-sub-tab": "Udziały - dane kat. ref." }
  },
  {
    label: "Jednostki naturalne - dane kat. ref.",
    value: SUMMARY_SUB_SWITCH.NATURAL_UNITS_REF_CATEGORY,
    ga: { "data-ga-summary-sub-tab": "Jednostki naturalne - dane kat. ref." }
  }
];

export const PROMOTION_CHART_DATA_TYPES = values(PROMOTION_DATA_TYPES);

// EXPORT
export const EXPORT_TYPES = {
  CSV: "CSV",
  EXCEL: "EXCEL",
  LOGISTICS_SINGLE_DAY: "LOGISTICS_SINGLE_DAY",
  LOGITICS_SELECTED_PERIOD: "LOGITICS_SELECTED_PERIOD"
} as const;
