import { useGetRanking } from "api/ranking/hooks";
import { useGetReceiptsByPeriod } from "api/receipts/mappers";

export const useIsReceiptsLoading = () => {
  const {
    isLoading: isReceiptsLoading,
    isIdle: isReceiptsIdle
  } = useGetReceiptsByPeriod();
  const { isLoading: rankingLoading } = useGetRanking();

  return isReceiptsLoading || isReceiptsIdle || rankingLoading;
};
