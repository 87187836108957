import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";
import qs from "query-string";

import { SpinnerLoader } from "components/atoms";
import { Search } from "components/atoms/Icon";
import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import { FilterErrorMsg } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/FilterErrorMsg/FilterErrorMsg";
import {
  asyncFiltersSelector,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { useShopTypeFetching } from "pages/Reports/redux/reducers/filters/hooks/useShopTypeFetching";
import {
  getItemsCounterLabel,
  getShopTypeLabel,
  ShopTypeHooks,
  ShopTypeItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useShopType";

import { DROPDOWN_PLACEHOLDERS } from "../constants";
import { useDisabledTooltip } from "../hooks/useDisabledTooltip";
import { filterItems, isActiveFn } from "../utils";
import s from "./shopTypeDropdown.module.scss";

export const ShopTypeDropdown = ({ isDisabled }: { isDisabled: boolean }) => {
  const [state, actions] = ShopTypeHooks.useShopType();
  const [isOpen, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const selectedShopTypesLabel = getShopTypeLabel(state);

  const dispatch = useDispatch();
  const errorMsg = useSelector(asyncFiltersSelector).error.shopTypes;
  const isLoading = useSelector(asyncFiltersSelector).fetching.shopTypes;
  const tooltip = useDisabledTooltip(FILTER_NAMES.STORE_TYPE, isDisabled);

  useShopTypeFetching(isDisabled);

  const handleInputValueChange = (value?: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (selectedItem?: ShopTypeItem) => {
    setOpen(false);

    if (!selectedItem) {
      setInputValue(selectedShopTypesLabel);
      actions.sortShopTypes();
    }
  };

  const handleStateChange = (changes: StateChangeOptions<ShopTypeItem[]>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleChange = (item: ShopTypeItem | null) => {
    setOpen(true);
    actions.selectShopType(item);
  };

  const handleSelectAll = () => actions.selectAllShopTypes(inputValue);

  React.useEffect(() => {
    if (!isOpen) setInputValue(selectedShopTypesLabel);
  }, [isOpen, selectedShopTypesLabel]);

  if (!isLoading && errorMsg) {
    return (
      <FilterErrorMsg
        callback={() => {
          dispatch(
            updateQuery({ filter: "shopTypes", query: qs.stringify({}) })
          );
        }}
        errorMsg={errorMsg}
      />
    );
  }

  return (
    <Dropdown<ShopTypeItem[]>
      isMultiChoice
      isDisabled={isDisabled || isLoading}
      // @ts-ignore workoaround: downshift calls onInputValueCahnge  with result of itemToString prop call.
      // once we know this fn always returns undefined it makes downshift fully controled and inputValue
      // depends only on state of CategoryDropdown
      itemToString={() => {}}
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      items={state.all}
      selectedItem={state.selected}
      // @ts-ignore
      onChange={handleChange}
      onClear={() => handleChange(null)}
      className={s.shopTypeDropdownWrapper}
      onStateChange={handleStateChange}
      openedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.STORE_TYPE.OPEN
      }
      closedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.STORE_TYPE.CLOSED
      }
      onSelectAll={handleSelectAll}
      input={
        <FilterDropdownInput
          customIcon={isLoading ? <SpinnerLoader size="small" /> : <Search />}
          isTypingEnabled
          automaticInputValue={selectedShopTypesLabel}
          tooltip={tooltip}
          testId="shop-type-dropdown"
        />
      }
      menu={
        <FilterDropdownMenu
          isItemActiveFn={isActiveFn}
          filterItemsFn={filterItems}
          listItem={<FilterDropdownListItem />}
          itemsCounterText={getItemsCounterLabel(state)}
        />
      }
    />
  );
};
