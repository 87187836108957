import { useMemo, useState } from "react";
import Measure, { ContentRect } from "react-measure";
import { useSelector } from "react-redux";

import { useRankingData } from "api/ranking/mappers/useRankingData";
import { max, min, scaleBand, scaleLinear } from "d3";

import { Cursor } from "components/atoms";
import s from "components/common.module.scss";
import { useChartTopLabel } from "hooks/useChartTopLabel";
import { checkedDataTypes } from "pages/Reports/sections/utils/chartData";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { getPolishLabelForChartHeader } from "utils";
import { Nullable } from "utils/types";

import { BottomAxis } from "./BottomAxis";
import { ChartRects } from "./ChartRects";
import { HorizontalChartSidebar } from "./HorizontalChartSidebar";
import { IndexAxis } from "./IndexAxis";
import { LeftAxis } from "./LeftAxis";
import { Tooltip } from "./Tooltip";
import { HorizontalChart } from "./utils/getRankingData";

export const HorizontalBarChart = () => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [yMaxLabelWidth, setYMaxLabelWidth] = useState(0);
  const [tooltip, setTooltip] = useState<Nullable<HorizontalChart>>(null);
  const { regularDateLabel } = useChartTopLabel({});

  const data = useRankingData();
  const firstType = useSelector(firstChartDataTypeSelector);
  const secondType = useSelector(secondChartDataTypeSelector);

  const sidebar = useMemo(() => {
    const title = getPolishLabelForChartHeader(secondType);
    const labels = data.map(bar => bar.sidebarLabel || "");

    const titleWidth = title.length * 8; // 8 - avg. letter width for title
    const labelsWidths = labels.map(label => label.length * 6); // 6 - avg. letter width for sidebar label
    const maxLabelWidth = Math.max(...labelsWidths);
    const width = Math.max(titleWidth, maxLabelWidth);

    return { title, width };
  }, [data, secondType]);

  const showSidebar = checkedDataTypes([firstType, secondType]).length === 2;
  const margin = {
    top: 25,
    right: showSidebar ? sidebar.width + 80 : 80, // 80 - avg. width for rect label
    bottom: 20,
    left: yMaxLabelWidth + 25 // 25 - avg. width for index labels
  };
  const width = containerWidth - margin.left - margin.right;
  const height = Math.max(300, data.length * 40) - margin.top - margin.bottom; // 40 - avg. bar height

  const hasNegatives = data.some(({ primaryValue }) => primaryValue < 0);
  const xMin = hasNegatives
    ? min(data.map(({ primaryValue }) => primaryValue)) || 0
    : 0;

  const xMaxValue = max(data.map(({ primaryValue }) => primaryValue)) || 1;
  const xMax = Math.max(0, xMaxValue);

  const scaleX = scaleLinear()
    .domain([xMin, xMax])
    .range([0, width]);
  const scaleY = scaleBand()
    .domain(data.map(rect => String(rect.id)))
    .range([0, height])
    .padding(data.length < 3 ? 0.5 : 0.2);

  const handleResize = ({ bounds }: ContentRect) => {
    setContainerWidth(bounds?.width || 0);
  };

  const handleRectMouseEvent = (data: Nullable<HorizontalChart>) => () => {
    setTooltip(data);
  };

  if (!data.length) return null;

  return (
    <>
      <span className={s.chartSectionDatesLabel}>{regularDateLabel}</span>
      <Measure onResize={handleResize} bounds>
        {({ measureRef }) => (
          <Cursor>
            {({ x, y }) => (
              <div
                ref={measureRef}
                className={s.chart}
                data-testid="ranking-chart-box"
              >
                <svg
                  width={width + margin.left + margin.right}
                  height={height + margin.top + margin.bottom}
                >
                  <g transform={`translate(${margin.left}, ${margin.top})`}>
                    <IndexAxis
                      transform={`translate(${-margin.left}, 0)`}
                      data={data}
                      scaleY={scaleY}
                    />
                    <BottomAxis
                      transform={`translate(0, ${height})`}
                      scaleX={scaleX}
                      height={height}
                    />
                    <LeftAxis
                      scaleY={scaleY}
                      setMaxLabelWidth={setYMaxLabelWidth}
                      data={data}
                      onMouse={handleRectMouseEvent}
                    />
                    <ChartRects
                      data={data}
                      scaleX={scaleX}
                      scaleY={scaleY}
                      tooltip={tooltip}
                      onMouse={handleRectMouseEvent}
                    />
                    {showSidebar && (
                      <HorizontalChartSidebar
                        transform={`translate(${scaleX(xMax) + 80}, 0)`} // 80 - avg. width for rect label
                        title={sidebar.title}
                        data={data}
                        tooltip={tooltip}
                        scaleY={scaleY}
                        height={height}
                        width={sidebar.width}
                      />
                    )}
                  </g>
                </svg>
                <Tooltip x={x} y={y} tooltip={tooltip} />
              </div>
            )}
          </Cursor>
        )}
      </Measure>
    </>
  );
};
