import React from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import { DropdownItem } from "components/molecules/types";
import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import {
  ShopTypeApi,
  sortBySpecificOrder
} from "pages/Reports/redux/reducers/sweetStateHooks/useShopType";
import { ShopTypeMetaHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useShopTypeMeta";
import { shopTypesMetaQueryParamsSelector } from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";

const fetchShopTypesMeta = async () => {
  const response = await HTTP.get<ShopTypeApi[]>(
    REST_API_ENDPOINTS.STORE_TYPES
  );

  return response.data.map(item => ({
    value: item.id || "",
    label: item.name || ""
  }));
};

const useFetchingFlag = (params: {}, isDisabled: boolean) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching.shopTypesMeta;
  const lastQuery = useSelector(asyncFiltersSelector).query.shopTypesMeta;

  const query = qs.stringify(params);
  if (isLoading || isDisabled) {
    return false;
  }

  if (query === lastQuery) {
    return false;
  }

  return true;
};

export const useShopTypeMetaFetching = (isDisabled: boolean) => {
  const filter = "shopTypesMeta";
  const [, actions] = ShopTypeMetaHooks.useShopTypeMeta();
  const dispatch = useDispatch();
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const shopTypeQP = useSelector(shopTypesMetaQueryParamsSelector);

  const isFetchingPossible = useFetchingFlag({}, isDisabled);

  const callback = React.useCallback(
    (shopTypesMeta: DropdownItem<string>[]) => {
      const allShopTypesInOrder = sortBySpecificOrder(shopTypesMeta);
      actions.updateAllShopTypesMeta(allShopTypesInOrder);

      if (isPristine) {
        actions.checkPristineSelectionsAfterFetching(shopTypesMeta, shopTypeQP);
      }
      actions.checkSelectionsAfterFetching(
        shopTypesMeta.map(shop => shop.value)
      );
    },
    [isPristine, actions, shopTypeQP]
  );
  React.useEffect(() => {
    if (!isFetchingPossible) {
      return;
    }

    const fetch = async () => {
      try {
        dispatch(updateQuery({ filter, query: qs.stringify({}) }));

        dispatch(setFlag({ filter, isFetching: true }));
        const shopTypes = await fetchShopTypesMeta();
        dispatch(setError({ filter, status: "" }));

        return shopTypes;
      } catch (e) {
        dispatch(
          setError({ filter, status: "Błąd pobierania typów metasklepów" })
        );
        return [];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };
    fetch().then(callback);
  }, [dispatch, isFetchingPossible, callback]);
};
