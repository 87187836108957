import {
  areIntervalsOverlapping,
  endOfMonth,
  endOfWeek,
  endOfYear,
  isWithinInterval
} from "date-fns";

import { FetchedPromotion } from "pages/Reports/redux/reducers/promotionsReducer";
import { newDateByTimezone } from "store/utils";
import { PERIOD_TYPE } from "utils";
import { Periods } from "utils/types";

const getEndDate = (date: Date, period: Periods): Date => {
  switch (period) {
    case PERIOD_TYPE.WEEKS:
      return endOfWeek(date, { weekStartsOn: 1 });
    case PERIOD_TYPE.MONTHS:
      return endOfMonth(date);
    case PERIOD_TYPE.YEAR:
      return endOfYear(date);
    default:
      return date;
  }
};

export const isDateInPromotionInterval = (
  date: Date,
  { validFrom, validTo }: FetchedPromotion,
  period: Periods
): boolean => {
  const promotionInterval = {
    start: newDateByTimezone(validFrom),
    end: newDateByTimezone(validTo)
  };

  if (period === PERIOD_TYPE.DAYS) {
    return isWithinInterval(date, promotionInterval);
  }

  return areIntervalsOverlapping(
    {
      start: date,
      end: getEndDate(date, period)
    },
    promotionInterval
  );
};
