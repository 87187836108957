import { ascend, comparator, descend, path, prop, sortWith } from "ramda";

import { SidebarSortingTypes } from "hooks/useSidebarSorting";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { Metrics } from "pages/Reports/types/metrics";
import {
  getSecondValueMetricKey,
  transformSortTypeToSidebarValueType
} from "pages/Reports/utils/getSecondValueDataType";
import { DATASET_TYPES } from "utils";
import { BOOL_STRING_VALUES, TABLE_COLUMN_ITEM_LABEL } from "utils/constants";
import { Keys, Values } from "utils/types";

import { getChartYKey } from "./getChartYKey";
import { DataTimeline } from "./sortDataByVendorAndLabel";

type Data = {
  vendorId: string;
  type: Values<typeof DATASET_TYPES>;
  label: string;
  data: DataTimeline;
  legendId: string;
  isInHiddenLines: boolean;
  isTimelineMerged?: boolean;
};

const moveNullsToTheEnd = (key: Keys<Metrics>) =>
  comparator((a: Data, b: Data) =>
    a.data[key] !== null && b.data[key] === null ? true : false
  );

const moveOwnDataToTheStart = comparator((a: Data, b: Data) =>
  a.type === DATASET_TYPES.OWN && b.type !== DATASET_TYPES.OWN ? true : false
);

export const sortSidebarData = (
  sidebarSortBy: string, //metric to sort by
  sidebarDesc: string, //descending/ascending
  sidebarSortType: string, //sort by first (base/integer) value or second (historical/percentage) value
  sidebarSortGroup: string, //group by vendors
  sidebarSortEnd: string //move unselected items to the end
) => {
  const moveUnselectedToTheEnd = () => {
    if (sidebarSortEnd === BOOL_STRING_VALUES.FALSE) {
      return (a: Data, b: Data) => 0;
    }

    return comparator((a: Data, b: Data) => {
      return Boolean(!a.isInHiddenLines && b.isInHiddenLines);
    });
  };

  const climbFunction =
    sidebarDesc === BOOL_STRING_VALUES.FALSE ? ascend : descend;

  const groupPerVendors = () => {
    if (sidebarSortGroup === BOOL_STRING_VALUES.FALSE) {
      return [];
    }

    //move own data to the start, then sort ascending/descending by vendorId
    //everything is returned as an array to perform a spread into sortWith down below
    return [moveOwnDataToTheStart, climbFunction<Data>(prop("vendorId"))];
  };

  // SORT BY PRODUCT/CATEGORY NAME COLUMN
  if (sidebarSortBy === TABLE_COLUMN_ITEM_LABEL) {
    return sortWith([
      ...groupPerVendors(),
      moveUnselectedToTheEnd(),
      climbFunction<Data>(prop("label"))
    ]);
  }

  // SORT BY VALUE/CHANGE COLUMN
  const chartKey = String(
    getChartYKey(sidebarSortBy as ChartDataTypes)
  ) as Keys<Metrics>;

  const sidebarValueType = transformSortTypeToSidebarValueType(sidebarSortType);
  const isSortedBySecondValue =
    sidebarSortType === SidebarSortingTypes.BY_HISTORICAL_PERIOD ||
    sidebarSortType === SidebarSortingTypes.BY_CHANGE_PERCENTAGE;

  const key = isSortedBySecondValue
    ? getSecondValueMetricKey(chartKey, sidebarValueType)
    : chartKey;

  return sortWith([
    ...groupPerVendors(),
    moveUnselectedToTheEnd(),
    moveNullsToTheEnd(key),
    climbFunction<Data>(path(["data", key]))
  ]);
};
