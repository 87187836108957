import { useSelector } from "react-redux";

import { SummaryItem } from "api/types";

import {
  createSummaryItemData,
  pickWs,
  SUMMARY_SUB_SWITCH,
  SUMMARY_SWITCH
} from "pages/Reports/utils";
import { NUMBER_TYPE } from "pages/Reports/utils/formatNumber";
import {
  uppercasedMultipackSummaryTabSelector,
  uppercasedSummarySubTypeSelector
} from "store/selectors/routerSelectors";
import { REPORTS_SUMMARY_LABELS } from "utils";
import { Nullable } from "utils/types";

import { useGetMultipackByPeriod } from "./useGetMultipackByPeriod";

const pickValueByType = (
  type: string,
  values: {
    ws: Nullable<number>;
    wsWsa: Nullable<number>;
    wso: Nullable<number>;
    wsa: Nullable<number>;
    total: Nullable<number>;
  }
) => {
  switch (type) {
    case SUMMARY_SWITCH.MULTIPACK:
      return pickWs(values.ws, values.wsWsa);
    case SUMMARY_SWITCH.MULTIPACK_WSO:
      return values.wso;
    case SUMMARY_SWITCH.MULTIPACK_WSA:
      return values.wsa;
    case SUMMARY_SWITCH.MULTIPACK_TOTAL:
      return values.total;
    default:
      return null;
  }
};

export const useMultipackSummary = (): SummaryItem[] => {
  const subType = useSelector(uppercasedSummarySubTypeSelector);
  const type = useSelector(uppercasedMultipackSummaryTabSelector);
  const data = useGetMultipackByPeriod();

  const isReferenceType = [
    SUMMARY_SUB_SWITCH.SELL_REF_CATEGORY,
    SUMMARY_SUB_SWITCH.SHARES_REF_CATEGORY,
    SUMMARY_SUB_SWITCH.NATURAL_UNITS_REF_CATEGORY
  ].includes(subType);
  const isSell = [
    SUMMARY_SUB_SWITCH.SELL_OWN,
    SUMMARY_SUB_SWITCH.SELL_REF_CATEGORY
  ].includes(subType);
  const isShares = [
    SUMMARY_SUB_SWITCH.SHARES_OWN,
    SUMMARY_SUB_SWITCH.SHARES_REF_CATEGORY
  ].includes(subType);
  const isNaturalUnits = [
    SUMMARY_SUB_SWITCH.NATURAL_UNITS_OWN,
    SUMMARY_SUB_SWITCH.NATURAL_UNITS_REF_CATEGORY
  ].includes(subType);

  const summary = isReferenceType
    ? data.data?.data.referenceSummary
    : data.data?.data.ownSummary;

  if (!summary) {
    return [];
  }

  if (isSell) {
    return createSummaryItemData([
      {
        label: REPORTS_SUMMARY_LABELS.WORTH_SUM,
        firstValue: pickValueByType(type, {
          ws: summary.worthSumWs,
          wsWsa: summary.worthSumWsWsa,
          wso: summary.worthSumWso,
          wsa: summary.worthSumWsa,
          total: summary.worthSumWsWsoWsa
        }),
        firstValueType: NUMBER_TYPE.GROUPED_PRICE,
        secondValue: pickValueByType(type, {
          ws: summary.worthSumWsChange,
          wsWsa: summary.worthSumWsWsaChange,
          wso: summary.worthSumWsoChange,
          wsa: summary.worthSumWsaChange,
          total: summary.worthSumWsWsoWsaChange
        }),
        secondValueType: NUMBER_TYPE.PERCENT,
        thirdValue: pickValueByType(type, {
          ws: summary.worthSumWsLastYear,
          wsWsa: summary.worthSumWsWsaLastYear,
          wso: summary.worthSumWsoLastYear,
          wsa: summary.worthSumWsaLastYear,
          total: summary.worthSumWsWsoWsaLastYear
        }),
        change: pickValueByType(type, {
          ws: summary.worthSumWsChange,
          wsWsa: summary.worthSumWsWsaChange,
          wso: summary.worthSumWsoChange,
          wsa: summary.worthSumWsaChange,
          total: summary.worthSumWsWsoWsaChange
        })
      },
      {
        label: "Wielosztuki",
        firstValue: pickValueByType(type, {
          ws: summary.quantitySumWs,
          wsWsa: summary.quantitySumWsWsa,
          wso: summary.quantitySumWso,
          wsa: summary.quantitySumWsa,
          total: summary.quantitySumWsWsoWsa
        }),
        firstValueType: NUMBER_TYPE.GROUPED_PRICE,
        secondValue: pickValueByType(type, {
          ws: summary.quantitySumWsChange,
          wsWsa: summary.quantitySumWsWsaChange,
          wso: summary.quantitySumWsoChange,
          wsa: summary.quantitySumWsaChange,
          total: summary.quantitySumWsWsoWsaChange
        }),
        secondValueType: NUMBER_TYPE.PERCENT,
        thirdValue: pickValueByType(type, {
          ws: summary.quantitySumWsLastYear,
          wsWsa: summary.quantitySumWsWsaLastYear,
          wso: summary.quantitySumWsoLastYear,
          wsa: summary.quantitySumWsaLastYear,
          total: summary.quantitySumWsWsoWsaLastYear
        }),
        change: pickValueByType(type, {
          ws: summary.quantitySumWsChange,
          wsWsa: summary.quantitySumWsWsaChange,
          wso: summary.quantitySumWsoChange,
          wsa: summary.quantitySumWsaChange,
          total: summary.quantitySumWsWsoWsaChange
        })
      },
      {
        label: REPORTS_SUMMARY_LABELS.AVERAGE_PRICE,
        firstValue: pickValueByType(type, {
          ws: summary.priceAvgWs,
          wsWsa: summary.priceAvgWsWsa,
          wso: summary.priceAvgWso,
          wsa: summary.priceAvgWsa,
          total: summary.priceAvgWsWsoWsa
        }),
        firstValueType: NUMBER_TYPE.GROUPED_PRICE,
        secondValue: pickValueByType(type, {
          ws: summary.priceAvgWsChange,
          wsWsa: summary.priceAvgWsWsaChange,
          wso: summary.priceAvgWsoChange,
          wsa: summary.priceAvgWsaChange,
          total: summary.priceAvgWsWsoWsaChange
        }),
        secondValueType: NUMBER_TYPE.PERCENT,
        thirdValue: pickValueByType(type, {
          ws: summary.priceAvgWsLastYear,
          wsWsa: summary.priceAvgWsWsaLastYear,
          wso: summary.priceAvgWsoLastYear,
          wsa: summary.priceAvgWsaLastYear,
          total: summary.priceAvgWsWsoWsaLastYear
        }),
        change: pickValueByType(type, {
          ws: summary.priceAvgWsChange,
          wsWsa: summary.priceAvgWsWsaChange,
          wso: summary.priceAvgWsoChange,
          wsa: summary.priceAvgWsaChange,
          total: summary.priceAvgWsWsoWsaChange
        })
      },
      {
        label: REPORTS_SUMMARY_LABELS.AVERAGE_WORTH,
        firstValue: pickValueByType(type, {
          ws: summary.worthAvgDailyWs,
          wsWsa: summary.worthAvgDailyWsWsa,
          wso: summary.worthAvgDailyWso,
          wsa: summary.worthAvgDailyWsa,
          total: summary.worthAvgDailyWsWsoWsa
        }),
        firstValueType: NUMBER_TYPE.GROUPED_PRICE,
        secondValue: pickValueByType(type, {
          ws: summary.worthAvgDailyWsChange,
          wsWsa: summary.worthAvgDailyWsWsaChange,
          wso: summary.worthAvgDailyWsoChange,
          wsa: summary.worthAvgDailyWsaChange,
          total: summary.worthAvgDailyWsWsoWsaChange
        }),
        secondValueType: NUMBER_TYPE.PERCENT,
        thirdValue: pickValueByType(type, {
          ws: summary.worthAvgDailyWsLastYear,
          wsWsa: summary.worthAvgDailyWsWsaLastYear,
          wso: summary.worthAvgDailyWsoLastYear,
          wsa: summary.worthAvgDailyWsaLastYear,
          total: summary.worthAvgDailyWsWsoWsaLastYear
        }),
        change: pickValueByType(type, {
          ws: summary.worthAvgDailyWsChange,
          wsWsa: summary.worthAvgDailyWsWsaChange,
          wso: summary.worthAvgDailyWsoChange,
          wsa: summary.worthAvgDailyWsaChange,
          total: summary.worthAvgDailyWsWsoWsaChange
        })
      },
      {
        label: "Wartość per sklep (brutto)",
        firstValue: pickValueByType(type, {
          ws: summary.worthSumPerShopWs,
          wsWsa: summary.worthSumPerShopWsWsa,
          wso: summary.worthSumPerShopWso,
          wsa: summary.worthSumPerShopWsa,
          total: summary.worthSumPerShopWsWsoWsa
        }),
        firstValueType: NUMBER_TYPE.GROUPED_PRICE,
        secondValue: pickValueByType(type, {
          ws: summary.worthSumPerShopWsChange,
          wsWsa: summary.worthSumPerShopWsWsaChange,
          wso: summary.worthSumPerShopWsoChange,
          wsa: summary.worthSumPerShopWsaChange,
          total: summary.worthSumPerShopWsWsoWsaChange
        }),
        secondValueType: NUMBER_TYPE.PERCENT,
        thirdValue: pickValueByType(type, {
          ws: summary.worthSumPerShopWsLastYear,
          wsWsa: summary.worthSumPerShopWsWsaLastYear,
          wso: summary.worthSumPerShopWsoLastYear,
          wsa: summary.worthSumPerShopWsaLastYear,
          total: summary.worthSumPerShopWsWsoWsaLastYear
        }),
        change: pickValueByType(type, {
          ws: summary.worthSumPerShopWsChange,
          wsWsa: summary.worthSumPerShopWsWsaChange,
          wso: summary.worthSumPerShopWsoChange,
          wsa: summary.worthSumPerShopWsaChange,
          total: summary.worthSumPerShopWsWsoWsaChange
        })
      },
      {
        label: "Sztuki per sklep",
        firstValue: pickValueByType(type, {
          ws: summary.quantitySumPerShopWs,
          wsWsa: summary.quantitySumPerShopWsWsa,
          wso: summary.quantitySumPerShopWso,
          wsa: summary.quantitySumPerShopWsa,
          total: summary.quantitySumPerShopWsWsoWsa
        }),
        firstValueType: NUMBER_TYPE.GROUPED_PRICE,
        secondValue: pickValueByType(type, {
          ws: summary.quantitySumPerShopWsChange,
          wsWsa: summary.quantitySumPerShopWsWsaChange,
          wso: summary.quantitySumPerShopWsoChange,
          wsa: summary.quantitySumPerShopWsaChange,
          total: summary.quantitySumPerShopWsWsoWsaChange
        }),
        secondValueType: NUMBER_TYPE.PERCENT,
        thirdValue: pickValueByType(type, {
          ws: summary.quantitySumPerShopWsLastYear,
          wsWsa: summary.quantitySumPerShopWsWsaLastYear,
          wso: summary.quantitySumPerShopWsoLastYear,
          wsa: summary.quantitySumPerShopWsaLastYear,
          total: summary.quantitySumPerShopWsWsoWsaLastYear
        }),
        change: pickValueByType(type, {
          ws: summary.quantitySumPerShopWsChange,
          wsWsa: summary.quantitySumPerShopWsWsaChange,
          wso: summary.quantitySumPerShopWsoChange,
          wsa: summary.quantitySumPerShopWsaChange,
          total: summary.quantitySumPerShopWsWsoWsaChange
        })
      }
    ]);
  }

  if (isShares) {
    return createSummaryItemData([
      {
        label: "Udział wartościowy\nw sprzedaży total",
        firstValue: pickValueByType(type, {
          ws: summary.worthShareTotalWs,
          wsWsa: summary.worthShareTotalWsWsa,
          wso: summary.worthShareTotalWso,
          wsa: summary.worthShareTotalWsa,
          total: summary.worthShareTotalWsWsoWsa
        }),
        firstValueType: NUMBER_TYPE.PERCENT,
        secondValue: pickValueByType(type, {
          ws: summary.worthShareTotalWsChange,
          wsWsa: summary.worthShareTotalWsWsaChange,
          wso: summary.worthShareTotalWsoChange,
          wsa: summary.worthShareTotalWsaChange,
          total: summary.worthShareTotalWsWsoWsaChange
        }),
        secondValueType: NUMBER_TYPE.PERCENT_POINTS,
        thirdValue: pickValueByType(type, {
          ws: summary.worthShareTotalWsLastYear,
          wsWsa: summary.worthShareTotalWsWsaLastYear,
          wso: summary.worthShareTotalWsoLastYear,
          wsa: summary.worthShareTotalWsaLastYear,
          total: summary.worthShareTotalWsWsoWsaLastYear
        }),
        change: pickValueByType(type, {
          ws: summary.worthShareTotalWsChange,
          wsWsa: summary.worthShareTotalWsWsaChange,
          wso: summary.worthShareTotalWsoChange,
          wsa: summary.worthShareTotalWsaChange,
          total: summary.worthShareTotalWsWsoWsaChange
        })
      },
      {
        label: "Udział ilościowy\nw sprzedaży total",
        firstValue: pickValueByType(type, {
          ws: summary.quantityShareTotalWs,
          wsWsa: summary.quantityShareTotalWsWsa,
          wso: summary.quantityShareTotalWso,
          wsa: summary.quantityShareTotalWsa,
          total: summary.quantityShareTotalWsWsoWsa
        }),
        firstValueType: NUMBER_TYPE.PERCENT,
        secondValue: pickValueByType(type, {
          ws: summary.quantityShareTotalWsChange,
          wsWsa: summary.quantityShareTotalWsWsaChange,
          wso: summary.quantityShareTotalWsoChange,
          wsa: summary.quantityShareTotalWsaChange,
          total: summary.quantityShareTotalWsWsoWsaChange
        }),
        secondValueType: NUMBER_TYPE.PERCENT_POINTS,
        thirdValue: pickValueByType(type, {
          ws: summary.quantityShareTotalWsLastYear,
          wsWsa: summary.quantityShareTotalWsWsaLastYear,
          wso: summary.quantityShareTotalWsoLastYear,
          wsa: summary.quantityShareTotalWsaLastYear,
          total: summary.quantityShareTotalWsWsoWsaLastYear
        }),
        change: pickValueByType(type, {
          ws: summary.quantityShareTotalWsChange,
          wsWsa: summary.quantityShareTotalWsWsaChange,
          wso: summary.quantityShareTotalWsoChange,
          wsa: summary.quantityShareTotalWsaChange,
          total: summary.quantityShareTotalWsWsoWsaChange
        })
      },
      {
        label: "Udział ilościowy w jn",
        firstValue: pickValueByType(type, {
          ws: summary.netQuantityShareTotalWs,
          wsWsa: summary.netQuantityShareTotalWsWsa,
          wso: summary.netQuantityShareTotalWso,
          wsa: summary.netQuantityShareTotalWsa,
          total: summary.netQuantityShareTotalWsWsoWsa
        }),
        firstValueType: NUMBER_TYPE.PERCENT,
        secondValue: pickValueByType(type, {
          ws: summary.netQuantityShareTotalWsChange,
          wsWsa: summary.netQuantityShareTotalWsWsaChange,
          wso: summary.netQuantityShareTotalWsoChange,
          wsa: summary.netQuantityShareTotalWsaChange,
          total: summary.netQuantityShareTotalWsWsoWsaChange
        }),
        secondValueType: NUMBER_TYPE.PERCENT_POINTS,
        thirdValue: pickValueByType(type, {
          ws: summary.netQuantityShareTotalWsLastYear,
          wsWsa: summary.netQuantityShareTotalWsWsaLastYear,
          wso: summary.netQuantityShareTotalWsoLastYear,
          wsa: summary.netQuantityShareTotalWsaLastYear,
          total: summary.netQuantityShareTotalWsWsoWsaLastYear
        }),
        change: pickValueByType(type, {
          ws: summary.netQuantityShareTotalWsChange,
          wsWsa: summary.netQuantityShareTotalWsWsaChange,
          wso: summary.netQuantityShareTotalWsoChange,
          wsa: summary.netQuantityShareTotalWsaChange,
          total: summary.netQuantityShareTotalWsWsoWsaChange
        })
      }
    ]);
  }

  if (isNaturalUnits) {
    return createSummaryItemData([
      {
        label: REPORTS_SUMMARY_LABELS.NET_QUANTITY_SALES_SUM,
        firstValue: pickValueByType(type, {
          ws: summary.netQuantitySumWs,
          wsWsa: summary.netQuantitySumWsWsa,
          wso: summary.netQuantitySumWso,
          wsa: summary.netQuantitySumWsa,
          total: summary.netQuantitySumWsWsoWsa
        }),
        firstValueType: NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
        secondValue: pickValueByType(type, {
          ws: summary.netQuantitySumWsChange,
          wsWsa: summary.netQuantitySumWsWsaChange,
          wso: summary.netQuantitySumWsoChange,
          wsa: summary.netQuantitySumWsaChange,
          total: summary.netQuantitySumWsWsoWsaChange
        }),
        secondValueType: NUMBER_TYPE.PERCENT,
        thirdValue: pickValueByType(type, {
          ws: summary.netQuantitySumWsLastYear,
          wsWsa: summary.netQuantitySumWsWsaLastYear,
          wso: summary.netQuantitySumWsoLastYear,
          wsa: summary.netQuantitySumWsaLastYear,
          total: summary.netQuantitySumWsWsoWsaLastYear
        }),
        change: pickValueByType(type, {
          ws: summary.netQuantitySumWsChange,
          wsWsa: summary.netQuantitySumWsWsaChange,
          wso: summary.netQuantitySumWsoChange,
          wsa: summary.netQuantitySumWsaChange,
          total: summary.netQuantitySumWsWsoWsaChange
        })
      },
      {
        label: REPORTS_SUMMARY_LABELS.AVG_DAILY_NET_QUANTITY_PER_SHOP,
        firstValue: pickValueByType(type, {
          ws: summary.avgNetQuantityPerShopWs,
          wsWsa: summary.avgNetQuantityPerShopWsWsa,
          wso: summary.avgNetQuantityPerShopWso,
          wsa: summary.avgNetQuantityPerShopWsa,
          total: summary.avgNetQuantityPerShopWsWsoWsa
        }),
        firstValueType: NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
        secondValue: pickValueByType(type, {
          ws: summary.avgNetQuantityPerShopWsChange,
          wsWsa: summary.avgNetQuantityPerShopWsWsaChange,
          wso: summary.avgNetQuantityPerShopWsoChange,
          wsa: summary.avgNetQuantityPerShopWsaChange,
          total: summary.avgNetQuantityPerShopWsWsoWsaChange
        }),
        secondValueType: NUMBER_TYPE.PERCENT,
        thirdValue: pickValueByType(type, {
          ws: summary.avgNetQuantityPerShopWsLastYear,
          wsWsa: summary.avgNetQuantityPerShopWsWsaLastYear,
          wso: summary.avgNetQuantityPerShopWsoLastYear,
          wsa: summary.avgNetQuantityPerShopWsaLastYear,
          total: summary.avgNetQuantityPerShopWsWsoWsaLastYear
        }),
        change: pickValueByType(type, {
          ws: summary.avgNetQuantityPerShopWsChange,
          wsWsa: summary.avgNetQuantityPerShopWsWsaChange,
          wso: summary.avgNetQuantityPerShopWsoChange,
          wsa: summary.avgNetQuantityPerShopWsaChange,
          total: summary.avgNetQuantityPerShopWsWsoWsaChange
        })
      }
    ]);
  }

  return [];
};
