import { useSelector } from "react-redux";

import { EMPTY_CHART } from "api/consts";
import { useGetSegmentsByPeriod } from "api/segments/mappers/useGetSegmentsByPeriod";
import { getName } from "api/segments/utils/getName";
import { ChartDataOptions } from "api/sells/mappers/useSellsChartData";
import { getDataTypeKey } from "api/utils";
import { useChartLabels } from "api/utils/useChartLabels";
import { max, min } from "d3";
import { omit } from "ramda";

import { ChartData, ChartDataPart } from "components/D3/types";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { useDataTypeSuffix } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import {
  hiddenChartsSelector,
  hiddenLinesSelector,
  updateHoveredTimepoint,
  updatePinnedTimepoint
} from "pages/Reports/redux/reducers/chartReducer";
import { useGetZappkaSharesSuffix } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import { getSegmentsColor } from "pages/Reports/sections/Segments/utils";
import {
  findDateDomain,
  findLastYearDomainLength
} from "pages/Reports/sections/utils/chartData";
import { getChartItemColor } from "pages/Reports/sections/utils/color";
import { chartRange, valueByDataType } from "pages/Reports/utils";
import {
  chartPeriodSelector,
  firstChartDataTypeSelector,
  isGroupedBySegmentsSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import {
  mapLastYearTimelineByType,
  mapTimelineByType
} from "store/utils/chartUtils";
import { CHART_DATA_TYPE, DATASET_TYPES, PERIOD_TYPE } from "utils";
import { isSameScaleMetrics } from "utils/isSameScaleMetrics";
import { Nullable } from "utils/types";

interface ReturnValue {
  name: string;
  charts: ChartData & { fakeDomain: string[] };
}

export const useSegmentsChartData = ({
  isForHistoricalChart = false
}: ChartDataOptions): ReturnValue[] => {
  const period = useSelector(chartPeriodSelector);
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const hiddenLines = useSelector(hiddenLinesSelector);
  const hiddenCharts = useSelector(hiddenChartsSelector);
  const isGroupedBySegments = useSelector(isGroupedBySegmentsSelector);
  const dataset = useCurrentDataset();
  const labels = useChartLabels();
  const { isGroupedBarChart, isIncrementalComboChart } = useChartTypeBoolean();
  const [suffix1, suffix2] = useDataTypeSuffix();
  const getZappkaSharesSuffix = useGetZappkaSharesSuffix();

  const {
    data: segments,
    isLoading: isSegmentLoading,
    isIdle: isSegmentIdle
  } = useGetSegmentsByPeriod();
  const isFetching = isSegmentIdle || isSegmentLoading;

  if (!segments || !segments.currentPeriod.length) {
    return [
      {
        name: "",
        charts: {
          ...EMPTY_CHART,
          fakeDomain: [],
          leftChart: {
            chart: [],
            dataType: firstChartType,
            maxValue: 1,
            minValue: 0
          }
        }
      }
    ];
  }

  let minValue = 0;
  let maxValue = 0;
  const isTotal = period.toUpperCase() === PERIOD_TYPE.TOTAL;
  const timeline = segments.currentPeriod[0]?.segments[0]?.timeline;
  const isBarChart = timeline?.length <= 1;
  const fakeDomain = timeline.map(({ date }) => date);
  const lastYearDomainLength = isTotal ? 1 : findLastYearDomainLength(timeline);

  const dataTypes = [firstChartType, secondChartType];
  const dataTypeKeys = dataTypes.map((dataType, index) => {
    const sharesSuffix = getZappkaSharesSuffix(firstChartType ? index : 1);
    const primaryKey = getDataTypeKey({
      dataType,
      suffix: suffix1,
      sharesSuffix
    });
    const secondaryKey = getDataTypeKey({
      dataType,
      suffix: suffix2,
      sharesSuffix,
      isSecondary: true
    });
    return { primaryKey, secondaryKey };
  });

  const formattedCharts = segments.currentPeriod
    .map(segment => {
      const chartData = dataTypes.map((dataType, index) => {
        const dataWithColor = segment.segments.map((data, index) => {
          const [resultId, vendorId] = data.label.split(" - ");

          return {
            ...data,
            color: isGroupedBySegments
              ? getChartItemColor({
                  dataType,
                  vendorId,
                  resultId,
                  index
                })
              : getSegmentsColor(data.shortLabel, dataType)
          };
        });
        const { primaryKey, secondaryKey } = dataTypeKeys[index];
        const hasSecondaryTimeline = {
          base: Boolean(secondaryKey),
          historical: Boolean(secondaryKey) && dataset.both
        };

        const isRightChartData = index === 1;

        const allLines = dataWithColor.map(segments => {
          const showLabel = isBarChart && !isGroupedBySegments;

          return {
            ...omit(["timeline"], segments),
            // primary timeline
            timeline: segments.timeline.map(data => ({
              valueX: showLabel
                ? segments.shortLabel
                : dataset.historical
                ? data.dateLastYear || ""
                : data.date,
              valueY: valueByDataType(
                dataset.historical ? `${primaryKey}_LAST_YEAR` : primaryKey,
                data
              )
            })),
            // secondary timeline
            ...(hasSecondaryTimeline.base && {
              secondaryTimeline: segments.timeline.map(data => ({
                valueX: showLabel
                  ? segments.shortLabel
                  : dataset.historical
                  ? data.dateLastYear || ""
                  : data.date,
                valueY: valueByDataType(
                  dataset.historical
                    ? `${secondaryKey}_LAST_YEAR`
                    : secondaryKey,
                  data
                )
              }))
            }),
            // primary historical timeline
            ...(dataset.both && {
              lastYearTimeline: segments.timeline.map(data => ({
                valueX: (() => {
                  if (isBarChart) {
                    return isGroupedBySegments
                      ? data.date
                      : segments.shortLabel;
                  }
                  return isGroupedBarChart && !isRightChartData
                    ? data.dateLastYear || ""
                    : data.date;
                })(),
                valueY: valueByDataType(
                  primaryKey === "" ? "" : `${primaryKey}_LAST_YEAR`,
                  data
                )
              }))
            }),
            // secondary historical timeline
            ...(hasSecondaryTimeline.historical && {
              secondaryLastYearTimeline: segments.timeline.map(data => ({
                valueX: (() => {
                  if (isBarChart) {
                    return isGroupedBySegments
                      ? data.date
                      : segments.shortLabel;
                  }
                  return isGroupedBarChart && !isRightChartData
                    ? data.dateLastYear || ""
                    : data.date;
                })(),
                valueY: valueByDataType(
                  secondaryKey === "" ? "" : `${secondaryKey}_LAST_YEAR`,
                  data
                )
              }))
            }),
            type: DATASET_TYPES.OWN
          };
        });

        const visibleLines = allLines.filter(
          timeline => !hiddenLines.segments.includes(timeline.label)
        );

        let range = chartRange(visibleLines, {
          isRightChartData,
          isGroupedBarChart,
          isIncrementalComboChart
        });

        minValue = Math.min(range.minValue, minValue);
        maxValue = Math.max(range.maxValue, maxValue);

        return {
          dataType,
          visibleLines,
          allLines,
          currentChartMaxValue: range.maxValue,
          ...range
        };
      });

      const timeline = segments.currentPeriod[0]?.segments[0]?.timeline || [];

      const domain: string[] =
        isBarChart && !isGroupedBySegments
          ? chartData[0]?.visibleLines
              .filter(line => !hiddenLines.segments.includes(line.shortLabel))
              .map(line => line.shortLabel) || []
          : findDateDomain(
              timeline,
              isForHistoricalChart || dataset.historical
            ) || [];

      const isSameScale = isSameScaleMetrics(
        dataTypeKeys.map(dataType => dataType.primaryKey)
      );
      const overallMaxValue = isSameScale
        ? Math.max(...chartData.map(data => data.maxValue))
        : 0;

      const filtered = chartData.map(chart => ({
        dataType: chart.dataType,
        minValue: chart.minValue,
        maxValue: overallMaxValue || chart.maxValue,
        currentChartMaxValue: chart.currentChartMaxValue,
        chart: chart.visibleLines.map(chart => ({
          label: chart.label,
          id: chart.label,
          color: chart.color,
          timeline: dataset.historical
            ? mapLastYearTimelineByType(
                chart.type,
                chart.timeline,
                lastYearDomainLength
              )
            : mapTimelineByType(chart.type, chart.timeline),
          ...(chart.secondaryTimeline && {
            secondaryTimeline: dataset.historical
              ? mapLastYearTimelineByType(
                  chart.type,
                  chart.secondaryTimeline,
                  lastYearDomainLength
                )
              : mapTimelineByType(chart.type, chart.secondaryTimeline)
          }),
          ...(chart.lastYearTimeline && {
            lastYearTimeline: mapLastYearTimelineByType(
              chart.type,
              chart.lastYearTimeline,
              lastYearDomainLength
            )
          }),
          ...(chart.secondaryLastYearTimeline && {
            secondaryLastYearTimeline: mapLastYearTimelineByType(
              chart.type,
              chart.secondaryLastYearTimeline,
              lastYearDomainLength
            )
          })
        }))
      }));

      const { left, right } = { left: filtered[0], right: filtered[1] };

      const firstChart = (left as unknown) as Nullable<ChartDataPart>;
      let secondChart = (right as unknown) as Nullable<ChartDataPart>;
      if (isBarChart && !isGroupedBySegments) {
        secondChart = secondChart
          ? {
              dataType: secondChart.dataType,
              minValue: secondChart.minValue,
              maxValue: secondChart.maxValue,
              chart:
                secondChart.chart.map(chart => ({
                  ...chart,
                  timeline:
                    secondChart?.chart.map(chart => chart.timeline[0]) || [],
                  ...(chart.lastYearTimeline && {
                    lastYearTimeline:
                      secondChart?.chart.map(
                        // @ts-ignore
                        chart => chart.lastYearTimeline[0]
                      ) || []
                  })
                })) || []
            }
          : null;
      }
      const name = isGroupedBySegments
        ? segment.vendorName
        : getName(segment, isForHistoricalChart);

      return {
        name,
        charts: {
          leftChart: firstChart
            ? firstChart
            : isGroupedBySegments
            ? null
            : {
                minValue: 0,
                maxValue: 1,
                chart: [],
                dataType: CHART_DATA_TYPE.NONE
              },
          rightChart: secondChart,
          domain,
          fakeDomain,
          period,
          labels,
          isFetching,
          showBarChart: isBarChart,
          onTimelineHover: updateHoveredTimepoint,
          isHistoricalGroupedChart: isForHistoricalChart
        }
      };
    })
    .filter(chart => !hiddenCharts.includes(chart.name))
    .sort(
      (a, b) =>
        (b.charts.leftChart?.currentChartMaxValue || 0) -
        (a.charts.leftChart?.currentChartMaxValue || 0)
    );

  const minLeftChartValue =
    min(formattedCharts.map(chart => chart.charts?.leftChart?.minValue || 0)) ||
    0;
  const maxLeftChartValue =
    max(formattedCharts.map(chart => chart.charts?.leftChart?.maxValue || 1)) ||
    1;

  const minRightChartValue =
    min(
      formattedCharts.map(chart => chart?.charts?.rightChart?.minValue || 0)
    ) || 0;
  const maxRightChartValue =
    max(
      formattedCharts.map(chart => chart?.charts?.rightChart?.maxValue || 1)
    ) || 1;

  const chartsWithSameRange = formattedCharts.map(item => {
    const itemRightChart = item?.charts?.rightChart;
    let rightChart = null;

    if (itemRightChart?.chart) {
      rightChart = {
        ...itemRightChart,
        minValue: minRightChartValue,
        maxValue: maxRightChartValue,
        chart:
          itemRightChart.dataType === CHART_DATA_TYPE.NONE
            ? []
            : isBarChart && !isGroupedBySegments
            ? itemRightChart.chart[0]
              ? [itemRightChart.chart[0]]
              : []
            : itemRightChart.chart
      };
    }

    return {
      ...item,
      charts: {
        ...item.charts,
        leftChart: item?.charts?.leftChart?.chart
          ? {
              ...item.charts.leftChart,
              minValue: minLeftChartValue,
              maxValue: maxLeftChartValue,
              chart:
                item.charts.leftChart.dataType === CHART_DATA_TYPE.NONE
                  ? []
                  : item.charts.leftChart.chart
            }
          : null,
        rightChart,
        onTimelineClick: updatePinnedTimepoint,
        isHistoricalGroupedChart: isForHistoricalChart
      }
    };
  });

  return chartsWithSameRange;
};
