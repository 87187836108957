import { Action, createHook, createStore } from "react-sweet-state";

import { DropdownItem } from "components/molecules/types";
import { translateTimeAggregation } from "pages/Reports/sections/Export/utils/translateParams";
import { PERIOD_TYPE } from "utils";
import { Values } from "utils/types";

export type ChartPeriodItem = DropdownItem<string>;

type State = { all: ChartPeriodItem[]; selected: ChartPeriodItem };
type Actions = typeof actions;

export const createPeriodItem = (period: Values<typeof PERIOD_TYPE>) => ({
  label: translateTimeAggregation(period),
  value: period.toLowerCase()
});

const actions = {
  updateAllChartPeriods: (
    availableChartPeriods: ChartPeriodItem[]
  ): Action<State> => ({ setState }) => {
    setState({ all: availableChartPeriods });
  },
  updateSelectedChartPeriod: (selected: ChartPeriodItem): Action<State> => ({
    setState
  }) => {
    setState({ selected });
  },
  setPristineChartPeriod: (
    chartPeriodQueryParam: string,
    periods: Values<typeof PERIOD_TYPE>[]
  ): Action<State> => ({ setState }) => {
    if (!chartPeriodQueryParam) return;

    const periodItems = periods.map(createPeriodItem);

    const selectedPeriod =
      periodItems.find(
        period => period.value === chartPeriodQueryParam.toLowerCase()
      ) || periodItems[0];

    if (!selectedPeriod) return;

    setState({ all: periodItems, selected: selectedPeriod });
  },
  clearChartPeriodFilter: (): Action<State> => ({ setState, getState }) => {
    const state = getState();
    const lowestPeriod = [...state.all].pop() || { label: "", value: "" };

    setState({ selected: lowestPeriod });
  }
};

// THIS HOOK REFERS TO CHART_PERIOD SIDEBAR FILTER
export const ChartPeriodStore = createStore<State, Actions>({
  name: "chartPeriod",
  initialState: {
    all: [],
    selected: { label: "", value: "" }
  },
  actions
});

export const ChartPeriodHooks = {
  useChartPeriod: createHook(ChartPeriodStore)
};
