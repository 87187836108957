import { useSelector } from "react-redux";

import { VendorHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { userSelector } from "store/reducers/userReducer";
import { vendorIdQueryParamSelector } from "store/selectors/routerSelectors";

export const useActiveCompanyName = () => {
  const user = useSelector(userSelector);
  const vendorQP = useSelector(vendorIdQueryParamSelector);
  const [{ all: vendors }] = VendorHooks.useVendor();

  if (user.company?.name) {
    return user.company.name;
  }

  const activeVendor = vendors.find(vendor => vendor.value === vendorQP);
  if (activeVendor) {
    return activeVendor.label;
  }

  return "";
};
