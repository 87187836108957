import { useDispatch, useSelector } from "react-redux";

import { RowData, TooltipContent } from "api/types";
import cn from "classnames";

import { Text } from "components/atoms";
import { SidebarTableHeader, StaticTable } from "components/molecules";
import { isRestrictedToOneLineSelector } from "components/molecules/ChartTypeToggle/hooks";
import { useAllLegendLineNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendLineNames";
import {
  hoveredLineSelector,
  toggleChart,
  updateHoveredLine
} from "pages/Reports/redux/reducers/chartReducer";
import { setGenericAlertMessage } from "store/actions/appActions";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { getPolishLabelForSidebar, TEXT_WRAPPERS } from "utils";
import { NO_COMPETITION_DATA_COPYING_ALLOWED } from "utils/constants";
import { checkChangeColumn } from "utils/polishLabels/getPolishLabelForSidebar";
import { Nullable } from "utils/types";

import { useSidebarToggleElements } from "./hooks/useSidebarToggleElements";
import s from "./sidebarData.module.scss";
import { SidebarDataCell } from "./TableCells/SidebarDataCell";

type Props = {
  sidebarData: Nullable<TooltipContent>;
};

const isLastInGroup = (keys: string[], index: number) => {
  const last = index === keys.length - 1;
  if (last) return false;
  const current = keys[index];
  const next = keys[index + 1];
  return ![current, current.concat("_CHANGE")].includes(next);
};

export const SidebarData = ({ sidebarData }: Props) => {
  const dispatch = useDispatch();
  const isRestrictedToOneLine = useSelector(isRestrictedToOneLineSelector);
  const legendLineNames = useAllLegendLineNames();
  const isPowerUser = useSelector(isPowerUserSelector);
  const hoveredLine = useSelector(hoveredLineSelector);

  useSidebarToggleElements(sidebarData);

  if (sidebarData === null)
    return (
      <Text Wrapper={TEXT_WRAPPERS.SPAN} className={s.noData}>
        Brak danych
      </Text>
    );

  const { data } = sidebarData;

  if (data.length === 0) return null;

  const keys = Object.keys(data[0]);

  const columns = keys
    .map((key, index) => {
      return {
        Header: getPolishLabelForSidebar(key),
        accessor: key,
        className: cn({
          [s.stickyFirstColumn]: index === 0,
          [s.columnDividerOnRight]: isLastInGroup(keys, index)
        }),
        headerClassName: cn({
          [s.stickyFirstColumn]: index === 0,
          [s.stickyFirstColumnHeader]: index === 0,
          [s.stickyHeaderRow]: index !== 0,
          [s.columnDividerOnRight]: isLastInGroup(keys, index),
          [s.noLeftPadding]: checkChangeColumn(key)
        }),
        id: key
      };
    })
    .filter(col => col.Header !== "");

  const toggleChartDispatch = (labels: string[]) =>
    dispatch(toggleChart(labels));

  const updateHoveredLineDispatch = (lines: string[]) =>
    dispatch(updateHoveredLine(lines));

  const setGenericAlertMessageDispatch = () =>
    dispatch(setGenericAlertMessage(NO_COMPETITION_DATA_COPYING_ALLOWED));

  return (
    <StaticTable<RowData>
      disableFixedColumns
      disableMultiSort
      data={data}
      columns={columns}
      renderHeader={column => <SidebarTableHeader<RowData> column={column} />}
      renderCell={cell => (
        <SidebarDataCell
          cell={cell}
          toggleChartDispatch={toggleChartDispatch}
          updateHoveredLineDispatch={updateHoveredLineDispatch}
          isRestrictedToOneLine={isRestrictedToOneLine}
          legendLineNames={legendLineNames}
          setGenericAlertMessageDispatch={setGenericAlertMessageDispatch}
          isPowerUser={isPowerUser}
          hoveredLine={hoveredLine}
        />
      )}
      className={{
        table: s.table,
        tableHeaderRow: s.tableHeaderRow,
        tableHeader: s.tableHeader,
        tableCell: s.tableCell
      }}
    />
  );
};
