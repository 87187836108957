import * as React from "react";

import cn from "classnames";

import { Text } from "components/atoms";
import { ArrowDown, ArrowUp } from "components/atoms/Icon";
import { ICON_SIZES, TEXT_TYPES } from "utils";
import { Nullable } from "utils/types";

import s from "./SummaryTrend.module.scss";
interface ArrowProps {
  change: Nullable<number>;
}

const Arrow = ({ change }: ArrowProps) => {
  if (!change) return null;

  return (
    <>
      {change > 0 && (
        <ArrowUp className={s.arrowIncrease} size={ICON_SIZES.EXTRA_SMALL} />
      )}
      {change < 0 && (
        <ArrowDown className={s.arrowDecrease} size={ICON_SIZES.EXTRA_SMALL} />
      )}
      {change === 0 && (
        <ArrowDown className={s.arrowNeutral} size={ICON_SIZES.EXTRA_SMALL} />
      )}
    </>
  );
};
interface Props {
  leftValue: Nullable<string>;
  rightValue: Nullable<string>;
  leftValueBottom: Nullable<string>;
  rightValueBottom: Nullable<string>;
  change: number;
  changeBottom: Nullable<number>;
  className?: string;
  outputFormat?: string;
}

export const SummaryTrend = ({
  leftValue,
  rightValue,
  change,
  leftValueBottom,
  rightValueBottom,
  changeBottom,
  className
}: Props) => {
  const hasSecondRow = Boolean(
    leftValueBottom && rightValueBottom && changeBottom
  );

  return (
    <div className={cn(s.base, className)}>
      <Arrow change={change} />
      <Text
        type={TEXT_TYPES.LABEL}
        className={cn(s.paddingSmall, s.withSeparator)}
      >
        {leftValue}
      </Text>
      <Text type={TEXT_TYPES.LABEL}>{rightValue}</Text>

      {hasSecondRow && (
        <>
          <Arrow change={changeBottom} />
          <Text
            type={TEXT_TYPES.LABEL}
            className={cn(s.paddingSmall, s.withSeparator)}
          >
            {leftValueBottom}
          </Text>
          <Text type={TEXT_TYPES.LABEL}>{rightValueBottom}</Text>
        </>
      )}
    </div>
  );
};
