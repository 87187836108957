import { Action, createHook, createStore } from "react-sweet-state";

import { getSelectedCountLabel } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";

export type DropdownItem = {
  label: string;
  value: string;
};

type State = {
  all: DropdownItem[];
  selected: DropdownItem[];
};

type Actions = typeof actions;

const actions = {
  updateAll: (items: DropdownItem[]): Action<State> => ({ setState }) => {
    setState({ all: items });
  },
  updateSelected: (items: DropdownItem[]): Action<State> => ({ setState }) => {
    setState({ selected: items });
  }
};

const CompetingCompaniesStore = createStore<State, Actions>({
  name: "competingCompanies",
  initialState: {
    all: [],
    selected: []
  },
  actions
});

export const CompetingCompaniesHooks = {
  useCompetingCompanies: createHook(CompetingCompaniesStore),
  useSelectedCountLabel: createHook(CompetingCompaniesStore, {
    selector: state =>
      getSelectedCountLabel(state.selected.length, state.all.length)
  }),
  useSelectedIds: createHook(CompetingCompaniesStore, {
    selector: state => state.selected.map(item => item.value)
  })
};
