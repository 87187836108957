import { useDispatch } from "react-redux";

import { ChartData } from "components/D3/types";
import { updateQueryParams } from "store/actions/routerActions";
import { BOOL_STRING_VALUES } from "utils";
import { QP } from "utils/defaultQueryParams";
import { Values } from "utils/types";

import { useChartValueTiles } from "./useChartValueTiles";

const TILE_TYPE = {
  RIGHT: "right",
  HISTORICAL: "historical",
  SECONDARY: "secondary"
} as const;
const { RIGHT, HISTORICAL, SECONDARY } = TILE_TYPE;

const tileFactory = (types: Values<typeof TILE_TYPE>[]) => ({
  isRightChart: types.includes(TILE_TYPE.RIGHT),
  isHistorical: types.includes(TILE_TYPE.HISTORICAL),
  isSecondary: types.includes(TILE_TYPE.SECONDARY)
});

export const useTilesMultiselect = (lineChart: ChartData) => {
  const [, tileActions] = useChartValueTiles();
  const dispatch = useDispatch();

  const selectTiles = (type: "all" | "left" | "right") => {
    const chartPart = lineChart.leftChart?.chart.length
      ? lineChart.leftChart
      : lineChart.rightChart;

    const tilesToAdd = chartPart?.chart.flatMap(line => {
      return line.timeline.flatMap((timepoint, index) => {
        const base = { lineName: line.id || "", timepoint: index };

        if (type === "left") {
          return [
            { ...base, ...tileFactory([]) },
            { ...base, ...tileFactory([HISTORICAL]) },
            { ...base, ...tileFactory([SECONDARY]) },
            { ...base, ...tileFactory([HISTORICAL, SECONDARY]) }
          ];
        }

        if (type === "right") {
          return [
            { ...base, ...tileFactory([RIGHT]) },
            { ...base, ...tileFactory([RIGHT, HISTORICAL]) },
            { ...base, ...tileFactory([RIGHT, SECONDARY]) },
            { ...base, ...tileFactory([RIGHT, HISTORICAL, SECONDARY]) }
          ];
        }

        if (type === "all") {
          return [
            { ...base, ...tileFactory([]) },
            { ...base, ...tileFactory([RIGHT]) },
            { ...base, ...tileFactory([HISTORICAL]) },
            { ...base, ...tileFactory([SECONDARY]) },
            { ...base, ...tileFactory([RIGHT, HISTORICAL]) },
            { ...base, ...tileFactory([RIGHT, SECONDARY]) },
            { ...base, ...tileFactory([HISTORICAL, SECONDARY]) },
            { ...base, ...tileFactory([RIGHT, HISTORICAL, SECONDARY]) }
          ];
        }

        return [];
      });
    });

    if (!tilesToAdd) return;

    tileActions.clearTiles();
    tileActions.handleTiles(tilesToAdd, false);

    dispatch(
      updateQueryParams({
        [QP.HIDE_TILES]: BOOL_STRING_VALUES.FALSE
      })
    );
  };

  return { selectTiles };
};
