import { useRef } from "react";

import { ScaleBand } from "d3";

import { COLOR } from "utils/constants";
import { Nullable } from "utils/types";

import { HorizontalChart } from "./utils/getRankingData";

interface Props {
  transform: string;
  title: string;
  data: HorizontalChart[];
  tooltip: Nullable<HorizontalChart>;
  scaleY: ScaleBand<string>;
  height: number;
  width: number;
}

export const HorizontalChartSidebar = ({
  transform,
  title,
  data,
  tooltip,
  scaleY,
  height,
  width
}: Props) => {
  const titleRef = useRef<SVGTextElement>(null);
  const x = width / 2;

  return (
    <g transform={transform} data-testid="ranking-chart-sidebar">
      <line stroke="currentColor" color={COLOR.UI_17} y1="-25" y2={height} />
      <text
        ref={titleRef}
        x={x}
        y="-10"
        fontSize="15"
        fill={COLOR.UI_3}
        textAnchor="middle"
      >
        {title}
      </text>
      {data.map(({ id, color, sidebarLabel }, index) => {
        const isActive = tooltip?.id === id;
        const y = (scaleY(String(id)) || 0) + scaleY.bandwidth() / 2;

        return (
          <g
            key={`ranking-sidebar-${index}`}
            transform={`translate(${x}, ${y})`}
          >
            <text
              dy="0.32em"
              fontSize="12"
              fontWeight={isActive ? "600" : "400"}
              fill={isActive ? color : COLOR.UI_15}
              textAnchor="middle"
            >
              {sidebarLabel}
            </text>
          </g>
        );
      })}
    </g>
  );
};
