import { Dispatch, FocusEvent, SetStateAction } from "react";
import DatePicker from "react-datepicker";

import { isAfter, isValid } from "date-fns";
import pl from "date-fns/locale/pl";
import moment from "moment";

import { CalendarHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCalendar";
import { newDateByTimezone } from "store/utils";
import { Nullable } from "utils/types";

import "./monthRangePicker.scss";

const DATE_FORMAT = "yyyy.MM";
const PREVIOUS_YEAR_LABEL = "<";
const NEXT_YEAR_LABEL = ">";

type Props = {
  minDate: Date | null;
  maxDate: Date | null;
  setValidationError: Dispatch<SetStateAction<string>>;
  onChange: (start: Date, end: Date) => void;
};

export const MonthRangePicker = ({
  minDate,
  maxDate,
  setValidationError,
  onChange
}: Props) => {
  const [{ calendar }, actions] = CalendarHooks.useCalendar();
  const dates = {
    start: calendar.dateFrom?.toDate(),
    end: calendar.dateTo?.toDate()
  };

  const handleChange = (type: "start" | "end") => (value: Nullable<Date>) => {
    const date = value || newDateByTimezone();

    const newDates = {
      dateFrom:
        type === "start" ? moment(date).startOf("month") : calendar.dateFrom,
      dateTo: type === "end" ? moment(date).endOf("month") : calendar.dateTo
    };

    actions.updateCalendarDates(newDates);

    if (newDates.dateFrom && newDates.dateTo) {
      onChange(newDates.dateFrom.toDate(), newDates.dateTo.toDate());
    }
  };

  const handleChangeRaw = (event: FocusEvent<HTMLInputElement>) => {
    setValidationError("");

    const { value = "" } = event.target;
    // needs to replace dots with dashes due to Firefox date compatibility reasons
    const dateString = value.replace(/\./g, "-");
    // instead of newDateByTimezone() we need to use new Date() here to obtain "Invalid date"
    const date = new Date(dateString);

    if (!isValid(date) || !isAfter(date, maxDate!)) return;

    setValidationError("Możesz wybrać tylko pełne przeszłe miesiące");
  };

  const handleBlur = () => {
    setValidationError("");
  };

  return (
    <>
      <DatePicker
        wrapperClassName="month-range-picker-wrapper"
        popperClassName="month-range-picker-popper"
        className="month-range-picker-input"
        calendarClassName="month-range-picker"
        locale={pl}
        selected={dates.start}
        onChange={handleChange("start")}
        selectsStart
        startDate={dates.start}
        endDate={dates.end}
        minDate={minDate}
        maxDate={dates.end || maxDate}
        dateFormat={DATE_FORMAT}
        showMonthYearPicker
        popperPlacement="bottom-end"
        previousYearButtonLabel={PREVIOUS_YEAR_LABEL}
        nextYearButtonLabel={NEXT_YEAR_LABEL}
        onChangeRaw={handleChangeRaw}
        onBlur={handleBlur}
      />
      <DatePicker
        wrapperClassName="month-range-picker-wrapper"
        popperClassName="month-range-picker-popper"
        className="month-range-picker-input"
        calendarClassName="month-range-picker"
        locale={pl}
        selected={dates.end}
        onChange={handleChange("end")}
        selectsEnd
        startDate={dates.start}
        endDate={dates.end}
        dateFormat={DATE_FORMAT}
        showMonthYearPicker
        popperPlacement="bottom-start"
        previousYearButtonLabel={PREVIOUS_YEAR_LABEL}
        nextYearButtonLabel={NEXT_YEAR_LABEL}
        minDate={dates.start || minDate}
        maxDate={maxDate}
        onChangeRaw={handleChangeRaw}
        onBlur={handleBlur}
      />
    </>
  );
};
