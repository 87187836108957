import { Checkbox } from "components/molecules";
import { CompetingProductsHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCompetingProducts";
import { ProductHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

import s from "./unactiveProductsCheckbox.module.scss";

interface Props {
  isCompetition?: boolean;
}

export const UnactiveProductsCheckbox = ({ isCompetition = false }: Props) => {
  const [productsState, productsActions] = ProductHooks.useProduct();
  const [
    competingProductsState,
    competingProductsActions
  ] = CompetingProductsHooks.useCompetingProducts();

  const isChecked = isCompetition
    ? competingProductsState.itemsPlacement === "inactive-bottom"
    : productsState.unactiveProductsBottom;

  const onChange = () => {
    if (isCompetition) {
      competingProductsActions.updateItemsPlacement(
        competingProductsState.itemsPlacement === "regular"
          ? "inactive-bottom"
          : "regular"
      );
      competingProductsActions.sortItems();
      pushFiltersEvent("suppliers products - inactive down");
    } else {
      productsActions.toggleUnactiveProductsBottom();
      productsActions.sortProducts();
      pushFiltersEvent("own products - inactive down");
    }
  };

  const testId = isCompetition
    ? "toggle-unactive-competition-products-checkbox"
    : "toggle-unactive-products-checkbox";

  return (
    <Checkbox
      className={{
        base: s.unactiveProductsCheckbox,
        label: s.unactiveProductsLabel
      }}
      label="Przesuń produkty nieaktywne na dół"
      onChange={onChange}
      isChecked={isChecked}
      testId={testId}
    />
  );
};
