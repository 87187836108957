import React from "react";

import { CardContent, Text } from "components/atoms";
import { Logo } from "components/atoms/Icon";
import { Card } from "components/molecules";
import { APP_NAME, TEXT_WRAPPERS } from "utils";

import s from "./internetExplorerAlert.module.scss";

export const InternetExplorerAlert = () => {
  return (
    <div className={s.base}>
      <div className={s.cardWrapper}>
        <Card className={{ base: s.card }}>
          <CardContent className={s.cardContent}>
            <Logo className={s.logo} />
            <h2>
              <Text className={s.header}>{APP_NAME}</Text>
            </h2>
            <div className={s.textWrapper}>
              <Text className={s.regularText}>
                Wygląda na to, że używasz bardzo przestarzałej przeglądarki jaką
                jest Internet Explorer 11
              </Text>
              <Text className={s.regularText}>
                Zachęcamy do instalacji{" "}
                <Text Wrapper={TEXT_WRAPPERS.SPAN} className={s.underlineText}>
                  Chrome
                </Text>
                , Firefox, Microsoft Egde, Opery lub Brave żeby móc w pełni
                korzystać z naszego portalu
              </Text>
              <Text className={s.regularText}>
                Nie możesz zainstalować nowej przeglądarki? Skontaktuj się z
                firmowym działem IT / Helpdesk.
              </Text>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
