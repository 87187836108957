import { forwardRef, Fragment } from "react";
import { useDispatch } from "react-redux";

import { useLocationMapDetailedChartsData } from "api/location/mappers/useLocationMapDetailedChartsData";

import { Text } from "components/atoms";
import c from "components/common.module.scss";
import { useChartTopLabel } from "hooks/useChartTopLabel";
import { useGetScaledColorFn } from "pages/Reports/sections/Location/hooks/useGetScaledColorFn";
import {
  HoveredRegion,
  updateHoveredRegion
} from "pages/Reports/sections/Location/redux/reducers/locationChartReducer";
import { MapChartData } from "pages/Reports/sections/Location/redux/types/locationChartTypes";
import { getValidId, REGION_TYPES } from "utils";

import { Region } from "../Map/Region";
import { useCounties } from "./useCounties";
import s from "./voivodeshipDetailed.module.scss";

interface VoivodeshipMapProps {
  data: MapChartData;
  parentKey: string;
  onHover: (value: string) => void;
  onBlur: () => void;
}

const VoivodeshipMap = ({
  data,
  parentKey,
  onHover,
  onBlur
}: VoivodeshipMapProps) => {
  const counties = useCounties();
  const getScaledColor = useGetScaledColorFn();

  return (
    <svg viewBox="0 0 1033.33 1077.09" className={s.canvas}>
      {counties.map(({ id, path }) => (
        <Region
          onHover={() => onHover(id)}
          onBlur={onBlur}
          type={REGION_TYPES.COUNTY}
          id={id}
          key={`${parentKey}:${id}`}
          Component={forwardRef(
            ({ onMouseEnter, onMouseLeave, className }, ref) => {
              return (
                <>
                  {Array.isArray(path) ? (
                    <g
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      fill={getScaledColor(data[id])}
                      id={id}
                      // @ts-ignore
                      ref={ref}
                    >
                      {path.map(element => (
                        <path key={id} d={element} className={className} />
                      ))}
                    </g>
                  ) : (
                    <path
                      key={getValidId(id)}
                      d={path}
                      fill={getScaledColor(data[id])}
                      id={id}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      className={className}
                      // @ts-ignore
                      ref={ref}
                    />
                  )}
                </>
              );
            }
          )}
        />
      ))}
    </svg>
  );
};

export const VoivodeshipDetailed = () => {
  const dispatch = useDispatch();
  const chartData = useLocationMapDetailedChartsData();
  const { regularDateLabel, compareDateLabel } = useChartTopLabel({});

  const handleRegionHover = (region: HoveredRegion) => {
    dispatch(updateHoveredRegion(region));
  };

  const handleRegionBlur = () => {
    dispatch(updateHoveredRegion(null));
  };

  return (
    <>
      {chartData.map(({ label, data, lastYearData }, index) => (
        <Fragment key={`map-chart-detailed-${index}`}>
          <label className={s.label} data-testid="map-chart-detailed">
            <Text className={s.labelText}>{label}</Text>
            <span className={c.chartSectionDatesLabel}>{regularDateLabel}</span>
            <VoivodeshipMap
              data={data}
              parentKey={label}
              onHover={id => handleRegionHover({ id, chart: index })}
              onBlur={handleRegionBlur}
            />
          </label>
          {lastYearData ? (
            <label
              className={s.label}
              data-testid="map-chart-detailed-historical"
            >
              <Text className={s.labelText}>{label} - dane historyczne</Text>
              <span className={c.chartSectionDatesLabel}>
                {compareDateLabel}
              </span>
              <VoivodeshipMap
                data={lastYearData}
                parentKey={`${label}:historical`}
                onHover={id => handleRegionHover({ id, chart: index })}
                onBlur={handleRegionBlur}
              />
            </label>
          ) : null}
        </Fragment>
      ))}
    </>
  );
};
