import { getChartYKey } from "pages/Reports/redux/utils";
import { Nullable } from "utils/types";

export const valueByDataTypeBoolean = (
  primaryKey: string,
  dataObject: any,
  dataset: { base: boolean; historical: boolean; both: boolean },
  secondaryKey: string = ""
): Nullable<Boolean> => {
  const keys = [];
  if (dataset.base || dataset.both) {
    keys.push(getChartYKey(primaryKey));
    if (secondaryKey) keys.push(getChartYKey(secondaryKey));
  }
  if (dataset.historical || dataset.both) {
    keys.push(`${getChartYKey(primaryKey)}LastYear`);
    if (secondaryKey) keys.push(`${getChartYKey(secondaryKey)}LastYear`);
  }
  if (keys.every(key => key === null)) return null;
  return keys.some(key => dataObject[key!]);
};
