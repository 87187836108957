import React, { useState } from "react";

import { LogoView } from "components/molecules";
import { IS_PRELOGIN_INFO_HIDDEN } from "utils";

import { PreloginInfo } from "./PreloginInfo";
import { PreloginRedirect } from "./PreloginRedirect";

export const Prelogin = () => {
  const [isInfoDisplayed, displayInfo] = useState(
    localStorage.getItem(IS_PRELOGIN_INFO_HIDDEN) !== "true"
  );

  return (
    <LogoView>
      {isInfoDisplayed ? (
        <PreloginInfo onSubmit={displayInfo} />
      ) : (
        <PreloginRedirect />
      )}
    </LogoView>
  );
};
