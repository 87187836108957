import { useSelector } from "react-redux";

import { useOwnSharesTableData } from "api/shares/mappers";
import { flatten } from "ramda";

import { isDistributionDisabledSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import {
  SharesTableColumn,
  SharesTableRow
} from "pages/Reports/sections/Shares/types";
import { getRowPeriod } from "pages/Reports/utils";
import { generateTableColumnId } from "pages/Reports/utils/generateTableColumnId";
import { tierSelector } from "store/reducers/userReducer";
import { tablePeriodSelector } from "store/selectors/routerSelectors";
import { sortTypeByFloatValue, sortTypeByPeriod } from "utils";
import { getPolishLabelForTableTimePeriod } from "utils/polishLabels/getPolishLabelForTableTimePeriod";

export const useSharesTableColumns = () => {
  const period = useSelector(tablePeriodSelector);
  const isDistributionDisabled = useSelector(isDistributionDisabledSelector);
  const tier = useSelector(tierSelector);

  const preparedTableData = useOwnSharesTableData();

  const tablePeriodHeader = getPolishLabelForTableTimePeriod(period);

  const columns = flatten(
    preparedTableData.map(({ name }): SharesTableColumn[] => {
      const idPrefix = generateTableColumnId(name);
      const distributionColumns: SharesTableColumn[] = isDistributionDisabled
        ? []
        : [
            {
              Header: `${name} - liczba sklepów`,
              accessor: row => String(row[`${idPrefix}-avgShopsCount`]),
              id: `${idPrefix}-avgShopsCount`,
              sortType: sortTypeByFloatValue(`${idPrefix}-avgShopsCount`)
            },
            {
              Header: `${name} - dystrybucja %`,
              accessor: row => String(row[`${idPrefix}-distributionRange`]),
              id: `${idPrefix}-distributionRange`,
              sortType: sortTypeByFloatValue(`${idPrefix}-distributionRange`)
            }
          ];

      return [
        {
          Header: `${name} - udz. wart. (%)`,
          accessor: row => String(row[`${idPrefix}-worthShare`]),
          id: `${idPrefix}-worthShare`,
          sortType: sortTypeByFloatValue(`${idPrefix}-worthShare`)
        },
        {
          Header: `${name} - udz. ilość. (%)`,
          accessor: row => String(row[`${idPrefix}-quantityShare`]),
          id: `${idPrefix}-quantityShare`,
          sortType: sortTypeByFloatValue(`${idPrefix}-quantityShare`)
        },
        ...distributionColumns
      ];
    })
  );

  const promotionColumns: SharesTableColumn[] =
    tier < 3
      ? []
      : [
          {
            Header: "Promocje",
            accessor: row => row.promotions,
            id: "promotions",
            sortType: "alphanumeric"
          }
        ];

  return [
    {
      Header: tablePeriodHeader,
      accessor: (row: SharesTableRow) => getRowPeriod(period, row),
      id: "period",
      sortType: sortTypeByPeriod(period)
    },
    ...columns,
    ...promotionColumns
  ];
};
