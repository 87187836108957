import {
  ChartValueSingleTile,
  SingleTileData
} from "../ChartValueTiles/ChartValueSingleTile";

type Props = {
  tile: SingleTileData;
  barWidth: number;
  horizontalPosition: number;
  verticalPosition: number;
};

export const BarTile = ({
  tile,
  barWidth,
  horizontalPosition,
  verticalPosition
}: Props) => (
  <foreignObject
    x={horizontalPosition + barWidth / 2}
    y={verticalPosition}
    width={1}
    height={1}
    style={{ overflow: "visible" }}
  >
    <ChartValueSingleTile tile={tile} />
  </foreignObject>
);
