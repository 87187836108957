import { SetStateAction, useEffect } from "react";

import { Close } from "components/atoms/Icon";
import LinearProgressBar from "components/atoms/linearProgressBar/LinearProgressBar";
import { Card } from "components/molecules";
import { ICON_SIZES } from "utils";

import parentStyles from "../insightsModal.module.scss";
import ownStyles from "./ThanksModal.module.scss";

interface Props {
  onClose: (value: SetStateAction<boolean>) => void;
  shouldThanksShow: boolean;
}

// seconds for framer motion
const THANKS_MODAL_DURATION = 4;
// miliseconds for useEffect
const THANKS_MODAL_DURATION_MS = THANKS_MODAL_DURATION * 1000;

export const ThanksModal = ({ shouldThanksShow, onClose }: Props) => {
  useEffect(() => {
    if (!shouldThanksShow) {
      return;
    }
    const timeout = setTimeout(() => {
      onClose(false);
    }, THANKS_MODAL_DURATION_MS);

    return () => clearTimeout(timeout);
  }, [onClose, shouldThanksShow]);

  return (
    <Card
      className={{ base: parentStyles.container }}
      testId="insights-thankyou-modal"
    >
      <div className={ownStyles.headerWrapper}>
        <LinearProgressBar duration={THANKS_MODAL_DURATION} />

        <button
          className={ownStyles.closeButton}
          type="button"
          onClick={() => onClose(false)}
        >
          <Close
            className={parentStyles.closeIcon}
            size={ICON_SIZES.EXTRA_SMALL}
          />
        </button>
      </div>

      <div className={ownStyles.contentWrapper}>
        <h3 className={ownStyles.header}>Dziękujemy!</h3>
        <span className={ownStyles.content}>
          Twoja opinia jest dla nas bardzo pomocna. Nie zapomnij o tej ankiecie
          przy innych insightach 😃
        </span>
      </div>
    </Card>
  );
};
