import { Action, createHook, createStore } from "react-sweet-state";

interface State {
  keyDown: string[];
}

type Actions = typeof actions;

const actions = {
  setKeyDown: (key: string): Action<State> => ({ setState, getState }) => {
    const state = getState();
    const { keyDown } = state;

    setState({
      keyDown: [...keyDown, key]
    });
  },
  clearKeyDown: (key: string): Action<State> => ({ setState, getState }) => {
    const state = getState();
    const { keyDown } = state;
    const newKeys = keyDown.filter(currentKey => key !== currentKey);

    setState({
      keyDown: newKeys
    });
  }
};

const KeyboardStore = createStore<State, Actions>({
  name: "keyboardListener",
  initialState: {
    keyDown: []
  },
  actions
});

export const KeyboardHooks = {
  useKeyboardKeys: createHook(KeyboardStore)
};
