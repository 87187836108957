import { useSelector } from "react-redux";

import { useGetDynamicsByPeriod } from "api/dynamics/mappers";
import { useGetHours } from "api/hours/hooks";
import { useGetLoyaltyByPeriod } from "api/loyalty/mappers";
import { useGetMultipackByPeriod } from "api/multipack/mappers";
import { useGetReceiptsByPeriod } from "api/receipts/mappers/useGetReceiptsByPeriod";
import { useGetSellsByPeriod } from "api/sells/mappers/useGetSellsByPeriod";
import { useGetSharesByPeriod } from "api/shares/mappers";

import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage } from "utils";

const {
  SELL_PATH,
  SHARES_PATH,
  RECEIPTS_PATH,
  HOURS_PATH,
  DYNAMICS_PATH,
  LOYALTY_PATH,
  MULTIPACK_PATH
} = REPORTS_FULL_PATHS;

export const useIsReferenceSummaryAvailable = () => {
  const pathname = useSelector(pathnameSelector);

  const { data: hoursData } = useGetHours();
  const { data: sellsData } = useGetSellsByPeriod();
  const { data: receiptsData } = useGetReceiptsByPeriod();
  const { data: dynamicsData } = useGetDynamicsByPeriod();
  const { data: sharesData } = useGetSharesByPeriod();
  const { data: loyaltyData } = useGetLoyaltyByPeriod();
  const { data: multipackData } = useGetMultipackByPeriod();

  const data = isThisPage(pathname, SELL_PATH)
    ? sellsData?.data
    : isThisPage(pathname, SHARES_PATH)
    ? sharesData
    : isThisPage(pathname, DYNAMICS_PATH)
    ? dynamicsData
    : isThisPage(pathname, RECEIPTS_PATH)
    ? receiptsData
    : isThisPage(pathname, HOURS_PATH)
    ? hoursData
    : isThisPage(pathname, LOYALTY_PATH)
    ? loyaltyData
    : isThisPage(pathname, MULTIPACK_PATH)
    ? multipackData?.data
    : null;

  return data?.referenceSummary !== null;
};
