import { isAfter, isBefore } from "date-fns";

import { FetchedPromotion } from "pages/Reports/redux/reducers/promotionsReducer";
import { countDatesDifference } from "pages/Reports/redux/utils";
import { weekStartEndDates } from "pages/Reports/utils";
import { newDateByTimezone } from "store/utils";
import { PERIOD_TYPE, PROMO_WEEK_START_DAY } from "utils/constants";

export const reducePromotionDatesToCalendarDates = (
  promotionDate: FetchedPromotion,
  calendarFrom: string,
  calendarTo: string,
  timePeriod: string
) => {
  // this function adds first days of calendar selected periods to the actual promotion dates in order to render them only in selected period
  const { validFrom, validTo } = promotionDate;
  const dateFrom = isBefore(
    newDateByTimezone(validFrom),
    newDateByTimezone(calendarFrom)
  )
    ? calendarFrom
    : validFrom;
  const dateTo = isAfter(
    newDateByTimezone(validTo),
    newDateByTimezone(calendarTo)
  )
    ? calendarTo
    : validTo;

  const givenPeriod = () => {
    switch (timePeriod) {
      case PERIOD_TYPE.DAYS:
      case PERIOD_TYPE.RANKING:
        return { startDate: dateFrom, endDate: dateTo };
      case PERIOD_TYPE.PROMO_WEEKS:
        const [startOfPromoWeekFromDate] = weekStartEndDates(
          newDateByTimezone(dateFrom),
          PROMO_WEEK_START_DAY
        );
        const [, endOfPromoWeekToDate] = weekStartEndDates(
          newDateByTimezone(dateTo),
          PROMO_WEEK_START_DAY
        );
        return {
          startDate: startOfPromoWeekFromDate,
          endDate: endOfPromoWeekToDate
        };
      case PERIOD_TYPE.WEEKS:
      case PERIOD_TYPE.MONTHS:
      case PERIOD_TYPE.YEAR:
      default:
        return { startDate: "", endDate: "" };
    }
  };

  const { startDate, endDate } = givenPeriod();

  return {
    ...promotionDate,
    firstDayOfGivenPeriod: startDate,
    lastDayOfGivenPeriod: endDate,
    datesDifference: countDatesDifference(startDate, endDate, timePeriod) + 1 // +1 because events have its length, fe. (5th january - 5th january) gives 0 days, while event lasts for 5th january
  };
};
