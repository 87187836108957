import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { SpinnerLoader } from "components/atoms";
import { Navbar, PrivateRoute } from "components/molecules";
import { APP_PATHS } from "utils";

const Reports = React.lazy(() =>
  import(/* webpackChunkName: "Reports page" */ "pages/Reports")
);

const Insights = React.lazy(() =>
  import(/* webpackChunkName: "Insights page" */ "pages/Insights")
);

const Export = React.lazy(() =>
  import(/* webpackChunkName: "Export page" */ "pages/Export")
);

const Login = React.lazy(() =>
  import(/* webpackChunkName: "Login page" */ "pages/Login")
);

const News = React.lazy(() =>
  import(/* webpackChunkName: "News page" */ "pages/News")
);

const KnowledgeBase = React.lazy(() =>
  import(/* webpackChunkName: "Knowledge base page" */ "pages/KnowledgeBase")
);

const Admin = React.lazy(() =>
  import(/* webpackChunkName: "Admin page" */ "pages/Admin")
);

const NotFound = React.lazy(() =>
  import(/* webpackChunkName: "NotFound page" */ "pages/NotFound")
);

const StorageSet = React.lazy(() =>
  import(/* webpackChunkName: "StorageSet page" */ "pages/StorageSet")
);

const Logout = React.lazy(() =>
  import(/* webpackChunkName: "Logout page" */ "pages/Logout")
);

const MainComponent = () => <Redirect to={APP_PATHS.REPORTS} />;

export const MainSwitch = () => (
  <React.Suspense fallback={<SpinnerLoader />}>
    <Switch>
      <Route path={APP_PATHS.LOGIN}>
        <Login />
      </Route>
      <Route path={APP_PATHS.LOGOUT}>
        <Logout />
      </Route>
      <PrivateRoute>
        <Navbar />
        <Switch>
          <PrivateRoute exact path={APP_PATHS.MAIN} component={MainComponent} />
          <PrivateRoute path={APP_PATHS.INSIGHTS} component={Insights} />
          <PrivateRoute path={APP_PATHS.EXPORT} component={Export} />
          <PrivateRoute
            path={APP_PATHS.KNOWLEDGE_BASE}
            component={KnowledgeBase}
          />
          <PrivateRoute path={APP_PATHS.NEWS} component={News} />
          <PrivateRoute path={APP_PATHS.REPORTS} component={Reports} />
          <PrivateRoute path={APP_PATHS.ADMIN} component={Admin} />
          <PrivateRoute path={APP_PATHS.STORAGE_SET} component={StorageSet} />
          <Route component={NotFound} />
        </Switch>
      </PrivateRoute>
    </Switch>
  </React.Suspense>
);
