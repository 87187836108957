import * as React from "react";

import cn from "classnames";

import { INPUT_TYPE } from "utils";

import { Text } from "../../atoms";
import s from "./inputText.module.scss";

interface Props
  extends Omit<React.AllHTMLAttributes<HTMLInputElement>, "className"> {
  label?: string;
  error?: string;
  className?: {
    input?: string;
    base?: string;
    label?: string;
    labelText?: string;
  };
  tooltipText?: string;
  suggestion?: string;
}

export const InputText = React.forwardRef(
  (
    {
      className = {},
      type = INPUT_TYPE.TEXT,
      label = "",
      value,
      error = "",
      tooltipText = "",
      suggestion = "",
      ...rest
    }: Props,
    ref?: React.Ref<HTMLInputElement>
  ) => {
    return (
      <div
        className={cn(className.base, { [s.tooltip]: tooltipText })}
        data-tooltip={tooltipText}
      >
        <label className={cn(className.label)}>
          {label && (
            <Text className={cn(s.labelText, className.labelText)}>
              {label}
            </Text>
          )}
          <input
            type={type}
            className={cn(
              s.input,
              {
                [s.filled]: type === INPUT_TYPE.TEXT && !!value
              },
              className.input
            )}
            value={value}
            {...rest}
            ref={ref}
            disabled={rest.disabled}
          />
          {suggestion && (
            <Text className={cn(s.info, s.suggestion)}>{suggestion}</Text>
          )}
        </label>
        {error && <Text className={cn(s.info, s.error)}>{error}</Text>}
      </div>
    );
  }
);
