import { REPORTS_FULL_PATHS } from "pages/Reports/utils/constants";
import { APP_PATHS } from "utils";

import {
  getMetricsArray,
  MetricValidationRequiredInfo
} from "../validateParamsPerReport";

export const pickReportToValidate = (
  location: string,
  search: string
): MetricValidationRequiredInfo[] => {
  switch (location) {
    case REPORTS_FULL_PATHS.SELL_PATH:
      return getMetricsArray(search).sell;
    case REPORTS_FULL_PATHS.LOCATION_PATH:
      return getMetricsArray(search).location;
    case REPORTS_FULL_PATHS.SHARES_PATH:
      return getMetricsArray(search).shares;
    case REPORTS_FULL_PATHS.DYNAMICS_PATH:
      return getMetricsArray(search).dynamics;
    case REPORTS_FULL_PATHS.RECEIPTS_PATH:
      return getMetricsArray(search).receipts;
    case REPORTS_FULL_PATHS.SEGMENTS_PATH:
      return getMetricsArray(search).segments;
    case REPORTS_FULL_PATHS.HOURS_PATH:
      return getMetricsArray(search).hours;
    case REPORTS_FULL_PATHS.LOGISTICS_PATH:
      return getMetricsArray(search).logistics;
    case APP_PATHS.EXPORT:
      return getMetricsArray(search).export;
    case REPORTS_FULL_PATHS.LOYALTY_PATH:
      return getMetricsArray(search).loyalty;
    case REPORTS_FULL_PATHS.MULTIPACK_PATH:
      return getMetricsArray(search).multipack;
    default:
      return [];
  }
};
