export const ROW_HEIGHT = 73;
export const MAX_TABLE_ROWS = 7;
export const MIN_COLUMN_WIDTH = 150;

export const MENU_PLACEMENTS = {
  AUTO: "auto",
  AUTO_START: "auto-start",
  TOP_START: "top-start",
  TOP: "top",
  TOP_END: "top-end",
  RIGHT_START: "right-start",
  RIGHT: "right",
  RIGHT_END: "right-end",
  BOTTOM_END: "bottom-end",
  BOTTOM: "bottom",
  BOTTOM_START: "bottom-start",
  LEFT_END: "left-end",
  LEFT: "left",
  LEFT_START: "left-start",
  FIXED: "fixed"
} as const;
