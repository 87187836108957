import * as React from "react";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { locale, localeData, Moment } from "moment";
import "moment/locale/pl";

import { ChevronLeft, ChevronRight } from "components/atoms/Icon";
import { CalendarHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCalendar";
import { Nullable } from "utils/types";

import "./dateRangeCalendar.scss";

interface Dates {
  startDate: Nullable<Moment>;
  endDate: Nullable<Moment>;
}

interface Props {
  minDate: Date;
  maxDate: Date;
  onChange: (start: Date, end: Date) => void;
  defaultFocusedInput: FocusedInputShape | null;
}

export const DateRangeCalendar = ({
  minDate,
  maxDate,
  onChange,
  defaultFocusedInput
}: Props) => {
  const [
    focusedInput,
    setFocusedInput
  ] = React.useState<FocusedInputShape | null>(
    defaultFocusedInput || "startDate"
  );
  const [{ calendar }, actions] = CalendarHooks.useCalendar();

  const handleDateChange = ({ startDate, endDate }: Dates) => {
    actions.updateCalendarDates({ dateFrom: startDate, dateTo: endDate });

    if (startDate && endDate) {
      onChange(
        startDate.set("hour", 0).toDate(),
        endDate.set("hour", 0).toDate()
      );
    }
  };

  React.useEffect(() => {
    locale("pl", {
      // @ts-ignore
      longDateFormat: {
        L: "YYYY.MM.DD"
      }
    });
  }, []);

  return (
    <div>
      <DateRangePicker
        startDate={calendar.dateFrom}
        startDateId="rangePickerStartDate"
        endDate={calendar.dateTo}
        endDateId="rangePickerEndDate"
        onDatesChange={date => handleDateChange(date)}
        focusedInput={focusedInput}
        onFocusChange={(input: FocusedInputShape | null) =>
          setFocusedInput(input === null ? focusedInput : input)
        }
        navPrev={
          <span data-ga-datepicker="przewiń miesiąc">
            <ChevronLeft />
          </span>
        }
        navNext={
          <span data-ga-datepicker="przewiń miesiąc">
            <ChevronRight />
          </span>
        }
        firstDayOfWeek={1}
        displayFormat={() => localeData("pl").longDateFormat("L")}
        // @ts-ignore modifiers are not defined in the renderDayContents types
        renderDayContents={(day: Moment, modifiers: Set<string>) => {
          const weekday = day.isoWeekday();
          // calculate left & right offset
          const left = `calc(${(weekday - 1) * -100}% - 28px)`;
          const right = `calc(${(7 - weekday) * -100}% - 28px)`;

          return (
            <>
              {(modifiers.has("first-day-of-week") ||
                modifiers.has("last-day-of-week")) && (
                // apply left & right here, but set one of them to "auto" in CSS
                // since we can't determine if it's left or right calendar via JS
                <div className="CalendarWeekNumber" style={{ left, right }}>
                  {day.isoWeek()}
                </div>
              )}
              <div className="CalendarDayCustomContent">{day.date()}</div>
            </>
          );
        }}
        transitionDuration={0}
        keepOpenOnDateSelect
        hideKeyboardShortcutsPanel
        startDatePlaceholderText="rrrr.mm.dd"
        endDatePlaceholderText="rrrr.mm.dd"
        isOutsideRange={date =>
          date.isBefore(minDate, "day") || date.isAfter(maxDate, "day")
        }
        minimumNights={0}
      />
    </div>
  );
};
