import { createSelector } from "reselect";

import { AppState } from "store";
import {
  isPowerUserSelector,
  tierSelector,
  userRoleSelector
} from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { BOOL_STRING_VALUES, isThisPage, PERIOD_TYPE } from "utils";
import { Values } from "utils/types";

import {
  CHART_PERIODS_SETTINGS,
  T1_T2_HIDDEN_PERIODS,
  TABLE_PERIODS_SETTINGS
} from "./constants";

const { TRUE } = BOOL_STRING_VALUES;

export const validatedChartPeriodsSelector = createSelector(
  tierSelector,
  userRoleSelector,
  isPowerUserSelector,
  pathnameSelector,
  (tier, role, isPowerUser, pathname) =>
    CHART_PERIODS_SETTINGS.filter(periods => periods.tier.includes(tier))
      .filter(period =>
        !isPowerUser && period.localStorageKey
          ? localStorage.getItem(period.localStorageKey) === TRUE
          : period.role.includes(role)
      )
      .filter(period => period.report.some(url => isThisPage(pathname, url)))
      .map(period => period.type)
);

export const validatedTablePeriodsSelector = createSelector(
  tierSelector,
  pathnameSelector,
  (tier, pathname) =>
    TABLE_PERIODS_SETTINGS.filter(period => period.tier.includes(tier))
      .filter(period => period.report.some(url => isThisPage(pathname, url)))
      .map(period => period.type)
);

export const hiddenPeriodsSelector = createSelector(tierSelector, tier =>
  [1, 2].includes(tier) ? T1_T2_HIDDEN_PERIODS : []
);

export const rankingSummaryPeriodSelector = createSelector<
  AppState,
  Values<typeof PERIOD_TYPE>[],
  { rawKey: "total" | "byMonth"; period: Values<typeof PERIOD_TYPE> }
>(validatedChartPeriodsSelector, periods =>
  // PMD-2546 - get period to fetch summary data for ranking period
  periods.includes(PERIOD_TYPE.TOTAL)
    ? { rawKey: "total", period: PERIOD_TYPE.TOTAL }
    : { rawKey: "byMonth", period: PERIOD_TYPE.MONTHS }
);
