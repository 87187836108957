import { ThunkAction } from "redux-thunk";

import { AppState } from "../";
import { BaseAction, CallbackAction } from "../utils/types";

export const dispatchAll: (
  actions: CallbackAction[]
) => ThunkAction<void, AppState, undefined, BaseAction> = actions => dispatch =>
  // @ts-ignore cannot figure out how to type ThunkAction to make typescript fine with dispatch thunks byother thunks
  actions.map(a => dispatch(a));
