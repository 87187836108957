import { useDispatch, useSelector } from "react-redux";

import { isRestrictedToOneLineSelector } from "components/molecules/ChartTypeToggle/hooks";
import {
  hiddenLinesByReportSelector,
  toggleChart
} from "pages/Reports/redux/reducers/chartReducer";

export const useResetHiddenLines = () => {
  const dispatch = useDispatch();
  const isRestrictedToOneLine = useSelector(isRestrictedToOneLineSelector);
  const hiddenLines = useSelector(hiddenLinesByReportSelector);

  const resetHiddenLines = () => {
    if (isRestrictedToOneLine) {
      dispatch(toggleChart([]));
      dispatch(toggleChart(hiddenLines));
    }
  };

  return { resetHiddenLines };
};
