import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";

import { Text } from "components/atoms";
import { TooltipIcon } from "components/atoms/Icon";
import { TOTAL_BLOCKED_BY_COUNTIES_TOOLTIP } from "pages/Reports/sections/Sell/constants";
import {
  REPORTS_FULL_PATHS,
  SUMMARY_SUB_SWITCH,
  SUMMARY_SWITCH
} from "pages/Reports/utils";
import { updateQueryParams } from "store/actions/routerActions";
import { isPowerUserSelector } from "store/reducers/userReducer";
import {
  countiesQueryParamSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import { getPageTitle, isThisPage } from "utils";
import { pushSummaryEvent } from "utils/googleTagManager/dataLayer";
import { Values } from "utils/types";

import s from "./summarySwitch.module.scss";

export type SummaryTab = {
  label: string;
  value: Values<typeof SUMMARY_SWITCH & typeof SUMMARY_SUB_SWITCH>;
  ga: { [key: string]: string };
  tooltip?: string;
};

interface Props {
  tabs: SummaryTab[];
  visibleTab: string;
  tabParam: string;
  isSecondary: boolean;
  label?: string;
  buttonsId?: string;
}

const { TOTAL } = SUMMARY_SWITCH;

export const SummarySwitch: FC<Props> = ({
  tabs,
  visibleTab,
  tabParam,
  isSecondary,
  label = "",
  buttonsId
}) => {
  const dispatch = useDispatch();
  const counties = useSelector(countiesQueryParamSelector);
  const pathname = useSelector(pathnameSelector);
  const isPowerUser = useSelector(isPowerUserSelector);

  const isLocation = isThisPage(pathname, REPORTS_FULL_PATHS.LOCATION_PATH);
  const hasCounties = counties.length > 0;

  const isTabBlocked = (tab: string) => {
    if (tab === TOTAL) {
      // [PMD-2212]: power users should have access to all first types on location
      if (isLocation && isPowerUser) return false;
      return hasCounties;
    }
    return false;
  };

  const getBlockedTabProps = (tab: string) =>
    isTabBlocked(tab)
      ? {
          disabled: true,
          "data-tooltip": TOTAL_BLOCKED_BY_COUNTIES_TOOLTIP
        }
      : {};

  const handleTabClick = (value: string, label: string) => {
    const pageTitle = getPageTitle(pathname);
    pushSummaryEvent(`${pageTitle} - ${label}`);
    dispatch(updateQueryParams({ [tabParam]: value.toLowerCase() }));
  };

  return (
    <section
      className={cn(s.toggleWrapper, {
        [s.toggleWrapperSecondary]: isSecondary
      })}
    >
      {label.length > 0 && <Text className={s.toggleTitle}>{label}</Text>}
      <div id={buttonsId} className={s.toggleButtonsWrapper}>
        {tabs.map(({ value, label, ga, tooltip = "" }) => (
          <div key={value} className={s.toggleButtonWrapper}>
            <button
              key={value}
              className={cn(s.toggleButton, {
                [s.toggleButtonActive]: visibleTab === value
              })}
              onClick={() => handleTabClick(value, label)}
              type="button"
              data-testid={`summary-tab-${value}`}
              data-active={visibleTab === value}
              {...ga}
              {...getBlockedTabProps(value)}
            >
              {label}
            </button>
            {tooltip.length > 0 && (
              <div className={s.tooltip} data-tooltip={tooltip}>
                <TooltipIcon data-tooltip={tooltip} />
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};
