import qs from "query-string";
import { isNil, pickBy } from "ramda";

import { getCategoriesAndLevel } from "pages/Reports/sections/Sell/redux/utils";

import { getBackendReportType } from "./getBackendReportType";

export const generatePromotionsParams = (pathname: string, search: string) => {
  const report_type = getBackendReportType(pathname);
  const queryElements = qs.parse(search);
  const {
    category1,
    category2,
    category3,
    date_from,
    date_to,
    product,
    competing_companies,
    competing_materials,
    delay,
    vendor_id,
    brands,
    sub_brands,
    competing_brands,
    competing_sub_brands,
    compare_date_from,
    compare_date_to,
    chart_type
  } = queryElements;

  const [categories, categoryLevel] = getCategoriesAndLevel(
    category1,
    category2,
    category3
  );

  const params = {
    date_from,
    date_to,
    compare_date_from,
    compare_date_to,
    materials: product || null,
    category_level: categoryLevel,
    categories: categories,
    competing_companies,
    competing_materials,
    competitor_delay: delay,
    vendor_id,
    brands,
    sub_brands,
    competing_brands,
    competing_sub_brands,
    report_type,
    chart_type
  };

  const clearedParams: { [key: string]: string } = pickBy(
    value => (typeof value === "string" ? !!value.length : !isNil(value)),
    params
  );

  return clearedParams;
};
