import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ZAPPKA_UNRELATED_DATA_TYPES } from "api/utils/getDataTypeKey";
import cn from "classnames";

import { Chart, ChartData } from "components/D3/types";
import {
  hoveredLineTypeSelector,
  updateHoveredLineType
} from "pages/Reports/redux/reducers/chartReducer";
import { useGetZappkaSharesSuffix } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import {
  CHART_COLORS,
  DATASET_TYPES,
  getPolishLabelForChartHeader
} from "utils";

import { useDataTypeSuffix } from "../StandardChart/components/WithZappkaSelect";
import { PRIMARY_RIGHT, SECONDARY_LEFT, SECONDARY_RIGHT } from "./constants";
import s from "./linesLegend.module.scss";

export const LINE_TYPES = {
  PRIMARY_LEFT: "primary-left",
  PRIMARY_RIGHT: "primary-right",
  SECONDARY_LEFT: "secondary-left",
  SECONDARY_RIGHT: "secondary-right"
};

const getBaseVisible = (data: Chart[]) => {
  const types: string[] = [DATASET_TYPES.OWN, DATASET_TYPES.COMPETITOR];
  return data.some(({ type }) => types.includes(type || ""));
};

const Line = ({ color, dasharray }: { color: string; dasharray?: string }) => {
  return (
    <svg width={60} height={12}>
      <line
        y1={7}
        x1={1}
        y2={7}
        x2={59}
        stroke={color}
        strokeWidth={2}
        strokeDasharray={dasharray}
        strokeLinecap="round"
      />
    </svg>
  );
};

export const LinesLegend = ({ data }: { data: ChartData }) => {
  const dispatch = useDispatch();
  const hoveredLineTypes = useSelector(hoveredLineTypeSelector);
  const [suffix1, suffix2] = useDataTypeSuffix();
  const getZappkaSharesSuffix = useGetZappkaSharesSuffix();

  const lines = useMemo(() => {
    const first = data.leftChart;
    const second = data.rightChart;
    const firstType = first?.dataType || "";
    const secondType = second?.dataType || "";

    const shares = {
      first: Boolean(getZappkaSharesSuffix(0)),
      second: Boolean(getZappkaSharesSuffix(1))
    };
    const unrelated = {
      first: ZAPPKA_UNRELATED_DATA_TYPES.includes(firstType),
      second: ZAPPKA_UNRELATED_DATA_TYPES.includes(secondType)
    };
    const getLabel = (dataType: string, suffix: string, shares: boolean) => {
      const label = getPolishLabelForChartHeader(dataType);
      if (ZAPPKA_UNRELATED_DATA_TYPES.includes(dataType)) return label;
      let value = "";
      if (suffix === "_OFFLINE") value = " - bez Żappki";
      if (suffix === "_ONLINE") value = " - z Żappką";
      if (shares) value = " - udział Żappki";
      return label.concat(value);
    };

    return [
      {
        id: LINE_TYPES.PRIMARY_LEFT,
        label: getLabel(firstType, suffix1, shares.first),
        dasharray: undefined,
        color: first?.chart[0]?.color || CHART_COLORS.colorChart0,
        visible: getBaseVisible(first?.chart || [])
      },
      {
        id: LINE_TYPES.SECONDARY_LEFT,
        label: getLabel(firstType, suffix2, shares.first),
        dasharray: SECONDARY_LEFT[0],
        color: first?.chart[0]?.color || CHART_COLORS.colorChart0,
        visible:
          !unrelated.first &&
          suffix2 &&
          !shares.first &&
          getBaseVisible(first?.chart || [])
      },
      // second type
      {
        id: LINE_TYPES.PRIMARY_RIGHT,
        label: getLabel(secondType, suffix1, shares.second),
        dasharray: PRIMARY_RIGHT[0],
        color: second?.chart[0]?.color || CHART_COLORS.colorChart0,
        visible: getBaseVisible(second?.chart || [])
      },
      {
        id: LINE_TYPES.SECONDARY_RIGHT,
        label: getLabel(secondType, suffix2, shares.second),
        dasharray: SECONDARY_RIGHT[0],
        color: second?.chart[0]?.color || CHART_COLORS.colorChart0,
        visible:
          !unrelated.second &&
          suffix2 &&
          !shares.second &&
          getBaseVisible(second?.chart || [])
      }
    ].filter(item => item.visible);
  }, [
    getZappkaSharesSuffix,
    data.leftChart,
    data.rightChart,
    suffix1,
    suffix2
  ]);

  if (data.domain.length <= 1 || lines.length < 2) return null;

  return (
    <div className={s.container}>
      {lines.map((line, index) => (
        <div
          key={index}
          className={cn(s.lineWrapper, {
            [s.active]: hoveredLineTypes.includes(line.id)
          })}
          onMouseEnter={() => {
            dispatch(updateHoveredLineType([line.id]));
          }}
          onMouseLeave={() => {
            dispatch(updateHoveredLineType([]));
          }}
        >
          <Line color={line.color} dasharray={line.dasharray} />
          <p className={s.lineLabel}>{line.label}</p>
        </div>
      ))}
    </div>
  );
};
