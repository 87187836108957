import { createSelector } from "reselect";

import {
  AllowedCookies,
  CookiesState
} from "pages/Admin/redux/reducers/cookiesSettingsReducer";
import { AppState } from "store";

const cookiesSelector = (store: AppState) => store.admin.cookiesSettings;

export const showCookieConsentSelector = createSelector<
  AppState,
  CookiesState,
  boolean
>(cookiesSelector, ({ showCookieConsent }) => showCookieConsent);

export const allowedCookiesSelector = createSelector<
  AppState,
  CookiesState,
  AllowedCookies
>(cookiesSelector, ({ allowedCookies }) => allowedCookies);
