import { AppState } from "store";
import { Values } from "utils/types";

// TYPES
type SegmentsChartState = {
  currentChart: string;
};

type UpdateCurrentChartAction = {
  payload: string;
  type: typeof UPDATE_CURRENT_CHART;
};

type Actions = {
  UPDATE_CURRENT_CHART: UpdateCurrentChartAction;
};

// CONST
const UPDATE_CURRENT_CHART = "Segments.chart.UPDATE_CURRENT_CHART" as const;

export const updateCurrentChart = (
  payload: string
): UpdateCurrentChartAction => ({
  type: UPDATE_CURRENT_CHART,
  payload
});

export const segmentsChartSelector = (state: AppState) =>
  state.reports.segments.chart;

//  REDUCER
const initialState: SegmentsChartState = {
  currentChart: ""
};

export const segmentsChartReducer = (
  state: SegmentsChartState = initialState,
  action: Values<Actions>
): SegmentsChartState => {
  switch (action.type) {
    case UPDATE_CURRENT_CHART:
      return { ...state, currentChart: action.payload };
    default:
      return state;
  }
};
