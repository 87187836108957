import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";
import qs from "query-string";

import { SpinnerLoader } from "components/atoms";
import { Search } from "components/atoms/Icon";
import { Dropdown, FilterDropdownInput } from "components/molecules";
import { FilterErrorMsg } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/FilterErrorMsg/FilterErrorMsg";
import {
  asyncFiltersSelector,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useProductBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useProductFetching";
import { CategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import {
  ProductHooks,
  ProductItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";
import { isParentCategorySelected } from "store/utils/filtersUtils";
import { QP } from "utils/defaultQueryParams";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

import { DROPDOWN_LABELS, DROPDOWN_PLACEHOLDERS } from "../constants";
import { filterItems } from "../utils";
import s from "./productDropdown.module.scss";
import { ProductDropdownMenu } from "./ProductDropdownMenu";

type Props = {
  isDisabled: boolean;
  selectedLabel: string;
};

export const ProductDropdown = ({ isDisabled, selectedLabel }: Props) => {
  const dispatch = useDispatch();
  const [state, actions] = ProductHooks.useProduct();

  const [selectedL1Items] = CategoryHooks.useSelectedCategories(1);
  const [selectedL2Items] = CategoryHooks.useSelectedCategories(2);
  const [selectedL3Items] = CategoryHooks.useSelectedCategories(3);
  const isLoading = useSelector(asyncFiltersSelector).fetching.products;
  const errorMsg = useSelector(asyncFiltersSelector).error.products;

  const [isOpen, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  useProductBehaviour(isDisabled);

  const handleChange = (item: ProductItem | null) => {
    setOpen(true);
    actions.selectProduct(item);
  };

  const handleInputValueChange = (value: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (selectedItem?: ProductItem | null) => {
    setOpen(false);
    if (!selectedItem) {
      setInputValue(selectedLabel);
      actions.sortProducts();
    }
  };

  const handleStateChange = (changes: StateChangeOptions<ProductItem[]>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleSelectAllActive = () => {
    actions.selectAllActiveOrInactiveProducts(inputValue, true, [
      selectedL1Items,
      selectedL2Items,
      selectedL3Items
    ]);
    pushFiltersEvent("product - select active");
  };

  const handleSelectAllInactive = () => {
    actions.selectAllActiveOrInactiveProducts(inputValue, false, [
      selectedL1Items,
      selectedL2Items,
      selectedL3Items
    ]);
    pushFiltersEvent("product - select inactive");
  };

  const handleClear = () => {
    handleChange(null);
    pushFiltersEvent("product - clear");
  };

  const filterProductsByParentAndInput = (inputValue: string) => (
    item: ProductItem
  ) => {
    const isItemSelected = state.selected.some(
      ({ value }) => value === item.value
    );

    //always show selected item
    if (isItemSelected) {
      return true;
    }

    const isParentSelected = isParentCategorySelected(
      QP.PRODUCT,
      item,
      selectedL1Items,
      selectedL2Items,
      selectedL3Items
    );

    const categoryMatchInputValue = filterItems<ProductItem>(inputValue)(item);

    return isParentSelected && categoryMatchInputValue;
  };

  useEffect(() => {
    if (!isOpen) setInputValue(selectedLabel);
  }, [isOpen, selectedLabel]);

  if (!isLoading && errorMsg) {
    return (
      <FilterErrorMsg
        label={DROPDOWN_LABELS.PRODUCT}
        callback={() => {
          dispatch(
            updateQuery({ filter: "products", query: qs.stringify({}) })
          );
        }}
        errorMsg={errorMsg}
      />
    );
  }

  return (
    <Dropdown<ProductItem[]>
      className={s.productDropdownWrapper}
      isMultiChoice
      isMultiChoiceSplitted={true}
      isDisabled={isDisabled || isLoading}
      //@ts-ignore
      itemToString={() => {}}
      selectedItem={state.selected}
      items={state.all}
      label={DROPDOWN_LABELS.PRODUCT}
      openedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.PRODUCT.OPEN
      }
      closedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.PRODUCT.CLOSED
      }
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      // @ts-ignore
      onChange={handleChange}
      onClear={handleClear}
      onStateChange={handleStateChange}
      onSelectAllActive={handleSelectAllActive}
      onSelectAllInactive={handleSelectAllInactive}
      input={
        <FilterDropdownInput
          customIcon={isLoading ? <SpinnerLoader size="small" /> : <Search />}
          isTypingEnabled
          automaticInputValue={selectedLabel}
          testId="own-product-dropdown"
        />
      }
      menu={
        <ProductDropdownMenu
          filterItemsFn={filterProductsByParentAndInput}
          minimumItemsToVirtualize={30}
        />
      }
      onOuterClick={() => setOpen(false)}
    />
  );
};
