import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { color } from "d3";
import { flatten } from "ramda";

import { Chart, ChartData, ChartTimePoint } from "components/D3/types";
import { scaleAndVertical } from "pages/Reports/partials/Chart/StandardChart/utils";
import { useChartScales } from "pages/Reports/partials/Chart/StandardChart/utils/useChartScales";
import { ChartOnClickAction } from "pages/Reports/redux/reducers/chartReducer";
import { areTilesHiddenSelector } from "store/selectors/routerSelectors";
import { BAR_RADIUS } from "store/utils/chartUtils";
import { NO_DATA_SHORT } from "utils/constants";
import { round } from "utils/round";
import { Nullable } from "utils/types";

import { ChartDot } from "../DotsChart/ChartDot";
import { DotsChart } from "../DotsChart/DotsChart";
import { Bar } from "./Bar";
import { BarTile } from "./BarTile";
import { useChartValueTiles } from "./utils/useChartValueTiles";

type Props = {
  width: number;
  height: number;
  onMouseMoveBar?: (index: number) => void;
  onMouseLeaveBar?: () => void;
  onTimelineClick: ChartOnClickAction;
  lineChart: ChartData;
  originalWidth: number;
};

const MAX_BAR_WIDTH = 30;

const getBarsRegionWidth = (
  barWidth: number,
  barsCount: number,
  gapWidth: number
) => barWidth * barsCount + gapWidth * (barsCount - 1);

const getGapWidth = (
  barWidth: number,
  barsCount: number,
  chartWidth: number,
  placeBarsAtCenter: boolean
) => {
  const gap = (chartWidth - barWidth * barsCount) / barsCount;

  if (!placeBarsAtCenter) return gap;

  return Math.min(gap, MAX_BAR_WIDTH * 1.2);
};

export const getBarHorizontalPos = ({
  barWidth,
  barIndex,
  barsCount,
  chartWidth,
  isBackData = false,
  placeBarsAtCenter = true
}: {
  barWidth: number;
  barIndex: number;
  barsCount: number;
  chartWidth: number;
  isBackData?: boolean;
  placeBarsAtCenter?: boolean;
}) => {
  const gapWidth = getGapWidth(
    barWidth,
    barsCount,
    chartWidth,
    placeBarsAtCenter
  );
  const barsRegionWidth = getBarsRegionWidth(barWidth, barsCount, gapWidth);
  const barsRegionStartPos = (chartWidth - barsRegionWidth) / 2;

  const basePosition = barsRegionStartPos + (barWidth + gapWidth) * barIndex;

  if (!isBackData) {
    return [basePosition, basePosition];
  }

  const regularBarPos = basePosition - barWidth / 2 - 1;
  const backDataBarPos = basePosition + barWidth / 2 + 1; // +1 for a tiny designish gap

  return [regularBarPos, backDataBarPos];
};

const getColors = (hex: string) => ({
  darken: String(color(hex)?.darker(0.6) || "#000"),
  opacity: `${hex}40`
});

export const BarsGroup = ({
  height,
  width,
  onTimelineClick,
  onMouseMoveBar = () => {},
  onMouseLeaveBar = () => {},
  lineChart,
  originalWidth
}: Props) => {
  const [tileState, tileActions] = useChartValueTiles();
  const { xScaleBand: xScale, yScale, yScaleRight } = useChartScales(
    lineChart,
    originalWidth
  );
  const areTilesHidden = useSelector(areTilesHiddenSelector);
  const leftData = lineChart.leftChart?.chart;
  const rightData = lineChart.rightChart?.chart;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onTimelineClick(null));
  }, [dispatch, onTimelineClick]);

  if (!leftData?.length && !rightData?.length) return null;

  if (!leftData?.length) {
    return (
      <DotsChart
        lineChart={lineChart}
        width={width}
        originalWidth={originalWidth}
        onTimelineHover={lineChart.onTimelineHover}
        onTimelineClick={onTimelineClick}
      />
    );
  }

  const bandWidth = xScale.bandwidth();
  const dataWithYValues = flatten(
    leftData.filter(({ timeline }) =>
      timeline.every(({ valueY }) => valueY !== null)
    )
  );
  const barsCount = dataWithYValues.length;

  const getValueAndScale = (
    timeline: ChartTimePoint[] | undefined,
    index: number
  ) => {
    if (!timeline) {
      return { value: null, scale: null };
    }

    const valueY = timeline[index].valueY;

    return {
      value: valueY,
      scale: valueY !== null ? scaleAndVertical(yScale, height, valueY) : null
    };
  };

  const getTile = ({
    chart,
    value,
    isHistorical,
    isSecondary
  }: {
    chart: Chart;
    value: Nullable<number>;
    isHistorical: boolean;
    isSecondary?: boolean;
  }) => {
    const tile = tileState.tiles.find(tile => {
      let exists =
        tile.lineName === chart.id && tile.isHistorical === isHistorical;
      if (typeof isSecondary === "boolean") {
        exists = exists && tile.isSecondary === isSecondary;
      }
      return exists;
    });

    return areTilesHidden || !tile
      ? null
      : {
          backgroundColor: chart.color,
          firstValue: value ? round(value) : NO_DATA_SHORT,
          onClick: () => tileActions.handleTiles([tile]),
          opacity: "1"
        };
  };

  const getDotPosition = ({
    timeline,
    horizontalPosition,
    barWidth
  }: {
    timeline: ChartTimePoint[] | undefined;
    horizontalPosition: number;
    barWidth: number;
  }) => {
    const x = horizontalPosition + barWidth / 2;
    const y = yScaleRight(timeline?.[0]?.valueY || 0);
    return `translate(${x},${y})`;
  };

  return (
    <>
      {dataWithYValues.map((chart, chartIndex) =>
        chart.timeline.map((bar, barIndex) => {
          const { valueY, valueX } = bar;
          // data is already filtered but it's here just to prevent tslint from complainig
          if (valueY === null) return null;

          const step = (yScale.ticks()[0] - yScale.ticks()[1]) / 2;
          const color = getColors(chart.color);
          const mouseEvents = {
            onMouseMove: () => onMouseMoveBar(chartIndex),
            onMouseLeave: onMouseLeaveBar
          };

          const { offsetTop, scaledHeight } = scaleAndVertical(
            yScale,
            height,
            valueY
          );

          const {
            value: secondaryValueY,
            scale: secondaryScale
          } = getValueAndScale(chart?.secondaryTimeline, barIndex);
          const {
            value: lastYearValueY,
            scale: backDataScale
          } = getValueAndScale(chart?.lastYearTimeline, barIndex);
          const {
            value: secondaryLastYearValueY,
            scale: secondaryLastYearScale
          } = getValueAndScale(chart?.secondaryLastYearTimeline, barIndex);

          const regularBarWidth = Math.min(
            bandWidth / barsCount - 10,
            MAX_BAR_WIDTH
          );
          const barWidth = backDataScale
            ? regularBarWidth / 1.4
            : regularBarWidth;

          const [horizontalPos, backDataHorizontalPos] = getBarHorizontalPos({
            barWidth,
            barIndex: chartIndex,
            barsCount,
            chartWidth: width,
            isBackData: Boolean(backDataScale),
            placeBarsAtCenter: true
          });

          const element = rightData?.find(el => el.label === chart.label);

          const regularTile = getTile({
            chart,
            value: valueY,
            isHistorical: false
          });
          const secondaryTile = getTile({
            chart,
            value: secondaryValueY,
            isHistorical: false,
            isSecondary: true
          });
          const lastYearTile = getTile({
            chart,
            value: lastYearValueY,
            isHistorical: true
          });
          const secondaryLastYearTile = getTile({
            chart,
            value: secondaryLastYearValueY,
            isHistorical: true,
            isSecondary: true
          });

          const key = `${chartIndex}-${chart.label}:${barIndex}-${valueX}-${valueY}`;

          return (
            <Fragment key={key}>
              <Bar
                id={chart.id || chart.label}
                showFakeBar={Math.abs(valueY) < Math.abs(step)}
                isBarUpsideDown={valueY < 0}
                width={barWidth}
                height={scaledHeight}
                color={chart.color}
                radius={
                  barWidth >= BAR_RADIUS.BIG ? BAR_RADIUS.BIG : BAR_RADIUS.SMALL
                }
                verticalPos={offsetTop}
                horizontalPos={horizontalPos}
                {...mouseEvents}
                tile={regularTile}
              />
              {secondaryScale && (
                <>
                  <rect
                    x={horizontalPos}
                    y={secondaryScale.offsetTop}
                    width={barWidth}
                    height={secondaryScale.scaledHeight}
                    fill={color.darken}
                    pointerEvents="none"
                  />
                  {secondaryTile && (
                    <BarTile
                      tile={secondaryTile}
                      barWidth={barWidth}
                      horizontalPosition={horizontalPos}
                      verticalPosition={secondaryScale.offsetTop}
                    />
                  )}
                </>
              )}
              {backDataScale && (
                <>
                  <Bar
                    id={chart.id || chart.label}
                    showFakeBar={Math.abs(lastYearValueY ?? 0) < Math.abs(step)}
                    isBarUpsideDown={(lastYearValueY ?? 0) < 0}
                    width={barWidth}
                    height={backDataScale.scaledHeight}
                    color={color.opacity}
                    radius={
                      barWidth >= BAR_RADIUS.BIG
                        ? BAR_RADIUS.BIG
                        : BAR_RADIUS.SMALL
                    }
                    verticalPos={backDataScale.offsetTop}
                    horizontalPos={backDataHorizontalPos}
                    {...mouseEvents}
                    tile={lastYearTile}
                    isHistorical
                  />
                  {secondaryLastYearScale && (
                    <>
                      <rect
                        x={backDataHorizontalPos}
                        y={secondaryLastYearScale.offsetTop}
                        width={barWidth}
                        height={secondaryLastYearScale.scaledHeight}
                        fill={`${chart.color}40`}
                        pointerEvents="none"
                      />
                      {secondaryLastYearTile && (
                        <BarTile
                          tile={secondaryLastYearTile}
                          barWidth={barWidth}
                          horizontalPosition={backDataHorizontalPos}
                          verticalPosition={secondaryLastYearScale.offsetTop}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              {element && (
                <>
                  <ChartDot
                    id={`${key}-dot`}
                    fill={chart.color}
                    transform={getDotPosition({
                      timeline: element.timeline,
                      horizontalPosition: horizontalPos,
                      barWidth
                    })}
                    {...mouseEvents}
                    onTimelineClick={() => dispatch(onTimelineClick(0))}
                  />
                  {secondaryScale && (
                    <ChartDot
                      id={`${key}-secondary-dot`}
                      fill={color.darken}
                      transform={getDotPosition({
                        timeline: element.secondaryTimeline,
                        horizontalPosition: horizontalPos,
                        barWidth
                      })}
                      {...mouseEvents}
                      onTimelineClick={() => dispatch(onTimelineClick(0))}
                    />
                  )}
                  {backDataScale && (
                    <>
                      <ChartDot
                        id={`${key}-back-data-dot`}
                        fill={color.opacity}
                        transform={getDotPosition({
                          timeline: element.lastYearTimeline,
                          horizontalPosition: backDataHorizontalPos,
                          barWidth
                        })}
                        {...mouseEvents}
                        onTimelineClick={() => dispatch(onTimelineClick(0))}
                      />
                      {secondaryLastYearScale && (
                        <ChartDot
                          id={`${key}-secondary-back-data-dot`}
                          fill={color.opacity}
                          transform={getDotPosition({
                            timeline: element.secondaryLastYearTimeline,
                            horizontalPosition: backDataHorizontalPos,
                            barWidth
                          })}
                          {...mouseEvents}
                          onTimelineClick={() => dispatch(onTimelineClick(0))}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Fragment>
          );
        })
      )}
    </>
  );
};
