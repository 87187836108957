import { createSelector } from "reselect";

import {
  aggregatedCompetitionQueryParamSelector,
  competitionCompaniesQueryParamSelector,
  competitionProductsQueryParamSelector,
  dateToQueryParamSelector
} from "store/selectors/routerSelectors";
import { BOOL_STRING_VALUES, isD28DateSelected } from "utils";

export const isD28DateSelectedSelector = createSelector(
  dateToQueryParamSelector,
  dateTo => isD28DateSelected(dateTo)
);

export const isCompetitionEnabledSelector = createSelector(
  competitionCompaniesQueryParamSelector,
  competitionProductsQueryParamSelector,
  aggregatedCompetitionQueryParamSelector,
  (
    competitionCompaniesQueryParam,
    competitionProductsQueryParam,
    aggregatedCompetitionQueryParam
  ) =>
    Boolean(
      competitionCompaniesQueryParam.length ||
        competitionProductsQueryParam.length ||
        aggregatedCompetitionQueryParam === BOOL_STRING_VALUES.TRUE
    )
);
