import { useSelector } from "react-redux";

import { useGetDynamicsByPeriod } from "api/dynamics/mappers";
import { transformSummary } from "api/utils";

import { useSummaryDataTypeSuffix } from "components/organisms/ReportSummary/hooks/useSummaryClientTabs";
import {
  DynamicsSummary,
  DynamicsSummaryItem
} from "pages/Reports/types/summary";
import {
  createSummaryItemData,
  SUMMARY_SUB_SWITCH,
  SUMMARY_SWITCH
} from "pages/Reports/utils";
import { NUMBER_TYPE } from "pages/Reports/utils/formatNumber";
import { isPowerUserSelector } from "store/reducers/userReducer";
import {
  productsQueryParamSelector,
  uppercasedDynamicsSummaryTabSelector,
  uppercasedSummarySubTypeSelector
} from "store/selectors/routerSelectors";
import { ONLY_SINGLE_SKU_VISIBLE, REPORTS_SUMMARY_LABELS } from "utils";

const KEYS_WITH_SUFFIX = [
  "netQuantitySalesSum",
  "quantitySum",
  "worthSum"
].flatMap(key => [key, `${key}Change`, `${key}LastYear`]);

export const useDynamicsSummary = () => {
  const { data } = useGetDynamicsByPeriod();

  const summaryType = useSelector(uppercasedDynamicsSummaryTabSelector);
  const summarySubType = useSelector(uppercasedSummarySubTypeSelector);
  const productsQueryParam = useSelector(productsQueryParamSelector);
  const isPowerUser = useSelector(isPowerUserSelector);
  const suffix = useSummaryDataTypeSuffix();

  const isReferenceType = summarySubType === SUMMARY_SUB_SWITCH.REF_CATEGORY;
  const rawSummary = isReferenceType
    ? data?.referenceSummary
    : data?.ownSummary;

  if (!rawSummary) {
    return [];
  }

  const summary = transformSummary<DynamicsSummary>(
    rawSummary,
    KEYS_WITH_SUFFIX,
    suffix
  );
  const [g1, g2, g3] = ["1", "2", "3"].map(key => {
    // @ts-ignore - avoid key type checking
    const data = rawSummary[key];
    return data
      ? transformSummary<DynamicsSummaryItem>(data, KEYS_WITH_SUFFIX, suffix)
      : null;
  });

  const isOneProductSelected =
    typeof productsQueryParam === "string" && !!productsQueryParam.length;

  const distributionTabItems = isReferenceType
    ? []
    : [
        {
          label: REPORTS_SUMMARY_LABELS.AVG_SHOPS_COUNT,
          firstValue: summary.avgShopsCount,
          firstValueType: isOneProductSelected
            ? NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
            : NUMBER_TYPE.NOT_APPLICABLE,
          secondValue: isOneProductSelected
            ? summary.avgShopsCountChange
            : null,
          secondValueType: NUMBER_TYPE.PERCENT,
          thirdValue: null,
          thirdValueType: NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
          change: isOneProductSelected ? summary.avgShopsCountChange : 0,
          tooltipText: !isOneProductSelected ? ONLY_SINGLE_SKU_VISIBLE : ""
        },
        {
          label: REPORTS_SUMMARY_LABELS.DISTRIBUTION_RANGE,
          firstValue: summary.distributionRange,
          firstValueType: isOneProductSelected
            ? NUMBER_TYPE.PERCENT
            : NUMBER_TYPE.NOT_APPLICABLE,
          secondValue: isOneProductSelected
            ? summary.distributionRangeChange
            : null,
          secondValueType: NUMBER_TYPE.PERCENT_POINTS,
          thirdValue: null,
          thirdValueType: NUMBER_TYPE.PERCENT,
          change: isOneProductSelected ? summary.distributionRangeChange : 0,
          tooltipText: !isOneProductSelected ? ONLY_SINGLE_SKU_VISIBLE : ""
        },
        ...(isPowerUser
          ? [
              {
                label: REPORTS_SUMMARY_LABELS.AVG_PLANOGRAM_SHOPS_COUNT,
                firstValue: summary.avgPlanogramShopsCount,
                firstValueType: isOneProductSelected
                  ? NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE
                  : NUMBER_TYPE.NOT_APPLICABLE,
                secondValue: isOneProductSelected
                  ? summary.avgPlanogramShopsCountChange
                  : null,
                secondValueType: NUMBER_TYPE.PERCENT,
                thirdValue: null,
                thirdValueType: NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
                change: isOneProductSelected
                  ? summary.avgPlanogramShopsCountChange
                  : 0,
                tooltipText: !isOneProductSelected
                  ? ONLY_SINGLE_SKU_VISIBLE
                  : ""
              },
              {
                label: REPORTS_SUMMARY_LABELS.PLANOGRAM_DISTRIBUTION_RANGE,
                firstValue: summary.planogramDistributionRange,
                firstValueType: isOneProductSelected
                  ? NUMBER_TYPE.PERCENT
                  : NUMBER_TYPE.NOT_APPLICABLE,
                secondValue: isOneProductSelected
                  ? summary.planogramDistributionRangeChange
                  : null,
                secondValueType: NUMBER_TYPE.PERCENT_POINTS,
                thirdValue: null,
                thirdValueType: NUMBER_TYPE.PERCENT,
                change: isOneProductSelected
                  ? summary.planogramDistributionRangeChange
                  : 0,
                tooltipText: !isOneProductSelected
                  ? ONLY_SINGLE_SKU_VISIBLE
                  : ""
              }
            ]
          : [])
      ];

  // TODO: add secondValue, thirdValue and change once BE is ready (PMD-3408)
  const worthTabItems = [
    {
      label: "Dynamika Własna",
      firstValue: summary.worthSumChange,
      firstValueType: NUMBER_TYPE.PERCENT,
      secondValue: null,
      secondValueType: NUMBER_TYPE.PERCENT_POINTS,
      thirdValue: null,
      change: 0
    },
    ...(g1
      ? [
          {
            label: "Dynamika G1",
            firstValue: g1.worthSumChange,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: null,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: null,
            change: 0
          }
        ]
      : []),
    ...(g2
      ? [
          {
            label: "Dynamika G2",
            firstValue: g2.worthSumChange,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: null,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: null,
            change: 0
          }
        ]
      : []),
    ...(g3
      ? [
          {
            label: "Dynamika G3",
            firstValue: g3.worthSumChange,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: null,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: null,
            change: 0
          }
        ]
      : [])
  ].filter(item => item !== null);

  const quantityTabItems = [
    {
      label: "Dynamika Własna",
      firstValue: summary.quantitySumChange,
      firstValueType: NUMBER_TYPE.PERCENT,
      secondValue: null,
      secondValueType: NUMBER_TYPE.PERCENT_POINTS,
      thirdValue: null,
      change: 0
    },
    ...(g1
      ? [
          {
            label: "Dynamika G1",
            firstValue: g1.quantitySumChange,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: null,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: null,
            change: 0
          }
        ]
      : []),
    ...(g2
      ? [
          {
            label: "Dynamika G2",
            firstValue: g2.quantitySumChange,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: null,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: null,
            change: 0
          }
        ]
      : []),
    ...(g3
      ? [
          {
            label: "Dynamika G3",
            firstValue: g3.quantitySumChange,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: null,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: null,
            change: 0
          }
        ]
      : [])
  ];

  const naturalUnitsTabItems = [
    {
      label: "Dynamika własna jn",
      firstValue: summary.netQuantitySalesSumChange,
      firstValueType: NUMBER_TYPE.PERCENT,
      secondValue: null,
      secondValueType: NUMBER_TYPE.PERCENT_POINTS,
      thirdValue: null,
      change: 0
    },
    ...(g1
      ? [
          {
            label: "Dynamika jn w G1",
            firstValue: g1.netQuantitySalesSumChange,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: null,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: null,
            change: 0
          }
        ]
      : []),
    ...(g2
      ? [
          {
            label: "Dynamika jn w G2",
            firstValue: g2.netQuantitySalesSumChange,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: null,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: null,
            change: 0
          }
        ]
      : []),
    ...(g3
      ? [
          {
            label: "Dynamika jn w G3",
            firstValue: g3.netQuantitySalesSumChange,
            firstValueType: NUMBER_TYPE.PERCENT,
            secondValue: null,
            secondValueType: NUMBER_TYPE.PERCENT_POINTS,
            thirdValue: null,
            change: 0
          }
        ]
      : [])
  ];

  if (summaryType === SUMMARY_SWITCH.WORTH) {
    return createSummaryItemData(worthTabItems);
  }

  if (summaryType === SUMMARY_SWITCH.QUANTITY) {
    return createSummaryItemData(quantityTabItems);
  }

  if (summaryType === SUMMARY_SWITCH.DISTRIBUTION) {
    return createSummaryItemData(distributionTabItems);
  }

  if (summaryType === SUMMARY_SWITCH.NATURAL_UNITS) {
    return createSummaryItemData(naturalUnitsTabItems);
  }

  return [];
};
