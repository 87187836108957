import { useSelector } from "react-redux";

import { EXPORT_DATA_TYPE } from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";
import { AttributesHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useAttributes";
import { CountyHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCounty";
import { ShopTypeHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useShopType";
import { ShopTypeMetaHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useShopTypeMeta";
import { VoivodeshipHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVoivodeship";
import { tierSelector, userRoleSelector } from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { APP_PATHS, isThisPage, USER_ROLES } from "utils";
import { Values } from "utils/types";

const EXPORT_TYPES_SETTINGS: {
  label: string;
  exportType: Values<typeof EXPORT_DATA_TYPE>;
  tiers: number[];
  roles: Values<typeof USER_ROLES>[];
  visible: boolean;
}[] = [
  {
    label: "Ogólne",
    exportType: EXPORT_DATA_TYPE.GENERAL,
    tiers: [1, 2, 3, 4],
    roles: [
      USER_ROLES.ANALYST,
      USER_ROLES.ADMIN,
      USER_ROLES.SUPERUSER,
      USER_ROLES.CATMAN
    ],
    visible: true
  },
  {
    label: "Wg segmentów",
    exportType: EXPORT_DATA_TYPE.SEGMENTS,
    tiers: [1, 2, 3, 4],
    roles: [
      // [PMD-5245]: temporarily disable segments
      // USER_ROLES.ANALYST,
      // USER_ROLES.ADMIN,
      USER_ROLES.SUPERUSER,
      USER_ROLES.CATMAN
    ],
    visible: true
  },
  {
    label: "Wg godzin",
    exportType: EXPORT_DATA_TYPE.HOURS,
    tiers: [3, 4],
    roles: [
      USER_ROLES.ANALYST,
      USER_ROLES.ADMIN,
      USER_ROLES.SUPERUSER,
      USER_ROLES.CATMAN
    ],
    visible: true
  },
  {
    label: "Wg województw",
    exportType: EXPORT_DATA_TYPE.VOIVODESHIPS,
    tiers: [3, 4],
    roles: [
      USER_ROLES.ANALYST,
      USER_ROLES.ADMIN,
      USER_ROLES.SUPERUSER,
      USER_ROLES.CATMAN
    ],
    visible: true
  },
  {
    label: "Wg powiatów",
    exportType: EXPORT_DATA_TYPE.COUNTIES,
    tiers: [3, 4],
    roles: [
      USER_ROLES.ANALYST,
      USER_ROLES.ADMIN,
      USER_ROLES.SUPERUSER,
      USER_ROLES.CATMAN
    ],
    visible: true
  },
  {
    label: "Wg sklepów",
    exportType: EXPORT_DATA_TYPE.SHOPS,
    tiers: [4],
    roles: [
      USER_ROLES.ANALYST,
      USER_ROLES.ADMIN,
      USER_ROLES.SUPERUSER,
      USER_ROLES.CATMAN
    ],
    visible: true
  },
  {
    label: "Wg atrybutów",
    exportType: EXPORT_DATA_TYPE.ATTRIBUTES,
    tiers: [3],
    roles: [USER_ROLES.SUPERUSER, USER_ROLES.CATMAN],
    visible: true
  }
];

const useDisabledItems = () => {
  const [counties] = CountyHooks.useCounty();
  const [voivodeships] = VoivodeshipHooks.useVoivodeship();
  const [segments] = ShopTypeHooks.useShopType();
  const [metaSegments] = ShopTypeMetaHooks.useShopTypeMeta();
  const [attributes] = AttributesHooks.useSelectedAttributes();

  const pathname = useSelector(pathnameSelector);
  const isExportPage = isThisPage(pathname, APP_PATHS.EXPORT);

  if (!isExportPage) {
    return [];
  }

  const hasCounties = counties.selected.length > 0;
  const hasVoivodeships = voivodeships.selected.length > 0;
  const hasSegments = segments.selected.length > 0;
  const hasMetaSegments = metaSegments.selected.length > 0;
  const hasAttributes = attributes.length > 0;
  const disabled: {
    type: Values<typeof EXPORT_DATA_TYPE>;
    tooltip: string;
  }[] = [];

  if (hasCounties || hasVoivodeships) {
    disabled.push({
      type: EXPORT_DATA_TYPE.SEGMENTS,
      tooltip: "Opcja dostępna bez określonego województwa i/lub powiatu"
    });
  }

  if (hasSegments || hasMetaSegments) {
    disabled.push(
      {
        type: EXPORT_DATA_TYPE.VOIVODESHIPS,
        tooltip: "Opcja dostępna bez określonych segmentów"
      },
      {
        type: EXPORT_DATA_TYPE.COUNTIES,
        tooltip: "Opcja dostępna bez określonych segmentów"
      }
    );
  }

  if (!hasAttributes) {
    disabled.push({
      type: EXPORT_DATA_TYPE.ATTRIBUTES,
      tooltip: "Opcja dostępna po wybraniu atrybutów"
    });
  }

  return disabled;
};

export const useButtons = (isFilterDisabled: boolean) => {
  const tier = useSelector(tierSelector);
  const role = useSelector(userRoleSelector);
  const disabledItems = useDisabledItems();

  return EXPORT_TYPES_SETTINGS.filter(settings => {
    if (!settings.visible) {
      return false;
    }
    // [PMD-5026]: unless option is visible, then it should be available for power users, regardless of tier
    if (role === USER_ROLES.SUPERUSER || role === USER_ROLES.CATMAN) {
      return true;
    }
    return settings.tiers.includes(tier) && settings.roles.includes(role);
  }).map(settings => {
    const disabled = disabledItems.find(item => {
      return item.type === settings.exportType;
    });
    return {
      label: settings.label,
      exportType: settings.exportType,
      isDisabled: isFilterDisabled || disabled?.type === settings.exportType,
      tooltipText: isFilterDisabled ? "" : disabled?.tooltip || ""
    };
  });
};
