import * as React from "react";
import { useSelector } from "react-redux";

import { ChartData, ChartDataPart } from "components/D3/types";
import { isRestrictedToOneLineSelector } from "components/molecules/ChartTypeToggle/hooks";
import { LinesGroup } from "pages/Reports/partials/Chart/StandardChart/LinesGroup";
import { useChartScales } from "pages/Reports/partials/Chart/StandardChart/utils";
import { hoveredLineSelector } from "pages/Reports/redux/reducers/chartReducer";
import { Nullable } from "utils/types";

type Props = {
  showLineChart: boolean;
  data: Nullable<ChartDataPart>;
  isOneTimepoint: boolean;
  lineChart: ChartData;
  width: number;
};

export const LinesChart: React.FC<Props> = ({
  showLineChart,
  data,
  isOneTimepoint,
  lineChart,
  width
}) => {
  const hoveredLine = useSelector(hoveredLineSelector);
  const isRestrictedToOneLine = useSelector(isRestrictedToOneLineSelector);
  const { xScaleBand, yScale } = useChartScales(lineChart, width);
  const isHistoricalGroupedChart = lineChart.isHistoricalGroupedChart;

  if (!showLineChart || isRestrictedToOneLine) return null;

  return (
    <LinesGroup
      xScale={xScaleBand}
      yScale={yScale}
      data={data}
      hoveredLine={hoveredLine}
      isOneTimepoint={isOneTimepoint}
      isHistoricalGroupedChart={isHistoricalGroupedChart}
    />
  );
};
