import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  RESIZE_WINDOW,
  SET_ERROR_MESSAGE,
  SET_GENERIC_ALERT_MESSAGE,
  SET_SUCCESS_MESSAGE,
  TOGGLE_CHART_SIDEBAR,
  TOGGLE_FILTER_SIDEBAR,
  TOGGLE_SEGMENTS_LEGEND,
  UPDATE_CHART_SIDEBAR_WIDTH,
  UPDATE_CHART_WIDTH,
  UPDATE_ITEMS_PER_CHART
} from "store/actions/appActions";
import { AppActionTypes, AppStateTypes } from "store/types/appTypes";
import { Values } from "utils/types";

export const CHART_SIDEBAR_MIN_WIDTH = "25%";

const initialState: AppStateTypes = {
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  homepageScrollingDown: false,
  errorMessage: "",
  successMessage: "",
  isFilterSidebarOpen: true,
  isSegmentsLegendOpen: true,
  isChartSidebarOpen:
    window.location.pathname !== REPORTS_FULL_PATHS.SEGMENTS_PATH, // [PMD-3186]: by default sidebar on segments report should be closed
  genericAlertMessage: "",
  itemsPerChart: 10,
  chartSidebarWidth: CHART_SIDEBAR_MIN_WIDTH,
  chartWidth: 0
};

export const appReducer = (
  state = initialState,
  action: Values<AppActionTypes>
): AppStateTypes => {
  switch (action.type) {
    case RESIZE_WINDOW:
      return {
        ...state,
        ...action.payload
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: ""
      };
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: action.payload,
        errorMessage: ""
      };
    case TOGGLE_FILTER_SIDEBAR:
      return {
        ...state,
        isFilterSidebarOpen: action.payload
      };
    case TOGGLE_CHART_SIDEBAR:
      return {
        ...state,
        isChartSidebarOpen: action.payload
      };
    case TOGGLE_SEGMENTS_LEGEND:
      return {
        ...state,
        isSegmentsLegendOpen: action.payload
      };
    case SET_GENERIC_ALERT_MESSAGE:
      return {
        ...state,
        genericAlertMessage: action.payload
      };
    case UPDATE_ITEMS_PER_CHART:
      return { ...state, itemsPerChart: action.payload };
    case UPDATE_CHART_SIDEBAR_WIDTH:
      return { ...state, chartSidebarWidth: action.payload };
    case UPDATE_CHART_WIDTH:
      return { ...state, chartWidth: action.payload };
    default:
      return state;
  }
};
