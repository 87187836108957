import * as React from "react";

interface Props {
  children: ({ x, y }: { x: number; y: number }) => React.ReactElement;
}

export const Cursor = ({ children }: Props) => {
  const [x, setX] = React.useState(0);
  const [y, setY] = React.useState(0);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    setX(e.pageX);
    setY(e.pageY);
  };

  return <div onMouseMove={handleMouseMove}>{children({ x, y })}</div>;
};
