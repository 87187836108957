import React from "react";

import { Alert } from "components/molecules";

import s from "./successAlert.module.scss";

type Props = {
  children: React.ReactNode;
  onClose: () => void;
};

export const SuccessAlert = ({ children, onClose }: Props) => {
  return (
    <Alert
      className={{
        alert: s.successAlert,
        text: s.successInterior,
        icon: s.closeIcon
      }}
      onClose={onClose}
    >
      {children}
    </Alert>
  );
};
