import { useSelector } from "react-redux";

import { useGetLocationByPeriod } from "api/location/mappers/useGetLocationByPeriod";
import { getChartData } from "api/location/utils/getChartData";
import { getLocationChartUniqData } from "api/location/utils/getLocationChartUniqData";

import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useBarChartSorting } from "hooks/useBarChartSorting";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import {
  hiddenLinesSelector,
  pinnedTimepointSelector
} from "pages/Reports/redux/reducers/chartReducer";
import {
  LocationChartUniqRawData,
  LocationLineChart
} from "pages/Reports/sections/Location/redux/types/locationChartTypes";
import { checkedDataTypes } from "pages/Reports/sections/utils/chartData";
import { ChartDataTypes } from "pages/Reports/types/chart";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { CHART_DATA_TYPE } from "utils";
import { Nullable } from "utils/types";

interface LocationGridChartData {
  allLines: LocationLineChart[];
  visibleLines: LocationLineChart[];
}

const INITIAL_DATA: LocationGridChartData = {
  allLines: [],
  visibleLines: []
};

const prepareData = (
  dataType: ChartDataTypes,
  data: LocationChartUniqRawData[],
  hiddenLines: string[],
  dataset: { base: boolean; historical: boolean; both: boolean },
  isForHistoricalChart: boolean,
  lastYearDomainLength: number,
  isTimelineMerged: boolean,
  sorting: { key: string; desc: boolean; timepoint: Nullable<number> }
) => {
  if (dataType === CHART_DATA_TYPE.NONE) {
    return INITIAL_DATA;
  }

  const allLines = data.reduce<LocationLineChart[]>(
    (charts, { label, data, type }, index, vals) => {
      const firstColorIndex = vals
        .slice(0, index)
        .reduce<number>((result, { data }) => data.length + result, 0);

      const currentCharts = data.map(
        getChartData(
          label,
          firstColorIndex,
          dataType,
          type,
          dataset,
          isForHistoricalChart,
          lastYearDomainLength,
          isTimelineMerged
        )
      );

      return [...charts, ...currentCharts];
    },
    []
  );

  // [PMD-4770]: bar chart sorting implementation differs for location report due to different data structure
  if (sorting.timepoint !== null) {
    allLines.sort((a, b) => {
      let datasetA = a.timeline;
      let datasetB = b.timeline;
      if (sorting.key.endsWith("_LAST_YEAR")) {
        datasetA = a.lastYearTimeline || [{ valueX: "", valueY: null }];
        datasetB = b.lastYearTimeline || [{ valueX: "", valueY: null }];
      } else if (sorting.key.endsWith("_CHANGE")) {
        datasetA = a.change;
        datasetB = b.change;
      } else if (sorting.key.endsWith("_CHANGE_PERCENTAGE")) {
        datasetA = a.changePercentage;
        datasetB = b.changePercentage;
      }
      const valueA = datasetA[sorting.timepoint!].valueY || 0;
      const valueB = datasetB[sorting.timepoint!].valueY || 0;
      return sorting.desc ? valueB - valueA : valueA - valueB;
    });
  }

  const visibleLines = allLines.filter(
    line => !hiddenLines.includes(`${line.chartName} - ${line.label}`)
  );

  return {
    allLines,
    visibleLines
  };
};

export const useLocationGridChartData = ({
  isForHistoricalChart,
  lastYearDomainLength,
  showBarChart,
  isTimelineMerged
}: {
  isForHistoricalChart: boolean;
  lastYearDomainLength: number;
  showBarChart: boolean;
  isTimelineMerged: boolean;
}): {
  left: LocationGridChartData;
  right: LocationGridChartData;
} => {
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const hiddenLines = useSelector(hiddenLinesSelector);
  const pinnedTimepoint = useSelector(pinnedTimepointSelector);
  const dataset = useCurrentDataset();
  const { isGroupedBarChart } = useChartTypeBoolean();
  const barChartSorting = useBarChartSorting();

  const { data } = useGetLocationByPeriod();

  if (!data) {
    return {
      left: INITIAL_DATA,
      right: INITIAL_DATA
    };
  }

  const uniqData = data.data.data.reduce(getLocationChartUniqData, []);
  const sorting = {
    key: barChartSorting.dataKey,
    desc: barChartSorting.isDesc,
    timepoint: barChartSorting.getSortingTimepoint({
      dataTypes: checkedDataTypes([firstChartType, secondChartType]),
      showBarChart,
      isGroupedBarChart,
      pinnedTimepoint
    })
  };

  return {
    left: prepareData(
      firstChartType,
      uniqData,
      hiddenLines.location,
      dataset,
      isForHistoricalChart,
      lastYearDomainLength,
      isTimelineMerged,
      sorting
    ),
    right: prepareData(
      secondChartType,
      uniqData,
      hiddenLines.location,
      dataset,
      isForHistoricalChart,
      lastYearDomainLength,
      isTimelineMerged,
      sorting
    )
  };
};
