import React, { Dispatch, SetStateAction, useState } from "react";

import { Button, Text } from "components/atoms";
import { ChevronRight } from "components/atoms/Icon";
import { Checkbox } from "components/molecules";
import { APP_NAME, ICON_SIZES, IS_PRELOGIN_INFO_HIDDEN } from "utils";

import s from "./prelogin.module.scss";

type Props = {
  onSubmit: Dispatch<SetStateAction<boolean>>;
};

export const PreloginInfo = ({ onSubmit }: Props) => {
  const [wontShowInfoAgain, setInfoDisplay] = useState(false);

  const handleInfoDisplaySet = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setInfoDisplay(true);
      localStorage.setItem(IS_PRELOGIN_INFO_HIDDEN, "true");
    } else {
      setInfoDisplay(false);
      localStorage.removeItem(IS_PRELOGIN_INFO_HIDDEN);
    }
  };

  return (
    <>
      <div className={s.textWrapper}>
        <Text className={s.regularText}>Zalogowanie do platformy</Text>
        <Text className={s.regularText}>Żabka {APP_NAME}</Text>
        <Text className={s.regularText}>
          jest możliwe po przejściu na stronę
        </Text>
        <Text className={s.regularText}>microsoftonline.com</Text>
      </div>
      <Checkbox
        isChecked={wontShowInfoAgain}
        label="Nie pokazuj więcej tego komunikatu"
        onChange={handleInfoDisplaySet}
      />
      <Button
        onClick={() => onSubmit(false)}
        type="button"
        className={s.button}
      >
        Zaloguj się w Żabka ACIT
        <ChevronRight size={ICON_SIZES.LARGE} className={s.chevronRightIcon} />
      </Button>
    </>
  );
};
