export const createDataTypeMap = (
  chartDataType: string,
  objectKey: string,
  withShares: boolean = false
) => {
  const base = {
    [chartDataType]: objectKey,
    [`${chartDataType}_CHANGE`]: `${objectKey}Change`,
    [`${chartDataType}_CHANGE_PERCENTAGE`]: `${objectKey}ChangePercentage`,
    [`${chartDataType}_LAST_YEAR`]: `${objectKey}LastYear`,

    [`${chartDataType}_OFFLINE`]: `${objectKey}Offline`,
    [`${chartDataType}_OFFLINE_CHANGE`]: `${objectKey}OfflineChange`,
    [`${chartDataType}_OFFLINE_CHANGE_PERCENTAGE`]: `${objectKey}OfflineChangePercentage`,
    [`${chartDataType}_OFFLINE_LAST_YEAR`]: `${objectKey}OfflineLastYear`,

    [`${chartDataType}_ONLINE`]: `${objectKey}Online`,
    [`${chartDataType}_ONLINE_CHANGE`]: `${objectKey}OnlineChange`,
    [`${chartDataType}_ONLINE_CHANGE_PERCENTAGE`]: `${objectKey}OnlineChangePercentage`,
    [`${chartDataType}_ONLINE_LAST_YEAR`]: `${objectKey}OnlineLastYear`
  };

  if (withShares) {
    Object.assign(base, {
      [`${chartDataType}_ONLINE_SHARE`]: `${objectKey}OnlineShare`,
      [`${chartDataType}_ONLINE_SHARE_CHANGE`]: `${objectKey}OnlineShareChange`,
      [`${chartDataType}_ONLINE_SHARE_CHANGE_PERCENTAGE`]: `${objectKey}OnlineShareChangePercentage`,
      [`${chartDataType}_ONLINE_SHARE_LAST_YEAR`]: `${objectKey}OnlineShareLastYear`
    });
  }

  return base;
};
