import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useFiltersFetchingParams } from "pages/Reports/redux/reducers/filters/hooks/useFiltersFetchingParams";
import {
  CompetingProductsHooks,
  sortByName
} from "pages/Reports/redux/reducers/sweetStateHooks/useCompetingProducts";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { competitionProductsQueryParamSelector } from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";

type CompetitorProductRequestParams = {
  competing_materials: boolean;
  categories: string;
  category_level: string;
  companies: string | string[];
  vendor_id: string;
  brands: string;
  sub_brands: string;
};

const useCompetitorFilterParams = (): CompetitorProductRequestParams => {
  const params = useFiltersFetchingParams([
    "category",
    "categoryLevel",
    "vendor",
    "competitorCompany",
    "competitorBrand",
    "competitorSubBrand",
    "attributes"
  ]);

  const base: CompetitorProductRequestParams = {
    competing_materials: true,
    categories: params.category,
    category_level: params.categoryLevel,
    vendor_id: params.vendor,
    companies: params.competitorCompany,
    brands: params.competitorBrand,
    sub_brands: params.competitorSubBrand
  };

  if (params.attributes) {
    Object.assign(base, { attributes: params.attributes });
  }

  return base;
};

const useFetchingFlag = (
  params: {
    categories: string;
    category_level: string;
    vendor_id: string;
  },
  isDisabled: boolean
) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching
    .competitorProducts;
  const lastQuery = useSelector(asyncFiltersSelector).query.competitorProducts;
  const isPowerUser = useSelector(isPowerUserSelector);
  const query = qs.stringify(params);

  if (isLoading || isDisabled) {
    return;
  }

  if (query === lastQuery) {
    return;
  }

  if (!params.categories || !params.category_level) {
    return;
  }

  if (isPowerUser && !params.vendor_id) {
    return;
  }
  return true;
};

export const useCompetitionProductBehaviour = (isDisabled: boolean) => {
  const dispatch = useDispatch();

  const params = useCompetitorFilterParams();
  const isFetchingPossible = useFetchingFlag(params, isDisabled);
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const productsQP = useSelector(competitionProductsQueryParamSelector);
  const [state, actions] = CompetingProductsHooks.useCompetingProducts();

  useEffect(() => {
    if (!isFetchingPossible) {
      return;
    }

    const fetch = async () => {
      const filter = "competitorProducts";
      try {
        dispatch(updateQuery({ filter, query: qs.stringify(params) }));
        dispatch(setFlag({ filter, isFetching: true }));
        const response = await HTTP.get<
          { display: string; material: number }[]
        >(REST_API_ENDPOINTS.PRODUCTS, { params });
        const items = response.data
          .map(item => ({ label: item.display, value: item.material }))
          .sort((a, b) => sortByName(a.label, b.label, "regular"));
        actions.updateAll(items);
        let found: typeof items = [];
        if (isPristine) {
          found = items.filter(item =>
            productsQP.split(",").includes(String(item.value))
          );
        } else {
          found = items.filter(item =>
            state.selected.some(i => i.value === item.value)
          );
        }
        actions.updateSelected(found);
        dispatch(setError({ filter, status: "" }));
      } catch (e) {
        dispatch(setError({ filter, status: "Błąd pobierania produktów" }));
        return [];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };

    fetch();
  }, [
    actions,
    dispatch,
    isFetchingPossible,
    isPristine,
    params,
    productsQP,
    state.selected
  ]);
};
