import { useQueries } from "react-query";
import { useLocation } from "react-router-dom";

import { QueryKey } from "api/consts";
import { locationServices } from "api/location/services";
import { useFetchParams } from "api/utils";

import { REPORTS_PATHS } from "pages/Reports/utils";
import { isThisPage } from "utils";

export const useGetLocation = () => {
  const { queryKeys, isG1Selected } = useFetchParams();

  const { pathname } = useLocation();
  const isLocationPage = isThisPage(pathname, REPORTS_PATHS.LOCATION);

  const queries = useQueries(
    queryKeys.map(({ period, query }) => {
      return {
        queryKey: [QueryKey.LOCATION_DATA, query],
        queryFn: () => locationServices.fetchLocation(query, period),
        enabled: isG1Selected && isLocationPage,
        retry: false
      };
    })
  );

  const isLoading = queries.some(query => query.isLoading);
  const isError = queries.some(query => query.isError);
  const isIdle = queries.some(query => query.isIdle);

  const returnValue = {
    isLoading,
    isError,
    isIdle,
    queriesData: queries.map(query => query.data)
  };

  return returnValue;
};
