import { useSelector } from "react-redux";

import { asyncFiltersSelector } from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useSelectedFilters } from "pages/Reports/redux/reducers/filters/filtersReducer";
import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { useVisibleList } from "pages/Reports/redux/reducers/filters/hooks/useVisibleFilters";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { queryParamsSelector } from "store/selectors/routerSelectors";
import { QP } from "utils/defaultQueryParams";

import { isAttributesAggregationEnabled, pickValue } from "./utils";

// get filter value from qp or state depending on it's fetching state
export const useFiltersState = () => {
  const params = useSelector(queryParamsSelector);
  const { query, fetching } = useSelector(asyncFiltersSelector);
  const selected = useSelectedFilters();

  const company = pickValue({
    query: query.company || "",
    fetching: fetching.company,
    param: String(params[QP.VENDOR_ID] || ""),
    state: selected.vendor_id
  });
  const attributes = pickValue({
    query: query.attributes || "",
    fetching: fetching.attributes,
    param: String(params[QP.ATTRIBUTES] || ""),
    state: selected.attributes
  });
  const brands = pickValue({
    query: query.brand || "",
    fetching: fetching.brand,
    param: String(params[QP.BRANDS] || ""),
    state: selected.brands
  });
  const subBrands = pickValue({
    query: query.subBrand || "",
    fetching: fetching.subBrand,
    param: String(params[QP.SUB_BRANDS] || ""),
    state: selected.sub_brands
  });
  const products = pickValue({
    query: query.products || "",
    fetching: fetching.products,
    param: String(params[QP.PRODUCT] || ""),
    state: selected.product
  });
  const competingCompanies = pickValue({
    query: query.competitorCompanies || "",
    fetching: fetching.competitorCompanies,
    param: String(params[QP.COMPETING_COMPANIES] || ""),
    state: selected.competing_companies
  });
  const competingProducts = pickValue({
    query: query.competitorProducts || "",
    fetching: fetching.competitorProducts,
    param: String(params[QP.COMPETING_MATERIALS] || ""),
    state: selected.competing_materials
  });
  const competingBrands = pickValue({
    query: query.competingBrand || "",
    fetching: fetching.competingBrand,
    param: String(params[QP.COMPETING_BRANDS] || ""),
    state: selected.competing_brands
  });
  const competingSubBrands = pickValue({
    query: query.competingSubBrand || "",
    fetching: fetching.competingSubBrand,
    param: String(params[QP.COMPETING_SUB_BRANDS] || ""),
    state: selected.competing_sub_brands
  });

  return {
    company,
    attributes,
    brands,
    subBrands,
    products,
    competingCompanies,
    competingProducts,
    competingBrands,
    competingSubBrands
  };
};

export const useAttributesState = () => {
  const isPowerUser = useSelector(isPowerUserSelector);
  const visibleFilters = useVisibleList();
  const filtersState = useFiltersState();

  return {
    visible: Boolean(
      visibleFilters.find(filter => filter.name === FILTER_NAMES.ATTRIBUTES)
    ),
    enabled: isAttributesAggregationEnabled({ isPowerUser, filtersState })
  };
};
