import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "components/molecules";
import { updateQueryParams } from "store/actions/routerActions";
import { isSidebarChangeSelector } from "store/selectors/routerSelectors";
import { QP } from "utils/defaultQueryParams";

import s from "./showSidebarChange.module.scss";

export const ShowSidebarChange: FC = () => {
  const dispatch = useDispatch();
  const isSidebarChangeVisible = useSelector(isSidebarChangeSelector);

  const onChange = () => {
    dispatch(
      updateQueryParams({
        [QP.SIDEBAR_CHANGE]: String(!isSidebarChangeVisible)
      })
    );
  };

  return (
    <Checkbox
      className={{ checkbox: s.checkbox, label: s.label }}
      label="Pokaż/ukryj zmianę"
      onChange={onChange}
      isChecked={isSidebarChangeVisible}
      testId="toggle-change-sidebar-chart-checkbox"
    />
  );
};
