import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import { FilterSidebar } from "components/organisms";
import { ReportsFilterForm } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/ReportsFilterForm";
import { toggleFilterSidebar } from "store/actions/appActions";
import { isFilterSidebarOpenSelector } from "store/selectors/appSelectors";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { APP_PATHS, isThisPage } from "utils";

import s from "./layoutSidebar.module.scss";

interface Props {
  children: ReactNode;
}

export const LayoutSidebar = ({ children }: Props) => {
  const dispatch = useDispatch();
  const pathname = useSelector(pathnameSelector);
  const isOpen = useSelector(isFilterSidebarOpenSelector);

  const isReports = isThisPage(pathname, APP_PATHS.REPORTS);

  const toggleOpen = () => {
    dispatch(toggleFilterSidebar(!isOpen));

    // Trigger resize event after filters animation (150ms) to recalculate chart width
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 160);
  };

  return (
    <div
      className={cn({
        [s.base]: true,
        [s.shrinkedBase]: isOpen,
        [s.offsetBase]: isReports
      })}
    >
      <div className={s.layout}>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.45 } }}
              exit={{ opacity: 0 }}
              className={s.overlay}
            />
          )}
        </AnimatePresence>
        <FilterSidebar
          isOpen={isOpen}
          onToggleOpen={toggleOpen}
          withOffset={isReports}
        >
          <ReportsFilterForm />
        </FilterSidebar>
        {children}
      </div>
    </div>
  );
};
