import { isAfter, isBefore, isSameDay, subDays } from "date-fns";
import { ParsedQuery } from "query-string";

import { getDateRangeMonthBoundaries } from "components/organisms/DateDropdown/utils";
import { newDateByTimezone } from "store/utils";
import { DATE_FROM_RESTRICTION, DATE_QUERY_PARAM_REGEXP } from "utils";
import { Tier } from "utils/types";

type DateQueryParam = string | string[] | null | undefined;

const isDateQueryParamFormatValid = (date?: DateQueryParam) =>
  !!date && !Array.isArray(date) && DATE_QUERY_PARAM_REGEXP.test(date);

const isDateQueryParamInAvailablePeriod = (date: string) => {
  const parsed = newDateByTimezone(date);

  return (
    !isAfter(parsed, subDays(newDateByTimezone(), 1)) &&
    !isBefore(parsed, newDateByTimezone(DATE_FROM_RESTRICTION))
  );
};

export const isDateQueryParamsRelationValid = (
  dateFrom: string,
  dateTo: string
) => {
  const parsedDateFrom = newDateByTimezone(dateFrom);
  const parsedDateTo = newDateByTimezone(dateTo);

  return !isAfter(parsedDateFrom, parsedDateTo);
};

export const isDateQueryParamValid = (date: DateQueryParam) =>
  isDateQueryParamFormatValid(date) &&
  isDateQueryParamInAvailablePeriod(String(date));

export const appendRequiredParams = (
  baseObject: ParsedQuery<string | string[]>,
  fallbacksObject: ParsedQuery<string | string[]>
): ParsedQuery =>
  Object.entries(fallbacksObject).reduce(
    (requiredValuesOrFallbacks, [key, fallbackValue]) => {
      // if key exists in object, we should not change it to default value even if it's falsy value ("", undefined, 0 etc.)
      const value = key in baseObject ? baseObject[key] : fallbackValue;
      return {
        ...requiredValuesOrFallbacks,
        [key]: value
      };
    },
    {}
  );

export const isDateMonthBoundaries = (
  dateFrom: Date,
  dateTo: Date,
  tier: Tier
) => {
  const {
    dateFrom: monthBoundaryStart,
    dateTo: monthBoundaryEnd
  } = getDateRangeMonthBoundaries(dateFrom, dateTo);

  return (
    isSameDay(dateFrom, newDateByTimezone(monthBoundaryStart)) &&
    isSameDay(dateTo, newDateByTimezone(monthBoundaryEnd))
  );
};
