import { appendInsightId } from "pages/Insights/partials/InsightsBox/utils";
import {
  getStandarizedDataset,
  standarizeLocation
} from "pages/Reports/sections/Location/redux/actions/locationRawActions";
import { LocationApi } from "pages/Reports/sections/Location/redux/types/locationRawTypes";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { pushReportFetchEvent } from "utils/googleTagManager/dataLayer";
import { Periods } from "utils/types";

export const locationServices = {
  fetchLocation: async (queryString: string, period: Periods) => {
    const url = appendInsightId(
      `${REST_API_ENDPOINTS.REPORTS.SALES_BY_LOCATION}?${queryString}`
    );
    const { data } = await HTTP.get<LocationApi>(url);

    const standardizedData = getStandarizedDataset(
      queryString,
      standarizeLocation(data),
      period
    );

    pushReportFetchEvent("Lokalizacja");

    return standardizedData;
  }
};
