import { useState } from "react";

import cn from "classnames";

import { Button } from "components/atoms";

import s from "./attributesHierarchy.module.scss";
import { AttributesHierarchyModal } from "./AttributesHierarchyModal";

export const AttributesHierarchy = ({
  isDisabled
}: {
  isDisabled: boolean;
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button
        type="button"
        test-id="show-attributes-hierarchy-button"
        className={cn(s.button, { [s.disabled]: isDisabled })}
        onClick={isDisabled ? undefined : () => setOpen(true)}
      >
        Pokaż atrybuty
      </Button>
      {isOpen && <AttributesHierarchyModal onClose={() => setOpen(false)} />}
    </>
  );
};
