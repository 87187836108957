import { Hours, HoursDataApi } from "pages/Reports/sections/Hours/types";

const mapHoursWeekDayNames = (name: string) => {
  switch (name) {
    case "Poniedziałek":
      return "Pon.";
    case "Wtorek":
      return "Wt.";
    case "Środa":
      return "Śr.";
    case "Czwartek":
      return "Czw.";
    case "Piątek":
      return "Pt.";
    case "Sobota":
      return "Sob.";
    case "Niedziela":
      return "Niedz.";
    default:
      return name;
  }
};

export const normalizeHoursResponse = (data: HoursDataApi[]): Hours[] =>
  data.map(array => {
    return {
      vendorId: array.vendor_id,
      resultId: array.result_id,
      type: array.type,
      timeline: array.timeline.map(time => ({
        date: time.date,
        summary: time.summary,
        summaryPercent: time.summary_percent,
        quantitySummary: time.quantity_summary,
        quantitySummaryPercent: time.quantity_summary_percent,
        hours: time.hours.map(hour => ({
          hour: hour.hour,
          value: hour.value,
          valuePercent: hour.value_percent,
          quantitySum: hour.quantity_sum,
          quantitySumPercent: hour.quantity_sum_percent
        }))
      })),
      timelineSummary: {
        summary: array.timeline_summary.summary,
        hours: array.timeline_summary.hours.map(hour => ({
          hour: hour.hour,
          value: hour.value,
          valuePercent: hour.value_percent,
          quantitySum: hour.quantity_sum,
          quantitySumPercent: hour.quantity_sum_percent
        }))
      },
      weekTimeline: array.week_timeline.map(time => ({
        day: mapHoursWeekDayNames(time.day),
        summary: time.summary,
        quantitySummary: time.quantity_summary,
        quantitySummaryPercent: time.quantity_summary_percent,
        summaryPercent: time.summary_percent,
        hours: time.hours.map(hour => ({
          hour: hour.hour,
          value: hour.value,
          valuePercent: hour.value_percent,
          quantitySum: hour.quantity_sum,
          quantitySumPercent: hour.quantity_sum_percent
        }))
      }))
    };
  });
