import { useMemo } from "react";
import { useSelector } from "react-redux";

import { TooltipContent } from "api/types";
import cn from "classnames";

import { Text } from "components/atoms";
import { TooltipIcon } from "components/atoms/Icon";
import c from "components/organisms/ChartSidebar/chartSidebar.module.scss";
import {
  PromotionPeriod,
  PromotionTableData
} from "pages/Reports/redux/reducers/promotionsReducer";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage } from "utils";
import { Nullable } from "utils/types";

import { CompetingMaterials } from "./CompetingMaterials";
import { OwnMaterials } from "./OwnMaterials";
import s from "./promotionContent.module.scss";

interface Props {
  sidebarData: Nullable<TooltipContent>;
  info: string[];
}

const { MULTIPACK_PATH } = REPORTS_FULL_PATHS;

const PromotionsSection = ({ data }: { data: PromotionTableData[] }) => {
  const pathname = useSelector(pathnameSelector);
  const isPowerUser = useSelector(isPowerUserSelector);

  return (
    <div className={s.promotionsSection}>
      {data.map((promotion, index) => {
        const showDesc = (() => {
          if (!promotion.promotionDesc) return false;
          if (isThisPage(pathname, MULTIPACK_PATH)) return true;
          return isPowerUser;
        })();

        return (
          <div className={s.promotionWrapper} key={`promotion-${index}`}>
            <div className={s.promotionHeader}>
              <Text className={s.promotionName}>{promotion.promotionName}</Text>
              {showDesc && (
                <Text className={s.promotionDesc}>
                  {promotion.promotionDesc}
                </Text>
              )}
            </div>
            <OwnMaterials materials={promotion.ownMaterials} />
            <CompetingMaterials materials={promotion.competingMaterials} />
          </div>
        );
      })}
    </div>
  );
};

export const PromotionContent = ({ sidebarData, info }: Props) => {
  const promotions = useMemo(() => {
    const data = sidebarData?.promotions || [];
    return {
      base: data.filter(({ period }) => period === PromotionPeriod.BASE),
      historical: data.filter(
        ({ period }) => period === PromotionPeriod.HISTORICAL
      )
    };
  }, [sidebarData]);

  const hasPromotions =
    promotions.base.length > 0 || promotions.historical.length > 0;

  return (
    <div className={s.promotionContent}>
      {hasPromotions ? (
        <>
          {promotions.base.length > 0 && (
            <PromotionsSection data={promotions.base} />
          )}
          {promotions.historical.length > 0 && (
            <PromotionsSection data={promotions.historical} />
          )}
        </>
      ) : (
        <div className={cn(c.chartSidebarPreviewWrapper, s.noPaddingTop)}>
          {info.map(info => (
            <div className={c.previewInfoWrapper} key={info}>
              <TooltipIcon className={c.tooltipIcon} />
              <Text className={c.previewText}>{info}</Text>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
