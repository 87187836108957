import { appendInsightId } from "pages/Insights/partials/InsightsBox/utils";
import {
  ReceiptsApi,
  ReceiptsDataset
} from "pages/Reports/sections/Receipts/redux/types";
import { getOwnSummary, getReferenceSummary } from "pages/Reports/utils";
import { createDatasetObject } from "pages/Reports/utils/createDatasetObject";
import { normalizeReceiptsResponse } from "pages/Reports/utils/normalize/normalizeReceiptsResponse";
import { normalizeReceiptsSummary } from "pages/Reports/utils/normalize/normalizeReceiptsSummary";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { pushReportFetchEvent } from "utils/googleTagManager/dataLayer";
import { Periods } from "utils/types";

export const receiptsServices = {
  fetchReceipts: async (queryString: string, period: Periods) => {
    const url = appendInsightId(
      `${REST_API_ENDPOINTS.REPORTS.RECEIPTS}?${queryString}`
    );
    const { data } = await HTTP.get<ReceiptsApi>(url);

    const ownSummary = getOwnSummary(data.summary);
    const referenceSummary = getReferenceSummary(data.summary);
    const standardizedData: ReceiptsDataset = createDatasetObject({
      query: queryString,
      currentPeriod: normalizeReceiptsResponse(data.data),
      ownSummary: normalizeReceiptsSummary(ownSummary),
      referenceSummary: referenceSummary
        ? normalizeReceiptsSummary(referenceSummary)
        : null,
      periodType: period,
      info: data.info
    });

    pushReportFetchEvent("Paragony");

    return standardizedData;
  }
};
