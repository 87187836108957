import * as React from "react";

import s from "components/common.module.scss";
import { isFirefox } from "utils";
import { isSafari } from "utils/isSafari";

interface Props {
  height: number;
  text: string;
  left: number;
  smallerOffset: boolean;
}

export const LeftLabel: React.FC<Props> = ({
  height,
  text,
  left,
  smallerOffset
}) => {
  const leftOffset = smallerOffset ? left + 20 : left;

  const y = isSafari || isFirefox() ? leftOffset + 16 : leftOffset;
  return (
    <g>
      <text
        dy={"1rem"}
        x={height}
        y={y}
        transform={"rotate(270)"}
        textAnchor="middle"
        className={s.axisLabel}
        data-testid="left-axis-label"
      >
        {text}
      </text>
    </g>
  );
};
