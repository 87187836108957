import { comparator, lt, sort } from "ramda";

import { DropdownItem } from "components/molecules/types";

import { getSortedUniqProducts } from "../utils";

const byLabel = comparator<DropdownItem>((a, b) =>
  lt(a.label.localeCompare(b.label, "pl"), 0)
);

const splitItems = <T extends DropdownItem>(
  ids: (string | number)[],
  items: T[]
) =>
  items.reduce(
    (splittedItemsTuple: [T[], T[]], item) => {
      const isActive = ids.includes(item.value);

      splittedItemsTuple[isActive ? 0 : 1].push(item);
      return splittedItemsTuple;
    },
    [[], []]
  );

export const sortItemsBySelectionOrder = <T extends DropdownItem>(
  ids: (string | number)[],
  items: T[]
) => {
  const [selectedItems, unselectedItems] = splitItems<T>(ids, items);
  return [...sort(byLabel, selectedItems), ...sort(byLabel, unselectedItems)];
};

export const sortProductsBySelectionOrder = <T extends DropdownItem>(
  ids: (string | number)[],
  items: T[]
) => {
  const [selectedProducts, unselectedProducts] = splitItems<T>(ids, items);

  return [
    ...getSortedUniqProducts(selectedProducts),
    ...getSortedUniqProducts(unselectedProducts)
  ];
};
