import React, { ReactNode } from "react";

import cn from "classnames";

import { Button } from "components/atoms";
import { PopupWindow } from "components/molecules";

import s from "./centeredAlert.module.scss";

interface Props {
  onClose: () => void;
  genericAlertMessage: ReactNode;
}

export const CenteredAlert = ({ onClose, genericAlertMessage }: Props) => (
  <PopupWindow>
    <div className={s.popupWindow}>
      <div className={s.popupContent}>
        <span className={cn(s.centeredText, s.mainText)}>
          {genericAlertMessage}
        </span>
        <Button onClick={() => onClose()} className={s.button}>
          OK
        </Button>
      </div>
    </div>
  </PopupWindow>
);
