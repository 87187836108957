import { RESET_DATA, resetData } from "store/actions/appActions";

import {
  CategoryItemsActions,
  UPDATE_CATEGORIES_ERROR_MSG
} from "./categoryFiltersActions";
import { initialCategoryFiltersState as initialState } from "./categoryFiltersSelectors";

// TYPES
export type CategoryItemApi = {
  id?: number;
  name?: string;
  level?: number;
  parent?: CategoryItemApi;
  start_date?: string;
  is_fake?: boolean;
};

export type CategoryItemsState = {
  errorMessage: string;
};

// REDUCER
export const categoryFiltersReducer = (
  state = initialState,
  action: CategoryItemsActions | ReturnType<typeof resetData>
) => {
  switch (action.type) {
    case UPDATE_CATEGORIES_ERROR_MSG:
      return {
        ...state,
        errorMessage: action.payload
      };

    case RESET_DATA:
      return initialState;

    default:
      return state;
  }
};
