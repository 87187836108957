import { getLocationChartUniqData } from "api/location/utils/getLocationChartUniqData";
import { getMergedTimelineDetails } from "api/utils";

import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { LocationRawDataset } from "pages/Reports/sections/Location/redux/types/locationRawTypes";
import {
  findDateDomain,
  findLastYearDomainLength
} from "pages/Reports/sections/utils/chartData";
import { PERIOD_TYPE } from "utils";
import { Values } from "utils/types";

export const useLocationGridChartDomain = ({
  data,
  isForHistoricalChart,
  period
}: {
  data: LocationRawDataset | undefined;
  isForHistoricalChart: boolean;
  period: Values<typeof PERIOD_TYPE>;
}) => {
  const dataset = useCurrentDataset();
  const { isGroupedBarChart } = useChartTypeBoolean();

  if (!data) {
    return { domain: [], lastYearDomainLength: 0, isTimelineMerged: false };
  }

  const uniqData = data.data.data.reduce(getLocationChartUniqData, []);
  const timeline = uniqData[0]?.data[0]?.timeline || [];
  let domain = findDateDomain(
    timeline,
    isForHistoricalChart || dataset.historical
  );
  const lastYearDomainLength = findLastYearDomainLength(timeline);
  const mergedTimeline = getMergedTimelineDetails({
    isBothDatasets: dataset.both,
    isGroupedBarChart,
    domainLength: domain.length,
    period
  });

  if (!isForHistoricalChart && mergedTimeline.shouldMerge) {
    domain = mergedTimeline.fakeDomain ?? [
      ...findDateDomain(timeline, true),
      ...domain
    ];
  }

  return {
    domain,
    lastYearDomainLength,
    isTimelineMerged: mergedTimeline.shouldMerge
  };
};
