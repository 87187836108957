import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const ThumbDown: React.FC<Props> = ({
  className,
  color,
  size = ICON_SIZES.NORMAL
}) => {
  const iconSize = SIZES[size];

  return (
    <svg
      className={className}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 20 20"
      version="1.1"
    >
      <g fill={color} fillRule="nonzero">
        <path d="M9.194 12.04v4.816a2.11 2.11 0 002.408 2.408.803.803 0 00.723-.602l2.859-9.03V0H4.86a3.225 3.225 0 00-3.13 2.77L.765 8.307a3.05 3.05 0 00.723 2.528c.595.791 1.54 1.24 2.528 1.204h5.177zM20 9.632h-3.211V0H20z" />
      </g>
    </svg>
  );
};
