import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { useDataTypeSuffix } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import {
  chartSidebarQueryParamsSelector,
  isSidebarChangeSelector
} from "store/selectors/routerSelectors";
import {
  BOOL_STRING_VALUES,
  isGroupedBarChartSortingEnabled
} from "utils/constants";
import { Nullable } from "utils/types";

import { SidebarSortingTypes } from "./useSidebarSorting";

export const useBarChartSorting = () => {
  const sidebarQP = useSelector(chartSidebarQueryParamsSelector);
  const isSidebarChangeEnabled = useSelector(isSidebarChangeSelector);
  const dataset = useCurrentDataset();
  const suffixes = useDataTypeSuffix();

  const isDesc = sidebarQP.sidebarDesc || "";
  const sortBy = sidebarQP.sidebarSortBy || "";
  const sortType = sidebarQP.sidebarSortType || "";
  let dataType = sortBy.replace(/(_change)$/i, "");

  const dataKey = useMemo(() => {
    const isValid =
      suffixes.length > 0
        ? suffixes.some(suffix => dataType.endsWith(suffix))
        : true;
    if (!isValid) return "";

    if (
      (dataset.base || dataset.both) &&
      sortType === SidebarSortingTypes.BY_BASE_PERIOD
    )
      return dataType;
    if (
      (dataset.historical || dataset.both) &&
      sortType === SidebarSortingTypes.BY_HISTORICAL_PERIOD
    )
      return `${dataType}_LAST_YEAR`;
    if (dataset.both && isSidebarChangeEnabled) {
      if (sortType === SidebarSortingTypes.BY_CHANGE_INTEGER)
        return `${dataType}_CHANGE`;
      if (sortType === SidebarSortingTypes.BY_CHANGE_PERCENTAGE)
        return `${dataType}_CHANGE_PERCENTAGE`;
    }

    return "";
  }, [dataType, dataset, isSidebarChangeEnabled, sortType, suffixes]);

  dataType = dataType.replace(/(_offline|_online|_online_share)$/i, "");

  const getSortingTimepoint = useCallback(
    ({
      dataTypes,
      showBarChart,
      isGroupedBarChart,
      pinnedTimepoint
    }: {
      dataTypes: string[];
      showBarChart: boolean;
      isGroupedBarChart: boolean;
      pinnedTimepoint: Nullable<number>;
    }) => {
      if (!dataKey || !dataTypes.includes(dataType)) return null;
      if (showBarChart) return 0;
      if (isGroupedBarChartSortingEnabled && isGroupedBarChart) {
        return pinnedTimepoint;
      }
      return null;
    },
    [dataKey, dataType]
  );

  return {
    dataType,
    dataKey,
    isDesc: isDesc === BOOL_STRING_VALUES.TRUE,
    getSortingTimepoint
  };
};
