import { format } from "date-fns";

import { DataTimeline } from "pages/Reports/redux/utils/sortDataByVendorAndLabel";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { newDateByTimezone } from "store/utils";
import { CHART_DATA_TYPE, DEFAULT_DATE_FORMAT } from "utils";

export const checkedDataTypes = (dataTypes: ChartDataTypes[]) =>
  dataTypes.filter(type => type !== CHART_DATA_TYPE.NONE);

export const findDateDomain = (
  timeline: DataTimeline[],
  isForHistoricalChart = false
) => {
  if (!timeline.length) return [];

  return timeline.map(el =>
    isForHistoricalChart && "dateLastYear" in el
      ? el.dateLastYear || format(newDateByTimezone(), DEFAULT_DATE_FORMAT)
      : el.date
  );
};

export const findLastYearDomainLength = (timeline: DataTimeline[]) =>
  timeline.filter(data =>
    "dateLastYear" in data ? data.dateLastYear !== null : false
  ).length;
