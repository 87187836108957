import React, { CSSProperties } from "react";

import cn from "classnames";

import { SpinnerLoader } from "components/atoms";
import { Nullable } from "utils/types";

import s from "./card.module.scss";

interface Props {
  children: React.ReactNode;
  isLoading?: boolean;
  isLoadingOpaque?: boolean;
  className?: {
    base?: string;
    loaderWrapper?: string;
    loader?: string;
  };
  style?: CSSProperties;
  testId?: string;
  loadingInfo?: Nullable<React.ReactNode>;
}

export const Card = ({
  children,
  className = {},
  isLoading,
  isLoadingOpaque,
  style = {},
  testId,
  loadingInfo = null
}: Props) => (
  <section
    className={cn(s.base, className.base)}
    style={style}
    data-testid={testId}
  >
    {(isLoading || isLoadingOpaque) && (
      <>
        <SpinnerLoader
          className={{
            wrapper: cn(s.loader, className.loaderWrapper, {
              [s.loaderOpaque]: isLoadingOpaque
            }),
            loader: className.loader
          }}
        />
        {loadingInfo && loadingInfo}
      </>
    )}
    {children}
  </section>
);
