import { useSelector } from "react-redux";

import { RadioButton, Text } from "components/atoms";
import { useGetDisabledDataTypeTooltip } from "components/molecules/ChartCheckboxDropdown/hooks";
import { ChartDropdownIndex } from "components/molecules/ChartHeader/ChartHeader";
import {
  MegaDropdownLevels,
  useMegaDropdown
} from "components/molecules/ChartHeader/hooks/useMegaDropdown";
import { isClientTypeMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { ChartDataTypes } from "pages/Reports/types/chart";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import {
  CHART_DATA_TYPE,
  getPolishLabelForChartHeader,
  TEXT_WRAPPERS
} from "utils";
import { getPolishLabelForDataTypeTooltip } from "utils/polishLabels/getPolishLabelForDataTypeTooltip";

import s from "./chartDropdown.module.scss";

interface Props {
  level: MegaDropdownLevels;
  checkedDataType: ChartDataTypes;
  handleChange: (type: ChartDataTypes) => void;
  dropdownIndex: ChartDropdownIndex;
}

export const ChartDropdownColumn = ({
  level,
  checkedDataType,
  handleChange,
  dropdownIndex
}: Props) => {
  const activeFirst = useSelector(firstChartDataTypeSelector);
  const activeSecond = useSelector(secondChartDataTypeSelector);
  const isClientTypeMultiChoice = useSelector(isClientTypeMultiChoiceSelector);
  const { text, items } = useMegaDropdown(level);
  const getDisabledDataTypeTooltip = useGetDisabledDataTypeTooltip();

  if (!text.length && !items.length) {
    return null;
  }

  const isItemActive = (type: ChartDataTypes) => {
    return type === checkedDataType;
  };

  const types = items.map(type => {
    const tooltip = getDisabledDataTypeTooltip(type);
    const isSelectedAlready = isClientTypeMultiChoice
      ? false
      : dropdownIndex === ChartDropdownIndex.first
      ? activeSecond === type
      : activeFirst === type;

    return {
      value: type,
      label: getPolishLabelForChartHeader(type),
      tooltip: isSelectedAlready ? "Ta miara jest już wybrana" : tooltip.value,
      iconTooltipText: getPolishLabelForDataTypeTooltip(type),
      isDisabled: tooltip.isDisabled || isSelectedAlready,
      isChecked: isSelectedAlready || isItemActive(type)
    };
  });

  return (
    <div className={s.categoriesWrapper}>
      <Text className={s.chartDropdownLabel} Wrapper={TEXT_WRAPPERS.SPAN}>
        {text}
      </Text>
      {types.map(
        ({ value, label, tooltip, isDisabled, isChecked, iconTooltipText }) => {
          if (value === CHART_DATA_TYPE.NONE) return null;
          return (
            <RadioButton
              key={value}
              onClick={() => handleChange(value)}
              isDisabled={isDisabled}
              tooltipText={tooltip}
              iconTooltipText={iconTooltipText}
              isChecked={isChecked}
              label={label}
              className={{ base: s.radioButton, label: s.radioButtonLabel }}
            />
          );
        }
      )}
    </div>
  );
};
