import { isMac } from "utils";

interface Args {
  isLineChart: boolean;
}

export const getChartTilesTooltipText = ({ isLineChart }: Args) => {
  const button = isMac() ? "option" : "ctrl";
  const CMD_TEXT = `Przytrzymaj ${button} i kliknij lewym przyciskiem myszki w punkt, gdzie chcesz oznaczyć etykietę.`;
  const CMD_SHIFT_TEXT = `Porównując dane dla okresu historycznego możesz automatycznie włączyć etykiety dla okresu bazowego i historycznego przytrzymując ${button}+shift i klikając na wykres.`;
  const DROPDOWN_TEXT =
    "Pokaż lub ukryj wszystkie etykiety poprzez wybór jednej z opcji dostępnych w poniższej liście rozwijalnej.";

  if (!isLineChart) return [CMD_TEXT, DROPDOWN_TEXT].join("\n");

  return [CMD_TEXT, CMD_SHIFT_TEXT, DROPDOWN_TEXT].join("\n");
};
