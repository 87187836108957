import { ChartTimePoint } from "components/D3/types";

// get difference in values between base & historical period y-values
export const getTimelineDiffs = (
  timeline: ChartTimePoint[],
  lastYearTimeline?: ChartTimePoint[]
) => {
  return timeline.map((item, index) => {
    const valueY = item.valueY || 0;
    if (!lastYearTimeline || !lastYearTimeline[index]?.valueY) return 0;
    const lastYearValueY = lastYearTimeline[index].valueY || 0;
    return valueY - lastYearValueY;
  });
};
