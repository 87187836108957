import { DATASET_TYPES, NO_DATA } from "utils";
import { Values } from "utils/types";

interface IBaseChartLabelInput {
  vendorId: string;
  resultId: string;
}

interface IGetChartLabelInput extends IBaseChartLabelInput {
  type: Values<typeof DATASET_TYPES>;
}

export const getChartLabel = (inputObj: IGetChartLabelInput): string => {
  const { vendorId, resultId, type } = inputObj;

  if (!vendorId && !resultId) {
    return NO_DATA;
  }

  if (
    !vendorId ||
    type === DATASET_TYPES.OWN ||
    type === DATASET_TYPES.ATTRIBUTES ||
    type === DATASET_TYPES.REFERENCE ||
    type === DATASET_TYPES.REFERENCE_BRAND
  ) {
    return resultId;
  }

  if (vendorId === "aggregated") {
    return `Konkurencja - ${resultId}`;
  }

  return `${vendorId} - ${resultId}`;
};

// https://do.zabka.pl/browse/PMD-2739
// BE is now returning hours report labels in the different way
// This changes will affect all reports if used in the getChartLabel function
export const getHoursReportChartLabel = (
  inputObj: IBaseChartLabelInput
): string => {
  const { vendorId, resultId } = inputObj;

  if (!resultId) {
    return vendorId;
  }

  if (vendorId === "aggregated") {
    return `Konkurencja - ${resultId}`;
  }

  return resultId;
};
