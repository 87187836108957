import { useSelector } from "react-redux";

import { RadioButton } from "components/atoms";
import { ChartDropdownIndex } from "components/molecules/ChartHeader/ChartHeader";
import { ChartDataTypes } from "pages/Reports/types/chart";
import {
  firstChartDataTypeSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { isMultipackWsaEnabled } from "utils/constants";

import s from "./chartDropdown.module.scss";

type Props = {
  rows: {
    label: string;
    metrics: { value: string; disabled: boolean }[];
  }[];
  checkedDataType: ChartDataTypes;
  handleChange: (type: ChartDataTypes) => void;
  dropdownIndex: ChartDropdownIndex;
};

export const MultipackGroup = ({
  rows,
  checkedDataType,
  handleChange,
  dropdownIndex
}: Props) => {
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);

  const headers = isMultipackWsaEnabled
    ? ["WS", "WSO", "WSA", "WS+WSO\n+WSA", "Sprzedaż\ntotal"]
    : ["WS", "WSO", "WS+WSO", "Sprzedaż\ntotal"];

  return (
    <div className={s.multipackGroupWrapper}>
      <table className={s.multipackGroupTable}>
        <thead>
          <tr>
            <th />
            {headers.map(header => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(row => (
            <tr key={row.label}>
              <td>{row.label}</td>
              {Array.from({ length: headers.length }).map((_, index) => {
                const metric = row.metrics[index];

                if (!metric) return <td key={`empty-${index}`} />;

                const isSelectedAlready =
                  dropdownIndex === ChartDropdownIndex.first
                    ? secondChartType === metric.value
                    : firstChartType === metric.value;

                return (
                  <td key={metric.value}>
                    <RadioButton
                      className={{ base: s.multipackRadio }}
                      isChecked={
                        isSelectedAlready || metric.value === checkedDataType
                      }
                      isDisabled={metric.disabled || isSelectedAlready}
                      tooltipText={
                        isSelectedAlready
                          ? "Ta miara jest już wybrana"
                          : undefined
                      }
                      onClick={() => handleChange(metric.value)}
                    />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
