import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const SettingsOutline: React.FC<Props> = ({
  className,
  color = "#000000",
  size = ICON_SIZES.LARGE
}) => {
  const iconSize = SIZES[size];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 512 512"
    >
      <g
        fill={color}
        stroke="none"
        transform="translate(0, 512) scale(0.1,-0.1)"
      >
        <path
          d="M2242 5101 c-29 -10 -70 -32 -90 -48 -46 -36 -98 -112 -112 -161 -5
-20 -10 -138 -10 -262 l0 -225 -99 -34 c-55 -18 -144 -55 -199 -81 l-100 -48
-153 155 c-190 190 -221 208 -354 208 -135 0 -154 -13 -376 -234 -224 -224
-234 -241 -234 -381 0 -85 3 -100 28 -146 17 -30 90 -113 181 -203 l154 -153
-48 -100 c-26 -55 -63 -144 -81 -198 l-33 -99 -246 -3 c-233 -3 -247 -4 -288
-26 -66 -35 -119 -88 -149 -150 l-28 -57 0 -295 0 -295 28 -57 c30 -62 83
-115 149 -150 41 -22 55 -23 288 -26 l246 -3 33 -99 c18 -54 55 -143 81 -198
l48 -100 -154 -153 c-89 -88 -164 -173 -179 -201 -48 -88 -49 -210 -3 -301 12
-25 99 -120 207 -228 222 -221 241 -234 376 -234 133 0 164 18 354 208 l153
155 103 -49 c56 -26 145 -63 198 -81 l96 -33 3 -245 c3 -233 4 -247 26 -288
35 -66 88 -119 150 -149 l57 -28 295 0 295 0 57 28 c62 30 115 83 150 149 22
41 23 55 26 288 l3 245 96 33 c53 18 142 55 198 81 l103 49 153 -155 c190
-190 221 -208 354 -208 134 0 154 13 376 234 223 224 234 241 234 381 0 85 -3
100 -28 146 -17 30 -90 113 -181 203 l-154 153 49 103 c26 56 63 145 81 198
l33 96 245 3 c233 3 247 4 288 26 66 35 119 88 149 150 l28 57 0 295 0 295
-28 57 c-30 62 -83 115 -149 150 -41 22 -55 23 -288 26 l-246 3 -33 99 c-18
54 -55 143 -81 198 l-48 100 154 153 c91 90 164 173 181 203 25 46 28 61 28
146 0 140 -10 157 -234 381 -212 211 -239 229 -361 237 -61 3 -86 0 -135 -19
-53 -19 -80 -41 -225 -184 l-165 -162 -98 47 c-55 26 -143 63 -197 81 l-99 33
-3 246 c-3 233 -4 247 -26 288 -35 66 -88 119 -150 149 l-57 28 -280 2 c-251
3 -285 1 -333 -16z m573 -205 c62 -26 65 -44 65 -338 0 -321 -2 -315 112 -348
131 -37 241 -81 368 -147 105 -54 131 -64 160 -59 28 4 67 38 220 190 102 101
195 189 207 195 12 6 36 11 55 11 29 0 52 -20 215 -183 163 -163 183 -186 183
-215 0 -19 -5 -43 -11 -55 -6 -12 -96 -108 -200 -213 -166 -166 -189 -194
-189 -222 0 -19 25 -79 64 -154 65 -125 109 -235 146 -366 33 -114 27 -112
348 -112 294 0 312 -3 338 -65 20 -49 20 -461 0 -510 -26 -62 -44 -65 -338
-65 -320 0 -315 2 -348 -112 -38 -133 -81 -242 -146 -366 -39 -75 -64 -135
-64 -154 0 -28 23 -56 189 -222 104 -105 194 -201 200 -213 6 -12 11 -36 11
-55 0 -29 -20 -52 -183 -215 -163 -163 -186 -183 -215 -183 -19 0 -43 5 -55
11 -12 6 -108 96 -213 200 -166 166 -194 189 -222 189 -19 0 -79 -25 -154 -64
-124 -65 -233 -108 -366 -146 -114 -33 -112 -28 -112 -348 0 -294 -3 -312 -65
-338 -49 -20 -461 -20 -510 0 -62 26 -65 44 -65 338 0 320 2 315 -112 348
-133 38 -242 81 -366 146 -75 39 -135 64 -154 64 -28 0 -56 -23 -222 -189
-105 -104 -201 -194 -213 -200 -12 -6 -36 -11 -55 -11 -29 0 -52 20 -215 183
-163 163 -183 186 -183 215 0 19 5 43 11 55 6 12 94 105 195 207 153 154 186
192 190 221 5 29 -4 54 -59 159 -66 127 -110 236 -147 368 -33 114 -27 112
-348 112 -294 0 -312 3 -338 65 -20 49 -20 461 0 510 26 62 44 65 338 65 321
0 315 -2 348 112 37 131 81 241 146 366 39 75 64 135 64 154 0 28 -23 56 -189
222 -104 105 -194 201 -200 213 -6 12 -11 36 -11 55 0 29 20 52 183 215 163
163 186 183 215 183 19 0 43 -5 55 -11 12 -6 108 -96 213 -200 164 -163 194
-189 221 -189 18 0 81 26 154 64 126 65 237 109 367 146 114 33 112 27 112
348 0 292 4 311 63 337 47 21 464 21 512 1z"
        />
        <path
          d="M2435 3619 c-172 -20 -380 -104 -515 -207 -403 -306 -538 -848 -325
-1299 59 -124 129 -225 223 -315 321 -311 790 -391 1188 -204 121 58 203 114
296 204 325 315 415 802 223 1209 -162 344 -477 570 -855 613 -96 11 -135 11
-235 -1z m295 -223 c221 -47 412 -177 539 -366 97 -145 141 -294 141 -474 -1
-289 -137 -541 -381 -705 -90 -61 -213 -111 -314 -130 -75 -14 -235 -14 -310
0 -280 52 -535 261 -636 524 -43 112 -59 194 -59 315 0 237 82 433 249 601
122 121 263 199 426 234 80 18 264 18 345 1z"
        />
      </g>
    </svg>
  );
};
