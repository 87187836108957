import { useSelector } from "react-redux";

import cn from "classnames";
import moment from "moment";

import { Tick, TooltipIcon } from "components/atoms/Icon";
import { Checkbox } from "components/molecules/Checkbox/Checkbox";
import { IDS } from "components/organisms/Tour";
import { CalendarHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCalendar";

import s from "./presetsSection.module.scss";
import { isDynamicPeriodEnabledSelector } from "./selectors";
import { usePresets } from "./usePresets";

type Props = {
  onOptionClick: (start: Date, end: Date) => void;
} & Omit<ReturnType<typeof usePresets>, "dataForStore">;

export const PresetsSection = ({
  onOptionClick,
  isChecked,
  setChecked,
  setClickedItem,
  items,
  activeItems,
  selectedItem
}: Props) => {
  const isDynamicPeriodEnabled = useSelector(isDynamicPeriodEnabledSelector);
  const [{ selectedDynamicPeriod }, actions] = CalendarHooks.useCalendar();

  const tooltipInfo = !selectedItem
    ? "Wybierz jedną z powyższych opcji, aby odblokować."
    : `Jeżeli wybierzesz tę opcję będziemy automatycznie dostosowywać zakres czasu
    i po powrocie do linku pokażemy Tobie zakres dat dla "${selectedItem.title}",
    brak zaznaczenia spowoduje powrót do dokładnie tego samego zakresu dat, który został wybrany dzisiaj.`;

  const handleCheckboxChange = () => {
    const newIsChecked = !isChecked;

    if (
      !newIsChecked &&
      selectedDynamicPeriod.dateFrom &&
      selectedDynamicPeriod.dateTo
    ) {
      actions.updateCalendarDates({
        dateFrom: moment(selectedDynamicPeriod.dateFrom),
        dateTo: moment(selectedDynamicPeriod.dateTo)
      });
    }

    setChecked(newIsChecked);
  };

  return (
    <div className={s.wrapper}>
      <span className={s.heading}>Ustawienia domyślne:</span>
      <ul>
        {items.map(item => (
          <li key={item.id}>
            <button
              type="button"
              className={cn(s.button, {
                [s.active]: activeItems.find(({ id }) => id === item.id)
              })}
              data-ga-datepicker={item.title}
              data-test-id={`date-predefined-item-${item.id}`}
              disabled={item.isDisabled}
              onClick={() => {
                setClickedItem(item);
                actions.updateCalendarDates({
                  dateFrom: moment(item.dateFrom),
                  dateTo: moment(item.dateTo)
                });
                onOptionClick(item.dateFrom, item.dateTo);
              }}
            >
              <Tick />
              <span>{item.display}</span>
            </button>
          </li>
        ))}
      </ul>
      {isDynamicPeriodEnabled && (
        <div id={IDS.DYNAMIC_PERIOD_WRAPPER} className={s.checkboxWrapper}>
          <Checkbox
            testId="dynamic-period-checkbox"
            label="Dynamiczny zakres dat"
            isChecked={isChecked}
            onChange={handleCheckboxChange}
            isDisabled={!selectedItem}
          />
          <div className={s.tooltipIconWrapper} data-tooltip={tooltipInfo}>
            <TooltipIcon />
          </div>
        </div>
      )}
    </div>
  );
};
