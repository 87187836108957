import { PERIOD_TYPE, PERIOD_TYPE_PL } from "utils/constants";

export const getPolishLabelForTableTimePeriod = (period: string) => {
  switch (period) {
    case PERIOD_TYPE.DAYS:
      return "Data";
    case PERIOD_TYPE.WEEKS:
      return PERIOD_TYPE_PL.WEEK;
    case PERIOD_TYPE.PROMO_WEEKS:
      return PERIOD_TYPE_PL.PROMO_WEEK;
    case PERIOD_TYPE.MONTHS:
      return PERIOD_TYPE_PL.MONTH;
    case PERIOD_TYPE.YEAR:
      return PERIOD_TYPE_PL.YEAR;
    default:
      return "Okres";
  }
};
