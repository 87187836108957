import { CHART_DATA_TYPE } from "utils";

const RELATED_METRICS = [
  [
    CHART_DATA_TYPE.VALUE_WS_WSO,
    CHART_DATA_TYPE.VALUE_WSO,
    CHART_DATA_TYPE.VALUE_WS,
    CHART_DATA_TYPE.VALUE
  ],
  [
    CHART_DATA_TYPE.QUANTITY_WS_WSO,
    CHART_DATA_TYPE.QUANTITY_WSO,
    CHART_DATA_TYPE.QUANTITY_WS,
    CHART_DATA_TYPE.QUANTITY
  ],
  [
    CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO,
    CHART_DATA_TYPE.VALUE_PER_SHOP_WSO,
    CHART_DATA_TYPE.VALUE_PER_SHOP_WS,
    CHART_DATA_TYPE.VALUE_PER_SHOP
  ],
  [
    CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO,
    CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSO,
    CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS,
    CHART_DATA_TYPE.QUANTITY_PER_SHOP
  ],
  [
    "NET_QUANTITY_SUM_WS_WSO", // this metric is not in CHART_DATA_TYPE
    "NET_QUANTITY_SUM_WSO", // this metric is not in CHART_DATA_TYPE
    "NET_QUANTITY_SUM_WS", // this metric is not in CHART_DATA_TYPE
    CHART_DATA_TYPE.NET_QUANTITY_SALES
  ],
  [
    CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO,
    CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSO,
    CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS,
    CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP
  ],
  [
    CHART_DATA_TYPE.VALUE_SHARES_WS_WSO,
    CHART_DATA_TYPE.VALUE_SHARES_WSO,
    CHART_DATA_TYPE.VALUE_SHARES_WS
  ],
  [
    CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO,
    CHART_DATA_TYPE.QUANTITY_SHARES_WSO,
    CHART_DATA_TYPE.QUANTITY_SHARES_WS
  ],
  [
    CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO,
    CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO,
    CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS
  ],
  [
    CHART_DATA_TYPE.PRICE_AVG_WS_WSO,
    CHART_DATA_TYPE.PRICE_AVG_WSO,
    CHART_DATA_TYPE.PRICE_AVG_WS,
    CHART_DATA_TYPE.AVG_PRICE
  ],
  [CHART_DATA_TYPE.AVG_SHOPS_COUNT, CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT],
  [
    CHART_DATA_TYPE.DISTRIBUTION_RANGE,
    CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE
  ]
];

export const isSameScaleMetrics = (dataTypes: string[]) => {
  if (dataTypes.length !== 2) return false;
  const relatedGroup = RELATED_METRICS.find(group =>
    group.includes(dataTypes[0])
  );
  return relatedGroup ? relatedGroup.includes(dataTypes[1]) : false;
};
