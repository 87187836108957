import React from "react";

import { ICON_SIZES } from "utils";
import { COLOR } from "utils/constants";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const Pin = ({
  className,
  color = COLOR.UI_3,
  size = ICON_SIZES.SMALL
}: Props) => {
  const currentSize = SIZES[size];
  return (
    <svg
      width={currentSize}
      height={currentSize}
      className={className}
      fill="none"
    >
      <path
        d="M12.4076 3.57102L9.06152 0.224962C8.94073 0.104049 8.7446 0.104049 8.62381 0.224962L8.60963 0.239139C8.40446 0.444251 8.29141 0.717094 8.29141 1.00721C8.29141 1.19412 8.33889 1.37348 8.42712 1.53253L4.92604 4.53726C4.65809 4.29779 4.31609 4.16673 3.95385 4.16673C3.5635 4.16673 3.19655 4.31872 2.92061 4.59472L2.89962 4.61571C2.77871 4.73656 2.77871 4.93257 2.89962 5.05342L4.90418 7.05798L2.95188 9.01023C2.91281 9.05041 1.98872 10.0027 1.38119 10.7605C0.802629 11.4819 0.688218 11.6141 0.682274 11.6209C0.574858 11.7432 0.580802 11.9277 0.695771 12.0433C0.75601 12.1037 0.835442 12.1343 0.915122 12.1343C0.987744 12.1343 1.06055 12.1089 1.11912 12.0577C1.12426 12.0533 1.2534 11.941 1.97956 11.3588C2.73723 10.7513 3.68949 9.82715 3.73276 9.78498L5.68191 7.83583L7.57912 9.73304C7.63954 9.79353 7.71879 9.82374 7.79798 9.82374C7.87716 9.82374 7.95647 9.79353 8.01683 9.73304L8.03782 9.71205C8.31382 9.43611 8.46581 9.0691 8.46581 8.67882C8.46581 8.31657 8.33468 7.97458 8.09527 7.70662L11.1 4.20554C11.2591 4.29377 11.4384 4.34125 11.6253 4.34125C11.9155 4.34125 12.1883 4.22827 12.3934 4.02303L12.4076 4.00885C12.5285 3.88788 12.5285 3.69187 12.4076 3.57102Z"
        fill={color}
      />
    </svg>
  );
};
