import {
  PromotionDataType,
  PromotionTableData
} from "pages/Reports/redux/reducers/promotionsReducer";
import { getPolishLabelForChartHeader } from "utils";

export const transformPromotionsForTable = (
  promotions: PromotionDataType[]
): PromotionTableData[] =>
  promotions.map(promotion => ({
    promotionName: `${getPolishLabelForChartHeader(promotion.display)} (${
      promotion.validFrom
    } - ${promotion.validTo})`,
    promotionDesc: promotion.promotionDesc,
    ownMaterials: promotion.materials.ownMaterials,
    competingMaterials: promotion.materials.competingMaterials,
    period: promotion.period
  }));
