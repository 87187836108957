import { FC, SyntheticEvent } from "react";
import { useSelector } from "react-redux";

import { Button, Text } from "components/atoms";
import { Checkbox } from "components/molecules";
import { ExcludedDaysHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useExcludedDays";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { APP_PATHS, isThisPage } from "utils";

import s from "./excludedDaysSection.module.scss";

const { LOGISTICS_PATH } = REPORTS_FULL_PATHS;
const { INSIGHTS } = APP_PATHS;

const DAYS = [
  {
    label: "Poniedziałek",
    value: "mon"
  },
  {
    label: "Wtorek",
    value: "tue"
  },
  {
    label: "Środa",
    value: "wed"
  },
  {
    label: "Czwartek",
    value: "thu"
  },
  {
    label: "Piątek",
    value: "fri"
  },
  {
    label: "Sobota",
    value: "sat"
  },
  {
    label: "Niedziela",
    value: "sun"
  }
];

export const ExcludedDaysSection: FC = () => {
  const [state, actions] = ExcludedDaysHooks.useExcludedDays();
  const selectedDays = state.excludedDays;
  const pathname = useSelector(pathnameSelector);

  const isLogisticsPage = isThisPage(pathname, LOGISTICS_PATH);
  const isInsightsPage = isThisPage(pathname, INSIGHTS);

  const isOneDayLeft = selectedDays.length === 6;

  const onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const clickedDay = e.currentTarget.value;

    if (selectedDays.includes(clickedDay)) {
      const filteredDays = selectedDays.filter(day => day !== clickedDay);

      actions.updateExcludedDays(filteredDays);
      return;
    }

    if (isOneDayLeft) return; //always leave 1 day selected
    actions.updateExcludedDays([...selectedDays, clickedDay]);
  };

  if (isLogisticsPage || isInsightsPage) return null;

  return (
    <div className={s.daysWrapper} data-testid="exclude-days-wrapper">
      <Text className={s.daysTitle}>Uwzględnij dni:</Text>
      <Button onClick={actions.clearExcludedDays} className={s.selectAllButton}>
        Zaznacz wszystko
      </Button>
      {DAYS.map(({ label, value }) => (
        <Checkbox
          key={label}
          className={{ base: s.dayCheckboxBase }}
          isChecked={!selectedDays.includes(value)}
          label={label}
          onChange={onChange}
          value={value}
          isSmall
          tooltipText={
            isOneDayLeft && !selectedDays.includes(value)
              ? "Wykluczenie wszystkich dni tygodnia jest niemożliwe"
              : ""
          }
        />
      ))}
    </div>
  );
};
