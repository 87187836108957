import { useSelector } from "react-redux";

import { isBackDataSelectableSelector } from "components/molecules/DateDropdownMenu/HistoricalSection/selectors";
import { queryParamsSelector } from "store/selectors/routerSelectors";
import { QP } from "utils/defaultQueryParams";

const DATASETS = {
  BASE: "base",
  HISTORICAL: "historical",
  BOTH: "both"
};

export const useShowDatasetOptions = () => {
  const params = useSelector(queryParamsSelector);
  const isBackDataSelectable = useSelector(isBackDataSelectableSelector);

  if (!isBackDataSelectable) {
    return { value: DATASETS.BASE, options: [] };
  }

  const showDatasetQP = String(params[QP.SHOW_DATASET] || "");
  const value = Object.values(DATASETS).includes(showDatasetQP)
    ? showDatasetQP
    : DATASETS.BOTH;
  const options = [
    { value: DATASETS.BOTH, display: "Bazowy i historyczny" },
    { value: DATASETS.BASE, display: "Bazowy" },
    { value: DATASETS.HISTORICAL, display: "Historyczny" }
  ];

  return { value, options };
};

export const useCurrentDataset = () => {
  const { value } = useShowDatasetOptions();

  return {
    base: value === DATASETS.BASE,
    historical: value === DATASETS.HISTORICAL,
    both: value === DATASETS.BOTH
  };
};
