import { ParsedQuery } from "query-string";

import { SUMMARY_SUB_SWITCH } from "pages/Reports/utils";
import { getCategoriesAndLevel, isThisPage } from "utils";
import { QP } from "utils/defaultQueryParams";

import { REPORTS_FULL_PATHS, SUMMARY_SWITCH } from "../constants";

const { OWN, REF_CATEGORY } = SUMMARY_SUB_SWITCH;
const {
  SELL_PATH,
  SHARES_PATH,
  DYNAMICS_PATH,
  RECEIPTS_PATH
} = REPORTS_FULL_PATHS;

const getSummaryType = (params: ParsedQuery<string>, pathname: string) => {
  if (isThisPage(pathname, SELL_PATH)) {
    return params[QP.SELL_SUMMARY_TYPE];
  }

  if (isThisPage(pathname, SHARES_PATH)) {
    return params[QP.SHARES_SUMMARY_TYPE];
  }

  if (isThisPage(pathname, DYNAMICS_PATH)) {
    return params[QP.DYNAMICS_SUMMARY_TYPE];
  }

  if (isThisPage(pathname, RECEIPTS_PATH)) {
    return params[QP.RECEIPTS_SUMMARY_TYPE];
  }

  return "";
};

export const adjustSummaryParams = (
  params: ParsedQuery<string>,
  pathname: string
): ParsedQuery<string> => {
  const type = getSummaryType(params, pathname);
  const subType = String(params[QP.SUMMARY_SUB_TYPE] || "").toUpperCase();
  const [referenceCategory] = getCategoriesAndLevel(
    params[QP.REF_CAT1],
    params[QP.REF_CAT2],
    params[QP.REF_CAT3]
  );
  const isReference = subType === REF_CATEGORY;
  const isDistribution = type === SUMMARY_SWITCH.DISTRIBUTION.toLowerCase();

  if (
    (!referenceCategory.length && isReference) ||
    isDistribution // [PMD-2665]: disable ref. cat. for distribution
  ) {
    params[QP.SUMMARY_SUB_TYPE] = OWN.toLowerCase();
  }

  return params;
};
