import React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const EditUser = ({
  className,
  color = "#43B649",
  size = ICON_SIZES.SMALL
}: Props) => {
  const currentSize = SIZES[size];
  return (
    <svg width={currentSize} height={currentSize} className={className}>
      <path
        d="M4.707 8.707l6-6a1 1 0 000-1.414l-1-1a1 1 0 00-1.414 0l-6 6a1 1 0 00-.188.26l-.812 3.154L4.447 8.9a1 1 0 00.26-.193zM11.5 11H.5a.5.5 0 100 1h11a.5.5 0 100-1z"
        fill={color}
      />
    </svg>
  );
};
