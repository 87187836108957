import * as React from "react";

import cn from "classnames";

import {
  TEXT_SIZES,
  TEXT_STYLES,
  TEXT_TYPES,
  TEXT_WEIGHTS,
  TEXT_WRAPPERS
} from "utils";

import { Values } from "../../../utils/types";
import s from "./Text.module.scss";

type WrapperElement = HTMLDivElement | HTMLSpanElement | HTMLParagraphElement;
interface Props {
  children: React.ReactNode;
  Wrapper?: Values<typeof TEXT_WRAPPERS>;
  type?: Values<typeof TEXT_TYPES>;
  size?: Values<typeof TEXT_SIZES>;
  weight?: Values<typeof TEXT_WEIGHTS>;
  style?: Values<typeof TEXT_STYLES>;
  className?: string;
  onClick?: (e: React.SyntheticEvent<WrapperElement>) => void;
  testId?: string;
  tooltip?: string;
}

export const Text = ({
  children,
  Wrapper = TEXT_WRAPPERS.P,
  type = TEXT_TYPES.PRIMARY,
  size = TEXT_SIZES.NORMAL,
  weight = TEXT_WEIGHTS.NORMAL,
  style = TEXT_STYLES.NORMAL,
  className,
  onClick,
  testId,
  tooltip
}: Props) => {
  return (
    <Wrapper
      data-testid={testId}
      onClick={onClick}
      data-tooltip={tooltip}
      className={cn(
        s.base,
        s[type],
        {
          [s.small]: size === TEXT_SIZES.SMALL,
          [s.extraSmall]: size === TEXT_SIZES.EXTRA_SMALL,
          [s.bold]: weight === TEXT_WEIGHTS.BOLD,
          [s.italic]: style === TEXT_STYLES.ITALIC
        },
        className
      )}
    >
      {children}
    </Wrapper>
  );
};
