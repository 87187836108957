import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import cn from "classnames";
import pl from "date-fns/locale/pl";

import { ChevronLeft, ChevronRight } from "components/atoms/Icon";
import { POLISH_WEEK_DAYS } from "utils";
import { Values } from "utils/types";

import s from "./compareDateCalendar.module.scss";
import "./compareDateCalendar.scss";

interface Props extends ReactDatePickerProps {
  label: string;
  isMonthPicker: boolean;
  className?: string;
}

const mapWeekDay = (day: string): string => {
  const short = ["nd", "pn", "wt", "śr", "cz", "pt", "so"];
  const index = POLISH_WEEK_DAYS.indexOf(day);

  if (index === -1) return "";

  return short[index];
};

const CustomHeader = (
  isMonthPicker: boolean
): Values<Pick<ReactDatePickerProps, "renderCustomHeader">> => ({
  decreaseMonth,
  increaseMonth,
  decreaseYear,
  increaseYear,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  date
}) => {
  const displayDate = date.toLocaleDateString("pl-PL", {
    month: isMonthPicker ? undefined : "long",
    year: "numeric"
  });
  const isPrevDisabled = isMonthPicker && prevYearButtonDisabled;
  const isNextDisabled = isMonthPicker && nextYearButtonDisabled;

  const handleChange = (isPrev: boolean) => () => {
    if (isPrev ? isPrevDisabled : isNextDisabled) return;

    if (isMonthPicker) {
      isPrev ? decreaseYear() : increaseYear();
    } else {
      isPrev ? decreaseMonth() : increaseMonth();
    }
  };

  return (
    <div>
      <button
        className={cn(
          "react-datepicker__nav",
          "react-datepicker__nav--prev",
          isPrevDisabled && "react-datepicker__nav--disabled"
        )}
        onClick={handleChange(true)}
      >
        <ChevronLeft />
      </button>
      <span className="react-datepicker__current-month">{displayDate}</span>
      <button
        className={cn(
          "react-datepicker__nav",
          "react-datepicker__nav--next",
          isNextDisabled && "react-datepicker__nav--disabled"
        )}
        onClick={handleChange(false)}
      >
        <ChevronRight />
      </button>
    </div>
  );
};

export const CompareDateCalendar = ({
  label,
  isMonthPicker,
  className,
  ...props
}: Props) => {
  return (
    <div
      className={cn(s.wrapper, className)}
      data-testid={`date-picker-${label}`}
    >
      <span className={s.label}>{label}</span>
      <DatePicker
        popperClassName="single-date-calendar-popper"
        className="single-date-calendar-input"
        calendarClassName={cn(
          "single-date-calendar",
          isMonthPicker && "single-date-calendar--month-picker"
        )}
        locale={pl}
        showMonthYearPicker={isMonthPicker}
        dateFormat={isMonthPicker ? "yyyy.MM" : "yyyy.MM.dd"}
        placeholderText={isMonthPicker ? "rrrr.mm" : "rrrr.mm.dd"}
        formatWeekDay={mapWeekDay}
        renderCustomHeader={CustomHeader(isMonthPicker)}
        {...props}
      />
    </div>
  );
};
