import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SpinnerLoader } from "components/atoms";
import { ChevronDown } from "components/atoms/Icon";
import { Dropdown, FilterDropdownInput } from "components/molecules";
import {
  asyncFiltersSelector,
  updateAsyncFilterQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useAttributesBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useAttributesFetching";
import {
  AttributesHooks,
  AttributesItemApi
} from "pages/Reports/redux/reducers/sweetStateHooks/useAttributes";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

import { DROPDOWN_LABELS, DROPDOWN_PLACEHOLDERS } from "../constants";
import { FilterErrorMsg } from "../FilterErrorMsg/FilterErrorMsg";
import s from "./attributes.module.scss";
import { AttributesMenu } from "./AttributesMenu";

type Props = {
  isDisabled: boolean;
};

export const AttributesDropdown = ({ isDisabled }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    fetching: { attributes: isLoading },
    error: { attributes: errorMsg }
  } = useSelector(asyncFiltersSelector);
  const isPowerUser = useSelector(isPowerUserSelector);
  const [inputValue] = AttributesHooks.useInputValue();

  useAttributesBehaviour(isDisabled);

  if (!isLoading && errorMsg) {
    return (
      <FilterErrorMsg
        callback={() => {
          dispatch(updateAsyncFilterQuery({ filter: "attributes", query: "" }));
        }}
        errorMsg={errorMsg}
      />
    );
  }

  return (
    <Dropdown<AttributesItemApi>
      isDisabled={isLoading || isDisabled}
      label={DROPDOWN_LABELS.ATTRIBUTES}
      openedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.ATTRIBUTES.OPEN
      }
      closedMenuPlaceholder={
        isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.ATTRIBUTES.CLOSED
      }
      inputValue={isLoading ? "Pobieranie" : isOpen ? "" : inputValue}
      isOpen={isOpen}
      onSelect={() => {}}
      onStateChange={changes => {
        if (changes.isOpen === true) {
          pushFiltersEvent("attributes");
          setOpen(true);
        }
      }}
      input={
        <FilterDropdownInput
          customIcon={
            isLoading ? <SpinnerLoader size="small" /> : <ChevronDown />
          }
          className={{ base: s.dropdownInput }}
          isTypingEnabled
          automaticInputValue={""}
          testId="attributes-dropdown"
          labelTooltip={
            isPowerUser
              ? "W nawiasach przy wartościach na liście rozwijanej są podane liczby indeksów mających przypisaną daną wartość atrybutów."
              : "W nawiasach przy wartościach na liście rozwijanej są podane liczby indeksów dla danych własnych i konkurencyjnych mających przypisaną daną wartość atrybutów."
          }
        />
      }
      menu={<AttributesMenu onClose={() => setOpen(false)} />}
    />
  );
};
