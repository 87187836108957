import { Row } from "react-table";

import { differenceInDays, parse } from "date-fns";

import { newDateByTimezone } from "store/utils";
import { CURRENCY, PERIOD_TYPE } from "utils";
import { Keys } from "utils/types";

import { capitalize } from "./capitalize";
import { DEFAULT_DATE_FORMAT, NO_DATA, POLISH_MONTH_NAMES } from "./constants";

export const getDates = <T extends { [key: string]: string }>(
  nextRow: Row<T>,
  prevRow: Row<T>,
  field: string = "period"
) => ({
  nextPeriod: nextRow.values[field],
  prevPeriod: prevRow.values[field]
});

export const sortByDate = (field: string = "period") => <
  T extends { [key: string]: string }
>(
  nextRow: Row<T>,
  prevRow: Row<T>
) => {
  const { nextPeriod, prevPeriod } = getDates(nextRow, prevRow, field);

  return differenceInDays(
    parse(prevPeriod.split(" - ")[0], DEFAULT_DATE_FORMAT, newDateByTimezone()),
    parse(nextPeriod.split(" - ")[0], DEFAULT_DATE_FORMAT, newDateByTimezone())
  );
};

export const sortByNumericString = <T extends { period: string }>(
  nextRow: Row<T>,
  prevRow: Row<T>
) => {
  const { nextPeriod, prevPeriod } = getDates(nextRow, prevRow);
  return Number(nextPeriod) - Number(prevPeriod);
};

export const sortByMonth = <T extends { period: string }>(
  nextRow: Row<T>,
  prevRow: Row<T>
) => {
  const { nextPeriod, prevPeriod } = getDates(nextRow, prevRow);

  const [nextMonth, nextYear] =
    nextPeriod.replace(/\(|\)/g, "").split(" ") || [];
  const [prevMonth, prevYear] =
    prevPeriod.replace(/\(|\)/g, "").split(" ") || [];

  if (!nextMonth || !nextYear || !prevMonth || !prevYear) {
    throw new Error("Invalid period field for given month period");
  }

  if (nextYear === prevYear) {
    const nextMonthIndex = POLISH_MONTH_NAMES.findIndex(
      m => capitalize(m) === nextMonth
    );
    const prevMonthIndex = POLISH_MONTH_NAMES.findIndex(
      m => capitalize(m) === prevMonth
    );

    if (nextMonthIndex === -1 || prevMonthIndex === -1) {
      throw new Error(
        `Invalid month value ${nextMonth} and/or ${prevMonth}  is not proper month value`
      );
    }
    return nextMonthIndex - prevMonthIndex;
  }

  return Number(nextYear) - Number(prevYear);
};

export const sortTypeByPeriod = (period: string, periodField = "period") => {
  switch (period) {
    case PERIOD_TYPE.DAYS:
      return sortByDate(periodField);
    case PERIOD_TYPE.YEAR:
      return sortByNumericString;
    case PERIOD_TYPE.MONTHS:
      return sortByMonth;
    case PERIOD_TYPE.WEEKS:
    case PERIOD_TYPE.TOTAL:
    default:
      return "alphanumeric";
  }
};

// matches whitespace and currency symbol (PLN)
const CURRENCY_OR_WHITE_SPACE = new RegExp(`\\s|${CURRENCY}`, "g");

const parseNumericValue = (worthSum: string) => {
  const worthSumNumericString = worthSum
    .replace(CURRENCY_OR_WHITE_SPACE, "")
    .replace(",", ".");

  return parseFloat(worthSumNumericString);
};

export const sortTypeByFloatValue = <T extends { [key: string]: string }>(
  key: Keys<T>
) => (nextRow: Row<T>, prevRow: Row<T>) => {
  const nextWorthSum = nextRow.values[key];
  const prevWorthSum = prevRow.values[key];

  if (nextWorthSum === NO_DATA) return -1;
  if (prevWorthSum === NO_DATA) return 1;

  return parseNumericValue(nextWorthSum) - parseNumericValue(prevWorthSum);
};

export const sortTypeByAlphanumericLocale = <
  T extends { [key: string]: string }
>(
  key: Keys<T>
) => (nextRow: Row<T>, prevRow: Row<T>) => {
  if (typeof nextRow.values[key] === "string") {
    return nextRow.original[key].localeCompare(prevRow.original[key], "pl");
  }
  if (typeof nextRow.original[key] === "boolean") {
    if (nextRow.original[key] === prevRow.original[key]) return 0;
    return nextRow.original[key] ? 1 : -1;
  }
  return nextRow.original[key].localeCompare(prevRow.original[key]);
};
