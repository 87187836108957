import { ParsedQuery } from "query-string";
import { omit } from "ramda";

import { USER_ROLES } from "utils";
import { POWER_USER_QP } from "utils/defaultQueryParams";
import { Values } from "utils/types";

export const adjustParamsByRole = (
  role: Values<typeof USER_ROLES>,
  params: ParsedQuery<string | string[]>
) => {
  const POWER_USERS = ([
    USER_ROLES.SUPERUSER,
    USER_ROLES.CATMAN
  ] as unknown) as Values<typeof USER_ROLES>;

  if (!POWER_USERS.includes(role)) {
    const powerUserParams = Object.keys(POWER_USER_QP);
    return omit(powerUserParams, params);
  }

  return params;
};
