import { omit } from "ramda";

import {
  ReceiptsSummary,
  ReceiptsSummaryApi
} from "pages/Reports/types/summary";

import { snake2camel } from "../snake2camel";

export const normalizeReceiptsSummary = (summary: ReceiptsSummaryApi) => {
  return {
    unit: summary.unit?.toLowerCase() || "",
    ...Object.keys(omit(["unit"], summary)).reduce(
      (acc, key) => ({
        ...acc,
        [snake2camel(key)]: summary[key as keyof ReceiptsSummaryApi] ?? null
      }),
      {}
    )
  } as ReceiptsSummary;
};
