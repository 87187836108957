import React from "react";
import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";

import { SpinnerLoader, Text } from "components/atoms";
import {
  ADLoginURLSelector,
  getAdLoginUrl,
  isLoginLoadingSelector,
  isPreloginErrorSelector
} from "pages/Login/redux/reducer";
import { userRoleSelector, userSelector } from "store/reducers/userReducer";
import { pushPageView } from "utils";
import { AD_LOGIN_URL } from "utils/googleTagManager/getPageTitle";

import s from "./prelogin.module.scss";

export const PreloginRedirect = () => {
  const dispatch = useDispatch();

  const ADLoginUrl = useSelector(ADLoginURLSelector);
  const isError = useSelector(isPreloginErrorSelector);
  const isLoading = useSelector(isLoginLoadingSelector);
  const userRole = useSelector(userRoleSelector);
  const user = useSelector(userSelector);

  React.useEffect(() => {
    if (!ADLoginUrl && !isLoading && !isError) {
      dispatch(getAdLoginUrl());
    }

    if (ADLoginUrl) {
      const pagePath = AD_LOGIN_URL + window.location.search;
      pushPageView({
        pathname: AD_LOGIN_URL,
        pagePath,
        userType: userRole,
        userId: user.id
      });
      window.location.assign(ADLoginUrl);
    }
  }, [ADLoginUrl, dispatch, isError, isLoading, userRole, user.id]);

  return (
    <div className={s.textWrapper}>
      {isError ? (
        <Text className={cn(s.regularText, s.error)}>
          Nie udało się przekierować na platformę microsoftonline.com{" "}
          <button
            className={s.retryButton}
            onClick={() => dispatch(getAdLoginUrl())}
          >
            spróbuj ponownie
          </button>
        </Text>
      ) : (
        <>
          <Text className={s.regularText}>
            Trwa przekierowanie na platformę microsoftonline.com
          </Text>
          <div className={s.loaderContainer}>
            <SpinnerLoader />
          </div>
        </>
      )}
    </div>
  );
};
