interface IApiRequestError extends Error {
  code: string;
}

export class ApiRequestError extends Error {
  code: string;
  constructor({ code, message }: IApiRequestError) {
    super();
    this.message = message;
    this.code = code;
  }
}
