import { useFiltersQP } from "hooks";

const LEGEND_ITEMS_TYPE = {
  PRODUCT: "product",
  SUBRAND: "subrand",
  BRAND: "brand",
  CATEGORY: "category"
};

export const useLegendItemsType = () => {
  const { productsQP, subBrandQP, brandQP } = useFiltersQP();

  if (productsQP.length) return LEGEND_ITEMS_TYPE.PRODUCT;
  if (subBrandQP.length) return LEGEND_ITEMS_TYPE.SUBRAND;
  if (brandQP.length) return LEGEND_ITEMS_TYPE.BRAND;
  return LEGEND_ITEMS_TYPE.CATEGORY;
};
