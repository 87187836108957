import { useSelector } from "react-redux";

import { TourHooks } from "components/organisms/Tour/hooks/useTours";
import { useZappkaTransaction } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import { isClientTypeMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import {
  firstChartDataTypeSelector,
  queryParamsSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import {
  BOOL_STRING_VALUES,
  CHART_DATA_TYPE,
  CLIENT_TYPES,
  PERIOD_TYPE
} from "utils";
import { QP } from "utils/defaultQueryParams";

export const ZAPPKA_SHARES_SUFFIX = "_ONLINE_SHARE";

const ZAPPKA_SHARES_ENABLED_METRICS = [
  CHART_DATA_TYPE.VALUE,
  CHART_DATA_TYPE.QUANTITY,
  CHART_DATA_TYPE.NET_QUANTITY_SALES,
  CHART_DATA_TYPE.RECEIPTS_QUANTITY
] as string[];

export const useZappkaSharesCheckboxDisabled = () => {
  const firstChartType = useSelector(firstChartDataTypeSelector);
  const secondChartType = useSelector(secondChartDataTypeSelector);
  const params = useSelector(queryParamsSelector);
  const { value: transaction } = useZappkaTransaction();
  const periodQP = String(params[QP.CHART_PERIOD] || "");
  const clientQP = String(params[QP.CLIENT] || "");

  let first = !ZAPPKA_SHARES_ENABLED_METRICS.includes(firstChartType);
  let second = !ZAPPKA_SHARES_ENABLED_METRICS.includes(secondChartType);

  if (periodQP === PERIOD_TYPE.RANKING.toLowerCase()) {
    // [PMD-4640]: on ranking second data type should be disabled if first one is selected
    if (firstChartType.length > 0) {
      second = true;
    }
    // [PMD-4640]: on ranking checkboxes should be disabled when client type is not "all"
    if ((transaction || clientQP) !== CLIENT_TYPES.ALL) {
      first = true;
      second = true;
    }
  }

  return { first, second };
};

export const useZappkaSharesCheckboxState = () => {
  const params = useSelector(queryParamsSelector);
  const disabled = useZappkaSharesCheckboxDisabled();

  return {
    first:
      !disabled.first &&
      params[QP.ZAPPKA_SHARES_FIRST] === BOOL_STRING_VALUES.TRUE,
    second:
      !disabled.second &&
      params[QP.ZAPPKA_SHARES_SECOND] === BOOL_STRING_VALUES.TRUE
  };
};

export const useGetZappkaSharesSuffix = () => {
  const state = useZappkaSharesCheckboxState();

  return (index: number) =>
    (state.first && index === 0) || (state.second && index === 1)
      ? ZAPPKA_SHARES_SUFFIX
      : "";
};

export const useShowZappkaSharesTour = () => {
  const showZappkaSharesCheckbox = useSelector(isClientTypeMultiChoiceSelector);
  const [{ isSharesCheckboxTourHidden }] = TourHooks.useTours();
  return showZappkaSharesCheckbox && !isSharesCheckboxTourHidden;
};
