import { Dispatch, SetStateAction, useEffect, useRef } from "react";

import { axisLeft, ScaleBand, select } from "d3";

import c from "pages/Reports/partials/Chart/common.module.scss";
import { Nullable } from "utils/types";

import { HorizontalChart } from "./utils/getRankingData";

interface Props {
  scaleY: ScaleBand<string>;
  setMaxLabelWidth: Dispatch<SetStateAction<number>>;
  data: HorizontalChart[];
  onMouse: (tooltip: Nullable<HorizontalChart>) => () => void;
}

export const LeftAxis = ({
  scaleY,
  setMaxLabelWidth,
  data,
  onMouse
}: Props) => {
  const axisLeftRef = useRef<Nullable<SVGGElement>>(null);

  useEffect(() => {
    if (!axisLeftRef.current) return;

    const leftAxis = select(axisLeftRef.current).call(
      axisLeft(scaleY).tickFormat(function(id) {
        return data.find(rect => String(rect.id) === id)?.label || "";
      })
    );
    let maxLabelWidth = 0;

    leftAxis
      .selectAll("text")
      .each(function() {
        const self = select(this);
        // @ts-ignore TS doesn't recognize node's methods
        let textWidth = self.node().getComputedTextLength();

        if (textWidth > maxLabelWidth) {
          maxLabelWidth = textWidth;
        }
      })
      .on("mouseenter", value => {
        const tooltip = data.find(({ label }) => label === value) || null;
        onMouse(tooltip)();
      })
      .on("mouseleave", onMouse(null));

    leftAxis.select(".domain").remove();
    leftAxis.selectAll(".tick > line").remove();
    setMaxLabelWidth(maxLabelWidth);
  }, [data, onMouse, scaleY, setMaxLabelWidth]);

  return (
    <g
      ref={axisLeftRef}
      className={c.text}
      data-testid="ranking-product-names"
    />
  );
};
