import {
  LocationSummary,
  LocationSummaryApi
} from "pages/Reports/types/summary";

import { snake2camel } from "../snake2camel";

export const normalizeLocationSummary = (
  summary: LocationSummaryApi
): LocationSummary => {
  return {
    ...Object.keys(summary).reduce(
      (acc, key) => ({
        ...acc,
        [snake2camel(key)]: summary[key as keyof LocationSummaryApi] ?? null
      }),
      {}
    )
  } as LocationSummary;
};
