import * as React from "react";
import { useDispatch } from "react-redux";

import { Button, NiceScrollbar } from "components/atoms";
import { acceptCookieConsentSettings } from "pages/Admin/redux/reducers/cookiesSettingsReducer";
import { USER_ANALYTICS_COOKIE_NAME } from "utils";
import {
  COOKIES_DETAILS_URL,
  PRIVACY_POLICY_URL,
  TRUSTED_PARTNERS_URL
} from "utils/constants";

import s from "./cookieConsent.module.scss";
import CookieConsentDetails from "./CookieConsentDetails";
import { cookiesContent } from "./CookieConsentSettings";

export const CookieConsent: React.FC = () => {
  const dispatch = useDispatch();

  const handleSubmit = (allowAnalytics: boolean) =>
    dispatch(
      acceptCookieConsentSettings({
        [USER_ANALYTICS_COOKIE_NAME]: allowAnalytics
      })
    );

  return (
    <aside className={s.cookieConsent}>
      <div className={s.wrapper}>
        <NiceScrollbar
          autoHeight
          autoHeightMax={200}
          className={s.content}
          hideHorizontal
        >
          <CookieConsentDetails />
          <p className={s.details}>
            Możesz także zapoznać się z listą naszych{" "}
            <a
              href={TRUSTED_PARTNERS_URL}
              className={s.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Zaufanych Partnerów
            </a>{" "}
            i dowiedzieć się, jakie pliki cookies są instalowane przez
            poszczególne podmioty.
          </p>
          <ul>
            {cookiesContent.map(({ type, title, description }) => (
              <li className={s.cookieItem} key={type}>
                <span className={s.cookieLabel}>{title}</span>
                <p>{description}</p>
              </li>
            ))}
          </ul>
          <p className={s.details}>
            <span>
              Więcej informacji o przetwarzaniu danych osobowych, w tym o
              przysługujących Ci prawach, znajduje się w naszej{" "}
            </span>
            <a
              href={PRIVACY_POLICY_URL}
              className={s.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Polityce Prywatności
            </a>
            <span>.</span>
          </p>
          <a
            href={COOKIES_DETAILS_URL}
            className={s.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Szczegóły dot. dostawców cookies
          </a>
        </NiceScrollbar>
        <div className={s.buttons}>
          <Button className={s.acceptButton} onClick={() => handleSubmit(true)}>
            Akceptuję wszystkie cookies
          </Button>
          <Button
            className={s.declineButton}
            onClick={() => handleSubmit(false)}
          >
            Akceptuję tylko cookies serwisowe
          </Button>
        </div>
      </div>
    </aside>
  );
};
