import { decode } from "js-base64";

import {
  Insight,
  InsightApi,
  InsightDataApi,
  SingleInsightData
} from "pages/Insights/utils/types";

export const transformSingleInsight = (
  data: InsightDataApi
): SingleInsightData => {
  return {
    insightId: data.insight_id,
    userId: data.user_id,
    insightName: data.insight_name,
    insightBulletContent: decode(data.insight_bullet_content),
    insightUrl: data.insight_url,
    timestamp: data.timestamp,
    validFrom: data.valid_from,
    validTo: data.valid_to,
    isDeleted: data.is_deleted,
    isFavourite: data.is_favourite,
    rateLevel: data.rate_level,
    insightTitle: data.insight_title,
    userContactEmail: data.user_contact_email,
    vendorName: data.vendor_name,
    isNew: data.is_new,
    reportType: data.report_type,
    insightContentSentiment: data.insight_content_sentiment,
    allowReloadPage: data.allow_reload_page || false,
    explainability: data.explainability || null,
    similarity: data.similarity
  };
};

export const transformInsightApiData = (apiData: InsightApi): Insight => {
  return {
    insights: apiData.insights.map(transformSingleInsight),
    page: apiData.page,
    totalInsightsCount: apiData.total_insights_count,
    totalPages: apiData.total_pages
  };
};
