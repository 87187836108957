import * as React from "react";
import { useSelector } from "react-redux";

import { useLocationChartData } from "api/location/mappers/useLocationChartData";

import { ChartSection } from "pages/Reports/partials/Chart/StandardChart/ChartSection";
import { chartPeriodSelector } from "store/selectors/routerSelectors";

import { CHART_PERIODS } from "../constants";
import { VoivodeshipDetailed } from "../Voivodeship/VoivodeshipDetailed";

const Charts = () => {
  const lineChart = useLocationChartData({});
  return <ChartSection lineChart={lineChart} />;
};

const MapChartDetailed = () => {
  const chartPeriod = useSelector(chartPeriodSelector);

  return chartPeriod === CHART_PERIODS.TOTAL ? (
    <VoivodeshipDetailed />
  ) : (
    <Charts />
  );
};

export default MapChartDetailed;
