import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { isAgreementAcceptedSelector } from "store/reducers/userReducer";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { Nullable } from "utils/types";

interface NewsletterApi {
  count: number;
  next: Nullable<string>;
  previous: Nullable<string>;
  results: {
    created_at: string;
    title: string;
    text: string;
  }[];
}

export const useNewsletter = (page: number) => {
  const isAgreementAccepted = useSelector(isAgreementAcceptedSelector);
  const search = `?limit=10&offset=${(page - 1) * 10}`;

  return useQuery({
    queryKey: ["newsletter", search],
    queryFn: async () => {
      const { data } = await HTTP.get<NewsletterApi>(
        REST_API_ENDPOINTS.NEWSLETTER + search
      );
      return data;
    },
    enabled: isAgreementAccepted
  });
};
