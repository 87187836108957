import { CHART_FONT_COLORS } from "utils/constants";

import s from "./chartValueTiles.module.scss";

export interface SingleTileData {
  backgroundColor: string;
  firstValue: string;
  secondValue?: string;
  onClick: () => void;
  opacity: string;
}

export const ChartValueSingleTile = ({ tile }: { tile: SingleTileData }) => {
  if (!tile) return null;

  const color = CHART_FONT_COLORS[tile.backgroundColor];

  return (
    <div
      className={s.tileBox}
      style={{
        backgroundColor: tile.backgroundColor,
        color,
        opacity: tile.opacity
      }}
      onDoubleClick={tile.onClick}
    >
      <p>{tile.firstValue}</p>
      {tile.secondValue && <p>({tile.secondValue})</p>}
    </div>
  );
};
