import { prop, uniqBy } from "ramda";

import { ProductItem } from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";

const removeExclamation = (item: string) => item.replace("!", "").trim();

export const getSortedUniqProducts = (items: ProductItem[]) => {
  const uniqItems = uniqBy(prop("value"), items);

  const sortedItems = uniqItems.sort((a: ProductItem, b: ProductItem) => {
    const labelA = removeExclamation(a.label);
    const labelB = removeExclamation(b.label);

    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
  });

  return sortedItems;
};
