import { useDispatch, useSelector } from "react-redux";

import { ChartData } from "components/D3/types";
import { useChartScales } from "pages/Reports/partials/Chart/StandardChart/utils";
import {
  ChartOnClickAction,
  timepointSelector
} from "pages/Reports/redux/reducers/chartReducer";
import { areTilesHiddenSelector } from "store/selectors/routerSelectors";
import { BAR_RADIUS } from "store/utils/chartUtils";
import { NO_DATA_SHORT } from "utils/constants";
import { round } from "utils/round";

import { Bar } from "./Bar";
import { scaleAndVertical } from "./utils";
import { useChartValueTiles } from "./utils/useChartValueTiles";

interface Props {
  lineChart: ChartData;
  height: number;
  onMouseMoveBar: (index: number) => void;
  onMouseLeaveBar: () => void;
  onTimelineClick: ChartOnClickAction;
  originalWidth: number;
}

export const SegmentsBarsGroup = ({
  lineChart,
  height,
  onMouseMoveBar,
  onMouseLeaveBar,
  onTimelineClick,
  originalWidth
}: Props) => {
  const { yScale, xScaleBand } = useChartScales(lineChart, originalWidth);
  const [tileState, tileActions] = useChartValueTiles();
  const areTilesHidden = useSelector(areTilesHiddenSelector);
  const dispatch = useDispatch();
  const timepoint = useSelector(timepointSelector);
  const data = lineChart.leftChart?.chart || null;

  if (!data?.length) return null;

  const grouped = data.map(
    ({ label, id, color, timeline, lastYearTimeline }) => {
      const base = {
        label,
        color,
        id,
        valueX: timeline[0].valueX,
        valuesY: [timeline[0].valueY]
      };

      if (lastYearTimeline) {
        base.valuesY.push(lastYearTimeline[0].valueY);
      }

      return base;
    }
  );

  const bandWidth = xScaleBand.bandwidth();

  const ticks = yScale.ticks();
  const step = (ticks[0] - ticks[1]) / 2;

  return (
    <>
      {grouped.map(({ label, id, color, valueX, valuesY }, groupIndex) => {
        const key = `bars-group-${valueX}`;
        const hasBackData = valuesY.length === 2;

        const barWithGap = bandWidth - 5;

        const barWidth = Math.min(
          hasBackData ? barWithGap / 2 : barWithGap,
          30
        );

        const translateX =
          (xScaleBand(valueX) || 0) + bandWidth / 2 - barWidth / 2;

        return (
          <g key={key} transform={`translate(${translateX}, 0)`}>
            {valuesY.map((valueY, barIndex) => {
              const value = valueY || 0;
              const { offsetTop, scaledHeight } = scaleAndVertical(
                yScale,
                height,
                value
              );

              const isHistoricalBar = barIndex === 1;

              const horizontalPosX = hasBackData
                ? isHistoricalBar
                  ? barWidth / 2 + 1
                  : -barWidth / 2 - 1
                : 0;

              const tile = tileState.tiles.find(
                tile =>
                  tile.lineName === id &&
                  !tile.isRightChart &&
                  tile.isHistorical === isHistoricalBar
              );

              const tileData =
                tile && !areTilesHidden
                  ? {
                      backgroundColor: color,
                      firstValue: valueY ? round(valueY) : NO_DATA_SHORT,
                      onClick: () => tileActions.handleTiles([tile]),
                      opacity: "1"
                    }
                  : null;

              return (
                <Bar
                  key={`${key}:bar-${barIndex}`}
                  id={label}
                  showFakeBar={Math.abs(value) < Math.abs(step)}
                  horizontalPos={horizontalPosX}
                  verticalPos={offsetTop}
                  width={barWidth}
                  height={scaledHeight}
                  radius={
                    barWidth >= BAR_RADIUS.BIG
                      ? BAR_RADIUS.BIG
                      : BAR_RADIUS.SMALL
                  }
                  color={isHistoricalBar ? `${color}40` : color}
                  onMouseMove={() => onMouseMoveBar(groupIndex)}
                  onMouseLeave={onMouseLeaveBar}
                  onTimelineClick={() => dispatch(onTimelineClick(timepoint))}
                  tile={tileData}
                  isHistorical={isHistoricalBar}
                />
              );
            })}
          </g>
        );
      })}
    </>
  );
};
