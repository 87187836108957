import { useMemo } from "react";
import { useSelector } from "react-redux";

import { uniq, without } from "ramda";

import { hiddenPeriodsSelector } from "pages/Reports/redux/reducers/periods/selectors";
import { queryParamsSelector } from "store/selectors/routerSelectors";
import { PERIOD_TYPE } from "utils";
import { translateFeParamsToBeParams } from "utils/translateFEParamsToBEParams";
import { Periods } from "utils/types";
import { usePeriodsToFetch } from "utils/usePeriodsToFetch";

import { useReportQueryString } from "./useReportQueryString";

export const useFetchParams = () => {
  const params = useSelector(queryParamsSelector);
  const hiddenPeriods = useSelector(hiddenPeriodsSelector);
  const reportQueryString = useReportQueryString();
  const periodsToFetch = usePeriodsToFetch();

  const uniqPeriods = (useMemo(
    () =>
      without(
        ["", PERIOD_TYPE.RANKING, ...hiddenPeriods],
        uniq(periodsToFetch)
      ),
    [hiddenPeriods, periodsToFetch]
  ) as unknown) as Omit<Periods[], "RANKING">;

  const queryKeys = uniqPeriods.map(period => ({
    query: translateFeParamsToBeParams(reportQueryString, period),
    period
  }));

  const isG1Selected = [
    params.category1,
    params.category2,
    params.category3
  ].some(Boolean);

  return {
    queryKeys,
    isG1Selected
  };
};
