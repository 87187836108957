import React, { useState } from "react";

import cn from "classnames";

import { Text } from "components/atoms";
import { ChevronDown, ChevronUp } from "components/atoms/Icon";
import { InputText } from "components/molecules";
import { ChartDropdownIndex } from "components/molecules/ChartHeader/ChartHeader";
import c from "components/molecules/ChartHeader/chartHeader.module.scss";
import { DropdownType } from "components/molecules/ChartHeader/hooks/useDropdownTypes";
import s from "pages/Reports/sections/NewChartDropdown/chartDropdown.module.scss";
import { ChartDropdownBox } from "pages/Reports/sections/NewChartDropdown/ChartDropdownBox";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { toArray } from "store/utils";
import { getPolishLabelForChartHeader, ICON_SIZES } from "utils";

import { useShowZappkaSharesTour } from "./ZappkaSharesCheckbox/hooks";

type Props = {
  id?: { base?: string; inputWrapper?: string };
  className?: string;
  onChangeDataType: (
    dataType: ChartDataTypes,
    dropdownType: DropdownType
  ) => void;
  dropdownType: DropdownType;
  checkedDataType: ChartDataTypes;
  prefix: string;
  dropdownIndex: ChartDropdownIndex;
};

export const ChartDropdown: React.FC<Props> = ({
  id,
  className,
  onChangeDataType,
  dropdownType,
  checkedDataType,
  prefix,
  dropdownIndex
}) => {
  const [isOpen, setOpen] = useState(false);
  const disableClose = useShowZappkaSharesTour();

  const checkedDataTypeArray = toArray(checkedDataType);
  const checkedDataTypeLabel = getPolishLabelForChartHeader(
    checkedDataTypeArray[0]
  );

  const handleChange = (type: ChartDataTypes) => {
    if (type !== null) {
      onChangeDataType(type, dropdownType);
    }
  };

  return (
    <div id={id?.base} className={cn(c.wrapper, className)}>
      {prefix && <Text className={c.prefix}>{prefix}</Text>}
      <div className={s.relative}>
        <div
          id={id?.inputWrapper}
          className={s.inputWrapper}
          data-testid="chart-checkbox-dropdown"
        >
          <InputText
            className={{ input: s.input }}
            readOnly
            value={checkedDataTypeLabel}
            onClick={() => setOpen(!isOpen)}
            placeholder="Wybierz"
          />
          {isOpen ? (
            <ChevronUp className={s.chevronIcon} size={ICON_SIZES.LARGE} />
          ) : (
            <ChevronDown className={s.chevronIcon} size={ICON_SIZES.LARGE} />
          )}
        </div>
        {isOpen && (
          <ChartDropdownBox
            onClose={() => {
              if (!disableClose) setOpen(false);
            }}
            checkedDataType={checkedDataType}
            handleChange={handleChange}
            dropdownIndex={dropdownIndex}
          />
        )}
      </div>
    </div>
  );
};
