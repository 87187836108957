import React from "react";

import cn from "classnames";

import { Button } from "components/atoms";

import s from "./tiledBox.module.scss";

interface Props {
  className?: string;
  icon: string;
  header: string;
  description: string;
  button: {
    text: string;
    onClick: VoidFunction;
    ga: { [key: string]: string };
  };
  secondButton?: {
    text: string;
    onClick: VoidFunction;
    ga: { [key: string]: string };
  };
}

export const TiledBox = ({
  className,
  icon,
  header,
  description,
  button,
  secondButton
}: Props) => (
  <div className={cn(s.tiledBox, className)}>
    <img className={s.icon} src={icon} alt={header} />
    <div className={s.textWrapper}>
      <span className={s.header}>{header}</span>
      <span className={s.description}>{description}</span>
      <div className={s.buttons}>
        <Button className={s.button} onClick={button.onClick} {...button.ga}>
          {button.text}
        </Button>
        {secondButton && (
          <Button
            className={s.button}
            onClick={secondButton.onClick}
            {...secondButton.ga}
          >
            {secondButton.text}
          </Button>
        )}
      </div>
    </div>
  </div>
);
