import { useEffect, useState } from "react";
import { Step } from "react-joyride";
import { useSelector } from "react-redux";

import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { isBackDataSelectableSelector } from "components/molecules/DateDropdownMenu/HistoricalSection/selectors";
import { useZappkaTransaction } from "pages/Reports/partials/Chart/StandardChart/components/WithZappkaSelect";
import { useFullScreen } from "pages/Reports/partials/FullScreenWrapper/FullScreenWrapper";
import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { useVisibleList } from "pages/Reports/redux/reducers/filters/hooks/useVisibleFilters";
import { validatedChartPeriodsSelector } from "pages/Reports/redux/reducers/periods/selectors";
import {
  hasNaturalUnitsSelector,
  hasSidebarSelector,
  hasWeatherDataSelector,
  isClientTypeMultiChoiceSelector,
  isShowDatasetSelectEnabledSelector,
  showAggregationDropdownSelector
} from "pages/Reports/redux/selectors/reportsSelectors";
import { useSellOnboardingModalHidden } from "pages/Reports/sections/Sell/SellOnboardingModal";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { BOOL_STRING_VALUES, isThisPage, PERIOD_TYPE, TOURS_KEYS } from "utils";
import { isLogisticsSegmentsEnabled } from "utils/constants";

import {
  AGGREGATION_DROPDOWN_STEPS,
  AREA_CHART_STEPS,
  ATTRIBUTES_STEPS,
  BRAND_STEPS,
  CHART_TILES_STEPS,
  CHART_TYPE_STEPS,
  CLIENT_ZAPPKA_FILTER_STEPS,
  DATASET_SELECT_STEPS,
  DRAGGABLE_TILES_STEPS,
  EXPANDABLE_SIDEBAR_STEPS,
  FULL_SCREEN_STEPS,
  HOURS_QUANTITY_METRICS_STEPS,
  INCREMENTAL_CHART_STEPS,
  LOGISTICS_SEGMENTS_STEPS,
  LOYALTY_METRICS_STEPS,
  MULTIPACK_METRICS_STEPS,
  NATUAL_UNITS_STEPS,
  PRODUCT_DROPDOWN_SORTING_CHECKBOX_STEPS,
  PRODUCT_HIERARCHY_STEPS,
  PROMO_WEEKS_STEPS,
  RANKING_STEPS,
  REF_CAT_STEPS,
  SEGMENTS_GROUP_BY_STEPS,
  TOTAL_STEPS,
  WEATHER_DATA_STEPS,
  ZAPPKA_TRANSACTIONS_STEPS
} from "../constants";
import { useDataReadyFlag } from "../hooks/useDataReadyFlag";
import { TourHooks } from "../hooks/useTours";
import { Tour } from "../Tour";

const {
  IS_BRAND_TOUR_HIDDEN,
  IS_NATURAL_UNITS_TOUR_HIDDEN,
  IS_PROMO_WEEK_TOUR_HIDDEN,
  IS_RANKING_TOUR_HIDDEN,
  IS_REF_CAT_TOUR_HIDDEN,
  IS_AGGREGATION_DROPDOWN_TOUR_HIDDEN,
  IS_TOTAL_TOUR_HIDDEN,
  IS_PRODUCT_HIERARCHY_TOUR_HIDDEN,
  IS_WEATHER_DATA_TOUR_HIDDEN,
  IS_EXPANDABLE_SIDEBAR_TOUR_HIDDEN,
  IS_CHART_TYPE_TOUR_HIDDEN,
  IS_AREA_CHART_TOUR_HIDDEN,
  IS_INCREMENTAL_CHART_TOUR_HIDDEN,
  IS_GROUP_BY_SEGMENTS_TOUR_HIDDEN,
  IS_MULTIPACK_METRICS_TOUR_HIDDEN,
  IS_HOURS_QUANTITY_METRIC_TOUR_HIDDEN,
  IS_LOGISTICS_SEGMENTS_TOUR_HIDDEN,
  IS_LOYALTY_METRICS_TOUR_HIDDEN,
  IS_CHART_TILES_TOUR_HIDDEN,
  IS_DRAGGABLE_TILES_TOUR_HIDDEN,
  IS_PRODUCT_DROPDOWN_SORTING_TOUR_HIDDEN,
  IS_CLIENT_ZAPPKA_FILTER_TOUR_HIDDEN,
  IS_ZAPPKA_TRANSACTION_TOUR_HIDDEN,
  IS_DATASET_SELECT_TOUR_HIDDEN,
  IS_FULL_SCREEN_TOUR_HIDDEN,
  IS_ATTRIBUTES_TOUR_HIDDEN
} = TOURS_KEYS;
const { TRUE } = BOOL_STRING_VALUES;
const { ATTRIBUTES, BRAND, REFERENCE_CATEGORY } = FILTER_NAMES;
const {
  MULTIPACK_PATH,
  SEGMENTS_PATH,
  HOURS_PATH,
  LOYALTY_PATH,
  LOGISTICS_PATH
} = REPORTS_FULL_PATHS;

interface Sequence {
  key: string;
  steps: Step[];
  isReady: boolean;
}

const useReportsTour = () => {
  const [sequence, setSequence] = useState<Sequence[]>([]);

  const activeTour = sequence.find(
    ({ isReady, key }) => isReady && localStorage.getItem(key) !== TRUE
  );

  const handleClose = () => {
    if (!activeTour) return;

    localStorage.setItem(activeTour.key, TRUE);

    setSequence(
      sequence.map(tour =>
        tour.key === activeTour.key ? { ...tour, isHidden: true } : tour
      )
    );
  };

  const periods = useSelector(validatedChartPeriodsSelector);
  const pathname = useSelector(pathnameSelector);
  const filters = useVisibleList();
  const {
    isAreaChart,
    isIncrementalChart,
    isIncrementalComboChart
  } = useChartTypeBoolean();
  const { options: transaction } = useZappkaTransaction();

  const isSellOnboardingModalHidden = useSellOnboardingModalHidden();
  const [
    {
      isHoursOnboaringModalHidden,
      isLoyaltyOnboaringModalHidden,
      isLogisticsOnboaringModalHidden
    }
  ] = TourHooks.useTours();
  const hasNaturalUnits = useSelector(hasNaturalUnitsSelector);
  const hasWeatherData = useSelector(hasWeatherDataSelector);
  const hasSidebar = useSelector(hasSidebarSelector);
  const hasAggregationDropdown = useSelector(showAggregationDropdownSelector);
  const isClientTypeMultiChoice = useSelector(isClientTypeMultiChoiceSelector);
  const isShowDatasetSelectEnabled = useSelector(
    isShowDatasetSelectEnabledSelector
  );
  const isBackDataSelectable = useSelector(isBackDataSelectableSelector);
  const { enabled: isFullScreenEnabled } = useFullScreen();
  const hasRanking = periods.includes(PERIOD_TYPE.RANKING);
  const hasTotal = periods.includes(PERIOD_TYPE.TOTAL);
  const hasPromoWeek = periods.includes(PERIOD_TYPE.PROMO_WEEKS);
  const hasAttributes = !!filters.find(({ name }) => name === ATTRIBUTES);
  const hasBrand = !!filters.find(({ name }) => name === BRAND);
  const hasRefCat = !!filters.find(({ name }) => name === REFERENCE_CATEGORY);
  const isHours = isThisPage(pathname, HOURS_PATH);
  const isMultipack = isThisPage(pathname, MULTIPACK_PATH);
  const isSegments = isThisPage(pathname, SEGMENTS_PATH);
  const isLoyalty = isThisPage(pathname, LOYALTY_PATH);
  const isLogistics = isThisPage(pathname, LOGISTICS_PATH);
  const isDatasetSelectReady =
    isShowDatasetSelectEnabled && isBackDataSelectable;

  useEffect(() => {
    if (!isSellOnboardingModalHidden) return;
    // tours has to be rendered in sequence due to the differences in targets access/deploy time
    setSequence([
      {
        key: IS_NATURAL_UNITS_TOUR_HIDDEN,
        steps: NATUAL_UNITS_STEPS,
        isReady: hasNaturalUnits
      },
      {
        key: IS_WEATHER_DATA_TOUR_HIDDEN,
        steps: WEATHER_DATA_STEPS,
        isReady: hasWeatherData
      },
      {
        key: IS_ATTRIBUTES_TOUR_HIDDEN,
        steps: ATTRIBUTES_STEPS,
        isReady: hasAttributes
      },
      {
        key: IS_BRAND_TOUR_HIDDEN,
        steps: BRAND_STEPS,
        isReady: hasBrand
      },
      {
        key: IS_PRODUCT_HIERARCHY_TOUR_HIDDEN,
        steps: PRODUCT_HIERARCHY_STEPS,
        isReady: true
      },
      {
        key: IS_REF_CAT_TOUR_HIDDEN,
        steps: REF_CAT_STEPS,
        isReady: hasRefCat
      },
      {
        key: IS_AGGREGATION_DROPDOWN_TOUR_HIDDEN,
        steps: AGGREGATION_DROPDOWN_STEPS,
        isReady: hasAggregationDropdown
      },
      {
        key: IS_RANKING_TOUR_HIDDEN,
        steps: RANKING_STEPS,
        isReady: hasRanking
      },
      {
        key: IS_TOTAL_TOUR_HIDDEN,
        steps: TOTAL_STEPS,
        isReady: hasTotal
      },
      {
        key: IS_PROMO_WEEK_TOUR_HIDDEN,
        steps: PROMO_WEEKS_STEPS,
        isReady: hasPromoWeek
      },
      {
        key: IS_EXPANDABLE_SIDEBAR_TOUR_HIDDEN,
        steps: EXPANDABLE_SIDEBAR_STEPS,
        isReady: hasSidebar
      },
      {
        key: IS_CHART_TYPE_TOUR_HIDDEN,
        steps: CHART_TYPE_STEPS,
        isReady: true
      },
      {
        key: IS_GROUP_BY_SEGMENTS_TOUR_HIDDEN,
        steps: SEGMENTS_GROUP_BY_STEPS,
        isReady: isSegments
      },
      {
        key: IS_AREA_CHART_TOUR_HIDDEN,
        steps: AREA_CHART_STEPS,
        isReady: isAreaChart
      },
      {
        key: IS_INCREMENTAL_CHART_TOUR_HIDDEN,
        steps: INCREMENTAL_CHART_STEPS,
        isReady: isIncrementalChart || isIncrementalComboChart
      },
      {
        key: IS_MULTIPACK_METRICS_TOUR_HIDDEN,
        steps: MULTIPACK_METRICS_STEPS,
        isReady: isMultipack
      },
      {
        key: IS_HOURS_QUANTITY_METRIC_TOUR_HIDDEN,
        steps: HOURS_QUANTITY_METRICS_STEPS,
        isReady: isHours && isHoursOnboaringModalHidden
      },
      {
        key: IS_LOGISTICS_SEGMENTS_TOUR_HIDDEN,
        steps: LOGISTICS_SEGMENTS_STEPS,
        isReady:
          isLogisticsSegmentsEnabled &&
          isLogistics &&
          isLogisticsOnboaringModalHidden
      },
      {
        key: IS_LOYALTY_METRICS_TOUR_HIDDEN,
        steps: LOYALTY_METRICS_STEPS,
        isReady: isLoyalty && isLoyaltyOnboaringModalHidden
      },
      {
        key: IS_CHART_TILES_TOUR_HIDDEN,
        steps: CHART_TILES_STEPS,
        isReady: true
      },
      {
        key: IS_DRAGGABLE_TILES_TOUR_HIDDEN,
        steps: DRAGGABLE_TILES_STEPS,
        isReady: true
      },
      {
        key: IS_PRODUCT_DROPDOWN_SORTING_TOUR_HIDDEN,
        steps: PRODUCT_DROPDOWN_SORTING_CHECKBOX_STEPS,
        isReady: true
      },
      {
        key: IS_CLIENT_ZAPPKA_FILTER_TOUR_HIDDEN,
        steps: CLIENT_ZAPPKA_FILTER_STEPS,
        isReady: isClientTypeMultiChoice
      },
      {
        key: IS_ZAPPKA_TRANSACTION_TOUR_HIDDEN,
        steps: ZAPPKA_TRANSACTIONS_STEPS,
        isReady: transaction.length > 1
      },
      {
        key: IS_DATASET_SELECT_TOUR_HIDDEN,
        steps: DATASET_SELECT_STEPS,
        isReady: isDatasetSelectReady
      },
      {
        key: IS_FULL_SCREEN_TOUR_HIDDEN,
        steps: FULL_SCREEN_STEPS,
        isReady: isFullScreenEnabled
      }
    ]);
  }, [
    hasAggregationDropdown,
    hasBrand,
    hasNaturalUnits,
    hasPromoWeek,
    hasRanking,
    hasRefCat,
    hasSidebar,
    hasTotal,
    hasWeatherData,
    isAreaChart,
    isClientTypeMultiChoice,
    isHours,
    isHoursOnboaringModalHidden,
    isIncrementalChart,
    isIncrementalComboChart,
    isLogistics,
    isLogisticsOnboaringModalHidden,
    isLoyalty,
    isLoyaltyOnboaringModalHidden,
    isMultipack,
    isSegments,
    transaction.length,
    isSellOnboardingModalHidden,
    isDatasetSelectReady,
    isFullScreenEnabled,
    hasAttributes
  ]);

  return { activeTour, handleClose };
};

export const ReportsTour = () => {
  const { activeTour, handleClose } = useReportsTour();
  const [isNavTourHidden] = TourHooks.useIsNavTourHidden();
  const isDataReady = useDataReadyFlag();
  const { visible: isFullScreen } = useFullScreen();

  if (isFullScreen || !isDataReady || !isNavTourHidden || !activeTour) {
    return null;
  }

  return (
    <Tour
      key={activeTour.key}
      run={activeTour.isReady}
      steps={activeTour.steps}
      onClose={handleClose}
    />
  );
};
