import { useMemo } from "react";
import { useSelector } from "react-redux";

import { ChartData } from "components/D3/types";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { Grid } from "pages/Reports/partials/Chart/StandardChart/Grid";
import { SegmentsGrid } from "pages/Reports/partials/Chart/StandardChart/SegmentsGrid";
import { getAxisTickValues } from "pages/Reports/partials/Chart/StandardChart/utils";
import { useChartScales } from "pages/Reports/partials/Chart/StandardChart/utils";
import { REPORTS_PATHS } from "pages/Reports/utils";
import { pathnameSelector } from "store/selectors/routerSelectors";

import { getSegmentsChartType } from "../utils/getSegmentsChartType";

type Props = {
  chartId: string;
  y: number;
  showTooltip: boolean;
  width: number;
  originalWidth: number;
  lineChart: ChartData;
};

export const GridChart: React.FC<Props> = ({
  chartId,
  y,
  showTooltip,
  width,
  lineChart,
  originalWidth
}) => {
  const { yScaleForGrid: yScale } = useChartScales(lineChart, originalWidth);
  const pathname = useSelector(pathnameSelector);
  const {
    isGroupedBarChart,
    isIncrementalChart,
    isIncrementalComboChart
  } = useChartTypeBoolean();
  const isSegmentsPage = pathname.includes(REPORTS_PATHS.SEGMENTS);

  const { domain, leftChart, rightChart, showBarChart } = lineChart;
  const segmentsChartType = getSegmentsChartType(lineChart);
  const isIndicatorAllowed =
    !showBarChart ||
    [isGroupedBarChart, isIncrementalChart, isIncrementalComboChart].some(
      Boolean
    );

  const ticks = useMemo(() => {
    if (leftChart) {
      return getAxisTickValues({
        min: leftChart?.minValue || 0,
        max: leftChart?.maxValue || 0,
        fillWithZero: true
      });
    }
    if (!leftChart && rightChart) {
      return getAxisTickValues({
        min: rightChart?.minValue || 0,
        max: rightChart?.maxValue || 0,
        fillWithZero: false
      });
    }
    return getAxisTickValues({
      min: 0,
      max: 1
    });
  }, [leftChart, rightChart]);

  if (isSegmentsPage) {
    return (
      <SegmentsGrid
        positionY={y}
        showTooltip={showTooltip}
        id={chartId}
        width={width}
        domain={domain}
        isLineChart={segmentsChartType !== "bar-chart"}
        tickValues={ticks}
        yScale={yScale}
      />
    );
  }

  return (
    <Grid
      domain={domain}
      tickValues={ticks}
      yScale={yScale}
      isIndicatorAllowed={isIndicatorAllowed}
      gridWidth={width}
      leftChart={leftChart}
    />
  );
};
