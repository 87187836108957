import { SortType } from "react-table";

import { Keys } from "utils/types";

type MetricsDetailsTableColumn = {
  Header: string;
  accessor: (row: MetricsDetailsTableMetrics) => string;
  id: Keys<MetricsDetailsTableMetrics>;
  className?: string;
  width?: string | number;
  minWidth?: string | number;
  sortType?: SortType;
};

export const METRICS_DETAILS_COLUMNS: MetricsDetailsTableColumn[] = [
  {
    Header: "Nazwa miary",
    id: "name",
    accessor: (row: MetricsDetailsTableMetrics) => row.name,
    sortType: "alphanumeric"
  },
  {
    Header: "Wartość",
    id: "value",
    accessor: (row: MetricsDetailsTableMetrics) => row.value,
    sortType: "alphanumeric"
  },
  {
    Header: "Opis miary",
    id: "description",
    accessor: (row: MetricsDetailsTableMetrics) => row.description,
    sortType: "alphanumeric",
    minWidth: 300
  }
];

export interface MetricsDetailsTableMetrics {
  name: string;
  value: string;
  description: string;
}

export const TABLE_DATA = [
  {
    name: "Liczba nowych klientów",
    value: "2301",
    description:
      "Tylu klientów (2301) kupiło w dniu 1.09.2022 kawę po raz pierwszy od 84 dni (czyli od 09.06.2022)"
  },
  {
    name: "% nowych klientów",
    value: "50,68%",
    description:
      "50,68% klientów, którzy nabyli kawę w dniu 1.09.022, kupiło ten produkt po raz pierwszy od 84 dni"
  },
  {
    name: "Liczba wszystkich powrotów",
    value: "556*",
    description:
      "Tyle razy (556) kawa została ponownie kupiona w okresie 1.09.2022 - 26.10.2022 przez osoby, które kupiły ten produkt po raz pierwszy w dniu 1.09.2022"
  },
  {
    name: "% wszystkich powrotów",
    value: "22,43%",
    description:
      "W okresie 1.09.2022 - 26.10.2022 było 22,43% powtórnych zakupów kawy w stosunku do wszystkich zakupów tego produktu z dnia 1.09.2022"
  },
  {
    name: "% powrotów w 1. tygodniu",
    value: "10,17%",
    description:
      "W okresie 1.09.2022 - 7.09.2022 było 10,17% powtórnych zakupów kawy w stosunku do wszystkich zakupów tego produktu z dnia 1.09.2022"
  },
  {
    name: "% powrotów w 2. tygodniu",
    value: "4,28%",
    description:
      "W okresie 7.09.2022 - 14.09.2022 było 4,28% powtórnych zakupów kawy w stosunku do wszystkich zakupów tego produktu z dnia 1.09.2022"
  },
  {
    name: "% powrotów w 3-4 tygodniu",
    value: "4,28%",
    description:
      "W okresie 15.09.2022 - 28.09.2022 było 4,28% powtórnych zakupów kawy w stosunku do wszystkich zakupów tego produktu z dnia 1.09.2022"
  },
  {
    name: "% powrotów w 5-6 tygodniu",
    value: "3,31%",
    description:
      "W okresie 29.09.2022 - 11.10.2022 było 3,31% powtórnych zakupów kawy w stosunku do wszystkich zakupów tego produktu z dnia 1.09.2022"
  },
  {
    name: "% powrotów w 7-8 tygodniu",
    value: "brak danych ze względu na zbyt wczesną weryfikację*",
    description: "Dane za ten okres będą dostępne po 25.10.2022"
  }
];
