import * as React from "react";

import cn from "classnames";

import { Text } from "components/atoms";
import { Tick, TooltipIcon } from "components/atoms/Icon";

import s from "./checkbox.module.scss";

interface Props {
  ga?: {
    [key: string]: string;
  };
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  label?: string;
  onLabelClick?: (event: React.MouseEvent<HTMLLabelElement>) => void;
  tooltipText?: string;
  className?: {
    base?: string;
    label?: string;
    icon?: string;
    checkbox?: string;
    iconWrapper?: string;
    tooltipIcon?: string;
  };
  isSmall?: boolean;
  testId?: string;
  value?: string | number;
  iconTooltipText?: string;
}

export const Checkbox = ({
  isChecked,
  onChange,
  label,
  onLabelClick,
  isDisabled = false,
  className = {},
  tooltipText,
  isSmall = false,
  testId = `checkbox-${label}`,
  value,
  ga,
  iconTooltipText
}: Props) => (
  <label
    className={cn(
      s.base,
      { [s.disabled]: isDisabled, [s.tooltip]: tooltipText },
      className.base
    )}
    data-tooltip={tooltipText}
    onClick={onLabelClick}
    {...ga}
  >
    <div
      className={cn(
        s.checkbox,
        { [s.checked]: isChecked, [s.disabled]: isDisabled },
        className.checkbox
      )}
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        readOnly={!onChange}
        disabled={isDisabled}
        data-testid={testId}
        data-active={isChecked}
        value={value}
      />
      <div className={cn(className.iconWrapper)}>
        <Tick className={cn(s.tick, className.icon)} />
      </div>
    </div>
    {label && (
      <Text
        className={cn(
          {
            [s.checked]: isChecked,
            [s.checkBoxLabelDisabled]: isDisabled,
            [s.checkBoxLabelSmall]: isSmall
          },
          className.label
        )}
        weight={isChecked ? "bold" : "normal"}
      >
        <span className={s.boldText} data-text={label}>
          {label}
        </span>
      </Text>
    )}
    {iconTooltipText && (
      <div
        className={cn(s.iconTooltipWrapper, className.tooltipIcon)}
        data-tooltip={iconTooltipText}
      >
        <TooltipIcon className={s.tooltipIcon} />
      </div>
    )}
  </label>
);
