import { connectRouter, routerMiddleware } from "connected-react-router";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { adminReducer } from "pages/Admin/redux/reducer";
import { agreementReducer } from "pages/Agreement/redux/agreementReducer";
import { loginReducer } from "pages/Login/redux/reducer";
import { reportsReducer } from "pages/Reports/redux/reducer";
import { userReducer } from "store/reducers/userReducer";

import { appReducer } from "./reducers/appReducer";
import { history } from "./utils";

const rootReducer = combineReducers({
  app: appReducer,
  reports: reportsReducer,
  router: connectRouter(history),
  login: loginReducer,
  admin: adminReducer,
  agreement: agreementReducer,
  user: userReducer
});

export type AppState = ReturnType<typeof rootReducer>;

const middlewares = [routerMiddleware(history), thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

// @ts-ignore
export const createNewStore = () => createStore(rootReducer, composedEnhancers);
export const store = createNewStore();
