import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import cn from "classnames";

import { Button, Text } from "components/atoms";
import { ChevronDown, TooltipIcon } from "components/atoms/Icon";
import { ChartData } from "components/D3/types";
import { Card } from "components/molecules";
import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useChartValueTiles } from "pages/Reports/partials/Chart/StandardChart/utils/useChartValueTiles";
import { useTilesMultiselect } from "pages/Reports/partials/Chart/StandardChart/utils/useTilesMultiselect";
import { ICON_SIZES, TEXT_WRAPPERS } from "utils";
import { pushChartSwitchEvent } from "utils/googleTagManager/dataLayer";

import { getChartTilesTooltipText } from "../ShowChartTiles/getChartTilesTooltipText";
import { ShowChartTiles } from "../ShowChartTiles/ShowChartTiles";
import s from "./tilesDropdown.module.scss";

export const TilesDropdown = ({ lineChart }: { lineChart: ChartData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLineChart, isGroupedBarChart } = useChartTypeBoolean();
  const { selectTiles } = useTilesMultiselect(lineChart);
  const [
    { selectAllTooltip, showBarChartLabels },
    tileActions
  ] = useChartValueTiles();
  const chartTilesCheckboxTooltip = getChartTilesTooltipText({ isLineChart });

  const buttons = [
    {
      label: "Pokaż wszystkie",
      onClick: () => {
        selectTiles("all");
        pushChartSwitchEvent("Labels all - on");
      },
      tooltip: selectAllTooltip
    },
    {
      label: "Pokaż dla pierwszej miary",
      onClick: () => {
        selectTiles("left");
        pushChartSwitchEvent("Labels left - on");
      },
      tooltip: "Włącz etykiety dla pierwszej (lewej) miary na wykresie"
    },
    {
      label: "Pokaż dla drugiej miary",
      onClick: () => {
        selectTiles("right");
        pushChartSwitchEvent("Labels right - on");
      },
      tooltip: "Włącz etykiety dla drugiej (prawej) miary na wykresie"
    },
    {
      label: "Wyłącz wszystkie",
      onClick: () => {
        tileActions.clearTiles();
        pushChartSwitchEvent("Labels all - off");
      },
      tooltip: "Wyłącz wszystkie etykiety na wykresie"
    }
  ];

  const handleGroupedBarChartLabelsToggle = () => {
    tileActions.updateShowBarChartLabels(!showBarChartLabels);
    pushChartSwitchEvent(
      showBarChartLabels ? "Labels KG chart - off" : "Labels KG chart - on"
    );
  };

  return (
    <div className={s.tilesDropdownWrapper}>
      <Text Wrapper={TEXT_WRAPPERS.SPAN} className={s.tilesDropdownLabel}>
        Etykiety
      </Text>
      <Button
        type="button"
        className={s.chevronButton}
        onClick={() => setIsOpen(!isOpen)}
      >
        <ChevronDown size={ICON_SIZES.NORMAL} className={s.chevron} />
      </Button>
      <div
        className={s.iconTooltipWrapper}
        data-tooltip={chartTilesCheckboxTooltip}
      >
        <TooltipIcon className={s.tooltipIcon} />
      </div>
      {isOpen && (
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <Card
            className={{
              base: s.tilesDropdownBox
            }}
          >
            <Text className={s.tilesDropdownHeader}>Etykiety</Text>
            <ShowChartTiles />
            {buttons.map(button => (
              <button
                key={button.label}
                className={s.selectTilesButton}
                onClick={button.onClick}
                data-tooltip={button.tooltip}
              >
                {button.label}
              </button>
            ))}
            <span className={s.separator} />
            <button
              className={cn(s.selectTilesButton, {
                [s.disabled]: !isGroupedBarChart
              })}
              onClick={handleGroupedBarChartLabelsToggle}
              disabled={!isGroupedBarChart}
              data-tooltip={
                !isGroupedBarChart
                  ? "Opcja dostępna tylko dla wykresu kolumnowo grupowanego"
                  : "Włącz lub wyłącz etykiety nad wykresem kolumnowo grupowanym"
              }
            >
              {showBarChartLabels
                ? "Ukryj dla wykresu KG"
                : "Pokaż dla wykresu KG"}
            </button>
          </Card>
        </OutsideClickHandler>
      )}
    </div>
  );
};
