import { MouseEvent, useCallback, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";
import { format } from "date-fns";

import { Portal } from "components/atoms";
import {
  ArrowDown,
  ArrowUp,
  ChevronLeft,
  ChevronRight,
  Close
} from "components/atoms/Icon";
import { Checkbox, PopupWindow } from "components/molecules";
import { SingleInsightData } from "pages/Insights/utils/types";
import { PromotionsApi } from "pages/Reports/redux/reducers/promotionsReducer";
import { updateQueryParams } from "store/actions/routerActions";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { DEFAULT_DATE_FORMAT, ICON_SIZES } from "utils";
import { QP } from "utils/defaultQueryParams";
import { pushInsightsAction } from "utils/googleTagManager/dataLayer";

import { useKeyboardEvents, useTransformedData } from "./hooks";
import s from "./insightsDetails.module.scss";
import { adjustPercentageChangeColumn, formatColumnValue } from "./utils";

type Props = {
  index: number;
  data: SingleInsightData;
  previousId: string;
  nextId: string;
};

enum Sentiment {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL"
}

const PromotionDetails = ({
  promotion,
  showDesc
}: {
  promotion: PromotionsApi;
  showDesc: boolean;
}) => {
  const materials = [
    ...promotion.materials.own_materials,
    ...promotion.materials.competing_materials
  ];

  return (
    <div className={s.promotion}>
      <p>
        <strong className={s.promotionDisplay}>
          {`${promotion.category_display} (${format(
            new Date(promotion.valid_from),
            DEFAULT_DATE_FORMAT
          )} - ${format(new Date(promotion.valid_to), DEFAULT_DATE_FORMAT)})`}
        </strong>
        {showDesc && <span>{` ${promotion.promotion_desc}`}</span>}
      </p>
      {materials.map(material => (
        <div key={material.vendor_name}>
          <strong className={s.promotionVendor}>{material.vendor_name}</strong>
          <p className={s.promotionProducts}>
            {material.data.map(product => product.display).join(", ")}
          </p>
        </div>
      ))}
    </div>
  );
};

export const InsightsDetails = ({ data, index, previousId, nextId }: Props) => {
  const dispatch = useDispatch();
  const [showPromotions, setShowPromotions] = useState(false);
  const isPowerUser = useSelector(isPowerUserSelector);
  const transformedData = useTransformedData(data);

  const handleChange = useCallback(
    (id: string) => {
      dispatch(updateQueryParams({ [QP.ACTIVE_INSIGHT]: id }));
    },
    [dispatch]
  );

  const handleNavigationButtonClick = (id: string, isNext: boolean) => {
    handleChange(id);
    pushInsightsAction(
      "click",
      `explainability - ${isNext ? "next" : "previous"}`
    );
  };

  const handlePromotionsToggle = () => {
    setShowPromotions(!showPromotions);
    pushInsightsAction(
      "click",
      `explainability - ${showPromotions ? "hide" : "show"} promotion`
    );
  };

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const url = event.currentTarget.href;

    pushInsightsAction("click", "explainability - Sprawdź na wykresie");
    window.location.assign(url);
  };

  useKeyboardEvents(previousId, nextId, handleChange);

  if (!transformedData) {
    return null;
  }

  const {
    tableHead,
    tableBody,
    promotions,
    isCompare,
    hasTableHead,
    changeIndexes,
    percentageChangeIndexes,
    hasPromotions,
    promotionsColSpanCount
  } = transformedData;

  return (
    <Portal id="js-insights-details-modal">
      <PopupWindow className={s.overlay}>
        <OutsideClickHandler onOutsideClick={() => handleChange("")}>
          <div
            className={cn(s.container, {
              [s.fullWidth]: showPromotions || isCompare
            })}
          >
            <nav className={s.navigation}>
              <div className={s.headingWrapper}>
                <span className={s.index}>{index + 1}</span>
                <h2 className={s.heading}>{data.insightTitle}</h2>
              </div>
              <div className={s.buttons}>
                <button
                  className={s.paginationButton}
                  onClick={() => handleNavigationButtonClick(previousId, false)}
                  disabled={!previousId.length}
                >
                  <ChevronLeft />
                  <span>Poprzedni</span>
                </button>
                <button
                  className={s.paginationButton}
                  onClick={() => handleNavigationButtonClick(nextId, true)}
                  disabled={!nextId.length}
                >
                  <span>Następny</span>
                  <ChevronRight />
                </button>
                <button
                  className={s.closeButton}
                  onClick={() => handleChange("")}
                >
                  <Close />
                </button>
              </div>
            </nav>
            <table className={s.table}>
              {hasTableHead && (
                <thead>
                  <tr>
                    {tableHead.columns.map((column, index) => (
                      <th
                        className={cn({
                          [s.change]: changeIndexes.includes(index),
                          [s.percentageChange]: percentageChangeIndexes.includes(
                            index
                          )
                        })}
                        key={`head-col-${index}`}
                      >
                        {String(column.value).replace(/\|/g, "\n")}
                      </th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody>
                {tableBody.map((row, rowIndex) => (
                  <tr
                    key={`row-${rowIndex}`}
                    className={cn({
                      [s.headerRow]: !hasTableHead && rowIndex === 0
                    })}
                  >
                    {row.columns.map((column, colIndex) => {
                      const isPercentageChange = percentageChangeIndexes.includes(
                        colIndex
                      );
                      const changeIndex =
                        changeIndexes.find(index => index === colIndex - 1) ||
                        -1;
                      const changeColumn =
                        changeIndex !== -1
                          ? row.columns[changeIndex]
                          : { value: null, unit: null };

                      const changeSentiment = (() => {
                        if (column.value === null || !isPercentageChange)
                          return "";
                        if (column.value === 0) return Sentiment.NEUTRAL;
                        if (column.value > 0) return Sentiment.POSITIVE;
                        return Sentiment.NEGATIVE;
                      })();

                      return (
                        <td key={`row-${rowIndex}-col-${colIndex}`}>
                          {changeSentiment === Sentiment.POSITIVE && (
                            <ArrowUp
                              className={cn(s.changeArrow, s.positive)}
                              size={ICON_SIZES.EXTRA_SMALL}
                            />
                          )}
                          {changeSentiment === Sentiment.NEGATIVE && (
                            <ArrowDown
                              className={cn(s.changeArrow, s.negative)}
                              size={ICON_SIZES.EXTRA_SMALL}
                            />
                          )}
                          <span>
                            {isPercentageChange
                              ? formatColumnValue(
                                  adjustPercentageChangeColumn(
                                    changeColumn,
                                    column
                                  )
                                )
                              : formatColumnValue(column)}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                ))}
                {hasPromotions && (
                  <tr>
                    <td>
                      <Checkbox
                        isChecked={showPromotions}
                        onChange={handlePromotionsToggle}
                        label="Pokaż promocje"
                      />
                    </td>
                    <td>
                      {showPromotions &&
                        promotions.base.map((promotion, index) => (
                          <PromotionDetails
                            key={`base-promotion-${index}`}
                            promotion={promotion}
                            showDesc={isPowerUser}
                          />
                        ))}
                    </td>
                    <td>
                      {showPromotions &&
                        promotions.compare.map((promotion, index) => (
                          <PromotionDetails
                            key={`compare-promotion-${index}`}
                            promotion={promotion}
                            showDesc={isPowerUser}
                          />
                        ))}
                    </td>
                    {promotionsColSpanCount > 0 && (
                      <td colSpan={promotionsColSpanCount} />
                    )}
                  </tr>
                )}
              </tbody>
            </table>
            <a
              className={s.link}
              href={data.insightUrl}
              onClick={handleLinkClick}
            >
              <span>Sprawdź na wykresie</span>
              <ChevronRight />
            </a>
          </div>
        </OutsideClickHandler>
      </PopupWindow>
    </Portal>
  );
};
