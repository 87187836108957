import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { SpinnerLoader, Text } from "components/atoms";
import {
  asyncFiltersSelector,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { categoriesErrMsgSelector } from "pages/Reports/redux/reducers/filters/categoryFilters/categoryFiltersSelectors";
import { useCategoryBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useCategory";
import { CategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import { TEXT_WEIGHTS } from "utils";

import { DROPDOWN_LABELS } from "../constants";
import { CategoryDropdown } from "./CategoryDropdown";
import s from "./categoryDropdown.module.scss";

export const CategoryDropdownGroup = () => {
  const isFetchingCat1 = useSelector(asyncFiltersSelector).fetching.category1;
  const isFetchingCat2 = useSelector(asyncFiltersSelector).fetching.category2;
  const isFetchingCat3 = useSelector(asyncFiltersSelector).fetching.category3;
  const errorMsg = useSelector(categoriesErrMsgSelector);
  const dispatch = useDispatch();

  const [category1SelectedItems] = CategoryHooks.useSelectedCategories(1);
  const [category1Items] = CategoryHooks.useAllCategories(1);
  const [category2Items] = CategoryHooks.useAllCategories(2);
  const [category3Items] = CategoryHooks.useAllCategories(3);

  useCategoryBehaviour(1);
  useCategoryBehaviour(2);
  useCategoryBehaviour(3);

  const reFetch = () => {
    dispatch(updateQuery({ filter: "category1", query: "" }));
    dispatch(updateQuery({ filter: "category2", query: "" }));
    dispatch(updateQuery({ filter: "category3", query: "" }));
  };

  if (isFetchingCat1 && isFetchingCat2 && isFetchingCat3)
    return (
      <label>
        <Text size="small" className={s.label} weight={TEXT_WEIGHTS.BOLD}>
          {DROPDOWN_LABELS.CATEGORY}
        </Text>
        <div className={s.loaderContainer}>
          <SpinnerLoader className={{ wrapper: s.loader }} size="medium" />
        </div>
      </label>
    );

  if (category1Items.length) {
    return (
      <>
        <CategoryDropdown
          level={1}
          label={DROPDOWN_LABELS.CATEGORY}
          promptOnChange
        />
        {!!category1SelectedItems.length && (
          <>
            {!!category2Items.length && <CategoryDropdown level={2} />}

            {!!category3Items.length && <CategoryDropdown level={3} />}
          </>
        )}
      </>
    );
  }

  if (category2Items.length) {
    return (
      <>
        <CategoryDropdown
          level={2}
          label={DROPDOWN_LABELS.CATEGORY}
          promptOnClear
        />
        {!!category3Items.length && <CategoryDropdown level={3} />}
      </>
    );
  }

  if (category3Items.length) {
    return <CategoryDropdown level={3} label={DROPDOWN_LABELS.CATEGORY} />;
  }

  return (
    <>
      <Text size="small" className={s.label} weight={TEXT_WEIGHTS.BOLD}>
        {DROPDOWN_LABELS.CATEGORY}
      </Text>
      <div className={s.errorContainer}>
        <Text className={s.errorText}>
          {errorMsg && <button onClick={reFetch}>spróbuj ponownie</button>}
        </Text>
      </div>
    </>
  );
};
