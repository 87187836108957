import { useSelector } from "react-redux";

import { isAfter, subDays } from "date-fns";
import { createSelector } from "reselect";

import {
  CategoryHooks,
  CategoryItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import { AppState } from "store";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { newDateByTimezone } from "store/utils";

import { CategoryItemsState } from "./categoryFiltersReducer";

export const initialCategoryFiltersState: CategoryItemsState = {
  errorMessage: ""
};

// SELECTORS
const categoryFilterSelector = (state: AppState): CategoryItemsState =>
  state.reports.filters.category || initialCategoryFiltersState;

export const categoriesErrMsgSelector = createSelector(
  categoryFilterSelector,
  ({ errorMessage }) => errorMessage
);

// returns category startDate, closest to today in selected categories items
const categoryStartDate = (
  isPowerUser: boolean,
  selectedCategoryItems: CategoryItem[]
) => {
  let startDate: Date | string =
    selectedCategoryItems[0]?.startDate || subDays(newDateByTimezone(), 1);

  selectedCategoryItems.forEach(category => {
    if (
      isAfter(
        newDateByTimezone(category.startDate),
        newDateByTimezone(startDate)
      )
    ) {
      startDate = category.startDate;
    }
  });

  if (isPowerUser) {
    const minDateForPowerUsers = "2018-01-01";
    return newDateByTimezone(minDateForPowerUsers);
  }

  return newDateByTimezone(startDate);
};

export const useCategoryDateRange = () => {
  const yesterday = subDays(newDateByTimezone(), 1);
  const isPowerUser = useSelector(isPowerUserSelector);
  const [selectedCategoryItems] = CategoryHooks.useSelectedTopLevelCategory();
  const currentCategoryStartDate = categoryStartDate(
    isPowerUser,
    selectedCategoryItems
  );

  return [currentCategoryStartDate, yesterday];
};
