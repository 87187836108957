import { useEffect, useState } from "react";
import { Step } from "react-joyride";
import { useSelector } from "react-redux";

import { isHistoricalDataEnabledSelector } from "components/molecules/DateDropdownMenu/HistoricalSection/selectors";
import { isDynamicPeriodEnabledSelector } from "components/molecules/DateDropdownMenu/PresetsSection/selectors";
import { BOOL_STRING_VALUES, TOURS_KEYS } from "utils";

import {
  DYNAMIC_PERIOD_STEPS,
  EXCLUDED_DAYS_STEPS,
  HISTORICAL_STEPS,
  PREDEFINED_OPTIONS_STEPS
} from "../constants";
import { Tour } from "../Tour";

interface Sequence {
  key: string;
  steps: Step[];
  isReady: boolean;
}

const {
  IS_HISTORICAL_SECTION_TOUR_HIDDEN,
  IS_EXCLUDED_DAYS_TOUR_HIDDEN,
  IS_DYNAMIC_PERIOD_TOUR_HIDDEN,
  IS_PREDEFINED_OPTIONS_TOUR_HIDDEN
} = TOURS_KEYS;
const { TRUE } = BOOL_STRING_VALUES;

const useCalendarTour = ({
  isHistoricalSectionOpen
}: {
  isHistoricalSectionOpen: boolean;
}) => {
  const [sequence, setSequence] = useState<Sequence[]>([]);
  const isHistoricalDataEnabled = useSelector(isHistoricalDataEnabledSelector);
  const isDynamicPeriodEnabled = useSelector(isDynamicPeriodEnabledSelector);

  const activeTour = sequence.find(
    ({ isReady, key }) => isReady && localStorage.getItem(key) !== TRUE
  );

  const handleClose = () => {
    if (!activeTour) return;

    localStorage.setItem(activeTour.key, TRUE);

    setSequence(
      sequence.map(tour =>
        tour.key === activeTour.key ? { ...tour, isHidden: true } : tour
      )
    );
  };

  useEffect(() => {
    setSequence([
      {
        key: IS_HISTORICAL_SECTION_TOUR_HIDDEN,
        steps: HISTORICAL_STEPS,
        isReady: isHistoricalDataEnabled
      },
      {
        key: IS_EXCLUDED_DAYS_TOUR_HIDDEN,
        steps: EXCLUDED_DAYS_STEPS,
        isReady: true
      },
      {
        key: IS_DYNAMIC_PERIOD_TOUR_HIDDEN,
        steps: DYNAMIC_PERIOD_STEPS,
        isReady: isDynamicPeriodEnabled
      },
      {
        key: IS_PREDEFINED_OPTIONS_TOUR_HIDDEN,
        steps: PREDEFINED_OPTIONS_STEPS,
        isReady: isHistoricalSectionOpen
      }
    ]);
  }, [
    isDynamicPeriodEnabled,
    isHistoricalDataEnabled,
    isHistoricalSectionOpen
  ]);

  return { activeTour, handleClose };
};

export const CalendarTour = ({
  isHistoricalSectionOpen
}: {
  isHistoricalSectionOpen: boolean;
}) => {
  const { activeTour, handleClose } = useCalendarTour({
    isHistoricalSectionOpen
  });

  if (!activeTour) {
    return null;
  }

  return (
    <Tour
      key={activeTour.key}
      run={activeTour.isReady}
      steps={activeTour.steps}
      onClose={handleClose}
      disableScrolling
    />
  );
};
