import * as React from "react";

import cn from "classnames";

import { Button } from "components/atoms";
import { PopupWindow } from "components/molecules";

import s from "./rwdPopupWindow.module.scss";

interface Props {
  onClose: () => void;
}

export const RWDPopupWindow = ({ onClose }: Props) => (
  <PopupWindow>
    <div className={s.popupWindow}>
      <div className={s.popupContent}>
        <span className={cn(s.centeredText, s.mainText)}>
          Pracujemy nad wersją serwisu w pełni dostosowaną do smartfonów.
        </span>
        <span className={cn(s.centeredText, s.secondaryText)}>
          W międzyczasie zachęcamy do korzystania ze strony na tablecie lub
          komputerze stacjonarnym.
        </span>
        <Button onClick={() => onClose()} className={s.button}>
          OK
        </Button>
      </div>
    </div>
  </PopupWindow>
);
