import { useSelector } from "react-redux";

import { useIsRankingWith5PercentDistributionVisible } from "api/ranking/mappers";

import { useChartTypeBoolean } from "components/molecules/ChartTypeToggle/hooks";
import { useCurrentDataset } from "pages/Reports/partials/Chart/StandardChart/components/ShowDatasetSelect/hooks";
import { CountyHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCounty";
import { VoivodeshipHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVoivodeship";
import {
  showCompetitionDistributionWarningSelector,
  showD28CompetitionWarningSelector,
  showNoMetricsWarningSelector,
  showRankingCompetitionWarningSelector
} from "pages/Reports/redux/selectors/chartWarningsSelectors";
import { lflQueryParamSelector } from "store/selectors/routerSelectors";
import { BOOL_STRING_VALUES, WARNINGS } from "utils";

interface WarningSettings {
  text: string;
  isVisible: boolean;
}

const useWholeCountryData = () => {
  const [counties] = CountyHooks.useSelectedCountiesIds();
  const [voivodeships] = VoivodeshipHooks.useSelectedVoivodeshipIds();

  const allVoivodeshipsSelected =
    voivodeships.length === 0 || voivodeships.length === 16;
  const noCountiesSelected = counties.length === 0;

  // If we selected all Poland, warning can not be shown
  if (allVoivodeshipsSelected && noCountiesSelected) {
    return false;
  }

  // If we selected part of Poland, warning must be shown
  const anyVoivSelected = voivodeships.length > 0;
  const anyCountySelected = counties.length > 0;
  if (anyVoivSelected || anyCountySelected) {
    return true;
  }

  return false;
};

export const useSegmentsGeneralWarnings = (): string[] => {
  const showD28CompetitionWarning = useSelector(
    showD28CompetitionWarningSelector
  );
  const showCompetitionDistributionWarning = useSelector(
    showCompetitionDistributionWarningSelector
  );
  const showRankingCompetitionWarning = useSelector(
    showRankingCompetitionWarningSelector
  );
  const showNoMetricsWarning = useSelector(showNoMetricsWarningSelector);
  const isRankingWith5PercentDistributionVisible = useIsRankingWith5PercentDistributionVisible();
  const { both: isBothDatasets } = useCurrentDataset();
  const lflQP = useSelector(lflQueryParamSelector);
  const {
    isAreaChart,
    isIncrementalChart,
    isIncrementalComboChart
  } = useChartTypeBoolean();

  const wholeCountryData = useWholeCountryData();

  const settings: WarningSettings[] = [
    {
      text: WARNINGS.INCOMPLETE_DATA,
      isVisible: showD28CompetitionWarning
    },
    {
      text: WARNINGS.DISTRIBUTION_VISIBLE_FOR_OWN_DATA,
      isVisible: showCompetitionDistributionWarning
    },
    {
      text: WARNINGS.RANKING_ONLY_OWN_DATA,
      isVisible: showRankingCompetitionWarning
    },
    {
      text: WARNINGS.NO_DATA_TYPE_SELECTED,
      isVisible: showNoMetricsWarning
    },
    {
      text: WARNINGS.RANKING_UNDER_5_PERCENT_HIDDEN,
      isVisible: isRankingWith5PercentDistributionVisible
    },
    {
      text: WARNINGS.SEGMENTS_REGION_SELECTED,
      isVisible: wholeCountryData
    },
    {
      text: WARNINGS.AREA_CHART,
      isVisible: isAreaChart && !isBothDatasets
    },
    {
      text: WARNINGS.INCREMENTAL_CHART,
      isVisible:
        (isIncrementalChart || isIncrementalComboChart) && !isBothDatasets
    },
    {
      text: WARNINGS.LFL_SELECTED,
      isVisible: lflQP === BOOL_STRING_VALUES.TRUE
    }
  ];

  return settings
    .filter(warning => warning.isVisible)
    .map(warning => warning.text);
};
