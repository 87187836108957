import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "components/molecules";
import { updateQueryParams } from "store/actions/routerActions";
import { areTilesHiddenSelector } from "store/selectors/routerSelectors";
import { QP } from "utils/defaultQueryParams";
import { pushChartSwitchEvent } from "utils/googleTagManager/dataLayer";

import s from "./showChartTiles.module.scss";

export const ShowChartTiles = () => {
  const dispatch = useDispatch();
  const areTilesHidden = useSelector(areTilesHiddenSelector);

  const onChange = () => {
    dispatch(
      updateQueryParams({
        [QP.HIDE_TILES]: String(!areTilesHidden)
      })
    );

    pushChartSwitchEvent(areTilesHidden ? "Show labels" : "Hide labels");
  };

  return (
    <Checkbox
      className={{
        base: s.base,
        label: s.label
      }}
      label="Ukryj"
      onChange={onChange}
      isChecked={areTilesHidden}
      testId="toggle-hide-chart-tiles-checkbox"
    />
  );
};
