import {
  BrandHooks,
  BrandType
} from "pages/Reports/redux/reducers/sweetStateHooks/useBrand";
import { CLIENT_TYPE_ITEMS } from "pages/Reports/redux/reducers/sweetStateHooks/useClientType";
import { CompetingCompaniesHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCompetingCompanies";
import { CompetingProductsHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCompetingProducts";
import { CountyHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCounty";
import { LFLHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useLFL";
import { ReferenceCategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useReferenceCategory";
import { ShopTypeHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useShopType";
import { ShopTypeMetaHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useShopTypeMeta";
import { VoivodeshipHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVoivodeship";
import { FILTER_SECTIONS } from "utils/constants";

const {
  MAIN,
  COMPETITION,
  REFERENCE_CATEGORIES,
  REFERENCE_BRANDS,
  REGION,
  SHOP_TYPE,
  CLIENT_TYPE,
  LFL,
  EXPORT_DATA_TYPE,
  SUPER_USER_SETTINGS
} = FILTER_SECTIONS;

type FilterSectionHeader = {
  label: string;
  count: string;
};

const getCountLabel = (selectedItems: number, allItems: number) =>
  `(${selectedItems}/${allItems})`;

export const useFiltersSectionHeader = (
  sectionKey: string
): FilterSectionHeader => {
  // === COMPETITION SECTION ===
  const [competingCompanies] = CompetingCompaniesHooks.useCompetingCompanies();
  const [competingProducts] = CompetingProductsHooks.useCompetingProducts();

  const selectedCompetitionItems = competingProducts.selected.length
    ? competingProducts.selected.length
    : competingCompanies.selected.length;

  const allCompetitionItems = competingProducts.all.length
    ? competingProducts.all.length
    : competingCompanies.all.length;

  // === REFERENCE SECTION ===
  const [
    { all: allReferenceCategoryItems, selected: selectedReferenceCategoryItems }
  ] = ReferenceCategoryHooks.useTopLevelCategories();

  const [allReferenceMainBrands] = BrandHooks.useAllBrands(
    BrandType.referenceBrand
  );
  const [selectedReferenceMainBrands] = BrandHooks.useSelectedBrands(
    BrandType.referenceBrand
  );

  const [allReferenceSubBrands] = BrandHooks.useAllBrands(
    BrandType.referenceSubBrand
  );
  const [selectedReferenceSubBrands] = BrandHooks.useSelectedBrands(
    BrandType.referenceSubBrand
  );

  const selectedReferenceBrands = selectedReferenceSubBrands.length
    ? selectedReferenceSubBrands.length
    : selectedReferenceMainBrands.length;
  const allReferenceBrands = allReferenceSubBrands.length
    ? allReferenceSubBrands.length
    : allReferenceMainBrands.length;

  // === REGION SECTION ===
  const [voivState] = VoivodeshipHooks.useVoivodeship();
  const [countyState] = CountyHooks.useCounty();

  const selectedRegions = countyState.selected.length
    ? countyState.selected.length
    : voivState.selected.length;

  const allRegions = countyState.selected.length
    ? countyState.all.length
    : voivState.all.length;

  // === SHOP TYPE SECTION ===
  const [shopTypeState] = ShopTypeHooks.useShopType();
  const [shopTypeMetaState] = ShopTypeMetaHooks.useShopTypeMeta();

  const selectedShopTypes = shopTypeState.selected.length
    ? shopTypeState.selected.length
    : shopTypeMetaState.selected.length;
  const allShopTypes = shopTypeState.all.length
    ? shopTypeState.all.length
    : shopTypeMetaState.all.length;

  // === CLIENT TYPE SECTION ===
  const selectedClientTypes = 1;
  const allClientTypes = CLIENT_TYPE_ITEMS.length;

  // === LFL SECTION ===
  const [lflState] = LFLHooks.useLFL();

  switch (sectionKey) {
    case MAIN.key:
      return { label: MAIN.label, count: "" };
    case COMPETITION.key:
      return {
        label: `${COMPETITION.label}`,
        count: getCountLabel(selectedCompetitionItems, allCompetitionItems)
      };
    case REFERENCE_CATEGORIES.key:
      return {
        label: `${REFERENCE_CATEGORIES.label}`,
        count: getCountLabel(
          selectedReferenceCategoryItems.length,
          allReferenceCategoryItems.length
        )
      };
    case REFERENCE_BRANDS.key:
      return {
        label: REFERENCE_BRANDS.label,
        count: getCountLabel(selectedReferenceBrands, allReferenceBrands)
      };
    case REGION.key:
      return {
        label: REGION.label,
        count: getCountLabel(selectedRegions, allRegions)
      };
    case SHOP_TYPE.key:
      return {
        label: SHOP_TYPE.label,
        count: getCountLabel(selectedShopTypes, allShopTypes)
      };
    case CLIENT_TYPE.key:
      return {
        label: CLIENT_TYPE.label,
        count: getCountLabel(selectedClientTypes, allClientTypes)
      };
    case LFL.key:
      return { label: LFL.label, count: lflState.lfl ? "(tak)" : "(nie)" };
    case EXPORT_DATA_TYPE.key:
      return { label: EXPORT_DATA_TYPE.label, count: "" };
    case SUPER_USER_SETTINGS.key:
      return { label: SUPER_USER_SETTINGS.label, count: "" };
    default:
      return { label: "", count: "" };
  }
};
