import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { QueryKey } from "api/consts";
import { rankingServices } from "api/ranking/services/ranking.services";
import { useIsRankingSelected } from "api/ranking/utils/useIsRankingSelected";
import { useReportQueryString } from "api/utils/useReportQueryString";
import qs from "query-string";

import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { PARSE_OPTIONS } from "pages/Reports/utils/validateParams/utils/adjustObjectProperty";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage, REST_API_ENDPOINTS } from "utils";
import { translateFeParamsToBeParams } from "utils/translateFEParamsToBEParams";

const endpointMap = {
  [REPORTS_FULL_PATHS.SELL_PATH]: REST_API_ENDPOINTS.RANKINGS.SALES,
  [REPORTS_FULL_PATHS.SHARES_PATH]: REST_API_ENDPOINTS.RANKINGS.SHARES,
  [REPORTS_FULL_PATHS.DYNAMICS_PATH]: REST_API_ENDPOINTS.RANKINGS.DYNAMICS,
  [REPORTS_FULL_PATHS.RECEIPTS_PATH]: REST_API_ENDPOINTS.RANKINGS.RECEIPTS,
  [REPORTS_FULL_PATHS.LOYALTY_PATH]: REST_API_ENDPOINTS.RANKINGS.LOYALTY,
  [REPORTS_FULL_PATHS.MULTIPACK_PATH]: REST_API_ENDPOINTS.RANKINGS.MULTIPACK
};

export const useGetRanking = () => {
  const pathname = useSelector(pathnameSelector);
  const isRankingSelected = useIsRankingSelected();
  const reportQueryString = useReportQueryString();

  const beQuery = translateFeParamsToBeParams(reportQueryString);
  const params = qs.parse(reportQueryString, PARSE_OPTIONS);
  const isCategorySelected = [
    params.category1,
    params.category2,
    params.category3
  ].some(Boolean);

  const currentReportUrl = Object.keys(endpointMap).find(reportUrl => {
    return isThisPage(pathname, reportUrl);
  });
  const endpoint = currentReportUrl ? endpointMap[currentReportUrl] : "";

  const query = useQuery(
    [QueryKey.RANKING_DATA, endpoint, beQuery],
    () => rankingServices.fetchRanking(beQuery, endpoint),
    {
      enabled: isCategorySelected && isRankingSelected,
      retry: false
    }
  );

  return { ...query, data: query?.data || [] };
};
