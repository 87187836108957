import { values } from "ramda";
import { createSelector } from "reselect";

import { VOIVODESHIPS } from "components/D3/Voivodeship/constants";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { isPowerUserSelector } from "store/reducers/userReducer";
import {
  countiesQueryParamSelector,
  logisticsPeriodTypeSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import { isThisPage, LOGISTICS_DATA_TYPES_PARAMS } from "utils";

const { SELL_PATH, LOCATION_PATH } = REPORTS_FULL_PATHS;

export const isVoivodeshipPage = (pathname: string) => {
  if (!isThisPage(pathname, LOCATION_PATH)) return false;

  const voivodeship = pathname.split("/").pop() || "";
  const voivodeships = values(VOIVODESHIPS) as string[];

  return voivodeships.includes(voivodeship);
};

export const isValueQuantityDataDisabledSelector = createSelector(
  isPowerUserSelector,
  pathnameSelector,
  countiesQueryParamSelector,
  (isPowerUser, pathname, counties) => {
    const isSell = isThisPage(pathname, SELL_PATH);
    const isLocation = isThisPage(pathname, LOCATION_PATH);
    const hasCounties = !!counties.length;

    // [PMD-2212]: power users should have access to all first types on location
    if (isPowerUser && isLocation) return false;

    if (isVoivodeshipPage(pathname)) return true;

    return hasCounties && (isSell || isLocation);
  }
);

export const areLogisticsDataDisabledSelector = createSelector(
  logisticsPeriodTypeSelector,
  logisticsPeriodType =>
    logisticsPeriodType === LOGISTICS_DATA_TYPES_PARAMS.SINGLE_DAY
);
