import { useDispatch, useSelector } from "react-redux";

import { RankingSorting } from "api/ranking/types";

import s from "components/common.module.scss";
import {
  ChartSectionSelect,
  HideLowRankingCheckbox
} from "components/organisms";
import { useZappkaSharesCheckboxState } from "pages/Reports/sections/NewChartDropdown/ZappkaSharesCheckbox/hooks";
import { updateItemsPerChart } from "store/actions/appActions";
import { updateQueryParams } from "store/actions/routerActions";
import { itemsPerChartSelector } from "store/selectors/appSelectors";
import { queryParamsSelector } from "store/selectors/routerSelectors";
import { QP } from "utils/defaultQueryParams";
import { pushRankingEvent } from "utils/googleTagManager/dataLayer";

import { ZappkaTransactionSelect } from "../StandardChart/components/WithZappkaSelect";
import { HorizontalBarChart } from "./HorizontalBarChart";

const ITEMS_PER_CHART_OPTIONS = [10, 20, 30, 50, 100].map(value => ({
  value: String(value),
  display: String(value)
}));

export const useRankingSorting = () => {
  const params = useSelector(queryParamsSelector);
  const shares = useZappkaSharesCheckboxState();
  const sortRankingQP = String(params[QP.SORT_RANKING] || "");
  const options = [
    { value: RankingSorting.ASC, display: "rosnąco" },
    { value: RankingSorting.DESC, display: "malejąco" }
  ];
  if (shares.first || shares.second) {
    options.push(
      { value: RankingSorting.SHARES_ASC, display: "rosnąco (udział)" },
      { value: RankingSorting.SHARES_DESC, display: "malejąco (udział)" }
    );
  }
  const selected =
    options.find(option => option.value === sortRankingQP) || options[0];
  return { selected, options };
};

export const RankingChartSection = () => {
  const dispatch = useDispatch();
  const itemsPerChart = useSelector(itemsPerChartSelector);
  const sorting = useRankingSorting();

  const handleItemsPerChartChange = (value: string) => {
    dispatch(updateItemsPerChart(Number(value)));
  };

  const handleSortingChange = (value: string) => {
    const option = sorting.options.find(option => option.value === value);
    if (option) {
      dispatch(updateQueryParams({ [QP.SORT_RANKING]: option.value }));
      pushRankingEvent(`sortowanie - ${option.display}`);
    }
  };

  return (
    <>
      <div className={s.chartTopOptions}>
        <ChartSectionSelect
          id="items-per-chart"
          label="Liczba kat. / prod. w rankingu:"
          value={String(itemsPerChart)}
          options={ITEMS_PER_CHART_OPTIONS}
          onChange={handleItemsPerChartChange}
        />
        <ChartSectionSelect
          id="sorting"
          label="Sortowanie:"
          value={sorting.selected.value}
          options={sorting.options}
          onChange={handleSortingChange}
        />
        <HideLowRankingCheckbox />
        <ZappkaTransactionSelect />
      </div>
      <HorizontalBarChart />
    </>
  );
};
