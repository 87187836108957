import { format } from "d3";
import { isNil } from "ramda";

import { NO_DATA } from "utils";
import { formatPolishLocale } from "utils/formatPolishLocale";
import { Nullable } from "utils/types";

// these values should specify correct case use only, they need to be unique
export const NUMBER_TYPE = {
  GROUPED_PRICE: "GROUPED_PRICE",
  GROUPED_PRICE_THREE_DECIMALS: "GROUPED_PRICE_THREE_DECIMALS",
  PERCENT: "PERCENT",
  PERCENT_FOUR_DECIMALS: "PERCENT_THREE_DECIMALS",
  GROUPED_ABSOLUTE_VALUE: "GROUPED_ABSOLUTE_VALUE",
  PERCENT_POINTS: "PERCENT_POINTS",
  PERCENT_POINTS_FOUR_DECIMALS: "PERCENT_POINTS_THREE_DECIMALS",
  NOT_APPLICABLE: "NOT_APPLICABLE",
  RAW: "RAW"
};

const removeLastZerosFromString = (value: string) => {
  if (value.endsWith("00")) {
    return value.slice(0, -2);
  }
  if (value.endsWith("0")) {
    return value.slice(0, -1);
  }
  return value;
};

formatPolishLocale();
export const formatNumber = (
  value: Nullable<number>,
  type: string = NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE,
  noDataMessage: string = NO_DATA
) => {
  if (isNil(value)) return noDataMessage;

  switch (type) {
    case NUMBER_TYPE.GROUPED_PRICE:
      return format(",.2f")(Number(value));
    case NUMBER_TYPE.GROUPED_PRICE_THREE_DECIMALS:
      return removeLastZerosFromString(format(",.4f")(Number(value)));
    case NUMBER_TYPE.PERCENT:
      return format(".2%")(Number(value / 100));
    case NUMBER_TYPE.PERCENT_FOUR_DECIMALS:
      return `${removeLastZerosFromString(format(",.4f")(Number(value)))}%`;
    case NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE:
      return format(",")(Number(value));
    case NUMBER_TYPE.PERCENT_POINTS:
      return `${format(",.2f")(Number(value))} p.p.`;
    case NUMBER_TYPE.PERCENT_POINTS_FOUR_DECIMALS:
      return `${removeLastZerosFromString(format(",.4f")(Number(value)))} p.p.`;
    default:
      return format(type)(Number(value));
  }
};
