import cn from "classnames";
import { ScalePoint } from "d3";

import s from "pages/Reports/partials/Chart/StandardChart/bottomAxis.module.scss";
import { newDateByTimezone } from "store/utils";
import { getTickLabels } from "store/utils/chartUtils";

interface Props {
  xScale: ScalePoint<string>;
  height: number;
  period: string;
  showSundayOnly?: boolean;
  domain: string[];
}
const TICK_LABELS_Y_POSITION = 20;

export const SegmentsBottomAxis = ({
  height,
  xScale,
  period,
  domain,
  showSundayOnly = false
}: Props) => {
  const barWidth = xScale.bandwidth();
  const isDateValid = /\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])*/g.test(
    domain[0]
  );

  if (!isDateValid) {
    return (
      <g transform={`translate(0, ${height})`}>
        {domain.map((label, i) => {
          const transform =
            // @ts-ignore xScale() can be always return undefined.
            // 1. Set calculate x property for label: xScale(label)
            // 2. Add left padding, because bars are not aligned to the left: (xScale.bandwidth() - barWidth) / 2
            // 3. Move text to the middle of the bar
            xScale(label) + barWidth / 2;
          return (
            Boolean(label) && (
              <text
                key={i}
                fill="currentColor"
                className={s.text}
                textAnchor="middle"
                transform={`translate(${transform}, ${TICK_LABELS_Y_POSITION})`}
              >
                {label}
              </text>
            )
          );
        })}
      </g>
    );
  }

  const tickLabels = getTickLabels(period, domain.map(newDateByTimezone));

  return (
    <g transform={`translate(0, ${height})`}>
      {tickLabels.map((label, i) => {
        const translateX =
          // @ts-ignore xScale() can be always return undefined.
          // 1. Set calculate x property for label: xScale(label)
          // 2. Add left padding, because bars are not aligned to the left: (xScale.bandwidth() - barWidth) / 2
          // 3. Move text to the middle of the bar
          xScale(label.date) + barWidth / 2;

        let text = label.value;
        if (showSundayOnly && !label.isSunday) {
          text = "";
        }
        return (
          Boolean(label.value) && (
            <text
              key={i}
              fill="currentColor"
              className={cn(s.text, label?.isSunday && s.sunday)}
              transform={`translate(${translateX}, ${TICK_LABELS_Y_POSITION})`}
            >
              {text}
            </text>
          )
        );
      })}
    </g>
  );
};
