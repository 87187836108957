import { LogisticsDataApi } from "pages/Reports/sections/Logistics/types";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { pushReportFetchEvent } from "utils/googleTagManager/dataLayer";

export const logisticsServices = {
  fetchLogistics: async (queryString: string) => {
    const url = REST_API_ENDPOINTS.REPORTS.LOGISTICS;
    const urlWithQueries = `${url}?${queryString}`;
    const { data } = await HTTP.get<{ data: LogisticsDataApi[] }>(
      urlWithQueries
    );

    pushReportFetchEvent("Logistyka");

    return data;
  }
};
