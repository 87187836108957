import { flatten } from "ramda";

import { LocationChartUniqRawData } from "pages/Reports/sections/Location/redux/types/locationChartTypes";
import {
  LocationDatum,
  LocationRegion
} from "pages/Reports/sections/Location/redux/types/locationRawTypes";

/** this fn checks if data for any county from given voivodeship
exists if so it returns LocationRegion array with data for
found counties. otherwise it returns 1-element array with
datum for provided voivodeship
*/
export const getLocationChartUniqData = (
  uniqData: LocationChartUniqRawData[],
  datum: LocationDatum
): LocationChartUniqRawData[] => {
  const { voivodeships, counties, name: label, type } = datum;
  return [
    ...uniqData,
    {
      label,
      type,
      data: flatten(voivodeships.map(getUniqLocationDatum(counties)))
    }
  ];
};

const getCountiesForVoivodeship = (
  voivodeship: LocationRegion,
  counties: LocationRegion[]
) => counties.filter(county => county.voivodeship === voivodeship.id);

const getUniqLocationDatum = (counties: LocationRegion[]) => (
  voivodeship: LocationRegion
) => {
  const countiesForVoivodeship = getCountiesForVoivodeship(
    voivodeship,
    counties
  );

  return countiesForVoivodeship.length ? countiesForVoivodeship : [voivodeship];
};
