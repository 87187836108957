import * as React from "react";

import { ICON_SIZES } from "utils";

import { IconProps as Props } from "./types";
import { SIZES } from "./utils/constants";

export const Search: React.FC<Props> = ({
  className,
  color = "#A6B7D3",
  size = ICON_SIZES.EXTRA_SMALL
}) => {
  const iconSize = SIZES[size];
  return (
    <svg
      className={className}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 12 12"
      version="1.1"
    >
      <g
        id="icons/search"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="search" fill={color} fillRule="nonzero">
          <path
            d="M5.25,10.5 C2.355,10.5 0,8.145 0,5.25 C0,2.355 2.355,0 5.25,0 C8.145,0 10.5,2.355 10.5,5.25 C10.5,8.145 8.145,10.5 5.25,10.5 Z M5.25,1.5 C3.18225,1.5 1.5,3.18225 1.5,5.25 C1.5,7.31775 3.18225,9 5.25,9 C7.31775,9 9,7.31775 9,5.25 C9,3.18225 7.31775,1.5 5.25,1.5 Z"
            id="Shape"
          ></path>
          <path
            d="M11.78025,10.71975 L9.9855,8.925 C9.67725,9.32175 9.32175,9.67725 8.925,9.9855 L10.71975,11.78025 C10.866,11.9265 11.058,12 11.25,12 C11.442,12 11.634,11.9265 11.78025,11.78025 C12.0735,11.487 12.0735,11.013 11.78025,10.71975 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
};
