import { CHART_DATA_TYPE } from "utils";

export const ZAPPKA_UNRELATED_DATA_TYPES: string[] = [
  CHART_DATA_TYPE.AVG_SHOPS_COUNT,
  CHART_DATA_TYPE.DISTRIBUTION_RANGE,
  CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE,
  CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT,
  CHART_DATA_TYPE.AVG_TEMP,
  CHART_DATA_TYPE.AVG_RAINFALL
];

export const getDataTypeKey = ({
  dataType,
  suffix,
  sharesSuffix,
  isSecondary = false
}: {
  dataType: string;
  suffix: string;
  sharesSuffix: string;
  isSecondary?: boolean;
}) => {
  const isUnrelated = ZAPPKA_UNRELATED_DATA_TYPES.includes(dataType);

  if (isSecondary) {
    return !isUnrelated && suffix && !sharesSuffix
      ? dataType.concat(suffix)
      : "";
  }

  return isUnrelated ? dataType : dataType.concat(sharesSuffix || suffix);
};
