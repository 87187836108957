import { useSelector } from "react-redux";

import { useIsLocationsLoading } from "api/location/mappers/useIsLocationLoading";
import cn from "classnames";

import { CardContent, CardHeader } from "components/atoms";
import s from "components/common.module.scss";
import { Card, LocationChartContent } from "components/molecules";
import { ChartHeader } from "components/molecules/ChartHeader/ChartHeader";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { APP_PATHS } from "utils/constants";

export const LocationChartSection = () => {
  const pathname = useSelector(pathnameSelector);
  const isLoading = useIsLocationsLoading();

  return (
    <section
      className={cn(
        pathname === APP_PATHS.MAIN ? s.dashboardSection : s.section
      )}
    >
      <Card
        isLoading={isLoading}
        className={{ base: cn(pathname === APP_PATHS.MAIN && s.dashboardCard) }}
      >
        <CardHeader className={cn(s.cardHeader)}>
          <ChartHeader />
        </CardHeader>
        <CardContent>
          <LocationChartContent />
        </CardContent>
      </Card>
    </section>
  );
};
