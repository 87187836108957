import {
  Action,
  createHook,
  createStore,
  defaultRegistry
} from "react-sweet-state";

import { BOOL_STRING_VALUES } from "utils";

type State = { aggregated: boolean };
type Actions = typeof actions;

const actions = {
  getInitialAggregationCompetitionState: (
    aggregatedQP: string
  ): Action<State> => ({ setState }) => {
    setState({
      aggregated: aggregatedQP === BOOL_STRING_VALUES.TRUE
    });
  },
  updateAggregatedCompetition: (aggregated: boolean): Action<State> => ({
    setState
  }) => {
    setState({
      aggregated
    });
  },
  clearAggregatedCompetition: (): Action<State> => ({ setState }) => {
    setState({
      aggregated: false
    });
  }
};

export const AggregatedCompetitionStore = createStore<State, Actions>({
  name: "aggregatedCompetition",
  initialState: {
    aggregated: false
  },
  actions
});

const useAggregatedCompetition = createHook(AggregatedCompetitionStore);

export const AggregatedCompetitionHooks = {
  useAggregatedCompetition
};

export const AggregatedCompetitionStoreInstance = defaultRegistry.getStore(
  AggregatedCompetitionStore
);
