import React from "react";
import ReactDOM from "react-dom";

interface Props {
  id: string;
  children: React.ReactNode;
}

export const Portal = ({ id, children }: Props) => {
  const [
    portalElement,
    setPortalElement
  ] = React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const element = document.createElement("div");
    element.id = id;
    document.body.appendChild(element);
    setPortalElement(element);

    return () => {
      document.body.removeChild(element);
    };
  }, [id]);

  if (!portalElement) {
    return null;
  }

  return portalElement && ReactDOM.createPortal(children, portalElement);
};
