import { useSelector } from "react-redux";

import { uniq } from "ramda";
import { createSelector } from "reselect";

import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  chartPeriodSelector,
  chartTypeQueryParamSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import { CHART_TYPES, isThisPage, PERIOD_TYPE } from "utils";

// selectors
export const hiddenChartTypesSelector = createSelector(
  pathnameSelector,
  pathname => {
    const hidden: string[] = [];

    if (isThisPage(pathname, REPORTS_FULL_PATHS.HOURS_PATH)) {
      hidden.push(
        CHART_TYPES.LINE,
        CHART_TYPES.AREA,
        CHART_TYPES.GROUPED_BAR,
        CHART_TYPES.INCREMENTAL,
        CHART_TYPES.COMBO
      );
    }

    if (isThisPage(pathname, REPORTS_FULL_PATHS.DYNAMICS_PATH)) {
      hidden.push(CHART_TYPES.AREA, CHART_TYPES.INCREMENTAL, CHART_TYPES.COMBO);
    }

    return uniq(hidden);
  }
);

export const disabledChartTypesSelector = createSelector(
  pathnameSelector,
  chartPeriodSelector,
  (pathname, period) => {
    const disabled: string[] = [];
    const isLocation = isThisPage(pathname, REPORTS_FULL_PATHS.LOCATION_PATH);
    const isTotal = period === PERIOD_TYPE.TOTAL;
    const isRanking = period === PERIOD_TYPE.RANKING;

    if ((isLocation && isTotal) || isRanking) {
      disabled.push(
        CHART_TYPES.LINE,
        CHART_TYPES.AREA,
        CHART_TYPES.GROUPED_BAR,
        CHART_TYPES.INCREMENTAL,
        CHART_TYPES.COMBO
      );
    }

    return disabled;
  }
);

export const isRestrictedToOneLineSelector = createSelector(
  hiddenChartTypesSelector,
  disabledChartTypesSelector,
  chartTypeQueryParamSelector,
  (hidden, disabled, chartTypeQP) => {
    const oneLineTypes: string[] = [
      CHART_TYPES.AREA,
      CHART_TYPES.INCREMENTAL,
      CHART_TYPES.COMBO
    ];
    const isTypeRestricted = oneLineTypes.includes(chartTypeQP);
    const isDisabled = uniq([...hidden, ...disabled]).includes(chartTypeQP);

    return isTypeRestricted && !isDisabled;
  }
);

// hooks
export const useSelectedChartType = () => {
  const chartTypeQP = useSelector(chartTypeQueryParamSelector);
  const hidden = useSelector(hiddenChartTypesSelector);
  const disabled = useSelector(disabledChartTypesSelector);

  if (uniq([...hidden, ...disabled]).includes(chartTypeQP)) {
    return CHART_TYPES.LINE;
  }

  return chartTypeQP;
};

export const useChartTypeBoolean = () => {
  const chartType = useSelectedChartType();

  return {
    isLineChart: chartType === CHART_TYPES.LINE,
    isAreaChart: chartType === CHART_TYPES.AREA,
    isIncrementalChart: chartType === CHART_TYPES.INCREMENTAL,
    isIncrementalComboChart: chartType === CHART_TYPES.COMBO,
    isGroupedBarChart: chartType === CHART_TYPES.GROUPED_BAR
  };
};
