import { isNil } from "ramda";

import { checkedDataTypes } from "pages/Reports/sections/utils/chartData";
import { ChartDataTypes } from "pages/Reports/types/chart";
import {
  RankingApiIntersection,
  RankingApiUnion
} from "pages/Reports/types/ranking";
import { formatNumber, formatTooltipValue, pickWs } from "pages/Reports/utils";
import { NUMBER_TYPE } from "pages/Reports/utils/formatNumber";
import {
  CHART_DATA_TYPE,
  DATASET_TYPES,
  NO_DATA_SHORT,
  RANKING_CHART_COLORS
} from "utils";
import { dataTypeToMetricType } from "utils/dataTypeToMetricType";
import { addSuffix, round } from "utils/round";
import { Keys, Nullable, Values } from "utils/types";

export type HorizontalChart = {
  id: number;
  label: string;
  company: string;
  color: string;
  distributionLessThan5: boolean;
  primaryValue: number;
  sharesValue: Nullable<number>;
  rectLabel: string;
  tooltipLabel: string;
  sharesLabel: string;
  sidebarLabel?: string;
  info: string[];
};

const createTypeMap = (
  dataType: string,
  objectKey: string,
  withShares: boolean = false
) => {
  const base = {
    [dataType]: objectKey,
    [`${dataType}_OFFLINE`]: `${objectKey}_offline`,
    [`${dataType}_ONLINE`]: `${objectKey}_online`
  };

  if (withShares) {
    Object.assign(base, {
      [`${dataType}_ONLINE_SHARE`]: `${objectKey}_online_share`
    });
  }

  return base;
};

// this "prettier-ignore" is needed to keep map keys in a single line for better readability
// prettier-ignore
const keysMap = {
  ...createTypeMap(CHART_DATA_TYPE.AVG_PRICE, "top_price_avg"),
  ...createTypeMap(CHART_DATA_TYPE.NET_QUANTITY_SALES, "top_net_quantity_sales", true),
  ...createTypeMap(CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP, "top_net_quantity_sales_per_shop"),
  ...createTypeMap(CHART_DATA_TYPE.QUANTITY, "top_quantity_sum", true),
  ...createTypeMap(CHART_DATA_TYPE.QUANTITY_PER_SHOP, "top_quantity_sum_per_shop"),
  ...createTypeMap(CHART_DATA_TYPE.VALUE, "top_worth_sum", true),
  ...createTypeMap(CHART_DATA_TYPE.VALUE_PER_SHOP, "top_worth_sum_per_shop"),

  [CHART_DATA_TYPE.AVG_SHOPS_COUNT]: "top_avg_shops_count",
  [CHART_DATA_TYPE.DISTRIBUTION_RANGE]: "top_distribution",
  [CHART_DATA_TYPE.VALUE_SHARES]: "top_worth_share",
  [CHART_DATA_TYPE.QUANTITY_SHARES]: "top_quantity_share",
  [CHART_DATA_TYPE.QUANTITY_DYNAMICS]: "top_quantity_change",
  [CHART_DATA_TYPE.VALUE_DYNAMICS]: "top_worth_change",
  [CHART_DATA_TYPE.ITEMS_PER_RECEIPT]: "top_items_per_receipt",
  [CHART_DATA_TYPE.RECEIPTS_QUANTITY]: "top_receipts_quantity",
  [CHART_DATA_TYPE.RECEIPTS_PER_SHOP]: "top_receipts_per_shop",
  [CHART_DATA_TYPE.SHARE_IN_RECEIPTS_CATEGORY]: "top_share_in_receipts_category",
  [CHART_DATA_TYPE.SHARE_IN_RECEIPTS_TOTAL_SHOP]: "top_share_in_receipts_total_shop",
  [CHART_DATA_TYPE.WORTH_PER_RECEIPT]: "top_worth_per_receipt",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE]: "top_net_quantity_sales_share",
  [CHART_DATA_TYPE.DYNAMICS_NET_QUANTITY_CHANGE]: "top_net_quantity_sales_change",
  [CHART_DATA_TYPE.NET_QUANTITY_PER_RECEIPT]: "top_net_quantity_sales_per_receipt",
  [CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE]: "top_planogram_distribution",
  [CHART_DATA_TYPE.AVG_PLANOGRAM_SHOPS_COUNT]: "top_avg_planogram_shops_count",
  [CHART_DATA_TYPE.AVG_TEMP]: "top_avg_temp",
  [CHART_DATA_TYPE.AVG_RAINFALL]: "top_avg_rainfall",
  [CHART_DATA_TYPE.ALL_RETURNS_PERCENT]: "top_loyalty_back_percent",
  [CHART_DATA_TYPE.ALL_RETURNS_COUNT]: "top_loyalty_back_count",
  [CHART_DATA_TYPE.NEW_CUSTOMERS_COUNT]: "top_loyalty_new_clients_count",
  [CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT]: "top_loyalty_new_clients_percent",

  [CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT]: "top_loyalty_back_share_1w",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT]: "top_loyalty_back_share_2w",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT]: "top_loyalty_back_share_4w",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT]: "top_loyalty_back_share_6w",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT]: "top_loyalty_back_share_8w",
  [CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE]: "top_loyalty_back_absolute_1w",
  [CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE]: "top_loyalty_back_absolute_2w",
  [CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE]: "top_loyalty_back_absolute_4w",
  [CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE]: "top_loyalty_back_absolute_6w",
  [CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE]: "top_loyalty_back_absolute_8w",

  [CHART_DATA_TYPE.VALUE_WS]: pickWs("top_worth_sum_ws", "top_worth_sum_ws_wsa"),
  [CHART_DATA_TYPE.VALUE_WSO]: "top_worth_sum_wso",
  [CHART_DATA_TYPE.VALUE_WSA]: "top_worth_sum_wsa",
  [CHART_DATA_TYPE.VALUE_WS_WSO]: "top_worth_sum_ws_wso_wsa",
  [CHART_DATA_TYPE.VALUE_WS_WSO_WSA]: "top_worth_sum_ws_wso_wsa",

  [CHART_DATA_TYPE.QUANTITY_WS]: pickWs("top_quantity_sum_ws", "top_quantity_sum_ws_wsa"),
  [CHART_DATA_TYPE.QUANTITY_WSO]: "top_quantity_sum_wso",
  [CHART_DATA_TYPE.QUANTITY_WSA]: "top_quantity_sum_wsa",
  [CHART_DATA_TYPE.QUANTITY_WS_WSO]: "top_quantity_sum_ws_wso_wsa",
  [CHART_DATA_TYPE.QUANTITY_WS_WSO_WSA]: "top_quantity_sum_ws_wso_wsa",

  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS]: pickWs("top_worth_sum_per_shop_ws", "top_worth_sum_per_shop_ws_wsa"),
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSO]: "top_worth_sum_per_shop_wso",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WSA]: "top_worth_sum_per_shop_wsa",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO]: "top_worth_sum_per_shop_ws_wso_wsa",
  [CHART_DATA_TYPE.VALUE_PER_SHOP_WS_WSO_WSA]: "top_worth_sum_per_shop_ws_wso_wsa",

  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS]: pickWs("top_quantity_sum_per_shop_ws", "top_quantity_sum_per_shop_ws_wsa"),
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSO]: "top_quantity_sum_per_shop_wso",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WSA]: "top_quantity_sum_per_shop_wsa",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO]: "top_quantity_sum_per_shop_ws_wso_wsa",
  [CHART_DATA_TYPE.QUANTITY_PER_SHOP_WS_WSO_WSA]: "top_quantity_sum_per_shop_ws_wso_wsa",

  [CHART_DATA_TYPE.VALUE_SHARES_WS]: pickWs("top_worth_share_total_ws", "top_worth_share_total_ws_wsa"),
  [CHART_DATA_TYPE.VALUE_SHARES_WSO]: "top_worth_share_total_wso",
  [CHART_DATA_TYPE.VALUE_SHARES_WSA]: "top_worth_share_total_wsa",
  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO]: "top_worth_share_total_ws_wso_wsa",
  [CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA]: "top_worth_share_total_ws_wso_wsa",

  [CHART_DATA_TYPE.QUANTITY_SHARES_WS]: pickWs("top_quantity_share_total_ws", "top_quantity_share_total_ws_wsa"),
  [CHART_DATA_TYPE.QUANTITY_SHARES_WSO]: "top_quantity_share_total_wso",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WSA]: "top_quantity_share_total_wsa",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO]: "top_quantity_share_total_ws_wso_wsa",
  [CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA]: "top_quantity_share_total_ws_wso_wsa",

  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS]: pickWs("top_net_quantity_share_total_ws", "top_net_quantity_share_total_ws_wsa"),
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO]: "top_net_quantity_share_total_wso",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA]: "top_net_quantity_share_total_wsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO]: "top_net_quantity_share_total_ws_wso_wsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA]: "top_net_quantity_share_total_ws_wso_wsa",

  [CHART_DATA_TYPE.PRICE_AVG_WS]: pickWs("top_price_avg_ws", "top_price_avg_ws_wsa"),
  [CHART_DATA_TYPE.PRICE_AVG_WSO]: "top_price_avg_wso",
  [CHART_DATA_TYPE.PRICE_AVG_WSA]: "top_price_avg_wsa",
  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO]: "top_price_avg_ws_wso_wsa",
  [CHART_DATA_TYPE.PRICE_AVG_WS_WSO_WSA]: "top_price_avg_ws_wso_wsa",

  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS]: pickWs("top_net_quantity_sum_ws", "top_net_quantity_sum_ws_wsa"),
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSO]: "top_net_quantity_sum_wso",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WSA]: "top_net_quantity_sum_wsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO]: "top_net_quantity_sum_ws_wso_wsa",
  [CHART_DATA_TYPE.NET_QUANTITY_SUM_WS_WSO_WSA]: "top_net_quantity_sum_ws_wso_wsa",

  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS]: pickWs("top_avg_net_quantity_per_shop_ws", "top_avg_net_quantity_per_shop_ws_wsa"),
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSO]: "top_avg_net_quantity_per_shop_wso",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WSA]: "top_avg_net_quantity_per_shop_wsa",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO]: "top_avg_net_quantity_per_shop_ws_wso_wsa",
  [CHART_DATA_TYPE.AVG_NET_QUANTITY_PER_SHOP_WS_WSO_WSA]: "top_avg_net_quantity_per_shop_ws_wso_wsa"
};

const getKey = (dataType: ChartDataTypes) => {
  if (!(dataType in keysMap)) throw new Error(`Unknown type: ${dataType}`);
  return keysMap[dataType] as Keys<RankingApiIntersection>;
};

export const getValueKey = (key: Keys<RankingApiIntersection>): string => {
  const [, valueKey] = key.split("top_"); // crop "top_" prefix e.g. top_worth_sum -> worth_sum
  return valueKey;
};

export const getColorForRankingData = (type: Values<typeof DATASET_TYPES>) =>
  type === DATASET_TYPES.OWN
    ? RANKING_CHART_COLORS.OWN
    : RANKING_CHART_COLORS.COMPETITOR;

export const getRankingData = (
  response: RankingApiUnion,
  first: { base: string; primary: string; shares: string },
  second: { base: string; primary: string; shares: string },
  is5RankingDistributionHidden: boolean
): HorizontalChart[] => {
  try {
    const dataTypes = checkedDataTypes([first.primary, second.primary]);

    if (!dataTypes.length) return [];

    const sharesData: {
      label: string;
      value: number;
    }[][] = checkedDataTypes([first.shares, second.shares]).map(dataType => {
      const key = getKey(dataType);
      const valueKey = getValueKey(key);
      // @ts-ignore
      return response[key].map(raw => ({
        label: raw.material_name,
        value: raw[valueKey]
      }));
    });

    const keys = dataTypes.map(type => getKey(type));
    const valueKeys = keys.map(key => getValueKey(key));
    const isPercentageType = ([
      CHART_DATA_TYPE.VALUE_SHARES,
      CHART_DATA_TYPE.QUANTITY_SHARES,
      CHART_DATA_TYPE.NET_QUANTITY_SHARE
    ] as ChartDataTypes[]).includes(first.base);

    // @ts-ignore response comes from many different endpoints and requesting it with keys requires too much typeguarding, try/catch solves it
    const data: HorizontalChart[] = response[keys[0]].map(raw => {
      const label = raw.material_name;
      const primaryValue = raw[valueKeys[0]];
      const sharesValue =
        sharesData[0]?.find(d => d.label === label)?.value || null;
      const rectLabel = isNil(primaryValue)
        ? NO_DATA_SHORT
        : isPercentageType
        ? formatTooltipValue(primaryValue, first.base)
        : round(primaryValue);
      const tooltipLabel = isNil(primaryValue)
        ? NO_DATA_SHORT
        : addSuffix(
            formatTooltipValue(primaryValue, first.base),
            dataTypeToMetricType(first.base)
          );
      const sharesLabel = isNil(sharesValue)
        ? ""
        : formatNumber(sharesValue, NUMBER_TYPE.PERCENT);

      let base: HorizontalChart = {
        id: raw.material_id,
        label,
        company: raw.vendor_name,
        color: getColorForRankingData(raw.type),
        distributionLessThan5: raw.distribution_less_than_5,
        primaryValue: primaryValue || 0,
        rectLabel,
        tooltipLabel,
        sharesValue,
        sharesLabel,
        info: response.info || ""
      };

      // @ts-ignore response comes from many different endpoints and requesting it with  keys requires too much typeguarding, try/catch solves it
      if (!second.base || !response[keys[1]]) return base;

      // @ts-ignore same as above
      const rawSecondary = response[keys[1]].find(
        // @ts-ignore same as above
        ({ material_id }) => material_id === base.id
      );
      const secondaryValue = isNil(rawSecondary)
        ? null
        : rawSecondary[valueKeys[1]];

      if (!secondaryValue) {
        return {
          ...base,
          sidebarLabel: NO_DATA_SHORT
        };
      }

      return {
        ...base,
        sidebarLabel: addSuffix(
          formatTooltipValue(rawSecondary[valueKeys[1]], second.base),
          dataTypeToMetricType(second.base)
        )
      };
    });

    if (!is5RankingDistributionHidden) return data;

    // filter out products with less than 5% distribution, no matter what data type is chosen in dropdown
    return data.filter(({ distributionLessThan5 }) => !distributionLessThan5);
  } catch (error) {
    console.error(error);
    return [];
  }
};
