import * as React from "react";

import { FilterDropdownListItem } from "components/molecules";
import {
  FilterDropdownMenu,
  Props
} from "components/molecules/FilterDropdownMenu/FilterDropdownMenu";
import { UnactiveProductsCheckbox } from "components/organisms/UnactiveProductsCheckbox/UnactiveProductsCheckbox";
import { ProductItem } from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";

import { isActiveFn } from "../utils";
import s from "./productDropdownMenu.module.scss";

export const ProductDropdownMenu = (
  FilterDropdownMenuProps: Props<ProductItem>
) => {
  return (
    <FilterDropdownMenu
      isItemActiveFn={isActiveFn}
      listItem={<FilterDropdownListItem shouldMarkWithdrawnItems={true} />}
      className={{ base: s.filterDropdownProductMenu }}
      bottomElement={<UnactiveProductsCheckbox />}
      {...FilterDropdownMenuProps}
    />
  );
};
