import { format } from "date-fns";
import { values } from "ramda";

import { User, USER_STATUSES, UserApi } from "store/reducers/userReducer";
import { standarizeCompanyData } from "store/utils";
import { DEFAULT_DATE_FORMAT, USER_ROLES } from "utils";
import { Tier } from "utils/types";

export const standarizeUserData = ({
  user,
  currentTier
}: {
  user: UserApi;
  currentTier: Tier;
}): User => ({
  id: user.id || "",
  email: user.email || "",
  phoneNumber: user.phone_number || "",
  company: user.company ? standarizeCompanyData(user.company) : null,
  role: values(USER_ROLES)[user.role ?? -1] || null,
  isActive: user.is_active,
  name: user.name || "",
  status: values(USER_STATUSES)[user.status ?? -1] || null,
  //these 2 keys doesnt need newDateByTimezone as they come with specific timezone in it
  invitedAt: user.invited_at
    ? format(new Date(user.invited_at), DEFAULT_DATE_FORMAT)
    : "",
  signedUpAt: user.signed_up_at
    ? format(new Date(user.signed_up_at), DEFAULT_DATE_FORMAT)
    : "",
  statusDisplay: user.status_display || "",
  isAgreementAccepted: user.agreement_accepted || false,
  tiersAccess: user.tiers_access || [0],
  currentTier
});
