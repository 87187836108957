import { Action, createHook, createStore } from "react-sweet-state";

import {
  sortByIndex,
  sortByPrefix,
  sortUnknown
} from "api/segments/utils/sortSegments";

import { DropdownItem } from "components/molecules/types";
import {
  getMatched,
  getSelectedCountLabel,
  remainingElementsToSelect,
  selectElement
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import { sortItemsBySelectionOrder } from "pages/Reports/redux/utils";
import { Nullable } from "utils/types";

export type ShopTypeItem = DropdownItem<string>;

export type ShopTypeApi = {
  id?: string;
  name?: string;
  store_count?: number;
};

type State = {
  all: ShopTypeItem[];
  selected: ShopTypeItem[];
  storeCountData: { value: string; count: number }[];
};
type Actions = typeof actions;

const actions = {
  updateAllShopTypes: (all: ShopTypeItem[]): Action<State> => ({ setState }) =>
    setState({
      all
    }),
  updateSelectedShopTypes: (selected: ShopTypeItem[]): Action<State> => ({
    setState
  }) =>
    setState({
      selected
    }),
  updateStoreCountData: (
    storeCountData: { value: string; count: number }[]
  ): Action<State> => ({ setState }) =>
    setState({
      storeCountData
    }),
  selectShopType: (
    selectedShopType: Nullable<ShopTypeItem>
  ): Action<State> => ({ setState, getState }) =>
    setState({
      selected: selectElement(selectedShopType, getState().selected)
    }),
  selectAllShopTypes: (inputValue: string): Action<State> => ({
    setState,
    getState
  }) => {
    const state = getState();
    const allShopTypes = state.all;
    const selectedShopTypes = state.selected;

    const remainingToSelect = remainingElementsToSelect(
      selectedShopTypes,
      allShopTypes,
      inputValue
    );
    setState({
      selected: [...selectedShopTypes, ...remainingToSelect]
    });
  },
  sortShopTypes: (): Action<State> => ({ setState, getState }) => {
    const state = getState();

    const allShopTypes = state.all;
    const selectedShopTypesIds = state.selected.map(item => item.value);

    const sortedAllShopTypes = sortItemsBySelectionOrder(
      selectedShopTypesIds,
      allShopTypes
    );

    const sortedBySpecificOrder = sortBySpecificOrder(sortedAllShopTypes);

    setState({
      all: sortedBySpecificOrder
    });
  },
  clearShopTypes: (): Action<State> => ({ setState }) =>
    setState({
      selected: []
    }),
  checkSelectionsAfterFetching: (ids: string[]): Action<State> => ({
    getState,
    setState
  }) =>
    setState({
      selected: getMatched(getState().selected, ids)
    }),
  checkPristineSelectionsAfterFetching: (
    shopTypes: ShopTypeItem[],
    shopTypeQueryParam: string | string[]
  ): Action<State> => ({ setState }) => {
    const updatedSelectedShopTypes = shopTypes.filter(({ value }) =>
      shopTypeQueryParam.includes(value)
    );

    setState({
      selected: updatedSelectedShopTypes
    });
  }
};

export const ShopTypeStore = createStore<State, Actions>({
  name: "shopType",
  initialState: {
    all: [],
    selected: [],
    storeCountData: []
  },
  actions
});

const getSelectedShopTypesIds = (state: State) =>
  state.selected.map(item => item.value);

export const getShopTypeLabel = (state: State) => {
  if (state.selected.length === 1) {
    return state.selected[0].label;
  }
  return getSelectedCountLabel(state.selected.length, state.all.length);
};

export const getItemsCounterLabel = (state: State) => {
  const { all, selected, storeCountData } = state;
  let label = `Wybrano ${selected.length} z ${all.length}`;
  const totalCount = storeCountData.reduce((acc, item) => acc + item.count, 0);
  if (!totalCount) return label;
  const selectedCount = selected.reduce((acc, item) => {
    const found = storeCountData.find(({ value }) => item.value === value) || 0;
    return found ? acc + found.count : acc;
  }, 0);
  return `${label} (${selectedCount}/${totalCount})`;
};

export const ShopTypeHooks = {
  useShopType: createHook(ShopTypeStore),
  useShopTypeLabel: createHook(ShopTypeStore, {
    selector: getShopTypeLabel
  }),
  useSelectedShopTypesIds: createHook(ShopTypeStore, {
    selector: getSelectedShopTypesIds
  })
};

// UTILS
export const sortBySpecificOrder = (items: ShopTypeItem[]) => {
  return items
    .sort((a, b) => sortByIndex(a.value, b.value))
    .sort((a, b) => sortByPrefix(a.value, b.value))
    .sort((a, b) => sortUnknown(a.value, b.value));
};
