import {
  useAttributesHierarchy,
  useAttributesHierarchyFile
} from "pages/Reports/redux/reducers/filters/hooks/useAttributesHierarchy";

import { HierarchyModal } from "../HierarchyModal/HierarchyModal";

export const AttributesHierarchyModal = ({
  onClose
}: {
  onClose: VoidFunction;
}) => {
  const hierarchy = useAttributesHierarchy();
  const file = useAttributesHierarchyFile();

  return (
    <HierarchyModal
      id="js-attributes-hierarchy-popup"
      data={hierarchy.data.rows}
      columns={hierarchy.data.columns}
      isFetching={hierarchy.isFetching}
      isUrlFetching={file.isFetching}
      downloadText="Pobierz hierarchię do Excela"
      emptyText="Brak produktów"
      onDownload={file.download}
      onClose={onClose}
    />
  );
};
