import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { useProductTabLegendItems } from "api/segments/mappers/useProductTabLegendItems";
import cn from "classnames";

import { Button, NiceScrollbar } from "components/atoms";
import { Dot } from "components/atoms/Dot/Dot";
import { ChevronLeft, ChevronRight } from "components/atoms/Icon";
import { isRestrictedToOneLineSelector } from "components/molecules/ChartTypeToggle/hooks";
import c from "pages/Reports/partials/Chart/Legend/common.module.scss";
import { useSegmentsLegendLineNames } from "pages/Reports/partials/Chart/Legend/hooks/useLegendLineNames";
import { LegendTab } from "pages/Reports/partials/Chart/Legend/Legend/Legend";
import s from "pages/Reports/partials/Chart/Legend/Legend/legends.module.scss";
import { LegendItems } from "pages/Reports/partials/Chart/Legend/LegendItems/LegendItems";
import { LegendSubSection } from "pages/Reports/partials/Chart/Legend/LegendSubSection/LegendSubSection";
import {
  hiddenChartsSelector,
  hiddenLinesSelector,
  hoveredLineSelector,
  toggleArray,
  toggleChart,
  updateHiddenCharts,
  updateHoveredLine
} from "pages/Reports/redux/reducers/chartReducer";
import { SECTIONS } from "pages/Reports/utils";
import { toggleSegmentsLegend } from "store/actions/appActions";
import { isSegmentsLegendOpenSelector } from "store/selectors/appSelectors";
import { isGroupedBySegmentsSelector } from "store/selectors/routerSelectors";
import { ICON_SIZES } from "utils";
import { pushSegmentsLegendEvent } from "utils/googleTagManager/dataLayer";
import { pushSegmentLegendTabToggleEvent } from "utils/googleTagManager/dataLayer";

import a from "./segmentsLegend.module.scss";

type Props = {
  tabs: LegendTab[];
  width: number;
  tabIndex: number;
  onTabChange: (value: number) => void;
};

export const SegmentsLegend: React.FC<Props> = ({
  tabs,
  width,
  tabIndex,
  onTabChange
}) => {
  const dispatch = useDispatch();
  const hoveredLine = useSelector(hoveredLineSelector);
  const productTabLegendItems = useProductTabLegendItems();
  const hiddenCharts = useSelector(hiddenChartsSelector);
  const hiddenLines = useSelector(hiddenLinesSelector).segments;
  const isOpen = useSelector(isSegmentsLegendOpenSelector);
  const isRestrictedToOneLine = useSelector(isRestrictedToOneLineSelector);
  const isGroupedBySegments = useSelector(isGroupedBySegmentsSelector);
  const legendLineNames = useSegmentsLegendLineNames();

  const hiddenProducts = isGroupedBySegments ? hiddenLines : hiddenCharts;

  const hideLines = (values: string[]) => {
    if (isRestrictedToOneLine) {
      dispatch(toggleChart([]));
      dispatch(toggleChart(legendLineNames));
    }

    dispatch(toggleChart(values));
  };

  const hideCharts = (values: string[]) => {
    dispatch(updateHiddenCharts(toggleArray(values, hiddenCharts)));
  };

  const onMouse = (labels: string[]) => {
    if (!isRestrictedToOneLine) {
      dispatch(updateHoveredLine(labels));
    }
  };

  const onSelect = (index: number) => {
    pushSegmentLegendTabToggleEvent(
      index === 0 ? "show Segments" : "show Products"
    );

    onTabChange(index);
  };

  const toggleOpen = () => {
    pushSegmentsLegendEvent(isOpen ? "hide Legend" : "show legend");
    dispatch(toggleSegmentsLegend(!isOpen));
  };

  const handleSegmentsLabelSubSectionClick = (labels: string[]) => {
    if (isGroupedBySegments) {
      hideCharts(labels);
      return;
    }

    if (!isRestrictedToOneLine) {
      hideLines(labels);
    }
  };

  const handleSegmentsLabelItemClick = (labels: string[]) => {
    if (isGroupedBySegments) {
      hideCharts(labels);
    } else {
      hideLines(labels);
    }
  };

  const handleSegmentsLabelItemMouse = (labels: string[]) => {
    if (!isGroupedBySegments) {
      onMouse(labels || "");
    }
  };

  const onProductLabelItemClick = (label: string) => {
    if (isGroupedBySegments) {
      hideLines([label]);
    } else {
      hideCharts([label]);
    }
  };

  const onProductLabelItemMouse = (labels: string[]) => {
    if (isGroupedBySegments) {
      dispatch(updateHoveredLine(labels));
    }
  };

  return (
    <div
      className={cn(a.segmentsLegend, { [a.legendBoxClosed]: !isOpen })}
      style={{
        width: `${width}px`
      }}
    >
      <Button
        className={cn(a.button, { [a.buttonOpen]: isOpen })}
        onClick={toggleOpen}
        testId="toggle-segments-legend"
      >
        {isOpen ? (
          <ChevronLeft size={ICON_SIZES.EXTRA_LARGE} />
        ) : (
          <ChevronRight size={ICON_SIZES.EXTRA_LARGE} />
        )}
      </Button>
      <NiceScrollbar hideHorizontal={true} autoHeight>
        <Tabs
          className={a.segmentsTabs}
          selectedIndex={tabIndex}
          onSelect={onSelect}
        >
          <TabList>
            <li className={cn(c.legend, a.segmentsTabItem)}>Legenda:</li>
            {tabs.map(tab => (
              <Tab
                key={`tab-list-${tab.label}`}
                disabled={tab.disabled}
                selectedClassName={c.activeTab}
                disabledClassName={c.disabledTab}
              >
                <Button
                  disabled={tab.disabled}
                  className={s.tabTitle}
                  data-ga-graph-legend={tab.label}
                  testId={`tab-list-${tab.label}`}
                >
                  {tab.label}
                </Button>
              </Tab>
            ))}
          </TabList>

          {tabs.map(tab => {
            if (tab.label === SECTIONS.SEGMENTS) {
              return (
                <TabPanel
                  key={`tab-panel-${tab.label}`}
                  data-testid={`tab-panel-${tab.label}`}
                  className={c.flex}
                >
                  {tab.sections.map(section => (
                    <li
                      className={a.tabPanelChartsNames}
                      key={section.label}
                      data-testid={section.label}
                    >
                      <LegendSubSection
                        onClick={handleSegmentsLabelSubSectionClick}
                        isDisabled={
                          isGroupedBySegments
                            ? false
                            : Boolean(tab.disabled) || isRestrictedToOneLine
                        }
                        sectionTitle={section.label}
                        items={section.items}
                      >
                        <LegendItems
                          isDisabled={Boolean(tab.disabled)}
                          items={section.items}
                          itemsTitle={section.label}
                          hoveredLine={hoveredLine}
                          onClick={handleSegmentsLabelItemClick}
                          onMouse={handleSegmentsLabelItemMouse}
                        />
                      </LegendSubSection>
                    </li>
                  ))}
                </TabPanel>
              );
            }

            return (
              <TabPanel
                key={`tab-panel-${tab.label}`}
                data-testid={`tab-panel-${tab.label}`}
                className={c.flex}
              >
                <ul className={a.tabPanelSegmentsList}>
                  {productTabLegendItems.map(item => (
                    <li
                      key={item.label}
                      data-testid={item.label}
                      className={a.tabPanelSegmentsListItem}
                      style={{
                        opacity: hiddenProducts.includes(item.label)
                          ? "0.6"
                          : "1"
                      }}
                      onClick={() => onProductLabelItemClick(item.label)}
                      onMouseEnter={() => onProductLabelItemMouse([item.label])}
                      onMouseLeave={() => onProductLabelItemMouse([])}
                    >
                      <Dot style={{ backgroundColor: item.color }} />
                      {item.label}
                    </li>
                  ))}
                </ul>
              </TabPanel>
            );
          })}
        </Tabs>
      </NiceScrollbar>
    </div>
  );
};
