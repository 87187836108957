import Cookies from "js-cookie";

import { REDIRECT_PATH_KEY_NAME, USER_ANALYTICS_COOKIE_NAME } from "./";

export const getAnalyticsCookie = () => Cookies.get(USER_ANALYTICS_COOKIE_NAME);

export const getUserCookies = () => ({
  [USER_ANALYTICS_COOKIE_NAME]: getAnalyticsCookie() === "true"
});

export const setUserCookies = (analytics: boolean) => {
  Cookies.set(USER_ANALYTICS_COOKIE_NAME, String(analytics), {
    expires: 2 * 365
  });
};

export const setLoginRedirectLocalStorage = (path: string) => {
  localStorage.setItem(REDIRECT_PATH_KEY_NAME, path);
};

export const getLoginRedirectLocalStorage = () =>
  localStorage.getItem(REDIRECT_PATH_KEY_NAME);

export const removeLoginRedirectLocalStorage = () =>
  localStorage.removeItem(REDIRECT_PATH_KEY_NAME);
