import {
  Action,
  createHook,
  createStore,
  defaultRegistry
} from "react-sweet-state";

import moment, { Moment } from "moment";

import { BOOL_STRING_VALUES } from "utils";
import { Nullable } from "utils/types";

export type DateItem<T = string> = { dateFrom: T; dateTo: T };

type State = {
  selected: DateItem; // dates selected and approved by "Aktualizuj" button
  selectedHistorical: DateItem; // dates selected and approved by "Aktualizuj" button
  selectedHistoricalOpen: boolean; // historical calendar open status selected and approved by "Aktualizuj" button
  calendar: DateItem<Nullable<Moment>>; // dates temporarily selected in calendar
  includeLeapYear: Nullable<boolean>; // if null then don't send to BE
  isDynamicPeriodChecked: boolean;
  dynamicPeriod: string;
  selectedDynamicPeriod: DateItem;
};
type Actions = typeof actions;

const actions = {
  updateSelectedDates: (selected: DateItem): Action<State> => ({
    setState
  }) => {
    setState({ selected });
  },
  updateSelectedHistorical: (selectedHistorical: DateItem): Action<State> => ({
    setState
  }) => {
    setState({ selectedHistorical });
  },
  updateHistoricalOpen: (isOpen: boolean): Action<State> => ({ setState }) => {
    setState({ selectedHistoricalOpen: isOpen });
  },
  updateCalendarDates: (
    calendar: DateItem<Nullable<Moment>>
  ): Action<State> => ({ setState }) => {
    setState({ calendar });
  },
  updateDynamicPeriodChecked: (isChecked: boolean): Action<State> => ({
    setState
  }) => {
    setState({ isDynamicPeriodChecked: isChecked });
  },
  updateDynamicPeriod: (period: string): Action<State> => ({ setState }) => {
    setState({ dynamicPeriod: period });
  },
  updateSelectedDynamicPeriod: (item: DateItem): Action<State> => ({
    setState
  }) => {
    setState({ selectedDynamicPeriod: item });
  },
  checkPristineSelections: (
    basePeriod: DateItem,
    comparePeriod: DateItem,
    includeLeapYear: string,
    selectedDynamicPeriod: DateItem,
    isDynamicPeriodChecked: string,
    dynamicPeriod: string
  ): Action<State> => ({ setState }) => {
    const initialOpen = !!comparePeriod.dateFrom && !!comparePeriod.dateTo;
    const initialIncludeLeapYear =
      includeLeapYear === ""
        ? null
        : includeLeapYear === BOOL_STRING_VALUES.TRUE;

    setState({
      selected: {
        dateFrom: basePeriod.dateFrom,
        dateTo: basePeriod.dateTo
      },
      calendar: {
        dateFrom: moment(basePeriod.dateFrom),
        dateTo: moment(basePeriod.dateTo)
      },
      selectedHistorical: {
        dateFrom: comparePeriod.dateFrom,
        dateTo: comparePeriod.dateTo
      },
      selectedHistoricalOpen: initialOpen,
      includeLeapYear: initialIncludeLeapYear,
      selectedDynamicPeriod,
      isDynamicPeriodChecked:
        isDynamicPeriodChecked === BOOL_STRING_VALUES.TRUE,
      dynamicPeriod
    });
  },
  updateIncludeLeapYear: (
    includeLeapYear: Nullable<boolean>
  ): Action<State> => ({ setState }) => {
    setState({ includeLeapYear });
  }
};

export const CalendarStore = createStore<State, Actions>({
  name: "calendar",
  initialState: {
    selected: { dateFrom: "2021-05-01", dateTo: "2021-05-30" },
    selectedHistorical: { dateFrom: "", dateTo: "" },
    selectedHistoricalOpen: false,
    calendar: { dateFrom: null, dateTo: null },
    includeLeapYear: null,
    isDynamicPeriodChecked: false,
    dynamicPeriod: "",
    selectedDynamicPeriod: { dateFrom: "", dateTo: "" }
  },
  actions
});

const getDatesLabel = (state: State) => {
  const { dateFrom, dateTo } = state.selected;
  const {
    dateFrom: historicalDateFrom,
    dateTo: historicalDateTo
  } = state.selectedHistorical;
  const formattedDateFrom = dateFrom.replace(/-/g, ".");
  const formattedDateTo = dateTo.replace(/-/g, ".");
  const formattedHistoricalDateFrom = historicalDateFrom.replace(/-/g, ".");
  const formattedHistoricalDateTo = historicalDateTo.replace(/-/g, ".");

  return {
    regularDateLabel: `${formattedDateFrom} - ${formattedDateTo}`,
    historicalDateLabel: formattedHistoricalDateFrom
      ? `${formattedHistoricalDateFrom} - ${formattedHistoricalDateTo}`
      : ""
  };
};

export const CalendarHooks = {
  useCalendar: createHook(CalendarStore),
  useCalendarDatesLabel: createHook(CalendarStore, {
    selector: getDatesLabel
  })
};

export const CalendarStoreInstance = defaultRegistry.getStore(CalendarStore);
