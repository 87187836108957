import { useQueries } from "react-query";
import { useLocation } from "react-router-dom";

import { QueryKey } from "api/consts";
import { multipackServices } from "api/multipack/services/multipack.services";
import { useFetchParams } from "api/utils";

import { REPORTS_PATHS } from "pages/Reports/utils";
import { isThisPage } from "utils";

export const useGetMultipack = () => {
  const { queryKeys, isG1Selected } = useFetchParams();

  const { pathname } = useLocation();
  const isMultipackPage = isThisPage(pathname, REPORTS_PATHS.MULTIPACK);

  const queries = useQueries(
    queryKeys.map(({ query, period }) => {
      return {
        queryKey: [QueryKey.MULTIPACK_DATA, query],
        queryFn: () => multipackServices.fetchMultipack(query, period),
        enabled: isG1Selected && isMultipackPage,
        retry: false
      };
    })
  );

  const isLoading = queries.some(query => query.isLoading);
  const isError = queries.some(query => query.isError);
  const isIdle = queries.some(query => query.isIdle);

  const returnValue = {
    isLoading,
    isError,
    isIdle,
    queriesData: queries.map(query => query.data)
  };

  return returnValue;
};
