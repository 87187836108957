import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";

import { Text } from "components/atoms";
import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import { useFiltersQP } from "hooks";
import {
  ClientTypeHooks,
  ClientTypeItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useClientType";
import { isClientTypeMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";

import { DROPDOWN_PLACEHOLDERS } from "../constants";
import { filterItems, isActiveFn } from "../utils";
import s from "./clientTypeChoiceGroup.module.scss";

type Props = {
  isDisabled: boolean;
};

export const ClientTypeChoiceGroup = ({ isDisabled }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [state, actions] = ClientTypeHooks.useClientType();
  const [label] = ClientTypeHooks.useClientTypeLabel();
  const { clientTypesQP } = useFiltersQP();
  const isMultiChoice = useSelector(isClientTypeMultiChoiceSelector);

  const handleChange = (item: ClientTypeItem) => {
    setOpen(isMultiChoice);
    actions.updateSelectedClientType(item, isMultiChoice);
  };

  const handleOpen = () => {
    setOpen(true);
    handleInputValueChange("");
    setInputValue("");
  };

  const handleClose = (selectedItem?: ClientTypeItem) => {
    setOpen(false);

    if (!selectedItem) {
      setInputValue(label);
    }
  };

  const handleStateChange = (changes: StateChangeOptions<ClientTypeItem[]>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleInputValueChange = (value?: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  useEffect(() => {
    if (!isOpen) setInputValue(label);
  }, [isOpen, label]);

  useEffect(() => {
    actions.setPristineClientType(clientTypesQP, isMultiChoice);
  }, [actions, clientTypesQP, isMultiChoice]);

  return (
    <Dropdown<ClientTypeItem[]>
      isDisabled={isDisabled}
      isMultiChoice={isMultiChoice}
      // @ts-ignore workaround: downshift calls onInputValueChange  with result of itemToString prop call.
      // once we know this fn always returns undefined it makes downshift fully controlled and inputValue
      // depends only on state of CategoryDropdown
      itemToString={() => {}}
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      items={state.all}
      selectedItem={state.selected}
      // @ts-ignore
      onChange={handleChange}
      onStateChange={handleStateChange}
      openedMenuPlaceholder={DROPDOWN_PLACEHOLDERS.CLIENT_TYPE.OPEN}
      closedMenuPlaceholder={DROPDOWN_PLACEHOLDERS.CLIENT_TYPE.CLOSED}
      input={
        <FilterDropdownInput
          isTypingEnabled
          automaticInputValue={inputValue}
          testId="client-type-dropdown"
        />
      }
      menu={
        <FilterDropdownMenu<ClientTypeItem>
          isItemActiveFn={isActiveFn}
          filterItemsFn={filterItems}
          placeholder={<Text className={s.placeholder}>pobieranie danych</Text>}
          listItem={<FilterDropdownListItem />}
          bottomElement={
            isMultiChoice ? (
              <span className={s.bottomElementInfo}>
                Możesz wybrać maks. 2 z 3 rodzajów transakcji
              </span>
            ) : null
          }
        />
      }
    />
  );
};
