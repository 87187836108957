import { useSelector } from "react-redux";

import { useGetRanking } from "api/ranking/hooks/useGetRanking";
import { useRankingData } from "api/ranking/mappers/useRankingData";
import { useIsRankingSelected } from "api/ranking/utils/useIsRankingSelected";
import { isEmpty } from "ramda";

import { isRanking5DistributionHiddenSelector } from "store/selectors/routerSelectors";

export const useIsRankingWith5PercentDistributionVisible = () => {
  const isRankingSelected = useIsRankingSelected();
  const { data } = useGetRanking();
  const is5RankingDistributionHidden = useSelector(
    isRanking5DistributionHiddenSelector
  );
  const rankingData = useRankingData();

  if (!isRankingSelected || isEmpty(data)) return false;

  // @ts-ignore data object is checked few lines above
  const allProductsLength = data["top_distribution"].length;
  const filteredBy5PercentDistributionRankingData = rankingData.length;

  return (
    is5RankingDistributionHidden &&
    filteredBy5PercentDistributionRankingData !== allProductsLength
  );
};
