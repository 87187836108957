import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "components/atoms";
import { IDS } from "components/organisms/Tour";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { updateQueryParams } from "store/actions/routerActions";
import {
  isLogisticsTableVisibleSelector,
  pathnameSelector,
  queryParamsSelector
} from "store/selectors/routerSelectors";
import { BOOL_STRING_VALUES, isThisPage } from "utils";
import { QP } from "utils/defaultQueryParams";

import s from "./fullScreenWrapper.module.scss";

export const useFullScreen = () => {
  const dispatch = useDispatch();
  const pathname = useSelector(pathnameSelector);
  const params = useSelector(queryParamsSelector);
  const isLogisticsTable = useSelector(isLogisticsTableVisibleSelector);

  // for logistics report enable only for chart tab
  const enabled = isThisPage(pathname, REPORTS_FULL_PATHS.LOGISTICS_PATH)
    ? !isLogisticsTable
    : [
        REPORTS_FULL_PATHS.SELL_PATH,
        REPORTS_FULL_PATHS.MULTIPACK_PATH,
        REPORTS_FULL_PATHS.LOCATION_PATH,
        REPORTS_FULL_PATHS.SHARES_PATH,
        REPORTS_FULL_PATHS.DYNAMICS_PATH,
        REPORTS_FULL_PATHS.RECEIPTS_PATH,
        REPORTS_FULL_PATHS.SEGMENTS_PATH,
        REPORTS_FULL_PATHS.LOYALTY_PATH
      ].some(url => isThisPage(pathname, url));
  const visible = params[QP.FULL_SCREEN] === BOOL_STRING_VALUES.TRUE;

  return {
    enabled,
    visible,
    onChange: () => {
      dispatch(updateQueryParams({ [QP.FULL_SCREEN]: String(!visible) }));
    }
  };
};

export const FullScreenButton = () => {
  const fullScreen = useFullScreen();

  if (!fullScreen.enabled) return null;

  return (
    <Button
      id={IDS.FULL_SCREEN_BUTTON}
      className={s.button}
      onClick={fullScreen.onChange}
    >
      {fullScreen.visible
        ? "Wyłącz tryb pełnoekranowy"
        : "Włącz tryb pełnoekranowy"}
    </Button>
  );
};

export const FullScreenWrapper = ({ children }: { children: ReactNode }) => {
  const fullScreen = useFullScreen();

  if (!fullScreen.enabled || !fullScreen.visible) {
    return <>{children}</>;
  }

  return (
    <div className={s.overlay}>
      <div className={s.content}>{children}</div>
    </div>
  );
};
