import {
  DSCounties,
  KPCounties,
  LBCounties,
  LDCounties,
  LSCounties,
  MPCounties,
  MZCounties,
  OPCounties,
  PKCounties,
  PLCounties,
  PMCounties,
  SKCounties,
  SLCounties,
  WMCounties,
  WPCounties,
  ZPCounties
} from "components/D3/County";
import { VOIVODESHIPS } from "components/D3/Voivodeship/constants";
import { Values } from "utils/types";

export const getCountiesIdsByVoivodeship = (
  voivodeship: Values<typeof VOIVODESHIPS>
): string[] => {
  switch (voivodeship) {
    case VOIVODESHIPS.DS:
      return DSCounties.map(({ id }) => id);
    case VOIVODESHIPS.KP:
      return KPCounties.map(({ id }) => id);
    case VOIVODESHIPS.LB:
      return LBCounties.map(({ id }) => id);
    case VOIVODESHIPS.LS:
      return LSCounties.map(({ id }) => id);
    case VOIVODESHIPS.LD:
      return LDCounties.map(({ id }) => id);
    case VOIVODESHIPS.MP:
      return MPCounties.map(({ id }) => id);
    case VOIVODESHIPS.MZ:
      return MZCounties.map(({ id }) => id);
    case VOIVODESHIPS.OP:
      return OPCounties.map(({ id }) => id);
    case VOIVODESHIPS.PK:
      return PKCounties.map(({ id }) => id);
    case VOIVODESHIPS.PL:
      return PLCounties.map(({ id }) => id);
    case VOIVODESHIPS.PM:
      return PMCounties.map(({ id }) => id);
    case VOIVODESHIPS.SL:
      return SLCounties.map(({ id }) => id);
    case VOIVODESHIPS.SK:
      return SKCounties.map(({ id }) => id);
    case VOIVODESHIPS.WM:
      return WMCounties.map(({ id }) => id);
    case VOIVODESHIPS.WP:
      return WPCounties.map(({ id }) => id);
    case VOIVODESHIPS.ZP:
      return ZPCounties.map(({ id }) => id);
  }
};
