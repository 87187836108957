import { useSelectedChartType } from "components/molecules/ChartTypeToggle/hooks";
import { CHART_TYPES } from "utils";

const SETTINGS = [
  {
    name: CHART_TYPES.LINE,
    isBackDataVisible: false
  },
  {
    name: CHART_TYPES.AREA,
    isBackDataVisible: false
  },
  {
    name: CHART_TYPES.INCREMENTAL,
    isBackDataVisible: true
  },
  {
    name: CHART_TYPES.COMBO,
    isBackDataVisible: false
  },
  {
    name: CHART_TYPES.GROUPED_BAR,
    isBackDataVisible: false
  }
];

export const useChartValueTilesVisibility = () => {
  const chartType = useSelectedChartType();
  const chart = SETTINGS.find(chart => chart.name === chartType);
  return [true, chart?.isBackDataVisible];
};
