import * as React from "react";

import cn from "classnames";
import { axisLeft, format, ScaleLinear, select, selectAll } from "d3";

import c from "pages/Reports/partials/Chart/common.module.scss";
import {
  areTicksBothPositiveAndNegative,
  getAxisTickValues,
  getDecimalZerosCount,
  getShowDecimals
} from "pages/Reports/partials/Chart/StandardChart/utils";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { CHART_DATA_TYPE } from "utils";

interface Props {
  yScale: ScaleLinear<number, number>;
  dataType?: ChartDataTypes;
  minValue: number;
  maxValue: number;
}

export const LeftAxis = ({
  yScale,
  dataType = "",
  minValue,
  maxValue
}: Props) => {
  const showDecimals = getShowDecimals(maxValue, dataType);
  const chartTicks: number[] = getAxisTickValues({
    min: minValue,
    max: maxValue,
    fillWithZero: true
  });

  const axisRef = React.useRef<SVGGElement | null>(null);

  const makeZeroTickBold = () => {
    selectAll(".leftAxisTicks > g.tick")
      .filter(d => d === 0)
      .select("text")
      .attr("class", `text ${c.zeroTextTick}`)
      .attr("transform", "translate(10, -6)");
  };

  const updateAxis = React.useCallback(() => {
    if (axisRef.current) {
      const axis = select(axisRef.current).call(
        axisLeft(yScale)
          .tickValues(chartTicks)
          .tickSize(0)
      );
      axis.call((g: any) => g.select(".domain").remove());
      axis.selectAll("line").remove();
      axis.selectAll("text").attr("class", "text");

      const areTicksNegativeAndPositive = areTicksBothPositiveAndNegative(
        chartTicks
      );

      if (areTicksNegativeAndPositive) {
        makeZeroTickBold();
      }

      if (showDecimals) {
        const ticks = axis.selectAll("text").data() as number[];
        const first = ticks[1] || 0;
        let specifier = ",.2f";

        if (first < 10) {
          // get smallest tick value (besides 0) from ticks array since it's already sorted
          // and count it's decimal zeros (e.g. 0,001 will return 2)
          const zeros = getDecimalZerosCount(ticks[1] || 0);

          // get max value from <2, x> range, so in case there's no zeros
          // the lowest possible specifier will be ",.2f"
          specifier = `,.${Math.max(2, zeros + 1)}f`;
        }

        axis
          .selectAll("text")
          .text(value => format(specifier)(value as number));
      }
    }
  }, [yScale, chartTicks, showDecimals]);

  React.useEffect(() => updateAxis(), [chartTicks, updateAxis]);

  if (dataType === CHART_DATA_TYPE.NONE) {
    return null;
  }

  return <g className={cn(c.text, "leftAxisTicks")} ref={axisRef} />;
};
