import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useLocationGridChartLegend } from "api/location/mappers/useLocationGridChartLegend";
import { useVisibleSegments } from "api/segments/mappers";
import { useProductTabLegendItems } from "api/segments/mappers/useProductTabLegendItems";

import { hiddenLinesSelector } from "pages/Reports/redux/reducers/chartReducer";
import { createSegmentsLegendTabs } from "pages/Reports/sections/Segments/SegmentsChartSection";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import {
  isGroupedBySegmentsSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";
import { isThisPage } from "utils";

import { useLegend } from "./useLegend";

export const useLegendLineNames = () => {
  const tabs = useLegend();
  const sections = tabs.length > 0 ? tabs[0].sections : [];
  return sections.flatMap(section => section.items.map(item => item.id));
};

// for location report only
export const useLocationLegendLineNames = () => {
  const tabs = useLocationGridChartLegend();
  const sections = tabs.length > 0 ? tabs[0].sections : [];
  const regions = sections.flatMap(section => section.regions);
  return regions.flatMap(({ label, region }) =>
    region.map(({ text }) => `${label} - ${text}`)
  );
};

// for segments report only
export const useSegmentsLegendLineNames = () => {
  const hiddenLines = useSelector(hiddenLinesSelector).segments;
  const isGroupedBySegments = useSelector(isGroupedBySegmentsSelector);
  const visibleSegments = useVisibleSegments();
  const productTabLegendItems = useProductTabLegendItems();

  const tabs = useMemo(() => {
    return createSegmentsLegendTabs(hiddenLines, visibleSegments);
  }, [hiddenLines, visibleSegments]);

  if (isGroupedBySegments) {
    return productTabLegendItems.map(item => item.label);
  }
  const sections = tabs.length > 0 ? tabs[0].sections : [];
  return sections.flatMap(section => section.items.map(item => item.text));
};

// for sidebar or other globally available components/hooks
export const useAllLegendLineNames = () => {
  const pathname = useSelector(pathnameSelector);
  const legendLineNames = useLegendLineNames();
  const locationLegendLineNames = useLocationLegendLineNames();
  const segmentsLegendLineNames = useSegmentsLegendLineNames();

  if (isThisPage(pathname, REPORTS_FULL_PATHS.LOCATION_PATH)) {
    return locationLegendLineNames;
  }

  if (isThisPage(pathname, REPORTS_FULL_PATHS.SEGMENTS_PATH)) {
    return segmentsLegendLineNames;
  }

  return legendLineNames;
};
